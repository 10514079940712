import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

import Echo from "laravel-echo"
import { API_URL, API_URL_1 } from "helpers/api_url"
import jwt_decode from "jwt-decode"

import Pusher from "pusher-js"
import { getNotifications, readNotification } from "helpers/backend_helpers"
import Toaster from "components/Common/Toast"

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: 'ab0f7406c459cb8e5f69',
//   cluster: 'ap1',
//   // encrypted: true
// });

// Pusher.log = (msg) => {
//   console.log(msg);
// };

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notif, setNotif] = useState([])
  const token = localStorage.getItem("token")

  useEffect(() => {
    getNotif()
  }, [])

  const getNotif = async () => {
    const data = await getNotifications(token)
    setNotif(data.data)
  }

  // console.log(notif)

  // const options = {
  //   broadcaster: 'pusher',
  //   key: config.pusher.key,
  //   cluster: config.pusher.cluster,
  //   forceTLS: config.pusher.tls,
  //   //authEndpoint is your apiUrl + /broadcasting/auth
  //   authEndpoint: `${API_URL}/api/notifications/get`,
  //   // As I'm using JWT tokens, I need to manually set up the headers.
  //   auth: {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       Accept: 'application/json',
  //     },
  //   },
  // };

  // const echo = new Echo(options);
  // echo.private(`App.User.${userId}`).notification((data) => {
  //     console.log(data);
  // });

  //   if(localStorage.getItem("token")) {
  //     window.Pusher = require('pusher-js');
  //     let decoded = jwt_decode(localStorage.getItem("token"));
  //     // const echo = new Echo();
  //     window.Echo.private(`App.User.`+decoded.sub)
  //       .notification((notification) => {
  //         console.log(notification);
  //     });
  //     // console.log(window)
  //  }

  const echo = new Echo({
    broadcaster: "pusher",
    key: `${process.env.REACT_APP_PUSHER_KEY}`,
    cluster: "ap1",
    encrypted: true,
    authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        apiKey: process.env.REACT_APP_API_KEY,
      },
    },
  })

  useEffect(() => {
    if (localStorage.getItem("token")) {
      // console.log(echo)
      let decoded = jwt_decode(localStorage.getItem("token"))
      echo
        .private(`App.Models.User.` + decoded.sub)
        .notification(notification => {
          setNotif([notification, ...notif])
          // console.log("notiff", notification);
          // console.log('okkk')
          // this.$store.dispatch('addNotif',notification).then(() => {});
        })
    }
    // var pusher = new Pusher('ab0f7406c459cb8e5f69', {
    //   cluster: 'ap1'
    // });

    // pusher.subscribe('kyc-channel')
    //   .bind('App\\Notifications\\NewKyc', function(data) {
    //       // alert(JSON.stringify(data));
    //       // console.log('df',JSON.stringify(data))
    //       log(JSON.stringify(data))
    //     });

    // pusher.connection.bind( 'error', function( err ) {
    //   if( err.error.data.code === 4004 ) {
    //     log('>>> detected limit error');
    //   }
    // });
    // console.log('adfsd')
    // let decoded = jwt_decode(localStorage.getItem("token"));
    // const echo = new Echo({
    //   broadcaster: 'pusher',
    //   key: 'ab0f7406c459cb8e5f69',
    //   cluster: 'ap1',
    // });
    // echo.private(`App.User.4`).notification((data) => {
    //   log('2', data);
    // });

    // window.Echo.private(`App.User.`+decoded.sub)
    //   .notification((notification) => {
    //     console.log(notification);
    // });

    // console.log("echo")
  })

  const handleNotifCampaign = async item => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/detail-kampanye/${item.campaign_id}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifSignature = async item => {
    try {
      await readNotification(item.id, token)
      props.history.push(
        `/proyek-detail/${item.id}?campaign_id=${item.campaign_id}`
      )
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifVa = async item => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/portfolio-transaksi?id=${item.campaign_id}&&page=1`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifKyc = async item => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/profile`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifProject = async item => {
    let type = "pengajuan-ukm"
    if (item.project_type == "Property") {
      type = "pengajuan-properti"
    }
    try {
      await readNotification(item.id, token)
      props.history.push(`/${type}/${item.project_id}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifCampaignUpdate = async item => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/portfolio/${item.trx_uuid}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {notif.length > 0 && (
            <span className="badge badge-danger badge-pill">
              {notif.length}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-right-custom">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Pemberitahuan")} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                  View All
                </a>
              </div> */}
            </Row>
          </div>

          <SimpleBar
            style={{ height: `${notif.length > 0 ? "230px" : "40px"}` }}
          >
            {notif.length > 0 ? (
              notif.map(item => {
                if (
                  item.type_notification == "published-campaign" ||
                  item.type_notification == null
                ) {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifCampaign(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bx-store" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Kampanye Terbaru <b>{item.campaign_title}</b>
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (item.type_notification == "lender-ttd-musyarakah") {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifSignature(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bx-briefcase-alt" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Pembiayaan Kampanye <b>{item.campaign_title}</b>,
                              Mohon tandatangani akad Musyarakah
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (item.type_notification == "borrower-info-va") {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifVa(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bx-money" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Virtual Account telah terbit untuk pembiayaan{" "}
                              <b>{item.campaign_title}</b>, silahkan lakukan
                              pembayaran.
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (item.type_notification == "approval-user") {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifKyc(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bxs-user-circle" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Akun anda telah <b>{item.kyc_answer}</b>
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (item.type_notification == "approval-project") {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifProject(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bx-briefcase-alt" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Status pengajuan proyek anda{" "}
                              <b>{item.project_title}</b> telah{" "}
                              <b>{item.project_status}</b>
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (item.type_notification == "registration-privy-status") {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifKyc(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bxs-user-circle" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Akun anda telah <b>{item.privy_answer}</b>
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                if (item.type_notification == "campaign-update") {
                  return (
                    <div
                      className="text-reset notification-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNotifCampaignUpdate(item)}
                    >
                      <div className="media">
                        <div className="avatar-xs mr-3">
                          <span className="avatar-title bg-primary rounded-circle font-size-16">
                            <i className="bx bx-store" />
                          </span>
                        </div>
                        <div className="media-body">
                          {/* <h6 className="mt-0 mb-1">
                          {props.t("Your order is placed")}
                        </h6> */}
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {/* {props.t("If several languages coalesce the grammar")} */}
                              Ada Perkembangan Proyek Terbaru{" "}
                              <b>{item.campaign_update_title}</b>
                            </p>
                            {/* <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>{" "}
                            {props.t("3 min ago")}{" "}
                          </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                return null
              })
            ) : (
              <div className="d-flex justify-content-center font-weight-bold">
                Belum Ada Pemberitahuan Baru
              </div>
            )}
            {/* <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-cart"/>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your order is placed")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"/>{" "}
                      {props.t("3 min ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <img
                  src={avatar3}
                  className="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"/>
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs mr-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check"/>
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"/>{" "}
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="media">
                <img
                  src={avatar4}
                  className="mr-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"/>
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top">
            <Link
              className="btn btn-sm btn-link font-size-12 btn-block text-center"
              to="/notification"
            >
              {" "}
              {props.t("Lihat Semua Pemberitahuan")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withRouter(withTranslation()(NotificationDropdown))

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
