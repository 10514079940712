import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import {
  getAkadType,
  getProvince,
  getUsersAllDropdown,
  postCampaignEdit,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Button,
  CardSubtitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import Switch from "react-switch"
import AsyncSelect from "react-select/async"

const INIT_INPUT = {
  akad_type_id: "",
  title: "",
  province_id: "",
  image: null,
  linkYoutube: "",
  target: "",
  collected: "",
  nisbah: "",
  tenor: "",
  end_date_campaign: "",
  secret_key: "",
}
const IINIT_MASTER = {
  province: [],
  akad_type: [],
}

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const CampaignEdit = props => {
  const token = localStorage.getItem("token")
  const { campaign, getCampaignUuid, loadButt, setLoadButt } = props

  const [input, setInput] = useState(INIT_INPUT)
  const [master, setMaster] = useState(IINIT_MASTER)
  const [campaignCopy, setCampaignCopy] = useState(campaign)
  const [isPrivatePassword, setIsPrivatePassword] = useState(false)
  const [isPrivateHidden, setIsPrivateHidden] = useState(false)
  const [assign, setAssign] = useState([])
  const [modalUsers, setModalUsers] = useState(false)
  const [users, setUsers] = useState([])

  const [autoSignature, setAutoSignature] = useState(false)

  useEffect(() => {
    if (campaignCopy) {
      initEdit()
      getMasterData()
    }
  }, [campaignCopy])

  useEffect(() => {
    setIsPrivatePassword(Boolean(campaign?.is_private))
    setIsPrivateHidden(Boolean(campaign?.isAssigne))
    setAssign(campaign?.campaign_private_assigne?.map(item => item.user) || [])
    setAutoSignature(Boolean(campaign?.isAutoSigner))
  }, [campaign])

  const getMasterData = async () => {
    const token = localStorage.getItem("token")
    try {
      const akad_type = await getAkadType(token)
      const province = await getProvince(token)
      setMaster({
        ...master,
        akad_type: akad_type.data,
        province: province.data,
      })
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const initEdit = () => {
    setInput({
      ...input,
      akad_type_id: campaign.akad_type ? campaign.akad_type.uuid : "",
      title: campaign.title,
      province_id: campaign.province.uuid,
      image: campaign.image,
      linkYoutube: campaign.link_video,
      target: campaign.target,
      collected: campaign.collected || 0,
      nisbah: campaign.nisbah,
      tenor: campaign.tenor,
      end_date_campaign: campaign.end_date_campaign,
      secret_key: campaign.secret_key || "",
    })
  }

  const handleChange = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeFile = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.files[0],
    })
  }

  const handleSubmit = async () => {
    const token = localStorage.getItem("token")

    let newValue = {
      ...input,
      is_private: isPrivatePassword,
      isAssigne: isPrivateHidden,
      isAutoSigner: autoSignature,
      user_ids: assign.map(item => item.id).join(","),
    }

    if (typeof newValue.image === "string") {
      delete newValue.image
    }

    const formData = new FormData()
    Object.keys(newValue).forEach(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postCampaignEdit(campaign.uuid, formData, token)
      getCampaignUuid()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Submit Data")
      window.location.reload()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalUsers = () => {
    return (
      <Modal isOpen={modalUsers} role="dialog" autoFocus={true}>
        <ModalHeader
          toggle={() => {
            setModalUsers(false)
            setUsers([])
          }}
        >
          Tambah Pengguna
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Pilih Pengguna</Label>
            <AsyncSelect
              cacheOptions
              isMulti={true}
              loadOptions={loadOptionsUsers}
              defaultOptions
              onChange={e => {
                setUsers(e)
              }}
              value={users}
            />
          </FormGroup>
          <div className="py-2">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              disabled={loadButt}
              onClick={() => {
                setAssign([
                  ...assign,
                  ...users.map(item => {
                    return {
                      ...item,
                      id: item.value,
                    }
                  }),
                ])
                setUsers([])
                setModalUsers(false)
              }}
            >
              Tambah
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const loadOptionsUsers = (inputValue, callback) => {
    let param = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAllDropdown(token, param)
      callback(
        data.data
          // .filter(el => assign.map(user => user.id).includes(el.uuid))
          .map(item => {
            return { label: item.email, value: item.uuid, ...item }
          })
      )
    })
  }

  const handleDeleteUserPrivate = item => {
    setAssign(assign.filter(el => el.id !== item.id))
  }

  return (
    <div>
      {renderModalUsers()}
      <div>
        {/* <CardTitle>Shipping information</CardTitle> */}
        <CardSubtitle className="mb-3">
          {/* Fill all information below */}
        </CardSubtitle>
        <div>
          <FormGroup className="mb-4" row>
            <Label md="2">Akad</Label>
            <Col md="10">
              <Input
                type="select"
                name="akad_type_id"
                value={input.akad_type_id}
                onChange={handleChange}
              >
                <option value="">Pilih</option>
                {master.akad_type.map(item => {
                  return <option value={item.uuid}>{item.title}</option>
                })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Nama Campaign</Label>
            <Col md="10">
              <Input
                type="text"
                name="title"
                placeholder="Nama Campaign"
                value={input.title}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Provinsi</Label>
            <Col md="10">
              <Input
                type="select"
                name="province_id"
                onChange={handleChange}
                value={input.province_id}
              >
                <option value="">Pilih</option>
                {master.province.map(item => {
                  return <option value={item.uuid}>{item.name}</option>
                })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Gambar</Label>
            <Col md="10">
              {/* <Input
                type="file"
                name="image"
                placeholder="Image Campaign"
                onChange={handleChangeFile}
              /> */}
              <div className="custom-file mb-2">
                <input
                  type="file"
                  name="image"
                  className="custom-file-input cp-custom"
                  id="customFile"
                  onChange={handleChangeFile}
                />
                <label
                  className="custom-file-label"
                  htmlFor="customFile"
                  style={{ color: "#636c72" }}
                >
                  {input && input.image && typeof input.image === "object"
                    ? input.image.name
                    : "Pilih Berkas"}
                </label>
              </div>
              {campaign && campaign.image ? (
                <img
                  src={API_URL + campaign.image}
                  alt="Image"
                  class="img-thumbnail mt-3"
                />
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Video</Label>
            <Col md="10">
              <Input
                type="text"
                name="linkYoutube"
                placeholder="Link Video"
                value={input.linkYoutube}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Target</Label>
            <Col md="10">
              <Input
                type="number"
                name="target"
                placeholder="Target"
                value={input.target}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Nisbah</Label>
            <Col md="10">
              <Input
                type="number"
                name="nisbah"
                placeholder="Nisbah"
                value={input.nisbah}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Tenor</Label>
            <Col md="10">
              <Input
                type="number"
                name="tenor"
                placeholder="Tenor"
                value={input.tenor}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2">Tanggal Berakhir</Label>
            <Col md="10">
              <Input
                type="date"
                name="end_date_campaign"
                placeholder="Tanggal Berakhir Kampanye"
                value={input.end_date_campaign}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4 d-flex align-items-center" row>
            <Label md="2">Autosign Akad Peminjam</Label>
            <Col md="10" className="d-flex align-items-center">
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2 mr-3"
                onColor="#0ec6a2"
                onChange={() => {
                  setAutoSignature(!autoSignature)
                }}
                checked={autoSignature}
              />
            </Col>
          </FormGroup>
          <FormGroup className="mb-4 d-flex align-items-center" row>
            <Label md="2">Kampanye Terbatas Dengan Kode</Label>
            <Col md="10" className="d-flex align-items-center">
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2 mr-3"
                onColor="#0ec6a2"
                onChange={() => {
                  setInput({ ...input, secret_key: "" })
                  setIsPrivatePassword(!isPrivatePassword)
                }}
                checked={isPrivatePassword}
              />
              {isPrivatePassword && (
                <Input
                  type="text"
                  name="secret_key"
                  placeholder="Masukkan Kode Kampanye"
                  value={input.secret_key}
                  onChange={handleChange}
                  className="w-50"
                  required
                />
              )}
            </Col>
          </FormGroup>
          <FormGroup className="mb-4" row>
            <Label md="2" className="pt-0">
              Kampanye Terbatas dengan Menambahkan Pengguna
            </Label>
            <Col md="10" className="">
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2 mr-4"
                onColor="#0ec6a2"
                onChange={() => {
                  setIsPrivateHidden(!isPrivateHidden)
                }}
                checked={isPrivateHidden}
              />
              {isPrivateHidden && (
                <table class="table table-striped border w-50">
                  <thead>
                    <tr className="position-relative">
                      <th scope="col">Nama</th>
                      <th scope="col">Email</th>
                      <th scope="col">
                        <Button
                          size="sm"
                          className="position-absolute"
                          style={{
                            right: 20,
                            top: "50%",
                            transform: "translate( 0,-50%)",
                          }}
                          onClick={() => setModalUsers(true)}
                        >
                          +
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assign && assign.length > 0 ? (
                      assign.map(item => {
                        return (
                          <tr>
                            <td>
                              {item?.first_name} {item?.last_name}
                            </td>
                            <td>{item?.email}</td>
                            <td>
                              <i
                                className="bx bx-trash font-18 cursor-pointer"
                                onClick={() => handleDeleteUserPrivate(item)}
                              ></i>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan="3">Belum Ada Data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </Col>
          </FormGroup>
          <div className="row justify-content-end mb-3">
            <div className="col-10">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                onClick={handleSubmit}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignEdit
