import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classNames from "classnames"
import DataTable from "components/Common/DataTable"
import { MDBDataTable } from "mdbreact"
import {
  getApprovalHistoryPayout,
  getApprovalPayout,
  getCampaignSelector,
  getDetailApprovalPayout,
  getDetailApprovalPayoutHistories,
  getUsersAllDropdown,
  postApprovalPayout,
} from "helpers/backend_helpers"
import { handleStatusCampaign } from "helpers/method_helpers"
import moment from "moment"
import Toaster from "components/Common/Toast"
import Switch from "react-switch"
import Select from "react-select"
import AsyncSelect from "react-select/async"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const search_init = {
  campaign: "",
  user: "",
  total: "",
  batch: "",
  userApprove: "",

  order: null,
}

const ApprovalPayout = props => {
  const token = localStorage.getItem("token")

  const [activeTab, setActiveTab] = useState("1")
  const [loading, setLoading] = useState(false)
  const [approvalList, setApprovalList] = useState([])
  const [modalDetail, setModalDetail] = useState(false)
  const [selectPayout, setSelectPayout] = useState(null)
  const [repaymentTrxId, setRepaymentTrxId] = useState(null)

  const [historyPayout, setHistoryPayout] = useState([])

  const [selectApprove, setSelectApprove] = useState([])
  const [loadButt, setLoadButt] = useState(false)
  const [isAutoDebit, setIsAutoDebit] = useState(false)

  const [selectPayoutHistory, setSelectPayoutHistory] = useState(null)
  const [modalHistory, setModalHistory] = useState(false)

  //pagination
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)

  const [filter, setFilter] = useState(search_init)

  const [dataApprovalUsers, setDataApprovalUsers] = useState([])

  const [pin, setPin] = useState("")
  const [modalConfirm, setModalConfirm] = useState(false)
  const [passwordType, setPasswordType] = useState(true)

  useEffect(() => {
    switch (activeTab) {
      case "1":
        return getApproval()
      case "2":
        return getApprovalHistory()
      default:
        return
    }
  }, [activeTab, filter, page, perPage])

  useEffect(() => {
    setPage(1)
  }, [filter, activeTab])

  const getApproval = async () => {
    let batch = filter.batch
      ? `&batch[operator]==&batch[value]=${filter.batch}`
      : ""
    let campaign = filter.campaign
      ? `&campaign[uuid]=${filter.campaign?.value}`
      : ""
    let user = filter.user ? `&created_by[id]=${filter.user?.value}` : ""
    let query = `?per_page=${perPage}&page=${page}${batch}${campaign}${user}`
    setLoading(true)
    const data = await getApprovalPayout(token, query)
    setApprovalList(data.data)
    setTotalPage(data?.meta?.pagination?.last_page)
    setCurrentPage(data?.meta?.pagination?.current_page)
    setPagination(data?.meta?.pagination)
    setLoading(false)
  }

  const getApprovalHistory = async () => {
    let batch = filter.batch
      ? `&batch[operator]==&batch[value]=${filter.batch}`
      : ""
    let campaign = filter.campaign
      ? `&campaign[uuid]=${filter.campaign?.value}`
      : ""
    let user = filter.user ? `&created_by[id]=${filter.user?.value}` : ""
    let userApprove = filter.userApprove
      ? `&process_by[id]=${filter.userApprove?.value}`
      : ""
    let query = `?per_page=${perPage}&page=${page}${batch}${campaign}${user}${userApprove}`
    setLoading(true)
    const data = await getApprovalHistoryPayout(token, query)
    setHistoryPayout(data.data)
    setTotalPage(data?.meta?.pagination?.last_page)
    setCurrentPage(data?.meta?.pagination?.current_page)
    setPagination(data?.meta?.pagination)
    setLoading(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setFilter(search_init)
    }
  }

  const toggleModalDetail = async (item = null) => {
    if (item) {
      setLoading(true)
      const data = await getDetailApprovalPayout(item?.id, token)
      setDataApprovalUsers(data.data)
      setLoading(false)
    } else {
      setDataApprovalUsers([])
    }

    setSelectPayout(item)
    setSelectApprove([])
    setIsAutoDebit(false)
    setModalDetail(!modalDetail)
  }

  const toggleModalHistory = async (item = null) => {
    if (item) {
      setLoading(true)
      const data = await getDetailApprovalPayoutHistories(item?.id, token)
      setDataApprovalUsers(data.data)
      setLoading(false)
    } else {
      setDataApprovalUsers([])
    }

    setSelectPayoutHistory(item)
    setModalHistory(!modalHistory)
  }

  const toggleModalConfirm = () => {
    setPasswordType(true)
    setPin("")
    setModalConfirm(!modalConfirm)
  }

  const dataApproval = {
    columns: [
      {
        label: "Batch",
        field: "batch",
      },
      {
        label: "Nama Kampanye",
        field: "campaign",
      },
      {
        label: "Total Kotor",
        field: "total",
      },
      {
        label: "Total Bersih",
        field: "amountTotalBersih",
      },
      {
        label: "Dibuat Oleh",
        field: "created_by",
      },
      {
        label: "Detail Persetujuan",
        field: "detail",
      },
    ],
    rows: approvalList.map(item => {
      return {
        ...item,
        campaign: item.campaign?.title,
        created_by: item.created_by?.fullName,
        total: `Rp ${parseFloat(item.total).toLocaleString()}`,
        amountTotalBersih: `Rp ${parseFloat(
          item.amountTotalBersih
        ).toLocaleString()}`,
        detail: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalDetail(item)}
          >
            Detail
          </Button>
        ),
      }
    }),
  }

  const dataHistoryApproval = {
    columns: [
      {
        label: "Batch",
        field: "batch",
      },
      {
        label: "Nama Kampanye",
        field: "campaign",
      },
      {
        label: "Total Kotor",
        field: "total",
      },
      {
        label: "Total Bersih",
        field: "amountTotalBersih",
      },
      {
        label: "Dibuat Oleh",
        field: "created_by",
      },
      {
        label: "Disetujui Oleh",
        field: "process_by",
      },
      {
        label: "Detail Persetujuan",
        field: "detail",
      },
    ],
    rows: historyPayout.map(item => {
      return {
        ...item,
        campaign: item.campaign?.title,
        created_by: item.created_by?.fullName,
        process_by: item.process_by?.fullName,
        total: `Rp ${parseFloat(item.total).toLocaleString()}`,
        amountTotalBersih: `Rp ${parseFloat(
          item.amountTotalBersih
        ).toLocaleString()}`,
        detail: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalHistory(item)}
          >
            Detail
          </Button>
        ),
      }
    }),
  }

  const handleChecklist = item => {
    let dataApproved = selectApprove.find(
      el => el.repayment_transaction?.id === item.repayment_transaction?.id
    )
    if (dataApproved) {
      setSelectApprove(
        selectApprove.filter(
          el =>
            dataApproved.repayment_transaction?.id !==
            el.repayment_transaction?.id
        )
      )
      return
    }
    setSelectApprove([...selectApprove, item])
  }

  const handleApproved = async () => {
    if (!pin) return Toaster("error", "Maaf", "PIN harus diisi")
    const rejected =
      dataApprovalUsers.filter(
        item =>
          !selectApprove
            .map(el => el.repayment_transaction?.id)
            .includes(item.repayment_transaction?.id)
      ) || []

    const formData = new FormData()
    selectApprove.forEach((item, i) => {
      formData.append(
        `repayment_transactions[${i}][id]`,
        item.repayment_transaction?.id
      )
      formData.append(`repayment_transactions[${i}][isApprove]`, true)
    })

    rejected.forEach((item, i) => {
      formData.append(
        `repayment_transactions[${i + selectApprove.length}][id]`,
        item.repayment_transaction?.id
      )
      formData.append(
        `repayment_transactions[${i + selectApprove.length}][isApprove]`,
        false
      )
    })

    formData.append("pin", pin)

    setLoadButt(true)
    try {
      const res = await postApprovalPayout(
        formData,
        token,
        `?isAutoDebit=${isAutoDebit}`
      )
      toggleModalConfirm()
      toggleModalDetail(null)

      const failed = res?.status?.failed || []
      if (failed.length > 0) {
        Toaster(
          "error",
          "Maaf",
          failed.map(item => item.error_messages)
        )
      } else {
        Toaster("success", "Sukses", "Konfirmasi Berhasil")
      }
      getApproval()
    } catch (error) {
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    } finally {
      setLoadButt(false)
    }
  }

  const handleConfirmApprove = () => {
    toggleModalConfirm()
  }

  const renderModalDetail = () => {
    return (
      <Modal
        isOpen={modalDetail}
        role="dialog"
        autoFocus={true}
        scrollable={false}
        size="xl"
        className="modal-fullscreen"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              toggleModalDetail(null)
            }}
          >
            {selectPayout?.campaign?.title}
          </ModalHeader>
          <ModalBody>
            <table class="table table-striped border">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      checked={
                        selectApprove.length > 0 &&
                        selectApprove.length === dataApprovalUsers.length
                      }
                      onChange={() => {
                        if (
                          selectApprove.length > 0 &&
                          selectApprove.length === dataApprovalUsers.length
                        ) {
                          setSelectApprove([])
                        } else {
                          setSelectApprove(dataApprovalUsers)
                        }
                      }}
                    />
                  </th>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Nama Lender</th>
                  <th scope="col">A/N Rekening</th>
                  <th scope="col">Bank</th>
                  <th scope="col">Rekening</th>
                  <th scope="col">Total Modal</th>
                  <th scope="col">Total DPP</th>
                  <th scope="col">Total (Modal + DPP)</th>
                  <th scope="col">Total Pajak</th>
                  <th scope="col">Total Transfer</th>
                </tr>
              </thead>
              <tbody>
                {dataApprovalUsers.length > 0 ? (
                  dataApprovalUsers.map(item => {
                    return (
                      <>
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectApprove.find(
                                el =>
                                  item.repayment_transaction?.id ===
                                  el.repayment_transaction?.id
                              )}
                              onChange={() => {
                                handleChecklist(item)
                              }}
                            />
                          </td>
                          <td>
                            {moment(item.repayment_transaction?.date).format(
                              "DD MMM YYYY"
                            )}
                          </td>

                          <td
                            className="cursor-pointer hover-underline"
                            onClick={() => {
                              if (
                                repaymentTrxId ===
                                item.repayment_transaction?.id
                              ) {
                                setRepaymentTrxId(null)
                              } else {
                                setRepaymentTrxId(
                                  item.repayment_transaction?.id
                                )
                              }
                            }}
                          >
                            {item.repayment_transaction?.fullName}
                          </td>
                          <td>
                            {
                              item.repayment_transaction?.bankInformation
                                ?.accountName
                            }
                          </td>
                          <td>
                            {
                              item.repayment_transaction?.bankInformation
                                ?.accountBank
                            }
                          </td>
                          <td>
                            {
                              item.repayment_transaction?.bankInformation
                                ?.accountNumber
                            }
                          </td>
                          <td>
                            Rp{" "}
                            {parseFloat(
                              item.repayment_transaction?.modal_payment
                            ).toLocaleString()}
                          </td>
                          <td>
                            Rp{" "}
                            {parseFloat(
                              item.repayment_transaction?.dpp
                            ).toLocaleString()}
                          </td>
                          <td>
                            Rp{" "}
                            {parseFloat(
                              item.repayment_transaction?.amount_total
                            ).toLocaleString()}
                          </td>
                          <td>
                            Rp{" "}
                            {parseFloat(
                              item.repayment_transaction?.total_tax
                            ).toLocaleString()}
                          </td>

                          <td>
                            Rp{" "}
                            {parseFloat(
                              item.repayment_transaction?.received_by_investor
                            ).toLocaleString()}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <td colSpan="11" className="p-0">
                            <Collapse
                              isOpen={
                                repaymentTrxId ===
                                item.repayment_transaction?.id
                              }
                            >
                              <div
                                className="px-5 py-3"
                                style={{ backgroundColor: "#eee" }}
                              >
                                <p className="font-weight-bold">
                                  Riwayat Pengembalian
                                </p>
                                <table className="table border mb-2">
                                  <thead>
                                    <tr>
                                      <th>Tanggal</th>
                                      <th>A/N Rekening</th>
                                      <th>Bank</th>
                                      <th>Rekening</th>
                                      <th>Total Modal</th>
                                      <th>Total DPP</th>
                                      <th>Total Pajak</th>
                                      <th>Total Transfer</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item?.repayment_details?.length > 0 ? (
                                      item.repayment_details.map(inItem => (
                                        <tr className="bg-white">
                                          <td>
                                            {inItem.date
                                              ? moment(item.date).format(
                                                  "DD MMM YYYY"
                                                )
                                              : "-"}
                                          </td>
                                          <td>
                                            {
                                              inItem.bankInformation
                                                ?.accountName
                                            }
                                          </td>
                                          <td>
                                            {
                                              inItem.bankInformation
                                                ?.accountBank
                                            }
                                          </td>
                                          <td>
                                            {
                                              inItem.bankInformation
                                                ?.accountNumber
                                            }
                                          </td>
                                          <td>
                                            Rp{" "}
                                            {parseFloat(
                                              inItem.modal_payment
                                            ).toLocaleString()}
                                          </td>
                                          <td>
                                            Rp{" "}
                                            {parseFloat(
                                              inItem.dpp
                                            ).toLocaleString()}
                                          </td>
                                          <td>
                                            Rp{" "}
                                            {parseFloat(
                                              inItem.total_tax
                                            ).toLocaleString()}
                                          </td>
                                          <td>
                                            Rp{" "}
                                            {parseFloat(
                                              inItem.received_by_investor
                                            ).toLocaleString()}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan="8">
                                          Belum Ada Riwayat Pengembalian
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      </>
                    )
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan="11">Belum Ada Pengembalian</td>
                  </tr>
                )}
              </tbody>
            </table>

            <p className="">
              <span className="text-danger">*</span> Checklist untuk disetujui
              dan Unchecklist untuk ditolak
            </p>

            <div className="d-flex align-items-center mb-2">
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2 mr-2"
                onColor="#0ec6a2"
                onChange={() => {
                  setIsAutoDebit(!isAutoDebit)
                }}
                checked={isAutoDebit}
              />

              <p className="mt-1 font-weight-bold">Auto Debit dari Escrow</p>
            </div>

            <Button
              color="primary"
              className={`w-100`}
              onClick={handleConfirmApprove}
            >
              Konfirmasi
            </Button>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderModalConfirm = () => {
    return (
      <Modal
        isOpen={modalConfirm}
        role="dialog"
        autoFocus={true}
        scrollable={false}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              toggleModalConfirm()
            }}
          >
            Konfirmasi Persetujuan
          </ModalHeader>
          <ModalBody>
            <table className="table table-striped border">
              <thead>
                <tr>
                  <th>Disetujui</th>
                  <th>Ditolak</th>
                  <th>Total Transfer</th>
                  <th>Metode Transfer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectApprove.length}</td>
                  <td>
                    {
                      dataApprovalUsers.filter(
                        item =>
                          !selectApprove
                            .map(el => el.repayment_transaction?.id)
                            .includes(item.repayment_transaction?.id)
                      )?.length
                    }
                  </td>
                  <td>
                    Rp{" "}
                    {selectApprove
                      .map(item =>
                        parseFloat(
                          item.repayment_transaction?.received_by_investor
                        )
                      )
                      .reduce(
                        (previousValue, currentValue) =>
                          previousValue + currentValue,
                        0
                      )
                      .toLocaleString()}
                  </td>
                  <td>{isAutoDebit ? "Auto Transfer" : "Manual Transfer"}</td>
                </tr>
              </tbody>
            </table>

            <div>
              <FormGroup>
                <Label>PIN Konfirmasi</Label>
                <InputGroup>
                  <Input
                    placeholder="Masukkan PIN"
                    type={passwordType ? "password" : "text"}
                    value={pin}
                    onChange={e => setPin(e.target.value)}
                    required
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="d-flex justify-content-center align-items-center cursor-pointer"
                    style={{
                      border: "1px solid #ced4da",
                      backgroundColor: "#eee",
                    }}
                    onClick={() => setPasswordType(!passwordType)}
                  >
                    <i
                      className={`${
                        passwordType ? "fas fa-eye" : "fas fa-eye-slash"
                      } px-3 py-2`}
                    ></i>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </div>

            <Button
              color="primary"
              className={`w-100 ${loadButt && "cursor-not-allowed"}`}
              onClick={handleApproved}
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Konfirmasi"
              )}
            </Button>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderModalHistory = () => {
    return (
      <Modal
        isOpen={modalHistory}
        role="dialog"
        autoFocus={true}
        scrollable={false}
        size="lg"
        className="modal-fullscreen"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              toggleModalHistory(null)
            }}
          >
            {selectPayoutHistory?.campaign?.title}
          </ModalHeader>
          <ModalBody>
            <table class="table table-striped border">
              <thead>
                <tr>
                  <th scope="col">Tanggal</th>
                  <th scope="col">Nama Lender</th>
                  <th scope="col">A/N Rekening</th>
                  <th scope="col">Bank</th>
                  <th scope="col">Rekening</th>
                  <th scope="col">Total Modal</th>
                  <th scope="col">Total DPP</th>
                  <th scope="col" className="text-nowrap">
                    Total Pajak
                  </th>
                  <th scope="col" className="text-nowrap">
                    Total Transfer
                  </th>
                  <th scope="col" className="text-nowrap">
                    Metode Transfer
                  </th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {dataApprovalUsers.length > 0 ? (
                  dataApprovalUsers.map(item => {
                    return (
                      <>
                        <tr>
                          <td className="text-nowrap">
                            {moment(item.date).format("DD MMM YYYY")}
                          </td>
                          <td>{item.fullName}</td>
                          <td>{item.bankInformation?.accountName}</td>
                          <td>{item.bankInformation?.accountBank}</td>
                          <td>{item.bankInformation?.accountNumber}</td>
                          <td>
                            Rp {parseFloat(item.modal_payment).toLocaleString()}
                          </td>
                          <td>Rp {parseFloat(item.dpp).toLocaleString()}</td>
                          <td>
                            Rp {parseFloat(item.total_tax).toLocaleString()}
                          </td>
                          <td>
                            Rp{" "}
                            {parseFloat(
                              item.received_by_investor
                            ).toLocaleString()}
                          </td>
                          <td>{item.paymentMethod || "-"}</td>
                          <td>
                            {!Boolean(item.isApprove) ? (
                              <Badge color="danger">Ditolak</Badge>
                            ) : (
                              <Badge color="primary">Disetujui</Badge>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan="11">Belum Ada Riwayat</td>
                  </tr>
                )}
              </tbody>
            </table>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const handleOrder = obj => {
    setFilter({
      ...filter,
      order: obj,
    })
  }

  const loadOptions = (inputValue, callback) => {
    let query = `?keySearch=title&search=${inputValue}`
    setTimeout(async () => {
      const data = await getCampaignSelector(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const loadOptionsUsers = (inputValue, callback) => {
    let query = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAllDropdown(token, query)
      callback(
        data.data.map(item => {
          return { label: item.email, value: item.uuid }
        })
      )
    })
  }

  // if (loading) return <Loading title="Persetujuan" breadcrumbItem="Proyek" />

  return (
    <React.Fragment>
      {renderModalDetail()}
      {renderModalHistory()}
      {renderModalConfirm()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Persetujuan" breadcrumbItem="Pengembalian" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Persetujuan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="1">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Batch</Label>
                            <Input
                              value={filter.batch}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  batch: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Kampanye</Label>
                            <AsyncSelect
                              cacheOptions
                              isClearable
                              loadOptions={loadOptions}
                              defaultOptions
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  campaign: e,
                                })
                              }}
                              value={filter.campaign}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <Label>Dibuat Oleh</Label>
                            <AsyncSelect
                              cacheOptions
                              isClearable
                              loadOptions={loadOptionsUsers}
                              defaultOptions
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  user: e,
                                })
                              }}
                              value={filter.user}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <DataTable
                        table={dataApproval}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        loading={loading}
                        order={filter.order}
                        orderClick={handleOrder}
                      />
                    </TabPane>

                    <TabPane tabId="2">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Batch</Label>
                            <Input
                              value={filter.batch}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  batch: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Kampanye</Label>
                            <AsyncSelect
                              cacheOptions
                              isClearable
                              loadOptions={loadOptions}
                              defaultOptions
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  campaign: e,
                                })
                              }}
                              value={filter.campaign}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <Label>Dibuat Oleh</Label>
                            <AsyncSelect
                              cacheOptions
                              isClearable
                              loadOptions={loadOptionsUsers}
                              defaultOptions
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  user: e,
                                })
                              }}
                              value={filter.user}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Disetujui Oleh</Label>
                            <AsyncSelect
                              cacheOptions
                              isClearable
                              loadOptions={loadOptionsUsers}
                              defaultOptions
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  userApprove: e,
                                })
                              }}
                              value={filter.userApprove}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <DataTable
                        table={dataHistoryApproval}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        loading={loading}
                        order={filter.order}
                        orderClick={handleOrder}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ApprovalPayout
