import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import React from "react"
import { Button, Col, FormGroup, Label } from "reactstrap"

const StepOne = ({
  handleSubmitStepOne,
  handleChangeFile,
  loadButt,
  masterData,
  handleNegara,
  handleRegion,
  project,
  file
}) => {
  return (
    <AvForm
      className="form-horizontal"
      onValidSubmit={(e, v) => {
        handleSubmitStepOne(e, v)
      }}
    >
      <FormGroup row>
        <Label sm={3}>Nama Proyek<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="title"
            type="text"
            placeholder="Nama Proyek"
            value={project.title}
            validate={{
              required: {value: true, errorMessage: 'Nama Proyek harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Badan Usaha<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="business_entity_id"
            type="select"
            value={project.project_company.business_entity.uuid}
            validate={{
              required: {value: true, errorMessage: 'Badan Usaha harus diisi'},
            }}
            options={masterData.business_entity}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Nama Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="company_name"
            type="text"
            placeholder="cth. PT Multi Guna Jaya"
            helpMessage="Mohon sertakan PT atau CV pada nama perusahaan anda"
            value={project.project_company.company_name}
            validate={{
              required: {value: true, errorMessage: 'Nama Perusahaan harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Sektor Industri<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="sector_industry_id"
            type="select"
            value={project.sector_industry.uuid}
            validate={{
              required: {value: true, errorMessage: 'Sektor Industri harus diisi'},
            }}
            options={masterData.sector_industry}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Tahun Berdiri<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="company_since"
            type="text"
            placeholder="ex. 2004"
            value={project.project_company.company_since}
            validate={{
              required: {value: true, errorMessage: 'Tahun Berdiri harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Alamat Kantor<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="company_full_address"
            type="textarea"
            placeholder="Nama jalan / Gedung / Tempat"
            value={project.project_company.company_address.full_address}
            validate={{
              required: {value: true, errorMessage: 'Alamat Kantor harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Provinsi<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="province_id"
            type="select"
            value={project.project_company.company_address.province.uuid}
            validate={{
              required: {value: true, errorMessage: 'Provinsi harus diisi'},
            }}
            options={masterData.province}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Kota<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="city_id"
            type="select"
            value={project.project_company.company_address.city.uuid}
            onChange={handleRegion}
            validate={{
              required: {value: true, errorMessage: 'Kota harus diisi'},
            }}
            options={masterData.city}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Kecamatan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="district_id"
            type="select"
            value={project.project_company.company_address.district.uuid}
            onChange={handleRegion}
            validate={{
              required: {value: true, errorMessage: 'Kecamatan harus diisi'},
            }}
            options={masterData.district}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Kelurahan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="sub_district_id"
            type="select"
            value={project.project_company.company_address.sub_district.uuid}
            onChange={handleRegion}
            validate={{
              required: {value: true, errorMessage: 'Kelurahan harus diisi'},
            }}
            options={masterData.sub_district}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Negara<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="country_id"
            type="select"
            value={handleNegara(masterData.country)}
            disabled
            validate={{
              required: {value: true, errorMessage: 'Negara harus diisi'},
            }}
            options={masterData.country}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Telepon Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="company_telp"
            type="text"
            placeholder="ex. 081233445566"
            validate={{
              required: {value: true, errorMessage: 'Telepon Perusahaan harus diisi'},
            }}
            value={project.project_company.company_telp}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Status Kepemilikan Kantor<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="property_ownership_id"
            type="select"
            value={project.project_company.property_ownership.uuid}
            validate={{
              required: {value: true, errorMessage: 'Status Kepemilikan Kantor harus diisi'},
            }}
            options={masterData.property_ownership}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Total Asset<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="total_asset"
            type="number"
            placeholder="Total Asset"
            helpMessage="Dalam Rupiah"
            value={parseInt(project.project_company.total_asset)}
            validate={{
              required: {value: true, errorMessage: 'Total Asset harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah Profile Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_company_profile"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            // validate={{
            //   required: {value: true, errorMessage: 'Unggah Profile Perusahaan harus diisi'},
            // }}
            value={file.file_company_profile}
          />
          <Button 
            className="btn-sm" 
            disabled={!project.project_company.file_company_profile}
            onClick={() => window.open(`${API_URL}${project.project_company.file_company_profile}`, "_blank") }
          >
            <i className="fas fa-eye"></i>
          </Button>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Website Perusahaan</Label>
        <Col sm={9}>
          <PInput 
            name="company_website"
            type="text"
            placeholder="https://"
            value={project.project_company.company_website}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Klien (Pemberi Pekerjaan)</Label>
        <Col sm={9}>
          <PInput 
            name="client_name"
            type="text"
            placeholder="Nama Perusahaan Klien"
            value={project.project_detail.client_name}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Jumlah Dana Diajukan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="submission_total"
            type="number"
            placeholder="Rp"
            helpMessage="Rupiah"
            value={parseInt(project.project_detail.submission_total)}
            validate={{
              required: {value: true, errorMessage: 'Jumlah Pengajuan harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Tenor Proyek <span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="tenor"
            type="number"
            placeholder="Tenor dalam bulan"
            helpMessage="Bulan"
            value={project.project_detail.tenor}
            validate={{
              required: {value: true, errorMessage: 'Tenor Proyek harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Kontrak / Purchase Order <span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_contract"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            // value={inputOne.title}
            onChange={handleChangeFile}
            // validate={{
            //   required: {value: true, errorMessage: 'Kontrak / Purchase Order harus diisi'},
            // }}
            value={file.file_contract}
          />
          <Button 
            className="btn-sm" 
            disabled={!project.project_detail.file_contract}
            onClick={() => window.open(`${API_URL}${project.project_detail.file_contract}`, "_blank") }
          >
            <i className="fas fa-eye"></i>
          </Button>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Deskripsi Proyek<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="description"
            type="textarea"
            placeholder="Deskripsikan manfaat apa saja dari proyek anda"
            value={project.project_detail.description}
            validate={{
              required: {value: true, errorMessage: 'Deskripsi Proyek harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Jumlah Proyek dikerjakan 12 bulan terakhir<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="total_project_last_year"
            type="number"
            placeholder="Total proyek yang pernah dikerjakan"
            validate={{
              required: {value: true, errorMessage: 'Jumlah Proyek harus diisi'},
            }}
            value={project.project_company.total_project_last_year}
          />
        </Col>
      </FormGroup>
      {/* <FormGroup row>
        <Label sm={3}>Rekening Koran 3-6 Bulan Terakhir<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="bank_account_papper_last_three_month"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            // validate={{
            //   required: {value: true, errorMessage: 'Rekening koran harus diisi'},
            // }}
          />
          <Button 
            className="btn-sm" 
            onClick={() => window.open(`${API_URL}${project.project_company.bank_account_papper_last_three_month}`, "_blank") }
          >
            <i className="fas fa-eye"></i>
          </Button>
        </Col>
      </FormGroup> */}
      <FormGroup row>
        <Label sm={3}>Rekening Koran 3 - 6 Bulan Terakhir<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="bank_account_papper_last_six_month"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            // validate={{
            //   required: {value: true, errorMessage: 'Rekening koran harus diisi'},
            // }}
            value={file.bank_account_papper_last_six_month}
          />
          <Button 
            className="btn-sm" 
            disabled={!project.project_company.bank_account_papper_last_six_month}
            onClick={() => window.open(`${API_URL}${project.project_company.bank_account_papper_last_six_month}`, "_blank") }
          >
            <i className="fas fa-eye"></i>
          </Button>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Ekspektasi Kontribusi Modal Pendana<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="capital_contribution"
            type="number"
            placeholder="Kontribusi Modal Pendana"
            helpMessage="Dalam Persen"
            helpMessage="Dalam Persen (Max 95%)"
            validate={{
              required: {value: true, errorMessage: 'Kontribusi Modal Pendana harus diisi'},
              max: {value: 95, errorMessage: 'Maksimal 95 %'},
              min: {value: 1, errorMessage: 'Minimal 1 %'}
            }}
            value={project.capital_contribution}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Total Karyawan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="total_employee"
            type="number"
            placeholder="Total Karyawan"
            // helpMessage="Dalam Persen"
            validate={{
              required: {value: true, errorMessage: 'Total Karyawan harus diisi'},
            }}
            value={project.project_company.total_employee}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Nama Lengkap PIC<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="pic_full_name"
            type="text"
            placeholder="Nama Lengkap PIC"
            value={project.project_pic[0].full_name}
            validate={{
              required: {value: true, errorMessage: 'Nama Lengkap PIC harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Jabatan PIC di Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="pic_position"
            type="text"
            placeholder="Jabatan PIC di Perusahaan"
            value={project.project_pic[0].position}
            validate={{
              required: {value: true, errorMessage: 'Jabatan di Perusahaan harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>No HP PIC<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="pic_phone_number"
            type="text"
            placeholder="No HP. PIC"
            value={project.project_pic[0].phone_number}
            validate={{
              required: {value: true, errorMessage: 'No Handphone PIC harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Email PIC<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="pic_email"
            type="text"
            placeholder="Email"
            // helpMessage="Email hanya digunakan untuk keperluan data"
            value={project.project_pic[0].email}
            validate={{
              required: {value: true, errorMessage: 'Email PIC harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      {project.status === 2 || project.status === 3 ? 
        <>
          <FormGroup row>
            <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
            <Col sm={9}>
              <PInput 
                name="agreement"
                type="checkbox"
                label="Dengan ini saya menyatakan bahwa semua data yang diberikan
                adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
                data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
                pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
                // placeholder="Email"
                // value={inputOne.agreement}
                validate={{
                  required: {value: true, errorMessage: 'Persetujuan Pemohon harus diisi'},
                }}
              />
            </Col>
          </FormGroup>
          <div className="row justify-content-end mb-3">
            <div className="col-9">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan Kembali'}
              </button>

            </div>
          </div>
        </> : null
      }
    </AvForm>
  )
}

export default StepOne