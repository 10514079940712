//REGISTER
export const POST_REGISTER = "/register"

//AUTH
export const POST_LOGIN = "/login"
export const POST_LOGOUT = "/user/logout"

//USER LOGIN
export const GET_USER_LOGIN = "/user/profile"

export const GET_PROFILE = "/user/profile"

// USER KYC
export const GET_PROVINCE = "/master/region/populate/province"
export const GET_CITY_ALL = "/master/region/populate/cities"
export const GET_DISTRICT_ALL = "/master/region/populate/districts"
export const GET_SUB_DISTRICT_ALL = "/master/region/populate/sub-districts"

export const GET_CITY = "/master/region/populate/cities?province_id="
export const GET_DISTRICT = "/master/region/populate/districts?city_id="
export const GET_SUB_DISTRICT =
  "/master/region/populate/sub-districts?district_id="

export const GET_BANK = "/master/banks/populate"

export const GET_TITLE = "/master/titles/populate"

export const GET_EDUCATION = "/master/education/populate"

export const GET_JOB = "/master/job/populate"

export const GET_JOB_SECTOR = "/master/job/populate/sector"

export const GET_MARRIED = "/master/married-status/populate"

export const GET_GENDER = "/master/genders/populate"

export const GET_MONTH_IN_COME = "/master/income/populate"

export const GET_RELIGION = "/master/religion/populate"

export const GET_SOURCE_FUNDING = "/master/source-funding/populate"

export const POST_KYC_1 = "/user/kyc/step-one"

export const POST_KYC_2 = "/user/kyc/step-two"

export const POST_KYC_3 = "/user/kyc/step-three"

export const POST_KYC_ALL = "/user/edit"

export const GET_USERS_KYC = "/moderator/kyc/populate"

export const GET_REASON = "/master/reasons/populate"

export const POST_APPROVAL = "/moderator/kyc/answer"

export const PUT_BANK_ACCOUNT = "/moderator/user/"

// Master data Project
export const GET_BUSSINES_ENTITY = "/master/business/populate"
export const GET_PROJECT_TYPE = "/master/project-type/populate"
export const GET_PROJECT_TYPE_DETAIL = "/master/project-type/detail/populate"
export const GET_SECTOR_INDUSTRY = "/master/sector-industries/populate"
export const GET_PROPERTY_OWNERSHIP = "/master/property-ownership/populate"
export const GET_COUNTRY = "/master/countries/populate"
export const GET_SUBMIT_ACTOR = "/master/submission-actors/populate"
export const GET_ORGANIZATION = "/master/organitation/populate"

export const POST_UKM_ONE = "/projects/create/ukm"
export const POST_UKM_TWO = "/projects/ukm/step-two/"
export const POST_PROPERTY = "/projects/create/property"

export const GET_PROJECT = "/projects/populate"

export const GET_PROJECT_MODERATOR = "/projects/moderator/populate"

export const APPROVE_PROJECT = "/moderator/project/answer/"

export const GET_CAMPAIGN_ALL = "/campaigns/populate"

export const GET_CAMPAIGN = "/campaigns/show/"
export const GET_CAMPAIGN_ADMIN = "/moderator/campaigns/show/"

export const GET_AKAD_TYPE = "/master/akad-type/populate"

export const PUT_CAMPAIGN = "/campaigns/edit/"

export const POST_PUBLISH_CAMPAIGN = "/campaigns/publish/"
export const POST_UNPUBLISH_CAMPAIGN = "/campaigns/unpublish/"

export const POST_FULLY_FUNDED = "/campaigns/fully-funded/"

export const POST_DESCRIPTION = "/description/create"
export const POST_GALLERY = "/gallery/create"
export const POST_UPDATE = "/update/create"
export const GET_TITLE_DESCRIPTION = "/master/description/title/populate"

// Akad
export const GET_AKAD_ALL = "/akad/populate"
export const GET_AKAD_TITLE = "/master/akad-title/populate"
export const GET_IMPLEMENT_CONTRACT = "/master/implementation-contract/populate"
export const POST_AKAD_MUSYARAKAH = "/akad/edit/akadMusyarakah/"
export const POST_AKAD_WAKALAH = "/akad/edit/akadWakalah/"
export const POST_AKAD_MURABAHAH = "/akad/edit/akadMurabahah/"

export const GET_AKAD_BY_CAMPAIGN = "/akad/populate/campaign/"

export const GET_AKAD_READY = "/akad/populate/ready-akad"
export const GET_AKAD_PUBLISH = "/akad/populate/ready-publish"
export const GET_AKAD_ACTIVE = "/akad/populate/active"
export const GET_AKAD_NONACTIVE = "/akad/populate/non-active"

// Campagin Client
export const GET_CAMPAIGN_ACTIVE = "/campaign/published"

// BNI VA
export const POST_CREATE_VA = "/bni/create-va"

//Portfolio
export const GET_PORTOFOLIO = "/portofolio/populate"
export const GET_TRANSACTION_BY_CAMPAIGN = "/portofolio/campaign/"

export const GET_TRX = "/portofolio/transaction/"

export const POST_DOC_WAKALAH = "/privy/document/wakalah/upload/"
export const POST_DOC_MUSYARAKAH = "/privy/document/musyarakah/upload/"
export const POST_DOC_BILUJROH = "/privy/document/bil-ujrah/upload/"
export const POST_DOC_ADDENDUM = (campaignId, trxId, addendumId) =>
  `/privy/document/addendum/${addendumId}/upload/campaign/${campaignId}/transaction/${trxId}`
export const GET_WAKALAH_BILUJRAH = "/campaigns/"

export const GET_PORTOFOLIO_CAMPAIGN = "/portofolio/group-populate"
export const GET_PORTOFOLIO_DETAIL = "/portofolio/campaign/"

// lupa password
export const POST_FORGET_PASSWORD = "/user/forgot-password"
// export const POST_EDIT_PASSWORD = ""

// Payout
export const GET_TRX_CAMPAIGN = "/payout/transaction/"
export const GET_REPAYMENT_TYPE = "/master/repayment-type/populate"
export const POST_PAYOUT = "/payout/"
export const GET_PAYOUT_USER = "/payout/user/"
export const GET_TRX_CAMPAIGN_INVESTMENT = "/moderator/transaction/"
export const POST_IMPORT_PAYOUT = "/moderator/import"
export const GET_TRX_HISTORIES = "/payout/transaction/"
export const POST_EDIT_PAYOUT = "/payout/repayment-transaction/"
export const POST_UPDATE_PAYOUT = "/payout/update/repayment"
export const GET_UPDATE_TEMPLATE = "/payout/update/repayment/template"
export const GET_COLLECTION_ID = "/collection/repayment/campaign/"

// users
export const GET_USER_ALL = "/user/populate"
export const GET_ROLE_ALL = "/master/roles/populate"
// export const POST_ROLE_USER = ""

//admin
export const GET_APPROVAL_HISTORY = "/moderator/kyc/histories"
export const GET_APPROVAL_HISTORY_PROYEK = "/moderator/project/histories"

// edit apply project
export const GET_PROJECT_EDIT = "/projects/show/"
export const POST_UKM_EDIT = "/projects/ukm/edit/"
export const POST_PROPERTY_EDIT = "/projects/property/edit/"

//notification
export const GET_NOTIFICATION = "/notifications/get"

//check booked trx
export const GET_CHECK_BOOKED = "/bni/check/"

// campaign
export const GET_PROJECT_APPROVED = "/projects/populate/list-approved"
export const POST_CREATE_CAMPAIGN = "/campaigns/createByProject/"

// user kyc
export const GET_USER_KYC_NOT_VERIFIED = "/moderator/kyc/populate/pre-regist"

// dasboard user
export const GET_DASHBOARD_USER = "/dashboard/populate"

//risk
export const GET_RISK = "/risk/populate"
export const POST_RISK_APPROVE = "/risk/create/"
export const GET_HISTORY_RISK = "/risk/histories"
export const GET_RISK_PROPERTY = "/risk/populate/property"

// bisnis
export const GET_RESULT_RISK = "/projects/populate/result"
export const POST_BISNIS_DECISION = "/moderator/project/final-decision/"
export const GET_HISTORY_BISNIS_DECISION = "/projects/business/histories"
export const GET_HISTORY_BISNIS_DECISION_HISTORIES =
  "/moderator/project/business/histories"

// get campaign by project id
export const GET_CAMPAIGN_BY_PROJECT = "/campaigns/project/"
export const GET_LIST_AKAD_MUSYARAKAH = "/campaigns/"

// chat
export const GET_FRIENDS = "/chatting/getFriends"
export const GET_SESSION = "/chatting/session/populate"
export const POST_SESSION = "/chatting/session/create"
export const POST_MESSAGES = "/chatting/send/"
export const POST_GET_CHAT = "/chatting/session/"
export const POST_READ_CHAT = "/chatting/session/"
export const POST_CLEAR_SESSION = "/chatting/session/"

// collection
export const GET_ALL_OWNED = "/collection/populate/owned"
export const GET_ALL_COLLECTION = "/collection/populate"
export const GET_COLLECTION_BY_CAMPAIGN = "/collection/populate/campaign/"
export const POST_COLLECTION = "/collection/create"
export const POST_UPDATE_COLLECTION = "/collection/update/"
export const DEL_COLLECTION = "/collection/delete/"
export const POST_VA_COLLECTION = `/collection/create/va-number${
  process.env.REACT_APP_API_URL_VIRTUAL_ACCOUNT != "bni" ? "/smart-billing" : ""
}`
export const GET_VA_NUMBER = "/collection/populate/owned/va-number"
export const GET_VA_BY_CAMPAIGN = "/collection/populate/va-number/campaign/"
export const POST_IMPORT_COLLECTION = "/collection/import"
export const GET_TEMPLATE_COLLECTION = "collection/template/import"
export const CREATE_VA_RDL_COLLECTION = "/v2/collection/create/va-number"

// Scoring
export const GET_ALL_SUBCATEGORY_ONE = "/analyst/populate"
export const GET_ALL_SUBCATEGORY_ONE_ON_TAB_RISK =
  "/v2/projects/moderator/analyst"
export const GET_ALL_SUBCATEGORY_PROPERTY = "/analyst/property/populate"
export const POST_SCORING_ONE = "/analyst/ukm/"
export const GET_CATEGORY_TWO = "/analyst/populate/step-two"
export const POST_SCORING_TWO = "/analyst/ukm/"
export const GET_SCORING_RESULT = "/v2/projects/moderator/analyst/"
export const GET_SCORING_RESULT_PROPERTY = "/analyst/property/"

export const GET_ANSWER_SCORING = "/analyst/master/populate/"

// impersonate
export const POST_IMPERSONATE = "/user/impersonate/"

// edit campaign
export const POST_EDIT_CAMPAIGN = "/campaigns/"

// populate campaign selector
export const GET_CAMPAIGN_SELECTOR = "/campaigns/v2/populate/selector"

// Change Mail
export const POST_CHANGE_MAIL = "/user/edit/verification/email"
export const POST_CHANGE_MAIL_VEFIF = "/user/edit/email"

// Disbursement
export const DISBURSE = "/campaigns/"

// Delete Campaign and Cancel Campaign
export const DEL_CAMPAIGN = "/campaigns/delete/"
export const CANCEL_CAMPAIGN = "/campaigns/cancel/"

// verify phone number
export const POST_CREATE_CODE_SMS = "/sms/phone/send"
export const POST_CLAIM_CODE_SMS = "/verify/phone"

// List Akad for Admin
export const GET_AKADS_ADMIN = "/campaigns/"

// trx
export const POST_PLEDGE = `/bni/pledge`
export const POST_PLEDGE_CREATE_VA = `/${process.env.REACT_APP_API_URL_VIRTUAL_ACCOUNT}/pledge`

// meeting proyek
export const GET_QUESTION_POPULATE = "/master/business-meeting/populate"
export const POST_ANSWER_MEET = "/master/business-meeting/"
export const GET_RESULT_MEET = "/master/business-meeting/"

// get Score all
export const GET_SCORE_ALL = "/analyst/project/"

// Refund
export const REFUND = "/refund/"
export const GET_REFUND = "/payout/transaction/"

// order campaign
export const GET_ORDER_CAMPAIGN = "/campaigns/order/populate"
export const POST_ORDER_CAMPAIGN = "/campaigns/order/edit"

// user verified
export const GET_USER_VERIFIED = "/top/active"

// additional data BD
export const GET_ADDITIONAL = "/projects/additional/populate"
export const POST_ADDITIONAL = "/projects/additional/"

// unpublish campaign fullyfunded
export const POST_UNPUBLISH_CAMPAIGN_FULLY = "/campaigns/unpublish-collected/"

// populate notif
export const GET_NOTIFICATIONS = "/notifications/populate"
export const READ_NOTIFICATION = "/notifications/read/"

// voucher
export const GET_VOUCHER = "/code-voc/populate"
export const POST_VOUCHER = "/code-voc/create"
export const UPDATE_VOUCHER = "/code-voc/update/"
export const GET_VOUCHER_CLAIMED = "/code-voc/campaign/"

//code register email
export const POST_CODE_REGISTER_EMAIL = "/email-verification/send/code"
export const CLAIM_CODE_REGISTER_EMAIL = "/email-verification/claim"

// Carousel
export const POST_CAROUSEL = "/carousel/create"
export const UPDATE_CAROUSEL = "/carousel/update/"
export const GET_CAROUSEL_ADMIN = "/carousel/populate"
export const GET_CAROUSEL = "/carousel/published"

// Group Chat
export const POST_GROUP = "/chat/group/create"
export const GET_GROUP = "/chat/populate/group"
export const POST_MESSAGE_GROUP = "/chat/group/new-message"
export const GET_MESSAGE_GROUP = "/chat/group/"

// private chat
export const GET_USERS_ALL = "/user/getAll"
export const SEND_MESSAGE_PRIVATE = "/chat/private/new-message"
export const GET_MESSAGE_PRIVATE = "/chat/private/populate"
export const READ_MESSAGE_PRIVATE = "/chat/private/"

// Blog
export const GET_BLOG_ALL = "admin/post/populate"
export const CREATE_BLOG = "/post/create"
export const UPDATE_BLOG = "/post/edit/"
export const GET_BLOG_BY_UUID = "/post/show/"
export const GET_BLOG_BY_SLUG = "/post/show/slug/"
export const DELETE_BLOG = "/post/delete/"
export const CREATE_CATEGORY = "/post/category/create"
export const UPDATE_CATEGORY = "/post/category/edit/"
export const DELETE_CATEGORY = "/post/category/delete/"
export const GET_CATEGORY = "/post/category/populate"
export const GET_CATEGORY_ADMIN = "admin/post/category/populate"
export const GET_BLOG_BY_CATEGORY = "/post/category/"

// Webinar
export const CREATE_WEBINAR = "/webinar/create"
export const CREATE_FILE_WEBINAR = "/webinar/"
export const UPDATE_WEBINAR = "/webinar/edit/"
export const UPDATE_FILE_WEBINAR = "/webinar/"
export const GET_WEBINAR_BY_UUID = "/webinar/show/"
export const GET_WEBINAR_BY_SLUG = "/webinar/show/slug/"
export const GET_WEBINAR_PUBLIC = "/webinar/populate/general/list"
export const GET_WEBINAR_ETHIS = "/webinar/populate/private/list"
export const GET_RESPONSE_WEBINAR = "/webinar/populate/response"
export const GET_RESPONSE_WEBINAR_ADMIN = "admin/webinar/populate/response"
export const DEL_WEBINAR = "/webinar/delete/"
export const REGISTER_WEBINAR = "/webinar/"
export const GET_RECENT_WEBINAR = "/webinar/populate/recent/list"

// Faspay
export const CREATE_VA_FASPAY = "/faspay/pledge/"
export const CREATE_VA_FASPAY_COLLECTION = "/collection/create/va-number/faspay"

// Setting
export const GET_SETTINGS = "/setting/populate"
export const UPDATE_SETTING = "/setting/edit/"

// Iluma
export const CHECK_BANK_ACCOUNT = "/check/bank-name"

// Banned User
export const BANNED_USER = "/user/"
export const GET_USER_BANNED = "/user/populate/ban"

// google analcytics
export const GET_TOTAL_VISITOR = "/analytics/total-visitor-and-page-view"
export const GET_TOP_REFFERER = "/analytics/top-refferer"
export const GET_TOP_BROWSER = "/analytics/top-browser"
export const GET_REALTIME_USER = "/analytics/user-active"
export const GET_MOST_VISITED = "/analytics/most-visited-page"

// helpdesk
export const GET_HELPDESK = "/helpdesk/customer-service/populate"
export const POST_ASSIGN_TICKET = id => `/helpdesk/assign/ticket/${id}`
export const GET_TICKET_ASSIGN = `/helpdesk/assign/populate`
export const POST_SOLVING_TICKET = id => `/helpdesk/ticket/${id}/edit/status`
export const POST_CLOSING_TICKET = id => `/helpdesk/close/ticket/${id}`
export const GET_MESSAGES_TICKET = id =>
  `/helpdesk/moderator/ticket/${id}/replies/populate`
export const POST_MESSAGES_TICKET = id =>
  `/helpdesk/moderator/reply/ticket/${id}`

// mailchimp
export const CREATE_LIST = "/mail-chimp/create"
export const GET_LIST_BY_ID = id => `/mail-chimp/show/${id}`
export const UPDATE_LIST = id => `/mail-chimp/update/${id}`
export const DELETE_LIST = id => `/mail-chimp/delete/${id}`
export const GET_LIST_ALL = "/mail-chimp/lists"

// addendum
export const REQUEST_RESTRUCTURE = id =>
  `/collection/campaign/${id}/request-addendum`
export const UPDATE_REQUEST_RESTRUCTURE = id =>
  `/collection/campaign/request-addendum/${id}/edit`
export const GET_ALL_RESTRUCTURE = (query = "") =>
  `/collection/campaign/request-addendum/populate${query}`
export const GET_RESTRUCTURE_BY_ID = id =>
  `/collection/campaign/request-addendum/${id}/show`

export const GET_LIST_TRANSACTION_ADDENDUM = (id, query = "") =>
  `/campaigns/${id}/addendum/populate-transaction${query}`
export const GET_LIST_TRANSACTION_ADDENDUM_V2 = (
  idCampaign,
  idAddendum,
  query = ""
) =>
  `/v2/campaigns/${idCampaign}/addendum/${idAddendum}/populate-transaction${query}`
export const UPDATE_AKAD_ADDENDUM = id => `/akad/edit/akadAddendum/${id}`

export const UPDATE_START_DATE_PROJECT = id =>
  `/collection/campaign/${id}/edit/due-date`

// expired va
export const EXPIRED_VA = id => `/moderator/transaction/expired/${id}`
export const EXPIRED_VA_RDL = id =>
  `/v2/moderator/rdl/transaction/expired/${id}`
export const EXPIRED_VA_XENDIT = id => `/xendit/update/${id}/expired`
// addendum
export const GET_AKAD_ADDENDUM = (id, query = "") =>
  `/campaigns/${id}/populate/addendum${query}`

// roleback project analyst to BD
export const POST_ROLEBACK_PROJECT = id => `/risk/rollback-to-bd/${id}`

// broadcast
export const POST_BROADCAST = "/broadcast/firebase"
export const GET_BROADCAST = "/broadcast/firebase/populate"

// RDL
export const GET_TRANSACTION_RDL = id =>
  `/user/rdl/list-transaction/campaign/${id}/populate`
export const POST_SEND_TO_ESCROW = "/user/rdl/retrieveFromRdl"
// upload proof tax
export const UPLOAD_TAX = idRepayment =>
  `/payout/repayment-transaction/${idRepayment}/upload/payment-receipt`

export const GET_APPROVAL_PAYOUT = "/payout/transaction/need-approval/populate"
export const POST_APPROVAL_PAYOUT = "/payout/approval/transaction"
export const GET_APPROVAL_HISTORY_PAYOUT =
  "/payout/transaction-approval/histories"
export const GET_DETAIL_APPROVAL_PAYOUT = id =>
  `/payout/transaction/batch/${id}/populate`
export const GET_DETAIL_APPROVAL_PAYOUT_HISTORIES = id =>
  `/payout/transaction-approval/histories/batch/${id}/populate`

// mailchimp
export const CREATE_TEMPLATE_EMAIL = "/mail-chimp/templates/create"
export const GET_TEMPLATE_EMAIL = "/mail-chimp/templates/list"
export const CREATE_CAMPAIGN_MAILCHIMP = "/mail-chimp/campaign/create"
export const GET_CAMPAIGN_MAILCHIMP = "/mail-chimp/campaign/list"
export const SEND_EMAIL_MAILCHIMP = campaignId =>
  `/mail-chimp/campaign/${campaignId}/send`
export const GET_LIST_AUDIENCE_MAILCHIMP = "/mail-chimp/audience/list"
export const GET_TAGS_MAILCHIMP = audienceId =>
  `/mail-chimp/audience/${audienceId}/tags/list`

// dttot
export const GET_LIST_DTTOT = "/moderator/dttot/populate"
export const APPROVAL_DTTOT = id => `/moderator/dttot/approval/${id}`
export const GET_HISTORY_DTTOT = "/moderator/dttot/approval-histories"
export const GET_STATUS_ILLUMA = "/v2/master/dttot/illuma/status"
export const GET_STATUS_DTTOT = "/v2/master/dttot/internal/status"

// get profile
export const GET_PROFILE_DETAILS = id => `/user/detail/${id}`

// refferer
export const GET_REFFERER_LIST = "/master/refferer/populate"
export const CREATE_REFFERER = "/master/refferer/create"
export const UPDATE_REFFERER = id => `/master/refferer/${id}/edit`
export const GET_USER_REFFERER = "/moderator/user-refferer/populate"

export const GET_BANK_LIST_XENDIT = "/xendit/va-bank/list"
export const CREATE_VA_XENDIT = "/collection/create/va-number/xendit"
