import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Cards
import CardProject from "./CardProjects"
import Toaster from "components/Common/Toast"
import {
  cancelCampaign,
  delCampaign,
  getCampaignAll,
  getProjectApproved,
  postCreateCampaign,
  postFullyFunded,
  postPublishCampaign,
  postUnpublishCampaign,
  postUnpublishCampaignFully,
} from "helpers/backend_helpers"
import { API_URL, API_URL_1 } from "helpers/api_url"
import PInput from "components/Input/PInput"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Loading from "pages/Utility/Loading"
import PuffLoader from "react-spinners/PuffLoader"

import noImage from "../../assets/images/ethis/no-image.jpg"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"
import { handleStatusCampaign } from "helpers/method_helpers"

const Campaign = props => {
  const [campaign, setCampaign] = useState([])
  const [modal, setModal] = useState(false)
  const [projects, setProjects] = useState([])
  const [loadButt, setLoadButt] = useState(false)
  const [loadCampaignId, setLoadCampaignId] = useState("")
  const [load, setload] = useState(false)

  const [perPage, setPerPage] = useState(6)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)

  const [search, setSearch] = useState("")
  const [loadSearch, setLoadSearch] = useState(false)

  const [select, setselect] = useState(null)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)

  const toggle = () => setModal(!modal)

  useEffect(async () => {
    const token = localStorage.getItem("token")
    const projectData = await getProjectApproved(token)
    setProjects(projectData.data)
  }, [])

  useEffect(() => {
    getCampaigns()
  }, [page])

  useEffect(() => {
    getCampaignsSearch()
  }, [search])

  const getCampaigns = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPage}&&page=${page}`
    setload(true)
    try {
      const data = await getCampaignAll(token, query)
      setCampaign(data.data)
      setTotalPage(data.meta.pagination.last_page)
      setCurrentPage(data.meta.pagination.current_page)

      setload(false)
    } catch (error) {
      setload(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const getCampaignsSearch = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPage}&search=${search}`
    setLoadSearch(true)
    try {
      const data = await getCampaignAll(token, query)
      setCampaign(data.data)
      setTotalPage(data.meta.pagination.last_page)
      setCurrentPage(data.meta.pagination.current_page)

      setLoadSearch(false)
    } catch (error) {
      setLoadSearch(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handlePublish = async campaignId => {
    const token = localStorage.getItem("token")
    setLoadCampaignId(campaignId)
    setLoadButt(true)
    try {
      await postPublishCampaign(campaignId, token)
      getCampaigns()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil terbit kampanye")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleUnPublish = async item => {
    const token = localStorage.getItem("token")
    // console.log(item)
    setLoadCampaignId(item.uuid)
    setLoadButt(true)
    if (item.status === 6) {
      try {
        await postUnpublishCampaignFully(item.uuid, token)
        getCampaigns()
        setLoadButt(false)
        Toaster("success", "Sukses", "Berhasil turunkan kampanye terkumpul")
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Maaf", error.response.data.message)
      }
    } else {
      try {
        await postUnpublishCampaign(item.uuid, token)
        getCampaigns()
        setLoadButt(false)
        Toaster("success", "Sukses", "Berhasil turunkan kampanye")
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Maaf", error.response.data.message)
      }
    }
  }

  const handleFullyFunded = async campaignId => {
    const token = localStorage.getItem("token")
    try {
      await postFullyFunded(campaignId, token)
      getCampaigns()
      Toaster("success", "Sukses", "Berhasil terdanai penuh")
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleCancelProyek = async () => {
    const token = localStorage.getItem("token")
    try {
      await cancelCampaign(select.uuid, token)
      setconfirm_alert2(false)
      getCampaigns()
      Toaster("success", "Sukses", "Berhasil batalkan Proyek")
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderCampaign = data => {
    if (data.length === 0) {
      return null
    }
    return data.map(item => {
      return (
        <div className="mb-20 col-lg-4">
          <div className="card rounded">
            <div
              style={{
                height: 200,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              }}
            >
              <img
                draggable="false"
                src={item.image ? `${API_URL}${item.image}` : noImage}
                // className="img-fluid"
                height="100%"
                width="100%"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
              />
            </div>
            <div className="f-11 card-body p-2">
              <div className="project-head">
                <Row>
                  <Col xs="12">
                    <p>
                      <b>{item.title}</b>
                    </p>
                    <div className="d-flex align-items-center">
                      <p className="mr-1">Status:</p>

                      <h6>
                        <Badge
                          color={handleStatusCampaign(
                            "color",
                            Boolean(item.isPaidOff),
                            Boolean(item.isHaveRepayments),
                            item.status
                          )}
                          size="large"
                        >
                          {handleStatusCampaign(
                            "label",
                            Boolean(item.isPaidOff),
                            Boolean(item.isHaveRepayments),
                            item.status
                          )}
                        </Badge>
                      </h6>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="mb-2 mt-2"></hr>
              <div className="project-body">
                <div className="project-content">
                  <Row>
                    <Col>
                      <p>Target</p>
                      <p className="bold">
                        Rp{" "}
                        {item.target
                          ? Number(item.target).toLocaleString()
                          : "-"}
                      </p>
                    </Col>
                    <Col>
                      <p>Terkumpul</p>
                      <p className="bold">
                        Rp {Number(item.collected).toLocaleString()}
                      </p>
                    </Col>
                  </Row>
                  <div class="separator"></div>
                  <Row>
                    <Col>
                      <p>Proyek Nisbah</p>
                      <p className="bold">
                        {item.nisbah ? item.nisbah : "-"} %
                      </p>
                    </Col>
                    <Col>
                      <p>Tenor Proyek</p>
                      <p className="bold">
                        {item.tenor ? item.tenor : "-"} Bulan
                      </p>
                    </Col>
                  </Row>
                  <div className="separator"></div>
                  <Row>
                    <Col>
                      <p>Tanggal Berakhir</p>
                      <p className="bold">
                        {item.end_date_campaign
                          ? moment(item.end_date_campaign).format("DD MMM YYYY")
                          : "-"}
                      </p>
                    </Col>
                    <Col>
                      <p>Sisa Waktu</p>
                      <p className="bold">
                        {item.end_date_campaign
                          ? renderSisaWaktu(item.end_date_campaign)
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <hr className="mb-2 mt-2"></hr>
              <div className="d-flex align-items-center justify-content-between m-2">
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm w-100"
                  onClick={() => props.history.push(`/campaign/${item.uuid}`)}
                >
                  Kelola
                </Button>
                {item.status === 3 ||
                item.status === 5 ||
                item.status === 10 ||
                item.status === 11 ? (
                  <Button
                    type="button"
                    color="info"
                    className="btn-sm w-100"
                    onClick={() => handlePublish(item.uuid)}
                    disabled={
                      (handleRoles().includes("Moderator")
                        ? false
                        : handleRoles().includes("Bisnis")
                        ? true
                        : false) ||
                      Boolean(loadButt && loadCampaignId === item.uuid)
                    }
                  >
                    {Boolean(loadButt && loadCampaignId === item.uuid) ? (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                        Loading
                      </>
                    ) : (
                      "Terbitkan"
                    )}
                  </Button>
                ) : null}
                {item.status === 4 ||
                item.status === 6 ||
                item.status === 7 ||
                item.status === 8 ||
                item.status === 9 ? (
                  <Button
                    type="button"
                    color="danger"
                    className="btn-sm w-100"
                    onClick={() => handleUnPublish(item)}
                    disabled={
                      (handleRoles().includes("Moderator")
                        ? false
                        : handleRoles().includes("Bisnis")
                        ? true
                        : false) ||
                      Boolean(loadButt && loadCampaignId === item.uuid)
                    }
                  >
                    {Boolean(loadButt && loadCampaignId === item.uuid) ? (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                        Loading
                      </>
                    ) : (
                      "Turunkan"
                    )}
                  </Button>
                ) : null}
                {/* {
                  item.status >= 3 &&
                  <Button
                    type="button"
                    color="warning"
                    className="btn-sm"
                    onClick={() => handleFullyFunded(item.uuid)}
                  >
                    Terdanai
                  </Button>
                } */}
              </div>
              {handleRoles().includes("Moderator") && (
                <div className="d-flex align-items-center justify-content-between m-2">
                  {item.status !== 11 ? (
                    <Button
                      type="button"
                      color="warning"
                      className="btn-sm w-100"
                      onClick={() => {
                        setselect(item)
                        setconfirm_alert2(true)
                      }}
                    >
                      Batalkan Proyek
                    </Button>
                  ) : null}
                  <Button
                    type="button"
                    color="secondary"
                    className="btn-sm w-100"
                    onClick={() => {
                      setselect(item)
                      setconfirm_alert(true)
                    }}
                    disabled={
                      handleRoles().includes("Moderator") ? false : true
                    }
                  >
                    Hapus
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderSisaWaktu = date => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    let countDay = eventdate.diff(todaysdate, "days")
    if (countDay < 0) {
      return "Kampanye Berakhir"
    }
    return `${countDay} Hari Lagi`
  }

  const handleSubmitCreateCampaign = async (e, value) => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postCreateCampaign(value.proyek, token)
      Toaster("success", "Sukses", "Berhasil buat kampanye")
      toggle()
      setLoadButt(false)
      getCampaigns()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderPaginate = numb => {
    let result = []
    for (let i = 1; i <= numb; i++) {
      result.push(
        <PaginationItem active={i === currentPage}>
          <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const handleRoles = () => {
    let result = []
    props.user.user_role.forEach(element => {
      result.push(element.role?.title)
    })
    return result
  }

  const handleDelCampaign = async () => {
    const token = localStorage.getItem("token")
    try {
      await delCampaign(select.uuid, token)
      setconfirm_alert(false)
      getCampaigns()
      Toaster("success", "Sukses", "Berhasil hapus kampanye")
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleDownloadProyek = async () => {
    const token = localStorage.getItem("token")
    axios
      .get(`${API_URL_1}/projects/export`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Disposition": "attachment; filename=daftar_proyek.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `daftar_proyek.xlsx`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(err => {
        Toaster("error", "Maaf", err.response.data.message)
      })
  }

  // if(load) return <Loading />
  if (load) {
    return (
      <SkeletonLoad title="Daftar" breadcrumbItem="Kampanye">
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton height={32} width={320} />
          <Row>
            {[1, 2, 3, 4, 5, 6].map(item => {
              return (
                <div className="mb-20 col-lg-4">
                  <Skeleton height={420} />
                </div>
              )
            })}
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      {confirm_alert ? (
        <SweetAlert
          title="Apakah anda yakin?"
          warning
          showCancel
          confirmBtnText="Hapus"
          cancelBtnText="Batal"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="secondary"
          onConfirm={handleDelCampaign}
          onCancel={() => setconfirm_alert(false)}
        >
          Anda tidak akan dapat mengembalikan data kampanye ini!
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Apakah anda yakin?"
          warning
          showCancel
          confirmBtnText="Ya"
          cancelBtnText="Tidak"
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="secondary"
          onConfirm={handleCancelProyek}
          onCancel={() => setconfirm_alert2(false)}
        >
          Pembatalan Proyek {select && select.title}
        </SweetAlert>
      ) : null}
      <Modal isOpen={modal} autoFocus={true} scrollable={true}>
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Buat Kampanye</ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmitCreateCampaign(e, v)
              }}
            >
              <PInput
                name="proyek"
                type="select"
                label="Pilih Proyek"
                validate={{
                  required: { value: true, errorMessage: "Proyek harus diisi" },
                }}
                options={projects}
              />
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Buat Kampanye"
                )}
              </button>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kelola" breadcrumbItem="Kampanye" />
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="app-search">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control bg-white border"
                  placeholder="Cari..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </div>
            <div>
              {handleRoles().includes("Marketing") ||
              handleRoles().includes("Moderator") ? (
                <Button className="mr-2" onClick={handleDownloadProyek}>
                  Unduh Proyek
                </Button>
              ) : null}
              {handleRoles().includes("Moderator") ? (
                <Button onClick={toggle}>+ Buat Kampanye</Button>
              ) : !handleRoles().includes("Bisnis") ? (
                <Button onClick={toggle}>+ Buat Kampanye</Button>
              ) : null}
            </div>
          </div>

          {loadSearch ? (
            <div className="d-flex justify-content-center">
              <PuffLoader color={"#0ec6a2"} loading={true} size={120} />
            </div>
          ) : (
            <>
              <Row>{renderCampaign(campaign)}</Row>
              {campaign.length > 0 ? (
                <Row className="pb-4">
                  <Col lg={12} className="d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink first onClick={() => setPage(1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink
                          previous
                          onClick={() => setPage(page - 1)}
                        />
                      </PaginationItem>
                      {renderPaginate(totalPage)}
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink
                          next
                          onClick={() => setPage(page + 1)}
                        />
                      </PaginationItem>
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink
                          last
                          onClick={() => setPage(totalPage)}
                        />
                      </PaginationItem>
                    </Pagination>
                  </Col>
                </Row>
              ) : (
                <Row className="">
                  <Col lg="12">
                    <Card>
                      <CardBody className="text-center">
                        <h5>
                          <b>Tidak Ada Data</b>
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(connect(mapStateToProps, null)(Campaign))
