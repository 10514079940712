import Toaster from 'components/Common/Toast'
import React from 'react'
import { Col, Row, Button } from "reactstrap"

import verificationImg from "../../assets/images/verification-img.png"
import CardProject from './card-project'

const ProjectChoose = props => {
  const {activeForm, setActiveForm, projects, handleStepTwoId, user, setLoad} = props

  return (
    <>
      {/* {
        projects.length > 0 &&
        <Row className="">
          <Col lg="12">
            <h4 className=" font-weight-semibold">
              Daftar Proyek Saya
            </h4>
            <Row className="">
              <CardProject 
                projects={projects}
                setActiveForm={setActiveForm}
                handleStepTwoId={handleStepTwoId}
              />
            </Row>
          </Col>
        </Row>
      } */}

      <Row className="justify-content-center mt-lg-1">
        <Col xl="5" sm="8">
          <div className="text-center card p-3">
            <Row className="justify-content-center">
              <Col lg="10">
                <h4 className="mt-4 font-weight-semibold">
                  Properti atau UKM
                </h4>
                <p className="text-muted mt-3">
                  Pilih Proyek yang mau kamu ajukan dengan klik tombol dibawah ini.
                </p>

                <div className="d-flex justify-content-center">
                  {/* button triggers modal */}
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      // if(user.kyc_status !== 9) return alert("Akun kamu belum terverifikasi.")
                      if(user.kyc_status < 9) return Toaster("error","Maaf" ,"Akun anda belum terverifikasi.")
                      setLoad(true)
                      setActiveForm(3)
                      setTimeout(() => {
                        setLoad(false)
                      }, 200)
                    }}
                  >
                    Properti
                  </Button>
                  <div className="mx-3"></div>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      if(user.kyc_status < 9) return Toaster("error","Maaf" ,"Akun anda belum terverifikasi.")
                      setLoad(true)
                      setActiveForm(1)
                      setTimeout(() => {
                        setLoad(false)
                      }, 200)
                    }}
                  >
                    UKM{`  `}
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-5 mb-2">
              <Col sm="6" xs="8">
                <div>
                  <img
                    src={verificationImg}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      
    </>
  )
}

export default ProjectChoose