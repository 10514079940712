import {
  downloadPayout,
  expiredVa,
  expiredVaRDL,
  expiredVaXendit,
  getCampaignActive,
  getCampaignAdmin,
  getCampaignAll,
  getCampaignSelector,
  getRepaymentType,
  getTrxCampaigns,
  getTrxCampaignsInvestment,
  postPayout,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { map } from "lodash"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { post } from "helpers/api_helpers"
import Toaster from "components/Common/Toast"
import { API_URL, API_URL_1 } from "helpers/api_url"
import axios from "axios"
import AsyncSelect from "react-select/async"
import { connect } from "react-redux"

const UserInvestment = props => {
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [trx, setTrx] = useState([])
  const [select, setSelect] = useState("")
  const [activeTab, setActiveTab] = useState("1")
  const [selected, setSelected] = useState(null)
  const [modal, setModal] = useState(false)
  const [selectTrx, setSelectTrx] = useState(null)
  const [cmpgn, setCmpgn] = useState(null)

  useEffect(() => {
    if (select) {
      handleSelectCampaign()
    }
  }, [select])

  const toggleModal = (item = null) => {
    setSelectTrx(item)
    setModal(!modal)
  }

  const handleSelectCampaign = async e => {
    const token = localStorage.getItem("token")
    setLoad(true)
    const data = await getTrxCampaignsInvestment(select, token)
    const cmpgn = await getCampaignAdmin(select, token)
    setCmpgn(cmpgn.data)
    setTrx(data.data)
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const isMurabahah = value => value == "Murabahah"
  const isMusyarakah = value => value == "Musyarakah"

  const checkPrivy = arr => {
    if (!arr) return
    if (arr.length === 0) return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].status === 1) return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].status === 2) return <Badge color="primary">Sudah TTD</Badge>
    if (arr[0].status === 3)
      return <Badge color="danger">Kesalahan Token</Badge>
  }

  const checkPrivyBorrower = arr => {
    if (!arr) return
    if (arr.length === 0) return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].borrower_status === 1)
      return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].borrower_status === 2)
      return <Badge color="primary">Sudah TTD</Badge>
    if (arr[0].borrower_status === 3)
      return <Badge color="danger">Kesalahan Token</Badge>
  }

  const checkPrivyEthis = arr => {
    if (!arr) return
    if (arr.length === 0) return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].borrower_status === 1)
      return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].borrower_status === 2)
      return <Badge color="primary">Sudah TTD</Badge>
    if (arr[0].borrower_status === 3)
      return <Badge color="danger">Kesalahan Token</Badge>
  }

  const checkRoles = () => {
    let roles = props.user?.user_role.map(item => item.role?.title)

    if (roles.includes("Moderator") || roles.includes("Finance")) {
      return false
    }
    return true
  }

  const handleBankType = value => {
    switch (value) {
      case 2:
      case 3:
        return "BSI [EX-BNIS]"
      case 4:
        return "Permata Syariah"
      case 6:
        return "RDF"
      default:
        return "-"
    }
  }

  const data = {
    columns: [
      {
        field: "created_at",
        label: "Tanggal",
      },
      {
        field: "campaign",
        label: <div className="text-nowrap">Nama Kampanye</div>,
      },
      {
        field: "trx_id",
        label: <div className="text-nowrap">ID Transaksi</div>,
      },
      {
        field: "amount",
        label: "Pembiayaan",
      },
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "email",
        label: "Email",
      },
      {
        field: "phone",
        label: "Telepon",
      },
      {
        field: "bank_type",
        label: "Metode Pembayaran",
      },
      {
        field: "status",
        label: "Status Pembayaran",
      },
      {
        field: "status_doc_musyarakah",
        label: `${cmpgn?.akad_type?.title || "TTD"} Pendana`,
      },
      {
        field: "status_doc_musyarakah_borrower",
        label: `${cmpgn?.akad_type?.title || "TTD"} Penerima`,
      },
      {
        field: "status_doc_wakalah",
        label: "Wakalah Pendana",
      },
      {
        field: "status_doc_wakalah_ethis",
        label: "Wakalah Ethis",
      },
      {
        field: "expired_va",
        label: "Kadaluarsakan VA",
      },
    ],
    rows: map(trx, item => ({
      ...item,
      bank_type:
        item.bank_type === 5
          ? item.bank_name || "-"
          : handleBankType(item.bank_type),
      created_at: moment(item.created_at).format("DD MMM YYYY HH:mm"),
      campaign: item.campaign.title,
      amount: `Rp ${Number(item.amount).toLocaleString()}`,
      status: (
        <div>
          {item.status === 1 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 2 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 3 && (
            <Badge color="warning">Pembayaran Bertahap</Badge>
          )}
          {item.status === 4 && <Badge color="success">Sudah Dibayar</Badge>}
          {item.status === 5 && (
            <Badge color="warning">Pengembalian Bertahap</Badge>
          )}
          {item.status === 6 && (
            <Badge color="primary">Selesai Pengembalian Pokok</Badge>
          )}
          {item.status === 7 && (
            <Badge color="primary">Selesai Pengembalian dan Nisbah</Badge>
          )}
          {/* {item.status === 7 && <Badge color="primary">Selesai Pengembalian</Badge>}
                {item.status === 8 && <Badge color="primary">Selesai Pengembalian</Badge>} */}
          {item.status === 9 && <Badge color="info">Kadaluarsa</Badge>}
          {item.status === 10 && <Badge color="secondary">Dikembalikan</Badge>}
          {item.status === 11 && <Badge color="secondary">Dibatalkan</Badge>}
        </div>
      ),
      name: `${item.user.first_name} ${item.user.last_name}`,
      email: item.user.email,
      phone: item.user.phone_number,
      status_doc_musyarakah: checkPrivy(
        isMurabahah(cmpgn?.akad_type?.title)
          ? item.privy_document_murabahah
          : item.privy_document_musyarakah
      ),
      status_doc_wakalah: checkPrivy(item.privy_document_wakalah),
      status_doc_musyarakah_borrower: checkPrivyBorrower(
        isMurabahah(cmpgn?.akad_type?.title)
          ? item.privy_document_murabahah
          : item.privy_document_musyarakah
      ),
      status_doc_wakalah_ethis: checkPrivyEthis(item.privy_document_wakalah),
      expired_va: (
        <Button
          className="btn-sm"
          color="secondary"
          onClick={() => toggleModal(item)}
          disabled={item.status > 2 || checkRoles()}
        >
          <i class="bx bxs-edit"></i>
        </Button>
      ),
    })),
  }

  const loadOptions = (inputValue, callback) => {
    const token = localStorage.getItem("token")
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getCampaignSelector(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const handleExpiredTrx = async () => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      if (selectTrx?.bank_type === 6) {
        await expiredVaRDL(selectTrx?.uuid, token)
      } else if (selectTrx?.bank_type === 5) {
        await expiredVaXendit(selectTrx?.uuid, token)
      } else {
        await expiredVa(selectTrx?.uuid, token)
      }
      toggleModal(null)
      setLoadButt(false)
      handleSelectCampaign()
      Toaster("success", "Sukses", "Berhasil kadaluarsakan virtual account")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response?.data?.message)
    }
  }

  const modalExpiredVa = () => {
    return (
      <Modal isOpen={modal} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModal(null)}>
          {selectTrx?.user?.email}
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">Apakah anda yakin ?</div>
          <div className="d-flex">
            <Button
              color="secondary"
              className="btn-sm mr-2"
              onClick={() => toggleModal(null)}
              disabled={loadButt}
            >
              Batal
            </Button>
            <Button
              color="primary"
              className="btn-sm"
              onClick={handleExpiredTrx}
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Ya"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading title="Pemberi" breadcrumbItem="Pembiayaan" />

  return (
    <React.Fragment>
      {modalExpiredVa()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pemberi" breadcrumbItem="Pembiayaan" />
          <Row>
            <Col lg="12">
              <FormGroup row>
                <Label sm={2}>Pilih Kampanye</Label>
                <Col sm={6}>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={e => {
                      setSelect(e.value)
                      setSelected(e)
                    }}
                    value={selected}
                    // onInputChange={handleInputSearch}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {cmpgn && (
                    <div className="row">
                      <div className="col-md-6">
                        <table class="table table-striped border">
                          <thead>
                            <tr className="full-width-tbl">
                              <th scope="col">Total Pembiayaan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="full-width-tbl">
                              <td>
                                Rp {Number(cmpgn?.collected).toLocaleString()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Transaksi
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1" id="all-order">
                      <MDBDataTable
                        responsive
                        bordered
                        data={data}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(UserInvestment)
