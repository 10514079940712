import {
  downloadPayout,
  expiredVa,
  getCampaignActive,
  getCampaignAll,
  getCampaignSelector,
  getRepaymentType,
  getTransactionRdl,
  getTrxCampaigns,
  getTrxCampaignsInvestment,
  postPayout,
  postSendToEscrow,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { map } from "lodash"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { post } from "helpers/api_helpers"
import Toaster from "components/Common/Toast"
import { API_URL, API_URL_1 } from "helpers/api_url"
import axios from "axios"
import AsyncSelect from "react-select/async"
import { connect } from "react-redux"

const TransferToEscrow = props => {
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [trx, setTrx] = useState([])
  const [select, setSelect] = useState("")
  const [activeTab, setActiveTab] = useState("1")
  const [selected, setSelected] = useState(null)
  const [modal, setModal] = useState(false)
  const [selectTrx, setSelectTrx] = useState(null)

  const [usersSelect, setUsersSelect] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    if (select) {
      handleSelectCampaign()
      setUsersSelect([])
    }
  }, [select])

  const toggleModal = () => {
    setModal(!modal)
  }

  const handleSelectCampaign = async e => {
    const token = localStorage.getItem("token")
    setLoad(true)
    const data = await getTransactionRdl(select, token)
    setTrx(data.data)
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleChecklist = item => {
    let dataUser = usersSelect.find(el => el.uuid === item.uuid)
    if (dataUser) {
      setUsersSelect(usersSelect.filter(el => dataUser.uuid !== el.uuid))
      return
    }
    setUsersSelect([...usersSelect, item])
  }

  const statusSignatureBorrower = data => {
    if (data.length === 0) return <Badge color="danger">Belum TTD</Badge>
    if (data[0].borrower_status === 1)
      return <Badge color="danger">Belum TTD</Badge>
    if (data[0].borrower_status === 2)
      return <Badge color="primary">Sudah TTD</Badge>
    if (data[0].borrower_status === 3)
      return <Badge color="danger">Kesalahan Token</Badge>
  }

  const handleDisableAkad = item => {
    let akad
    if (item?.campaign_transaction?.campaign?.akad_type?.title == "Murabahah") {
      akad = item?.campaign_transaction?.privy_document_murabahah?.[0] || {}
    } else {
      akad = item?.campaign_transaction?.privy_document_musyarakah?.[0] || {}
    }

    if (akad?.borrower_status == 2) {
      return false
    }

    return true
  }

  const data = {
    columns: [
      {
        field: "created_at",
        label: "Tanggal",
      },
      {
        field: "campaign",
        label: "Nama Kampanye",
        sort: "asc",
      },
      {
        field: "trx_id",
        label: "ID Transaksi",
      },
      {
        field: "amount",
        label: "Pembiayaan",
      },
      {
        field: "name",
        label: "Nama",
        sort: "asc",
      },
      //   {
      //     field: "email",
      //     label: "Email",
      //     sort: "asc",
      //   },
      //   {
      //     field: "phone",
      //     label: "Telepon",
      //     sort: "asc",
      //   },
      {
        field: "ttd_borrower",
        label: "TTD Borrower",
      },
      //   {
      //     field: "status",
      //     label: "Status Pembayaran",
      //   },
      {
        field: "action",
        label: (
          <div>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={() => {
                if (selectAll) {
                  setUsersSelect([])
                } else {
                  const data = trx.filter(item => {
                    if (
                      item.campaign_transaction?.campaign?.akad_type?.title ==
                      "Murabahah"
                    ) {
                      return (
                        item.campaign_transaction?.privy_document_murabahah?.[0]
                          ?.borrower_status === 2
                      )
                    }
                    return (
                      item.campaign_transaction?.privy_document_musyarakah?.[0]
                        ?.borrower_status === 2
                    )
                  })
                  setUsersSelect(data)
                }

                setSelectAll(!selectAll)
              }}
            />
          </div>
        ),
      },
    ],
    rows: map(trx, item => ({
      ...item,
      ttd_borrower: statusSignatureBorrower(
        item.campaign_transaction?.campaign?.akad_type?.title === "Murabahah"
          ? item.campaign_transaction?.privy_document_murabahah || []
          : item.campaign_transaction?.privy_document_musyarakah || []
      ),
      created_at: moment(item.created_at).format("DD MMM YYYY HH:mm"),
      campaign: item.campaign_transaction?.campaign?.title,
      amount: `Rp ${Number(item.subTotal).toLocaleString()}`,
      status: (
        <div>
          {item.status === 1 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 2 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 3 && (
            <Badge color="warning">Pembayaran Bertahap</Badge>
          )}
          {item.status === 4 && <Badge color="success">Sudah Dibayar</Badge>}
          {item.status === 5 && (
            <Badge color="warning">Pengembalian Bertahap</Badge>
          )}
          {item.status === 6 && (
            <Badge color="primary">Selesai Pengembalian Pokok</Badge>
          )}
          {item.status === 7 && (
            <Badge color="primary">Selesai Pengembalian dan Nisbah</Badge>
          )}
          {/* {item.status === 7 && <Badge color="primary">Selesai Pengembalian</Badge>}
                  {item.status === 8 && <Badge color="primary">Selesai Pengembalian</Badge>} */}
          {item.status === 9 && <Badge color="info">Kadaluarsa</Badge>}
          {item.status === 10 && <Badge color="secondary">Dikembalikan</Badge>}
          {item.status === 11 && <Badge color="secondary">Dibatalkan</Badge>}
        </div>
      ),
      name: `${item.user?.first_name} ${item.user?.last_name}`,
      //   email: item.user.email,
      //   phone: item.user.phone_number,
      action: (
        <div>
          <input
            type="checkbox"
            checked={usersSelect.find(el => item.uuid === el.uuid)}
            disabled={handleDisableAkad(item)}
            onChange={() => {
              handleChecklist(item)
            }}
          />
        </div>
      ),
    })),
  }

  const loadOptions = (inputValue, callback) => {
    const token = localStorage.getItem("token")
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getCampaignSelector(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const handleTransfer = async () => {
    const token = localStorage.getItem("token")
    setLoadButt(true)

    const data = usersSelect
      .map(item => item.campaign_transaction?.id)
      .join(",")

    try {
      await postSendToEscrow({ campaign_transaction_ids: data }, token)
      toggleModal(null)
      setLoadButt(false)
      setUsersSelect([])
      handleSelectCampaign()
      Toaster("success", "Sukses", "Permitaan transfer berhasil dikirim")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message)
    }
  }

  const modalTransfer = () => {
    return (
      <Modal isOpen={modal} role="dialog" autoFocus={true} size="lg">
        <ModalHeader toggle={() => toggleModal()}>
          {selected?.label}
        </ModalHeader>
        <ModalBody>
          <table class="table table-striped border">
            <thead>
              <tr>
                <th scope="col">Id Transaksi</th>
                <th scope="col">Nama</th>
                <th scope="col">Pembiayaan</th>
              </tr>
            </thead>
            <tbody>
              {usersSelect.map(item => {
                return (
                  <tr>
                    <td>{item.trx_id}</td>
                    <td>{`${item.user?.first_name} ${item.user?.last_name}`}</td>
                    <td>{Number(item.subTotal).toLocaleString()}</td>
                  </tr>
                )
              })}
              <tr>
                <th colSpan="2">Total</th>
                <th>{countNominalTransfer()}</th>
              </tr>
            </tbody>
          </table>

          <Button
            color="primary"
            className="w-100"
            onClick={handleTransfer}
            disabled={loadButt}
          >
            {loadButt ? (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                Loading
              </>
            ) : (
              "Konfirmasi"
            )}
          </Button>
        </ModalBody>
      </Modal>
    )
  }

  const countNominalTransfer = () => {
    let numb = 0
    usersSelect.forEach(el => {
      numb += Number(el.subTotal)
    })

    return `Rp ${numb.toLocaleString()}`
  }

  if (load) return <Loading title="Pemberi" breadcrumbItem="Pembiayaan" />

  return (
    <React.Fragment>
      {modalTransfer()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Transfer RDF ke Escrow"
            breadcrumbItem="Transfer RDF"
          />
          <Row>
            <Col lg="12">
              <FormGroup row>
                <Label sm={2}>Pilih Kampanye</Label>
                <Col sm={6}>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={e => {
                      setSelect(e.value)
                      setSelected(e)
                    }}
                    value={selected}
                    // onInputChange={handleInputSearch}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Transaksi RDF
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1" id="all-order">
                      {select && (
                        <div className="d-flex justify-content-between align-items-center mt-4">
                          <p className="font-20">
                            {!usersSelect.length ? (
                              <span className="font-weight-bold">
                                Checklist Pengguna untuk menambahkan nominal
                                transfer
                              </span>
                            ) : (
                              <>
                                Total :{" "}
                                <span className="font-weight-bold">
                                  {countNominalTransfer()}
                                </span>
                              </>
                            )}
                          </p>
                          <Button
                            color="primary"
                            disabled={!usersSelect.length}
                            onClick={toggleModal}
                          >
                            Transfer ke Escrow
                          </Button>
                        </div>
                      )}
                      <MDBDataTable
                        responsive
                        bordered
                        data={data}
                        striped
                        className="mt-2"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(TransferToEscrow)
