import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap"
import { connect } from "react-redux"
import "./index.css"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {
  getGroup,
  getMessageGroup,
  readMessageGroup,
  getUsersAll,
  postGroup,
  postMessageGroup,
  getUsersAllDropdown,
  getMessagePrivate,
  sendMessagePrivate,
  readMessagePrivate,
} from "helpers/backend_helpers"
import Toaster from "components/Common/Toast"
import Echo from "laravel-echo"
import jwt_decode from "jwt-decode"
import GroupTooltip from "./GroupTooltip"
import Skeleton from "react-loading-skeleton"
import Message from "./Message"
import MessageLeft from "./MessageLeft"
import classnames from "classnames"
import soundPrivate from "./sound/private.mp3"
import soundGroup from "./sound/group.mp3"
import { API_URL } from "helpers/api_url"

const useAudio = url => {
  const [audio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(false)

  const toggleSound = () => setPlaying(true)

  useEffect(() => {
    playing ? audio.play() : audio.pause()
  }, [playing])

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false))
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false))
    }
  }, [])

  return [playing, toggleSound]
}

const echo = new Echo({
  broadcaster: "pusher",
  key: `${process.env.REACT_APP_PUSHER_KEY}`,
  cluster: "ap1",
  encrypted: true,
  authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      apiKey: process.env.REACT_APP_API_KEY,
    },
  },
})

const GroupChat = props => {
  const token = localStorage.getItem("token")
  const [playing, toggleSound] = useAudio(soundPrivate)
  const [playingGroup, toggleSoundGroup] = useAudio(soundGroup)
  const [active, setActive] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [modalCreateGroup, setModalCreateGroup] = useState(false)
  const [modalCreatePrivate, setModalCreatePrivate] = useState(false)

  const [users, setUsers] = useState(null)
  const [groups, setGroups] = useState([])
  const [selectGroup, setSelectGroup] = useState(null)
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const [loadChat, setLoadChat] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const [privates, setPrivates] = useState([])
  const [messagesPrivateAll, setMessagesPrivateAll] = useState([])
  const [messagesPrivate, setMessagesPrivate] = useState([])
  const [selectPrivate, setSelectPrivate] = useState(null)
  const [search, setSearch] = useState("")

  const [chatFile, setChatFile] = useState(null)
  const [chatFileGroup, setChatFileGroup] = useState(null)

  const [loadSend, setLoadSend] = useState(false)

  const messagesData = useRef([])
  messagesData.current = messages
  const groupsData = useRef([])
  groupsData.current = groups

  const messagesDataPrivate = useRef([])
  messagesDataPrivate.current = messagesPrivate

  const selectGroupData = useRef(null)
  selectGroupData.current = selectGroup

  const selectPrivateData = useRef([])
  selectPrivateData.current = selectPrivate

  const scroll = useRef(null)
  const scrollPrivate = useRef(null)

  const roleUser = () => props.user.user_role.map(item => item.role.title)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (scroll && scroll.current) {
      scroll.current.scrollIntoView()
    }
  }, [messages, loadChat])

  useEffect(() => {
    if (scrollPrivate && scrollPrivate.current) {
      scrollPrivate.current.scrollIntoView()
    }
  }, [messagesPrivate, loadChat])

  useEffect(() => {
    echo
      .private(`App.Models.User.` + props.user.id)
      .listen("GroupCreated", el => {
        // console.log("group created", el)
        // toggleSoundGroup()

        echo.private(`groups.` + el.group.id).listen("NewConversation", e => {
          if (props.user.id != e.conversation.user_id) {
            toggleSoundGroup()
            getDataGroup()
          }
          let newMessage = {
            message: e.conversation.message,
            type: "conversation",
            userName: e.conversation.userName,
            user_id: e.conversation.user_id,
            conversation_status: [],
            file: e.conversation.file,
          }
          if (
            selectGroupData.current &&
            selectGroupData.current.id == e.conversation.group_id
          ) {
            setMessages([...messagesData.current, newMessage])
          }
        })

        echo
          .private(`groups.` + el.group.id)
          .listen("NewReadConversation", async e => {
            // console.log("read message", e)
            if (
              selectGroupData.current &&
              selectGroupData.current.id == e.conversationStatus.group_id
            ) {
              const data = await getMessageGroup(
                e.conversationStatus.group_uuid,
                token
              )
              setMessages(data.data)
            }
          })

        getDataGroup()
      })
  }, [])

  useEffect(async () => {
    const data = await getGroup(token)

    data.data.forEach(el => {
      echo.private(`groups.` + el.id).listen("NewConversation", e => {
        // console.log(e)
        if (props.user.id != e.conversation.user_id) {
          toggleSoundGroup()
          getDataGroup()
        }

        let newMessage = {
          message: e.conversation.message,
          type: "conversation",
          userName: e.conversation.userName,
          user_id: e.conversation.user_id,
          conversation_status: [],
          file: e.conversation.file,
        }
        if (
          selectGroupData.current &&
          selectGroupData.current.id == e.conversation.group_id
        ) {
          setMessages([...messagesData.current, newMessage])
        }
      })

      echo.private(`groups.` + el.id).listen("NewReadConversation", async e => {
        // console.log("read message", e)
        if (
          selectGroupData.current &&
          selectGroupData.current.id == e.conversationStatus.group_id
        ) {
          const data = await getMessageGroup(
            e.conversationStatus.group_uuid,
            token
          )
          setMessages(data.data)
        }
      })
    })
  }, [])

  useEffect(() => {
    echo
      .private(`App.Models.User.` + props.user.id)
      .listen("NewPrivateConversation", e => {
        // console.log("new message", e)
        toggleSound()
        getMessagePrivateAll()
        if (
          selectPrivateData.current &&
          selectPrivateData.current.users &&
          selectPrivateData.current.users.id == e.user.user_id
        ) {
          setMessagesPrivate([
            ...messagesDataPrivate.current,
            {
              user_id: e.user.user_id,
              message: e.user.message,
              file: e.user.file,
            },
          ])
        }
      })

    // echo.private(`App.Models.User.`+ props.user.id)
    // .listen('NewPrivateReadConversation', (e) => {
    //   // console.log("new message", e)
    //   toggleSound()
    //   getMessagePrivateAll()
    //   if(selectPrivateData.current && selectPrivateData.current.users && selectPrivateData.current.users.id == e.user.user_id) {
    //     setMessagesPrivate([...messagesDataPrivate.current, {
    //       user_id: e.user.user_id,
    //       message: e.user.message,
    //       // uuid: e.user.
    //     }])
    //   }
    // });
  }, [])

  useEffect(() => {
    getDataGroup()
    getMessagePrivateAll()
  }, [])

  useEffect(() => {
    getMessagePrivateAll()
  }, [search])

  useEffect(() => {
    if (search) {
      getAllUsers()
    }
  }, [search])

  useEffect(() => {
    getMessage()
  }, [selectGroup])

  const getDataGroup = async () => {
    const data = await getGroup(token)
    setGroups(data.data)
  }

  const getAllUsers = async () => {
    let query = `?per_page=10&keySearch=email&search=${search || "xxxxx"}`
    const data = await getUsersAllDropdown(token, query)
    let arrNew = []
    data.data.forEach(el => {
      let temp = messagesPrivateAll.map(item => item.users.id)
      if (!temp.includes(el.id.toString())) {
        arrNew.push({
          conversation: [],
          users: el,
          totalUnreadMessage: 0,
          type: "conversation_private",
        })
      }
    })

    setPrivates([...arrNew])
  }

  const getMessagePrivateAll = async () => {
    const data = await getMessagePrivate(token)
    let newArr = data.data.map(item => {
      return {
        ...item,
        users: item.sender.id == props.user.id ? item.receiver : item.sender,
      }
    })
    setMessagesPrivateAll(newArr)
  }

  const getMessage = async () => {
    if (selectGroup) {
      setLoadChat(true)
      if (selectGroup.totalUnreadMessage > 0) {
        await readMessageGroup(selectGroup.uuid, token)
        getDataGroup()
      }
      const data = await getMessageGroup(selectGroup.uuid, token)
      setMessages(data.data)
      setLoadChat(false)
    }
  }

  const loadOptions = (inputValue, callback) => {
    let param = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAll(token, param)
      callback(
        data.data.map(item => {
          return { label: item.email, value: item.uuid }
        })
      )
    })
  }

  const loadOptionsPrivate = (inputValue, callback) => {
    let param = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAllDropdown(token, param)
      callback(
        data.data.map(item => {
          return { label: item.email, value: item.uuid, ...item }
        })
      )
    })
  }

  const toggleModalCreateGroup = () => {
    setUsers(null)
    setModalCreateGroup(!modalCreateGroup)
  }

  const toggleModalCreatePrivate = () => {
    setUsers(null)
    setModalCreatePrivate(!modalCreatePrivate)
  }

  let roles = props.user.user_role.map(item => item.role.title)

  const handleCreateGroup = async (e, value) => {
    if (!users) return Toaster("error", "Maaf", "Pengguna harus diisi")
    value.users = users.map(item => item.value).join(",")

    setLoadButt(true)
    try {
      await postGroup(value, token)
      setUsers(null)
      toggleModalCreateGroup()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil buat grup")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalCreateGroup = () => {
    return (
      <Modal
        isOpen={modalCreateGroup}
        role="dialog"
        autoFocus={true}
        // scrollable
      >
        <ModalHeader toggle={toggleModalCreateGroup}>Buat Grup</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateGroup(e, v)
            }}
          >
            <PInput
              name="name"
              type="text"
              label="Nama Grup"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Nama Grup harus diisi",
                },
              }}
            />
            <FormGroup className="ajax-select mt-3 mt-lg-0 select2-container">
              <Label>Tambah Pengguna</Label>
              {/* <Select
                value={users}
                isMulti={true}
                onChange={(e) => {
                  setUsers(e)
                }}
                options={options}
                classNamePrefix="select2-selection"
                isLoading={loadQuery}
                closeMenuOnSelect={false}
                onInputChange={handleInputChange}
              /> */}
              <AsyncSelect
                cacheOptions
                isMulti={true}
                loadOptions={loadOptions}
                defaultOptions
                classNamePrefix="select2-selection"
                onChange={e => {
                  setUsers(e)
                }}
                value={users}
              />
            </FormGroup>
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleCreatePrivate = () => {
    // console.log(users)
    if (!users) return Toaster("error", "Maaf", "Pengguna harus dipilih")
    // console.log(messagesPrivateAll)
    let arr = messagesPrivateAll.filter(item => item.users.uuid == users.uuid)
    if (arr.length > 0) {
      setSelectPrivate(arr[0])
      setMessagesPrivate(arr[0].conversation)
      toggleModalCreatePrivate()
    } else {
      setSelectPrivate({
        conversation: [],
        totalUnreadMessage: 0,
        type: "conversation_private",
        users: {
          type: "user",
          id: users.id,
          uuid: users.uuid,
          first_name: users.first_name,
          last_name: users.last_name,
        },
      })
      setMessagesPrivate([])
      setMessagesPrivateAll([
        ...messagesPrivateAll,
        {
          conversation: [],
          totalUnreadMessage: 0,
          type: "conversation_private",
          users: {
            type: "user",
            id: users.id,
            uuid: users.uuid,
            first_name: users.first_name,
            last_name: users.last_name,
          },
        },
      ])
      toggleModalCreatePrivate()
    }
    // console.log(arr)
  }

  const renderModalCreatePrivate = () => {
    return (
      <Modal
        isOpen={modalCreatePrivate}
        role="dialog"
        autoFocus={true}
        // scrollable
      >
        <ModalHeader toggle={toggleModalCreatePrivate}>
          Tambah Chat Baru
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Pilih Pengguna</Label>
            <AsyncSelect
              cacheOptions
              // isMulti={true}
              loadOptions={loadOptionsPrivate}
              defaultOptions
              // classNamePrefix="select2-selection"
              onChange={e => {
                // console.log(e)
                setUsers(e)
              }}
              value={users}
            />
          </FormGroup>
          <div className="py-2">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              // type="submit"
              disabled={loadButt}
              onClick={handleCreatePrivate}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Pilih"
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const handleActiveGroup = item => {
    if (!selectGroup) return ""
    if (item.id === selectGroup.id) return "active"
    return ""
  }

  const handleName = id => {
    if (!selectGroup) return ""
    for (let i = 0; i < selectGroup.participants.length; i++) {
      if (id == selectGroup.participants[i].id) {
        return `${selectGroup.participants[i].first_name} ${selectGroup.participants[i].last_name}`
      }
    }
    return ""
  }

  const handleSend = async () => {
    if (message) {
      let postMessage = {
        message: message,
        group_id: selectGroup.uuid,
        file: chatFileGroup,
      }
      const formData = new FormData()
      Object.keys(postMessage).map(item => {
        formData.append(item, postMessage[item])
      })
      setLoadSend(true)
      await postMessageGroup(formData, token)
      setMessage("")
      setChatFileGroup(null)
      setLoadSend(false)
    }
  }

  const handleKeyPress = async e => {
    if (e.key === "Enter") {
      if (e.target.value) {
        let postMessage = {
          message: message,
          group_id: selectGroup.uuid,
          file: chatFileGroup,
        }
        const formData = new FormData()
        Object.keys(postMessage).map(item => {
          formData.append(item, postMessage[item])
        })
        setLoadSend(true)
        await postMessageGroup(formData, token)
        setMessage("")
        setChatFileGroup(null)
        setLoadSend(false)
      }
    }
  }

  const handleSendPrivate = async () => {
    if (message) {
      let postMessage = {
        message: message,
        to_user_id: selectPrivate.users.uuid,
        file: chatFile,
      }
      const formData = new FormData()
      Object.keys(postMessage).map(item => {
        formData.append(item, postMessage[item])
      })
      setLoadSend(true)
      const data = await sendMessagePrivate(formData, token)
      getMessagePrivateAll()
      setChatFile(null)
      setSearch("")
      setMessage("")
      setMessagesPrivate([
        ...messagesDataPrivate.current,
        {
          user_id: data.data.user_id,
          message: data.data.message,
          file: data.data.file,
        },
      ])
      setLoadSend(false)
    }
  }

  const handleKeyPressPrivate = async e => {
    if (e.key === "Enter") {
      if (e.target.value) {
        let postMessage = {
          message: message,
          to_user_id: selectPrivate.users.uuid,
          file: chatFile,
        }
        const formData = new FormData()
        Object.keys(postMessage).map(item => {
          formData.append(item, postMessage[item])
        })
        setLoadSend(true)
        const data = await sendMessagePrivate(formData, token)
        getMessagePrivateAll()
        setMessage("")
        setChatFile(null)
        setMessagesPrivate([
          ...messagesDataPrivate.current,
          {
            user_id: data.data.user_id,
            message: data.data.message,
            file: data.data.file,
            // uuid: e.user.
          },
        ])
        setLoadSend(false)
      }
    }
  }

  const handleSelectPrivateUser = async item => {
    if (item.uuid) {
      setLoadChat(true)
      await readMessagePrivate(item.uuid, token)
      getMessagePrivateAll()
      setLoadChat(false)
    }
    setSelectPrivate(item)
    setMessagesPrivate(item.conversation)
  }

  const renderChatNew = () => {
    let countMessage =
      groups.length > 0
        ? groups.reduce((a, b) => ({
            totalUnreadMessage: a.totalUnreadMessage + b.totalUnreadMessage,
          })).totalUnreadMessage
        : 0
    let countMessagePrivate =
      messagesPrivateAll.length > 0
        ? messagesPrivateAll.reduce((a, b) => ({
            totalUnreadMessage: a.totalUnreadMessage + b.totalUnreadMessage,
          })).totalUnreadMessage
        : 0
    return (
      <button
        data-unify="Button"
        onClick={() => setActive(!active)}
        class={`${
          !active ? "css-1oojfrg-unf-btn" : "css-1lykvjp-unf-btn"
        } e1ggruw00`}
      >
        <span>
          <div
            class="chatImgWrapper position-relative"
            data-testid="btnChatFloating"
          >
            <img
              class="chatImg"
              src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/57d0f05a.svg"
              alt="Chat"
            />
            {countMessage + countMessagePrivate > 0 && (
              <span class="chatUnreadsCounter">
                {countMessage + countMessagePrivate}
              </span>
            )}
          </div>
          Chat
        </span>
      </button>
    )
  }

  const handleRenderChat = () => {
    if (loadChat) {
      return (
        <div class="chatbox-body-group">
          <div class="chatbox__body">
            <div style={{ fontSize: 24, lineHeight: 2 }}>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </div>
            {/* <div ref={scroll}></div> */}
          </div>
        </div>
      )
    }
    if (!selectGroup) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="w-75 text-center">
            <div className="bold text-secondary font-20">
              Selamat Datang di Chat
            </div>
            <div className="mt-3 px-4 font-14">
              Silakan memilih pesan untuk memulai percakapan
            </div>
          </div>
        </div>
      )
    }

    if (messages.length === 0) {
      return (
        <div class="chatbox-body-group">
          <div class="chatbox__body">
            <div className="text-center mt-3">Belum ada pesan</div>
            {/* <div ref={scroll}></div> */}
          </div>
          <div class="panel-footer">
            {chatFileGroup && (
              <div className="mb-1 d-flex align-items-center">
                <div className="mr-1">{chatFileGroup.name}</div>
                <i
                  className="bx bx-x font-16"
                  onClick={() => setChatFileGroup(null)}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            )}
            <div class="input-group">
              <input
                id="btn-input"
                type="text"
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                class={`form-control input-sm chat_set_height ${
                  loadSend && "disabled-input-custom"
                }`}
                placeholder="Tulis pesan..."
                tabindex="0"
                dir="ltr"
                spellcheck="false"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                contenteditable="true"
                disabled={loadSend}
              />
              <input
                type="file"
                id="upload-chat2"
                onChange={e => setChatFileGroup(e.target.files[0])}
                hidden
              />
              <label htmlFor="upload-chat2" className="mb-0">
                <div className="d-flex align-items-center h-100 attachment-chat">
                  <img
                    src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/06713830.svg"
                    alt="Attachment"
                    className="px-2"
                  />
                </div>
              </label>
              <span class="input-group-btn d-flex align-items-center">
                <button
                  class="btn bt_bg btn-sm"
                  id="btn-chat"
                  disabled={loadSend}
                  onClick={handleSend}
                >
                  {/* Kirim <i className="mdi mdi-send" /> */}
                  {/* Kirim <i className="bx bx-loader bx-spin font-size-16 align-middle"></i> */}
                  Kirim{" "}
                  {loadSend ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                  ) : (
                    <i className="mdi mdi-send" />
                  )}
                </button>
              </span>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div class="chatbox-body-group">
        <div class="chatbox__body">
          {selectGroup &&
            messages.map((item, i) => {
              if (props.user.id == item.user_id) {
                return (
                  <Message
                    key={i}
                    item={item.conversation_status.filter(el => el.read_at)}
                    name={`${props.user.first_name} ${props.user.last_name}`}
                    message={item.message}
                    file={item.file}
                    id={i}
                  />
                )
              }
              let name = handleName(item.user_id) || item.userName
              return (
                <MessageLeft
                  key={i}
                  item={item.conversation_status.filter(el => el.read_at)}
                  name={name}
                  message={item.message}
                  file={item.file}
                  id={i}
                />
              )
            })}
          <div ref={scroll}></div>
        </div>
        <div class="panel-footer">
          {chatFileGroup && (
            <div className="mb-1 d-flex align-items-center">
              <div className="mr-1">{chatFileGroup.name}</div>
              <i
                className="bx bx-x font-16"
                onClick={() => setChatFileGroup(null)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          )}
          <div class="input-group">
            <input
              id="btn-input"
              type="text"
              value={message}
              onChange={e => setMessage(e.target.value)}
              onKeyDown={handleKeyPress}
              class={`form-control input-sm chat_set_height ${
                loadSend && "disabled-input-custom"
              }`}
              placeholder="Tulis pesan..."
              tabindex="0"
              dir="ltr"
              spellcheck="false"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              contenteditable="true"
              disabled={loadSend}
            />
            <input
              type="file"
              id="upload-chat2"
              onChange={e => setChatFileGroup(e.target.files[0])}
              hidden
            />
            <label htmlFor="upload-chat2" className="mb-0">
              <div className="d-flex align-items-center h-100 attachment-chat">
                <img
                  src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/06713830.svg"
                  alt="Attachment"
                  className="px-2"
                />
              </div>
            </label>
            <span class="input-group-btn d-flex align-items-center">
              <button
                class="btn bt_bg btn-sm"
                id="btn-chat"
                disabled={loadSend}
                onClick={handleSend}
              >
                {/* Kirim <i className="mdi mdi-send" /> */}
                {/* Kirim <i className="bx bx-loader bx-spin font-size-16 align-middle"></i> */}
                Kirim{" "}
                {loadSend ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                ) : (
                  <i className="mdi mdi-send" />
                )}
              </button>
            </span>
          </div>
        </div>
      </div>
    )
  }

  const handleRenderChatPrivate = () => {
    if (loadChat) {
      return (
        <div class="chatbox-body-group">
          <div class="chatbox__body">
            <div style={{ fontSize: 24, lineHeight: 2 }}>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={40} />
            </div>
            {/* <div ref={scroll}></div> */}
          </div>
        </div>
      )
    }
    if (!selectPrivate) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="w-75 text-center">
            <div className="bold text-secondary font-20">
              Selamat Datang di Chat
            </div>
            <div className="mt-3 px-4 font-14">
              Silakan memilih pesan untuk memulai percakapan
            </div>
          </div>
        </div>
      )
    }

    if (messagesPrivate.length === 0) {
      return (
        <div class="chatbox-body-group">
          <div class="chatbox__body">
            <div className="text-center mt-3">Belum ada pesan</div>
            {/* <div ref={scroll}></div> */}
          </div>
          <div class="panel-footer">
            {chatFile && (
              <div className="mb-1 d-flex align-items-center">
                <div className="mr-1">{chatFile.name}</div>
                <i
                  className="bx bx-x font-16"
                  onClick={() => setChatFile(null)}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            )}
            <div class="input-group">
              <input
                id="btn-input"
                type="text"
                disabled={loadSend}
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={handleKeyPressPrivate}
                class={`form-control input-sm chat_set_height ${
                  loadSend && "disabled-input-custom"
                }`}
                placeholder="Tulis pesan..."
                tabindex="0"
                dir="ltr"
                spellcheck="false"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                contenteditable="true"
              />
              <input
                type="file"
                id="upload-chat"
                onChange={e => setChatFile(e.target.files[0])}
                hidden
              />
              <label htmlFor="upload-chat" className="mb-0">
                <div className="d-flex align-items-center h-100 attachment-chat">
                  <img
                    src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/06713830.svg"
                    alt="Attachment"
                    className="px-2"
                  />
                </div>
              </label>
              <span class="input-group-btn d-flex align-items-center">
                <button
                  class="btn bt_bg btn-sm"
                  id="btn-chat"
                  disabled={loadSend}
                  onClick={handleSendPrivate}
                >
                  {/* Kirim <i className="mdi mdi-send" /> */}
                  {/* Kirim <i className="bx bx-loader bx-spin font-size-16 align-middle"></i> */}
                  Kirim{" "}
                  {loadSend ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                  ) : (
                    <i className="mdi mdi-send" />
                  )}
                </button>
              </span>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div class="chatbox-body-group">
        <div class="chatbox__body">
          {selectPrivate &&
            messagesPrivate.map((item, i) => {
              if (props.user.id == item.user_id) {
                return (
                  <div class="chatbox__body__message chatbox__body__message--right">
                    <div class="ul_section_full">
                      <ul class="ul_msg">
                        <li className="font-12">{item.message}</li>
                        {item.file && (
                          <Button
                            className="btn-sm mt-1 rounded-circle"
                            onClick={() =>
                              window.open(`${API_URL}${item.file}`, "_blank")
                            }
                          >
                            <i className="bx bxs-download"></i>
                          </Button>
                        )}
                        {item.conversation_status &&
                        item.conversation_status.length > 0 &&
                        item.conversation_status[0].read_at ? (
                          <div className="read-chat-private text-primary">
                            <i className="bx bx-comment-minus"></i>
                          </div>
                        ) : (
                          <div className="read-chat-private">
                            <i className="bx bx-comment-minus"></i>
                          </div>
                        )}
                      </ul>
                      <div class="clearfix"></div>
                    </div>
                    {/* <span class="time_date_right"> 11:01 AM | June 9</span> */}
                  </div>
                )
              }
              return (
                <div class="chatbox__body__message chatbox__body__message--left">
                  <div class="ul_section_full">
                    <ul class="ul_msg">
                      <li className="font-12">{item.message}</li>
                      {item.file && (
                        <Button
                          className="btn-sm mt-1 rounded-circle"
                          onClick={() =>
                            window.open(`${API_URL}${item.file}`, "_blank")
                          }
                        >
                          <i className="bx bxs-download"></i>
                        </Button>
                      )}
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                  {/* <span class="time_date_left"> 11:01 AM | June 9</span> */}
                </div>
              )
            })}
          <div ref={scrollPrivate}></div>
        </div>
        <div class="panel-footer">
          {chatFile && (
            <div className="mb-1 d-flex align-items-center">
              <div className="mr-1">{chatFile.name}</div>
              <i
                className="bx bx-x font-16"
                onClick={() => setChatFile(null)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          )}
          <div class="input-group">
            <input
              id="btn-input"
              type="text"
              value={message}
              onChange={e => setMessage(e.target.value)}
              onKeyDown={handleKeyPressPrivate}
              class={`form-control input-sm chat_set_height ${
                loadSend && "disabled-input-custom"
              }`}
              placeholder="Tulis pesan..."
              tabindex="0"
              dir="ltr"
              spellcheck="false"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              contenteditable="true"
              disabled={loadSend}
            />
            <input
              type="file"
              id="upload-chat"
              onChange={e => setChatFile(e.target.files[0])}
              hidden
            />
            <label htmlFor="upload-chat" className="mb-0">
              <div className="d-flex align-items-center h-100 attachment-chat">
                <img
                  src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/zeus/kratos/06713830.svg"
                  alt="Attachment"
                  className="px-2"
                />
              </div>
            </label>
            <span class="input-group-btn d-flex align-items-center">
              <button
                class="btn bt_bg btn-sm"
                id="btn-chat"
                disabled={loadSend}
                onClick={handleSendPrivate}
              >
                {/* Kirim <i className="mdi mdi-send" /> */}
                {/* Kirim <i className="bx bx-loader bx-spin font-size-16 align-middle"></i> */}
                Kirim{" "}
                {loadSend ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                ) : (
                  <i className="mdi mdi-send" />
                )}
              </button>
            </span>
          </div>
        </div>
      </div>
    )
  }

  const handleUserChat = () => {
    if (!search) return messagesPrivateAll
    return messagesPrivateAll.filter(item => item.users.email.includes(search))
  }

  const renderChatOpen = () => {
    return (
      <div className={`${active ? "chat-grup-box" : "chat-grup-box-close"}`}>
        <div className="content-chat">
          <div className="left-box-chat">
            <div className="header-chat-group">
              <ul className="nav nav-tabs nav-tabs-custom bold" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    style={{ borderTopLeftRadius: "12px" }}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Grup
                  </NavLink>
                </NavItem>
                {roleUser().includes("Moderator") ||
                roleUser().includes("Legal") ||
                roleUser().includes("Finance") ||
                roleUser().includes("Publisher") ||
                roleUser().includes("Marketing") ||
                roleUser().includes("Bisnis") ||
                roleUser().includes("Risk") ||
                roleUser().includes("Collection") ||
                roleUser().includes("Director") ? (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2")
                      }}
                    >
                      Personal
                    </NavLink>
                  </NavItem>
                ) : null}
              </ul>
              {activeTab === "2" ? (
                <div className="text-center p-2">
                  <Button className="btn-sm" onClick={toggleModalCreatePrivate}>
                    + Chat Baru
                  </Button>
                </div>
              ) : (
                <>
                  {roles.includes("Moderator") && (
                    <div className="text-center p-2">
                      <Button
                        className="btn-sm"
                        onClick={toggleModalCreateGroup}
                      >
                        + Buat Group
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <TabContent activeTab={activeTab} className="list-group-chat-box">
              <TabPane tabId="1" className="h-100">
                <div className="list-group-chat">
                  {groups.map((item, i) => {
                    return (
                      <React.Fragment>
                        <GroupTooltip
                          key={i}
                          item={item}
                          id={i}
                          onClick={() => setSelectGroup(item)}
                          className={`group_list ${handleActiveGroup(item)}`}
                        />
                      </React.Fragment>
                    )
                  })}

                  {/* {roles.includes("Moderator") &&
                    <div className="text-center mt-3">
                      <Button className="btn-sm" onClick={toggleModalCreateGroup}>+ Buat Group</Button>
                    </div>
                  } */}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="list-group-chat">
                  {[...handleUserChat(), ...privates].map((item, i) => {
                    return (
                      <div
                        className={`group_list`}
                        key={i}
                        onClick={() => handleSelectPrivateUser(item)}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="font-12">
                            {item.users.first_name} {item.users.last_name}
                          </span>
                          {item.totalUnreadMessage > 0 ? (
                            <Badge color="primary" className="bold">
                              {item.totalUnreadMessage}
                            </Badge>
                          ) : null}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </TabPane>
            </TabContent>
          </div>
          <div className="right-box-chat">
            <TabContent activeTab={activeTab} className="h-100">
              <TabPane tabId="1" className="h-100">
                <div className="d-flex flex-column h-100 position-relative">
                  <div
                    className="selected-group"
                    onClick={() => setActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="bold font-14">
                      {selectGroup && selectGroup.name}
                    </div>
                    <i className={`bx bx-chevron-down font-24`} />
                  </div>
                  {handleRenderChat()}
                  {/* {handleRenderChatPrivate()} */}
                </div>
              </TabPane>
              <TabPane tabId="2" className="h-100">
                <div className="d-flex flex-column h-100 position-relative">
                  <div
                    className="selected-group"
                    onClick={() => setActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="bold font-14">
                      {selectPrivate &&
                        selectPrivate.users.first_name +
                          " " +
                          selectPrivate.users.last_name}
                    </div>
                    <i className={`bx bx-chevron-down font-24`} />
                  </div>
                  {/* {handleRenderChat()} */}
                  {handleRenderChatPrivate()}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderModalCreateGroup()}
      {renderModalCreatePrivate()}
      {renderChatNew()}
      {renderChatOpen()}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(GroupChat)
