import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"

import { MDBDataTable } from "mdbreact"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import { getUserVerified } from "helpers/backend_helpers"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import moment from "moment"
import DataTable from "components/Common/DataTable"

const UserActive = props => {
  const token = localStorage.getItem("token")
  const [activeTab, setActiveTab] = useState("1")
  const [load, setLoad] = useState(false)
  const [users, setUsers] = useState([])
  const [loadButt, setLoadButt] = useState(false)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)
  const [pagination, setPagination] = useState(null)

  const [first_date, setFirst_date] = useState("")
  const [end_date, setEnd_date] = useState("")

  useEffect(() => {
    getData()
  }, [page, perPage])

  const getData = async () => {
    if (first_date && end_date) {
      var dateFrom = new Date(first_date)
      var dateTo = new Date(end_date)
      var firstDay = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1)
      var lastDay = new Date(dateTo.getFullYear(), dateTo.getMonth() + 1, 0)

      let query = `?from=${moment(firstDay).format("YYYY-MM-DD")}&&to=${moment(
        lastDay
      ).format("YYYY-MM-DD")}&&per_page=${perPage}&&page=${page}`

      setLoad(true)
      try {
        const data = await getUserVerified(token, query)
        setTotalPage(data.meta.pagination.last_page)
        setCurrentPage(data.meta.pagination.current_page)
        setPagination(data.meta.pagination)
        setUsers(data.data)
        setLoad(false)
      } catch (error) {
        setLoad(false)
        // Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // console.log(users)

  const dataUser = {
    columns: [
      {
        field: "trx_id",
        label: "Transaksi",
      },
      // {
      //   field: "campaign_name",
      //   label: "Kampanye",
      // },
      {
        field: "amount",
        label: "Pembiayaan",
      },
      // {
      //   field: "datetime_payment",
      //   label: "Tanggal Bayar",
      // },
      {
        field: "name",
        label: "Nama",
        sort: "asc",
      },
      {
        field: "email",
        label: "Email",
        sort: "asc",
      },
      {
        field: "phone",
        label: "Telepon",
      },
      {
        field: "status",
        label: "Status",
      },
      {
        field: "description",
        label: "Deskripsi",
      },
    ],
    rows: users.map(item => {
      return {
        ...item,
        // campaign_name: item.campaign.title,
        // datetime_payment: moment(item.datetime_payment).format("DD MMM YYYY HH:mm"),
        amount: `Rp ${Number(item.amount).toLocaleString()}`,
        status: (
          <div>
            {item.status === 1 && <Badge color="danger">Belum Dibayar</Badge>}
            {item.status === 2 && <Badge color="danger">Belum Dibayar</Badge>}
            {item.status === 3 && (
              <Badge color="warning">Pembayaran Bertahap</Badge>
            )}
            {item.status === 4 && <Badge color="success">Sudah Dibayar</Badge>}
            {item.status === 5 && (
              <Badge color="warning">Pengembalian Bertahap</Badge>
            )}
            {item.status === 6 && (
              <Badge color="primary">Selesai Pengembalian Pokok</Badge>
            )}
            {item.status === 7 && (
              <Badge color="primary">Selesai Pengembalian dan Nisbah</Badge>
            )}
            {item.status === 9 && <Badge color="info">Kadaluarsa</Badge>}
            {item.status === 10 && (
              <Badge color="secondary">Dikembalikan</Badge>
            )}
            {item.status === 11 && <Badge color="secondary">Dibatalkan</Badge>}
          </div>
        ),
        name: `${item.user.first_name} ${item.user.last_name}`,
        email: item.user.email,
        phone: item.user.phone_number,
      }
    }),
  }

  const handleSearch = async (e, value) => {
    // console.log(value)
    // let from =
    var dateFrom = new Date(value.dateOne)
    var dateTo = new Date(value.dateTwo)
    var firstDay = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1)
    var lastDay = new Date(dateTo.getFullYear(), dateTo.getMonth() + 1, 0)

    let query = `?from=${moment(firstDay).format("YYYY-MM-DD")}&&to=${moment(
      lastDay
    ).format("YYYY-MM-DD")}&&per_page=${perPage}&&page=${page}`
    setLoadButt(true)
    try {
      const data = await getUserVerified(token, query)
      setTotalPage(data.meta.pagination.last_page)
      setCurrentPage(data.meta.pagination.current_page)
      setPagination(data.meta.pagination)
      setUsers(data.data)
      setLoadButt(false)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  if (load) return <Loading />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Aktif" breadcrumbItem="Pengguna" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Pengguna Aktif
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="1">
                      <div className="mt-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleSearch(e, v)
                          }}
                        >
                          <Row>
                            <Col lg="6">
                              <PInput
                                name="dateOne"
                                label="Tanggal Awal"
                                type="month"
                                onChange={e => setFirst_date(e.target.value)}
                                value={first_date}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="dateTwo"
                                label="Tanggal Akhir"
                                type="month"
                                value={end_date}
                                onChange={e => setEnd_date(e.target.value)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <button
                              className="btn btn-primary waves-effect waves-light d-flex align-items-center"
                              type="submit"
                              disabled={loadButt}
                            >
                              {loadButt ? (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                  Loading
                                </>
                              ) : (
                                <>
                                  <i className="bx bx-search-alt mr-1"></i> Cari
                                </>
                              )}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                      {/* <MDBDataTable
                        responsive
                        bordered
                        data={dataUser}
                        striped
                        className="mt-5"
                      /> */}
                      <DataTable
                        table={dataUser}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserActive
