import React from "react"
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const SkeletonLoad = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.title} breadcrumbItem={props.breadcrumbItem} />
            {props.children}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SkeletonLoad