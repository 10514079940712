import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { getCities, getDistrict, getSubDistrict, postUKMone, postUKMtwo } from "helpers/backend_helpers"
import React, { useState } from "react"
import { Col, FormGroup, Label } from "reactstrap"
// import { Form } from "redux-form"

const INIT_STEP_TWO = {
  file_ktp_direktur: null,
  file_npwp_company: null,
  file_nib_company: null,
  file_siup_company: null,
}

const StepTwo = (props) => {
  const {projects, setActiveForm, getProjectUser, stepTwoId, setLoad} = props
  const [inputTwo, setInputTwo] = useState(INIT_STEP_TWO)
  const [loadButt, setLoadButt] = useState(false)

  const handleChangeFileTwo = e => {
    setInputTwo({
      ...inputTwo,
      [e.target.name]: e.target.files[0]
    })
  }

  const handleSubmitStepTwo = async (e, value) => {
    let postUkmTwo = {
      ...value,
      ...inputTwo
    }
    const formData = new FormData()
    Object.keys(postUkmTwo).map(item => {
      formData.append(item, postUkmTwo[item])
    })

    const token = localStorage.getItem('token')
    setLoadButt(true)
    try {
      await postUKMtwo(stepTwoId.uuid, formData, token)
      getProjectUser()
      Toaster("success", "Sukses", "Berhasil ajukan proyek")
      setLoadButt(false)
      setActiveForm(0)
    } catch (error) {
      Toaster("error", `Ups`, error.response.data.message)
      setLoadButt(false)
    }
  }

  return (
    <div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleSubmitStepTwo(e, v)
        }}
      >
        <FormGroup row>
          <Label sm={3}>ID Onboarding Tahap 1</Label>
          <Col sm={9}>
            <PInput 
              name="onboardingId"
              type="text"
              value={stepTwoId.onboardingId}
              disabled
              placeholder="ID Onboarding"
              // onChange={}
              validate={{
                required: {value: true, errorMessage: ' harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Nomor KTP Direktur/Komisaris<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="ktp_director_number"
              type="text"
              placeholder="Nomor KTP"
              // onChange={}
              validate={{
                required: {value: true, errorMessage: 'Nomor KTP Direktur/Komisaris harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Unggah KTP Direktur/Komisaris<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="file_ktp_direktur"
              type="file"
              helpMessage="jpeg, jpg atau png maksimal 5 MB"
              onChange={handleChangeFileTwo}
              validate={{
                required: {value: true, errorMessage: 'Unggah KTP Direktur/Komisaris harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Nomor NPWP Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="npwp_company_number"
              type="text"
              placeholder="Nomor NPWP"
              // onChange={}
              validate={{
                required: {value: true, errorMessage: 'Nomor NPWP Perusahaan harus diisi'},
                pattern: {value: '^[0-9]+$', errorMessage: 'Format NPWP harus angka'},
                minLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
                maxLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Unggah NPWP Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="file_npwp_company"
              type="file"
              helpMessage="jpeg, jpg atau png maksimal 5 MB"
              onChange={handleChangeFileTwo}
              validate={{
                required: {value: true, errorMessage: 'Unggah NPWP Perusahaan harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Unggah NIB<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="file_nib_company"
              type="file"
              helpMessage="Pdf file maksimal 50 MB"
              onChange={handleChangeFileTwo}
              validate={{
                required: {value: true, errorMessage: 'Unggah NIB harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Nomor SIUP Perusahaan</Label>
          <Col sm={9}>
            <PInput 
              name="company_siup_number"
              type="text"
              placeholder="Nomor SIUP"
              // onChange={}
              validate={{
                required: {value: true, errorMessage: 'Nomor SIUP Perusahaan harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Unggah SIUP Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="file_siup_company"
              type="file"
              helpMessage="Pdf file maksimal 50 MB"
              onChange={handleChangeFileTwo}
              validate={{
                required: {value: true, errorMessage: 'Unggah SIUP Perusahaan harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="agreement"
              type="checkbox"
              label="Dengan ini saya menyatakan bahwa semua data yang diberikan
              adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
              data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
              pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
              // placeholder="Email"
              validate={{
                required: {value: true, errorMessage: 'Persetujuan Pemohon harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <div className="row justify-content-end mb-3">
          <div className="col-9">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              type="submit"
              disabled={loadButt}
            >
              {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan'}
            </button>

          </div>
        </div>
      </AvForm>
      <div className="row justify-content-end mb-5">
        <div className="col-9">
          <button
            className="btn btn-secondary btn-block waves-effect waves-light"
            type="submit"
            disabled={loadButt}
            onClick={() => setActiveForm(0)}
          >
            {`Batal`}
          </button>

        </div>
      </div>
    </div>
  )
}

const FormUKMTwo = props => {
  const {projects, setActiveForm, getProjectUser, stepTwoId, setLoad} = props

  return (
    <div>
      <h4 className="mt-2 font-weight-semibold">
        Formulir Pengajuan Proyek UKM Tahap 2
      </h4>
      <div className="mt-4 ">


        <StepTwo
          projects={projects}
          getProjectUser={getProjectUser}
          setActiveForm={setActiveForm}
          stepTwoId={stepTwoId}
          setLoad={setLoad}
        />
      </div>
    </div>
  )
}

export default FormUKMTwo