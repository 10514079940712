import axios from "axios"
import { post, del, get, put } from "./api_helpers"
import {
  delData,
  delDataBlog,
  getData,
  getDataBlog,
  getDataHelpdesk,
  postData,
  postDataBlog,
  postDataHelpdesk,
  putData,
} from "./api_helper_local"
import { API_URL_1 } from "./api_url"
import * as url from "./url_helpers"

// Register Method
const postRegister = data => {
  return axios
    .post(API_URL_1 + url.POST_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data.data
    })
    .catch(err => {
      let message
      if (err.response) {
        message = err.response.data
      }
      throw message
    })
}

// Login Method
const postLogin = data => {
  return axios
    .post(API_URL_1 + url.POST_LOGIN, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      let message
      if (err.response) {
        message = err.response.data
      }
      throw message
    })
}

//get User login
const getUserLogin = token => {
  // console.log('dsaf', { Authorization: `Bearer ${token}` })
  // console.log(token)
  return axios
    .get(API_URL_1 + url.GET_USER_LOGIN, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      let message
      if (err.response) {
        message = err.response.data
      }
      throw message
    })
}

// Logout Method
const postUserLogout = token => {
  return axios
    .post(
      API_URL_1 + url.POST_LOGOUT,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(response => {
      return response.data
    })
    .catch(err => {
      let message
      if (err.response) {
        message = err.response.data
      }
      throw message
    })
}

// get Profile
// export const getProfile = () => get(url.GET_PROFILE)

// get Master KYC User
export const getProvince = token => getData(url.GET_PROVINCE, token)

export const getCities = (provinceId, token) =>
  getData(`${url.GET_CITY}${provinceId}`, token)
export const getDistrict = (cityId, token) =>
  getData(`${url.GET_DISTRICT}${cityId}`, token)
export const getSubDistrict = (districtId, token) =>
  getData(`${url.GET_SUB_DISTRICT}${districtId}`, token)

export const getCitiesAll = token => getData(url.GET_CITY_ALL, token)
export const getDistrictAll = token => getData(url.GET_DISTRICT_ALL, token)
export const getSubDistrictAll = token =>
  getData(url.GET_SUB_DISTRICT_ALL, token)

export const getBank = token => getData(url.GET_BANK, token)
export const getBankWithSearch = (token, query = "") =>
  getData(url.GET_BANK + query, token)

export const getTitle = token => getData(url.GET_TITLE, token)

export const getEducation = token => getData(url.GET_EDUCATION, token)

export const getJob = token => getData(url.GET_JOB, token)

export const getJobSector = token => getData(url.GET_JOB_SECTOR, token)

export const getMarried = token => getData(url.GET_MARRIED, token)

export const getGender = token => getData(url.GET_GENDER, token)

export const getMonthInCome = token => getData(url.GET_MONTH_IN_COME, token)

export const getReligion = token => getData(url.GET_RELIGION, token)

export const getSourceFunding = token => getData(url.GET_SOURCE_FUNDING, token)

export const postStepOne = (data, token) =>
  postData(url.POST_KYC_1, data, token)

export const postStepTwo = (data, token) =>
  postData(url.POST_KYC_2, data, token)

export const postStepThree = (data, token) =>
  postData(url.POST_KYC_3, data, token)

export const postKYCAll = (data, token) =>
  postData(url.POST_KYC_ALL, data, token)

export const putBankAccount = (id, data, token) =>
  postData(`${url.PUT_BANK_ACCOUNT}${id}/account`, data, token)

export const getBankAccount = (id, token) =>
  getData(`${url.PUT_BANK_ACCOUNT}${id}/account`, token)

// Get user KYC
export const getUserKYC = token => getData(url.GET_USERS_KYC, token)

export const getReason = (token, query = "") =>
  getData(url.GET_REASON + query, token)

// Get master project
export const getBussinesEntity = token =>
  getData(url.GET_BUSSINES_ENTITY, token)
export const getProyekType = token => getData(url.GET_PROJECT_TYPE, token)
export const getSectorIndustry = token =>
  getData(url.GET_SECTOR_INDUSTRY, token)
export const getPropertyOwner = token =>
  getData(url.GET_PROPERTY_OWNERSHIP, token)
export const getCountry = token => getData(url.GET_COUNTRY, token)
export const getSubmitActor = token => getData(url.GET_SUBMIT_ACTOR, token)
export const getProyekTypeDetail = token =>
  getData(url.GET_PROJECT_TYPE_DETAIL, token)
export const getOrganization = token => getData(url.GET_ORGANIZATION, token)

export const postUKMone = (data, token) =>
  postData(url.POST_UKM_ONE, data, token)
export const postUKMtwo = (uuid, data, token) =>
  postData(url.POST_UKM_TWO + uuid, data, token)
export const postProperty = (data, token) =>
  postData(url.POST_PROPERTY, data, token)

export const getProject = (token, query = "") =>
  getData(url.GET_PROJECT + query, token)

export const getProjectModerator = (token, query = "") =>
  getData(url.GET_PROJECT_MODERATOR + query, token)

export const approveProject = (uuid, status, isPermanent, data, token) =>
  postData(
    `${url.APPROVE_PROJECT}${uuid}?approve=${status}&permanent=${isPermanent}`,
    data,
    token
  )

// Campaign
export const getCampaignAll = (token, query = "") =>
  getData(url.GET_CAMPAIGN_ALL + query, token)
export const getCampaign = (uuid, token) =>
  getData(url.GET_CAMPAIGN + uuid, token)
export const getCampaignAdmin = (uuid, token) =>
  getData(url.GET_CAMPAIGN_ADMIN + uuid, token)

export const getAkadType = token => getData(url.GET_AKAD_TYPE, token)

export const postCampaignEdit = (uuid, data, token) =>
  postData(url.PUT_CAMPAIGN + uuid, data, token)

export const postDescription = (uuid, data, token) =>
  postData(`/campaigns/${uuid}${url.POST_DESCRIPTION}`, data, token)
export const postGallery = (uuid, data, token) =>
  postData(`/campaigns/${uuid}${url.POST_GALLERY}`, data, token)
export const postProjectUpdate = (uuid, data, token) =>
  postData(`/campaigns/${uuid}${url.POST_UPDATE}`, data, token)
export const getTitleDescription = (token, query = "") =>
  getData(url.GET_TITLE_DESCRIPTION + query, token)
export const getTitleDescriptionEn = (token, query = "") =>
  getData(url.GET_TITLE_DESCRIPTION + query, token, "en")

export const postPublishCampaign = (uuid, token) =>
  postData(url.POST_PUBLISH_CAMPAIGN + uuid, {}, token)
export const postUnpublishCampaign = (uuid, token) =>
  postData(url.POST_UNPUBLISH_CAMPAIGN + uuid, {}, token)

// Akad
export const getAkadAll = token => getData(url.GET_AKAD_ALL, token)
export const getAkadTitle = token => getData(url.GET_AKAD_TITLE, token)
export const getContract = token => getData(url.GET_IMPLEMENT_CONTRACT, token)
export const postAkadMusyarakah = (campaignId, data, token) =>
  postData(url.POST_AKAD_MUSYARAKAH + campaignId, data, token)
export const postAkadWakalah = (uuid, data, token) =>
  postData(url.POST_AKAD_WAKALAH + uuid, data, token)
export const postAkadMurabahah = (uuid, data, token) =>
  postData(url.POST_AKAD_MURABAHAH + uuid, data, token)
export const getAkadByCampaign = (id, token) =>
  getData(url.GET_AKAD_BY_CAMPAIGN + id, token)

export const getAkadReady = token => getData(url.GET_AKAD_READY, token)
export const getAkadPublish = token => getData(url.GET_AKAD_PUBLISH, token)
export const getAkadActive = token => getData(url.GET_AKAD_ACTIVE, token)
export const getAkadNonActive = token => getData(url.GET_AKAD_NONACTIVE, token)

// Campaign Client
export const getCampaignActive = (queryParam = "") =>
  getData(url.GET_CAMPAIGN_ACTIVE + queryParam)

export const postCreateVA = (data, token) =>
  postData(url.POST_CREATE_VA, data, token)

//Portfolio
export const getPortofolio = (token, query = "") =>
  getData(url.GET_PORTOFOLIO + query, token)
export const getTransaction = (uuid, token) =>
  getData(url.GET_TRANSACTION_BY_CAMPAIGN + uuid, token)

export const getTRX = (uuid, token) => getData(url.GET_TRX + uuid, token)

export const postDocWak = (campaignId, data, token) =>
  postData(url.POST_DOC_WAKALAH + campaignId, data, token)
export const postDocMus = (campaignId, data, token) =>
  postData(url.POST_DOC_MUSYARAKAH + campaignId, data, token)
export const postDocBilUjrah = (campaignId, data, token) =>
  postData(url.POST_DOC_BILUJROH + campaignId, data, token)
export const postDocAddendum = (campaignId, trxId, addendumId, data, token) =>
  postData(url.POST_DOC_ADDENDUM(campaignId, trxId, addendumId), data, token)
export const getWakalahBilUjrah = (campaignId, token) =>
  getData(`${url.GET_WAKALAH_BILUJRAH}${campaignId}/populate/bil-ujrah`, token)

export const getPortofolioCampaign = (token, query = "") =>
  getData(url.GET_PORTOFOLIO_CAMPAIGN + query, token)
export const getPortofolioDetail = (id, token, query = "") =>
  getData(`${url.GET_PORTOFOLIO_DETAIL}${id}/list${query}`, token)

//lupa password
export const postForgetPassword = data =>
  postData(url.POST_FORGET_PASSWORD, data)
export const postCreatePassword = (data, token) =>
  postData(`/user/forgot-password/${token}/change`, data)

// campaign
export const delCampaignDesc = (campaignId, descId, token) =>
  delData(`/campaigns/${campaignId}/description/delete/${descId}`, token)
export const delCampaignGal = (campaignId, descId, token) =>
  delData(`/campaigns/${campaignId}/gallery/delete/${descId}`, token)
export const delCampaignUpd = (campaignId, descId, token) =>
  delData(`/campaigns/${campaignId}/update/delete/${descId}`, token)

// Payout
export const getTrxCampaigns = (campaignId, token, query = "") =>
  getData(url.GET_TRX_CAMPAIGN + campaignId + query, token)
export const getRepaymentType = token => getData(url.GET_REPAYMENT_TYPE, token)
export const postPayout = (id, data, token) =>
  postData(url.POST_PAYOUT + id, data, token)
export const getPayoutUser = (id, token) =>
  getData(url.GET_PAYOUT_USER + id, token)
export const downloadPayout = (id, token) =>
  getData(`${API_URL_1}/campaigns/${id}/export`, token)
export const getTrxCampaignsInvestment = (campaignId, token) =>
  getData(url.GET_TRX_CAMPAIGN_INVESTMENT + campaignId, token)
export const postImportPayout = (data, token) =>
  postData(url.POST_IMPORT_PAYOUT, data, token)
export const getTrxHistories = (campaignId, token) =>
  getData(`${url.GET_TRX_HISTORIES}${campaignId}/histories`, token)
export const postEditPayout = (id, data, token) =>
  postData(url.POST_EDIT_PAYOUT + id, data, token)
export const postImportUpdatePayout = (data, token) =>
  postData(url.POST_UPDATE_PAYOUT, data, token)
export const delPayout = (id, token) =>
  delData(`${url.POST_EDIT_PAYOUT}${id}/delete`, token)
export const getCollectionId = (id, token) =>
  getData(`${url.GET_COLLECTION_ID}${id}/populate`, token)

// user
export const getUsersAll = (token, query = "") =>
  getData(url.GET_USER_ALL + query, token)
export const getRoleAll = token => getData(url.GET_ROLE_ALL, token)
export const postRoleUser = (id, data, token) =>
  postData(`/user/change/${id}/roles`, data, token)

//admin
export const getApprovalHistory = (token, query = "") =>
  getData(url.GET_APPROVAL_HISTORY + query, token)
export const getApprovalHistoryProyek = (token, query = "") =>
  getData(url.GET_APPROVAL_HISTORY_PROYEK + query, token)

export const postFullyFunded = (id, token) =>
  postData(url.POST_FULLY_FUNDED + id, {}, token)

// edit project
export const getProjectEdit = (id, token) =>
  getData(url.GET_PROJECT_EDIT + id, token)
export const postEditUkm = (id, data, token) =>
  postData(url.POST_UKM_EDIT + id, data, token)
export const postEditProperty = (id, data, token) =>
  postData(url.POST_PROPERTY_EDIT + id, data, token)

export const getNotif = token => getData(url.GET_NOTIFICATION, token)

export const getCheckBooked = (id, token) =>
  getData(url.GET_CHECK_BOOKED + id, token)

export const getProjectApproved = token =>
  getData(url.GET_PROJECT_APPROVED, token)
export const postCreateCampaign = (id, token) =>
  postData(url.POST_CREATE_CAMPAIGN + id, {}, token)

// user kyc
export const getUserKYCNotVerified = token =>
  getData(url.GET_USER_KYC_NOT_VERIFIED, token)

// dashboard user
export const getDashboardUser = token => getData(url.GET_DASHBOARD_USER, token)

// risk
export const getPopulateRisk = (token, query = "") =>
  getData(url.GET_RISK + query, token)
export const getPopulateRiskProperty = token =>
  getData(url.GET_RISK_PROPERTY, token)
export const postApproveRisk = (id, data, token) =>
  postData(url.POST_RISK_APPROVE + id, data, token)
export const getHistoryRisk = token => getData(url.GET_HISTORY_RISK, token)

// bisnis
export const getResultRisk = token => getData(url.GET_RESULT_RISK, token)
export const postBisnisDecision = (id, data, token) =>
  postData(url.POST_BISNIS_DECISION + id, data, token)
export const getHistoryBisnisDecision = token =>
  getData(url.GET_HISTORY_BISNIS_DECISION, token)
export const getHistoryApprovalBisnisDecision = (token, query = "") =>
  getData(url.GET_HISTORY_BISNIS_DECISION_HISTORIES + query, token)

// list akad
export const getCampaignByProject = (id, token) =>
  getData(url.GET_CAMPAIGN_BY_PROJECT + id, token)
export const getListAkadMusyarakah = (id, token, query = "") =>
  getData(
    `${url.GET_LIST_AKAD_MUSYARAKAH}${id}/populate/musyarakah${query}`,
    token
  )

// chat
export const getFriends = token => getData(url.GET_FRIENDS, token)
export const getSession = token => getData(url.GET_SESSION, token)
export const postSession = (data, token) =>
  postData(url.POST_SESSION, data, token)
export const postMessages = (session, data, token) =>
  postData(`${url.POST_MESSAGES}${session}`, data, token)
export const postGetChat = (session, token) =>
  postData(`${url.POST_GET_CHAT}${session}/chats`, {}, token)
export const postReadChat = (session, token) =>
  postData(`${url.POST_READ_CHAT}${session}/read`, token)
export const postClearSession = (session, token) =>
  postData(`${url.POST_CLEAR_SESSION}${session}/clear`, token)

// collection
export const getAllOwned = token => getData(url.GET_ALL_OWNED, token)
export const getAllCollection = token => getData(url.GET_ALL_COLLECTION, token)
export const getCollectionByCampaign = (id, token) =>
  getData(url.GET_COLLECTION_BY_CAMPAIGN + id, token)
export const postCollection = (data, token) =>
  postData(url.POST_COLLECTION, data, token)
export const postEditCollection = (id, data, token) =>
  postData(url.POST_UPDATE_COLLECTION + id, data, token)
export const delCollection = (id, token) =>
  delData(url.DEL_COLLECTION + id, token)
export const postVaCollection = (data, token) =>
  postData(url.POST_VA_COLLECTION, data, token)
export const getVaByUser = token => getData(url.GET_VA_NUMBER, token)
export const getVaByCampaign = (id, token) =>
  getData(url.GET_VA_BY_CAMPAIGN + id, token)
export const postImportCollection = (data, token) =>
  postData(url.POST_IMPORT_COLLECTION, data, token)
export const createVaRdlBniCollection = (data, token) =>
  postData(url.CREATE_VA_RDL_COLLECTION, data, token)

// Scoring
export const getSubCategoryOne = (token, query = "") =>
  getData(url.GET_ALL_SUBCATEGORY_ONE + query, token)
export const getSubCategoryOneOnTabRisk = (token, query = "") =>
  getData(url.GET_ALL_SUBCATEGORY_ONE_ON_TAB_RISK + query, token)
export const postScoringOne = (id, data, token) =>
  postData(`${url.POST_SCORING_ONE}${id}/step-one/save`, data, token)
export const getCategoryTwo = token => getData(url.GET_CATEGORY_TWO, token)
export const postScoringTwo = (id, data, token) =>
  postData(`${url.POST_SCORING_TWO}${id}/step-two/save`, data, token)
export const getScoringResult = (id, token) =>
  getData(`${url.GET_SCORING_RESULT}${id}/results`, token)
export const getScoringResultProperty = (id, token) =>
  getData(`${url.GET_SCORING_RESULT_PROPERTY}${id}/result`, token)
export const getSubCategoryProperty = token =>
  getData(url.GET_ALL_SUBCATEGORY_PROPERTY, token)

export const getAnswerScoring = (id, token) =>
  getData(url.GET_ANSWER_SCORING + id, token)

export const postImpersonate = (id, token) =>
  postData(url.POST_IMPERSONATE + id, {}, token)

// Edit Campaign
export const putCampaignDesc = (cmpgnId, id, data, token) =>
  postData(
    `${url.POST_EDIT_CAMPAIGN}${cmpgnId}/description/${id}/edit`,
    data,
    token
  )
export const putCampaignGall = (cmpgnId, id, data, token) =>
  postData(
    `${url.POST_EDIT_CAMPAIGN}${cmpgnId}/gallery/${id}/edit`,
    data,
    token
  )
export const putProjectUpdate = (cmpgnId, id, data, token) =>
  postData(`${url.POST_EDIT_CAMPAIGN}${cmpgnId}/update/${id}/edit`, data, token)
export const getProjectUpdate = (cmpgnId, id, token) =>
  getData(`${url.POST_EDIT_CAMPAIGN}${cmpgnId}/update/show/${id}`, token)

// get campaign selector
export const getCampaignSelector = (token, query = "") =>
  getData(url.GET_CAMPAIGN_SELECTOR + query, token)

// Change Mail
export const postChangeMail = (data, token) =>
  postData(url.POST_CHANGE_MAIL, data, token)
export const postChangeMailVerif = (data, token) =>
  postData(url.POST_CHANGE_MAIL_VEFIF, data, token)

// Disbursement
export const postDisburse = (id, data, token) =>
  postData(`${url.DISBURSE}${id}/disbursement/create`, data, token)
export const getAllDisburse = (id, token) =>
  getData(`${url.DISBURSE}${id}/disbursement/populate`, token)
export const getDisburse = (cmpgnId, selectId, token) =>
  getData(`${url.DISBURSE}${cmpgnId}/disbursement/show/${selectId}`, token)
export const postUpdateDisburse = (cmpgnId, selectId, data, token) =>
  postData(
    `${url.DISBURSE}${cmpgnId}/disbursement/edit/${selectId}`,
    data,
    token
  )
export const delDisburse = (cmpgnId, selectId, token) =>
  delData(`${url.DISBURSE}${cmpgnId}/disbursement/delete/${selectId}`, token)

// Delete Campaign and Cancel Campaign
export const delCampaign = (id, token) => delData(url.DEL_CAMPAIGN + id, token)
export const cancelCampaign = (id, token) =>
  postData(url.CANCEL_CAMPAIGN + id, {}, token)

// Verify phone number
export const postCreateCodeSms = (data, token) =>
  postData(url.POST_CREATE_CODE_SMS, data, token)
export const postClaimCodeSms = (data, token) =>
  postData(url.POST_CLAIM_CODE_SMS, data, token)

// List Akad for Admin
export const getMusyarakahAdmin = (cmpgnId, token) =>
  getData(`${url.GET_AKADS_ADMIN}${cmpgnId}/all/musyarakah`, token)
export const getMurabahahAdmin = (cmpgnId, token) =>
  getData(`${url.GET_AKADS_ADMIN}${cmpgnId}/all/murabahah`, token)
export const getWakalahAdmin = (cmpgnId, token) =>
  getData(`${url.GET_AKADS_ADMIN}${cmpgnId}/all/wakalah`, token)
export const getBilUjrahAdmin = (cmpgnId, token) =>
  getData(`${url.GET_AKADS_ADMIN}${cmpgnId}/all/bil-ujrah`, token)

// trx
export const postPledge = (data, token) =>
  postData(url.POST_PLEDGE, data, token)
export const postVaNew = (trxId, token) =>
  postData(`${url.POST_PLEDGE_CREATE_VA}/${trxId}/create-va`, {}, token)

// meeting internal
export const getQuestionMeet = token =>
  getData(url.GET_QUESTION_POPULATE, token)
export const postAnswerMeet = (id, data, token) =>
  postData(`${url.POST_ANSWER_MEET}${id}/save`, data, token)
export const getResultMeet = (id, token) =>
  getData(`${url.GET_RESULT_MEET}${id}/result`, token)

// get score all
export const getScoreAll = (id, token) =>
  getData(`${url.GET_SCORE_ALL}${id}/result`, token)

// refund
export const postRefund = (trxId, data, token) =>
  postData(`${url.REFUND}${trxId}/create`, data, token)
export const getRefund = (trxId, refundId, token) =>
  getData(`${url.REFUND}${trxId}/show/${refundId}`, token)
export const delRefund = (trxId, refundId, token) =>
  delData(`${url.REFUND}${trxId}/delete/${refundId}`, token)
export const getRefundByCampaign = (cmpgnId, token) =>
  getData(`${url.GET_REFUND}${cmpgnId}/refund`, token)

// order campaign
export const getOrderCampaign = token => getData(url.GET_ORDER_CAMPAIGN, token)
export const postOrderCampaign = (data, token) =>
  postData(url.POST_ORDER_CAMPAIGN, data, token)

// get user verified
export const getUserVerified = (token, query = "") =>
  getData(`${url.GET_USER_VERIFIED}${query}`, token)

// additional data BD
export const getAdditional = token => getData(url.GET_ADDITIONAL, token)
export const postAdditional = (id, data, token) =>
  postData(`${url.POST_ADDITIONAL}${id}/create`, data, token)

// unpublish campaign fullyfunded
export const postUnpublishCampaignFully = (id, token) =>
  postData(`${url.POST_UNPUBLISH_CAMPAIGN_FULLY}${id}`, {}, token)

// notif
export const getNotifications = token => getData(url.GET_NOTIFICATIONS, token)
export const readNotification = (id, token) =>
  postData(`${url.READ_NOTIFICATION}${id}`, {}, token)

export const getVoucher = token => getData(url.GET_VOUCHER, token)
export const postVoucher = (data, token) =>
  postData(url.POST_VOUCHER, data, token)
export const updateVoucher = (id, data, token) =>
  postData(`${url.UPDATE_VOUCHER}${id}`, data, token)
export const getClaimVoucher = (id, token) =>
  getData(`${url.GET_VOUCHER_CLAIMED}${id}/populate`, token)

// Code register email
export const postCodeRegisterEmail = data =>
  postData(url.POST_CODE_REGISTER_EMAIL, data, "")
export const claimCodeRegisterEmail = data =>
  postData(url.CLAIM_CODE_REGISTER_EMAIL, data, "")

// Carousel
export const postCarousel = (data, token) =>
  postData(url.POST_CAROUSEL, data, token)
export const updateCarousel = (id, data, token) =>
  postData(`${url.UPDATE_CAROUSEL}${id}`, data, token)
export const getCarousel = () => getData(url.GET_CAROUSEL)
export const getCarouselAdmin = token => getData(url.GET_CAROUSEL_ADMIN, token)

// Group Chat
export const postGroup = (data, token) => postData(url.POST_GROUP, data, token)
export const getGroup = token => getData(url.GET_GROUP, token)
export const postMessageGroup = (data, token) =>
  postData(url.POST_MESSAGE_GROUP, data, token)
export const getMessageGroup = (id, token) =>
  getData(`${url.GET_MESSAGE_GROUP}${id}/get-message`, token)
export const readMessageGroup = (id, token) =>
  getData(`${url.GET_MESSAGE_GROUP}${id}/read-message`, token)

// Private Chat
export const getUsersAllDropdown = (token, query) =>
  getData(url.GET_USERS_ALL + query, token)
export const sendMessagePrivate = (data, token) =>
  postData(url.SEND_MESSAGE_PRIVATE, data, token)
export const getMessagePrivate = token =>
  getData(url.GET_MESSAGE_PRIVATE, token)
export const readMessagePrivate = (id, token) =>
  getData(`${url.READ_MESSAGE_PRIVATE}${id}/read-message`, token)

//Blog
export const getBlogAll = token => getDataBlog(url.GET_BLOG_ALL, token)
export const createBlog = (data, token) =>
  postDataBlog(url.CREATE_BLOG, data, token)
export const updateBlog = (id, data, token) =>
  postDataBlog(url.UPDATE_BLOG + id, data, token)
export const getBlogByUuid = (id, token) =>
  getDataBlog(url.GET_BLOG_BY_UUID + id, token)
export const getBlogBySlug = slug => getDataBlog(url.GET_BLOG_BY_SLUG + slug)
export const deleteBlog = (id, token) =>
  delDataBlog(url.DELETE_BLOG + id, token)
export const createCategory = (data, token) =>
  postDataBlog(url.CREATE_CATEGORY, data, token)
export const updateCategory = (id, data, token) =>
  postDataBlog(`${url.UPDATE_CATEGORY}${id}`, data, token)
export const deleteCategory = (id, token) =>
  delDataBlog(`${url.DELETE_CATEGORY}${id}`, token)
export const getCategory = token => getDataBlog(url.GET_CATEGORY, token)
export const getCategoryAdmin = token =>
  getDataBlog(url.GET_CATEGORY_ADMIN, token)
export const getBlogByCat = (id, token) =>
  getDataBlog(`${url.GET_BLOG_BY_CATEGORY}${id}/populate-post`, token)

// Webinar
export const createWebinar = (data, token) =>
  postDataBlog(url.CREATE_WEBINAR, data, token)
export const createFileWebinar = (id, data, token) =>
  postDataBlog(`${url.CREATE_FILE_WEBINAR}${id}/upload/file`, data, token)
export const updateWebinar = (id, data, token) =>
  postDataBlog(`${url.UPDATE_WEBINAR}${id}`, data, token)
export const uploadFileWebinar = (id, data, token) =>
  postDataBlog(`${url.UPDATE_FILE_WEBINAR}${id}/upload/file`, data, token)
export const getWebinarByUuid = (id, token) =>
  getDataBlog(`${url.GET_WEBINAR_BY_UUID}${id}`, token)
export const getWebinarBySlug = slug =>
  getDataBlog(`${url.GET_WEBINAR_BY_SLUG}${slug}`)
export const getWebinarPublic = () => getDataBlog(url.GET_WEBINAR_PUBLIC)
export const getWebinarEthis = token =>
  getDataBlog(url.GET_WEBINAR_ETHIS, token)
export const getResponseWebinar = token =>
  getDataBlog(url.GET_RESPONSE_WEBINAR, token)
export const getResponseWebinarAdmin = token =>
  getDataBlog(url.GET_RESPONSE_WEBINAR_ADMIN, token)
export const deleteWebinar = (id, token) =>
  delDataBlog(`${url.DEL_WEBINAR}${id}`, token)
export const registerWebinar = (id, data) =>
  postDataBlog(`${url.REGISTER_WEBINAR}${id}/register`, data)
export const getRecentWebinar = (query = "") =>
  getDataBlog(`${url.GET_RECENT_WEBINAR}${query}`)

export const createVaFaspay = (id, token) =>
  postData(`${url.CREATE_VA_FASPAY}${id}/create-va`, {}, token)
export const createVaFaspayColl = (data, token) =>
  postData(`${url.CREATE_VA_FASPAY_COLLECTION}`, data, token)

// Setting
export const getSettings = (token, query = "") =>
  getData(url.GET_SETTINGS + query, token)
export const updateSetting = (id, data, token) =>
  postData(`${url.UPDATE_SETTING}${id}`, data, token)

// Iluma
export const checkBankAccount = (data, token) =>
  postData(url.CHECK_BANK_ACCOUNT, data, token)

// Banned user
export const bannedUser = (id, data, token) =>
  postData(`${url.BANNED_USER}${id}/banned`, data, token)
export const unBannedUser = (id, data, token) =>
  postData(`${url.BANNED_USER}${id}/un-banned`, data, token)
export const getUserBanned = token => getData(url.GET_USER_BANNED, token)

// google analytics
export const getTopBrowser = (query = "") =>
  getData(url.GET_TOP_BROWSER + query)
export const getTopRefferer = (query = "") =>
  getData(url.GET_TOP_REFFERER + query)
export const getRealTimeUser = (query = "") =>
  getData(url.GET_REALTIME_USER + query)
export const getMostVisited = (query = "") =>
  getData(url.GET_MOST_VISITED + query)

// helpdesk
export const getHelpdesk = (token, query = "") =>
  getDataHelpdesk(url.GET_HELPDESK + query, token)
export const postAssignTicket = (id, data, token) =>
  postDataHelpdesk(url.POST_ASSIGN_TICKET(id), data, token)
export const getTicketAssign = (token, query = "") =>
  getDataHelpdesk(url.GET_TICKET_ASSIGN + query, token)
export const postSolvingTicket = (id, data, token) =>
  postDataHelpdesk(url.POST_SOLVING_TICKET(id), data, token)
export const postClosingTicket = (id, data, token) =>
  postDataHelpdesk(url.POST_CLOSING_TICKET(id), data, token)
export const getMessagesTicket = (id, token) =>
  getDataHelpdesk(url.GET_MESSAGES_TICKET(id), token)
export const postMessagesTicket = (id, data, token) =>
  postDataHelpdesk(url.POST_MESSAGES_TICKET(id), data, token)

// mailchimp
export const createList = data => postData(url.CREATE_LIST, data)
export const updateList = (id, data) => postData(url.UPDATE_LIST(id), data)
export const deleteList = id => delData(url.DELETE_LIST(id))
export const getList = () => getData(url.GET_LIST_ALL)

export const requestRestructure = (id, data, token) =>
  postData(url.REQUEST_RESTRUCTURE(id), data, token)
export const updateRequestRestructure = (id, data, token) =>
  postData(url.UPDATE_REQUEST_RESTRUCTURE(id), data, token)
export const getAllRestructure = (query = "", token) =>
  getData(url.GET_ALL_RESTRUCTURE(query), token)
export const getRestructureById = (id, token) =>
  getData(url.GET_RESTRUCTURE_BY_ID(id), token)

export const getListTransactionAddendum = (id, token, query = "") =>
  getData(url.GET_LIST_TRANSACTION_ADDENDUM(id, query), token)
export const getListTransactionAddendumV2 = (
  idCampaign,
  idAddendum,
  token,
  query = ""
) =>
  getData(
    url.GET_LIST_TRANSACTION_ADDENDUM_V2(idCampaign, idAddendum, query),
    token
  )
export const updateAkadAddendum = (id, token) =>
  postData(url.UPDATE_AKAD_ADDENDUM(id), data, token)

export const updateStartDateProject = (id, data, token) =>
  postData(url.UPDATE_START_DATE_PROJECT(id), data, token)

// expired va
export const expiredVa = (id, token) => postData(url.EXPIRED_VA(id), {}, token)
export const expiredVaRDL = (id, token) =>
  postData(url.EXPIRED_VA_RDL(id), {}, token)
export const expiredVaXendit = (id, token) =>
  postData(url.EXPIRED_VA_XENDIT(id), {}, token)
// addendum
export const getAkadAddendum = (id, query, token) =>
  getData(url.GET_AKAD_ADDENDUM(id, query), token)

// roleback project analyst to BD
export const postRolebackProject = (id, data, token) =>
  postData(url.POST_ROLEBACK_PROJECT(id), data, token)

// broadcast
export const postBroadcast = (data, token) =>
  postData(url.POST_BROADCAST, data, token)
export const getBroadcast = (query = "", token) =>
  getData(url.GET_BROADCAST + query, token)

// RDL
export const getTransactionRdl = (id, token, query = "") =>
  getData(url.GET_TRANSACTION_RDL(id) + query, token)
export const postSendToEscrow = (data, token) =>
  postData(url.POST_SEND_TO_ESCROW, data, token)
export const uploadTax = (id, data, token) =>
  postData(url.UPLOAD_TAX(id), data, token)

// approval payout
export const getApprovalPayout = (token, query = "") =>
  getData(url.GET_APPROVAL_PAYOUT + query, token)
export const postApprovalPayout = (data, token, query = "") =>
  postData(url.POST_APPROVAL_PAYOUT + query, data, token)
export const getApprovalHistoryPayout = (token, query = "") =>
  getData(url.GET_APPROVAL_HISTORY_PAYOUT + query, token)
export const getDetailApprovalPayout = (id, token) =>
  getData(url.GET_DETAIL_APPROVAL_PAYOUT(id), token)
export const getDetailApprovalPayoutHistories = (id, token) =>
  getData(url.GET_DETAIL_APPROVAL_PAYOUT_HISTORIES(id), token)

// mailchimp
export const createTemplate = (data, token) =>
  postData(url.CREATE_TEMPLATE_EMAIL, data, token)
export const getTemplate = token => getData(url.GET_TEMPLATE_EMAIL, token)
export const getAudience = token =>
  getData(url.GET_LIST_AUDIENCE_MAILCHIMP, token)
export const getTag = (id, token) => getData(url.GET_TAGS_MAILCHIMP(id), token)
export const createCampaignMailchimp = (data, token) =>
  postData(url.CREATE_CAMPAIGN_MAILCHIMP, data, token)
export const getCampaignMailchimp = token =>
  getData(url.GET_CAMPAIGN_MAILCHIMP, token)
export const sendEmailMailchimp = (id, token) =>
  postData(url.SEND_EMAIL_MAILCHIMP(id), token)

// dttot
export const getListDttot = (token, query = "") =>
  getData(url.GET_LIST_DTTOT + query, token)
export const approvalDttot = (id, data, token) =>
  postData(url.APPROVAL_DTTOT(id), data, token)
export const getHistoryDttot = (token, query = "") =>
  getData(url.GET_HISTORY_DTTOT + query, token)
export const getStatusIlluma = (token, query = "") =>
  getData(url.GET_STATUS_ILLUMA + query, token)
export const getStatusDttot = (token, query = "") =>
  getData(url.GET_STATUS_DTTOT + query, token)

// get user profile details
export const getProfileDetails = (id, token) =>
  getData(url.GET_PROFILE_DETAILS(id), token)

export const getRefferer = (token, query = "") =>
  getData(url.GET_REFFERER_LIST + query, token)
export const createRefferer = (data, token) =>
  postData(url.CREATE_REFFERER, data, token)
export const updateRefferer = (id, data, token) =>
  postData(url.UPDATE_REFFERER(id), data, token)
export const getUserRefferer = (token, query = "") =>
  getData(url.GET_USER_REFFERER + query, token)

export const createVaXendit = (data, token) =>
  postData(url.CREATE_VA_XENDIT, data, token)
export const getBankListXendit = token =>
  getData(url.GET_BANK_LIST_XENDIT, token)

export { postRegister, postLogin, getUserLogin, postUserLogout }
