import React from "react"
import Skeleton from "react-loading-skeleton"
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap"

const DataTable = props => {
  const {
    table,
    setPerPage,
    perPage,
    page,
    setPage,
    currentPage,
    setCurrentPage,
    totalPage,
    setTotalPage,
    handleSearch,
    search,
    maxPages = 7,
    pagination,
    dataKey,
    keySearch,
    setKeySearch,
    handleGetSearch,
    searching,
    dataKeyCustom,
    loading,
    order,
    orderClick,
  } = props

  // console.log(currentPage == i)

  const renderPaginate = numb => {
    // ensure current page isn't out of range
    // if (currentPage < 1) {
    //   // currentPage = 1
    //   console.log("1", currentPage)
    // } else if (currentPage > totalPage) {
    //   // currentPage = totalPage
    //   console.log("2", currentPage)
    // }
    // console.log("3", currentPage, totalPage)

    let startPage
    let endPage

    if (totalPage <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1
      endPage = totalPage
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1

      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1
        endPage = maxPages
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPage) {
        // current page near the end
        startPage = totalPage - maxPages + 1
        endPage = totalPage
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage
        endPage = currentPage + maxPagesAfterCurrentPage
      }
    }

    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    )

    let result = []
    for (let i = 0; i <= pages.length - 1; i++) {
      result.push(
        <PaginationItem active={pages[i] == currentPage}>
          <PaginationLink onClick={() => setPage(pages[i])}>
            {pages[i]}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const handleEnterSearch = e => {
    if (e.key === "Enter") {
      handleGetSearch()
    }
  }

  if (loading) {
    return (
      <div className="pt-3 pb-3">
        <div
          className="table-responsive"
          style={{ maxHeight: 500, overflow: "auto" }}
        >
          <Table className="table table-striped table-bordered mb-0">
            <thead>
              <tr>
                {table &&
                  table.columns.map((item, i) => {
                    return (
                      <th
                        key={i}
                        className="position-sticky text-nowrap"
                        style={{ top: 0, backgroundColor: "#fcfcfa" }}
                      >
                        {item.label}
                      </th>
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {true ? (
                [1, 2, 3, 4, 5, 6, 7, 8].map((item, i) => {
                  return (
                    <tr key={i + "tr"}>
                      {table &&
                        table.columns.map((itemInner, idx) => {
                          return (
                            <td key={idx + "td"}>
                              <Skeleton />
                            </td>
                          )
                        })}
                    </tr>
                  )
                })
              ) : (
                <tr className="text-center">
                  <td colSpan={table.columns.length}>Tidak ada data</td>
                </tr>
              )}
            </tbody>
            <thead>
              <tr>
                {table &&
                  table.columns.map((item, i) => {
                    return <th key={i}>{item.label}</th>
                  })}
              </tr>
            </thead>
          </Table>
        </div>
      </div>
    )
  }

  return (
    <div className="pt-3">
      <div className="d-flex align-items-center justify-content-end">
        {searching && (
          <FormGroup className="d-flex" style={{ width: 400 }}>
            <Input
              type="select"
              name="keySearch"
              className="form-control-sm w-50"
              value={keySearch}
              onChange={e => setKeySearch(e.target.value)}
            >
              {dataKeyCustom?.length > 0
                ? dataKeyCustom.map(item => {
                    return <option value={item.value}>{item.label}</option>
                  })
                : dataKey.map(item => {
                    if (
                      item === "type" ||
                      item === "id" ||
                      item === "uuid" ||
                      item === "user_role"
                    )
                      return null
                    return (
                      <option value={item}>{item.split("_").join(" ")}</option>
                    )
                  })}
            </Input>
            <InputGroup>
              <Input
                type="text"
                name="search"
                className="form-control-sm"
                placeholder="Cari"
                value={search}
                onChange={e => {
                  if (page !== 1) {
                    setPage(1)
                  }
                  handleSearch(e.target.value)
                }}
                autoFocus
                onKeyDown={handleEnterSearch}
              />
              <InputGroupAddon addonType="append">
                <Button className="btn-sm" onClick={handleGetSearch}>
                  Cari
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        )}
      </div>

      <div
        className="table-responsive"
        style={{ maxHeight: 500, overflow: "auto" }}
      >
        <Table className="table table-striped table-bordered mb-0">
          <thead>
            <tr>
              {table &&
                table.columns.map((item, i) => {
                  return (
                    <th
                      key={i}
                      className={`position-sticky text-nowrap ${
                        item?.order ? "cursor-pointer" : ""
                      }`}
                      style={{ top: 0, backgroundColor: "#fcfcfa" }}
                      onClick={() => {
                        if (item?.order) {
                          if (!order) {
                            orderClick({ label: item.field, value: "ASC" })
                          } else {
                            if (item.field == order?.label) {
                              if (order?.value == "ASC") {
                                orderClick({ label: item.field, value: "DESC" })
                              } else {
                                orderClick(null)
                              }
                            } else {
                              orderClick({ label: item.field, value: "ASC" })
                            }
                          }
                        }
                      }}
                    >
                      {item.label}{" "}
                      {Boolean(order?.label == item?.field && item?.order) && (
                        <>
                          {order?.value == "DESC" && (
                            <i className="fas fa-arrow-down font-10 ml-1" />
                          )}
                          {order?.value == "ASC" && (
                            <i className="fas fa-arrow-up font-10 ml-1" />
                          )}
                        </>
                      )}
                    </th>
                  )
                })}
            </tr>
          </thead>
          <tbody>
            {table && table.rows.length > 0 ? (
              table.rows.map((item, i) => {
                return (
                  <tr key={i + "tr"}>
                    {table &&
                      table.columns.map((itemInner, idx) => {
                        return <td key={idx + "td"}>{item[itemInner.field]}</td>
                      })}
                  </tr>
                )
              })
            ) : (
              <tr className="text-center">
                <td colSpan={table.columns.length}>Tidak ada data</td>
              </tr>
            )}
          </tbody>
          <thead>
            <tr>
              {table &&
                table.columns.map((item, i) => {
                  return <th key={i}>{item.label}</th>
                })}
            </tr>
          </thead>
        </Table>
      </div>
      {table && table.rows.length > 0 ? (
        <div className="d-flex align-items-center justify-content-between mt-3 paginate-custom">
          <div className="d-flex align-items-center">
            <div className="mr-3 d-flex align-items-center">
              <Input
                type="select"
                name="perPage"
                className="form-control-sm"
                value={perPage}
                onChange={e => setPerPage(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Input>
            </div>
            <div>
              Showing {pagination && pagination.from} to{" "}
              {pagination && pagination.to} of {pagination && pagination.total}{" "}
              entries
            </div>
          </div>
          <Pagination>
            <PaginationItem disabled={page === 1}>
              <PaginationLink first onClick={() => setPage(1)} />
            </PaginationItem>
            <PaginationItem disabled={page === 1}>
              <PaginationLink previous onClick={() => setPage(page - 1)} />
            </PaginationItem>
            {renderPaginate(totalPage)}
            <PaginationItem disabled={page === totalPage}>
              <PaginationLink next onClick={() => setPage(page + 1)} />
            </PaginationItem>
            <PaginationItem disabled={page === totalPage}>
              <PaginationLink last onClick={() => setPage(totalPage)} />
            </PaginationItem>
          </Pagination>
        </div>
      ) : null}
    </div>
  )
}

export default DataTable
