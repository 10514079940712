import {
    GET_PROFILE
} from "./actionTypes"

export const getProfile = profile => {
  return {
    type: GET_PROFILE,
    payload: profile,
  }
}

