import React from 'react';
import { useSelector } from 'react-redux';

import ic_arrow from './image/ic_arrow_green.svg';
import ic_cart from './image/ic_cart.svg';
import ic_profile from './image/ic_profile.svg';
// import ic_arrow_green from './image/ic_arrow.svg';

export default ({
  name,
  dropdownActive,
  setDropdownActive,
  handleActiveMenu,
  activeMenu,
  content,
  className,
  auth,
}) => {
  const handleClickMenu = () => {
    setDropdownActive(true);
    handleActiveMenu(name);
  };
  return (
    <div style={{ position: 'relative' }}>
      <div className="d-flex flex-row align-items-center">
        <div
          className={`navItem ${
            dropdownActive && activeMenu === name ? 'isActive' : ''
          }`}
          onClick={handleClickMenu}
          onKeyDown
        >
          <span>
            {name === 'ProfileNav' && auth && auth.user.email ? (
              // <a href>{`${auth.user.firstName} ${auth.user.lastName}`}</a>
              <a href>{`${
                auth.user.firstName.length < 8
                  ? `${auth.user.firstName}...`
                  : `${auth.user.firstName}`
              }`}</a>
            ) : (
              <a href>{name}</a>
            )}
          </span>
          <img
            src={ic_arrow}
            alt="arrow-item"
            className={`arrow-nav ${
              dropdownActive && activeMenu === name ? 'rotateArrowNav' : ''
            }`}
          />
        </div>
      </div>
      <div
        className={`dropdownMenu transitionDropdown ${className} ${
          dropdownActive && activeMenu === name ? 'm-fadeIn' : 'm-fadeOut'
        }`}
      >
        {content}
      </div>
    </div>
  );
};
