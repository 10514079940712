import Toaster from "components/Common/Toast"
import { API_URL } from "helpers/api_url"
import {
  getCampaign,
  getCampaignAdmin,
  getCheckBooked,
  postPledge,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import { Link, Redirect, withRouter } from "react-router-dom"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
  Progress,
  CardImg,
  Badge,
} from "reactstrap"
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap"
import classnames from "classnames"
import moment from "moment"
import { connect } from "react-redux"
import Loading from "pages/Utility/Loading"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const DetailCampaign = props => {
  const [detail, setDetail] = useState(null)
  const [activeTab, setactiveTab] = useState("1")
  const [nominal, setNominal] = useState("")
  const [codeVoucher, setCodeVoucher] = useState("")
  // const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)

  const [lightBox, setLightBox] = useState(false)
  const [galIndex, setGalIndex] = useState(0)

  useEffect(() => {
    getDetails()
  }, [])

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const renderSisaWaktu = date => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    return eventdate.diff(todaysdate, "days")
  }

  const handleCheckout = async () => {
    const token = localStorage.getItem("token")
    if (props.user.kyc_status < 9)
      return Toaster("error", "Maaf", "Akun anda belum terverifikasi")
    if (Number(detail.target) <= Number(detail.collected))
      return Toaster("error", "Maaf", "Kampanye telah terdanai")
    if (!nominal) return Toaster("error", "Maaf", "Masukkan Nominal")
    // if(Number(nominal) < 1000000) return Toaster("error", "Maaf", "Pembiayaan minimal Rp 1.000.000")
    if (Number(renderSisaWaktu(detail.end_date_campaign)) < 0)
      return Toaster("error", "Maaf", "Kampanye telah berakhir")
    if (Number(nominal) > Number(detail.target) - Number(detail.collected))
      return Toaster("error", "Maaf", "Nominal tidak tersedia")

    setLoadButt(true)
    const check = await getCheckBooked(props.match.params.uuid, token)
    const dataBooked = check.data

    let remaining = parseInt(dataBooked.target) - parseInt(dataBooked.booked)
    if (remaining < parseInt(nominal)) {
      setLoadButt(false)
      return Toaster(
        "error",
        "Maaf",
        `Nominal kampanye tersedia saat ini Rp ${remaining.toLocaleString()}`
      )
    }

    try {
      const postTrx = {
        trx_amount: nominal,
        description: `Pembiayaan proyek ${detail.title}`,
        campaign_id: detail.uuid,
      }
      if (codeVoucher) {
        postTrx.code_voc = codeVoucher
      }

      const createTrx = await postPledge(postTrx, token)

      localStorage.setItem(
        "pledge",
        JSON.stringify({
          campaign_uuid: props.match.params.uuid,
          nominal: nominal,
          campaign_name: detail.title,
        })
      )
      localStorage.setItem("trx", createTrx.data.uuid)
      setLoadButt(false)
      props.history.push("/highlight")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }

    // localStorage.setItem("pledge", JSON.stringify({campaign_uuid: props.match.params.uuid, nominal: nominal}))
    // props.history.push("/highlight")
  }

  const getDetails = async () => {
    const token = localStorage.getItem("token")
    // setLoad(true)
    try {
      const data = await getCampaignAdmin(props.match.params.uuid, token)
      setDetail(data.data)
      // setLoad(false)
    } catch (error) {
      // Toaster("error", "Kesalahan", "Periksa Koneksi anda dan refresh browser")
      // setLoad(false)
    }
  }

  const renderSisaWaktuText = (date, status) => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    let countDay = eventdate.diff(todaysdate, "days")
    if (countDay < 0) {
      return "Kampanye Berakhir"
    }
    if (status === 6) return "Target Terkumpul"
    return `${countDay} Hari Lagi`
  }

  const handleNisbah = () => {
    let modal = Number(nominal)
    let nisbah = Number(detail.nisbah) / 100
    return parseInt(modal * nisbah).toLocaleString()
  }

  const handleOnInput = evt => {
    const nominals = evt.target.validity.valid ? evt.target.value : nominal
    setNominal(nominals)
  }

  const CardDetail = item => {
    return (
      <div className="mb-20 sticky-card-campaign">
        <div className="card">
          <img
            draggable="false"
            src={API_URL + item.image}
            className="img-fluid"
            height="180px"
            width="100%"
          />

          <div className="f-11 project-card-body">
            <div className="project-head">
              <Row>
                <Col xs="12">
                  <p>
                    <b>{item.title}</b>
                  </p>
                </Col>
                <Col xs="12">
                  <Row>
                    <Col>
                      <p>
                        <i className="fa fa-map-marker-alt"></i>{" "}
                        {item.province.name}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <i className="fa fa-handshake"></i>{" "}
                        {item.akad_type.title}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <hr className="mt-2 mb-2"></hr>
            <div className="project-body">
              <div className="project-content">
                <Row className="mb-3">
                  <Col xs="12">
                    <Progress className="height-base" multi>
                      <Progress
                        animated
                        bar
                        color="success"
                        value={
                          (Number(item.collected) / Number(item.target)) * 100
                        }
                      />
                      <Progress
                        animated
                        bar
                        color="secondary"
                        value={
                          (Number(item.pledge) / Number(item.target)) * 100 -
                          (Number(item.collected) / Number(item.target)) * 100
                        }
                      />
                    </Progress>
                    <Row className="mt-2">
                      <Col md={6}>
                        <Badge color="primary">{` `}</Badge> Terkumpul{" "}
                        <b>
                          {(
                            (Number(item.collected) / Number(item.target)) *
                            100
                          ).toFixed(2)}
                          %
                        </b>
                      </Col>
                      <Col md={6}>
                        <Badge color="secondary">{` `}</Badge> Minat{" "}
                        <b>
                          {(
                            (Number(item.pledge) / Number(item.target)) *
                            100
                          ).toFixed(2)}
                          %
                        </b>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Target</p>
                    <p className="bold">
                      Rp {Number(item.target).toLocaleString()}
                    </p>
                  </Col>
                  <Col>
                    <p>Terkumpul</p>
                    <p className="bold">
                      Rp {Number(item.collected).toLocaleString()}
                    </p>
                  </Col>
                </Row>
                <div class="separator"></div>
                <Row>
                  <Col>
                    <p>Proyeksi Imbal Hasil</p>
                    <p className="bold">{item.nisbah} %</p>
                  </Col>
                  <Col>
                    <p>Tenor Proyek</p>
                    <p className="bold">{item.tenor} Bulan</p>
                  </Col>
                </Row>
                <div className="separator"></div>
                <Row>
                  <Col>
                    <p>Tanggal Berakhir</p>
                    <p className="bold">
                      {moment(item.end_date_campaign).format("DD MMM YYYY")}
                    </p>
                  </Col>
                  <Col>
                    <p>Sisa Waktu</p>
                    <p className="bold">
                      {renderSisaWaktuText(item.end_date_campaign, item.status)}
                    </p>
                  </Col>
                </Row>
                <div className="mt-2 mb-2">
                  {/* <div>Nominal Pembiayaan</div> */}
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Min. Rp 500.000"
                      // onChange={(e) => setNominal(e.target.value)}
                      onInput={handleOnInput}
                      // onWheel={(e) => e.target.blur()}
                      pattern="[0-9]*"
                      value={nominal}
                    />
                  </InputGroup>
                  <div>Estimasi Nisbah Rp {handleNisbah()}</div>
                </div>
                <div className="">
                  <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Kode Voucher</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder=""
                      onChange={e => setCodeVoucher(e.target.value)}
                    />
                  </InputGroup>
                  <div>Masukkan kode voucher jika ada.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="project-card-footer">
            <button
              className="btn btn-success btn-block campaign-btn"
              onClick={handleCheckout}
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Mulai Pembiayaan"
              )}
            </button>
          </div>
        </div>
      </div>
    )
  }

  if (!detail) {
    return (
      <SkeletonLoad title="" breadcrumbItem="">
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Row>
            <Col lg="4">
              <Skeleton height={500} />
            </Col>
            <Col lg="8">
              <Skeleton height={60} />
              <Skeleton height={300} />
              <Skeleton height={500} />
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="4" className="">
              {detail && CardDetail(detail)}
            </Col>
            <Col lg="8">
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1")
                        }}
                      >
                        Deskripsi
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2")
                        }}
                      >
                        Galleri
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
              </Card>

              {activeTab === "1" && detail.link_video ? (
                <Card>
                  <CardBody>
                    <iframe
                      width="100%"
                      height="315"
                      src={detail.link_video + `?autoplay=1&loop=1`}
                      title={detail.title}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              ) : null}

              <Card>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" className="p-3">
                    <Row className="">
                      {detail.campaign_description.map(item => {
                        return (
                          <>
                            <Col lg="12">
                              <Card>
                                <Row className="no-gutters">
                                  <Col md={4}>
                                    <CardImg
                                      className="img-fluid"
                                      src={API_URL + item.image}
                                      alt="Skote"
                                    />
                                  </Col>
                                  <Col md={8}>
                                    <CardBody className="pt-0 pb-0">
                                      <CardTitle className="mb-0">
                                        {item.title}
                                      </CardTitle>
                                      <CardText className="mb-1">
                                        <small className="text-muted">
                                          {item.subtitle}
                                        </small>
                                      </CardText>
                                      <CardText className="text-justify">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.description,
                                          }}
                                        />
                                      </CardText>
                                    </CardBody>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </>
                        )
                      })}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Row className="">
                      {detail.campaign_galleries.map((item, idx) => {
                        return (
                          <Col lg={4}>
                            <Card
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setGalIndex(idx)
                                setLightBox(true)
                              }}
                            >
                              <CardImg
                                top
                                className="img-fluid"
                                src={API_URL + item.image}
                                alt="Skote"
                              />
                              <CardBody className="p-1">
                                <CardTitle className="mb-0 text-center">
                                  {item.title}
                                </CardTitle>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      })}
                    </Row>
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {lightBox && (
        <Lightbox
          mainSrc={API_URL + detail.campaign_galleries[galIndex].image}
          nextSrc={
            detail.campaign_galleries[
              (galIndex + 1) % detail.campaign_galleries.length
            ]
          }
          prevSrc={
            detail.campaign_galleries[
              (galIndex + detail.campaign_galleries.length - 1) %
                detail.campaign_galleries.length
            ]
          }
          enableZoom={true}
          onCloseRequest={() => {
            setLightBox(false)
          }}
          onMovePrevRequest={() => {
            setGalIndex(
              (galIndex + detail.campaign_galleries.length - 1) %
                detail.campaign_galleries.length
            )
          }}
          onMoveNextRequest={() => {
            setGalIndex((galIndex + 1) % detail.campaign_galleries.length)
          }}
          imageCaption={detail.campaign_galleries[galIndex].description}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

// export default withRouter(DetailCampaign)
export default withRouter(connect(mapStateToProps, null)(DetailCampaign))
