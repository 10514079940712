import Breadcrumb from "components/Common/Breadcrumb"
import Toaster from "components/Common/Toast"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import { getProject } from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap"
import CardProject from "./card-project"

const ProjectList = props => {

  const [load, setLoad] = useState(false)
  const [projects, setProjects] = useState([])

  const [perPage, setPerPage] = useState(4)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)

  useEffect(() => {
    getProjectUser()
  }, [page])

  const getProjectUser = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPage}&&page=${page}`
    setLoad(true)
    try {
      console.log(query)
      const data = await getProject(token, query)
      setProjects(data.data)
      setTotalPage(data.meta.pagination.last_page)
      setCurrentPage(data.meta.pagination.current_page)
      setLoad(false)
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
      setLoad(false)
    }
  }

  const renderPaginate = (numb) => {
    let result = []
    for(let i = 1; i <= numb; i++) {
      result.push(
        <PaginationItem active={i === currentPage}>
          <PaginationLink onClick={() => setPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  // console.log(projects)

  if(load) {
    return (
      <SkeletonLoad
        title="Daftar"
        breadcrumbItem="Proyek Saya"
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton height={40} />
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Daftar" breadcrumbItem="Proyek Saya" />
          <Row className="">
            <Col lg="12">
              <Row className="">
                <CardProject 
                  projects={projects}
                  history={props.history}
                />
                {projects.length === 0 &&
                  <Col lg="12">
                    <Card>
                      <CardBody className="text-center">
                        <h5><b>Belum Ada Pengajuan Proyek</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                }
              </Row>
              {projects.length > 0 &&
                <Row className="pb-4">
                  <Col lg={12} className="d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink first onClick={() => setPage(1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink previous onClick={() => setPage(page - 1)} />
                      </PaginationItem>
                      {renderPaginate(totalPage)}
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink next onClick={() => setPage(page + 1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink last onClick={() => setPage(totalPage)} />
                      </PaginationItem>
                    </Pagination>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ProjectList)
