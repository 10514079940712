import {
  getHelpdesk,
  getMessagesTicket,
  getUsersAll,
  postAssignTicket,
  postClosingTicket,
} from "helpers/backend_helpers"
import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import AsyncSelect from "react-select/async"
import DataTable from "components/Common/DataTable"
import { MDBDataTable } from "mdbreact"
import { API_URL } from "helpers/api_url"
import Toaster from "components/Common/Toast"
import { connect } from "react-redux"
import Loading from "pages/Utility/Loading"
import PInput from "components/Input/PInput"
import moment from "moment"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import ModalImage from "react-modal-image"

const HelpdeskIn = props => {
  const token = localStorage.getItem("token")
  const [helpdesk, setHelpdesk] = useState([])
  const [load, setLoad] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [loadButt, setLoadButt] = useState(false)

  const [select, setSelect] = useState(null)
  const [modalAssign, setModalAssign] = useState(false)
  const [modalClosed, setModalClosed] = useState(false)
  const [user, setUser] = useState(null)

  const [filter, setFilter] = useState("")
  const [valueFilter, setValueFilter] = useState("")

  const [messages, setMessages] = useState([])
  const [modalMessages, setModalMessages] = useState(false)
  const [selectTicket, setSelectTicket] = useState(null)

  const scrollMessage = useRef(null)

  // const [perPage, setPerPage] = useState(10)
  // const [page, setPage] = useState(1)
  // const [totalPage, setTotalPage] = useState(0)
  // const [currentPage, setCurrentPage] = useState(0)
  // const [pagination, setPagination] = useState(null)
  // const [keySearch, setKeySearch] = useState("email")
  // const [search, setSearch] = useState("")
  // const [dataKey, setDataKey] = useState([])

  useEffect(() => {
    getHepldesks()
  }, [valueFilter])

  useEffect(() => {
    if (selectTicket) {
      getMessages()
    }
  }, [selectTicket])

  const toggleMessagesModal = (item = null) => {
    console.log(item)
    setSelectTicket(item)
    if (!item) {
      setMessages([])
    }
    setModalMessages(!modalMessages)
  }

  const toggleModalAssign = (item = null) => {
    setSelect(item)
    setUser(null)
    setModalAssign(!modalAssign)
  }

  const toggleModalClosed = (item = null) => {
    setSelect(item)
    setModalClosed(!modalClosed)
  }

  const getMessages = async () => {
    setLoad(true)
    const data = await getMessagesTicket(selectTicket?.id, token)
    setMessages(data.data)
    setLoad(false)
  }

  const getHepldesks = async () => {
    let query
    if (filter && valueFilter) {
      query = `?keySearch=${filter}&&search=${valueFilter}`
    }

    setLoad(true)
    const data = await getHelpdesk(token, query)

    // setTotalPage(data.meta.pagination.last_page)
    // setCurrentPage(data.meta.pagination.current_page)
    // setPagination(data.meta.pagination)
    // if (data.data.length > 0) {
    //   setDataKey(Object.keys(users.data[0]))
    // }
    setHelpdesk(data.data)
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const priorityData = [
    { value: 1, label: <Badge color="info">Low</Badge> },
    { value: 2, label: <Badge color="warning">Medium</Badge> },
    { value: 3, label: <Badge color="danger">High</Badge> },
  ]

  const status = [
    { value: 1, label: <Badge color="info">Open</Badge> },
    { value: 2, label: <Badge color="warning">Pending</Badge> },
    { value: 3, label: <Badge color="info">InProggess</Badge> },
    { value: 4, label: <Badge color="secondary">Deffered</Badge> },
    { value: 5, label: <Badge color="primary">Solved</Badge> },
    { value: 6, label: <Badge color="secondary">Closed</Badge> },
  ]

  const typeData = [
    { value: 1, label: <Badge color="danger">Bug</Badge> },
    { value: 2, label: <Badge color="primary">Imporvement</Badge> },
    { value: 3, label: <Badge color="warning">Question</Badge> },
    { value: 4, label: <Badge color="info">Feedback</Badge> },
  ]

  const dataTableHelpdesk = {
    columns: [
      {
        field: "ticket_id",
        label: "Ticket Id",
      },
      {
        field: "email",
        label: "Email",
      },
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "title",
        label: "Judul",
      },
      {
        field: "description",
        label: "Deskripsi",
      },
      {
        field: "priority",
        label: "Prioritas",
      },
      {
        field: "type",
        label: "Tipe",
      },
      {
        field: "image",
        label: "Gambar",
      },
      {
        field: "status",
        label: "Status",
      },
      {
        field: "assignee",
        label: "Penerima Tugas",
      },
      {
        field: "reported_at",
        label: "Tanggal Pengaduan",
      },
      {
        field: "solved_at",
        label: "Tanggal Penyelesaian",
      },
      {
        field: "solved_by",
        label: "Diselesaikan Oleh",
      },
      {
        field: "solvingDescription",
        label: "Deskripsi Penyelesaian",
      },
      {
        field: "closed_at",
        label: "Tanggal Ditutup",
      },
      {
        field: "closed_by",
        label: "Ditutup Oleh",
      },
      {
        field: "assign_by",
        label: "Ditugaskan Kepada",
      },
      {
        field: "closed_ticket",
        label: "Tutup Tiket",
      },
    ],
    rows: helpdesk.map(item => {
      return {
        ...item,
        ticket_id: (
          <div
            className="cursor-pointer text-primary hover-underline"
            onClick={() => toggleMessagesModal(item)}
          >
            {item.ticket_id}
          </div>
        ),
        solvingDescription: item.solvingDescription || "-",
        assignee: (
          <>
            {item.helpdesk_assignee?.length
              ? item.helpdesk_assignee.map(
                  inner => inner.assigned_to?.fullName || "-"
                )
              : "-"}
          </>
        ),
        solved_by: item.solved_by?.fullName || "-",
        closed_by: item.closed_by?.fullName || "-",
        priority: priorityData.find(inner => inner.value === item.priority)
          ?.label,
        type: typeData.find(inner => inner.value === item.type)?.label,
        image: item.files?.length && (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => window.open(`${API_URL}${item.files[0].file}`)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        status: status.find(inner => inner.value === item.status)?.label,
        solved_at: item.solved_at || "-",
        closed_at: item.closed_at || "-",
        assign_by: (
          <Button
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalAssign(item)}
            disabled={item.helpdesk_assignee?.length > 0}
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
        closed_ticket: (
          <Button
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalClosed(item)}
            disabled={item.status === 6}
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const loadOptions = (inputValue, callback) => {
    let param = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAll(token, param)
      callback(
        data.data.map(item => {
          return { label: item.email, value: item.uuid }
        })
      )
    })
  }

  const handleAssign = async () => {
    if (!user) return Toaster("error", "Maaf", "Kolom wajib diisi.")
    setLoadButt(true)
    try {
      await postAssignTicket(select.id, { user_id: user.value }, token)
      toggleModalAssign(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Ditugaskan")
      getHepldesks()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalAssign = () => {
    return (
      <Modal isOpen={modalAssign} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalAssign(null)}>
          Tambah Penerima Tugas
        </ModalHeader>
        <ModalBody>
          <AsyncSelect
            cacheOptions
            // isMulti={true}
            loadOptions={loadOptions}
            defaultOptions
            classNamePrefix="select2-selection"
            onChange={e => {
              setUser(e)
            }}
            value={user}
          />
          <div className="py-2 mt-3">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              disabled={loadButt}
              onClick={handleAssign}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const handleClosed = async () => {
    setLoadButt(true)
    try {
      await postClosingTicket(select.id, {}, token)
      toggleModalClosed(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil tutup tiket")
      getHepldesks()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalClosed = () => {
    return (
      <Modal isOpen={modalClosed} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalClosed(null)}>
          Tutup Tiket
        </ModalHeader>
        <ModalBody>
          <div className="">
            <div className="font-16">Yakin ingin Menutup Tiket ?</div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="mr-2"
                color="secondary"
                disabled={loadButt}
                onClick={() => toggleModalClosed(null)}
              >
                Batal
              </Button>
              <Button
                // className="btn-sm"
                color="primary"
                onClick={handleClosed}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Tutup"
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const renderMessagesTicket = () => {
    return (
      <Modal isOpen={modalMessages} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleMessagesModal(null)}>
          {selectTicket?.title}
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="chat-conversation">
              <ul className="list-unstyled">
                <PerfectScrollbar
                  style={{ maxHeight: "460px" }}
                  // containerRef={ref => setMessageBox(ref)}
                >
                  <li className={``}>
                    <div className="conversation-list">
                      <div className="ctext-wrap">
                        {Boolean(selectTicket?.files?.length) && (
                          <ModalImage
                            small={API_URL + selectTicket.files[0].file}
                            large={API_URL + selectTicket.files[0].file}
                            className="mb-1 w-100"
                          />
                        )}
                        <div className="conversation-name">
                          {selectTicket?.reported_by?.fullName}
                        </div>
                        <p>{selectTicket?.description}</p>
                        <p className="chat-time mb-0">
                          <i className="bx bx-time-five align-middle mr-1" />
                          {moment(selectTicket?.created_at).format(
                            "DD-MM-YY hh:mm"
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  {Boolean(messages.length) ? (
                    messages.map(message => (
                      <li
                        key={"test_k" + message.id}
                        className={
                          message.created_by?.id == props?.user?.uuid
                            ? "right"
                            : ""
                        }
                      >
                        <div className="conversation-list">
                          <div className="ctext-wrap">
                            {Boolean(message.files?.length) && (
                              <ModalImage
                                small={API_URL + message.files[0].file}
                                large={API_URL + message.files[0].file}
                                className="mb-1 w-100"
                              />
                            )}
                            <div className="conversation-name">
                              {message.created_by?.fullName}
                            </div>
                            <p>{message.text}</p>
                            <p className="chat-time mb-0">
                              <i className="bx bx-time-five align-middle mr-1" />
                              {moment(message.created_at).format(
                                "DD-MM-YY hh:mm"
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="text-center">Tidak Ada Pesan</div>
                  )}
                  <div ref={scrollMessage} />
                </PerfectScrollbar>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  if (load)
    return (
      <Loading title="Kelola Pengaduan" breadcrumbItem="Kelola Pengaduan" />
    )

  // console.log(props.user)

  return (
    <React.Fragment>
      {renderModalAssign()}
      {renderModalClosed()}
      {renderMessagesTicket()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Kelola Pengaduan"
            breadcrumbItem="Kelola Pengaduan"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Pengaduan
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="1">
                      <div className="row">
                        <Col lg="4">
                          <FormGroup>
                            <Label>Filter</Label>
                            <Input
                              type="select"
                              name="priority"
                              value={filter}
                              onChange={e => {
                                setValueFilter("")
                                setFilter(e.target.value)
                              }}
                            >
                              <option value="">Pilih</option>
                              <option value="priority">Prioritas</option>
                              <option value="type">Tipe</option>
                              <option value="status">Status</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        {filter === "priority" && (
                          <Col lg="4">
                            <FormGroup>
                              <Label>Prioritas</Label>
                              <Input
                                type="select"
                                name="priority"
                                value={valueFilter}
                                onChange={e => setValueFilter(e.target.value)}
                              >
                                <option value="">Pilih</option>
                                <option value="1">Low</option>
                                <option value="2">Medium</option>
                                <option value="3">High</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        )}
                        {filter === "type" && (
                          <Col lg="4">
                            <FormGroup>
                              <Label>Tipe</Label>
                              <Input
                                type="select"
                                name="priority"
                                value={valueFilter}
                                onChange={e => setValueFilter(e.target.value)}
                              >
                                <option value="">Pilih</option>
                                <option value="1">Bug</option>
                                <option value="2">Improvement</option>
                                <option value="3">Question</option>
                                <option value="4">Feedback</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        )}
                        {filter === "status" && (
                          <Col lg="4">
                            <FormGroup>
                              <Label>Status</Label>
                              <Input
                                type="select"
                                name="priority"
                                value={valueFilter}
                                onChange={e => setValueFilter(e.target.value)}
                              >
                                <option value="">Pilih</option>
                                <option value="1">Open</option>
                                <option value="2">Pending</option>
                                <option value="3">InProgress</option>
                                <option value="4">Deffered</option>
                                <option value="5">Solved</option>
                                <option value="6">Closed</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        )}
                      </div>
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataTableHelpdesk}
                        striped
                        className="mt-1"
                      />
                      {/* <DataTable
                        table={dataTableHelpdesk}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        search={search}
                        handleSearch={setSearch}
                        keySearch={keySearch}
                        setKeySearch={setKeySearch}
                        dataKey={dataKey}
                        handleGetSearch={getHepldesks}
                        searching={true}
                      /> */}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default connect(mapStateToProps, null)(HelpdeskIn)
