import Toaster from "components/Common/Toast"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import { getNotifications, readNotification } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { withRouter } from "react-router"
import { Card, CardBody, CardHeader, Container } from "reactstrap"
import SimpleBar from "simplebar-react"

const Notifications = props => {
  const token = localStorage.getItem("token")
  const [notif, setNotif] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    getNotif()
  }, [])

  const getNotif = async () => {
    setLoad(true)
    const data = await getNotifications(token)
    setNotif(data.data)
    setLoad(false)
  }

  // console.log(notif)
  const handleNotifCampaign = async (item) => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/detail-kampanye/${item.campaign_id}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifSignature = async (item) => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/proyek-detail/${item.id}?campaign_id=${item.campaign_id}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifVa = async (item) => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/portfolio-transaksi?id=${item.campaign_id}&&page=1`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifKyc = async (item) => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/profile`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifProject = async (item) => {
    let type = "pengajuan-ukm"
    if(item.project_type == "Property") {
      type = "pengajuan-properti"
    }
    try {
      await readNotification(item.id, token)
      props.history.push(`/${type}/${item.project_id}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }
  const handleNotifCampaignUpdate = async (item) => {
    try {
      await readNotification(item.id, token)
      props.history.push(`/portfolio/${item.trx_uuid}`)
      getNotif()
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  if(load) {
    return (
      <SkeletonLoad
        title=""
        breadcrumbItem=""
      >
        <Card>
          <CardHeader><b>Semua Pemberitahuan</b></CardHeader>
          <CardBody>
            <div style={{ fontSize: 24, lineHeight: 2 }}>
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
              <Skeleton height={32} />
            </div>
          </CardBody>
        </Card>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardHeader><b>Semua Pemberitahuan</b></CardHeader>
            <CardBody>
              <SimpleBar>
                {notif.length > 0 ? notif.map(item => {
                  if(item.type_notification == "published-campaign" || item.type_notification == null) {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifCampaign(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-store"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Kampanye Terbaru <b>{item.campaign_title}</b>
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if(item.type_notification == "lender-ttd-musyarakah") {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifSignature(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-briefcase-alt"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Pembiayaan Kampanye <b>{item.campaign_title}</b>, Mohon tandatangani akad Musyarakah
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if(item.type_notification == "borrower-info-va") {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifVa(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-money"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Virtual Account telah terbit untuk pembiayaan <b>{item.campaign_title}</b>, silahkan lakukan pembayaran.
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if(item.type_notification == "approval-user") {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifKyc(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bxs-user-circle"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Akun anda telah <b>{item.kyc_answer}</b>
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if(item.type_notification == "approval-project") {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifProject(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-briefcase-alt"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Status pengajuan proyek anda <b>{item.project_title}</b> telah <b>{item.project_status}</b>
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if(item.type_notification == "registration-privy-status") {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifKyc(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bxs-user-circle"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Akun anda telah <b>{item.privy_answer}</b>
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if(item.type_notification == "campaign-update") {
                    return (
                      <div className="text-reset notification-item" style={{cursor: "pointer"}} onClick={() => handleNotifCampaignUpdate(item)}>
                        <div className="media">
                          <div className="avatar-xs mr-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-store"/>
                            </span>
                          </div>
                          <div className="media-body">
                            {/* <h6 className="mt-0 mb-1">
                              {props.t("Your order is placed")}
                            </h6> */}
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">
                                {/* {props.t("If several languages coalesce the grammar")} */}
                                Ada Perkembangan Proyek Terbaru <b>{item.campaign_update_title}</b>
                              </p>
                              {/* <p className="mb-0">
                                <i className="mdi mdi-clock-outline"/>{" "}
                                {props.t("3 min ago")}{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return null
                }): <div className="d-flex justify-content-center font-weight-bold">Belum Ada Pemberitahuan</div>}
              </SimpleBar>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Notifications)
