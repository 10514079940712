import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_PROFILE } from "./actionTypes"
import { getProfile } from "./actions"
import { getUserLogin } from "helpers/backend_helpers"

function* getProfileUser({ payload: { token } }) {
  try {
    const responseUser = yield call(getUserLogin, token)
    yield put(getProfile(responseUser.data))
  } catch (error) {
    console.log(error)
  }
}

function* profile() {
  yield takeEvery(GET_PROFILE, getProfileUser)
}

export default profile