import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL, API_URL_1 } from "helpers/api_url"
import { connect } from "react-redux"
import {
  createCampaignMailchimp,
  createList,
  createTemplate,
  deleteList,
  getCampaignMailchimp,
  getList,
  getTag,
  getTemplate,
  sendEmailMailchimp,
  updateList,
} from "helpers/backend_helpers"
import Select from "react-select"

const templateEmail = body => {
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
  >
    <head>
      <meta charset="UTF-8" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta name="x-apple-disable-message-reformatting" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta content="telephone=no" name="format-detection" />
      <title>Email Promo Kampanye</title>
      <!--[if (mso 16)]>
        <style type="text/css">
          a {
            text-decoration: none;
          }
        </style>
      <![endif]-->
      <!--[if gte mso 9
        ]><style>
          sup {
            font-size: 100% !important;
          }
        </style><!
      [endif]-->
      <!--[if gte mso 9]>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG></o:AllowPNG>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      <![endif]-->
      <!--[if !mso]><!-- -->
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i"
        rel="stylesheet"
      />
      <!--<![endif]-->
  
      <style type="text/css">
        #outlook a {
          padding: 0;
        }
        .es-button {
          mso-style-priority: 100 !important;
          text-decoration: none !important;
        }
        a[x-apple-data-detectors] {
          color: inherit !important;
          text-decoration: none !important;
          font-size: inherit !important;
          font-family: inherit !important;
          font-weight: inherit !important;
          line-height: inherit !important;
        }
        .es-desk-hidden {
          display: none;
          float: left;
          overflow: hidden;
          width: 0;
          max-height: 0;
          line-height: 0;
          mso-hide: all;
        }
        [data-ogsb] .es-button {
          border-width: 0 !important;
          padding: 10px 20px 10px 20px !important;
        }
        [data-ogsb] .es-button.es-button-1 {
          padding: 20px 20px 20px 30px !important;
        }
        [data-ogsb] .es-button.es-button-2 {
          padding: 20px 45px 20px 30px !important;
        }
        [data-ogsb] .es-button.es-button-3 {
          padding: 4px 35px 4px 20px !important;
        }
        [data-ogsb] .es-button.es-button-4 {
          padding: 4px 24px 4px 9px !important;
        }
        @media only screen and (max-width: 600px) {
          p,
          ul li,
          ol li,
          a {
            line-height: 150% !important;
          }
        }
        @media only screen and (max-width: 600px) {
          h1,
          h2,
          h3,
          h1 a,
          h2 a,
          h3 a {
            line-height: 120% !important;
          }
        }
        @media only screen and (max-width: 600px) {
          h1 {
            font-size: 30px !important;
            text-align: center;
          }
        }
        @media only screen and (max-width: 600px) {
          h2 {
            font-size: 26px !important;
            text-align: center;
          }
        }
        @media only screen and (max-width: 600px) {
          h3 {
            font-size: 20px !important;
            text-align: center;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-header-body h1 a,
          .es-content-body h1 a,
          .es-footer-body h1 a {
            font-size: 30px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-header-body h2 a,
          .es-content-body h2 a,
          .es-footer-body h2 a {
            font-size: 26px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-header-body h3 a,
          .es-content-body h3 a,
          .es-footer-body h3 a {
            font-size: 20px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-menu td a {
            font-size: 16px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-header-body p,
          .es-header-body ul li,
          .es-header-body ol li,
          .es-header-body a {
            font-size: 16px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-content-body p,
          .es-content-body ul li,
          .es-content-body ol li,
          .es-content-body a {
            font-size: 16px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-footer-body p,
          .es-footer-body ul li,
          .es-footer-body ol li,
          .es-footer-body a {
            font-size: 16px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-infoblock p,
          .es-infoblock ul li,
          .es-infoblock ol li,
          .es-infoblock a {
            font-size: 12px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          *[class="gmail-fix"] {
            display: none !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-txt-c,
          .es-m-txt-c h1,
          .es-m-txt-c h2,
          .es-m-txt-c h3 {
            text-align: center !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-txt-r,
          .es-m-txt-r h1,
          .es-m-txt-r h2,
          .es-m-txt-r h3 {
            text-align: right !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-txt-l,
          .es-m-txt-l h1,
          .es-m-txt-l h2,
          .es-m-txt-l h3 {
            text-align: left !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-txt-r img,
          .es-m-txt-c img,
          .es-m-txt-l img {
            display: inline !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-button-border {
            display: block !important;
          }
        }
        @media only screen and (max-width: 600px) {
          a.es-button,
          button.es-button {
            font-size: 20px !important;
            display: block !important;
            border-width: 10px 0px 10px 0px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-adaptive table,
          .es-left,
          .es-right {
            width: 100% !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-content table,
          .es-header table,
          .es-footer table,
          .es-content,
          .es-footer,
          .es-header {
            width: 100% !important;
            max-width: 600px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-adapt-td {
            display: block !important;
            width: 100% !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .adapt-img {
            width: 100% !important;
            height: auto !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-p0 {
            padding: 0px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-p0r {
            padding-right: 0px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-p0l {
            padding-left: 0px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-p0t {
            padding-top: 0px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-p0b {
            padding-bottom: 0 !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-m-p20b {
            padding-bottom: 20px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-mobile-hidden,
          .es-hidden {
            display: none !important;
          }
        }
        @media only screen and (max-width: 600px) {
          tr.es-desk-hidden,
          td.es-desk-hidden,
          table.es-desk-hidden {
            width: auto !important;
            overflow: visible !important;
            float: none !important;
            max-height: inherit !important;
            line-height: inherit !important;
          }
        }
        @media only screen and (max-width: 600px) {
          tr.es-desk-hidden {
            display: table-row !important;
          }
        }
        @media only screen and (max-width: 600px) {
          table.es-desk-hidden {
            display: table !important;
          }
        }
        @media only screen and (max-width: 600px) {
          td.es-desk-menu-hidden {
            display: table-cell !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-menu td {
            width: 1% !important;
          }
        }
        @media only screen and (max-width: 600px) {
          table.es-table-not-adapt,
          .esd-block-html table {
            width: auto !important;
          }
        }
        @media only screen and (max-width: 600px) {
          table.es-social {
            display: inline-block !important;
          }
        }
        @media only screen and (max-width: 600px) {
          table.es-social td {
            display: inline-block !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .es-desk-hidden {
            display: table-row !important;
            width: auto !important;
            overflow: visible !important;
            max-height: inherit !important;
          }
        }
      </style>
    </head>
    <body
      style="
        width: 100%;
        font-family: arial, 'helvetica neue', helvetica, sans-serif;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        padding: 0;
        margin: 0;
      "
    >
      ${body}
    </body>
  </html>
  `
}

const MailChimp = props => {
  const token = localStorage.getItem("token")

  const [load, setLoad] = useState(false)
  const [list, setList] = useState([])
  const [select, setSelect] = useState(null)
  const [selectInfo, setSelectInfo] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const [loadButt, setLoadButt] = useState(false)

  const [modalInfo, setModalInfo] = useState(false)
  const [modalForm, setModalForm] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const [modalCreateTemplate, setModalCreateTemplate] = useState(false)
  const [modalCreateCampaign, setModalCreateCampaign] = useState(false)

  const [templates, setTemplates] = useState([])
  const [campaigns, setCampaigns] = useState([])

  const [tags, setTags] = useState([])
  const [audience, setAudience] = useState(null)
  const [tag, setTag] = useState(null)
  const [template, setTemplate] = useState(null)

  const [modalSendMail, setModalSendMail] = useState(false)
  const [selectCampaign, setSelectCampaign] = useState(null)

  const [bodyTemplate, setBodyTemplate] = useState("")

  const toggleModalSendMail = item => {
    setSelectCampaign(item)
    setModalSendMail(!modalSendMail)
  }

  const toggleModalCreateTemplate = () => {
    setBodyTemplate("")
    setModalCreateTemplate(!modalCreateTemplate)
  }

  const toggleModalCreateCampaign = () => {
    setAudience(null)
    setTag(null)
    setTemplate(null)
    setModalCreateCampaign(!modalCreateCampaign)
  }

  const toggleModalDelete = item => {
    setSelect(item)
    setModalDelete(!modalDelete)
  }

  const toggleModalForm = item => {
    setSelect(item)
    setModalForm(!modalForm)
  }

  const toggleModalInfo = item => {
    setSelectInfo(item)
    setModalInfo(!modalInfo)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (activeTab == "1") {
      getDataList()
    } else if (activeTab == "2") {
      getTemplateList()
    } else {
      getCampaignList()
    }
  }, [activeTab])

  useEffect(() => {
    if (audience) {
      getTagsList(audience.value)
    } else {
      setTags([])
    }
  }, [audience])

  const getDataList = async () => {
    setLoad(true)
    const data = await getList()
    setList(data.data?.lists || [])
    setLoad(false)
  }

  const getTemplateList = async () => {
    setLoad(true)
    const template = await getTemplate(token)
    setTemplates(template?.data?.templates || [])
    setLoad(false)
  }

  const getTagsList = async id => {
    const tag = await getTag(id, token)
    setTags(tag?.data?.tags || [])
  }

  const getCampaignList = async () => {
    setLoad(true)
    const campaign = await getCampaignMailchimp(token)
    setCampaigns(campaign?.data?.campaigns || [])
    setLoad(false)
  }

  const tableList = {
    columns:
      list.length > 0
        ? [
            ...Object.keys(list[0])
              .filter(item => item !== "_links" && item !== "modules")
              .map(item => {
                return { field: item, label: item }
              }),
            { field: "action", label: "action" },
          ]
        : [],
    rows: list.map(item => {
      return {
        ...item,
        use_archive_bar: item.use_archive_bar.toString(),
        email_type_option: item.email_type_option.toString(),
        double_optin: item.double_optin.toString(),
        has_welcome: item.has_welcome.toString(),
        marketing_permissions: item.marketing_permissions.toString(),
        campaign_defaults: (
          <Button
            className="btn-sm"
            onClick={() => toggleModalInfo(item.campaign_defaults || null)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        contact: (
          <Button
            className="btn-sm"
            onClick={() => toggleModalInfo(item.contact || null)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        stats: (
          <Button
            className="btn-sm"
            onClick={() => toggleModalInfo(item.stats || null)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm mr-2"
              onClick={() => toggleModalForm(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => toggleModalDelete(item)}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const tableTemplate = {
    columns: [
      { field: "name", label: "Nama Template" },
      { field: "date_created", label: "Tanggal Dibuat" },
    ],
    rows: templates.map(item => {
      return {
        ...item,
        date_created: moment(item.date_created).format("DD MMM YYYY"),
      }
    }),
  }

  const tableCampaign = {
    columns: [
      {
        field: "from_name",
        label: <div className="text-nowrap">From Name</div>,
      },
      {
        field: "reply_to",
        label: <div className="text-nowrap">Reply To</div>,
      },
      {
        field: "subject_line",
        label: <div className="text-nowrap">Subject Line</div>,
      },
      {
        field: "preview_text",
        label: <div className="text-nowrap">Preview Text</div>,
      },
      { field: "title", label: <div className="text-nowrap">Title</div> },
      {
        field: "reply_to",
        label: <div className="text-nowrap">Reply To</div>,
      },
      { field: "status", label: <div className="text-nowrap">Status</div> },
      {
        field: "emails_sent",
        label: <div className="text-nowrap">Emails Sent</div>,
      },
      {
        field: "recipients",
        label: <div className="text-nowrap">Recipients</div>,
      },
      {
        field: "recipient_count",
        label: <div className="text-nowrap">Recipient Count</div>,
      },
      {
        field: "preview",
        label: <div className="text-nowrap">Template Email</div>,
      },
      {
        field: "send_mail",
        label: <div className="text-nowrap">Send Email</div>,
      },
    ],
    rows: campaigns.map(item => {
      return {
        ...item,
        from_name: item.settings?.from_name || "-",
        reply_to: item.settings?.reply_to || "-",
        subject_line: item.settings?.subject_line || "-",
        preview_text: item.settings?.preview_text || "-",
        title: item.settings?.title || "-",
        recipients: item.recipients?.list_name,
        recipient_count: item.recipients?.recipient_count,
        preview: (
          <Button
            className="btn-sm"
            onClick={() => window.open(item.long_archive_url)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        send_mail: (
          <Button
            color="primary"
            className="btn-sm"
            onClick={() => toggleModalSendMail(item)}
          >
            <i className="fas fa-envelope"></i>
          </Button>
        ),
      }
    }),
  }

  const renderModalInfo = () => {
    return (
      <Modal isOpen={modalInfo} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalInfo(null)}>Detail</ModalHeader>
        <ModalBody>
          {selectInfo && (
            <>
              <table className="table table-striped border">
                <tbody>
                  {Object.keys(selectInfo).map(item => {
                    return (
                      <tr>
                        <td>{item}</td>
                        <td>{selectInfo[item].toString() || "-"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          )}
        </ModalBody>
      </Modal>
    )
  }

  const handleSubmit = async (e, value) => {
    if (select) {
      setLoadButt(true)
      try {
        await updateList(select.id, value)
        toggleModalForm()
        setLoadButt(false)
        Toaster("success", "Sukses", "Berhasil ubah data")
        getDataList()
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Maaf", error.response.data?.message)
      }
    } else {
      setLoadButt(true)
      try {
        await createList(value)
        toggleModalForm()
        setLoadButt(false)
        Toaster("success", "Sukses", "Berhasil tambah data")
        getDataList()
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Maaf", error.response.data?.message)
      }
    }
  }

  const renderModalForm = () => {
    return (
      <Modal isOpen={modalForm} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalForm(null)}>
          Form List
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmit(e, v)
            }}
          >
            <PInput
              name="name"
              type="text"
              label="Nama"
              value={select ? select.name : ""}
              validate={{
                required: { value: true, errorMessage: "Nama harus diisi" },
              }}
            />
            <PInput
              name="contact[company]"
              type="text"
              label="Nama Perusahaan"
              value={select ? select.contact?.company : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Nama Perusahaan harus diisi",
                },
              }}
            />
            <PInput
              name="contact[address1]"
              type="text"
              label="Alamat Perusahaan"
              value={select ? select.contact?.address1 : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Alamat Perusahaan harus diisi",
                },
              }}
            />
            <PInput
              name="contact[city]"
              type="text"
              label="Kota Perusahaan"
              value={select ? select.contact?.city : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Kota Perusahaan harus diisi",
                },
              }}
            />
            <PInput
              name="contact[country]"
              type="text"
              label="Negara Perusahaan"
              value={select ? select.contact?.country : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Negara Perusahaan harus diisi",
                },
              }}
            />
            <PInput
              name="contact[state]"
              type="text"
              label="Negara Bagian"
              value={select ? select.contact?.country : ""}
              validate={{
                required: { value: false, errorMessage: "" },
              }}
            />
            <PInput
              name="contact[zip]"
              type="text"
              label="Kode Pos Perusahaan"
              value={select ? select.contact?.zip : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Kode Pos Perusahaan harus diisi",
                },
              }}
            />
            <PInput
              name="campaign_defaults[from_name]"
              type="text"
              label="Nama Campaign"
              value={select ? select.campaign_defaults?.from_name : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Nama Campaign harus diisi",
                },
              }}
            />
            <PInput
              name="campaign_defaults[from_email]"
              type="text"
              label="Email Campaign"
              value={select ? select.campaign_defaults?.from_email : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Email Campaign harus diisi",
                },
                email: { value: true, errorMessage: "Format email salah" },
              }}
            />
            <PInput
              name="campaign_defaults[subject]"
              type="text"
              label="Subject Campaign"
              value={select ? select.campaign_defaults?.subject : ""}
              validate={{
                required: { value: false, errorMessage: "" },
              }}
            />
            <PInput
              name="campaign_defaults[language]"
              type="text"
              label="Language Campaign"
              value={select ? select.campaign_defaults?.language : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Languange Campaign harus diisi",
                },
              }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleDeleteList = async () => {
    setLoadButt(true)
    try {
      await deleteList(select.id)
      toggleModalDelete()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil hapus data")
      getDataList()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data?.message)
    }
  }

  const renderModalDelete = () => {
    return (
      <Modal isOpen={modalDelete} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDelete(null)}>Hapus</ModalHeader>
        <ModalBody>
          <div className="">
            <div className="font-16">
              Yakin ingin Menghapus <b>{select && select.name}</b> ?
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="mr-2"
                color="secondary"
                disabled={loadButt}
                onClick={() => toggleModalDelete(null)}
              >
                Batal
              </Button>
              <Button
                // className="btn-sm"
                color="danger"
                onClick={handleDeleteList}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Hapus"
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const handleSubmitTemplate = async (e, value) => {
    const newValue = {
      ...value,
      html: templateEmail(bodyTemplate),
    }
    setLoadButt(true)
    try {
      await createTemplate(newValue, token)
      toggleModalCreateTemplate()
      getTemplateList()
      Toaster("success", "Sukses", "Berhasil buat template")
    } catch (error) {
      Toaster(
        "error",
        "Gagal",
        error?.response?.data?.message || "Server Error"
      )
    } finally {
      setLoadButt(false)
    }
  }

  const renderModalCreateTemplate = () => (
    <Modal
      isOpen={modalCreateTemplate}
      role="dialog"
      autoFocus={true}
      size="xl"
      className="modal-fullscreen"
    >
      <ModalHeader toggle={() => toggleModalCreateTemplate()}>
        Buat Template Email
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div
              dangerouslySetInnerHTML={{
                __html: `${bodyTemplate}`,
              }}
            />
          </Col>
          <Col>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmitTemplate(e, v)
              }}
            >
              <PInput
                name="name"
                type="text"
                label="Nama"
                validate={{
                  required: { value: true, errorMessage: "Nama harus diisi" },
                }}
              />
              <PInput
                name="html"
                type="textarea"
                label="Template"
                rows="23"
                value={bodyTemplate}
                onChange={e => setBodyTemplate(e.target.value)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Template harus diisi",
                  },
                }}
              />
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )

  const handleSubmitCampaign = async (e, value) => {
    if (!audience || !tag || !template)
      return Toaster("error", "Maaf", "Field harus diisi")
    const newValue = {
      ...value,
      tags_id: tag.value,
      template_id: template.value,
    }

    setLoadButt(true)
    try {
      await createCampaignMailchimp(newValue, token)
      toggleModalCreateCampaign(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil buat campaign")
      getCampaignList()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    }
  }

  const renderModalCreateCampaign = () => (
    <Modal
      isOpen={modalCreateCampaign}
      role="dialog"
      autoFocus={true}
      // size="lg"
    >
      <ModalHeader toggle={() => toggleModalCreateCampaign()}>
        Buat Campaign
      </ModalHeader>
      <ModalBody>
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleSubmitCampaign(e, v)
          }}
        >
          <FormGroup>
            <Label>Audience</Label>
            <Select
              options={list.map(item => {
                return { label: item.name, value: item.id }
              })}
              value={audience}
              onChange={e => setAudience(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Tag</Label>
            <Select
              options={tags.map(item => {
                return { label: item.name, value: item.id }
              })}
              value={tag}
              onChange={e => setTag(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Template</Label>
            <Select
              options={templates.map(item => {
                return { label: item.name, value: item.id }
              })}
              value={template}
              onChange={e => setTemplate(e)}
            />
          </FormGroup>
          <PInput
            name="from_name"
            type="text"
            label="From Name"
            validate={{
              required: { value: true, errorMessage: "From Name harus diisi" },
            }}
          />
          <PInput
            name="reply_to"
            type="text"
            label="Reply To"
            validate={{
              required: { value: true, errorMessage: "Reply To harus diisi" },
            }}
          />
          <PInput
            name="subject_line"
            type="text"
            label="Subject Line"
            validate={{
              required: {
                value: true,
                errorMessage: "Subject Line harus diisi",
              },
            }}
          />
          <PInput
            name="preview_text"
            type="text"
            label="Preview Text"
            validate={{
              required: {
                value: true,
                errorMessage: "Preview Text harus diisi",
              },
            }}
          />
          <PInput
            name="title"
            type="text"
            label="Title"
            validate={{
              required: { value: true, errorMessage: "Title harus diisi" },
            }}
          />
          <div className="py-2">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              type="submit"
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )

  const handleSendMail = async () => {
    setLoadButt(true)
    try {
      await sendEmailMailchimp(selectCampaign.id, token)
      toggleModalSendMail(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Email dikirim")
      getCampaignList()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    }
  }

  const renderModalSendMail = () => {
    return (
      <Modal isOpen={modalSendMail} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalSendMail(null)}>
          Kirim Email
        </ModalHeader>
        <ModalBody>
          {selectCampaign && (
            <table className="table table-striped border">
              <tbody>
                <tr>
                  <td>From Name</td>
                  <td>{selectCampaign.settings?.from_name || "-"}</td>
                </tr>
                <tr>
                  <td>Reply To</td>
                  <td>{selectCampaign.settings?.reply_to || "-"}</td>
                </tr>
                <tr>
                  <td>Subject Line</td>
                  <td>{selectCampaign.settings?.subject_line || "-"}</td>
                </tr>
                <tr>
                  <td>Preview Text</td>
                  <td>{selectCampaign.settings?.preview_text || "-"}</td>
                </tr>
                <tr>
                  <td>Title</td>
                  <td>{selectCampaign.settings?.title || "-"}</td>
                </tr>
                <tr>
                  <td>Recipients</td>
                  <td>{selectCampaign.recipients?.list_name || "-"}</td>
                </tr>
                <tr>
                  <td>Recipients Count</td>
                  <td>{selectCampaign.recipients?.recipient_count || "-"}</td>
                </tr>
                <tr>
                  <td>Preview</td>
                  <td>
                    <Button
                      className="btn-sm"
                      onClick={() =>
                        window.open(selectCampaign.long_archive_url)
                      }
                    >
                      <i className="fas fa-eye"></i>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <div className="py-2">
            <Button
              color="primary"
              className="btn btn-block waves-effect waves-light"
              disabled={loadButt}
              onClick={handleSendMail}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Kirim"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading title="Mailchimp" breadcrumbItem="Mailchimp" />

  return (
    <React.Fragment>
      {renderModalForm()}
      {renderModalInfo()}
      {renderModalDelete()}
      {renderModalCreateTemplate()}
      {renderModalCreateCampaign()}
      {renderModalSendMail()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Mailchimp" breadcrumbItem="Mailchimp" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        List Mailchimp
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Template
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Campaign
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={() => toggleModalForm(null)}>
                          + Tambah
                        </Button>
                      </div>
                      {list.length === 0 ? (
                        <div className="text-center font-32 pt-3 font-weight-bold">
                          Tidak ada data
                        </div>
                      ) : (
                        <MDBDataTable
                          responsive
                          bordered
                          data={tableList}
                          striped
                          className="mt-3"
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={() => toggleModalCreateTemplate()}>
                          + Buat Template
                        </Button>
                      </div>
                      <MDBDataTable
                        responsive
                        bordered
                        data={tableTemplate}
                        striped
                        className="mt-3"
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={() => toggleModalCreateCampaign()}>
                          + Buat Campaign
                        </Button>
                      </div>
                      <MDBDataTable
                        responsive
                        bordered
                        data={tableCampaign}
                        striped
                        className="mt-3"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(MailChimp)
