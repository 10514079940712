import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { getCities, getCitiesAll, getDistrict, getSubDistrict, postProperty } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap"
import SignaturePad from 'react-signature-pad-wrapper'
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"

const INIT_INPUT = {
  file_npwp_company: null,
  file_siup_company: null,
  file_nib_company: null,
  file_akta_company: null,
  file_akta_last_change_number: null,
  file_sk_kemenkumham: null,
  file_organitation: null,
}

const REGION_PIC = {
  pic_city: [],
  pic_district: [],
  pic_sub_district: [],
}

let signaturePad;

const FormProperty = props => {
  const {masterData, setMasterData, setActiveForm, getProjectUser, setLoad} = props
  const [input, setInput] = useState(INIT_INPUT)
  const [pic, setPic] = useState(REGION_PIC)
  const [loadButt, setLoadButt] = useState(false)

  const [organization, setOrganization] = useState(null)

  const handleOrganization = () => {
    for(let i = 0; i < masterData.organitation.length; i++) {
      if(masterData.organitation[i].title == "Tidak ada") {
        return masterData.organitation[i].id
      }
    }
  }

  const handleChange = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeFile = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.files[0]
    })
  }

  useEffect(() => {
    getDataPic()
  }, [])

  const getDataPic = async () => {
    const token = localStorage.getItem("token")
    // setLoad(true)
    try {
      const city = await getCitiesAll(token)

      setPic({
        ...pic,
        pic_city: city.data
      })
      // setLoad(false)
    } catch (error) {
      Toaster("error", "Ups", "Refresh browser anda")
      // setLoad(false)
    }
  }

  const handleNegara = (data) => {
    for(let i = 0; i < data.length; i++) {
      if(data[i].name === "Indonesia") {
        return data[i].uuid
      }
    }
  }

  const getKota = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kota = await getCities(param, token)
      setMasterData({
        ...masterData,
        city: kota.data,
        district: [],
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        city: [],
        district: [],
        sub_district: []
      })
    }
  }

  const GetKecamatan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kecamatan = await getDistrict(param, token)
      setMasterData({
        ...masterData,
        district: kecamatan.data,
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        district: [],
        sub_district: []
      })
    }
  }

  const getKelurahan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const subdistrict = await getSubDistrict(param, token)
      setMasterData({
        ...masterData,
        sub_district: subdistrict.data,
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        sub_district: []
      })
    }
  }

  const handleRegion = (e) => {
    if(e.target.name === 'province_id') {
      getKota(e.target.value)
    } else if(e.target.name === 'city_id') {
      GetKecamatan(e.target.value)
    } else if(e.target.name === 'district_id') {
      getKelurahan(e.target.value)
    }
  }

  const getKecamatanPic = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kecamatan = await getDistrict(param, token)
      setPic({
        ...pic,
        pic_district: kecamatan.data,
        pic_sub_district: []
      })
    } catch (error) {
      setPic({
        ...pic,
        pic_district: [],
        pic_sub_district: []
      })
    }
  }

  const getKelurahanPic = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const subdistrict = await getSubDistrict(param, token)
      setPic({
        ...pic,
        pic_sub_district: subdistrict.data,
      })
    } catch (error) {
      setPic({
        ...pic,
        pic_sub_district: []
      })
    }
  }

  const handleRegionPic = (e) => {
    if(e.target.name === 'pic_city_id') {
      getKecamatanPic(e.target.value)
    } else if(e.target.name === 'pic_district_id') {
      getKelurahanPic(e.target.value)
    }
  }

  const handleSubmit = async (e, value) => {
    if(signaturePad.isEmpty()) return Toaster("error", "Ups", "Tanda tangan harus diisi")
    const postPropertyData = {
      ...value,
      project_type_id: masterData.project_type[0].uuid,
      ...input,
      ttd_borrower: signaturePad.toDataURL(),
    }
    const formData = new FormData()
    Object.keys(postPropertyData).map(item => {
      formData.append(item, postPropertyData[item])
    })

    const token = localStorage.getItem('token')
    setLoadButt(true)
    try {
      await postProperty(formData, token)
      getProjectUser()
      Toaster("success", "Sukses", "Berhasil ajukan proyek")
      // setActiveForm(0)
      setLoadButt(false)
      props.history.push("/proyeks")
    } catch (error) {
      Toaster("error", `Ups`, error.response.data.message)
      setLoadButt(false)
    }
  }

  return (
    <div>
      <CardTitle className="mb-4">Formulir Pengajuan Proyek Properti</CardTitle>
      <div className="mt-4 ">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleSubmit(e, v)
          }}
        >
          <Card className="border">
            <CardHeader><b>Infomasi Perusahaan</b></CardHeader>
            <CardBody>
              <FormGroup row>
                <Label sm={3}>Pengajuan Sebagai<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="submission_actor_id"
                    type="select"
                    validate={{
                      required: {value: true, errorMessage: 'Pengajuan Sebagai harus diisi'},
                    }}
                    options={masterData.submit_actor}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Badan Usaha<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="business_entity_id"
                    type="select"
                    validate={{
                      required: {value: true, errorMessage: 'Badan Usaha harus diisi'},
                    }}
                    options={masterData.business_entity}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Nama Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="company_name"
                    type="text"
                    placeholder="cth. PT Multi Guna Jaya"
                    helpMessage="Mohon sertakan PT atau CV pada nama perusahaan anda"
                    validate={{
                      required: {value: true, errorMessage: 'Nama Perusahaan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Alamat Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="company_full_address"
                    type="text"
                    placeholder="Alamat"
                    validate={{
                      required: {value: true, errorMessage: 'Alamat Perusahaan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Provinsi<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="province_id"
                    type="select"
                    // value={input.province_id}
                    onChange={handleRegion}
                    validate={{
                      required: {value: true, errorMessage: 'Provinsi harus diisi'},
                    }}
                    options={masterData.province}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Kota<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="city_id"
                    type="select"
                    onChange={(e) => handleRegion(e)}
                    validate={{
                      required: {value: true, errorMessage: 'Kota harus diisi'},
                    }}
                    options={masterData.city}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Kecamatan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="district_id"
                    type="select"
                    onChange={(e) => handleRegion(e)}
                    validate={{
                      required: {value: true, errorMessage: 'Kecamatan harus diisi'},
                    }}
                    options={masterData.district}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Kelurahan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="sub_district_id"
                    type="select"
                    // onChange={(e) => handleRegion(e)}
                    validate={{
                      required: {value: true, errorMessage: 'Kelurahan harus diisi'},
                    }}
                    options={masterData.sub_district}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Negara<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="country_id"
                    type="select"
                    value={handleNegara(masterData.country)}
                    disabled
                    validate={{
                      required: {value: true, errorMessage: 'Negara harus diisi'},
                    }}
                    options={masterData.country}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Status Kepemilikan Kantor<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="property_ownership_id"
                    type="select"
                    validate={{
                      required: {value: true, errorMessage: 'Status Kepemilikan Kantor harus diisi'},
                    }}
                    options={masterData.property_ownership}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Total Asset<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="total_asset"
                    type="number"
                    placeholder="Total Asset"
                    helpMessage="Dalam Rupiah"
                    validate={{
                      required: {value: true, errorMessage: 'Total Asset harus diisi'},
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Total Karyawan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="total_employee"
                    type="number"
                    placeholder="Total Karyawan"
                    // helpMessage="Dalam Persen"
                    validate={{
                      required: {value: true, errorMessage: 'Total Karyawan harus diisi'},
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Tahun Berdiri<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="company_since"
                    type="text"
                    placeholder="ex. 2004"
                    validate={{
                      required: {value: true, errorMessage: 'Tahun Berdiri harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Jumlah Proyek yang sudah di Kerjakan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="total_project_last_year"
                    type="number"
                    placeholder="Jumlah Proyek yang sudah di Kerjakan"
                    validate={{
                      required: {value: true, errorMessage: 'Jumlah Proyek harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Telepon Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="company_telp"
                    type="text"
                    placeholder="Telepon"
                    helpMessage="ex. 081233445566"
                    validate={{
                      required: {value: true, errorMessage: 'Telepon Perusahaan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <Card className="border">
            <CardHeader><b>Legalitas Perusahaan</b></CardHeader>
            <CardBody>
              <FormGroup row>
                <Label sm={3}>Nomor NIB Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="company_siup_number"
                    type="text"
                    placeholder="Nomor NIB Perusahaan"
                    validate={{
                      required: {value: true, errorMessage: 'Nomor NIB Perusahaan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Unggah NIB<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="file_nib_company"
                    type="file"
                    onChange={handleChangeFile}
                    helpMessage="Pdf file maksimal 50 MB"
                    validate={{
                      required: {value: true, errorMessage: 'Unggah NIB harus diisi'},
                    }}
                    value={input.file_nib_company}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Nomor NPWP Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="npwp_company_number"
                    type="text"
                    placeholder="Nomor NPWP"
                    validate={{
                      required: {value: true, errorMessage: 'Nomor NPWP Perusahaan harus diisi'},
                      pattern: {value: '^[0-9]+$', errorMessage: 'Format NPWP harus angka'},
                      minLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
                      maxLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Unggah NPWP Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="file_npwp_company"
                    onChange={handleChangeFile}
                    type="file"
                    helpMessage="jpg, jpeg, atau png maksimal 5 MB"
                    validate={{
                      required: {value: true, errorMessage: 'Unggah NPWP Perusahaan harus diisi'},
                    }}
                    value={input.file_npwp_company}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Unggah SIUP Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="file_siup_company"
                    type="file"
                    onChange={handleChangeFile}
                    helpMessage="Pdf file maksimal 50 MB"
                    validate={{
                      required: {value: true, errorMessage: 'Unggah SIUP Perusahaan harus diisi'},
                    }}
                    value={input.file_siup_company}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Nomor Akta Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="akta_company_number"
                    type="text"
                    placeholder="Nomor Akta Perusahaan"
                    validate={{
                      required: {value: true, errorMessage: 'Nomor Akta Perusahaan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Unggah Akta Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="file_akta_company"
                    type="file"
                    onChange={handleChangeFile}
                    helpMessage="Pdf file maksimal 50 MB"
                    validate={{
                      required: {value: true, errorMessage: 'Akta Perusahaan harus diisi'},
                    }}
                    value={input.file_akta_company}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Nomor Akta Perubahan Terakhir<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="akta_last_change_number"
                    type="text"
                    placeholder="Akta Perubahan Terakhir"
                    // helpMessage="Dalam Persen"
                    validate={{
                      required: {value: true, errorMessage: 'Akta Perubahan Terakhir harus diisi'},
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Unggah Akta Perubahan Terakhir<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="file_akta_last_change_number"
                    type="file"
                    onChange={handleChangeFile}
                    helpMessage="Pdf file maksimal 50 MB"
                    validate={{
                      required: {value: true, errorMessage: 'Akta Perubahan terakhir harus diisi'},
                    }}
                    value={input.file_akta_last_change_number}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>SK Kemenkumham<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="sk_kemenkumham"
                    type="text"
                    placeholder="SK Kemenkumhan"
                    // helpMessage="Dalam Persen"
                    validate={{
                      required: {value: true, errorMessage: 'SK Kemenkumham harus diisi'},
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Unggah SK Kemenkumham<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="file_sk_kemenkumham"
                    type="file"
                    onChange={handleChangeFile}
                    helpMessage="Pdf file maksimal 50 MB"
                    validate={{
                      required: {value: true, errorMessage: 'Unggah SK Kemenkumham harus diisi'},
                    }}
                    value={input.file_sk_kemenkumham}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3} className="pt-0">Apakah perusahaan anda tergabung dalam sebuah organisasi?<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="organitation_id"
                    type="select"
                    placeholder="Organisasi"
                    // helpMessage="Dalam Persen"
                    validate={{
                      required: {value: true, errorMessage: 'Field harus diisi'},
                    }}
                    options={masterData.organitation}
                    onChange={e => setOrganization(e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3} className="pt-0">Unggah Bukti Keanggotaan / Kepengurusan Organisasi{!organization || organization == handleOrganization() ? null : <span className="text-danger">*</span>}</Label>
                <Col sm={9}>
                  <PInput 
                    name="file_organitation"
                    type="file"
                    onChange={handleChangeFile}
                    helpMessage="Pdf file maksimal 50 MB"
                    validate={{
                      required: {value: !organization || organization == handleOrganization() ? false : true, errorMessage: 'Unggah Organisasi harus diisi'},
                    }}
                    disabled={!organization || organization == handleOrganization() ? true : false}
                    value={input.file_organitation}
                    // onChange={handleChange}"a4ffe505-91b1-4c6b-83f6-2162c7e7369c" 
                  />
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <Card className="border">
            <CardHeader><b>Informasi Proyek Diajukan</b></CardHeader>
            <CardBody>
              <FormGroup row>
                <Label sm={3}>Nama Proyek<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="title"
                    type="text"
                    placeholder="Nama Proyek"
                    validate={{
                      required: {value: true, errorMessage: 'Nama Proyek harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Jenis Proyek<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="project_detail_type"
                    type="text"
                    placeholder="Jenis Proyek"
                    validate={{
                      required: {value: true, errorMessage: 'Jenis Proyek harus diisi'},
                    }}
                    // options={masterData.proyekTypeDetail}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Deskripsi Proyek<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="description"
                    type="textarea"
                    placeholder="Deskripsi Proyek"
                    validate={{
                      required: {value: true, errorMessage: 'Deskripsi Proyek harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Alamat Proyek<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="project_full_address"
                    type="textarea"
                    placeholder="Alamat Proyek"
                    validate={{
                      required: {value: true, errorMessage: 'Alamat Proyek harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Jumlah Dana yang Diajukan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="submission_total"
                    type="number"
                    placeholder="Rp"
                    helpMessage="Dalam Rupiah (Maksimal 2 Milyar Rupiah)"
                    validate={{
                      required: {value: true, errorMessage: 'Jumlah Dana harus diisi'},
                      max: {value: 2000000000, errorMessage: 'Maksimal 2 Milyar'}
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3} className="pt-0">Ekspektasi Kontribusi Modal Pendana<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="capital_contribution"
                    type="number"
                    placeholder="Kontribusi Modal Pendana"
                    helpMessage="Dalam Persen (Max 95%)"
                    validate={{
                      required: {value: true, errorMessage: 'Kontribusi Modal Pendana harus diisi'},
                      max: {value: 95, errorMessage: 'Maksimal 95 %'},
                      min: {value: 1, errorMessage: 'Minimal 1 %'}
                    }}
                  />
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <Card className="border">
            <CardHeader><b>Informasi Pengaju</b></CardHeader>
            <CardBody>
              <FormGroup row>
                <Label sm={3}>Nama Lengkap Pengaju<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="pic_full_name"
                    type="text"
                    placeholder="Nama Pengaju"
                    validate={{
                      required: {value: true, errorMessage: 'Nama Lengkap harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Jabatan Pengaju di Perusahaan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="pic_position"
                    type="text"
                    placeholder="Jabatan Pengaju"
                    validate={{
                      required: {value: true, errorMessage: 'Jabatan di Perusahaan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>No HP Pengaju<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="pic_phone_number"
                    type="text"
                    placeholder="ex. 081233445566"
                    validate={{
                      required: {value: true, errorMessage: 'No Hp harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Email Pengaju<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="pic_email"
                    type="text"
                    placeholder="Email Pengaju"
                    validate={{
                      required: {value: true, errorMessage: 'Email harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Tanda Tangan Pengaju<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <Card className="w-50 border border-dark" >
                    <SignaturePad redrawOnResize={true} ref={ref => signaturePad = ref} />
                  </Card>
                  <Button className="btn-sm" onClick={() => signaturePad.clear()}>Hapus</Button>
                  
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Penggunaan Tanda Tangan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="agreementTTD"
                    label="Dengan ini saya bersedia dan menyetujui bahwa penggunaan tanda tangan ini akan digunakan pada akad Musyarakah pada PrivyId sebagai platform tanda tangan elektronik"
                    type="checkbox"
                    validate={{
                      required: {value: true, errorMessage: 'Pernyataan harus diisi'},
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
                <Col sm={9}>
                  <PInput 
                    name="agreement"
                    label="Dengan ini saya menyatakan bahwa semua data yang diberikan
                    adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
                    data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
                    pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
                    type="checkbox"
                    validate={{
                      required: {value: true, errorMessage: 'Persetujuan harus diisi'},
                    }}
                    // value={input}
                    // onChange={handleChange}
                  />
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          <div className="row justify-content-end mb-3">
            <div className="col-12">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan'}
              </button>

            </div>
          </div>
        </AvForm>
        <div className="row justify-content-end mb-5">
          <div className="col-12">
            <button
              className="btn btn-secondary btn-block waves-effect waves-light"
              type="submit"
              disabled={loadButt}
              onClick={() => setActiveForm(0)}
            >
              {`Batal`}
            </button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(FormProperty)