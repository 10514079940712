import PropTypes from 'prop-types'
import React from "react"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Media,
  UncontrolledTooltip,
  Row,
  Table,
  Container,
  Button
} from "reactstrap"

import moment from "moment"

const ListPortfolio = ({ projects, history }) => {

  const handleStatusProyek = status => {
    if(status >= 4 && status <= 6 || status === 10) return <Badge color="info">Proyek Sedang Berjalan</Badge>
    if(status === 7) return <Badge color="danger">Proyek Selesai (Proyek Berjalan Tidak Maksimal)</Badge>
    if(status === 8) return <Badge color="danger">Proyek Selesai (Proyek Berjalan Kurang Maksimal)</Badge>
    if(status === 9) return <Badge color="primary">Proyek Selesai (Proyek Berjalan Maksimal)</Badge>
    // if(status === 7 || status === 6) return <Badge color="info">Sedang Berjalan</Badge>
    // if(status === 4 || status === 5) return <Badge color="info">Sedang Pengumpulan Dana</Badge>
    return <Badge color="info">Proyek Belum Dimulai</Badge>
  }

  return (
    <React.Fragment>
      <Container fluid>

        <Row>
          <Col lg="12">
            <div className="">
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap table-centered table-borderless">
                  <thead>
                    <tr>
                      {/* <th scope="col">No</th> */}
                      <th scope="col">Nama Proyek</th>
                      <th scope="col">Status Proyek</th>
                      <th scope="col">Tenor</th>
                      <th scope="col">Nisbah</th>
                      <th scope="col">Transaksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((item, index) => {
                      return (
                        <tr key={index}>
                          {/* <td>{index + 1}.</td> */}
                          <td>{item.title}</td>
                          <td>{handleStatusProyek(item.status)}</td>
                          <td>{item.tenor} Bulan</td>
                          <td>{item.nisbah} %</td>
                          <td>
                            <Button
                              type="button"
                              color="primary"
                              className="btn-sm"
                              // onClick={() => setSelectFolio(item)}
                              onClick={() => history.push(`/portfolio-transaksi?id=${item.uuid}&&page=1`)}
                            >
                              Lihat
                            </Button> 
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

ListPortfolio.propTypes = {
  projects: PropTypes.array
}

export default withRouter(ListPortfolio)
