import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import PInputHTML from "components/Input/PInputHTML"
import { API_URL } from "helpers/api_url"
import {
  delCampaignDesc,
  getCampaignSelector,
  getTitleDescription,
  getTitleDescriptionEn,
  postDescription,
  putCampaignDesc,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Card,
  CardText,
  Label,
  FormGroup,
  Badge,
} from "reactstrap"
import AsyncSelect from "react-select/async"
import Switch from "react-switch"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const CampaignDeskripsi = props => {
  const { campaign, getCampaignUuid, handleRoles, loadButt, setLoadButt } =
    props
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [file, setFile] = useState(null)

  const [itemEdit, setItemEdit] = useState(null)

  const [description, setDescription] = useState("")
  const [description_en, setDescription_en] = useState("")

  const [titleSelect, setTitleSelect] = useState(null)
  const [titleSelectEn, setTitleSelectEn] = useState(null)

  const [titleSelectEdit, setTitleSelectEdit] = useState(null)
  const [titleSelectEditEn, setTitleSelectEditEn] = useState(null)

  const [titleDescEn, setTitleDescEn] = useState([])

  const [publicDescription, setPublicDescription] = useState(false)

  useEffect(async () => {
    const token = localStorage.getItem("token")
    const data = await getTitleDescriptionEn(token)
    setTitleDescEn(data.data)
  }, [])

  useEffect(() => {
    if (titleSelect) {
      const select = titleDescEn.find(item => item.id == titleSelect?.value)
      if (select) {
        setTitleSelectEn({
          value: select?.id,
          label: select?.title,
        })
      } else {
        setTitleSelectEn(null)
      }
    } else {
      setTitleSelectEn(null)
    }
  }, [titleSelect])

  useEffect(() => {
    if (titleSelectEdit) {
      const select = titleDescEn.find(item => item.id == titleSelectEdit?.value)
      if (select) {
        setTitleSelectEditEn({
          value: select?.id,
          label: select?.title,
        })
      } else {
        setTitleSelectEditEn(null)
      }
    } else {
      setTitleSelectEditEn(null)
    }
  }, [titleSelectEdit])

  const toggle = () => {
    setDescription("")
    setDescription_en("")
    setTitleSelect(null)
    setTitleSelectEn(null)
    setFile(null)
    setPublicDescription(false)
    setModal(!modal)
  }

  const toggleEdit = item => {
    setItemEdit(item)
    setPublicDescription(Boolean(item?.isPublic))
    setTitleSelectEdit(
      item?.campaign_description_title
        ? {
            value: item.campaign_description_title?.id,
            label: item.campaign_description_title?.title,
          }
        : null
    )
    setModalEdit(!modalEdit)
  }

  const handleSubmit = async (e, value) => {
    const newValue = {
      ...value,
      description,
      description_en,
      image: file,
      isPublic: publicDescription,
    }
    if (titleSelect) {
      newValue.campaign_description_title_id = titleSelect?.value
    }
    const token = localStorage.getItem("token")

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postDescription(campaign.uuid, formData, token)
      getCampaignUuid()
      toggle()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Tambah Data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleDelete = async item => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await delCampaignDesc(campaign.uuid, item.uuid, token)
      getCampaignUuid()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil hapus data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleSubmitEdit = async (e, value) => {
    const token = localStorage.getItem("token")

    const newValue = {
      ...value,
      description: itemEdit?.description,
      description_en: itemEdit?.description_en,
      image: file,
      isPublic: publicDescription,
    }

    if (titleSelectEdit) {
      newValue.campaign_description_title_id = titleSelectEdit.value
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await putCampaignDesc(campaign.uuid, itemEdit.uuid, formData, token)
      getCampaignUuid()
      setFile(null)
      toggleEdit(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const loadOptions = (inputValue, callback) => {
    const token = localStorage.getItem("token")
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getTitleDescription(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const loadOptionsEn = (inputValue, callback) => {
    const token = localStorage.getItem("token")
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getTitleDescriptionEn(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  return (
    <div>
      <Modal isOpen={modal} autoFocus={true} size="lg">
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Tambah Deskripsi</ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmit(e, v)
              }}
            >
              <Row className="form-desc-campaign">
                <Col lg="6">
                  <FormGroup>
                    <Label>Judul</Label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptions}
                      defaultOptions
                      onChange={e => {
                        setTitleSelect(e)
                      }}
                      value={titleSelect}
                      isClearable
                    />
                  </FormGroup>
                  {!titleSelect && (
                    <PInput
                      name="title"
                      type="text"
                      label="Judul"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Judul harus diisi",
                        },
                      }}
                    />
                  )}
                  <PInput
                    name="subtitle"
                    type="text"
                    label="Sub Judul"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Sub Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label>Judul (EN)</Label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptionsEn}
                      defaultOptions
                      isDisabled
                      onChange={e => {
                        setTitleSelectEn(e)
                      }}
                      value={titleSelectEn}
                    />
                  </FormGroup>
                  {!titleSelect && (
                    <PInput
                      name="title_en"
                      type="text"
                      label="Judul (EN)"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Judul harus diisi",
                        },
                      }}
                    />
                  )}
                  <PInput
                    name="subtitle_en"
                    type="text"
                    label="Sub Judul (EN)"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Sub Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="12">
                  <PInputHTML
                    label="Deskripsi"
                    value={description}
                    onChange={val => {
                      setDescription(val)
                    }}
                  />

                  <PInputHTML
                    label="Deskripsi (EN)"
                    value={description_en}
                    onChange={val => {
                      setDescription_en(val)
                    }}
                  />
                </Col>
              </Row>

              <PInput
                name="image"
                type="file"
                label="Gambar"
                helpMessage="jpg, jpeg atau png"
                onChange={e => setFile(e.target.files[0])}
                validate={{
                  required: { value: true, errorMessage: "Gambar harus diisi" },
                }}
                value={file}
              />

              <FormGroup className="mb-4 d-flex align-items-center" row>
                <Label md="3">Publik Deskripsi</Label>
                <Col md="9" className="d-flex align-items-center">
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2 mr-3"
                    onColor="#0ec6a2"
                    onChange={() => {
                      setPublicDescription(!publicDescription)
                    }}
                    checked={publicDescription}
                  />
                </Col>
              </FormGroup>
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <Modal isOpen={modalEdit} autoFocus={true} size="lg">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleEdit(null)}>
            Ubah Deskripsi
          </ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmitEdit(e, v)
              }}
            >
              <Row className="form-desc-campaign">
                <Col lg="6">
                  <FormGroup>
                    <Label>Judul</Label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptions}
                      defaultOptions
                      onChange={e => {
                        setTitleSelectEdit(e)
                      }}
                      value={titleSelectEdit}
                      isClearable
                    />
                  </FormGroup>
                  {!titleSelectEdit && (
                    <PInput
                      name="title"
                      type="text"
                      label="Judul"
                      value={itemEdit ? itemEdit.title : ""}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Judul harus diisi",
                        },
                      }}
                    />
                  )}
                  <PInput
                    name="subtitle"
                    type="text"
                    label="Sub Judul"
                    value={itemEdit ? itemEdit.subtitle : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Sub Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label>Judul (EN)</Label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadOptionsEn}
                      defaultOptions
                      isDisabled
                      onChange={e => {
                        setTitleSelectEditEn(e)
                      }}
                      value={titleSelectEditEn}
                    />
                  </FormGroup>
                  {!titleSelectEdit && (
                    <PInput
                      name="title_en"
                      type="text"
                      label="Judul (EN)"
                      value={itemEdit ? itemEdit.title_en : ""}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Judul harus diisi",
                        },
                      }}
                    />
                  )}
                  <PInput
                    name="subtitle_en"
                    type="text"
                    label="Sub Judul (EN)"
                    value={itemEdit ? itemEdit.subtitle_en : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Sub Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="12">
                  <PInputHTML
                    label="Deskripsi"
                    value={itemEdit ? itemEdit.description : ""}
                    onChange={val => {
                      setItemEdit({
                        ...itemEdit,
                        description: val,
                      })
                    }}
                  />

                  <PInputHTML
                    label="Deskripsi (EN)"
                    value={itemEdit ? itemEdit.description_en : ""}
                    onChange={val => {
                      setItemEdit({
                        ...itemEdit,
                        description_en: val,
                      })
                    }}
                  />
                </Col>
              </Row>

              <PInput
                name="image"
                type="file"
                label="Image"
                helpMessage="jpg, jpeg atau png"
                onChange={e => setFile(e.target.files[0])}
                value={file}
              />

              <FormGroup className="mb-4 d-flex align-items-center" row>
                <Label md="3">Publik Deskripsi</Label>
                <Col md="9" className="d-flex align-items-center">
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2 mr-3"
                    onColor="#0ec6a2"
                    onChange={() => {
                      setPublicDescription(!publicDescription)
                    }}
                    checked={publicDescription}
                  />
                </Col>
              </FormGroup>
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button
          color="info"
          onClick={toggle}
          disabled={
            handleRoles().includes("Moderator")
              ? false
              : handleRoles().includes("Bisnis")
              ? true
              : false
          }
        >
          + Tambah Deskripsi
        </Button>
      </div>

      <Row className="mt-5">
        {campaign &&
          campaign.campaign_description.map(item => {
            console.log(Boolean(item?.isPublic))
            return (
              <Col lg="12">
                <Card>
                  <Row className="no-gutters">
                    <Col md={4}>
                      <CardImg
                        className="img-fluid"
                        src={API_URL + item.image}
                        alt="Skote"
                      />
                    </Col>
                    <Col md={8}>
                      <CardBody className="pt-0 pb-0">
                        <CardTitle className="mb-0">
                          {item.title || item.campaign_description_title?.title}
                        </CardTitle>
                        <CardText>
                          <small className="text-muted">{item.subtitle}</small>
                        </CardText>
                        <CardText className="text-justify">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </CardText>
                      </CardBody>
                    </Col>
                    <div className="py-1 font-12">
                      Status:{" "}
                      {Boolean(item.isPublic) ? (
                        <Badge color="warning">Public</Badge>
                      ) : (
                        <Badge color="info">Private</Badge>
                      )}
                    </div>
                  </Row>
                  <Row className="row">
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="danger"
                        onClick={() => handleDelete(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Hapus
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="info"
                        onClick={() => toggleEdit(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Ubah
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )
          })}
      </Row>
    </div>
  )
}

export default CampaignDeskripsi
