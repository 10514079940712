import Toaster from "components/Common/Toast"
import {
  getAkadAddendum,
  getBilUjrahAdmin,
  getCampaignAdmin,
  getCampaignSelector,
  getMurabahahAdmin,
  getMusyarakahAdmin,
  getWakalahAdmin,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { API_URL } from "helpers/api_url"
import { connect } from "react-redux"
import AsyncSelect from "react-select/async"

const ListAkad = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [akadWakalah, setAkadWakalah] = useState([])
  const [akadMusyarakah, setAkadMusyarakah] = useState([])
  const [akadBilUjrah, setAkadBilUjrah] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [select, setSelect] = useState(null)
  const [selected, setSelected] = useState(null)

  const [modalAkad, setModalAkad] = useState(false)
  const [urlDocument, setUrlDocument] = useState("")
  const [akadName, setAkadName] = useState("")

  const [akadAddendum, setAkadAddendum] = useState([])

  const [cmpgn, setCmpgn] = useState(null)

  const toggleAkad = () => setModalAkad(!modalAkad)

  useEffect(() => {
    if (select) {
      getAkads()
      getCampaignDetail()
    }
  }, [select, activeTab])

  const getCampaignDetail = async () => {
    setLoad(true)
    const res = await getCampaignAdmin(select, token)
    setCmpgn(res.data)
    setLoad(false)
  }

  const getAkads = async () => {
    setLoad(true)
    try {
      if (activeTab === "1") {
        const wak = await getWakalahAdmin(select, token)
        setAkadWakalah(wak.data)
      } else if (activeTab === "2") {
        const cmpgn = await getCampaignAdmin(select, token)
        if (cmpgn?.data?.akad_type?.title === "Murabahah") {
          const mur = await getMurabahahAdmin(select, token)
          setAkadMusyarakah(mur.data)
        } else {
          const mus = await getMusyarakahAdmin(select, token)
          setAkadMusyarakah(mus.data)
        }
      } else if (activeTab === "3") {
        const bilUjrh = await getBilUjrahAdmin(select, token)
        setAkadBilUjrah(bilUjrh.data)
      } else {
        const addendum = await getAkadAddendum(select, "", token)
        setAkadAddendum(addendum.data)
      }
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleRoles = () => {
    let result = []
    props.user.user_role.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  const renderStatusTTD = status => {
    if (status == 1) return <Badge color="danger">Belum TTD</Badge>
    if (status == 2) return <Badge color="primary">Sudah TTD</Badge>
    return <Badge color="warning">Token tidak valid</Badge>
  }

  const renderTtdDirector = status => {
    if (status == 1) return <Badge color="danger">Belum TTD</Badge>
    if (status == 2) return <Badge color="primary">Sudah TTD</Badge>
    return <Badge color="warning">Token tidak valid</Badge>
  }

  const wakalahTable = {
    columns: [
      {
        field: "number",
        label: "Nomor",
        sort: "asc",
      },
      {
        field: "name",
        label: "Nama Pendana",
      },
      {
        field: "status",
        label: "TTD Pendana",
      },
      {
        field: "ttd_director",
        label: "TTD Ethis",
      },
      {
        field: "signedAt",
        label: "Tanggal TTD",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: akadWakalah.map(item => {
      return {
        ...item,
        name: `${item.user_privy.user.first_name} ${item.user_privy.user.last_name}`,
        signedAt: item.signedAt
          ? moment(item.signedAt).format("DD MMM YYYY")
          : "-",
        status: renderStatusTTD(item.status),
        ttd_director: renderTtdDirector(item.borrower_status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => {
              setUrlDocument(item.url_document)
              setAkadName("Wakalah")
              toggleAkad()
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const musyarakahTable = {
    columns: [
      {
        field: "number",
        label: "Nomor",
        sort: "asc",
      },
      {
        field: "name",
        label: "Nama Pendana",
      },
      {
        field: "status",
        label: "TTD Pendana",
      },
      {
        field: "signedAt",
        label: "Tanggal TTD Pendana",
      },
      {
        field: "borrower_status",
        label: "TTD Penerima Dana",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: akadMusyarakah.map(item => {
      return {
        ...item,
        name: `${item.user_privy.user.first_name} ${item.user_privy.user.last_name}`,
        signedAt: item.signedAt
          ? moment(item.signedAt).format("DD MMM YYYY")
          : "-",
        status: renderStatusTTD(item.status),
        borrower_status: renderStatusTTD(item.borrower_status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => {
              setUrlDocument(item.url_document)
              setAkadName(cmpgn?.akad_type?.title || "Musyarakah")
              toggleAkad()
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const addendumTable = {
    columns: [
      {
        field: "number",
        label: "Nomor",
        sort: "asc",
      },
      {
        field: "name",
        label: "Nama Pendana",
      },
      {
        field: "status",
        label: "TTD Pendana",
      },
      {
        field: "signedAt",
        label: "Tanggal TTD Pendana",
      },
      {
        field: "borrower_status",
        label: "TTD Penerima Dana",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: akadAddendum.map(item => {
      return {
        ...item,
        name: `${item.user_privy.user.first_name} ${item.user_privy.user.last_name}`,
        signedAt: item.signedAt
          ? moment(item.signedAt).format("DD MMM YYYY")
          : "-",
        status: renderStatusTTD(item.lender_status),
        borrower_status: renderStatusTTD(item.borrower_status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => {
              setUrlDocument(item.url_document)
              setAkadName(`${cmpgn?.akad_type?.title || "Musyarakah"} Addendum`)
              toggleAkad()
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const wakalahBilUjrahTable = {
    columns: [
      {
        field: "name",
        label: "Nama Penerima Dana",
      },
      {
        field: "status",
        label: "TTD Penerima Dana",
      },
      {
        field: "ttd_director",
        label: "TTD Ethis",
      },
      {
        field: "signedAt",
        label: "Tanggal TTD",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: akadBilUjrah.map(item => {
      return {
        ...item,
        name: `${item.user_privy.user.first_name} ${item.user_privy.user.last_name}`,
        signedAt: item.signedAt
          ? moment(item.signedAt).format("DD MMM YYYY")
          : "-",
        status: renderStatusTTD(item.status),
        ttd_director: renderTtdDirector(item.director_status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => {
              setUrlDocument(item.url_document)
              setAkadName("Wakalah Bil Ujrah")
              toggleAkad()
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const loadOptions = (inputValue, callback) => {
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getCampaignSelector(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const renderModalAkad = () => {
    return (
      <Modal
        isOpen={modalAkad}
        // toggle={toggleAkad}
        scrollable={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Dokumen Akad {akadName}</h5>
          <button
            type="button"
            onClick={toggleAkad}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe
              title={akadName}
              className="embed-responsive-item"
              src={urlDocument}
            />
          </div>
        </div>
      </Modal>
    )
  }

  if (load) return <Loading title="Daftar" breadcrumbItem="Akad" />

  return (
    <React.Fragment>
      {renderModalAkad()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Daftar" breadcrumbItem="Akad" />
          <Row className="mb-2">
            <Col lg="12">
              <FormGroup row>
                <Label sm={2}>Pilih Kampanye</Label>
                <Col sm={6}>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={e => {
                      setSelect(e.value)
                      setSelected(e)
                    }}
                    value={selected}
                    // onInputChange={handleInputSearch}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <div className="w-100 mb-3">
                    <div>
                      {select && detailCmpgn ?
                        <table class="table table-striped border">
                          <thead>
                            <tr>
                              <th scope="col">Pengembalian Peminjam</th>
                              <th scope="col">Menunggu Pengembalian Peminjam</th>
                              <th scope="col">Estimasi Pengembalian dengan Nisbah</th>
                              <th scope="col">Sisa yang harus dibayar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Rp {parseInt(detailCmpgn.collection_collect).toLocaleString()}</td>
                              <td>Rp {parseInt(detailCmpgn.estimate_collection).toLocaleString()}</td>
                              <td>Rp {parseInt(detailCmpgn.estimate_repayment).toLocaleString()}</td>
                              <td>Rp {(parseInt(detailCmpgn.estimate_repayment) - parseInt(detailCmpgn.collection_collect)).toLocaleString()}</td>
                            </tr>
                          </tbody>
                        </table> : null
                      }
                    </div>
                  </div> */}

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Wakalah
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        {cmpgn?.akad_type?.title || "Musyarakah"}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        {cmpgn?.akad_type?.title || "Musyarakah"} Addendum
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Wakalah Bil Ujrah
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <MDBDataTable
                        responsive
                        bordered
                        data={wakalahTable}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <MDBDataTable
                        responsive
                        bordered
                        data={musyarakahTable}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <MDBDataTable
                        responsive
                        bordered
                        data={wakalahBilUjrahTable}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <MDBDataTable
                        responsive
                        bordered
                        data={addendumTable}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(ListAkad)
