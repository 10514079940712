import React from "react"
import { connect } from "react-redux"
import Admin from "./Admin"
import Admins from "./Admins"
import Borrower from "./Borrower"
import Director from "./Director"
import Lender from "./Lender"
import User from "./User"

const Dasbor = props => {
  const roles = props.user.user_role.map(item => {
    return item.role.title
  })

  if (roles.includes("Moderator")) {
    return <Admin user={props.user} />
  }

  if (roles.includes("Director")) {
    return <Director user={props.user} />
  }

  if (roles.includes("Lender") && roles.includes("Borrower")) {
    return <User user={props.user} />
  }

  if (roles.includes("Lender")) {
    return <Lender user={props.user} />
  }

  if (roles.includes("Borrower")) {
    return <Borrower user={props.user} />
  }

  return <Admins user={props.user} />
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, {})(Dasbor)
