import React, { useEffect, useState } from "react"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { map } from "lodash"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

import Toaster from "components/Common/Toast"
import { getCampaign, getCampaignAdmin } from "helpers/backend_helpers"
import { withRouter } from "react-router-dom"
import CampaignEdit from "./CampaignEdit"
import CampaignDeskripsi from "./CampaignDeskripsi"
import CampaignGallery from "./CampaignGallery"
import ProjectUpdate from "./ProjectUpdate"
import { connect } from "react-redux"
import Loading from "pages/Utility/Loading"

const CampaignDetail = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [campaign, setCampaign] = useState(null)

  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)

  useEffect(() => {
    getCampaignUuid()
  }, [activeTab])

  const getCampaignUuid = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      const data = await getCampaignAdmin(props.match.params.uuid, token)
      setCampaign(data.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleChange = (e, item, action) => {
    action({
      ...item,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeFile = (e, item, action) => {
    action({
      ...item,
      [e.target.name]: e.target.files[0],
    })
  }

  const handleRoles = () => {
    let result = []
    props.user.user_role.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  if (load) return <Loading />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Detail" breadcrumbItem="Kampanye" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Kelola Kampanye</h4>

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Detail Kampanye
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                        // style={{cursor: `${handleRoles().includes("Bisnis") ? "not-allowed" : "pointer"}`}}
                      >
                        Deskripsi Kampanye
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                        // style={{cursor: `${handleRoles().includes("Bisnis") ? "not-allowed" : "pointer"}`}}
                      >
                        Gallery Kampanye
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                        // style={{cursor: `${handleRoles().includes("Bisnis") ? "not-allowed" : "pointer"}`}}
                      >
                        Perkembangan Proyek
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="all-order">
                      <CampaignEdit
                        campaign={campaign}
                        handleChange={handleChange}
                        handleChangeFile={handleChange}
                        getCampaignUuid={getCampaignUuid}
                        setLoadButt={setLoadButt}
                        loadButt={loadButt}
                      />
                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <CampaignDeskripsi
                        campaign={campaign}
                        getCampaignUuid={getCampaignUuid}
                        handleRoles={handleRoles}
                        setLoadButt={setLoadButt}
                        loadButt={loadButt}
                      />
                    </TabPane>
                    <TabPane tabId="3" id="processing">
                      <CampaignGallery
                        campaign={campaign}
                        getCampaignUuid={getCampaignUuid}
                        handleRoles={handleRoles}
                        setLoadButt={setLoadButt}
                        loadButt={loadButt}
                      />
                    </TabPane>
                    <TabPane tabId="4" id="processing">
                      <ProjectUpdate
                        campaign={campaign}
                        getCampaignUuid={getCampaignUuid}
                        handleRoles={handleRoles}
                        setLoadButt={setLoadButt}
                        loadButt={loadButt}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(connect(mapStateToProps, null)(CampaignDetail))
