import React from "react"
import { FormGroup, Label } from "reactstrap"
import { Editor } from "@tinymce/tinymce-react"

export default ({ value, onChange, className, style, label }) => {
  return (
    <FormGroup className={`${className}`} style={style}>
      <Label>{label}</Label>
      <Editor
        // initialValue="<p>Initial content</p>"
        apiKey="400x7qjsu6kc7ihxs8bhv9klervfy5k1taha6583pje42dfb"
        init={{
          plugins:
            "print preview code searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime lists wordcount imagetools textpattern help",
          toolbar:
            "bold italic underline strikethrough superscript subscript forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code",
        }}
        onEditorChange={(content, editor) => onChange(content)}
        value={value}
      />
    </FormGroup>
  )
}
