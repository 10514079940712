import React, { useEffect, useState } from "react"
import { Badge, Button, Container } from "reactstrap"

import Board, { moveCard } from "@lourenci/react-kanban";
import Toaster from "components/Common/Toast";
import { getOrderCampaign, postOrderCampaign } from "helpers/backend_helpers";
import Loading from "pages/Utility/Loading";
import Skeleton from "react-loading-skeleton";

const SortCampaign = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [controlledBoard, setBoard] = useState(null)
  const [loadButt, setLoadButt] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const handleStatusOrder = item => {
    if(item.status === 11) return <Badge color="secondary">Dibatalkan</Badge>
    if(item.deleted_at) return <Badge color="danger">Dihapus</Badge>
    if(item.status === 1 || item.status === 2 || item.status === 3 || item.status === 5) return <Badge color="info">Tidak Aktif</Badge>
    return <Badge color="primary">Aktif</Badge>
  }

  const getData = async () => {
    setLoad(true)
    try {
      const cmpgn = await getOrderCampaign(token)
      let arrNew = cmpgn.data.map(item => {
        return {
          id: item.id,
          title: item.title,
          description: <div>{handleStatusOrder(item)} {item.order}</div>
        }
      })
      setBoard({
        columns: [
          {
            id: 1,
            title: "Sorting Kampanye",
            cards: arrNew,
          },
        ]
      })
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  function handleCardMove(_card, source, destination) {
    // console.log(source, destination)
    // let cardCampaign = [...controlledBoard.columns[0].cards]
    // if(source.fromPosition > destination.toPosition) {
    //   console.log('OKe')
    //   for(let i = destination.toPosition; i < cardCampaign.length; i++) {
    //     if(i === destination.toPosition) {

    //     }
    //     cardCampaign[i].description = 0
    //   }
    // } else {
    //   console.log('ok')
    // }

    // console.log(cardCampaign)

    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
  }

  const handleOrder = async () => {
    let postOrders = controlledBoard.columns[0].cards.map((item, idx) => {
      return {
        campaign_id: item.id,
        order: (controlledBoard.columns[0].cards.length - idx) - 1,
      }
    })

    setLoadButt(true)
    try {
      await postOrderCampaign({orders: postOrders}, token)
      setLoadButt(false)
      getData()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  if(!controlledBoard || load) return <Skeleton height={600} />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="row d-flex justify-content-center">
            <div className="col-12 scroll-order">
              <Board 
                onCardDragEnd={handleCardMove} 
                disableColumnDrag
              >
                {controlledBoard}
              </Board>
              <div className="">
                <Button 
                  className="w-100" 
                  onClick={handleOrder}
                  disabled={loadButt}
                >
                  {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SortCampaign
