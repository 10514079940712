import Footer from 'pages/HomePage/Component/Footer'
import Menuhome from 'pages/HomePage/Component/Menuhome'
import PropTypes from 'prop-types'
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Navbar from './Navbar'

class NonAuthLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.history.location.pathname)

    if(currentage) {
      currentage = currentage + " | "
    } else {
      currentage = ""
    }

    document.title =
      currentage + "Ethis - Pembiayaan Peer to Peer Syariah"
  }
  render() {
    return (
      <React.Fragment>
        {/* header */}
        {/* <Menuhome /> */}
        <Navbar />
        {this.props.children}
        {/* footer */}
        <Footer />
      </React.Fragment>
    )
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default withRouter(NonAuthLayout)
