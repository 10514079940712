import PropTypes from 'prop-types'
import React from "react"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Media,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import images from "assets/images"
import companies from "assets/images/companies"
import { API_URL } from 'helpers/api_url'

const CardProject = ({ projects, history, handlePublish, handleUnPublish }) => {
  return (
    <React.Fragment>
      {map(projects, (project, key) => (
        <Col xl="4" sm="6" key={"_project_" + key}>
          <Card>
            <CardBody>
              <Media>
                <div className="avatar-md mr-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    {!project.image ?
                      <img src={`https://dev.devopsethis.com/image/content/menu/ethis.png`} alt="" height="30" />
                      : <img src={`${API_URL}${project.image}`} alt="" height="30" />
                    }
                  </span>
                </div>

                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-15">
                    <Link
                      to={`/projects-overview/${project.id}`}
                      className="text-dark"
                    >
                      {project.title}
                    </Link>
                  </h5>
                  <Row className="border-bottom">
                    <Col lg="5">Target</Col>
                    <Col lg="7">{project.target}</Col>
                  </Row>
                  <Row className="border-bottom">
                    <Col lg="5">Tenor</Col>
                    <Col lg="7">{project.tenor ? project.tenor : "-"}</Col>
                  </Row>
                  <Row className="border-bottom">
                    <Col lg="5">Nisbah</Col>
                    <Col lg="7">{project.nisbah ? project.nisbah : "-"}</Col>
                  </Row>
                  {/* <p className="text-muted">Target: {project.target}</p>
                  <p className="">{project.tenor + " Bulan" || "Belum ada"}</p>
                  <p className="">{project.nisbah || "Belum ada"}</p> */}

                </Media>
              </Media>
            </CardBody>
            <div className="px-3 py-2 border-top text-center">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">
                  {project.status === 1 && <h5><Badge color="danger">Not Ready</Badge></h5>}
                  {project.status === 2 && <h5><Badge color="warning">Ready Akad</Badge></h5>}
                  {project.status === 3 && <h5><Badge color="info">Ready Publish</Badge></h5>}
                  {project.status === 4 && <h5><Badge color="success">Active</Badge></h5>}
                  {project.status === 5 && <h5><Badge color="danger">Not Active</Badge></h5>}
                </li>
                {/* <li className="list-inline-item mr-3" id="dueDate">
                  <i className="bx bx-calendar mr-1" /> {project.end_date_campaign || "-"}
                  <UncontrolledTooltip placement="top" target="dueDate">
                    Tanggal berakhir kampanye
                  </UncontrolledTooltip>
                </li> */}
                <li className="list-inline-item mr-3">
                  <Button
                    type="button"
                    color="secondary"
                    className="btn-sm"
                    onClick={() => history.push(`/campaign/${project.uuid}`)}
                  >
                    Kelola
                  </Button>
                </li>
                {project.status === 3 || project.status === 5 ?
                  <li className="list-inline-item mr-3">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-sm"
                      onClick={() => handlePublish(project.uuid)}
                    >
                      Terbitkan
                    </Button>
                  </li> : null
                }
                {project.status === 4 && 
                  <li className="list-inline-item mr-3">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-sm"
                      onClick={() => handleUnPublish(project.uuid)}
                    >
                      Unpublish
                    </Button>
                  </li>
                }
              </ul>
            </div>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

CardProject.propTypes = {
  projects: PropTypes.array
}

export default withRouter(CardProject)
