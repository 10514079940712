import { get } from "helpers/api_helpers"
import { GET_REASON, GET_USERS_KYC } from "helpers/url_helpers"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import { timeDiffCalc } from "helpers/method_helpers"

import Breadcrumbs from "components/Common/Breadcrumb"
import ModalDetail from "./ModalDetail"
import ModalApprove from "./ModalApprove"
import {
  getApprovalHistory,
  getReason,
  getUserKYC,
  getUserKYCNotVerified,
} from "helpers/backend_helpers"
import Toaster from "components/Common/Toast"
import { MDBDataTable } from "mdbreact"
import classnames from "classnames"
import moment from "moment"
import Loading from "pages/Utility/Loading"
import DataTable from "components/Common/DataTable"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const Users = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [historyTab, setHistoryTab] = useState("1")
  const [users, setUsers] = useState([])
  const [reason, setReason] = useState([])
  const [uuid, setUuid] = useState("")
  const [user, setUser] = useState(null)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalApproved, setmodalApproved] = useState(false)
  const [appHistory, setAppHistory] = useState([])
  const [userPreApproved, setUserPreApproved] = useState([])
  const [load, setLoad] = useState(false)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)
  const [keySearch, setKeySearch] = useState("email")
  const [search, setSearch] = useState("")

  // console.log(props.user.user_role.map(item => item.role.title))
  const handleDisableBisdev = () => {
    let arrRoles = props.user.user_role.map(item => item.role.title)
    if (arrRoles.includes("Moderator")) return false
    if (arrRoles.includes("Bisnis")) return true
    return false
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleTabHistory = tab => {
    if (historyTab !== tab) {
      setHistoryTab(tab)
    }
  }

  const getUsers = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      if (activeTab === "3") {
        const pre = await getUserKYCNotVerified(token)
        setUserPreApproved(pre.data)
      } else if (activeTab === "1") {
        const data = await getUserKYC(token)
        setUsers(data.data)
      } else {
        let query =
          historyTab === "1"
            ? `?roles=lender&per_page=${perPage}&page=${page}`
            : `?roles=borrower&per_page=${perPage}&page=${page}`

        if (search) {
          query += `&keySearch=${keySearch}&search=${search}`
        }
        const his = await getApprovalHistory(token, query)
        setAppHistory(his.data)
        setTotalPage(his.meta.pagination.last_page)
        setCurrentPage(his.meta.pagination.current_page)
        setPagination(his.meta.pagination)
      }
      setLoad(false)
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
      setLoad(false)
    }
  }

  const getReasonData = async () => {
    const token = localStorage.getItem("token")
    try {
      const data = await getReason(token)
      setReason(data.data)
    } catch (error) {
      // Toaster("error")
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  useEffect(() => {
    getUsers()
  }, [activeTab, historyTab, perPage, page])

  useEffect(() => {
    getReasonData()
  }, [])

  const toggleModalDetail = obj => {
    setUser(obj)
    setModalDetail(!modalDetail)
  }

  const toggleModalApproved = id => {
    setUuid(id)
    setmodalApproved(!modalApproved)
  }

  const dataHistories = {
    columns: [
      {
        label: "Waktu Persetujuan",
        field: "time_approved",
      },
      {
        label: "Pengguna",
        field: "emailUser",
        sort: "asc",
      },
      {
        label: "Sebagai",
        field: "roles",
        sort: "asc",
      },
      {
        label: "Pembuat",
        field: "emailCreator",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "details",
        sort: "asc",
      },
      {
        label: "Persetujuan",
        field: "isApprove",
        sort: "asc",
      },
      {
        label: "Alasan",
        field: "reason",
        sort: "asc",
      },
      {
        label: "Deskripsi",
        field: "description",
        sort: "asc",
      },
    ],
    rows: appHistory.map(item => {
      return {
        ...item,
        time_approved:
          !item.dateTimeAfterAction || !item.dateTimeBeforeAction
            ? "-"
            : timeDiffCalc(item.dateTimeAfterAction, item.dateTimeBeforeAction),
        roles: item.user.user_role
          .map(el => {
            // if (el.role.title == "Lender")
            //   return item.user.lenderAsCompany === 0
            //     ? "Lender (Perorangan)"
            //     : "Lender (Institusi)"
            return el.role.title
          })
          .join(", "),
        emailUser: item.user.email,
        emailCreator: item.creator.email,
        isApprove:
          item.isApprove === 0 ? (
            <Badge color="danger">Ditolak</Badge>
          ) : (
            <Badge color="primary">Disetujui</Badge>
          ),
        reason: item.reason.title,
        description: item.description ? item.description : "Tidak ada",
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalDetail(item.user)}
            disabled={handleDisableBisdev()}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const data = {
    columns: [
      {
        label: "Sudah Menunggu Dalam",
        field: "timer",
        sort: "asc",
      },
      {
        label: "Tanggal",
        field: "updated_at",
        sort: "asc",
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
      },
      {
        label: "Nama Depan",
        field: "first_name",
        sort: "asc",
      },
      {
        label: "Nama Belakang",
        field: "last_name",
        sort: "asc",
      },
      {
        label: "Telepon",
        field: "phone_number",
        sort: "asc",
      },
      {
        label: "Sebagai",
        field: "roles",
        sort: "asc",
      },
      {
        label: "Status",
        field: "kyc_status",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "details",
        sort: "asc",
      },
      {
        label: "Persetujuan",
        field: "action",
        sort: "asc",
      },
    ],
    rows: users.map(item => {
      return {
        ...item,
        roles: item.user_role
          .map(el => {
            // if (el.role.title == "Lender")
            //   return item.lenderAsCompany === 0
            //     ? "Lender (Perorangan)"
            //     : "Lender (Institusi)"
            return el.role.title
          })
          .join(", "),
        updated_at: moment(item.updated_at).format("DD MMM YYYY HH:mm:ss"),
        timer: item.time,
        kyc_status: <Badge color="warning">Menunggu Persetujuan</Badge>,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm btn-rounded"
            onClick={() => toggleModalDetail(item)}
            disabled={handleDisableBisdev()}
          >
            Detail
          </Button>
        ),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm btn-rounded"
            onClick={() => toggleModalApproved(item)}
            disabled={item.kyc_status !== 4 || handleDisableBisdev()}
          >
            Aksi
          </Button>
        ),
      }
    }),
  }

  const userPre = {
    columns: [
      {
        label: "Tanggal",
        field: "updated_at",
        sort: "asc",
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
      },
      {
        label: "Nama",
        field: "full_name",
        sort: "asc",
      },
      {
        label: "Telepon",
        field: "phone_number",
        sort: "asc",
      },
      {
        label: "Status KYC",
        field: "kyc_status",
        sort: "asc",
      },
    ],
    rows: userPreApproved.map(item => {
      return {
        ...item,
        updated_at: moment(item.updated_at).format("DD MMM YYYY HH:mm:ss"),
        full_name: `${item.first_name} ${item.last_name}`,
        kyc_status: (
          <Badge color="info">
            {item.kyc_status === 1 && "Profil"}
            {item.kyc_status === 2 && "Dokumen"}
            {item.kyc_status === 3 && "Rekening"}
          </Badge>
        ),
      }
    }),
  }

  // if(load) return <Loading />
  if (load) {
    return (
      <SkeletonLoad title="KYC" breadcrumbItem="Pengguna">
        <div style={{ fontSize: 24, lineHeight: 1.5 }}>
          <Skeleton height={30} />
          <div className="d-flex justify-content-between mt-4">
            <Skeleton />
            {/* <Skeleton width={300} /> */}
          </div>
          <Skeleton height={350} />
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <ModalDetail
        isOpen={modalDetail}
        toggle={toggleModalDetail}
        user={user}
      />
      <ModalApprove
        isOpen={modalApproved}
        toggle={toggleModalApproved}
        user={uuid}
        getUsers={getUsers}
        reason={reason}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="KYC" breadcrumbItem="Pengguna" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  {/* <h4 className="card-title mb-3">Profil</h4> */}

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Pra Registrasi
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Persetujuan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <MDBDataTable
                        responsive
                        bordered
                        data={data}
                        striped
                        className="mt-3"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <ul
                        className="nav nav-tabs nav-tabs-custom"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: historyTab === "1",
                            })}
                            onClick={() => {
                              toggleTabHistory("1")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("email")
                              setSearch("")
                            }}
                          >
                            Lender
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: historyTab === "2",
                            })}
                            onClick={() => {
                              toggleTabHistory("2")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("email")
                              setSearch("")
                            }}
                          >
                            Borrower
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent activeTab={historyTab} className="">
                        <TabPane tabId="1">
                          <DataTable
                            table={dataHistories}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["email", "phone_number"]}
                            handleGetSearch={getUsers}
                            searching={true}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <DataTable
                            table={dataHistories}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["email", "phone_number"]}
                            handleGetSearch={getUsers}
                            searching={true}
                          />
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="3">
                      <div>
                        <MDBDataTable
                          responsive
                          bordered
                          data={userPre}
                          striped
                          className="mt-3"
                          disableRetreatAfterSorting
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Users)
