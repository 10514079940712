import { SET_LOADING } from "./actionTypes"

const INIT_STATE = {
  loading: false,
}

const Loading = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    
    default:
    return state
  }
}

export default Loading