import React, { useState } from "react"
import Logo from "../Image/Logoethis.png"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap"
import { withRouter } from "react-router-dom"

const Menuhome = props => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Navbar color="light" light expand="md">
        <div className="container">
          <NavbarBrand href="/">
            <span className="base bold">ETHIS INDONESIA</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink className="active" href="#">
                  Beranda
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Tentang Kami
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Profil</DropdownItem>
                  <DropdownItem>Cara Kerja</DropdownItem>
                  <DropdownItem>Pernyataan Resiko</DropdownItem>
                  <DropdownItem>FAQ Ethis Indonesia</DropdownItem>
                  <DropdownItem>Syarat dan Ketentuan</DropdownItem>
                  <DropdownItem>Kebijakan Privasi</DropdownItem>
                  <DropdownItem>Edukasi</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Pembiayaan
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Cara Memberi Pembiayaan</DropdownItem>
                  <DropdownItem>Ajukan Pembiayaan Properti</DropdownItem>
                  <DropdownItem>Ajukan Pembiayaan UKM</DropdownItem>
                  <DropdownItem>Biaya-Biaya</DropdownItem>
                  <DropdownItem>Akad</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <div className="ext-button"></div>
            <button
                className="f-11 btn-menu btn btn-outline-success my-2 my-sm-2"
                type="submit"
                onClick={() => props.history.push("/login")}
              >
                Masuk
              </button>
            <div className="ext-button"></div>
            <button
                className="f-11 btn-menu ml-1  btn btn-outline-success my-2 my-sm-2"
                type="submit"
                onClick={() => props.history.push("/register")}
              >
                Daftar
              </button>
          </Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default withRouter(Menuhome)
