import React, { useState } from "react"
import Skeleton from "react-loading-skeleton"

const ImageLoader = props => {
  const [loaded, setLoaded] = useState(false)
  const handleImageLoaded = () => setLoaded(true)

  const imageStyle = !loaded ? { display: "none" } : {}

  // if(!loaded) return <Skeleton width={props.width || "100%"} height={props.height || "180px"} />

  return (
    <React.Fragment>
      {!loaded && (
        <Skeleton
          width={props.width || "100%"}
          height={props.height || "180px"}
        />
      )}
      <img
        src={props.src}
        className={props.className}
        alt={props.alt}
        height={props.height}
        width={props.width}
        style={{ ...imageStyle, ...props.style }}
        onLoad={handleImageLoaded}
      />
    </React.Fragment>
  )
}

export default ImageLoader
