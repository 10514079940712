import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import {
  getCampaign,
  getCampaignByProject,
  getCollectionByCampaign,
  getListAkadMusyarakah,
  getVaByCampaign,
  getVaByUser,
  getWakalahBilUjrah,
  getAkadAddendum,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import moment from "moment"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

import noImage from "../../assets/images/ethis/no-image.jpg"
import Toaster from "components/Common/Toast"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const DetailProject = props => {
  const token = localStorage.getItem("token")
  const idProject = props.match.params.uuid
  const urlParams = new URLSearchParams(window.location.search)
  const idCampaign = urlParams.get("campaign_id")

  // console.log(idCampaign)

  const [campaign, setCampaign] = useState([])
  const [load, setLoad] = useState(false)
  const [selectProject, setSelectProject] = useState(null)
  const [akad, setAkad] = useState([])
  const [akadAddendum, setAkadAddendum] = useState([])
  const [activeTab, setactiveTab] = useState("1")
  const [collection, setCollection] = useState([])
  const [numberVa, setNumberVa] = useState([])

  const [perPage, setPerPage] = useState(6)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)

  const [perPage2, setPerPage2] = useState(6)
  const [page2, setPage2] = useState(1)
  const [totalPage2, setTotalPage2] = useState(0)
  const [currentPage2, setCurrentPage2] = useState(null)

  const [modalAkad, setModalAkad] = useState(false)
  const [urlDocument, setUrlDocument] = useState("")
  const [akadName, setAkadName] = useState("")

  const [wakalahBilUjrah, setWakalahBilUjrah] = useState(null)

  const [cmpgn, setCmpgn] = useState(null)

  const toggleAkad = () => setModalAkad(!modalAkad)

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  useEffect(() => {
    if (idCampaign) {
      getCampaignByUuid(idCampaign)
      setSelectProject(idCampaign)
    } else {
      getData()
    }
  }, [])

  useEffect(() => {
    getDataContent()
  }, [selectProject, page])

  const getCampaignByUuid = async id => {
    setLoad(true)
    const data = await getCampaign(id, token)
    setCmpgn(data.data)
    setCampaign([data.data])
    setLoad(false)
  }

  const getData = async () => {
    setLoad(true)
    const campaign = await getCampaignByProject(idProject, token)
    setCampaign(campaign.data)
    setLoad(false)
  }

  const getDataContent = async () => {
    let query = `?per_page=${perPage}&&page=${page}`
    let query2 = `?per_page=${perPage2}&&page=${page2}`
    if (selectProject) {
      setLoad(true)
      try {
        const data = await getListAkadMusyarakah(selectProject, token, query)
        const coll = await getCollectionByCampaign(selectProject, token)
        const va = await getVaByCampaign(selectProject, token)
        const wakUjrah = await getWakalahBilUjrah(selectProject, token)
        // const addendumData = await getAkadAddendum(selectProject, query2, token)
        // setAkadAddendum(addendumData.data)
        // setTotalPage2(addendumData.meta.pagination.last_page)
        // setCurrentPage2(addendumData.meta.pagination.current_page)
        setAkad(data.data)
        setTotalPage(data.meta.pagination.last_page)
        setCurrentPage(data.meta.pagination.current_page)
        setNumberVa(va.data)
        setCollection(coll.data)
        setWakalahBilUjrah(wakUjrah.data)
        setLoad(false)
      } catch (error) {
        setLoad(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    } else {
      setAkad([])
      setWakalahBilUjrah(null)
    }
  }

  const handleCard = arr => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].uuid === selectProject) {
        return arr[i]
      }
    }
    return null
  }

  const handleStatusTrx = status => {
    if (status === 1) return <Badge color="danger">Belum Dibayar</Badge>
    if (status === 3) return <Badge color="primary">Sudah Dibayar</Badge>
    if (status === 9) return <Badge color="info">Kadaluarsa</Badge>
  }

  const renderPaginate = numb => {
    let maxPages = 9
    let current_page = currentPage

    // ensure current page isn't out of range
    if (current_page < 1) {
      current_page = 1
    } else if (current_page > totalPage) {
      current_page = totalPage
    }

    let startPage
    let endPage

    if (totalPage <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1
      endPage = totalPage
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1

      if (current_page <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1
        endPage = maxPages
      } else if (current_page + maxPagesAfterCurrentPage >= totalPage) {
        // current page near the end
        startPage = totalPage - maxPages + 1
        endPage = totalPage
      } else {
        // current page somewhere in the middle
        startPage = current_page - maxPagesBeforeCurrentPage
        endPage = current_page + maxPagesAfterCurrentPage
      }
    }

    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    )

    let result = []
    for (let i = 0; i <= pages.length - 1; i++) {
      result.push(
        <PaginationItem active={pages[i] === current_page}>
          <PaginationLink onClick={() => setPage(pages[i])}>
            {pages[i]}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const renderPaginate2 = numb => {
    let maxPages = 9
    let current_page = currentPage2

    // ensure current page isn't out of range
    if (current_page < 1) {
      current_page = 1
    } else if (current_page > totalPage2) {
      current_page = totalPage2
    }

    let startPage
    let endPage

    if (totalPage2 <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1
      endPage = totalPage2
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1

      if (current_page <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1
        endPage = maxPages
      } else if (current_page + maxPagesAfterCurrentPage >= totalPage) {
        // current page near the end
        startPage = totalPage - maxPages + 1
        endPage = totalPage
      } else {
        // current page somewhere in the middle
        startPage = current_page - maxPagesBeforeCurrentPage
        endPage = current_page + maxPagesAfterCurrentPage
      }
    }

    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    )

    let result = []
    for (let i = 0; i <= pages.length - 1; i++) {
      result.push(
        <PaginationItem active={pages[i] === current_page}>
          <PaginationLink onClick={() => setPage(pages[i])}>
            {pages[i]}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const renderSisaWaktu = date => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    let countDay = eventdate.diff(todaysdate, "days")
    if (countDay < 0) {
      return "Kampanye Berakhir"
    }
    return `${countDay} Hari Lagi`
  }

  const renderModalAkad = () => {
    return (
      <Modal
        isOpen={modalAkad}
        // toggle={toggleAkad}
        scrollable={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Dokumen Akad {akadName}</h5>
          <button
            type="button"
            onClick={toggleAkad}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe
              title={akadName}
              className="embed-responsive-item"
              src={urlDocument}
            />
          </div>
        </div>
      </Modal>
    )
  }

  const statusTtdUjrah = () => {
    if (wakalahBilUjrah.status == 2) return "Sudah TTD"
    return "Belum TTD"
  }

  const handleWakalahBilUjrah = () => {
    setUrlDocument(wakalahBilUjrah.url_document)
    setAkadName("Wakalah Bil Ujrah")
    toggleAkad()
  }

  const renderCard = item => {
    if (!item) return null
    return (
      <>
        <div className="col-lg-4">
          <div className="card rounded">
            <img
              draggable="false"
              src={item.image ? `${API_URL}${item.image}` : noImage}
              className="rounded img-fluid"
              cla
              height="180px"
              width="100%"
            />
            <div className="f-11 card-body p-2">
              <div className="project-head">
                <Row>
                  <Col xs="12">
                    <p>
                      <b>{item.title}</b>
                    </p>
                    {/* Status Kampanye:{' '}
                  {item.status === 1 && <h6><Badge color="danger">Tidak Aktif</Badge></h6>}
                  {item.status === 2 && <h6><Badge color="danger">Tidak Aktif</Badge></h6>}
                  {item.status === 3 && <h6><Badge color="danger">Tidak Aktif</Badge></h6>}
                  {item.status === 4 && <h6><Badge color="success">Aktif</Badge></h6>}
                  {item.status === 5 && <h6><Badge color="danger">Tidak Aktif</Badge></h6>} */}
                  </Col>
                </Row>
              </div>
              <hr className="mb-3 mt-2"></hr>
              <div className="project-body">
                <div className="project-content">
                  <Row>
                    <Col>
                      <p>Target</p>
                      <p className="bold">
                        Rp{" "}
                        {item.target
                          ? Number(item.target).toLocaleString()
                          : "-"}
                      </p>
                    </Col>
                    <Col>
                      <p>Terkumpul</p>
                      <p className="bold">
                        Rp {Number(item.collected).toLocaleString()}
                      </p>
                    </Col>
                  </Row>
                  <div class="separator"></div>
                  <Row>
                    <Col>
                      <p>Proyeksi Imbal Hasil</p>
                      <p className="bold">
                        {item.nisbah ? item.nisbah : "-"} %
                      </p>
                    </Col>
                    <Col>
                      <p>Tenor Proyek</p>
                      <p className="bold">
                        {item.tenor ? item.tenor : "-"} Bulan
                      </p>
                    </Col>
                  </Row>
                  <div className="separator"></div>
                  <Row>
                    <Col>
                      <p>Tanggal Berakhir</p>
                      <p className="bold">
                        {item.end_date_campaign
                          ? moment(item.end_date_campaign).format("DD MMM YYYY")
                          : "-"}
                      </p>
                    </Col>
                    <Col>
                      <p>Sisa Waktu</p>
                      <p className="bold">
                        {item.end_date_campaign
                          ? renderSisaWaktu(item.end_date_campaign)
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <hr className="mb-2 mt-2"></hr>
                  {wakalahBilUjrah ? (
                    <Row className="mt-2 text-center">
                      <Col>
                        <div className="mb-2">
                          <b>TTD Akad Digital Privy</b>
                        </div>
                        <Button
                          className="w-100"
                          color="primary"
                          onClick={handleWakalahBilUjrah}
                        >
                          Wakalah Bil Ujrah
                        </Button>
                        <div className=" mt-1 text-justify">
                          Status: <b>{statusTtdUjrah()} Akad</b>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="mt-2 text-center">
                      <Col>
                        <div className="mb-2">
                          <b>TTD Akad Digital Privy</b>
                        </div>
                        <Button
                          className="w-100"
                          color="primary"
                          disabled
                          // onClick={handleWakalahBilUjrah}
                        >
                          Wakalah Bil Ujrah
                        </Button>
                        <div className=" mt-1 text-justify">
                          Status: <b>Belum Dikirimkan Akad</b>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderListAkad(akad, item)}
      </>
    )
  }

  const renderListAkad = (data, item) => {
    // if(data.length === 0) return null
    return (
      <Col lg="8">
        <Card>
          <CardBody>
            <Nav pills className="navtab-bg nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1")
                  }}
                >
                  Pemberi Pembiayaan
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    toggle("4")
                  }}
                >
                  Addendum
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2")
                  }}
                >
                  Pencairan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggle("3")
                  }}
                >
                  Tagihan
                </NavLink>
              </NavItem>
            </Nav>
          </CardBody>
        </Card>
        <Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="p-3">
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Nomor Akad Musyarakah</th>
                    <th scope="col">TTD Akad Musyarakah</th>
                    <th scope="col">Status TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map(item => {
                      return (
                        <tr>
                          <td>
                            {item.user.first_name} {item.user.last_name}
                          </td>
                          <td>{item.number}</td>
                          <td>
                            <Button
                              className="btn-sm w-75"
                              color="primary"
                              // onClick={() => window.open(item.url_document, "_blank")}
                              onClick={() => {
                                setUrlDocument(item.url_document)
                                setAkadName("Musyarakah")
                                toggleAkad()
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </td>
                          <td>
                            {item.borrower_status == 1 ? (
                              <Badge color="danger">Belum TTD</Badge>
                            ) : (
                              <Badge color="primary">Sudah TTD</Badge>
                            )}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan="3">Belum Ada Pemberi Pembiayaan</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data.length > 0 && (
                <div className="font-weight-bold mb-2">
                  Note: Status akan berubah dalam 1 menit setelah anda
                  menandatangani akad.
                </div>
              )}
              {data.length > 0 && (
                <Row className="py-2">
                  <Col lg={12} className="d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink first onClick={() => setPage(1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink
                          previous
                          onClick={() => setPage(page - 1)}
                        />
                      </PaginationItem>
                      {renderPaginate(totalPage)}
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink
                          next
                          onClick={() => setPage(page + 1)}
                        />
                      </PaginationItem>
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink
                          last
                          onClick={() => setPage(totalPage)}
                        />
                      </PaginationItem>
                    </Pagination>
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="4" className="p-3">
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Nomor Akad Addendum</th>
                    <th scope="col">TTD Akad Addendum</th>
                    <th scope="col">Status TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {akadAddendum.length > 0 ? (
                    akadAddendum.map(item => {
                      return (
                        <tr>
                          <td>
                            {item.user_privy?.user?.first_name}{" "}
                            {item.user_privy?.user?.last_name}
                          </td>
                          <td>{item.number}</td>
                          <td>
                            <Button
                              className="btn-sm w-75"
                              color="primary"
                              // onClick={() => window.open(item.url_document, "_blank")}
                              onClick={() => {
                                setUrlDocument(item.url_document)
                                setAkadName("Addendum")
                                toggleAkad()
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </td>
                          <td>
                            {item.borrower_status == 1 ? (
                              <Badge color="danger">Belum TTD</Badge>
                            ) : (
                              <Badge color="primary">Sudah TTD</Badge>
                            )}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan="3">Tidak Ada Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {akadAddendum.length > 0 && (
                <div className="font-weight-bold mb-2">
                  Note: Status akan berubah dalam 1 menit setelah anda
                  menandatangani akad.
                </div>
              )}
              {akadAddendum.length > 0 && (
                <Row className="py-2">
                  <Col lg={12} className="d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example">
                      <PaginationItem disabled={page2 === 1}>
                        <PaginationLink first onClick={() => setPage2(1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page2 === 1}>
                        <PaginationLink
                          previous
                          onClick={() => setPage(page2 - 1)}
                        />
                      </PaginationItem>
                      {renderPaginate2(totalPage2)}
                      <PaginationItem disabled={page2 === totalPage2}>
                        <PaginationLink
                          next
                          onClick={() => setPage(page2 + 1)}
                        />
                      </PaginationItem>
                      <PaginationItem disabled={page2 === totalPage2}>
                        <PaginationLink
                          last
                          onClick={() => setPage(totalPage2)}
                        />
                      </PaginationItem>
                    </Pagination>
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <div class="table-wrapper">
                <table class="table table-striped border">
                  <thead>
                    <tr className="full-width-tbl">
                      <th scope="col">Tanggal</th>
                      <th scope="col">Deskripsi</th>
                      <th scope="col">Jumlah</th>
                      <th scope="col">Bukti Pencairan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.campaign_disbursement.map(item => {
                      return (
                        <tr className="full-width-tbl">
                          <td>{moment(item.date).format("DD MMM YYYY")}</td>
                          <td>{item.description}</td>
                          <td>Rp {parseInt(item.amount).toLocaleString()}</td>
                          <td>
                            <Button
                              type="button"
                              color="primary"
                              className="btn-sm"
                              onClick={() =>
                                window.open(API_URL + item.file, "_blank")
                              }
                            >
                              <i className="fas fa-eye"></i>
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <thead>
                    <tr className="full-width-tbl">
                      <th colSpan="2" scope="col">
                        Total Pencairan
                      </th>
                      <th colSpan="2" scope="col">
                        Rp{" "}
                        {parseInt(
                          item.totalAmountDisbursement
                        ).toLocaleString()}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <div class="table-wrapper">
                <table class="table table-striped border">
                  <thead>
                    <tr className="full-width-tbl">
                      <th scope="col">Id Transaksi</th>
                      <th scope="col">Jumlah</th>
                      <th scope="col">Deskripsi</th>
                      <th scope="col">Virtual Account</th>
                      <th scope="col">Status</th>
                      <th scope="col">Tanggal Kadaluarsa</th>
                      <th scope="col">Tanggal Bayar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {numberVa.length > 0 ? (
                      numberVa.map(item => {
                        return (
                          <tr className="full-width-tbl">
                            <td>{item.trx_id}</td>
                            <td>Rp {parseInt(item.amount).toLocaleString()}</td>
                            <td>{item.description}</td>
                            <td>{item.va_number}</td>
                            <td>{handleStatusTrx(item.status)}</td>
                            <td>
                              {moment(item.created_at)
                                .add(3, "days")
                                .format("DD MMM YYYY | HH:mm")}
                            </td>
                            <td>
                              {item.datetime_payment
                                ? moment(item.datetime_payment).format(
                                    "DD MMM YYYY"
                                  )
                                : "-"}
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan="6">Belum Ada Tagihan</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    )
  }

  // console.log(!wakalahBilUjrah)

  if (load) {
    return (
      <SkeletonLoad title="" breadcrumbItem="">
        <div style={{ fontSize: 24, lineHeight: 2, marginTop: -56 }}>
          <Skeleton height={100} />
          <Row>
            <Col lg="4">
              <Skeleton height={400} />
            </Col>
            <Col lg="8">
              <Skeleton height={50} />
              <Skeleton height={150} />
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      {renderModalAkad()}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pb-2">
                  <CardTitle>Pilih Kampanye</CardTitle>
                  {/* <CardText className="text-primary">Silahkan lakukan pembayaran melalui virtual account</CardText> */}
                  <FormGroup className="w-50">
                    {/* <Label for="exampleSelect">Select</Label> */}
                    <Input
                      type="select"
                      name="select"
                      onChange={e => {
                        setSelectProject(e.target.value)
                      }}
                      value={selectProject || ""}
                    >
                      <option value="">Pilih</option>
                      {campaign.map(item => {
                        return <option value={item.uuid}>{item.title}</option>
                      })}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>

            {renderCard(handleCard(campaign))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DetailProject
