import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Media,
  UncontrolledTooltip,
  Row,
  Table,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import moment from "moment"
import { API_URL } from "helpers/api_url"

const ListTransaction = ({ transactions, history }) => {
  const [refund, setRefund] = useState([])
  const [modalRefund, setModalRefund] = useState(false)

  const toggleModalRefund = (item = []) => {
    setRefund(item)
    setModalRefund(!modalRefund)
  }

  const handleStatusTrx = status => {
    if (status === 1) return <Badge color="danger">Belum Dibayar</Badge>
    if (status === 2) return <Badge color="danger">Belum Dibayar</Badge>
    if (status === 3) return <Badge color="warning">Pembayaran Bertahap</Badge>
    if (status === 4) return <Badge color="primary">Sudah Dibayar</Badge>
    if (status === 5) return <Badge color="info">Proses Pengembalian</Badge>
    if (status === 6)
      return <Badge color="primary">Selesai Pengembalian Pokok</Badge>
    if (status === 7)
      return (
        <Badge color="primary">Selesai Pengembalian Pokok dan Nisbah</Badge>
      )
    if (status === 9) return <Badge color="info">Kadaluarsa</Badge>
    if (status === 10) return <Badge color="secondary">Dibatalkan</Badge>
  }

  const handleStatusTrxPayout = status => {
    if (status === 1) return "-"
    if (status === 2) return "-"
    if (status === 3) return "Proses"
    if (status === 4) return "Proses Pengembalian"
    if (status === 5) return "Selesai Pengembalian Pokok"
    if (status === 6) return "Selesai Pengembalian"
    if (status === 7) return "-"
    if (status === 8) return "-"
    if (status === 9) return "-"
  }

  const handleTTDMusyarakah = data => {
    if (data.privy_document_musyarakah.length === 0)
      return <Badge color="danger">Belum TTD</Badge>
    if (data.privy_document_musyarakah[0].status === 1) {
      return <Badge color="danger">Belum TTD</Badge>
    }
    return <Badge color="primary">Sudah TTD</Badge>
  }
  const handleTTDWakalah = data => {
    if (data.privy_document_wakalah.length === 0)
      return <Badge color="danger">Belum TTD</Badge>
    if (data.privy_document_wakalah[0].status === 1) {
      return <Badge color="danger">Belum TTD</Badge>
    }
    return <Badge color="primary">Sudah TTD</Badge>
  }

  const renderModalRefund = () => {
    return (
      <Modal isOpen={modalRefund} role="dialog" autoFocus={true} size="lg">
        <ModalHeader toggle={() => toggleModalRefund([])}>
          Pembatalan Transaksi
        </ModalHeader>
        <ModalBody>
          <table class="table table-striped border">
            <thead>
              <tr>
                {/* <th scope="col">#</th> */}
                <th scope="col">Tanggal Pembatalan</th>
                <th scope="col">Deskripsi</th>
                <th scope="col">Bukti Pembatalan</th>
              </tr>
            </thead>
            <tbody>
              {refund.map(item => {
                return (
                  <tr>
                    <td>{moment(item.date).format("DD MMM YYYY")}</td>
                    <td>{item.description}</td>
                    <td>
                      <Button
                        className="btn-sm"
                        color="secondary"
                        onClick={() =>
                          window.open(`${API_URL}${item.file}`, "_blank")
                        }
                      >
                        <i class="fas fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      {renderModalRefund()}
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="">
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap table-centered table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">ID Transaksi</th>
                      {/* <th scope="col">Nama Proyek</th> */}
                      <th scope="col">Nominal</th>
                      <th scope="col">Status Transaksi</th>
                      {/* <th scope="col">Status Pengembalian</th> */}
                      {/* <th scope="col">Wakalah</th>
                      <th scope="col">Musyarakah</th> */}
                      <th scope="col">Virtual Account</th>
                      <th scope="col">Tanggal Berlaku VA</th>
                      <th scope="col">Akad & Perkembangan Proyek</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{item.trx_id}</td>
                          <td>Rp {Number(item.amount).toLocaleString()}</td>

                          <td>
                            {handleStatusTrx(item.status)}
                            {/* {item.status === 10 &&
                              <Button
                                className="btn-sm"
                                color="info"
                                onClick={() => toggleModalRefund(item.transaction_refund)}
                              >
                                Detail
                              </Button>
                            } */}
                          </td>
                          {/* <td>
                            {handleStatusTrxPayout(item.status)}
                          </td> */}
                          {/* <td>{handleTTDWakalah(item)}</td>
                          <td>{handleTTDMusyarakah(item)}</td> */}
                          <td>{item.va_number || "-"}</td>
                          <td>
                            {item.va_number ? (
                              <>
                                {moment(item.created_at)
                                  .add(1, "days")
                                  .format("DD MMM YYYY HH:mm")}{" "}
                                WIB
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="text-center">
                            {item.status === 10 ? (
                              <Button
                                className="btn-sm w-100"
                                color="primary"
                                onClick={() =>
                                  toggleModalRefund(item.transaction_refund)
                                }
                              >
                                Detail
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                color="primary"
                                className={`btn-sm w-100 ${
                                  item.status === 1 ||
                                  item.status === 9 ||
                                  item.status === 2 ||
                                  item.status === 10
                                    ? "bg-secondary"
                                    : ""
                                }`}
                                onClick={() =>
                                  history.push(`/portfolio/${item.uuid}`)
                                }
                                disabled={
                                  item.status === 1 ||
                                  item.status === 9 ||
                                  item.status === 2 ||
                                  item.status === 10
                                    ? true
                                    : false
                                }
                                style={{
                                  cursor: `${
                                    item.status === 1 ||
                                    item.status === 9 ||
                                    item.status === 2 ||
                                    item.status === 10
                                      ? "not-allowed"
                                      : "pointer"
                                  }`,
                                }}
                              >
                                Lihat
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                    {/* {map(projects, (project, index) => (
                      <tr key={index}>
                        <td>
                          {project.trx_id}
                        </td>
                        <td>
                          <b>
                            {project.campaign.title}
                          </b>
                        </td>
                        <td>{project.campaign.tenor} Bulan</td>
                        <td>
                         {project.campaign.nisbah} %
                        </td>
                        <td>Rp {Number(project.amount).toLocaleString()}</td>
                        <td>
                          {project.va_number || "-"}
                        </td>
                        <td>
                          {project.va_number ? <>{moment(project.created_at).add(1, 'days').format("DD MMM YYYY HH:mm")} WIB</> : "-"}
                        </td>
                        <td>
                          {handleStatusTrx(project.status)}
                        </td>
                        
                        <td className="text-center">
                          <Button
                            type="button"
                            color="primary"
                            className={`btn-sm w-100 ${project.status === 1 || project.status === 9 ? "bg-secondary": ""}`}
                            onClick={() => history.push(`/portfolio/${project.uuid}`)}
                            disabled={project.status === 1 || project.status === 9}
                            style={{cursor: `${project.status === 1 || project.status === 9 ? "not-allowed" : "pointer"}`}}
                          >
                            Lihat
                          </Button> 
                        </td>
                      </tr>
                    ))} */}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

ListTransaction.propTypes = {
  transactions: PropTypes.array,
}

export default withRouter(ListTransaction)
