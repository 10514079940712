import React from 'react';
import { Link } from 'react-router-dom';

// import { API_URL_1 } from '../../../supports/api-url/apiurl';

const FullTime = [
  {
    item: 'Tentang Kami',
    slug: '/tentang-kami',
  },
  {
    item: 'Cara Kerja',
    slug: '/how-it-works',
  },
  {
    item: 'GCG',
    slug: '/good-corporate-governance',
  },
  {
    item: 'Laporan Audit Keuangan',
    slug: '/laporan-keuangan',
  },
];

const AfterHour = [
  {
    item: 'Webinar',
    slug: '/webinar',
  },
  {
    item: 'Ebook',
    slug: '/ebook',
  },
  {
    item: 'Blog',
    slug: '/blogs',
  },
  {
    item: 'Hak Cipta',
    slug: '/haki',
  },
];

const PartTime = [
  {
    item: 'Pernyataan Resiko',
    slug: '/mitigasi-resiko',
  },
  {
    item: 'FAQ Ethis Indonesia',
    slug: '/faq',
  },
  {
    item: 'Syarat & Ketentuan',
    slug: '/syarat-dan-ketentuan',
  },
  {
    item: 'Kebijakan Privasi',
    slug: '/ethis-kebijakan-privasi',
  },
  {
    item: 'Layanan Pengaduan',
    slug: '/layanan-pengaduan',
  },
];

export default ({ handleNonActiveDropdown }) => {
  return (
    <>
      <div className="row p-0 m-0 contentNav">
        <div className="col p-4 m-0">
          <div className="titleNav">PROFIL</div>
          <div className="subNavBox">
            {FullTime.map(item => {
              return (
                <Link
                  to={item.slug}
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="subtitleNav">{item.item}</div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="col p-4 border-left border-right">
          <div className="titleNav">FAQ ETHIS INDONESIA</div>
          <div className="subNavBox">
            {PartTime.map(item => {
              return (
                <Link
                  to={item.slug}
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="subtitleNav">{item.item}</div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="col p-4 m-0">
          <div className="titleNav">EDUKASI</div>
          <div className="subNavBox">
            {AfterHour.map(item => {
              return (
                <Link
                  to={item.slug}
                  style={{ textDecoration: 'none' }}
                  onClick={handleNonActiveDropdown}
                >
                  <div className="subtitleNav">{item.item}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className="row p-0 m-0 backgroundNavPrograms">
        <div className="col pwdk-pl-6 pwdk-pt-3 pwdk-pr-7 pwdk-pb-4">
          <div className="titleNav">EVENT, WORKSHOP, & BOOTCAMP</div>
          <div className="subNavBox">
            <Link
              to="/courses"
              style={{ textDecoration: 'none' }}
              onClick={handleNonActiveDropdown}
            >
              <div className="subtitleNav">Event, Workshop, & Bootcamp</div>
            </Link>
          </div>
        </div>
        <div className="col pwdk-pl-6 pwdk-pt-3 pwdk-pr-7 pwdk-pb-4">
          <div className="titleNav">KIDS & TEENS</div>
          <div className="subNavBox">
            <Link
              to="/codestarter"
              style={{ textDecoration: 'none' }}
              onClick={handleNonActiveDropdown}
            >
              <div className="subtitleNav">Coding Classes for Kids & Teens</div>
            </Link>
          </div>
        </div>
        <div className="col pwdk-pl-6 pwdk-pt-3 pwdk-pr-7 pwdk-pb-4">
          <div className="titleNav">CORPORATE</div>
          <div className="subNavBox">
            <Link
              to="/corporate"
              style={{ textDecoration: 'none' }}
              onClick={handleNonActiveDropdown}
            >
              <div className="subtitleNav">Corporate Training</div>
            </Link>
          </div>
        </div>
      </div> */}
    </>
  );
};
