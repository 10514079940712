import Toaster from "components/Common/Toast"
import { getCampaignActive, getDashboardUser } from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  Card,
  CardBody,
  Media,
  Tooltip,
} from "reactstrap"
import { Progress } from "reactstrap"
import Welcome from "./Welcome"
import moment from "moment"
import { API_URL } from "helpers/api_url"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"
import Blog from "./Blog"

const User = props => {
  const token = localStorage.getItem("token")
  const [dataUser, setDataUser] = useState(null)
  const [campaign, setCampaign] = useState([])
  const [load, setLoad] = useState(false)
  const [ttop, setttop] = useState(false)
  const [ttop2, setttop2] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let query = `?per_page=3`
    setLoad(true)
    try {
      const userDashboard = await getDashboardUser(token)
      const data = await getCampaignActive(query)
      setCampaign(data.data)
      setDataUser(userDashboard.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
    }
  }

  const renderSisaWaktu = (date, status) => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    let countDay = eventdate.diff(todaysdate, "days")
    if (countDay < 0) {
      return "Kampanye Berakhir"
    }
    if (status === 6) return "Target Terkumpul"
    return `${countDay} Hari Lagi`
  }

  const renderCampaign = () => {
    if (campaign.length === 0) {
      return null
    }
    return campaign.map(item => {
      return (
        <div className="mb-2 col-lg-4">
          <div className="card">
            <img
              draggable="false"
              src={API_URL + item.image}
              className="img-fluid"
              height="180px"
              width="100%"
            />
            <div className="f-11 project-card-body">
              <div className="project-head">
                <Row>
                  <Col xs="12">
                    <p>
                      <b>{item.title}</b>
                    </p>
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col>
                        <p>
                          <i className="fa fa-map-marker-alt"></i>{" "}
                          {item.province.name}
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <i className="fa fa-handshake"></i>{" "}
                          {item.akad_type.title}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <hr className=""></hr>
              <div className="project-body">
                <div className="project-content">
                  <Row className="mb-3">
                    <Col xs="12">
                      <Progress className="height-base" multi>
                        <Progress
                          animated
                          bar
                          color="success"
                          value={
                            (Number(item.collected) / Number(item.target)) * 100
                          }
                        />
                        <Progress
                          animated
                          bar
                          color="secondary"
                          value={
                            (Number(item.pledge) / Number(item.target)) * 100 -
                            (Number(item.collected) / Number(item.target)) * 100
                          }
                        />
                      </Progress>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Badge color="primary">{` `}</Badge> Terkumpul{" "}
                          <b>
                            {(
                              (Number(item.collected) / Number(item.target)) *
                              100
                            ).toFixed(2)}
                            %
                          </b>
                        </Col>
                        <Col md={6}>
                          <Badge color="secondary">{` `}</Badge> Minat{" "}
                          <b>
                            {(
                              (Number(item.pledge) / Number(item.target)) *
                              100
                            ).toFixed(2)}
                            %
                          </b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Target</p>
                      <p className="bold">
                        Rp {Number(item.target).toLocaleString()}
                      </p>
                    </Col>
                    <Col>
                      <p>Terkumpul</p>
                      <p className="bold">
                        Rp {Number(item.collected).toLocaleString()}
                      </p>
                    </Col>
                  </Row>
                  <div class="separator"></div>
                  <Row>
                    <Col>
                      <p>Proyeksi Imbal Hasilh</p>
                      <p className="bold">{item.nisbah} %</p>
                    </Col>
                    <Col>
                      <p>Tenor Proyek</p>
                      <p className="bold">{item.tenor} Bulan</p>
                    </Col>
                  </Row>
                  <div className="separator"></div>
                  <Row>
                    <Col>
                      <p>Tanggal Berakhir</p>
                      <p className="bold">
                        {moment(item.end_date_campaign).format("DD MMM YYYY")}
                      </p>
                    </Col>
                    <Col>
                      <p>Sisa Waktu</p>
                      <p className="bold">
                        {renderSisaWaktu(item.end_date_campaign, item.status)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="project-card-footer">
              <a id="startCampaign" href="">
                <Link to={`/detail-kampanye/${item.uuid}`}>
                  <button className="btn btn-success btn-block campaign-btn">
                    Detail Kampanye
                  </button>
                </Link>
              </a>
            </div>
          </div>
        </div>
      )
    })
  }

  if (!dataUser) {
    return (
      <SkeletonLoad title="Beranda" breadcrumbItem="Beranda">
        <div style={{ lineHeight: 3 }}>
          <Skeleton height={60} />
          <Row className="mt-2 mb-3">
            {[1, 2, 3, 4, 5, 6].map(item => {
              return (
                <Col md="4">
                  <Skeleton height={80} />
                </Col>
              )
            })}
          </Row>
          <Skeleton />
          <Row className="mt-2">
            {[1, 2, 3].map(item => {
              return (
                <Col md="4">
                  <Skeleton height={500} />
                </Col>
              )
            })}
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Beranda" breadcrumbItem="Beranda" />
          <Row>
            <Col xl={12}>
              <Welcome user={props.user} />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Link to="/portfolio?page=1&perPage=5">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Pembiayaan
                        </p>
                        <h4 className="mb-0">
                          Rp{" "}
                          {parseInt(dataUser.totalInvestment).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-save font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/portfolio?page=1&perPage=5">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Dibiayai
                        </p>
                        <h4 className="mb-0">
                          {parseInt(
                            dataUser.totalInvestCampaign
                          ).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-copy-alt font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            {/* <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Berjalan
                        </p>
                        <div style={{cursor: 'pointer'}}>
                          <Tooltip
                            placement="top"
                            isOpen={ttop}
                            target="TooltipTop"
                            toggle={() => {
                              setttop(!ttop)
                            }}
                          >
                            Sedang Proses Pengembalian
                          </Tooltip>
                          <i className="bx bxs-calendar-heart bx-xs ml-1" id="TooltipTop"></i>
                        </div>
                      </div>
                      <h4 className="mb-0">{parseInt(dataUser.totalInvestmentActive).toLocaleString()}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i
                          className={
                            "bx bx-calendar-minus font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Selesai
                        </p>
                        <div style={{cursor: 'pointer'}}>
                          <Tooltip
                            placement="top"
                            isOpen={ttop2}
                            target="TooltipTop2"
                            toggle={() => {
                              setttop2(!ttop2)
                            }}
                          >
                            Selesai Pengembalian
                          </Tooltip>
                          <i className="bx bxs-calendar-heart bx-xs ml-1" id="TooltipTop2"></i>
                        </div>
                      </div>
                      <h4 className="mb-0">{parseInt(dataUser.totalFinishCampaign).toLocaleString()}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i
                          className={
                            "bx bx-calendar-check font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="4">
              <Link to="/portfolio?page=1&perPage=5">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Pengembalian
                        </p>
                        <h4 className="mb-0">
                          Rp {parseInt(dataUser.totalPayout).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-money font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/proyeks">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Pengajuan Proyek
                        </p>
                        <h4 className="mb-0">
                          {parseInt(dataUser.totalProject).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-briefcase-alt font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/proyeks">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Proyek
                        </p>
                        <h4 className="mb-0">
                          {parseInt(dataUser.totalCampaign).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-store font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
          <hr
            className="hr-text"
            style={{ marginTop: "16px" }}
            data-content="Kampanye Terbaru"
          />
          <Row className="mt-5">{renderCampaign()}</Row>
          <div className="text-center mb-5">
            <Link to="/daftar-kampanye">
              <Button className="btn-rounded" color="primary">
                Lihat Daftar Kampanye
              </Button>
            </Link>
          </div>
          {/* <Blog /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default User
