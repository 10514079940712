import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, Redirect } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/brands-ethis/logo.png"
import { postCreatePassword, postForgetPassword } from 'helpers/backend_helpers'
import Toaster from 'components/Common/Toast'

const CreatePassword = props => {

  const queryParams = new URLSearchParams(props.location.search)
  const token = queryParams.get('token')
  const [success, setSuccess] = useState(false)

  async function handleValidSubmit(event, values) {
    const data = await postCreatePassword({password: values.password}, token)

    if(data.success) {
      Toaster("success", "Ubah Password", `Berhasil ubah password`)
      setSuccess(true)
    } else {
      Toaster("error", "Ubah Password", `${data.message}`)
    }
  }

  if(!token) {
    return <Redirect to="/not-found" />
  }

  if(success) {
    return <Redirect to="/login" />
  }


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"/>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Password Baru</h5>
                        <p>Silahkan Masukkan Password baru anda</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          placeholder="Password"
                          validate={{
                            required: {value: true, errorMessage: 'Password harus diisi'},
                            minLength: {value: 8, errorMessage: 'Panjang karakter minimal 8'},
                            maxLength: {value: 16, errorMessage: 'Panjang karakter maksimal 16'},
                            pattern: {value: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$', errorMessage: 'Password harus terdiri dari huruf besar, huruf kecil, dan angka'}
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="confirmation_password"
                          label="Konfirmasi Password"
                          type="password"
                          placeholder="Konfirmasi password"
                          validate={{
                            required: {value: true, errorMessage: 'Konfirmasi password harus diisi'},
                            match: {value: 'password', errorMessage: 'Konfirmasi password tidak sama'},
                          }}
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Ubah Password
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Kembali{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Masuk
                  </Link>{" "}
                </p>
                <div className="text-muted">
                  {new Date().getFullYear()} © PT Ethis Fintek Indonesia{" "}
                  {/* <i className="mdi mdi-heart text-danger"/> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  CreatePassword
)
