import Toaster from "components/Common/Toast"
import { API_URL } from "helpers/api_url"
import { getCampaignActive } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Badge,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { Progress } from "reactstrap"
import moment from "moment"
import Breadcrumb from "components/Common/Breadcrumb"
import Loading from "pages/Utility/Loading"
import PuffLoader from "react-spinners/PuffLoader"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"
import InfiniteScroll from "react-infinite-scroll-component"
import ImageLoader from "components/ImageLoader"
import { handleStatusCampaign } from "helpers/method_helpers"

const CampaignClient = props => {
  const [campaign, setCampaign] = useState([])
  const [load, setLoad] = useState(false)

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [search, setSearch] = useState("")
  const [loadSearch, setLoadSearch] = useState(false)

  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    getCampaignInitial()
  }, [])

  useEffect(() => {
    getCampaignsSearch()
  }, [search])

  const getDataCampaign = async () => {
    if (page >= totalPage) {
      setHasMore(false)
      return
    }

    let query = `?per_page=${6}&page=${page + 1}&search=${search}`
    const data = await getCampaignActive(query)
    setPage(data.meta.pagination.current_page)
    setTotalPage(data.meta.pagination.last_page)
    setCampaign([...campaign, ...data.data])
  }

  const getCampaignInitial = async () => {
    let query = `?per_page=${6}&page=${page}`
    setLoad(true)
    try {
      const data = await getCampaignActive(query)
      setCampaign(data.data)
      setPage(data.meta.pagination.current_page)
      setTotalPage(data.meta.pagination.last_page)
      setLoad(false)
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
      setLoad(false)
    }
  }

  const getCampaignsSearch = async () => {
    let query = `?per_page=${6}&page=${1}&search=${search}`
    setLoadSearch(true)
    try {
      const data = await getCampaignActive(query)
      setCampaign(data.data)
      setTotalPage(data.meta.pagination.last_page)
      setPage(data.meta.pagination.current_page)
      setLoadSearch(false)
      setHasMore(true)
    } catch (error) {
      setLoadSearch(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  // useEffect(() => {
  //   getCampaign()
  // }, [page])

  // useEffect(() => {
  //   getCampaignsSearch()
  // }, [search])

  // const getCampaign = async () => {
  //   let query = ""
  //   query = `?per_page=${perPage}&&page=${page}`
  //   setLoad(true)
  //   try {
  //     const data = await getCampaignActive(query)
  //     // onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
  //     window.scrollTo({top: 0, behavior: 'smooth'})
  //     setCampaign(data.data)
  //     setTotalPage(data.meta.pagination.last_page)
  //     setCurrentPage(data.meta.pagination.current_page)
  //     setLoad(false)
  //   } catch (error) {
  //     Toaster("error", "Ups", error.response.data.message)
  //     setLoad(false)
  //   }
  // }

  // const getCampaignsSearch = async () => {
  //   let query = `?per_page=${perPage}&search=${search}`
  //   setLoadSearch(true)
  //   try {
  //     const data = await getCampaignActive(query)
  //     setCampaign(data.data)
  //     setTotalPage(data.meta.pagination.last_page)
  //     setCurrentPage(data.meta.pagination.current_page)

  //     setLoadSearch(false)
  //   } catch (error) {
  //     setLoadSearch(false)
  //     Toaster("error", "Ups", error.response.data.message)
  //   }
  // }

  const renderSisaWaktu = (date, status) => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    let countDay = eventdate.diff(todaysdate, "days")
    if (countDay < 0) {
      return "Kampanye Berakhir"
    }
    if (status === 6) return "Target Terkumpul"
    return `${countDay} Hari Lagi`
  }

  const renderCampaign = () => {
    return campaign.map(item => {
      return (
        <div className="mb-20 col-lg-4">
          <div className="card">
            <div
              style={{
                height: 200,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                position: "relative",
              }}
            >
              <Badge
                color={handleStatusCampaign(
                  "color",
                  Boolean(item.isPaidOff),
                  Boolean(item.isHaveRepayments),
                  item.status
                )}
                size="large"
                style={{ position: "absolute", top: 12, right: 12 }}
              >
                {handleStatusCampaign(
                  "label",
                  Boolean(item.isPaidOff),
                  Boolean(item.isHaveRepayments),
                  item.status
                )}
              </Badge>
              <ImageLoader
                src={API_URL + item.image}
                height="100%"
                width="100%"
                style={{
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                }}
              />
            </div>
            <div className="f-11 project-card-body">
              <div className="project-head">
                <Row>
                  <Col xs="12">
                    <p>
                      <b>{item.title}</b>
                    </p>
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col>
                        <p>
                          <i className="fa fa-map-marker-alt"></i>{" "}
                          {item.province.name}
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <i className="fa fa-handshake"></i>{" "}
                          {item.akad_type.title}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <hr className=""></hr>
              <div className="project-body">
                <div className="project-content">
                  <Row className="mb-3">
                    <Col xs="12">
                      <Progress className="height-base" multi>
                        <Progress
                          animated
                          bar
                          color="success"
                          value={
                            (Number(item.collected) / Number(item.target)) * 100
                          }
                        />
                        <Progress
                          animated
                          bar
                          color="secondary"
                          value={
                            (Number(item.pledge) / Number(item.target)) * 100 -
                            (Number(item.collected) / Number(item.target)) * 100
                          }
                        />
                      </Progress>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Badge color="primary">{` `}</Badge> Terkumpul{" "}
                          <b>
                            {(
                              (Number(item.collected) / Number(item.target)) *
                              100
                            ).toFixed(2)}
                            %
                          </b>
                        </Col>
                        <Col md={6}>
                          <Badge color="secondary">{` `}</Badge> Minat{" "}
                          <b>
                            {(
                              (Number(item.pledge) / Number(item.target)) *
                              100
                            ).toFixed(2)}
                            %
                          </b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Target</p>
                      <p className="bold">
                        Rp {Number(item.target).toLocaleString()}
                      </p>
                    </Col>
                    <Col>
                      <p>Terkumpul</p>
                      <p className="bold">
                        Rp {Number(item.collected).toLocaleString()}
                      </p>
                    </Col>
                  </Row>
                  <div class="separator"></div>
                  <Row>
                    <Col>
                      <p>Proyeksi Imbal Hasil</p>
                      <p className="bold">{item.nisbah} %</p>
                    </Col>
                    <Col>
                      <p>Tenor Proyek</p>
                      <p className="bold">{item.tenor} Bulan</p>
                    </Col>
                  </Row>
                  <div className="separator"></div>
                  <Row>
                    <Col>
                      <p>Tanggal Berakhir</p>
                      <p className="bold">
                        {moment(item.end_date_campaign).format("DD MMM YYYY")}
                      </p>
                    </Col>
                    <Col>
                      <p>Sisa Waktu</p>
                      <p className="bold">
                        {renderSisaWaktu(item.end_date_campaign, item.status)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="project-card-footer">
              <a id="startCampaign" href="">
                <Link to={`/detail-kampanye/${item.uuid}`}>
                  <button className="btn btn-success btn-block campaign-btn">
                    Detail Kampanye
                  </button>
                </Link>
              </a>
            </div>
          </div>
        </div>
      )
    })
  }

  const renderPaginate = numb => {
    let result = []
    for (let i = 1; i <= numb; i++) {
      result.push(
        <PaginationItem active={i === currentPage}>
          <PaginationLink onClick={() => setPage(i)}>{i}</PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const renderLoadScroll = () => {
    return (
      <Row>
        {[1, 2, 3].map(item => {
          return (
            <div className="mb-20 col-lg-4">
              <Skeleton height={420} />
            </div>
          )
        })}
      </Row>
    )
  }

  if (load) {
    return (
      <SkeletonLoad title="Daftar" breadcrumbItem="Kampanye">
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton height={32} width={320} />
          <Row>
            {[1, 2, 3, 4, 5, 6].map(item => {
              return (
                <div className="mb-20 col-lg-4">
                  <Skeleton height={420} />
                </div>
              )
            })}
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Daftar" breadcrumbItem="Kampanye" />
          <div className="d-flex justify-content-start mb-2">
            <div className="app-search pt-0">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control bg-white border"
                  placeholder="Cari..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </div>
          </div>
          {loadSearch ? (
            <Row>
              {[1, 2, 3, 4, 5, 6].map(item => {
                return (
                  <div className="mb-20 col-lg-4">
                    <Skeleton height={420} />
                  </div>
                )
              })}
            </Row>
          ) : (
            <>
              {/* {renderCampaign()} */}
              <InfiniteScroll
                dataLength={campaign.length} //This is important field to render the next data
                next={getDataCampaign}
                hasMore={hasMore && campaign.length > 0}
                loader={
                  <div className="text-center pt-1 pb-5">
                    <Spinner size="lg" color="primary" />
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b></b>
                  </p>
                }
                // below props only if you need pull down functionality
                // refreshFunction={this.refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                // }
              >
                <Row>{renderCampaign()}</Row>
              </InfiniteScroll>
              {campaign.length === 0 ? (
                <Row className="">
                  <Col lg="12">
                    <Card>
                      <CardBody className="text-center">
                        <h5>
                          <b>Tidak Ada Kampanye</b>
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {/* {campaign.length > 0 ?
                <Row className="pb-4">
                  <Col lg={12} className="d-flex justify-content-center">
                    <Pagination aria-label="Page navigation example" >
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink first onClick={() => setPage(1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink previous onClick={() => setPage(page - 1)} />
                      </PaginationItem>
                      {renderPaginate(totalPage)}
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink next onClick={() => setPage(page + 1)} />
                      </PaginationItem>
                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink last onClick={() => setPage(totalPage)} />
                      </PaginationItem>
                    </Pagination>
                  </Col>
                </Row> :
                <Row className="">
                  <Col lg="12">
                    <Card>
                      <CardBody className="text-center">
                        <h5><b>Tidak Ada Kampanye</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              } */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CampaignClient)
