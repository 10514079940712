import Toaster from "components/Common/Toast"
import { connect } from "react-redux"
import { getBussinesEntity, getCountry, getOrganization, getProject, getPropertyOwner, getProvince, getProyekType, getProyekTypeDetail, getSectorIndustry, getSubmitActor } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Button, Table } from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CardProject from "./card-project"
import FormProperty from "./FormProperty"
import FormUKMTwo from "./FormUKMTwo"
import FormUMKM from "./FormUMKM"

//Import images
import ProjectChoose from "./ProjectChoose"
import Loading from "pages/Utility/Loading"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const INIT_MASTER_DATA = {
  business_entity: [],
  project_type: [],
  sector_industry: [],
  property_ownership: [],
  country: [],
  province: [],
  city: [],
  district: [],
  sub_district: [],
  submit_actor: [],
  project_type_detail: [],
  organitation: [],
}

const ApplyProject = props => {

  const [activeForm, setActiveForm] = useState(0)
  const [masterData, setMasterData] = useState(INIT_MASTER_DATA)
  const [projects, setProjects] = useState([])
  const [stepTwoId, setstepTwoId] = useState("")
  const [load, setLoad] = useState(false)

  const handleStepTwoId = (uuid) => {
    setstepTwoId(uuid)
    setActiveForm(2)
  }

  useEffect(() => {
    getMastedData()
    getProjectUser()
  }, [])

  const getProjectUser = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      const data = await getProject(token)
      setProjects(data.data)
      setLoad(false)
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
      setLoad(false)
    }
  }

  const getMastedData = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      const businessEntity = await getBussinesEntity(token)
      const proyekType = await getProyekType(token)
      const sectorIndustry = await getSectorIndustry(token)
      const propertyOwner = await getPropertyOwner(token)
      const country = await getCountry(token)
      const province = await getProvince(token)
      const submitActor = await getSubmitActor(token)
      const proyekTypeDetail = await getProyekTypeDetail(token)
      const organ = await getOrganization(token)

      setMasterData({
        ...masterData,
        business_entity: businessEntity.data,
        project_type: proyekType.data,
        sector_industry: sectorIndustry.data,
        property_ownership: propertyOwner.data,
        country: country.data,
        province: province.data,
        submit_actor: submitActor.data,
        proyekTypeDetail: proyekTypeDetail.data,
        organitation: organ.data
      })
      setLoad(false)
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
      setLoad(false)
    }
  }

  const form = [
    <ProjectChoose
      activeForm={activeForm}
      setActiveForm={setActiveForm}
      projects={projects}
      handleStepTwoId={handleStepTwoId}
      user={props.user}
      setLoad={setLoad}
    />,
    <FormUMKM 
      masterData={masterData} 
      setMasterData={setMasterData} 
      projects={projects}
      getProjectUser={getProjectUser}
      setActiveForm={setActiveForm}
      setLoad={setLoad}
    />,
    <FormUKMTwo 
      masterData={masterData} 
      setMasterData={setMasterData} 
      projects={projects}
      getProjectUser={getProjectUser}
      setActiveForm={setActiveForm}
      stepTwoId={stepTwoId}
      setLoad={setLoad}
    />,
    <FormProperty 
      masterData={masterData} 
      setMasterData={setMasterData}
      setActiveForm={setActiveForm}
      getProjectUser={getProjectUser}
      setLoad={setLoad}
    />
  ]

  if(load && activeForm === 0) {
    return (
      <SkeletonLoad
        title="Pengajuan"
        breadcrumbItem="Proyek"
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Row className="justify-content-center mt-lg-1">
            <Col xl="5" sm="8">
              <Skeleton height={380} />
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  if(load && activeForm === 1 || activeForm === 2) {
    return (
      <SkeletonLoad
        title="Pengajuan"
        breadcrumbItem="Proyek"
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton width={320} />
          {[1,2,3,4,5,6,7,8,9].map(item => {
            return (
              <Row>
                <Col sm={3}><Skeleton /></Col>
                <Col sm={9}><Skeleton /></Col>
              </Row>
            )
          })}
        </div>
      </SkeletonLoad>
    )
  }

  if(load && activeForm === 3) {
    return (
      <SkeletonLoad
        title="Pengajuan"
        breadcrumbItem="Proyek"
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton width={320} />
          {[1,2,3,4,5,6,7,8,9].map(item => {
            return (
              <Row>
                <Col sm={3}><Skeleton /></Col>
                <Col sm={9}><Skeleton /></Col>
              </Row>
            )
          })}
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Pembiayaan" breadcrumbItem="Proyek" />
          {form[activeForm]}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

// export default ApplyProject
export default connect(mapStateToProps, null)(ApplyProject)
