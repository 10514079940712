import { getSettings, updateSetting } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import DataTable from "components/Common/DataTable"
import NumberFormat from "react-number-format"

const Setting = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [settings, setSettings] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const [modalUpdate, setModalUpdate] = useState(null)
  const [select, setSelect] = useState(null)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)
  const [keySearch, setKeySearch] = useState("title")
  const [search, setSearch] = useState("")

  const [decimal, setDecimal] = useState("")
  const [number, setNumber] = useState("")

  const toggleModalUpdate = item => {
    setSelect(item)
    setDecimal("")
    setNumber("")
    setModalUpdate(!modalUpdate)
  }

  useEffect(() => {
    getData()
  }, [page])

  const getData = async () => {
    setLoad(true)
    const data = await getSettings(
      token,
      `?per_page=${perPage}&page=${page}&keySearch=${keySearch}&search=${search}`
    )
    setTotalPage(data.meta.pagination.last_page)
    setCurrentPage(data.meta.pagination.current_page)
    setPagination(data.meta.pagination)
    setSettings(data.data)
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleTableRender = item => {
    if (item.json_value) {
      return item.json_value
    }
    if (item.number_value || item.number_value === 0) {
      return parseInt(item.number_value).toLocaleString()
    }
    if (item.decimal_value || item.decimal_value === 0) {
      return parseFloat(item.decimal_value).toLocaleString()
    }
    if (item.boolean_value !== null) {
      return item.boolean_value ? (
        <Badge color="primary">Aktif</Badge>
      ) : (
        <Badge color="secondary">Tidak Aktif</Badge>
      )
    }
    if (item.string_value) {
      return item.string_value
    }

    return ""
  }

  const tableSetting = {
    columns: [
      {
        field: "title",
        label: "Judul",
        sort: "asc",
      },
      {
        field: "tags",
        label: "Tags",
        sort: "asc",
      },
      {
        field: "value",
        label: "Value",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: settings.map(item => {
      return {
        ...item,
        value: handleTableRender(item),
        action: (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggleModalUpdate(item)}
          >
            <i class="bx bxs-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const handleUpdate = async (e, value) => {
    const newValue = {
      ...value,
      decimal_value: decimal,
      number_value: number,
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await updateSetting(select.uuid, formData, token)
      toggleModalUpdate(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil ubah data")
      getData()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderUpdate = () => {
    return (
      <Modal isOpen={modalUpdate} role="dialog" autoFocus={true} scrollable>
        <ModalHeader toggle={() => toggleModalUpdate(null)}>
          {select && select.title}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleUpdate(e, v)
            }}
          >
            {select && select.string_value ? (
              <PInput
                name="string_value"
                type="text"
                label="String"
                value={select ? select.string_value : ""}
              />
            ) : null}
            {(select && select?.number_value) || select?.number_value === 0 ? (
              // <PInput
              //   name="number_value"
              //   type="number"
              //   label="Number"
              //   value={select ? select.number_value : ""}
              // />
              <NumberFormat
                customInput={PInput}
                thousandSeparator={true}
                // prefix={"Rp "}
                name="number_value"
                label="Number"
                value={number}
                onValueChange={e => setNumber(e.value)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Number harus diisi",
                  },
                }}
              />
            ) : null}
            {(select && select?.decimal_value) ||
            select?.decimal_value === 0 ? (
              <NumberFormat
                customInput={PInput}
                thousandSeparator={true}
                // prefix={"Rp "}
                name="decimal_value"
                label="Decimal"
                value={decimal}
                onValueChange={e => setDecimal(e.value)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Decimal harus diisi",
                  },
                }}
              />
            ) : null}
            {select && select.json_value ? (
              <PInput
                name="json_value"
                type="textarea"
                label="JSON"
                helpMessage="Format JSON"
                value={select ? select.json_value : ""}
              />
            ) : null}
            <PInput
              name="boolean_value"
              type="select"
              label="Status"
              value={
                select
                  ? select.boolean_value == null
                    ? ""
                    : select.boolean_value
                    ? "true"
                    : "false"
                  : ""
              }
              options={[
                { uuid: "true", title: "Aktif" },
                { uuid: "false", title: "Tidak Aktif" },
              ]}
              disabled={select && select.boolean_value == null ? true : false}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading />

  return (
    <React.Fragment>
      {renderUpdate()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pengaturan" breadcrumbItem="Pengaturan" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Pengaturan
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      {/* <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModalCreateVoucher}>+ Buat Voucher</Button>
                      </div> */}

                      {/* <MDBDataTable
                        responsive
                        bordered
                        data={tableSetting}
                        striped
                        className="mt-3"
                        disableRetreatAfterSorting
                      /> */}
                      <DataTable
                        table={tableSetting}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        search={search}
                        handleSearch={setSearch}
                        keySearch={keySearch}
                        setKeySearch={setKeySearch}
                        dataKey={["title", "tags"]}
                        dataKeyCustom={[
                          { value: "title", label: "Judul" },
                          { value: "tags", label: "Tags" },
                        ]}
                        handleGetSearch={getData}
                        searching={true}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Setting
