import React, { useState } from "react";
import { Badge, Button, Tooltip } from "reactstrap";

const GroupTooltip = props => {
  const { item, id, onClick, className } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  // console.log('sdfs', item)

  return (
    <React.Fragment>
      <div onClick={onClick} className={className} id={`group-${id}`}>
        <div className="d-flex justify-content-between align-items-center"> 
          <span className="font-12">
            {item.name}
          </span>
          {item.totalUnreadMessage > 0 ? <Badge color="primary" className="bold">{item.totalUnreadMessage}</Badge> : null}
        </div>
      </div>
      <Tooltip
        placement={`top`}
        isOpen={tooltipOpen}
        target={"group-" + id}
        toggle={toggle}
      >
        {item.participants.map(el => <div>{el.first_name} {el.last_name}</div>)}
      </Tooltip>
    </React.Fragment>
  );
}

export default GroupTooltip