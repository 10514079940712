import Toaster from "components/Common/Toast"
import {
  getCampaignActive,
  getDashboardUser,
  getMostVisited,
  getRealTimeUser,
  getTopBrowser,
  getTopRefferer,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  Card,
  CardBody,
  Media,
  Tooltip,
  CardHeader,
  Input,
} from "reactstrap"
import { Progress } from "reactstrap"
import Welcome from "./Welcome"
import moment from "moment"
import { API_URL } from "helpers/api_url"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import WelcomeAdmin from "./WelcomeAdmin"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"
import Blog from "./Blog"
import PieChart from "./PieChart"
import BarChart from "./BarChart"
import PInput from "components/Input/PInput"
import DountChart from "./DonutChart"

import Pusher from "pusher-js"

const Admin = props => {
  const token = localStorage.getItem("token")
  const [dataUser, setDataUser] = useState(null)
  const [campaign, setCampaign] = useState([])
  const [load, setLoad] = useState(false)
  const [ttop, setttop] = useState(false)
  const [ttop2, setttop2] = useState(false)

  // google analytics
  const [mostVisited, setMostVisited] = useState([])
  const [firstDateVisited, setFirstDateVisited] = useState("")
  const [secondDateVisited, setSecondDateVisited] = useState("")

  const [topRefferer, setTopReffer] = useState([])
  const [firstDateRef, setFirstDateRef] = useState("")
  const [secondDateRef, setSecondDateRef] = useState("")

  const [topBrowser, setTopBrowser] = useState([])
  const [firstDateBrowser, setFirstDateBrowser] = useState("")
  const [secondDateBrowser, setSecondDateBrowser] = useState("")

  const [activeUser, setActiveUser] = useState(null)

  useEffect(async () => {
    const data = await getRealTimeUser("")
    if (data.data.length > 0) {
      setActiveUser(data.data[0])
    }
  }, [])

  useEffect(() => {
    listenPusher()
  }, [])

  const listenPusher = async () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "ap1",
      authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          apiKey: process.env.REACT_APP_API_KEY,
        },
      },
    })

    const channel = pusher.subscribe(`active-user`)

    channel.bind("realtime", data => {
      setActiveUser(data)
    })
  }

  useEffect(() => {
    getDataTopReferrer()
  }, [firstDateRef, secondDateRef])

  useEffect(() => {
    getDataTopBrowser()
  }, [firstDateBrowser, secondDateBrowser])

  useEffect(() => {
    DataGAVisited()
  }, [firstDateVisited, secondDateVisited])

  useEffect(() => {
    getData()
  }, [])

  const DataGAVisited = async () => {
    let query = ""
    if (firstDateVisited && secondDateVisited) {
      query = `?from=${firstDateVisited}&to=${secondDateVisited}`
    }
    const dataVisited = await getMostVisited(query)
    setMostVisited(dataVisited.data)
  }

  const getDataTopReferrer = async () => {
    let query = ""
    if (firstDateRef && secondDateRef) {
      query = `?from=${firstDateRef}&to=${secondDateRef}`
    }
    const data = await getTopRefferer(query)
    setTopReffer(data.data)
  }

  const getDataTopBrowser = async () => {
    let query = ""
    if (firstDateBrowser && secondDateBrowser) {
      query = `?from=${firstDateBrowser}&to=${secondDateBrowser}`
    }
    const data = await getTopBrowser(query)
    setTopBrowser(data.data)
  }

  const getData = async () => {
    let query = `?per_page=3`
    setLoad(true)
    try {
      const userDashboard = await getDashboardUser(token)
      const data = await getCampaignActive(query)
      setCampaign(data.data)
      setDataUser(userDashboard.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
    }
  }

  const renderSisaWaktu = (date, status) => {
    var eventdate = moment(date)
    var todaysdate = moment().startOf("day")
    let countDay = eventdate.diff(todaysdate, "days")
    if (countDay < 0) {
      return "Kampanye Berakhir"
    }
    if (status === 6) return "Target Terkumpul"
    return `${countDay} Hari Lagi`
  }

  const renderCampaign = () => {
    if (campaign.length === 0) {
      return null
    }
    return campaign.map(item => {
      return (
        <div className="mb-2 col-lg-4">
          <div className="card">
            <div
              style={{
                height: 200,
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
              }}
            >
              <img
                draggable="false"
                src={API_URL + item.image}
                // className="img-fluid"
                height="100%"
                width="100%"
                style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
              />
            </div>
            <div className="f-11 project-card-body">
              <div className="project-head">
                <Row>
                  <Col xs="12">
                    <p>
                      <b>{item.title}</b>
                    </p>
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col>
                        <p>
                          <i className="fa fa-map-marker-alt"></i>{" "}
                          {item.province.name}
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <i className="fa fa-handshake"></i>{" "}
                          {item.akad_type.title}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <hr className=""></hr>
              <div className="project-body">
                <div className="project-content">
                  <Row className="mb-3">
                    <Col xs="12">
                      <Progress className="height-base" multi>
                        <Progress
                          animated
                          bar
                          color="success"
                          value={
                            (Number(item.collected) / Number(item.target)) * 100
                          }
                        />
                        <Progress
                          animated
                          bar
                          color="secondary"
                          value={
                            (Number(item.pledge) / Number(item.target)) * 100 -
                            (Number(item.collected) / Number(item.target)) * 100
                          }
                        />
                      </Progress>
                      <Row className="mt-2">
                        <Col md={6}>
                          <Badge color="primary">{` `}</Badge> Terkumpul{" "}
                          <b>
                            {(
                              (Number(item.collected) / Number(item.target)) *
                              100
                            ).toFixed(2)}
                            %
                          </b>
                        </Col>
                        <Col md={6}>
                          <Badge color="secondary">{` `}</Badge> Minat{" "}
                          <b>
                            {(
                              (Number(item.pledge) / Number(item.target)) *
                              100
                            ).toFixed(2)}
                            %
                          </b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Target</p>
                      <p className="bold">
                        Rp {Number(item.target).toLocaleString()}
                      </p>
                    </Col>
                    <Col>
                      <p>Terkumpul</p>
                      <p className="bold">
                        Rp {Number(item.collected).toLocaleString()}
                      </p>
                    </Col>
                  </Row>
                  <div class="separator"></div>
                  <Row>
                    <Col>
                      <p>Proyeksi Imbal Hasil</p>
                      <p className="bold">{item.nisbah} %</p>
                    </Col>
                    <Col>
                      <p>Tenor Proyek</p>
                      <p className="bold">{item.tenor} Bulan</p>
                    </Col>
                  </Row>
                  <div className="separator"></div>
                  <Row>
                    <Col>
                      <p>Tanggal Berakhir</p>
                      <p className="bold">
                        {moment(item.end_date_campaign).format("DD MMM YYYY")}
                      </p>
                    </Col>
                    <Col>
                      <p>Sisa Waktu</p>
                      <p className="bold">
                        {renderSisaWaktu(item.end_date_campaign, item.status)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="project-card-footer">
              <a id="startCampaign" href="">
                <Link to={`/detail-kampanye/${item.uuid}`}>
                  <button className="btn btn-success btn-block campaign-btn">
                    Detail Kampanye
                  </button>
                </Link>
              </a>
            </div>
          </div>
        </div>
      )
    })
  }

  if (!dataUser) {
    return (
      <SkeletonLoad title="Beranda" breadcrumbItem="Admin">
        <div style={{ lineHeight: 3 }}>
          <Skeleton height={60} />
          <Row className="mt-2 mb-3">
            {[1, 2, 3, 4, 5, 6].map(item => {
              return (
                <Col md="4">
                  <Skeleton height={80} />
                </Col>
              )
            })}
          </Row>
          <Skeleton />
          <Row className="mt-2">
            {[1, 2, 3].map(item => {
              return (
                <Col md="4">
                  <Skeleton height={500} />
                </Col>
              )
            })}
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Beranda" breadcrumbItem="Admin" />
          <Row>
            <Col xl={12}>
              <WelcomeAdmin user={props.user} />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Total Pengguna
                      </p>
                      <h4 className="mb-0">
                        {parseInt(dataUser.totalUser).toLocaleString()}
                      </h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bxs-user font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Total Proyek
                      </p>
                      <h4 className="mb-0">
                        {parseInt(dataUser.totalProject).toLocaleString()}
                      </h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bx-copy-alt font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted font-weight-medium">
                        Total Kampanye
                      </p>
                      <h4 className="mb-0">
                        {parseInt(dataUser.totalCampaign).toLocaleString()}
                      </h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bx-store font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Transaksi
                        </p>
                        <div style={{ cursor: "pointer" }}>
                          <Tooltip
                            placement="top"
                            isOpen={ttop}
                            target="TooltipTop"
                            toggle={() => {
                              setttop(!ttop)
                            }}
                          >
                            Total seluruh transaksi yang sudah terbayar pada
                            semua kampanye termasuk yang sudah dikembalikan.
                          </Tooltip>
                          <i
                            className="bx bxs-calendar-heart bx-xs ml-1"
                            id="TooltipTop"
                          ></i>
                        </div>
                      </div>
                      <h4 className="mb-0">
                        {parseInt(
                          dataUser.totalAllCampaignSuccessTransaction
                        ).toLocaleString()}
                      </h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bx-money font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Transaksi Per Hari Ini
                        </p>
                        <div style={{ cursor: "pointer" }}>
                          <Tooltip
                            placement="top"
                            isOpen={ttop2}
                            target="TooltipTop2"
                            toggle={() => {
                              setttop2(!ttop2)
                            }}
                          >
                            Total seluruh transaksi yang sudah terbayar pada
                            semua kampanye termasuk yang sudah dikembalikan Per
                            Hari ini.
                          </Tooltip>
                          <i
                            className="bx bxs-calendar-heart bx-xs ml-1"
                            id="TooltipTop2"
                          ></i>
                        </div>
                      </div>
                      <h4 className="mb-0">
                        {parseInt(
                          dataUser.totalAllCampaignSuccessTransactionToday
                        ).toLocaleString()}
                      </h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bx-money font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Transaksi Belum Dibayar
                        </p>
                      </div>
                      <h4 className="mb-0">
                        {parseInt(
                          dataUser.totalAllCampaignStatusUnpaidTransaction
                        ).toLocaleString()}
                      </h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i className={"bx bx-money font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Link to="/portfolio?page=1&perPage=5">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Pembiayaan
                        </p>
                        <h4 className="mb-0">
                          Rp{" "}
                          {parseInt(dataUser.totalInvestment).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-save font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col md="4">
              <Link to="/portfolio?page=1&perPage=5">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Dibiayai
                        </p>
                        <h4 className="mb-0">
                          {parseInt(
                            dataUser.totalInvestCampaign
                          ).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-copy-alt font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            {/* <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Berjalan
                        </p>
                        <div style={{cursor: 'pointer'}}>
                          <Tooltip
                            placement="top"
                            isOpen={ttop}
                            target="TooltipTop"
                            toggle={() => {
                              setttop(!ttop)
                            }}
                          >
                            Sedang Proses Pengembalian
                          </Tooltip>
                          <i className="bx bxs-calendar-heart bx-xs ml-1" id="TooltipTop"></i>
                        </div>
                      </div>
                      <h4 className="mb-0">{parseInt(dataUser.totalInvestmentActive).toLocaleString()}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i
                          className={
                            "bx bx-calendar-minus font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md="4">
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <div className="d-flex">
                        <p className="text-muted font-weight-medium">
                          Total Kampanye Selesai
                        </p>
                        <div style={{cursor: 'pointer'}}>
                          <Tooltip
                            placement="top"
                            isOpen={ttop2}
                            target="TooltipTop2"
                            toggle={() => {
                              setttop2(!ttop2)
                            }}
                          >
                            Selesai Pengembalian
                          </Tooltip>
                          <i className="bx bxs-calendar-heart bx-xs ml-1" id="TooltipTop2"></i>
                        </div>
                      </div>
                      <h4 className="mb-0">{parseInt(dataUser.totalFinishCampaign).toLocaleString()}</h4>
                    </Media>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                      <span className="avatar-title">
                        <i
                          className={
                            "bx bx-calendar-check font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="4">
              <Link to="/portfolio?page=1&perPage=5">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted font-weight-medium">
                          Total Pengembalian
                        </p>
                        <h4 className="mb-0">
                          Rp {parseInt(dataUser.totalPayout).toLocaleString()}
                        </h4>
                      </Media>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className={"bx bx-money font-size-24"}></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
          <hr
            className="hr-text pt-2"
            // style={{ marginTop: "16px" }}
            data-content="Google Analytics"
          />
          <Row className="mb-3 mt-4">
            <Col lg="6">
              <Card>
                <CardHeader className="border font-16 font-weight-bold">
                  <div className="text-center mb-2">Most Visited Page</div>
                  <div className="d-flex justify-content-between">
                    <Input
                      type="date"
                      onChange={e => setFirstDateVisited(e.target.value)}
                    />
                    <div className="mx-2" />
                    <Input
                      type="date"
                      onChange={e => setSecondDateVisited(e.target.value)}
                    />
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pb-0">
                  <BarChart
                    data={mostVisited.map(item => item.pageViews)}
                    categories={mostVisited.map(
                      item => item.pageTitle.split(" | ")[0].split("/")[0]
                    )}
                    name="Users"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardHeader className="border font-16 font-weight-bold">
                  <div className="text-center mb-2">Top Refferer</div>
                  <div className="d-flex justify-content-between">
                    <Input
                      type="date"
                      onChange={e => setFirstDateRef(e.target.value)}
                    />
                    <div className="mx-2" />
                    <Input
                      type="date"
                      onChange={e => setSecondDateRef(e.target.value)}
                    />
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pb-0">
                  <BarChart
                    data={topRefferer.map(item => item.pageViews)}
                    categories={topRefferer.map(item => item.url)}
                    name="Users"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardHeader className="border font-16 font-weight-bold">
                  <div className="text-center mb-2">Top Browser</div>
                  <div className="d-flex justify-content-between">
                    <Input
                      type="date"
                      onChange={e => setFirstDateBrowser(e.target.value)}
                    />
                    <div className="mx-2" />
                    <Input
                      type="date"
                      onChange={e => setSecondDateBrowser(e.target.value)}
                    />
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pb-0">
                  <PieChart
                    data={topBrowser.map(item => {
                      return { value: item.sessions, name: item.browser }
                    })}
                    title={topBrowser.map(item => item.browser)}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardHeader className="border font-16 font-weight-bold">
                  <div className="text-center mb-2">Active Users</div>
                  <div className="d-flex justify-content-between">
                    <Input
                      type="date"
                      disabled
                      className="disabled-input-custom"
                    />
                    <div className="mx-2" />
                    <Input
                      type="date"
                      disabled
                      className="disabled-input-custom"
                    />
                  </div>
                </CardHeader>
                <CardBody style={{ height: 350 }}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">
                          {activeUser?.activeDevice?.filter(
                            item => item.deviceCategory === "DESKTOP"
                          ).length || 0}
                        </h5>
                        <p className="text-muted">DESKTOP</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">
                          {activeUser?.activeDevice?.filter(
                            item => item.deviceCategory === "MOBILE"
                          ).length || 0}
                        </h5>
                        <p className="text-muted">MOBILE</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">
                          {activeUser?.activeDevice?.filter(
                            item =>
                              item.deviceCategory !== "MOBILE" &&
                              item.deviceCategory !== "DESKTOP"
                          ).length || 0}
                        </h5>
                        <p className="text-muted">LAINNYA</p>
                      </div>
                    </Col>
                  </Row>
                  <DountChart
                    data={
                      activeUser?.activeDevice?.length
                        ? [
                            activeUser.activeDevice.filter(
                              item => item.deviceCategory === "DESKTOP"
                            ).length,
                            activeUser.activeDevice.filter(
                              item => item.deviceCategory === "MOBILE"
                            ).length,
                            activeUser.activeDevice.filter(
                              item =>
                                item.deviceCategory !== "MOBILE" &&
                                item.deviceCategory !== "DESKTOP"
                            ).length,
                          ]
                        : [0, 0, 0]
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr
            className="hr-text"
            style={{ marginTop: "16px" }}
            data-content="Kampanye Terbaru"
          />
          <Row className="mt-5">{renderCampaign()}</Row>
          {/* <div className="text-center mb-5">
            <Link to="/daftar-kampanye">
              <Button className="btn-rounded" color="primary">Lihat Daftar Kampanye</Button>
            </Link>
          </div> */}
          {/* <Blog /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Admin
