import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"
import { connect } from "react-redux"

import { MDBDataTable } from "mdbreact"
import ModalImage from "react-modal-image"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import {
  approvalDttot,
  getCampaignSelector,
  getHistoryDttot,
  getListDttot,
  getProfileDetails,
  getRoleAll,
  getStatusDttot,
  getStatusIlluma,
  getUsersAllDropdown,
  getUserVerified,
} from "helpers/backend_helpers"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import moment from "moment"
import { API_URL, API_URL_1 } from "helpers/api_url"
import axios from "axios"
import DataTable from "components/Common/DataTable"
import Select from "react-select"
import AsyncSelect from "react-select/async"

const search_init = {
  email: "",
  fullName: "",
  role: "",
  statusIlluma: "",
  statusInternal: "",
  startDate: "",
  endDate: "",

  creator: "",
  order: null,
}

const DTTOT = props => {
  const token = localStorage.getItem("token")
  const [activeTab, setActiveTab] = useState("1")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [users, setUsers] = useState([])
  const [selectUser, setSelectUser] = useState(null)
  const [profile, setProfile] = useState(null)
  const [dttot, setDttot] = useState([])
  const [selectApprove, setSelectApprove] = useState(null)
  const [histories, setHistories] = useState([])
  const [roles, setRoles] = useState([])

  const [modalProfile, setModalProfile] = useState(false)
  const [modalDttot, setModalDttot] = useState(false)
  const [modalApprove, setModalApprove] = useState(false)

  //status
  const [statusIlluma, setStatusIlluma] = useState([])
  const [statusDttot, setStatusDttot] = useState([])

  const [files, setFiles] = useState(null)

  //pagination
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)

  const [filter, setFilter] = useState(search_init)

  useEffect(() => {
    getData(activeTab)
  }, [activeTab, filter, page, perPage])

  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [filter, activeTab])

  useEffect(() => {
    getProfile(selectUser?.user?.id)
  }, [selectUser])

  const getData = async activeTab => {
    setLoad(true)
    try {
      if (activeTab == "1") {
        let query = `?per_page=${perPage}&page=${page}`

        if (filter.email) {
          query += `&user[email][operator]=LIKE&user[email][value]=%${filter.email}%`
        }
        if (filter.fullName) {
          query += `&fullName[operator]=LIKE&fullName[value]=%${filter.fullName}%`
        }
        if (filter.role) {
          query += `&user[user_role][role][uuid]=${filter.role}`
        }
        if (filter.statusIlluma) {
          query += `&vendor_status[operator]==&vendor_status[value]=${filter.statusIlluma}`
        }
        if (filter.statusInternal) {
          query += `&status[operator]==&status[value]=${filter.statusInternal}`
        }
        if (filter.startDate) {
          query += `&created_at[start]=${filter.startDate}`
        }
        if (filter.endDate) {
          query += `&created_at[end]=${filter.endDate}`
        }
        if (filter.order) {
          query += `&f_params[orderBy][field]=${filter.order?.label}&f_params[orderBy][type]=${filter.order?.value}`
        }

        const { data: list, meta } = await getListDttot(token, query)
        setUsers(list)
        setTotalPage(meta?.pagination?.last_page)
        setCurrentPage(meta?.pagination?.current_page)
        setPagination(meta?.pagination)
      } else if (activeTab == "2") {
        let query = `?per_page=${perPage}&page=${page}`

        if (filter.email) {
          query += `&user_suspect[user][email][operator]=LIKE&user_suspect[user][email][value]=%${filter.email}%`
        }
        if (filter.fullName) {
          query += `&user_suspect[fullName][operator]=LIKE&user_suspect[fullName][value]=%${filter.fullName}%`
        }
        if (filter.statusInternal) {
          query += `&statusAfterAction=${filter.statusInternal}`
        }
        if (filter.creator?.value) {
          query += `&creator[id]=${filter.creator?.value}`
        }
        if (filter.startDate) {
          query += `&created_at[start]=${filter.startDate}`
        }
        if (filter.endDate) {
          query += `&created_at[end]=${filter.endDate}`
        }
        if (filter.order) {
          query += `&f_params[orderBy][field]=${filter.order?.label}&f_params[orderBy][type]=${filter.order?.value}`
        }

        const { data: list, meta } = await getHistoryDttot(token, query)
        setHistories(list)
        setTotalPage(meta?.pagination?.last_page)
        setCurrentPage(meta?.pagination?.current_page)
        setPagination(meta?.pagination)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  const getProfile = async id => {
    if (id) {
      setLoad(true)
      const res = await getProfileDetails(id, token)
      setProfile(res.data)
      setLoad(false)
    }
  }

  const getStatus = async () => {
    setLoad(true)
    const listDttot = await getStatusDttot(token)
    const listIlluma = await getStatusIlluma(token)
    const roles = await getRoleAll(token)
    setRoles(roles.data)
    setStatusDttot(listDttot?.data || [])
    setStatusIlluma(listIlluma?.data || [])
    setLoad(false)
  }

  const toggleModalProfile = (item = null) => {
    setSelectUser(item)
    setModalProfile(!modalProfile)
  }

  const toggleModalDttot = (item = null) => {
    setDttot(item?.user_suspect_result || [])
    setModalDttot(!modalDttot)
  }

  const toggleModalApprove = (item = null) => {
    setSelectApprove(item)
    setModalApprove(!modalApprove)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setFilter(search_init)
    }
  }

  const handleStatusIlluma = item => {
    switch (item?.value) {
      case 1:
        return <Badge color="primary">{item?.title}</Badge>
      case 2:
        return <Badge color="danger">{item?.title}</Badge>
      default:
        return ""
    }
  }

  const handleStatusDttot = item => {
    switch (item?.value) {
      case 1:
        return <Badge color="info">{item?.title}</Badge>
      case 2:
        return <Badge color="primary">{item?.title}</Badge>
      case 3:
        return <Badge color="danger">{item?.title}</Badge>
      default:
        return ""
    }
  }

  const tableUser = {
    columns: [
      {
        field: "created_at",
        label: "Tanggal",
        order: true,
      },
      {
        field: "email",
        label: "Email",
      },
      {
        field: "fullName",
        label: "Nama",
      },
      {
        field: "role",
        label: "Peran",
      },
      {
        field: "profile",
        label: "Profil",
      },
      {
        field: "vendor_status",
        label: "status Illuma",
        order: true,
      },
      {
        field: "status",
        label: "Status Persetujuan",
        order: true,
      },
      {
        field: "params",
        label: "Parameter Match",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: users.map(item => {
      return {
        ...item,
        created_at: item.created_at ? (
          <div className="text-nowrap">
            {moment(item.created_at).format("DD MMM YYYY")}
          </div>
        ) : (
          "-"
        ),
        email: item.user?.email,
        fullName: item.user?.fullName,
        role: item.user?.user_role?.map(item => <div>{item.role?.title}</div>),
        vendor_status: handleStatusIlluma(
          statusIlluma.find(el => el.value == item.vendor_status)
        ),
        status: handleStatusDttot(
          statusDttot.find(el => el.value == item.status)
        ),
        profile: (
          <div className="text-center">
            <Button
              className="btn-sm"
              color="secondary"
              onClick={() => toggleModalProfile(item)}
            >
              <i className="fas fa-eye"></i>
            </Button>
          </div>
        ),
        params: (
          <div className="text-center">
            <Button
              className="btn-sm"
              color="secondary"
              onClick={() => toggleModalDttot(item)}
            >
              <i className="fas fa-eye"></i>
            </Button>
          </div>
        ),
        action: (
          <div className="text-center">
            <Button
              className="btn-sm"
              color="secondary"
              onClick={() => toggleModalApprove(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const tableHistories = {
    columns: [
      {
        field: "created_at",
        label: "Tanggal",
        order: true,
      },
      {
        field: "email",
        label: "Email",
      },
      {
        field: "fullName",
        label: "Nama",
      },
      {
        field: "profile",
        label: "Profil",
      },
      // {
      //   field: "roles",
      //   label: "Peran",
      // },
      // {
      //   field: "statusIlluma",
      //   label: "status Illuma",
      // },
      {
        field: "statusAfterAction",
        label: "Status Persetujuan",
        order: true,
      },
      {
        field: "description",
        label: "Deskripsi",
      },
      {
        field: "fileDttot",
        label: "Berkas",
      },
      {
        field: "actor",
        label: "Persetujuan Oleh",
      },
    ],
    rows: histories.map(item => {
      return {
        ...item,
        created_at: item.created_at
          ? moment(item.created_at).format("DD MMM YYYY")
          : "",
        email: item.user_suspect?.user?.email,
        fullName: item.user_suspect?.user?.fullName,
        roles: item.user?.user_role?.map(item => <div>{item.role?.title}</div>),
        statusIlluma: handleStatusIlluma(
          statusIlluma.find(el => el.value == item.vendorStatusAfterAction)
        ),
        statusAfterAction: handleStatusDttot(
          statusDttot.find(el => el.value == item.statusAfterAction)
        ),
        actor: item.creator?.fullName,
        description: item.description,
        fileDttot: item.files?.length ? (
          item.files?.map(el => {
            return (
              <div className="text-center">
                <Button
                  className="btn-sm"
                  color="secondary"
                  onClick={() => window.open(API_URL + el.file, "_blank")}
                >
                  <i className="fas fa-eye"></i>
                </Button>
              </div>
            )
          })
        ) : (
          <div className="text-center">-</div>
        ),
        profile: (
          <div className="text-center">
            <Button
              className="btn-sm"
              color="secondary"
              onClick={() => toggleModalProfile(item.user_suspect)}
            >
              <i className="fas fa-eye"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const handleRoles = roles => {
    if (!Array.isArray(roles)) return []
    let result = []
    roles.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  const handleOrder = obj => {
    setFilter({
      ...filter,
      order: obj,
    })
  }

  const renderButtonFile = item => {
    if (typeof item === "string") {
      return (
        <Button
          className="btn-sm"
          style={{ marginTop: "-12px", marginBottom: "8px" }}
          disabled={!item}
          onClick={() => window.open(`${API_URL}${item}`, "_blank")}
        >
          <i className="fas fa-eye"></i>
        </Button>
      )
    }
    return null
  }

  const renderModalProfile = () => {
    return (
      <Modal
        isOpen={modalProfile}
        role="dialog"
        autoFocus={true}
        scrollable={true}
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalProfile(null)}>
          Profil
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <Row className="">
              <Col xs={12} md={6}>
                <PInput
                  name="first_name"
                  label="Nama Depan"
                  type="text"
                  value={profile?.first_name}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="last_name"
                  label="Nama Belakang"
                  type="text"
                  value={profile?.last_name}
                  disabled
                />
              </Col>

              <Col xs={12} md={6}>
                <PInput
                  name="email"
                  label="Email"
                  type="text"
                  value={profile?.email}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="phone_number"
                  label="No Handphone"
                  type="text"
                  value={profile?.phone_number}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="user_title_id"
                  label="Panggilan"
                  value={profile?.profile?.title?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="address"
                  label="Alamat Sesuai KTP"
                  type="text"
                  value={profile?.address?.[0]?.address}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="rt"
                  label="RT Sesuai KTP"
                  type="text"
                  value={profile?.address?.[0]?.rt}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="rw"
                  label="RW Sesuai KTP"
                  type="text"
                  value={profile?.address?.[0]?.rw}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="province_id"
                  label="Provinsi Sesuai KTP"
                  value={profile?.address?.[0]?.province?.name}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="city_id"
                  label="Kota Sesuai KTP"
                  value={profile?.address?.[0]?.city?.name}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="district_id"
                  label="Kecamatan Sesuai KTP"
                  value={profile?.address?.[0]?.district?.name}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="sub_district_id"
                  label="Kelurahan Sesuai KTP"
                  value={profile?.address?.[0]?.sub_district?.name}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="home_address"
                  label="Alamat Tempat Tinggal"
                  type="text"
                  value={profile?.address?.[0]?.home_address}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="parent_name"
                  label="Nama Ibu"
                  type="text"
                  value={profile?.profile?.parent_name}
                  disabled
                />
              </Col>
              <Col xs={12} md={3}>
                <PInput
                  name="place_of_birth"
                  label="Tempat Lahir"
                  type="text"
                  value={profile?.profile?.place_of_birth}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="date_of_birth"
                  label="Tanggal Lahir"
                  type="date"
                  helpMessage="Bulan/Hari/Tahun"
                  value={profile?.profile?.date_of_birth}
                  disabled
                />
              </Col>
              <Col className="col-md-9"></Col>
              <Col xs={12} md={6}>
                <PInput
                  name="religion_id"
                  label="Agama"
                  value={profile?.profile?.religion?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="gender_id"
                  label="Jenis Kelamin"
                  value={profile?.profile?.gender?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="married_status_id"
                  label="Status Perkawinan"
                  value={profile?.profile?.married_status?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="education_id"
                  label="Pendidikan Terakhir"
                  value={profile?.profile?.education?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="job_id"
                  label="Pekerjaan"
                  value={profile?.profile?.job?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="job_sector_id"
                  label="Bidang Pekerjaan"
                  value={profile?.profile?.job_sector?.title}
                  disabled
                />
              </Col>
              {handleRoles(profile?.user_role).includes("Lender") && (
                <Col xs={12} md={6}>
                  <PInput
                    name="source_funding_id"
                    label="Sumber Dana"
                    value={profile?.profile?.source_funding?.title}
                    disabled
                  />
                </Col>
              )}
              <Col xs={12} md={6}>
                <PInput
                  name="month_in_come_id"
                  label="Penghasilan Perbulan"
                  value={profile?.profile?.month_income?.title}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="ktp_number"
                  label="NIK"
                  type="text"
                  value={profile?.profile?.ktp_number}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="npwp_number"
                  label="NPWP"
                  type="text"
                  value={profile?.profile?.npwp_number}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <div className="mb-3">
                  <Label>KTP</Label>
                  <ModalImage
                    small={API_URL + profile?.profile?.ktp_file}
                    large={API_URL + profile?.profile?.ktp_file}
                    alt="KTP file"
                  />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="mb-3">
                  <Label>NPWP</Label>
                  <ModalImage
                    small={API_URL + profile?.profile?.npwp_file}
                    large={API_URL + profile?.profile?.npwp_file}
                    alt="NPWP file"
                  />
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="mb-3">
                  <Label>Foto selfie</Label>
                  <ModalImage
                    small={API_URL + profile?.profile?.selfie_file}
                    large={API_URL + profile?.profile?.selfie_file}
                    alt="Selfie file"
                  />
                </div>
              </Col>
              {profile?.lenderAsCompany == 1 ? (
                <>
                  <Col xs={12} md={6}>
                    <PInput
                      name="company_name"
                      label="Nama Perusahaan"
                      type="text"
                      value={profile?.profile?.company_name}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="addressNib"
                      label="Alamat Perusahaan"
                      type="text"
                      value={profile?.profile?.addressNib}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="establishmentDeedNumber"
                      label="Nomor Akta Pendirian"
                      type="text"
                      value={profile?.profile?.establishmentDeedNumber}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="dateOfDeedOfEstablishment"
                      label="Tanggal Akta Pendirian"
                      type="date"
                      value={profile?.profile?.dateOfDeedOfEstablishment}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <p>
                      <Label>File Akta Pendirian</Label>
                    </p>
                    {renderButtonFile(profile?.profile?.akta_pendirian)}
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="establishmentSkNumber"
                      label="Nomor SK Akta Pendirian"
                      type="text"
                      value={profile?.profile?.establishmentSkNumber}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="dateOfEstablishmentDecree"
                      label="Tanggal SK Akta Pendirian"
                      type="date"
                      value={profile?.profile?.dateOfEstablishmentDecree}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <p>
                      <Label>File SK Akta Pendirian</Label>
                    </p>
                    {renderButtonFile(profile?.profile?.sk_akta_pendirian)}
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="lastAmendmentDeedNumber"
                      label="Nomor Akta Perubahan Terakhir"
                      type="text"
                      value={profile?.profile?.lastAmendmentDeedNumber}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="dateOfLastAmendmentDeed"
                      label="Tanggal Akta Perubahan Terakhir"
                      type="date"
                      value={profile?.profile?.dateOfLastAmendmentDeed}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <p>
                      <Label>File Akta Perubahan Terakhir</Label>
                    </p>
                    {renderButtonFile(
                      profile?.profile?.akta_perubahan_terakhir
                    )}
                  </Col>

                  <Col xs={12} md={6}>
                    <PInput
                      name="skNumberLastChange"
                      label="Nomor SK Akta Perubahan Terakhir"
                      type="text"
                      value={profile?.profile?.skNumberLastChange}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="dateSkLastChange"
                      label="Tanggal SK Akta Perubahan Terakhir"
                      type="date"
                      value={profile?.profile?.dateSkLastChange}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <p>
                      <Label>File SK Akta Perubahan Terakhir</Label>
                    </p>
                    {renderButtonFile(profile?.profile?.sk_perubahan_terakhir)}
                  </Col>

                  <Col xs={12} md={6}>
                    <PInput
                      name="no_nib"
                      label="NIB Perusahaan"
                      type="text"
                      value={profile?.profile?.no_nib}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <p>
                      <Label>File NIB Perusahaan</Label>
                    </p>
                    {renderButtonFile(profile?.profile?.nib_file)}
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="no_siup"
                      label="Nomor SIUP"
                      type="text"
                      value={profile?.profile?.no_siup}
                      disabled
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <p>
                      <Label>File SIUP</Label>
                    </p>
                    {renderButtonFile(profile?.profile?.siup_file)}
                  </Col>
                </>
              ) : null}
              <Col xs={12} md={6}>
                <PInput
                  name="name"
                  label="Nama Pemilik Rekening"
                  type="text"
                  value={profile?.account?.[0]?.name}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="account_number"
                  label="Nomor Rekening"
                  type="text"
                  value={profile?.account?.[0]?.account_number}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="bank_id"
                  label="Bank"
                  value={profile?.account?.[0]?.bank_account?.name}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="branch"
                  label="Cabang"
                  type="text"
                  value={profile?.account?.[0]?.branch}
                  disabled
                />
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const renderModalDttot = () => {
    return (
      <Modal
        isOpen={modalDttot}
        role="dialog"
        autoFocus={true}
        scrollable={true}
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalDttot(null)}>
          Parameter Match Illuma
        </ModalHeader>
        <ModalBody>
          {dttot.length ? (
            dttot.map((item, i) => {
              return (
                <div>
                  <table
                    className="w-100 font-16 table table-striped border"
                    key={`dttot-${i}`}
                  >
                    <tbody>
                      <tr>
                        <td>Score</td>
                        <td>{item.score || "-"}</td>
                      </tr>
                      <tr>
                        <td>Nama</td>
                        <td>
                          {item.user_suspect_name?.length
                            ? item.user_suspect_name?.map(el => (
                                <div>{el.name}</div>
                              ))
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Kewarganegaraan</td>
                        <td>
                          {item.user_suspect_nationality?.length
                            ? item.user_suspect_nationality?.map(el => (
                                <div>{el.nationality}</div>
                              ))
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Lahir</td>
                        <td>
                          {item.user_suspect_date_of_birth?.length
                            ? item.user_suspect_date_of_birth?.map(el => (
                                <div>{el.date_of_birth}</div>
                              ))
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Scope</td>
                        <td>
                          {item.user_suspect_scope?.length
                            ? item.user_suspect_scope?.map(el => (
                                <div>{el.scopes}</div>
                              ))
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr className="my-4" />
                </div>
              )
            })
          ) : (
            <table className="w-100 font-16 table table-striped border">
              <tbody>
                <tr>
                  <td className="text-center">Data Kosong</td>
                </tr>
              </tbody>
            </table>
          )}
        </ModalBody>
      </Modal>
    )
  }

  const handleApprove = async (e, value) => {
    const newValue = {
      ...value,
    }

    files &&
      Array.from(files).forEach((el, index) => {
        newValue[`files[${index}]`] = el
      })

    delete newValue.files

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await approvalDttot(selectApprove?.id, formData, token)
      toggleModalApprove(null)
      setFiles(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil")
      getData("1")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message)
    }
  }

  const renderModalApprove = () => {
    return (
      <Modal
        isOpen={modalApprove}
        role="dialog"
        autoFocus={true}
        scrollable={true}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => toggleModalApprove(null)}>
            {selectApprove?.user?.fullName}
          </ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleApprove(e, v)
              }}
            >
              <PInput
                name="notFoundInSigap"
                label="Persetujuan"
                type="select"
                options={[
                  { id: 1, title: "Tidak Ditemukan" },
                  { id: 0, title: "Ditemukan" },
                ]}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Persetujuan harus diisi",
                  },
                }}
              />
              <PInput
                name="description"
                label="Deskripsi"
                type="textarea"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Deskripsi harus diisi",
                  },
                }}
              />
              <PInput
                name="files"
                label="Unggah Bukti"
                type="file"
                accept="image/*|file_extension"
                helpMessage="Berkas bisa lebih dari 1 (jpg, jpeg, png atau pdf)"
                onChange={e => setFiles(e.target.files)}
                validate={{
                  required: {
                    value: files?.length ? false : true,
                    errorMessage: "Unggah bukti harus diisi",
                  },
                }}
                value={
                  files?.length ? { name: files?.length + " Berkas" } : null
                }
                multiple
              />
              <div className="mt-3 d-flex justify-content-end">
                <Button
                  color="secondary"
                  className="mr-2"
                  onClick={() => toggleModalApprove(null)}
                >
                  Batal
                </Button>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Konfirmasi"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const loadOptions = (inputValue, callback) => {
    let param = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAllDropdown(token, param)
      callback(
        data.data.map(item => {
          return { label: item.email, value: item.uuid, ...item }
        })
      )
    })
  }

  return (
    <React.Fragment>
      {renderModalProfile()}
      {renderModalDttot()}
      {renderModalApprove()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Daftar" breadcrumbItem="DTTOT" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        KYC Due Diligent
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat Persetujuan
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="1">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              value={filter.email}
                              onChange={e => {
                                setFilter({ ...filter, email: e.target.value })
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Nama</Label>
                            <Input
                              value={filter.fullName}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  fullName: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Peran</Label>
                            <Select
                              // defaultValue={colourOptions[0]}
                              isClearable
                              isSearchable
                              options={roles.map(item => {
                                return { label: item.title, value: item.uuid }
                              })}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  role: e?.value || "",
                                })
                              }}
                              value={
                                roles
                                  .map(item => {
                                    return {
                                      label: item.title,
                                      value: item.uuid,
                                    }
                                  })
                                  .find(item => item.value === filter.role) ||
                                null
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Status Illuma</Label>
                            <Select
                              // defaultValue={colourOptions[0]}
                              isClearable
                              isSearchable
                              options={statusIlluma.map(item => {
                                return { label: item.title, value: item.value }
                              })}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  statusIlluma: e?.value || "",
                                })
                              }
                              value={
                                statusIlluma
                                  .map(item => {
                                    return {
                                      label: item.title,
                                      value: item.value,
                                    }
                                  })
                                  .find(
                                    item => item.value === filter.statusIlluma
                                  ) || null
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Status Persetujuan</Label>
                            <Select
                              // defaultValue={colourOptions[0]}
                              isClearable
                              isSearchable
                              options={statusDttot.map(item => {
                                return { label: item.title, value: item.value }
                              })}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  statusInternal: e?.value || "",
                                })
                              }
                              value={
                                statusDttot
                                  .map(item => {
                                    return {
                                      label: item.title,
                                      value: item.value,
                                    }
                                  })
                                  .find(
                                    item => item.value === filter.statusInternal
                                  ) || null
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Tanggal Awal</Label>
                            <Input
                              type="date"
                              value={filter.startDate}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  startDate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Tanggal Akhir</Label>
                            <Input
                              type="date"
                              value={filter.endDate}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  endDate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <DataTable
                        table={tableUser}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        loading={load}
                        order={filter.order}
                        orderClick={handleOrder}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              value={filter.email}
                              onChange={e =>
                                setFilter({ ...filter, email: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Nama</Label>
                            <Input
                              value={filter.fullName}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  fullName: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <Label>Status Persetujuan</Label>
                            <Select
                              // defaultValue={colourOptions[0]}
                              isClearable
                              isSearchable
                              options={statusDttot.map(item => {
                                return { label: item.title, value: item.value }
                              })}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  statusInternal: e?.value || "",
                                })
                              }
                              value={
                                statusDttot
                                  .map(item => {
                                    return {
                                      label: item.title,
                                      value: item.value,
                                    }
                                  })
                                  .find(
                                    item => item.value === filter.statusInternal
                                  ) || null
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Persetujuan Oleh</Label>
                            <AsyncSelect
                              cacheOptions
                              isClearable
                              loadOptions={loadOptions}
                              defaultOptions
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  creator: e,
                                })
                              }}
                              value={filter.creator}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Tanggal Awal</Label>
                            <Input
                              type="date"
                              value={filter.startDate}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  startDate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <Label>Tanggal Akhir</Label>
                            <Input
                              type="date"
                              value={filter.endDate}
                              onChange={e =>
                                setFilter({
                                  ...filter,
                                  endDate: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <DataTable
                        table={tableHistories}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        loading={load}
                        order={filter.order}
                        orderClick={handleOrder}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(DTTOT)
