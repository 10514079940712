import { getBroadcast, postBroadcast } from "helpers/backend_helpers"
import ModalImage from "react-modal-image"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import DataTable from "components/Common/DataTable"

const BroadcastNotification = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [broadcasts, setBroadcasts] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const [modal, setModal] = useState(null)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)
  const [keySearch, setKeySearch] = useState("title")
  const [search, setSearch] = useState("")

  const [file, setFile] = useState(null)

  const toggleModal = () => {
    setFile(null)
    setModal(!modal)
  }

  useEffect(() => {
    getData()
  }, [page])

  const getData = async () => {
    setLoad(true)
    const data = await getBroadcast(
      `?per_page=${perPage}&page=${page}&keySearch=${keySearch}&search=${search}`,
      token
    )
    setTotalPage(data.meta.pagination.last_page)
    setCurrentPage(data.meta.pagination.current_page)
    setPagination(data.meta.pagination)
    setBroadcasts(data.data)
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const tableBroadcast = {
    columns: [
      {
        field: "title",
        label: "Judul",
        sort: "asc",
      },
      {
        field: "content",
        label: "Pesan",
        sort: "asc",
        width: 200,
      },
      {
        field: "image",
        label: "Gambar",
      },
      {
        field: "created_at",
        label: "Tanggal Penyiaran",
      },
    ],
    rows: broadcasts.map(item => {
      return {
        ...item,
        created_at: item.created_at
          ? moment(item.created_at).format("DD MMM YYYY")
          : "-",
        image: item.image ? (
          <ModalImage
            small={API_URL + item.image}
            large={API_URL + item.image}
            alt="broadcast-image"
            className="image-broadcast"
          />
        ) : (
          "-"
        ),
      }
    }),
  }

  const handleSubmitBroadcast = async (e, value) => {
    const newValue = {
      ...value,
      image: file,
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postBroadcast(formData, token)
      toggleModal()
      setLoadButt(false)
      getData()
      Toaster("success", "Sukses", "Berhasil Dikirim")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalBroadcast = () => {
    return (
      <Modal isOpen={modal} role="dialog" autoFocus={true} scrollable>
        <ModalHeader toggle={() => toggleModal()}>Penyiaran</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitBroadcast(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Judul"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Judul harus diisi",
                },
              }}
            />

            <PInput
              name="content"
              type="textarea"
              label="Pesan"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Pesan harus diisi",
                },
              }}
            />

            <PInput
              name="image"
              type="file"
              label="Gambar"
              helpMessage="jpg, jpeg atau png"
              onChange={e => setFile(e.target.files[0])}
              value={file}
              accept=".jpg, .jpeg, .png"
            />

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Kirim Siaran"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading />

  return (
    <React.Fragment>
      {renderModalBroadcast()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Penyiaran" breadcrumbItem="Penyiaran" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Riwayat Penyiaran
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModal}>+ Buat Siaran</Button>
                      </div>

                      <DataTable
                        table={tableBroadcast}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        search={search}
                        handleSearch={setSearch}
                        keySearch={keySearch}
                        setKeySearch={setKeySearch}
                        dataKey={["title"]}
                        dataKeyCustom={[{ value: "title", label: "Judul" }]}
                        handleGetSearch={getData}
                        searching={true}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BroadcastNotification
