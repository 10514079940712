import PropTypes from "prop-types"
import React, { useEffect } from "react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// Redux
import { connect } from "react-redux"

// Redux Store
import {
  // showRightSidebarAction,
  // toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const SidebarContent = props => {
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      return false
    }
    return false
  }

  const menu = [
    {
      link: "/profile",
      icon: "bx bxs-user-circle",
      name: "Profil",
      access: ["Lender", "Borrower"],
    },
    {
      link: "/portfolio",
      icon: "bx bx-home",
      name: "Portfolio",
      access: ["Lender", "Borrower"],
    },
    {
      link: "/daftar-kampanye",
      icon: "bx bx-chevron-left-square",
      name: "Daftar Kampanye",
      access: ["Lender", "Borrower"],
    },
    {
      link: "/apply-project",
      icon: "bx bxs-eraser",
      name: "Ajukan Pembiayaan",
      access: ["Lender", "Borrower"],
    },
    {
      link: "/proyeks",
      icon: "bx bx-briefcase-alt",
      name: "Proyek Saya",
      access: ["Lender", "Borrower"],
    },
    {
      link: "/approval-user",
      icon: "bx bx-calendar",
      name: "Pengguna dan KYC",
      access: ["Moderator", "Marketing"],
    },
    {
      link: "/approval-project",
      icon: "bx bx-calendar",
      name: "Proyek",
      access: ["Moderator", "Bisnis"],
    },
    {
      link: "/campaign",
      icon: "bx bx-calendar",
      name: "Kelola Kampanye",
      access: ["Moderator", "Marketing"],
    },
    {
      link: "/akad",
      icon: "bx bx-calendar",
      name: "Akad",
      access: ["Moderator", "Legal"],
    },
    {
      link: "/transaksi",
      icon: "bx bx-calendar",
      name: "Transaksi",
      access: ["Moderator", "Finance"],
    },
    {
      link: "/roles",
      icon: "bx bx-calendar",
      name: "Peran",
      access: ["Moderator"],
    },
    // {
    //   link: "/pesan",
    //   icon: "bx bx-calendar",
    //   name: "Pesan",
    //   access: ["Moderator", "Lender", "Borrower"]
    // },
    {
      link: "/investment",
      icon: "bx bx-calendar",
      name: "Pemberi Pembiayaan",
      access: ["Moderator", "Marketing"],
    },
    {
      link: "/risk",
      icon: "bx bx-calendar",
      name: "Analisis Resiko",
      access: ["Moderator", "Risk"],
    },
    {
      link: "/collection",
      icon: "bx bx-calendar",
      name: "Penagihan",
      access: ["Moderator", "Collection", "Finance", "Marketing"],
    },
  ]

  const menuNew = [
    {
      link: "/beranda",
      icon: "bx bxs-dashboard",
      name: "Beranda",
      access: [
        "Lender",
        "Borrower",
        "Moderator",
        "Legal",
        "Finance",
        "Publisher",
        "Marketing",
        "Bisnis",
        "Risk",
        "Collection",
      ],
    },
    {
      link: "/profile",
      icon: "bx bxs-user-circle",
      name: "Profil",
      access: ["Lender", "Borrower"],
    },
    {
      link: "/portfolio?page=1&perPage=5",
      icon: "bx bx-home",
      name: "Portofolio",
      access: ["Lender"],
    },
    {
      link: "/daftar-kampanye",
      icon: "bx bx-store",
      name: "Daftar Kampanye",
      access: ["Lender"],
    },
    {
      link: "/apply-project",
      icon: "bx bxs-eraser",
      name: "Ajukan Pembiayaan",
      access: ["Borrower"],
    },
    {
      link: "/proyeks",
      icon: "bx bx-briefcase-alt",
      name: "Proyek Saya",
      access: ["Borrower"],
    },
    // {
    //   link: "/dashboard",
    //   icon: "bx bxs-dashboard",
    //   name: "Beranda Admin",
    //   access: ["Moderator", "Legal", "Finance", "Publisher", "Marketing", "Bisnis", "Risk", "Collection"]
    // },
    {
      link: "/#",
      icon: "bx bxs-user-detail",
      name: "Pengguna",
      access: ["Moderator", "Marketing", "Bisnis"],
      item: [
        {
          link: "/approval-user",
          name: "Pengguna dan KYC",
          access: ["Moderator", "Marketing", "Bisnis"],
        },
        {
          link: "/dttot",
          name: "DTTOT",
          access: ["Moderator", "Legal"],
        },
        {
          link: "/pengguna-aktif",
          name: "Pengguna Aktif",
          access: ["Moderator", "Marketing"],
        },
        {
          link: "/voucher",
          name: "Voucher",
          access: ["Moderator", "Marketing"],
        },
        {
          link: "/roles",
          name: "Peran",
          access: ["Moderator"],
        },
        {
          link: "/banned-user",
          name: "Banned",
          access: ["Moderator", "Marketing"],
        },
        {
          link: "/refferer",
          name: "Rujukan",
          access: ["Moderator", "Marketing"],
        },
      ],
    },
    {
      link: "/#",
      icon: "bx bx-briefcase-alt-2",
      name: "Proyek",
      access: [
        "Moderator",
        "Bisnis",
        "Risk",
        "Collection",
        "Marketing",
        "Finance",
      ],
      item: [
        {
          link: "/approval-project",
          name: "Persetujuan Proyek",
          access: ["Moderator", "Bisnis"],
        },
        {
          link: "/risk",
          name: "Analisis Resiko",
          access: ["Moderator", "Risk"],
        },
        {
          link: "/collection",
          name: "Penagihan",
          access: ["Moderator", "Collection", "Finance", "Marketing"],
        },
      ],
    },
    {
      link: "/#",
      icon: "bx bxs-detail",
      name: "Kampanye",
      access: ["Moderator", "Marketing", "Bisnis"],
      item: [
        {
          link: "/campaign",
          name: "Kelola Kampanye",
          access: ["Moderator", "Marketing", "Bisnis"],
        },
        {
          link: "/sort-campaign",
          name: "Sorting Kampanye",
          access: ["Moderator"],
        },
        // {
        //   link: "/akad",
        //   name: "Akad",
        //   access: ["Moderator", "Legal"]
        // },
      ],
    },
    {
      link: "/#",
      icon: "bx bx-money",
      name: "Pembiayaan",
      access: ["Moderator", "Marketing", "Finance"],
      item: [
        {
          link: "/transaksi",
          name: "Transaksi",
          access: ["Moderator", "Finance"],
        },
        {
          link: "/investment",
          name: "Pemberi Pembiayaan",
          access: ["Moderator", "Marketing", "Finance"],
        },
        {
          link: "/approval-payout",
          name: "Persetujuan Pengembalian",
          access: ["Moderator", "Finance"],
        },
        {
          link: "/transfer-rdf",
          name: "Transfer RDF ke Escrow",
          access: ["Moderator", "Finance"],
        },
      ],
    },
    // {
    //   link: "/daftar-akad",
    //   icon: "bx bx-briefcase-alt",
    //   name: "Daftar Akad",
    //   access: ["Moderator", "Legal"]
    // },
    {
      link: "/#",
      icon: "bx bx-list-ul",
      name: "Akad",
      access: ["Moderator", "Legal", "Director"],
      item: [
        {
          link: "/akad",
          name: "Kelola Akad",
          access: ["Moderator", "Legal"],
        },
        {
          link: "/daftar-akad",
          name: "Daftar Akad",
          access: ["Moderator", "Legal", "Director"],
        },
        // {
        //   link: "/ttd-wakalah-ethis",
        //   name: "TTD Wakalah ETHIS",
        //   access: ["Moderator", "Director"]
        // },
      ],
    },
    {
      link: "/#",
      icon: "bx bx-message-dots",
      name: "Pengaduan",
      access: [
        "Moderator",
        "Legal",
        "Finance",
        "Publisher",
        "Marketing",
        "Bisnis",
        "Risk",
        "Collection",
        "Director",
      ],
      item: [
        {
          link: "/kelola-pengaduan",
          name: "Kelola Pengaduan",
          access: ["Moderator", "Marketing"],
        },
        {
          link: "/pengaduan",
          name: "Pengaduan",
          access: [
            "Moderator",
            "Legal",
            "Finance",
            "Publisher",
            "Marketing",
            "Bisnis",
            "Risk",
            "Collection",
            "Director",
          ],
        },
      ],
    },
    {
      link: "/#",
      icon: "bx bx-message-square-minus",
      name: "Kelola Halaman",
      access: ["Moderator", "Marketing"],
      item: [
        {
          link: "/kelola-homepage",
          name: "Home",
          access: ["Moderator", "Marketing"],
        },
        {
          link: "/manage-blog",
          name: "Blog",
          access: ["Moderator", "Marketing"],
        },
        {
          link: "/manage-webinar",
          name: "Webinar",
          access: ["Moderator", "Marketing"],
        },
      ],
    },
    {
      link: "/unduh",
      icon: "bx bxs-report",
      name: "Laporan",
      access: ["Moderator", "Marketing", "Finance"],
    },
    {
      link: "/mailchimp",
      icon: "bx bxl-mailchimp",
      name: "Mailchimp",
      access: ["Moderator", "Marketing"],
    },
    {
      link: "/setting",
      icon: "bx bx-message-square-x",
      name: "Pengaturan",
      access: ["Moderator", "Marketing"],
    },
    {
      link: "/broadcast-notification",
      icon: "bx bx-broadcast",
      name: "Penyiaran",
      access: ["Moderator", "Marketing"],
    },
  ]

  const handleRoles = names => {
    let compare = props.user.user_role.map(item => item.role.title)
    for (let i = 0; i < names.length; i++) {
      if (compare.includes(names[i])) {
        return true
      }
    }
    return false
  }

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const renderMenu = () => {
    return menuNew.map(item => {
      if (item.link !== "/#" && handleRoles(item.access)) {
        return (
          <li onClick={() => props.changeSidebarType("default", isMobile)}>
            <Link to={item.link} className=" waves-effect">
              <i className={item.icon}></i>
              <span>{props.t(item.name)}</span>
            </Link>
          </li>
        )
      }

      if (item.link === "/#" && handleRoles(item.access)) {
        return (
          <li>
            <Link to={item.link} className="has-arrow waves-effect">
              <i className={item.icon}></i>
              <span>{props.t(item.name)}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              {item.item.map(innerItem => {
                if (handleRoles(innerItem.access)) {
                  return (
                    <li
                      onClick={() =>
                        props.changeSidebarType("default", isMobile)
                      }
                    >
                      <Link to={innerItem.link}>{props.t(innerItem.name)}</Link>
                    </li>
                  )
                }
              })}
            </ul>
          </li>
        )
      }
    })
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("Menu")} </li>
          {renderMenu()}
        </ul>
      </div>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(
  connect(mapStateToProps, { changeSidebarType })(
    withTranslation()(SidebarContent)
  )
  // withTranslation()(SidebarContent)
)
