import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const checkRedirect = (arr) => {
  if(arr.includes("Moderator")) return "/dashboard"
  if(arr.includes("Marketing")) return "/dashboard"
  if(arr.includes("Legal")) return "/dashboard"
  if(arr.includes("Bisnis")) return "/dashboard"
  if(arr.includes("Legal")) return "/dashboard"
  if(arr.includes("Finance")) return "/dashboard"
  if(arr.includes("Risk")) return "/dashboard"
  if(arr.includes("Collection")) return "/dashboard"
  if(arr.includes("Lender")) return "/beranda"
  if(arr.includes("Borrower")) return "/beranda"
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isPageAuth,
  loggingRole,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("token")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      if (!isAuthProtected && localStorage.getItem("token") && isPageAuth) {
        return (
          <Redirect
            to={{ pathname: "/beranda", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
