import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ModalImage from "react-modal-image"
import PuffLoader from "react-spinners/PuffLoader"
import {
  Button,
  Card,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import { API_PEFINDO, API_URL } from "helpers/api_url"
import PInput from "components/Input/PInput"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import axios from "axios"
import Toaster from "components/Common/Toast"
import { MDBDataTable } from "mdbreact"
import moment from 'moment'
import classnames from "classnames"
import Loading from "pages/Utility/Loading"

const ModalPefindo = props => {
  const {isOpen, toggle } = props
  const project = props.item

  const [dataPefindo, setDataPefindo] = useState(null)
  const [pageResult, setPageResult] = useState(false)
  const [dataScore, setDataScore] = useState([])
  const [dataContract, setDataContract] = useState([])
  const [pefId, setPefId] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const [load, setLoad] = useState(false)
  
  // console.log(dataPefindo)

  useEffect(() => {
    if(project) {
      getDataPefindo()
      getDetailPefindo()
    }
  }, [project, pefId])

  const getDataPefindo = async () => {
    setLoad(true)
    const query = `?name=${project.project_company.company_name}&npwp=${project.project_company.npwp_company_number}&reason=OperationalRiskManagement&reasontext=`
    try {
      const data = await axios.post(`${API_PEFINDO}/api/company${query}`, null)
      setDataPefindo(data.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      // Toaster("error", "Ups", "Gagal ambil data pefindo")
    }
  }

  const getDetailPefindo = async () => {
    if(pefId) {
      setLoad(true)
      const query = `?pefindoid=${pefId}&reason=OperationalRiskManagement&reasontext=&subject=Company`
      try {
        const data = await axios.post(`${API_PEFINDO}/api/company/score${query}`, null)
        setDataScore(data.data.GetCustomReportResult.CIP.RecordList.Record)
        if(Array.isArray(data.data.GetCustomReportResult.ContractOverview.ContractList.Contract)) {
          setDataContract(data.data.GetCustomReportResult.ContractOverview.ContractList.Contract)
        } else {
          setDataContract([data.data.GetCustomReportResult.ContractOverview.ContractList.Contract])
        }
        setLoad(false)
      } catch (error) {
        setLoad(false)
        // Toaster("error", "Ups", "Gagal ambil data pefindo")
      }
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleDataPefindo = (data) => {
    if(!data) return []
    if(!data.SmartSearchCompanyResult) return []
    if(data.SmartSearchCompanyResult.Status === "SubjectNotFound") return []
    if(Array.isArray(data.SmartSearchCompanyResult.CompanyRecords.SearchCompanyRecord)) {
      return data.SmartSearchCompanyResult.CompanyRecords.SearchCompanyRecord
    }
    return [data.SmartSearchCompanyResult.CompanyRecords.SearchCompanyRecord]
  }

  const handleAction = (idPef) => {
    setPefId(idPef)
    setPageResult(true)
  }

  const pefindoId = {
    columns: [
      {
        label: "Nama Perusahaan",
        field: "CompanyName",
      },
      {
        label: "NPWP Perusahaan",
        field: "NPWP",
      },
      {
        label: "Alamat Perusahaan",
        field: "Address",
      },
      {
        label: "Pefindo Skor",
        field: "action",
      },
    ],
    rows: handleDataPefindo(dataPefindo).map(item => {
      return {
        ...item,
        action: <Button
                  className="btn-sm btn-rounded"
                  color="primary"
                  onClick={() => handleAction(item.PefindoId)}
                >
                  Lihat
                </Button>,
      }
    })
  }

  const handleToggleAllClose = () => {
    setDataPefindo(null)
    setPageResult(false)
    setDataScore([])
    setPefId(null)
    toggle(null)
  }

  const renderContent = () => {
    return (
      <div className="mt-4">
        {pageResult ?

          <div>
            <ul className="nav nav-tabs nav-tabs-custom mb-4" role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  Scoring
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  Contract Overview
                </NavLink>
              </NavItem>
              <Button className="ml-auto" onClick={() => setPageResult(false)}>Kembali</Button>
            </ul>

            <TabContent activeTab={activeTab} className="">
              <TabPane tabId="1" id="all-order">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Grade</th>
                      <th scope="col">Probability Of Default</th>
                      <th scope="col">Reason Code</th>
                      <th scope="col">Reason Description</th>
                      <th scope="col">Score</th>
                      <th scope="col">Trend</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataScore.map(item => {
                      return (
                        <tr>
                          <th>{moment(item.Date).format("DD MMM YYYY")}</th>
                          <td>{item.Grade}</td>
                          <td>{item.ProbabilityOfDefault}</td>
                          <td>{item.ReasonsList.Reason.Code}</td>
                          <td>{item.ReasonsList.Reason.Description || "-"}</td>
                          <td>{item.Score}</td>
                          <td>{item.Trend}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </TabPane>
              <TabPane tabId="2" id="contract">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sektor</th>
                      <th scope="col">Tipe</th>
                      <th scope="col">Dibuka</th>
                      <th scope="col">Status</th>
                      <th scope="col">Total</th>
                      <th scope="col">Saldo</th>
                      <th scope="col">Past Due Amount</th>
                      <th scope="col">Tunggakan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataContract.map(item => {
                      return (
                        <tr>
                          <th>{item.Sector}</th>
                          <td>{item.TypeOfContract}</td>
                          <td>{moment(item.StartDate).format("DD MMM YYYY")}</td>
                          <td>{item.ContractStatus}</td>
                          <td>{item.TotalAmount.Currency} {parseInt(item.TotalAmount.Value).toLocaleString()}</td>
                          <td>{item.OutstandingAmount.Currency} {parseInt(item.OutstandingAmount.Value).toLocaleString()}</td>
                          <td>{!item.PastDueAmount ? "-" : `${item.PastDueAmount.Currency} ${item.PastDueAmount.Value}`}</td>
                          <td>{item.PastDueDays} Hari</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </TabPane>
            </TabContent>
          </div>
          
          :
          <MDBDataTable
            responsive
            bordered
            data={pefindoId}
            striped
            // className="mt-4"
          />
        }
      </div>
    )
  }


  // console.log(dataScore)

  if(!project) return null

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      // centered={true}
      className="exampleModal h-100"
      tabindex="-1"
      // toggle={toggle}
      scrollable={true}
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={handleToggleAllClose}>Proyek {project && project.title}</ModalHeader>
        <ModalBody>
          <h6>Nama Perusahaan : {project.project_company.business_entity.title} {project.project_company.company_name}</h6>
          <h6>Nomor NPWP : {project.project_company.npwp_company_number}</h6>

          {load ? 
            <div style={{height: "50vh"}} className="d-flex justify-content-center align-items-center">
              <PuffLoader color={"#0ec6a2"} loading={true} size={120} />
            </div>
            : renderContent()
          }

        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalPefindo