import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"
import { connect } from "react-redux"

import { MDBDataTable } from "mdbreact"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import { getCampaignSelector, getUserVerified } from "helpers/backend_helpers"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import moment from "moment"
import { API_URL_1 } from "helpers/api_url"
import axios from "axios"

const Downloads = props => {
  const token = localStorage.getItem("token")
  const [activeTab, setActiveTab] = useState("1")
  const [load, setLoad] = useState(false)
  const [users, setUsers] = useState([])
  const [loadButt, setLoadButt] = useState(false)
  const [isAsuransi, setIsAsuransi] = useState(false)
  const [campaigns, setCampaigns] = useState([])

  const [file, setFile] = useState(null)
  const [loadButtFile, setLoadButtFile] = useState(false)

  useEffect(async () => {
    const data = await getCampaignSelector(token)
    setCampaigns(data.data)
  }, [])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleSearch = async (e, value) => {
    var dateFrom = new Date(value.dateOne)
    var dateTo = new Date(value.dateTwo)
    var firstDay = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1)
    var lastDay = new Date(dateTo.getFullYear(), dateTo.getMonth() + 1, 0)

    let query = ""
    let queryAsuransi = ""

    if (value.dateOne && value.dateTwo) {
      query = `?from=${moment(firstDay).format("YYYY-MM-DD")}&&to=${moment(
        lastDay
      ).format("YYYY-MM-DD")}`

      queryAsuransi = `?trx_from_date=${moment(firstDay).format(
        "YYYY-MM-DD"
      )}&&trx_to_date=${moment(lastDay).format("YYYY-MM-DD")}`
    }

    if (value.name === "users") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/user/export${query}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=users.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `users.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name === "transactions") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/report/ojk/monthly${query}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=transactions.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `transactions.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name === "sigap") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/user/export/with-npwp${query}&forOjk=true`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=sigap_ojk.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `sigap_ojk.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name === "users_npwp") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/user/export/with-npwp${query}&forOjk=false`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=users_with_npwp.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `users_with_npwp.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name === "asuransi") {
      setLoadButt(true)
      axios
        .get(
          `${API_URL_1}/report/campaign/${value.project}/third-party/asuransi${queryAsuransi}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Disposition": "attachment; filename=data_asuransi.xlsx",
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: "arraybuffer",
          }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `data_asuransi.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    }
  }

  const handleRoles = () => {
    let result = []
    props.user.user_role.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  const handleSelectDownload = () => {
    let moderator = [
      { uuid: "users", title: "Data Pengguna" },
      { uuid: "transactions", title: "Data Transaksi" },
      { uuid: "sigap", title: "Data Sigap OJK" },
      { uuid: "users_npwp", title: "Data Pengguna dengan NPWP" },
      { uuid: "asuransi", title: "Data Asuransi" },
    ]
    let result = []
    if (handleRoles().includes("Moderator")) return moderator
    if (handleRoles().includes("Finance")) {
      result.push({ uuid: "transactions", title: "Data Transaksi" })
    }
    if (handleRoles().includes("Marketing")) {
      result.push({ uuid: "users", title: "Data Pengguna" })
    }
    if (handleRoles().includes("Legal")) {
      result.push({ uuid: "sigap", title: "Data Sigap OJK" })
      result.push({ uuid: "users_npwp", title: "Data Pengguna dengan NPWP" })
      result.push({ uuid: "asuransi", title: "Data Asuransi" })
    }
    return result
  }

  const handleDownloadPusdafil = async (e, value) => {
    if (value.name == "users") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/user/export/reg-pengguna`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=reg_pengguna.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `reg_pengguna.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name == "borrower") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/user/export/reg-borrower`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=reg_borrower.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `reg_borrower.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name == "lender") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/user/export/reg-lender`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=reg_lender.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `reg_lender.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name == "pengajuanPinjaman") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/projects/export/pengajuan-pinjaman`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition":
              "attachment; filename=pengajuan_pinjaman.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `pengajuan_pinjaman.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name == "pengajuanPemberianPinjaman") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/campaigns/export/pengajuan-pemberian-pinjaman`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition":
              "attachment; filename=pengajuan_pemberian_pinjaman.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `pengajuan_pemberian_pinjaman.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name == "pinjamMeminjam") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/campaigns/export/pinjam-meminjam`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=pinjam_meminjam.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `pinjam_meminjam.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    } else if (value.name == "pembayaranPinjaman") {
      setLoadButt(true)
      axios
        .get(`${API_URL_1}/campaigns/export/pembayaran-pinjaman`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition":
              "attachment; filename=pembayaran_pinjaman.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `pembayaran_pinjaman.xlsx`)
          document.body.appendChild(link)
          link.click()
          setLoadButt(false)
        })
        .catch(err => {
          setLoadButt(false)
          Toaster("error", "Ups", err.response.data.message)
        })
    }
  }

  const handleDownloadFDC = async () => {
    setLoadButt(true)
    axios
      .get(`https://inquiry-report.ethis.co.id/api/fdc/results/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "FDC-secret-key": "105yoiRc18cDoFZfaxVuIbTQQ2MXQokX",
        },
        responseType: "blob",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `result-fdc.zip`)
        document.body.appendChild(link)
        link.click()
        setLoadButt(false)
      })
      .catch(err => {
        setLoadButt(false)
        Toaster("error", "Maaf", err?.response?.data?.message)
      })
  }

  const handleUploadFDC = async () => {
    if (!file) return Toaster("error", "Maaf", "Mohon unggah file FDC")

    const formData = new FormData()
    formData.append("file", file)
    const token = localStorage.getItem("token")
    setLoadButtFile(true)
    try {
      await axios.post(
        "https://inquiry-report.ethis.co.id/api/fdc/upload/zip ",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "FDC-secret-key": "105yoiRc18cDoFZfaxVuIbTQQ2MXQokX",
          },
        }
      )
      setFile(null)
      setLoadButtFile(false)
      Toaster("success", "Sukses", "Berhasil unggah data FDC")
    } catch (error) {
      setLoadButtFile(false)
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    }
  }

  if (load) return <Loading />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Laporan" breadcrumbItem="Laporan" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Unduhan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Laporan Pusdafil
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                          setFile(null)
                        }}
                      >
                        Laporan FDC
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="1">
                      <div className="mt-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleSearch(e, v)
                          }}
                        >
                          <Row>
                            <Col lg="6">
                              <PInput
                                name="dateOne"
                                label="Tanggal Awal"
                                type="month"
                                validate={{
                                  required: {
                                    value: isAsuransi ? false : true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="dateTwo"
                                label="Tanggal Akhir"
                                type="month"
                                validate={{
                                  required: {
                                    value: isAsuransi ? false : true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="name"
                                label="Data"
                                type="select"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                                onChange={e => {
                                  if (e.target.value == "asuransi") {
                                    setIsAsuransi(true)
                                  } else {
                                    setIsAsuransi(false)
                                  }
                                }}
                                options={handleSelectDownload()}
                              />
                            </Col>
                            {isAsuransi && (
                              <Col lg="6">
                                <PInput
                                  name="project"
                                  label="Proyek"
                                  type="select"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Field harus diisi",
                                    },
                                  }}
                                  options={campaigns}
                                />
                              </Col>
                            )}
                          </Row>
                          <div className="mt-2">
                            <button
                              className="btn btn-primary waves-effect waves-light d-flex align-items-center"
                              type="submit"
                              disabled={loadButt}
                            >
                              {loadButt ? (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                  Loading
                                </>
                              ) : (
                                <>
                                  <i className="bx bxs-download mr-1"></i>{" "}
                                  Download
                                </>
                              )}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                      {/* <MDBDataTable
                        responsive
                        bordered
                        data={dataUser}
                        striped
                        className="mt-5"
                      /> */}
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="mt-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleDownloadPusdafil(e, v)
                          }}
                        >
                          <Row>
                            <Col lg="6">
                              <PInput
                                name="name"
                                label="Data"
                                type="select"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                                options={[
                                  { uuid: "users", title: "Reg Pengguna" },
                                  { uuid: "borrower", title: "Reg Borrower" },
                                  { uuid: "lender", title: "Reg Lender" },
                                  {
                                    uuid: "pengajuanPinjaman",
                                    title: "Pengajuan Pinjaman",
                                  },
                                  {
                                    uuid: "pengajuanPemberianPinjaman",
                                    title: "Pengajuan Pemberian Pinjaman",
                                  },
                                  {
                                    uuid: "pinjamMeminjam",
                                    title: "Pinjam Meminjam",
                                  },
                                  {
                                    uuid: "pembayaranPinjaman",
                                    title: "Pembayaran Pinjaman",
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <button
                              className="btn btn-primary waves-effect waves-light d-flex align-items-center"
                              type="submit"
                              disabled={loadButt}
                            >
                              {loadButt ? (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                  Loading
                                </>
                              ) : (
                                <>
                                  <i className="bx bxs-download mr-1"></i>{" "}
                                  Download
                                </>
                              )}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col lg="12" className="mb-4">
                          <div className="border p-3">
                            <div className="mb-2">Unduh Hasil Laporan FDC</div>
                            <Button
                              color="primary"
                              className="w-50"
                              disabled={loadButt}
                              onClick={handleDownloadFDC}
                            >
                              {loadButt ? (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                  Loading
                                </>
                              ) : (
                                <>
                                  <i className="bx bxs-download mr-1"></i> Unduh
                                </>
                              )}
                            </Button>
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="border p-3">
                            <div className="mb-2">Unggah Data Laporan FDC</div>
                            <div className="custom-file mb-2 w-50">
                              <input
                                type="file"
                                className="custom-file-input cp-custom"
                                id="customFile"
                                onChange={e =>
                                  setFile(e.target.files?.[0] || null)
                                }
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFile"
                                style={{ color: "#636c72" }}
                              >
                                {file ? file.name : "Pilih Berkas"}
                              </label>

                              <Button
                                color="primary"
                                className="mt-3 mb-4 w-100"
                                disabled={loadButtFile}
                                onClick={handleUploadFDC}
                              >
                                {loadButtFile ? (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                    Loading
                                  </>
                                ) : (
                                  <>Simpan</>
                                )}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Downloads)
