import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import EmailVerify from "../pages/Authentication/auth-email-verification"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Profile from "../pages/Profile/index"
import Pages404 from "pages/Utility/pages-404"
import HomePage from "pages/HomePage"
import Users from "pages/Users"
// import Landing from "pages/HomePage/Landing"
import About from "pages/HomePage/About"
import LandingPage from "pages/LandingPage"
import ApplyProject from "pages/ApplyProject"
import Landing from "pages/HomePage/Landing"
import ProjectApproval from "pages/ProjectsApproval"
import Campaign from "pages/Campaign"
import CampaignDetail from "pages/CampaignDetail"
import Akad from "pages/Akad"
import CampaignClient from "pages/CampaignClient"
import DetailCampaign from "pages/CampaignDetailClient"
import HighlightAkad from "pages/HighlightAkad"
import Checkout from "pages/Checkout"
import Portfolio from "pages/Portfolio"
import PortfolioDetail from "pages/PortfolioDetail"
import ForgetPassword from "../pages/Authentication/ForgetPassword"
import CreatePassword from "pages/Authentication/CreatePassword"
import Payout from "pages/Payout"
import Roles from "pages/Roles"
import UserInvestment from "pages/PemberiPembiayaan"
import ProjectList from "pages/ProjectList"
import FormUkm from "pages/FormUkm"
import FormProperties from "pages/FormProperty"
import Risk from "pages/Risk"
import Dasbor from "pages/Dasbor"
import DetailProject from "pages/DetailProjectCampaign"
import Chat from "pages/Chat"
import Collection from "pages/Collection"
import DaftarAkad from "pages/DaftarAkad"
import SortCampaign from "pages/SortCampaign"
import PortfolioTransaction from "pages/PortfolioTransaction"
import UserActive from "pages/UserActive"
import EthisSignature from "pages/EthisSignature"
import Downloads from "pages/Downloads"
import Notifications from "pages/Notifications"
import Voucher from "pages/Voucher"
import AboutMe from "pages/NonAuthPages/AboutMe"
import StatementRisk from "pages/NonAuthPages/StatementRisk"
import Procedure from "pages/NonAuthPages/Procedure"
import Governance from "pages/NonAuthPages/Governance"
import ReportFinance from "pages/NonAuthPages/ReportFinance"
import Faq from "pages/NonAuthPages/Faq"
import TermsConditions from "pages/NonAuthPages/TermsConditions"
import Privacy from "pages/NonAuthPages/Privacy"
import Webinar from "pages/NonAuthPages/Webinar"
import Ebook from "pages/NonAuthPages/Ebook"
import Funding from "pages/NonAuthPages/Funding"
import HowToFunding from "pages/NonAuthPages/HowToFunding"
import Cost from "pages/NonAuthPages/Cost"
import Blogs from "pages/NonAuthPages/Blog"
import Contract from "pages/NonAuthPages/Contract"
import ManageHomePage from "pages/ManageHomePage"
import Blog from "pages/Blog"
import DetailBlog from "pages/NonAuthPages/DetailBlog"
import ManageWebinar from "pages/Webinar"
import HowToFinancing from "pages/NonAuthPages/HowToFinancing"
import Complaint from "pages/NonAuthPages/Complaint"
import PortofolioDetailTransaction from "pages/PortofolioDetailTransaction"
import Setting from "pages/Settings"
import Components from "pages/NonAuthPages/Components"
import DetailWebinar from "pages/NonAuthPages/DetailWebinar"
import BannedUser from "pages/BannedUser"
import Haki from "pages/NonAuthPages/Haki"
import HelpdeskIn from "pages/HelpdeskIn"
import Helpdesk from "pages/Helpdesk"
import MailChimp from "pages/MailChimp"
import BroadcastNotification from "pages/BroadcastNotification"
import TransferToEscrow from "pages/TransferToEscrow"
import ApprovalPayout from "pages/ApprovalPayout"
import DTTOT from "pages/DTTOT"
import Refferer from "pages/Refferer"

const userRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    roles: [
      "Moderator",
      "Legal",
      "Finance",
      "Publisher",
      "Marketing",
      "Bisnis",
      "Risk",
      "Collection",
      "Director",
    ],
  },
  { path: "/profile", component: Profile, roles: ["Lender", "Borrower"] },
  {
    path: "/approval-user",
    component: Users,
    roles: ["Moderator", "Marketing", "Bisnis"],
  },
  { path: "/apply-project", component: ApplyProject, roles: ["Borrower"] },
  {
    path: "/approval-project",
    component: ProjectApproval,
    roles: ["Moderator", "Bisnis"],
  },
  {
    path: "/campaign",
    component: Campaign,
    roles: ["Moderator", "Marketing", "Bisnis"],
  },
  {
    path: "/campaign/:uuid",
    component: CampaignDetail,
    roles: ["Moderator", "Marketing", "Bisnis"],
  },
  { path: "/akad", component: Akad, roles: ["Moderator", "Legal"] },
  { path: "/daftar-kampanye", component: CampaignClient, roles: ["Lender"] },
  {
    path: "/detail-kampanye/:uuid",
    component: DetailCampaign,
    roles: ["Lender"],
  },
  { path: "/highlight", component: HighlightAkad, roles: ["Lender"] },
  // { path: "/checkout" , component: Checkout },
  { path: "/portfolio", component: Portfolio, roles: ["Lender"] },
  {
    path: "/portfolio-transaksi",
    component: PortfolioTransaction,
    roles: ["Lender"],
  },
  { path: "/portfolio/:uuid", component: PortfolioDetail, roles: ["Lender"] },
  { path: "/transaksi", component: Payout, roles: ["Moderator", "Finance"] },
  { path: "/roles", component: Roles, roles: ["Moderator"] },
  {
    path: "/investment",
    component: UserInvestment,
    roles: ["Moderator", "Marketing", "Finance"],
  },
  { path: "/proyeks", component: ProjectList, roles: ["Borrower"] },
  { path: "/pengajuan-ukm/:uuid", component: FormUkm, roles: ["Borrower"] },
  {
    path: "/pengajuan-properti/:uuid",
    component: FormProperties,
    roles: ["Borrower"],
  },
  { path: "/risk", component: Risk, roles: ["Moderator", "Risk"] },
  {
    path: "/proyek-detail/:uuid",
    component: DetailProject,
    roles: ["Borrower"],
  },
  {
    path: "/collection",
    component: Collection,
    roles: ["Moderator", "Collection", "Finance", "Marketing"],
  },
  // { path: "/pesan" , component: Chat, roles: ["Moderator", "Lender", "Borrower"]  },
  {
    path: "/beranda",
    component: Dasbor,
    roles: [
      "Lender",
      "Borrower",
      "Moderator",
      "Legal",
      "Finance",
      "Publisher",
      "Marketing",
      "Bisnis",
      "Risk",
      "Collection",
      "Director",
    ],
  },
  {
    path: "/daftar-akad",
    component: DaftarAkad,
    roles: ["Moderator", "Legal", "Director"],
  },
  { path: "/sort-campaign", component: SortCampaign, roles: ["Moderator"] },
  {
    path: "/pengguna-aktif",
    component: UserActive,
    roles: ["Moderator", "Marketing"],
  },
  {
    path: "/unduh",
    component: Downloads,
    roles: ["Moderator", "Marketing", "Finance"],
  },
  {
    path: "/notification",
    component: Notifications,
    roles: [
      "Lender",
      "Borrower",
      "Moderator",
      "Legal",
      "Finance",
      "Publisher",
      "Marketing",
      "Bisnis",
      "Risk",
      "Collection",
      "Director",
    ],
  },
  { path: "/voucher", component: Voucher, roles: ["Moderator", "Marketing"] },
  {
    path: "/kelola-homepage",
    component: ManageHomePage,
    roles: ["Moderator", "Marketing"],
  },
  { path: "/manage-blog", component: Blog, roles: ["Moderator", "Marketing"] },
  {
    path: "/manage-webinar",
    component: ManageWebinar,
    roles: ["Moderator", "Marketing"],
  },
  {
    path: "/detail-transaksi/:uuid",
    component: PortofolioDetailTransaction,
    roles: ["Lender"],
  },
  { path: "/setting", component: Setting, roles: ["Moderator", "Marketing"] },
  {
    path: "/banned-user",
    component: BannedUser,
    roles: ["Moderator", "Marketing"],
  },
  {
    path: "/kelola-pengaduan",
    component: HelpdeskIn,
    roles: ["Moderator", "Marketing"],
  },
  {
    path: "/pengaduan",
    component: Helpdesk,
    roles: [
      "Moderator",
      "Legal",
      "Finance",
      "Publisher",
      "Marketing",
      "Bisnis",
      "Risk",
      "Collection",
      "Director",
    ],
  },
  {
    path: "/mailchimp",
    component: MailChimp,
    roles: ["Moderator", "Marketing"],
  },
  {
    path: "/broadcast-notification",
    component: BroadcastNotification,
    roles: ["Moderator", "Marketing"],
  },
  {
    path: "/transfer-rdf",
    component: TransferToEscrow,
    roles: ["Moderator", "Finance"],
  },
  {
    path: "/approval-payout",
    component: ApprovalPayout,
    roles: ["Moderator", "Finance"],
  },
  {
    path: "/dttot",
    component: DTTOT,
    roles: ["Moderator", "Legal"],
  },
  {
    path: "/refferer",
    component: Refferer,
    roles: ["Moderator", "Marketing"],
  },
  // { path: "/ttd-wakalah-ethis" , component: EthisSignature, roles: ["Moderator", "Director"] },
  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/verify", component: EmailVerify },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/ubah-password", component: CreatePassword },
  { path: "/not-found", component: Pages404 },
]

const homeRoutes = [
  { path: "/", component: () => <Redirect to="/login" /> },
  // { path: "/", component: LandingPage },
  // { path: "/tentang-kami", component: AboutMe },
  // { path: "/mitigasi-resiko", component: StatementRisk },
  // { path: "/how-it-works", component: Procedure },
  // { path: "/good-corporate-governance", component: Governance },
  // { path: "/laporan-keuangan", component: ReportFinance },
  // { path: "/faq", component: Faq },

  // { path: "/syarat-dan-ketentuan", component: TermsConditions },
  // { path: "/ethis-kebijakan-privasi", component: Privacy },
  // { path: "/webinar", component: Webinar },
  // { path: "/daftar-webinar/:slug", component: DetailWebinar },
  // { path: "/ebook", component: Ebook },
  // { path: "/kampanye", component: Funding },
  // { path: "/cara-mendanai", component: HowToFunding },
  // { path: "/cara-mengajukan-pendanaan", component: HowToFinancing },
  // { path: "/interest-rates-and-fees", component: Cost },
  // { path: "/akad-akad", component: Contract },
  // { path: "/blogs", component: Blogs },
  // { path: "/blog/:slug", component: DetailBlog },
  // { path: "/layanan-pengaduan", component: Complaint },
  // { path: "/haki", component: Haki },
  // { path: "/components",  component: Components},
]

const nonAuthRoutes = [
  // { component: Pages404 },
  { component: () => <Redirect to="/login" /> },
]

export { userRoutes, authRoutes, nonAuthRoutes, homeRoutes }
