import {
  checkBankAccount,
  getBank,
  getBankAccount,
  getRoleAll,
  getUsersAll,
  postImpersonate,
  postRoleUser,
  putBankAccount,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { map } from "lodash"
import { MDBDataTable } from "mdbreact"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import DataTable from "components/Common/DataTable"
import axios from "axios"
import { API_URL, API_URL_1 } from "helpers/api_url"
import moment from "moment"

const Roles = props => {
  const [users, setUsers] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [modal, setModal] = useState(false)
  const [select, setSelect] = useState(null)
  const [roles, setRoles] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)

  const [modalProfile, setModalProfile] = useState(false)
  const [bank, setBank] = useState([])
  const [account, setAccount] = useState(null)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)
  const [keySearch, setKeySearch] = useState("email")
  const [search, setSearch] = useState("")
  const [dataKey, setDataKey] = useState([])

  const [modalDelete, setModalDelete] = useState(false)

  const [dataHistory, setDataHistory] = useState([])

  const toggleModalProfile = (item = null) => {
    setSelect(item)
    setModalProfile(!modalProfile)
  }

  const toggleModalDelete = (item = null) => {
    setSelect(item)
    setModalDelete(!modalDelete)
  }

  const handleCheck = event => {
    let newArray = [...checkedItems, event.target.id]
    if (checkedItems.includes(event.target.id)) {
      newArray = newArray.filter(id => id !== event.target.id)
    }
    setCheckedItems(newArray)
  }

  const handleCheckPerorangan = event => {
    // console.log("perorangan")
    let newArray = [...checkedItems, event.target.id]
    if (checkedItems.includes(event.target.id)) {
      newArray = newArray.filter(id => id !== event.target.id)
    }
    setCheckedItems(newArray)
  }

  const handleCheckInstitusi = event => {
    // console.log("institusi")
    let newArray = [...checkedItems, event.target.id]
    if (checkedItems.includes(event.target.id)) {
      newArray = newArray.filter(id => id !== event.target.id)
    }
    setCheckedItems(newArray)
  }

  const toggleModal = item => {
    if (item) {
      let temp = item.user_role.map(elem => {
        // if (elem.role.title == "Lender")
        //   return item.lenderAsCompany === 0
        //     ? elem.role.uuid + "-perorangan"
        //     : elem.role.uuid + "-institusi"
        return elem.role.uuid
      })
      setCheckedItems(temp)
      setSelect(item)
    }
    setModal(!modal)
  }

  useEffect(() => {
    if (activeTab === "1") {
      getUsers()
    }
  }, [page, perPage, activeTab])

  useEffect(() => {
    getAccountBank()
  }, [select])

  useEffect(() => {
    getHistory()
  }, [activeTab])

  const getHistory = async () => {
    const token = localStorage.getItem("token")
    if (activeTab === "2") {
      setLoad(true)
      const data = await axios.get(`${API_URL_1}/user/delete/history`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      setDataHistory(data.data.data)
      setLoad(false)
    }
  }
  // console.log(dataHistory)

  const getAccountBank = async () => {
    const token = localStorage.getItem("token")
    if (select) {
      try {
        const data = await getBankAccount(select.uuid, token)
        setAccount(data.data)
      } catch (error) {
        // Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const getUsers = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPage}&&page=${page}&&keySearch=${keySearch}&&search=${search}`
    setLoad(true)
    try {
      const users = await getUsersAll(token, query)
      const roles = await getRoleAll(token)
      const banks = await getBank(token)
      setTotalPage(users.meta.pagination.last_page)
      setCurrentPage(users.meta.pagination.current_page)
      setPagination(users.meta.pagination)
      setUsers(users.data)
      if (users.data.length > 0) {
        setDataKey(Object.keys(users.data[0]))
      }
      setRoles(roles.data)
      setBank(banks.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
    }
  }

  const handleImpersonate = async value => {
    const token = localStorage.getItem("token")
    if (
      value.user_role?.length === 1 &&
      value.user_role[0].role?.title === "Borrower"
    ) {
      setLoadButt(true)
      try {
        const data = await postImpersonate(value.uuid, token)
        window.open(
          `${process.env.REACT_APP_BORROWER_URL}/token?token=${data.token}&isImpersonate=true`
        )
        setLoadButt(false)
        return
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
        return
      }
    } else if (
      value.user_role?.length === 1 &&
      (value.user_role[0].role?.title === "Lender" ||
        value.user_role[0].role?.title === "Lender Institusi")
    ) {
      setLoadButt(true)
      try {
        const data = await postImpersonate(value.uuid, token)
        // return console.log(data)
        window.open(
          `${process.env.REACT_APP_LENDER_URL}/token?token=${data.token}&isImpersonate=true`
        )
        setLoadButt(false)
        return
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
        return
      }
    } else {
      setLoadButt(true)
      try {
        const data = await postImpersonate(value.uuid, token)
        localStorage.setItem("tokenImpersonater", token)
        localStorage.setItem("token", data.token)
        window.location.reload()
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const handleStatus = status => {
    if (status === 1) return <Badge color="info">KYC</Badge>
    if (status === 2) return <Badge color="info">KYC</Badge>
    if (status === 3) return <Badge color="info">KYC</Badge>
    if (status === 4) return <Badge color="warning">Verifikasi Admin</Badge>
    if (status === 5) return <Badge color="warning">Verifikasi Privy</Badge>
    if (status === 6) return <Badge color="danger">Ditolak Admin</Badge>
    if (status === 7) return <Badge color="danger">Ditolak Privy</Badge>
    if (status === 8) return <Badge color="danger">Ditolak Privy</Badge>
    if (status === 9) return <Badge color="primary">Disetujui</Badge>
    if (status === 10) return <Badge color="primary">Disetujui</Badge>
  }

  const data = {
    columns: [
      {
        field: "email",
        label: "Email",
        sort: "asc",
      },
      {
        field: "name",
        label: "Nama",
        sort: "asc",
      },
      // {
      //   field: "last_name",
      //   label: "Nama Belakang",
      //   sort: "asc",
      // },
      {
        field: "phone_number",
        label: "Telepon",
        sort: "asc",
      },
      {
        field: "kyc_status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "role",
        label: "Peran",
        sort: "asc",
      },
      {
        field: "edit_role",
        label: "Aksi",
        sort: "asc",
      },
      {
        field: "edit_bank",
        label: "Rekening",
      },
    ],
    rows: users.map(item => {
      return {
        ...item,
        kyc_status: handleStatus(item.kyc_status),
        name: `${item.first_name} ${item.last_name}`,
        role: item.user_role.map((el, i) => {
          // if (el.role.title == "Lender")
          //   return item.lenderAsCompany === 0 ? (
          //     <div>{el.role.title} (Perorangan)</div>
          //   ) : (
          //     <div>{el.role.title} (Institusi)</div>
          //   )
          return <div>{el.role.title}</div>
        }),
        edit_role: (
          <div className="d-flex">
            <Button
              className="btn-sm mr-2"
              color="secondary"
              onClick={() => toggleModal(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              className="btn-sm mr-2"
              color="primary"
              onClick={() => handleImpersonate(item)}
            >
              <i className="bx bx-log-in bx-xs"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => toggleModalDelete(item)}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
        edit_bank: (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggleModalProfile(item)}
            disabled={item.kyc_status < 9}
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const dataRiwayat = {
    columns: [
      {
        field: "name",
        label: "Nama",
        sort: "asc",
      },
      {
        field: "email",
        label: "Email",
        sort: "asc",
      },
      {
        field: "deleted_by",
        label: "Dihapus Oleh",
        sort: "asc",
      },
      {
        field: "deleted_at",
        label: "Tanggal Hapus",
      },
    ],
    rows: dataHistory.map(item => {
      return {
        ...item,
        name: `${item.first_name} ${item.last_name}`,
        deleted_by: item.deleted_by.email,
        deleted_at: moment(item.deleted_at).format("DD MMM YYYY"),
      }
    }),
  }

  const handlesubmit = async () => {
    const token = localStorage.getItem("token")
    // let newRoles = []
    // let lenderAsCompany = false
    // checkedItems.forEach(item => {
    //   let lenderArr = item.split("-")
    //   if (item.includes("institusi")) {
    //     newRoles.push(lenderArr.slice(0, lenderArr.length - 1).join("-"))
    //     lenderAsCompany = true
    //   } else if (item.includes("perorangan")) {
    //     newRoles.push(lenderArr.slice(0, lenderArr.length - 1).join("-"))
    //   } else {
    //     newRoles.push(item)
    //   }
    // })

    setLoadButt(true)
    try {
      await postRoleUser(select.uuid, { roles: checkedItems }, token)
      getUsers()
      toggleModal(null)
      Toaster("success", "Sukses", "Berhasil ubah peran")
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    } finally {
      setLoadButt(false)
    }
  }

  const handleSubmitProfile = async (e, value) => {
    const token = localStorage.getItem("token")
    // setLoadButt(true)
    // try {
    //   await putBankAccount(select.uuid, value, token)
    //   toggleModalProfile(null)
    //   setLoadButt(false)
    //   Toaster("success", "Sukses", "Berhasil ubah rekening")
    // } catch (error) {
    //   setLoadButt(false)
    //   Toaster("error", "Ups", error.response.data.message)
    // }

    const bankAccount = bank.find(item => item.uuid === value.bank_id)

    const newValue = {
      bank_account_number: value.account_number,
      bank_code: bankAccount?.illuma_code,
      fullName: value.name,
    }

    setLoadButt(true)
    try {
      await checkBankAccount(newValue, token)

      let intervalId = setInterval(async () => {
        let result = await checkBankAccount(newValue, token)
        if (
          (result && result.data.status == "SUCCESS") ||
          result.data.status == "FAILED"
        ) {
          clearInterval(intervalId)
          if (
            result.data.status == "SUCCESS" &&
            result.data.name_matching_result == "MATCH"
          ) {
            // match
            await putBankAccount(select.uuid, value, token)
            toggleModalProfile(null)
            setLoadButt(false)
            Toaster("success", "Sukses", "Berhasil ubah rekening")
          } else {
            Toaster(
              "error",
              "Maaf",
              "Nama atau Nomor Rekening Anda tidak ditemukan."
            )
          }
          setLoadButt(false)
        }
      }, 2000)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message)
    }
  }

  const handleDeleteUser = async () => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await axios.delete(`${API_URL_1}/user/delete/${select.uuid}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      toggleModalDelete(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil hapus data")
      getUsers()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // console.log(bank)

  if (load) return <Loading title="Pengguna" breadcrumbItem="Peran" />

  return (
    <React.Fragment>
      <Modal isOpen={modal} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModal(null)}>
          {select && select.email}
        </ModalHeader>
        <ModalBody>
          {select &&
            roles.map(item => {
              // if (item.title == "Lender") {
              //   return (
              //     <>
              //       <div className="custom-control custom-checkbox  custom-checkbox-success mb-3">
              //         <input
              //           type="checkbox"
              //           className="custom-control-input"
              //           id={item.uuid + "-perorangan"}
              //           value={item.uuid + "-perorangan"}
              //           checked={checkedItems.includes(
              //             item.uuid + "-perorangan"
              //           )}
              //           onChange={handleCheckPerorangan}
              //           disabled={checkedItems.includes(
              //             item.uuid + "-institusi"
              //           )}
              //         />
              //         <label
              //           className="custom-control-label"
              //           htmlFor={item.uuid + "-perorangan"}
              //         >
              //           {item.title} (Perorangan)
              //         </label>
              //       </div>
              //       <div className="custom-control custom-checkbox  custom-checkbox-success mb-3">
              //         <input
              //           type="checkbox"
              //           className="custom-control-input"
              //           id={item.uuid + "-institusi"}
              //           value={item.uuid + "-institusi"}
              //           checked={checkedItems.includes(
              //             item.uuid + "-institusi"
              //           )}
              //           onChange={handleCheckInstitusi}
              //           disabled={
              //             checkedItems.includes(item.uuid + "-perorangan") ||
              //             select.kyc_status > 1
              //           }
              //         />
              //         <label
              //           className="custom-control-label"
              //           htmlFor={item.uuid + "-institusi"}
              //         >
              //           {item.title} (Institusi)
              //         </label>
              //       </div>
              //     </>
              //   )
              // }
              return (
                <div className="custom-control custom-checkbox  custom-checkbox-success mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={item.uuid}
                    value={item.uuid}
                    checked={checkedItems.includes(item.uuid)}
                    onChange={handleCheck}
                  />
                  <label className="custom-control-label" htmlFor={item.uuid}>
                    {item.title}
                  </label>
                </div>
              )
            })}
          <div className="py-2">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              type="submit"
              onClick={handlesubmit}
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalProfile} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalProfile(null)}>
          Ubah Nomor Rekening A/n{" "}
          {select && `${select.first_name} ${select.last_name}`}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitProfile(e, v)
            }}
          >
            <PInput
              name="name"
              label="Nama Pemilik Rekening"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Pemilik rekening harus diisi",
                },
              }}
              value={account ? account.name : ""}
            />
            <PInput
              name="account_number"
              label="Nomor Rekening"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Nomor rekening harus diisi",
                },
                pattern: {
                  value: "^[0-9]+$",
                  errorMessage: "Format Rekening harus angka",
                },
              }}
              value={account ? account.account_number : ""}
            />
            <PInput
              name="bank_id"
              label="Bank"
              type="select"
              validate={{
                required: { value: true, errorMessage: "Bank harus diisi" },
              }}
              value={account ? account.bank_account.uuid : ""}
              options={bank}
            />
            <PInput
              name="branch"
              label="Cabang"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Cabang bank harus diisi",
                },
              }}
              value={account ? account.branch : ""}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDelete(null)}>
          Hapus Data
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            Apakah anda yakin ingin menghapus data ini dengan email{" "}
            {select && select.email} ?
          </div>
          <div className="d-flex">
            <Button
              color="secondary"
              className="btn-sm mr-2"
              onClick={() => toggleModalDelete(null)}
              disabled={loadButt}
            >
              Batal
            </Button>
            <Button
              color="danger"
              className="btn-sm"
              onClick={handleDeleteUser}
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Hapus"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pengguna" breadcrumbItem="Peran" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Pengguna
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat Hapus Data
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="1">
                      <DataTable
                        table={data}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        search={search}
                        handleSearch={setSearch}
                        keySearch={keySearch}
                        setKeySearch={setKeySearch}
                        dataKey={dataKey}
                        handleGetSearch={getUsers}
                        searching={true}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataRiwayat}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                  </TabContent>
                  {/* <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    striped
                    // className="mt-5"
                  /> */}
                  {/* <DataTable 
                    table={data}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPage={totalPage}
                    setTotalPage={setTotalPage}
                    pagination={pagination}
                    search={search}
                    handleSearch={setSearch}
                    keySearch={keySearch}
                    setKeySearch={setKeySearch}
                    dataKey={dataKey}
                    handleGetSearch={getUsers}
                    searching={true}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Roles
