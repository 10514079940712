import React, { useState } from "react"
import { Container } from "reactstrap"

const Dashboard = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="text-center mt-4">
            <h2>Beranda Admin</h2>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
