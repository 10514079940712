import Toaster from "components/Common/Toast"
import {
  createVaFaspayColl,
  createVaRdlBniCollection,
  createVaXendit,
  delCollection,
  getAllCollection,
  getAllOwned,
  getAllRestructure,
  getBankListXendit,
  getCampaign,
  getCampaignAdmin,
  getCampaignAll,
  getCampaignSelector,
  getCollectionByCampaign,
  getVaByCampaign,
  postCollection,
  postEditCollection,
  postImportCollection,
  postVaCollection,
  requestRestructure,
  updateRequestRestructure,
  updateStartDateProject,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormText,
  Tooltip,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL, API_URL_1 } from "helpers/api_url"
import { connect } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import AsyncSelect from "react-select/async"
import NumberFormat from "react-number-format"
import axios from "axios"
import { GET_TEMPLATE_COLLECTION } from "helpers/url_helpers"

const INIT_FORM = {
  pokok: "",
  totalProfitEndingProject: "",
  restructure_tenor: "",
  restructure_nisbah: "",
  restructure_outstanding: "",
  restructure_psr_pihak_pertama: "",
  restructure_psr_pihak_kedua: "",
  borrowerPaidOut: "",
  total_pelunasan: "",
  nisbahProjectToInvestor: "",
}

const Collection = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [campaign, setCampaign] = useState([])
  const [collection, setCollection] = useState([])
  const [select, setSelect] = useState("")
  const [activeTab, setActiveTab] = useState("1")
  const [modalColl, setModalColl] = useState(false)
  const [modalCollEdit, setModalCollEdit] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [file, setFile] = useState(null)
  const [selectEdit, setSelectEdit] = useState(null)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [modalCreateVa, setModalCreateVa] = useState(false)
  const [numberVa, setNumberVa] = useState([])
  const [detailCmpgn, setDetailCmpgn] = useState(null)
  const [selected, setSelected] = useState(null)
  const [loadDownload, setLoadDownload] = useState(false)

  const [restructures, setRestructures] = useState([])
  const [modalRestructure, setModalRestructure] = useState(false)
  const [filesRestructure, setFilesRestructure] = useState(null)

  const [selectRestructure, setSelectRestructure] = useState(null)

  const [startProjectEdited, setStartProjectEdited] = useState(false)
  const [startProject, setStartProject] = useState(null)
  const [loadButtStartProject, setLoadButtStartProject] = useState(false)

  const [form, setForm] = useState(INIT_FORM)

  const [bank, setBank] = useState(null)

  const [ttop, setttop] = useState(false)

  const [xendit, setXendit] = useState([])

  useEffect(() => {
    getLDataXendit()
  }, [])

  const getLDataXendit = async () => {
    setLoad(true)
    const data = await getBankListXendit(token)
    setXendit(data.data)
    setLoad(false)
  }

  // useEffect(() => {
  //   if (selectRestructure) {
  //     setForm({
  //       restructure_psr_pihak_kedua:
  //         selectRestructure.psrPihakKeduaAfterRestructure,
  //       pokok: selectRestructure.pokok,
  //       nisbahProjectToInvestor: selectRestructure.nisbahProjectToInvestor,
  //       borrowerPaidOut: selectRestructure.borrowerPaidOut,
  //       restructure_nisbah: selectRestructure.nisbahAfterRestructure,
  //     })
  //   } else {
  //     setForm(INIT_FORM)
  //   }
  // }, [selectRestructure])

  const toggleModalRestructure = (item = null) => {
    setFilesRestructure(null)
    setForm(INIT_FORM)
    setSelectRestructure(item)
    setModalRestructure(!modalRestructure)
  }

  const toggleModalColl = () => {
    setFile(null)
    setModalColl(!modalColl)
  }
  const toggleModalCollEdit = item => {
    setFile(null)
    setSelectEdit(item)
    setModalCollEdit(!modalCollEdit)
  }
  const toggleModalCreateVa = () => {
    setBank(null)
    setModalCreateVa(!modalCreateVa)
  }

  useEffect(() => {
    if (select) {
      getSelectCampaign()
    }

    if (handleRoles().includes("Marketing")) {
      setActiveTab("1")
    } else if (handleRoles().includes("Finance")) {
      setActiveTab("2")
    }
  }, [select])

  const getSelectCampaign = async () => {
    setLoad(true)
    try {
      const data = await getCollectionByCampaign(select, token)
      const va = await getVaByCampaign(select, token)
      const cmpgn = await getCampaignAdmin(select, token)
      const restructureData = await getAllRestructure("", token)
      setRestructures(
        restructureData.data.filter(item => item.campaign?.uuid === select)
      )
      // const cmpgnData = await getCampaignAll(
      //   token,
      //   `?search=${selected?.label}`
      // )
      // if (cmpgnData.data?.length > 0) {
      //   setStartProject(cmpgnData.data[0].start_date_project)
      // }
      setStartProject(cmpgn.data?.start_date_project)
      setDetailCmpgn(cmpgn.data)
      setCollection(data.data)
      setNumberVa(va.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      // Toaster("error", "Ups", error.response.data.message)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleSubmit = async (e, value) => {
    let newValue = {
      ...value,
      campaign_id: select,
      file: file,
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postCollection(formData, token)
      toggleModalColl()
      getSelectCampaign()
      setLoadButt(false)
      setFile(null)
      Toaster("success", "Sukses", "Berhasil tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleSubmitEdit = async (e, value) => {
    let newValue = {
      ...value,
    }

    if (file) {
      newValue.file = file
    } else {
      delete newValue.file
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postEditCollection(selectEdit.id, formData, token)
      toggleModalCollEdit()
      getSelectCampaign()
      setLoadButt(false)
      setFile(null)
      Toaster("success", "Sukses", "Berhasil diubah")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleRoles = () => {
    let result = []
    props.user.user_role.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  const dataCollection = {
    columns: [
      {
        field: "title",
        label: "Judul",
        sort: "asc",
      },
      {
        field: "description",
        label: "Deskripsi",
        sort: "asc",
        width: 200,
      },
      {
        field: "file",
        label: "Berkas",
        sort: "asc",
      },
      {
        field: "url",
        label: "Berkas Pendukung",
      },
      {
        field: "created_at",
        label: "Tanggal Dibuat",
      },
      {
        field: "updated_at",
        label: "Tanggal Diubah",
      },
      {
        field: "creator",
        label: "Pembuat",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: collection.map(item => {
      return {
        ...item,
        url: item.url ? (
          <Button
            className="btn-sm"
            onClick={() => window.open(`${item.url}`, "_blank")}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ) : (
          "-"
        ),
        created_at: moment(item.created_at).format("DD MMM YYYY"),
        updated_at: moment(item.updated_at).format("DD MMM YYYY"),
        creator: item.creator.first_name + " " + item.creator.last_name,
        file: (
          <Button
            className="btn-sm"
            onClick={() => window.open(`${API_URL}${item.file}`, "_blank")}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm mr-2"
              color="secondary"
              onClick={() => toggleModalCollEdit(item)}
              disabled={
                handleRoles().includes("Collection") ||
                handleRoles().includes("Moderator")
                  ? false
                  : true
              }
            >
              <i class="bx bxs-edit"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => {
                setSelectEdit(item)
                setconfirm_alert(true)
              }}
              disabled={
                handleRoles().includes("Collection") ||
                handleRoles().includes("Moderator")
                  ? false
                  : true
              }
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const handleBank = type => {
    switch (type) {
      case 4:
        return "Permata Syariah"
      case 6:
        return "BNI"
      case 5:
        return "Xendit"
      default:
        return "BSI"
    }
  }

  const handleStatus = status => {
    if (status === 1) return <Badge color="danger">Belum Dibayar</Badge>
    if (status === 2) return <Badge color="warning">Dibayar Bertahap</Badge>
    if (status === 3) return <Badge color="primary">Sudah Dibayar</Badge>
    if (status === 9) return <Badge color="info">Kadaluarsa</Badge>
  }

  const dataTrx = {
    columns: [
      {
        field: "trx_id",
        label: "Id Transaksi",
        sort: "asc",
      },
      {
        field: "amount",
        label: "Jumlah",
        sort: "asc",
      },
      {
        field: "description",
        label: "Deskripsi",
        sort: "asc",
      },
      {
        field: "bank",
        label: "Bank",
        sort: "asc",
      },
      {
        field: "va_number",
        label: "Virtual Account",
        sort: "asc",
      },
      {
        field: "expired_at",
        label: "Tanggal Kadaluarsa",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "datetime_payment",
        label: "Tanggal Bayar",
        sort: "asc",
      },
      {
        field: "created_at",
        label: "Tanggal Dibuat",
        sort: "asc",
      },
      {
        field: "name",
        label: "Nama Pemilik",
        sort: "asc",
      },
      {
        field: "email",
        label: "Email Pemilik",
        sort: "asc",
      },
      {
        field: "no_telp",
        label: "Nomor Telpon",
        sort: "asc",
      },
    ],
    rows: numberVa.map(item => {
      return {
        ...item,
        bank: handleBank(item.bank_type),
        expired_at: item.datetime_expired
          ? moment(item.datetime_expired).format("DD MMM YYYY")
          : "-",
        name: `${item.user.first_name} ${item.user.last_name}`,
        email: `${item.user.email}`,
        no_telp: item.user.phone_number,
        status: handleStatus(item.status),
        datetime_payment: item.datetime_payment ? (
          <div className="text-nowrap">
            {moment(item.datetime_payment).format("DD MMM YYYY")}
          </div>
        ) : (
          "-"
        ),
        created_at: (
          <div className="text-nowrap">
            {moment(item.created_at).format("DD MMM YYYY")}
          </div>
        ),
        amount: (
          <div className="text-nowrap">{`Rp ${parseInt(
            item.amount
          ).toLocaleString()}`}</div>
        ),
      }
    }),
  }

  const handleDeleteColl = async () => {
    // setLoadButt(true)
    setconfirm_alert(false)
    try {
      await delCollection(selectEdit.id, token)
      getSelectCampaign()
      // setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil dihapus")
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleCreateVa = async (e, value) => {
    const newValue = {
      ...value,
      campaign_id: select,
    }

    setLoadButt(true)
    try {
      if (value.bank == "bsi") {
        await postVaCollection(newValue, token)
      } else if (value.bank == "bni") {
        await createVaRdlBniCollection(newValue, token)
      } else if (value.bank?.split("-")[0] == "x") {
        await createVaXendit(
          { ...newValue, type: value.bank?.split("-")[1].toLowerCase() },
          token
        )
      } else {
        await createVaFaspayColl(newValue, token)
      }
      toggleModalCreateVa()
      getSelectCampaign()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil buat virtual account")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const loadOptions = (inputValue, callback) => {
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getCampaignSelector(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const handleUpload = async () => {
    if (!file) return Toaster("error", "Maaf", "Masukkan file excel")
    const formData = new FormData()
    formData.append("file", file)
    setLoadButt(true)
    try {
      await postImportCollection(formData, token)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil unggah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleDownloadTemplate = () => {
    setLoadDownload(true)
    axios
      .get(`${API_URL_1}/${GET_TEMPLATE_COLLECTION}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Disposition":
            "attachment; filename=template_repayment_collection.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `template_repayment_collection.xlsx`)
        document.body.appendChild(link)
        link.click()
        setLoadDownload(false)
      })
      .catch(err => {
        setLoadDownload(false)
        // Toaster("error", "Ups", err.response.data.message)
      })
  }

  const checkCapital = letter => {
    return letter === letter.toUpperCase()
  }

  const dataTableRestructure = {
    columns: [
      {
        field: "campaign_name",
        label: "Nama Kampanye",
      },
      {
        field: "akad_addendum_number",
        label: "Nomor Akad Addendum",
      },
      {
        field: "borrowerPaidOut",
        label: "Total Pembayaran yang telah dilakukan Borrower",
      },
      {
        field: "dueDateAfterRestructure",
        label: "Due date setelah Restruktur",
      },
      {
        field: "dueDateBeforeRestructure",
        label: "Due date sebelum Restruktur",
      },
      {
        field: "legitimationDate",
        label: "Tanggal Addendum",
      },
      {
        field: "nisbahBeforeRestructure",
        label: "ROI sebelum Restruktur",
      },
      {
        field: "nisbahAfterRestructure",
        label: "ROI setelah Restruktur",
      },
      {
        field: "tenor",
        label: "Penambahan Tenor",
      },
      {
        field: "restructure_tenor",
        label: "Total Tenor",
      },
      {
        field: "nisbahProjectToInvestor",
        label: "Proyeksi Total Nisbah Lender",
      },
      {
        field: "outstandingBeforeRestructure",
        label: "Outstanding sebelum Restruktur",
      },
      {
        field: "outstandingAfterRestructure",
        label: "Outstanding setelah Restruktur",
      },
      // {
      //   field: "persentaseNetProfit",
      //   label: "Persentase profit",
      // },
      {
        field: "pokok",
        label: "Pokok Pembiayaan",
      },
      {
        field: "psrPihakPertamaBeforeRestructure",
        label: "PSR pihak pertama sebelum Restruktur",
      },
      {
        field: "psrPihakPertamaAfterRestructure",
        label: "PSR pihak pertama setelah Restruktur",
      },
      {
        field: "psrPihakKeduaBeforeRestructure",
        label: "PSR pihak kedua sebelum Restruktur",
      },
      {
        field: "psrPihakKeduaAfterRestructure",
        label: "PSR pihak kedua setelah Restruktur",
      },
      {
        field: "status",
        label: "Status",
      },
      // {
      //   field: "totalNetProfit",
      //   label: "Total profit",
      // },
      {
        field: "totalProfitEndingProject",
        label: "Proyeksi Total Keuntungan proyek",
      },
      {
        field: "total_pelunasan",
        label: "Total pelunasan",
      },
      {
        field: "files",
        label: "Berkas Permohonan Addendum",
      },
      {
        field: "creator",
        label: "Dibuat oleh",
      },
      {
        field: "updater",
        label: "Diupdate oleh",
      },
      // {
      //   field: "action",
      //   label: "Ubah",
      // },
    ],
    rows: restructures.map(item => {
      return {
        ...item,
        tenor: item.tenor + " Bulan",
        dueDateAfterRestructure: moment(item.dueDateAfterRestructure).format(
          "DD MMM YYYY"
        ),
        dueDateBeforeRestructure: moment(item.dueDateBeforeRestructure).format(
          "DD MMM YYYY"
        ),
        restructure_tenor: item.campaign?.restructure_tenor + " Bulan",
        campaign_name: item.campaign?.title,
        legitimationDate: !item.legitimationDate
          ? "-"
          : moment(item.legitimationDate).format("DD MMM YYYY"),
        akad_addendum_number: item.akad_addendum_number || "-",
        borrowerPaidOut: `Rp ${Number(item.borrowerPaidOut).toLocaleString()}`,
        nisbahAfterRestructure: `${item.nisbahAfterRestructure} %`,
        nisbahBeforeRestructure: `${item.nisbahBeforeRestructure} %`,
        nisbahProjectToInvestor: (
          <span>
            <span>Rp</span>{" "}
            {`${Number(item.nisbahProjectToInvestor).toLocaleString()}`}
          </span>
        ),
        outstandingAfterRestructure: `Rp ${Number(
          item.outstandingAfterRestructure
        ).toLocaleString()}`,
        outstandingBeforeRestructure: `Rp ${Number(
          item.outstandingBeforeRestructure
        ).toLocaleString()}`,
        persentaseNetProfit: item.persentaseNetProfit
          ? `${item.persentaseNetProfit} %`
          : "-",
        pokok: `Rp ${Number(item.pokok).toLocaleString()}`,
        psrPihakKeduaAfterRestructure: `${item.psrPihakKeduaAfterRestructure} %`,
        psrPihakKeduaBeforeRestructure: `${item.psrPihakKeduaBeforeRestructure} %`,
        psrPihakPertamaAfterRestructure: `${item.psrPihakPertamaAfterRestructure} %`,
        psrPihakPertamaBeforeRestructure: `${item.psrPihakPertamaBeforeRestructure} %`,
        status: item.status ? "Sudah direview" : "Belum direview",
        totalNetProfit: `Rp ${Number(item.totalNetProfit).toLocaleString()}`,
        totalProfitEndingProject: `Rp ${Number(
          item.totalProfitEndingProject
        ).toLocaleString()}`,
        total_pelunasan: `Rp ${Number(item.total_pelunasan).toLocaleString()}`,
        creator: item.creator
          ? `${item.creator.first_name} ${item.creator.last_name}`
          : "-",
        files: item.files?.length
          ? item.files.map(el => {
              return (
                <Button
                  className="btn-sm mb-1 mr-1"
                  onClick={() => window.open(`${API_URL}${el.file}`)}
                >
                  <i className="fas fa-eye"></i>
                </Button>
              )
            })
          : "-",
        updater: item.updater
          ? `${item.updater.first_name} ${item.updater.last_name}`
          : "-",
        action: (
          <Button
            className="btn-sm"
            onClick={() => {
              toggleModalRestructure(item)
            }}
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const handleSubmitRestructure = async (e, value) => {
    const newValue = {
      ...form,
      nisbahProjectToInvestor:
        parseFloat(form.pokok) * (parseFloat(form.restructure_nisbah) / 100),
      restructure_outstanding:
        parseFloat(form.pokok) +
        parseFloat(form.pokok) * (parseFloat(form.restructure_nisbah) / 100),
      restructure_psr_pihak_kedua:
        ((parseFloat(form.pokok) *
          (parseFloat(form.restructure_nisbah) / 100)) /
          parseFloat(form.totalProfitEndingProject)) *
        100,
      restructure_psr_pihak_pertama:
        100 -
        ((parseFloat(form.pokok) *
          (parseFloat(form.restructure_nisbah) / 100)) /
          parseFloat(form.totalProfitEndingProject)) *
          100,
      total_pelunasan:
        parseFloat(form.pokok) +
        parseFloat(form.pokok) * (parseFloat(form.restructure_nisbah) / 100) -
        parseFloat(form.borrowerPaidOut),
    }
    // console.log(newValue)
    // delete value.files
    if (selectRestructure) {
      if (filesRestructure) {
        Object.values(filesRestructure).forEach((el, index) => {
          newValue[`files[${index}]`] = el
        })
      }

      const formData = new FormData()
      Object.keys(newValue).map(item => {
        formData.append(item, newValue[item])
      })

      setLoadButt(true)
      try {
        await updateRequestRestructure(selectRestructure.id, formData, token)
        toggleModalRestructure(null)
        setLoadButt(false)
        setForm(INIT_FORM)
        getSelectCampaign()
        Toaster("success", "Sukses", "Berhasil Ubah restruktusisasi")
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Maaf", error.response.data.message)
      }
    } else {
      Object.values(filesRestructure).forEach((el, index) => {
        newValue[`files[${index}]`] = el
      })

      const formData = new FormData()
      Object.keys(newValue).map(item => {
        formData.append(item, newValue[item])
      })

      setLoadButt(true)
      try {
        await requestRestructure(select, formData, token)
        toggleModalRestructure(null)
        setLoadButt(false)
        setForm(INIT_FORM)
        getSelectCampaign()
        Toaster("success", "Sukses", "Berhasil buat permohonan restruktusisasi")
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Maaf", error.response.data.message)
      }
    }
  }

  // console.log(restructures)

  const renderModalRestructure = () => {
    return (
      <Modal
        isOpen={modalRestructure}
        role="dialog"
        autoFocus={true}
        size="lg"
        scrollable
      >
        <ModalHeader toggle={() => toggleModalRestructure(null)}>
          Restrukturisasi
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitRestructure(e, v)
            }}
          >
            <Row>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="pokok"
                  label="Pokok Pembiayaan"
                  helpMessage="Rupiah"
                  value={form.pokok}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      pokok: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Pokok harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="totalProfitEndingProject"
                  label="Proyeksi Total Keuntungan Proyek"
                  helpMessage="Rupiah"
                  value={form.totalProfitEndingProject}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      totalProfitEndingProject: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Total Profit harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" Bulan"}
                  name="restructure_tenor"
                  label="Penambahan Tenor"
                  helpMessage="Bulan"
                  value={form.restructure_tenor}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_tenor: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Tenor harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" %"}
                  name="restructure_nisbah"
                  label="ROI"
                  value={form.restructure_nisbah}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_nisbah: e.value,
                    })
                  }
                  helpMessage="Persen"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Nisbah harus diisi",
                    },
                  }}
                />
              </Col>
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="restructure_outstanding"
                  label="Outstanding"
                  value={form.restructure_outstanding}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_outstanding: e.value,
                    })
                  }
                  helpMessage="Rupiah"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Outstanding harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" %"}
                  name="restructure_psr_pihak_pertama"
                  label="PSR Pihak Pertama"
                  helpMessage="Persen"
                  value={
                    form.restructure_psr_pihak_kedua &&
                    parseFloat(form.restructure_psr_pihak_kedua) <= 100
                      ? 100 - parseFloat(form.restructure_psr_pihak_kedua)
                      : ""
                  }
                  disabled
                  className="disabled-input-custom"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "PSR Pihak Pertama harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" %"}
                  name="restructure_psr_pihak_kedua"
                  label="PSR Pihak Kedua"
                  helpMessage="Persen"
                  value={form.restructure_psr_pihak_kedua}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_psr_pihak_kedua: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "PSR Pihak Kedua harus diisi",
                    },
                  }}
                />
              </Col> */}
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="borrowerPaidOut"
                  label="Total Yang sudah dibayarkan Borrower"
                  helpMessage="Rupiah"
                  value={form.borrowerPaidOut}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      borrowerPaidOut: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage:
                        "Total Yang sudah dibayarkan Borrower harus diisi",
                    },
                  }}
                />
              </Col>
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="total_pelunasan"
                  label="Total Pelunasan"
                  helpMessage="Rupiah"
                  disabled
                  className="disabled-input-custom"
                  value={form.total_pelunasan}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Total Pelunasan harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="nisbahProjectToInvestor"
                  label="Proyeksi Imbal Hasil Ke Lender Keseluruhan"
                  helpMessage="Rupiah"
                  disabled
                  className="disabled-input-custom"
                  value={form.nisbahProjectToInvestor}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Nisbah Lender harus diisi",
                    },
                  }}
                />
              </Col> */}
              <Col lg="6">
                <PInput
                  name="files"
                  type="file"
                  label="Unggah Berkas"
                  helpMessage="Berkas bisa lebih dari 1 (pdf.)"
                  onChange={e => setFilesRestructure(e.target.files)}
                  validate={{
                    required: {
                      value: selectRestructure ? false : true,
                      errorMessage: "Berkas harus diisi",
                    },
                  }}
                  value={
                    filesRestructure?.length
                      ? { name: filesRestructure.length + " Berkas" }
                      : null
                  }
                  multiple
                />
              </Col>
            </Row>

            <div>
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Deskripsi</th>
                    <th scope="col">Restruktur</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pokok Pembiayaan</td>
                    <td>
                      Rp{" "}
                      {form.pokok
                        ? `${parseFloat(form.pokok).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Proyeksi Total Keuntungan Proyek</td>
                    <td>
                      Rp{" "}
                      {form.totalProfitEndingProject
                        ? `${parseFloat(
                            form.totalProfitEndingProject
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Penambahan Tenor</td>
                    <td>
                      {form.restructure_tenor
                        ? `${parseFloat(
                            form.restructure_tenor
                          ).toLocaleString()} Bulan`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>ROI</td>
                    <td>
                      {form.restructure_nisbah
                        ? `${parseFloat(form.restructure_nisbah)} %`
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <td>PSR Pihak Pertama (Borrower)</td>
                    <td>
                      {form.pokok &&
                      form.restructure_nisbah &&
                      form.totalProfitEndingProject
                        ? `${
                            100 -
                            ((parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100)) /
                              parseFloat(form.totalProfitEndingProject)) *
                              100
                          } %`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>PSR Pihak Kedua (Lender)</td>
                    <td>
                      {form.pokok &&
                      form.restructure_nisbah &&
                      form.totalProfitEndingProject
                        ? `${
                            ((parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100)) /
                              parseFloat(form.totalProfitEndingProject)) *
                            100
                          } %`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Proyeksi Total Nisbah ke Lender</td>
                    <td>
                      Rp{" "}
                      {form.pokok && form.restructure_nisbah
                        ? `${(
                            parseFloat(form.pokok) *
                            (parseFloat(form.restructure_nisbah) / 100)
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Outstanding Baru</td>
                    <td>
                      Rp{" "}
                      {form.pokok && form.restructure_nisbah
                        ? `${(
                            parseFloat(form.pokok) +
                            parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100)
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Pembayaran yang telah dilakukan</td>
                    <td>
                      Rp{" "}
                      {form.borrowerPaidOut
                        ? `${parseFloat(form.borrowerPaidOut).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Pelunasan</td>
                    <td>
                      Rp{" "}
                      {form.pokok &&
                      form.restructure_nisbah &&
                      form.borrowerPaidOut
                        ? `${(
                            parseFloat(form.pokok) +
                            parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100) -
                            parseFloat(form.borrowerPaidOut)
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleStartProject = async () => {
    if (!startProject)
      return Toaster("error", "Maaf", "Tanggal mulai proyek harus diisi.")
    setLoadButtStartProject(true)
    try {
      const res = await updateStartDateProject(
        select,
        { start_date_project: startProject },
        token
      )
      setStartProject(res.data?.start_date_project)
      setLoadButtStartProject(false)
      setStartProjectEdited(false)
      Toaster("success", "Sukses", "Tanggal mulai proyek berhasil diubah")
    } catch (error) {
      setLoadButtStartProject(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  // console.log(selectRestructure)

  if (load) return <Loading title="Penagihan" breadcrumbItem="Penagihan" />

  return (
    <React.Fragment>
      {renderModalRestructure()}
      {confirm_alert ? (
        <SweetAlert
          title="Apakah anda yakin?"
          warning
          showCancel
          confirmBtnText="Hapus"
          cancelBtnText="Batal"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={handleDeleteColl}
          onCancel={() => setconfirm_alert(false)}
        >
          Anda tidak akan dapat mengembalikan data ini!
        </SweetAlert>
      ) : null}
      <Modal isOpen={modalColl} role="dialog" autoFocus={true}>
        <ModalHeader toggle={toggleModalColl}>
          Buat Perkembangan Proyek
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmit(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Judul"
              validate={{
                required: { value: true, errorMessage: "Judul harus diisi" },
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi harus diisi",
                },
              }}
            />
            <PInput
              name="file"
              type="file"
              label="Unggah Berkas"
              helpMessage="png, jpeg, jpg, pdf."
              onChange={e => setFile(e.target.files[0])}
              validate={{
                required: { value: false, errorMessage: "Berkas harus diisi" },
              }}
              value={file}
            />
            <PInput
              name="url"
              type="text"
              label="Berkas Pendukung"
              validate={{
                required: {
                  value: false,
                  errorMessage: "Berkas pendukung harus diisi",
                },
              }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalCollEdit} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalCollEdit(null)}>
          {selectEdit && selectEdit.title}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitEdit(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Judul"
              value={selectEdit ? selectEdit.title : ""}
              validate={{
                required: { value: true, errorMessage: "Judul harus diisi" },
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              value={selectEdit ? selectEdit.description : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi harus diisi",
                },
              }}
            />
            <PInput
              name="file"
              type="file"
              label="Unggah Berkas"
              helpMessage="png, jpeg, jpg, pdf."
              onChange={e => setFile(e.target.files[0])}
              validate={{
                required: { value: false, errorMessage: "Berkas harus diisi" },
              }}
              value={file}
            />
            <PInput
              name="url"
              type="text"
              label="Berkas Pendukung"
              value={selectEdit ? selectEdit.url : ""}
              validate={{
                required: { value: false, errorMessage: "URL harus diisi" },
              }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalCreateVa} role="dialog" autoFocus={true}>
        <ModalHeader toggle={toggleModalCreateVa}>
          Buat Virtual Account
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateVa(e, v)
            }}
          >
            <PInput
              name="trx_amount"
              type="number"
              label="Jumlah"
              helpMessage="Rupiah"
              validate={{
                required: { value: true, errorMessage: "Jumlah harus diisi" },
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi harus diisi",
                },
              }}
            />
            <PInput
              name="bank"
              type="select"
              label="Metode Pembayaran"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Metode Pembayaran harus diisi",
                },
              }}
              options={[
                { uuid: "bsi", title: "BSI" },
                { uuid: "permata", title: "Permata Syariah" },
                { uuid: "bni", title: "BNI RDF" },
                ...xendit
                  .filter(item => item.is_activated)
                  .map(item => {
                    return {
                      uuid: `x-${item.code}`,
                      title: `${item.name} (Xendit)`,
                    }
                  }),
              ]}
              onChange={e => {
                setBank(e.target.value)
              }}
            />
            {Boolean(
              bank == "permata" || bank == "bni" || bank?.split("-")[0] == "x"
            ) && (
              <PInput
                name="inDays"
                type="number"
                label="Masa Berlaku VA (Dalam Hari)"
                helpMessage="Maksimal 60 hari (Default 3 hari)"
                validate={{
                  max: { value: 60, errorMessage: "Maksimal 60 hari" },
                }}
              />
            )}
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Buat"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Penagihan" breadcrumbItem="Penagihan" />
          <Row className="mb-2">
            <Col lg="8">
              <FormGroup row>
                <Label sm={3}>Pilih Kampanye</Label>
                <Col sm={8}>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={e => {
                      setSelect(e.value)
                      setSelected(e)
                    }}
                    value={selected}
                    // onInputChange={handleInputSearch}
                  />
                </Col>
              </FormGroup>
              {select && (
                <FormGroup row className="d-flex align-items-center">
                  <Label sm={3}>Tanggal Mulai Proyek</Label>
                  <Col sm={8} className="d-flex align-items-start">
                    <div className="w-100 mr-2">
                      <Input
                        type="date"
                        onChange={e => setStartProject(e.target.value)}
                        value={startProject}
                        disabled={!startProjectEdited}
                        className={`${
                          !startProjectEdited ? "disabled-input-custom" : ""
                        }`}
                      />
                      <FormText>Format: DD/MM/YYYY</FormText>
                    </div>
                    {startProjectEdited ? (
                      <>
                        <Button
                          color="primary"
                          className="mr-2"
                          onClick={handleStartProject}
                          disabled={loadButtStartProject}
                        >
                          Simpan
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => {
                            setStartProjectEdited(false)
                            setStartProject("")
                          }}
                          disabled={loadButtStartProject}
                        >
                          Batal
                        </Button>
                      </>
                    ) : (
                      <Button onClick={() => setStartProjectEdited(true)}>
                        Ubah
                      </Button>
                    )}
                  </Col>
                </FormGroup>
              )}
            </Col>
            <Col lg={4}>
              <div className="">
                <div className="mr-3">
                  <div className="custom-file mb-2">
                    <input
                      type="file"
                      className="custom-file-input cp-custom"
                      id="customFile"
                      onChange={e => setFile(e.target.files[0])}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFile"
                      style={{ color: "#636c72" }}
                    >
                      {file ? file.name : "Pilih Berkas"}
                    </label>
                  </div>
                </div>
                <Button
                  color="primary"
                  onClick={handleUpload}
                  disabled={loadButt}
                  className="w-100"
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Unggah Pengembalian Borrower"
                  )}
                </Button>
                <Button
                  className="w-100 mt-2"
                  disabled={loadDownload}
                  onClick={handleDownloadTemplate}
                >
                  {loadDownload ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Unduh Template"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="w-100 mb-3">
                    <div>
                      {select && detailCmpgn ? (
                        <table class="table table-striped border">
                          <thead>
                            <tr>
                              <th scope="col">Pengembalian Peminjam</th>
                              <th scope="col">
                                Menunggu Pengembalian Peminjam
                              </th>
                              <th scope="col">
                                Estimasi Pengembalian dengan Nisbah
                              </th>
                              <th scope="col">Sisa yang harus dibayar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Rp{" "}
                                {parseInt(
                                  detailCmpgn.collection_collect
                                ).toLocaleString()}
                              </td>
                              <td>
                                Rp{" "}
                                {parseInt(
                                  detailCmpgn.estimate_collection
                                ).toLocaleString()}
                              </td>
                              <td>
                                Rp{" "}
                                {parseInt(
                                  detailCmpgn.estimate_repayment
                                ).toLocaleString()}
                              </td>
                              <td>
                                Rp{" "}
                                {(
                                  parseInt(detailCmpgn.estimate_repayment) -
                                  parseInt(detailCmpgn.collection_collect)
                                ).toLocaleString()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  </div>

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Perkembangan Proyek
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Penagihan
                      </NavLink>
                    </NavItem>
                    {handleRoles().includes("Collection") ||
                    handleRoles().includes("Moderator") ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleTab("3")
                          }}
                        >
                          Restrukturisasi
                        </NavLink>
                      </NavItem>
                    ) : null}
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      {select && (
                        <>
                          {handleRoles().includes("Collection") ||
                          handleRoles().includes("Moderator") ? (
                            <div className="d-flex justify-content-end mt-3">
                              <Button onClick={toggleModalColl}>+ Buat</Button>
                            </div>
                          ) : null}
                        </>
                      )}

                      <MDBDataTable
                        responsive
                        bordered
                        data={dataCollection}
                        striped
                        className="mt-2"
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      {select && (
                        <>
                          {handleRoles().includes("Collection") ||
                          handleRoles().includes("Moderator") ? (
                            <div className="d-flex justify-content-end align-items-center mt-3">
                              {/* {detailCmpgn?.akad_type?.title == "Murabahah" && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="mr-2"
                                >
                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop}
                                    target="TooltipTop-va"
                                    toggle={() => {
                                      setttop(!ttop)
                                    }}
                                  >
                                    Kampanye ini menggunakakan akad Murabahah,
                                    pembuatan Virtual Account dilakukan oleh
                                    penerima dana pada dashboard.
                                  </Tooltip>
                                  <i
                                    className="bx bxs-calendar-heart bx-sm ml-1"
                                    id="TooltipTop-va"
                                  ></i>
                                </div>
                              )} */}
                              <Button
                                onClick={toggleModalCreateVa}
                                // disabled={
                                //   detailCmpgn?.akad_type?.title == "Murabahah"
                                // }
                              >
                                + Buat Virtual Account
                              </Button>
                            </div>
                          ) : null}
                        </>
                      )}

                      <MDBDataTable
                        responsive
                        bordered
                        data={dataTrx}
                        striped
                        className="mt-2"
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      {select && (
                        <>
                          {handleRoles().includes("Collection") ||
                          handleRoles().includes("Moderator") ? (
                            <div className="d-flex justify-content-end mt-3">
                              <Button
                                onClick={() => toggleModalRestructure(null)}
                              >
                                + Permohonan Restrukturisasi
                              </Button>
                            </div>
                          ) : null}
                        </>
                      )}

                      <MDBDataTable
                        responsive
                        bordered
                        data={dataTableRestructure}
                        striped
                        className="mt-2"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Collection)
