import React from "react"
import { Doughnut } from "react-chartjs-2"

const DountChart = ({ data }) => {
  const datas = {
    labels: ["DESKTOP", "MOBILE", "LAINNYA"],
    datasets: [
      {
        data: data,
        backgroundColor: ["#556ee6", "#38a4f8", "#02a499"],
        hoverBackgroundColor: ["#556ee6", "#38a4f8", "#02a499"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  return <Doughnut width={474} height={230} data={datas} />
}

export default DountChart
