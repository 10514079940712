import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Media,
  UncontrolledTooltip,
  Row,
  Table,
  Container,
  Button,
  Modal
} from "reactstrap"

import logo from "../../assets/images/brands-ethis/logo.png"
import { getCampaignByProject } from 'helpers/backend_helpers'

const CardProject = ({projects, history}) => {
  const [modal, setModal] = useState(false)
  const [selectProject, setSelectProject] = useState(null)
  const [campaign, setCampaign] = useState([])

  useEffect(() => {
    if(selectProject) {
      getData()
    }
  }, [selectProject])

  const getData = async () => {
    const token = localStorage.getItem("token")
    const campaign = await getCampaignByProject(selectProject.uuid, token)
    setCampaign(campaign.data)
  }

  const toggle = (item) => {
    setSelectProject(item)
    setModal(!modal)
  }

  const handleStatus = (project) => {
    if(project.project_type.title == "UKM") {

      if(project.status === 1) return <Badge color="info">Peninjauan Tahap 1</Badge>
      if(project.status === 2) return <Badge color="danger">Ditolak Tahap 1</Badge>
      if(project.status === 3) return <Badge color="danger">Ditolak Tahap 2</Badge>
      if(project.status === 4) return <Badge color="warning">Pengisian Tahap 2</Badge>
      if(project.status === 5) return <Badge color="info">Peninjauan Tahap 2</Badge>
      if(project.status === 6) return <Badge color="primary">Disetujui</Badge>
    } else {
      if(project.status === 1) return <Badge color="info">Peninjauan</Badge>
      if(project.status === 2) return <Badge color="danger">Ditolak</Badge>
      if(project.status === 3) return <Badge color="danger">Ditolak Tahap 2</Badge>
      if(project.status === 5) return <Badge color="info">Peninjauan Tahap 2</Badge>
      if(project.status === 6) return <Badge color="primary">Disetujui</Badge>
    }
  }

  const handleStatusProject = (type, status) => {
    if(type === "UKM") {
      switch (status) {
        case 1: return <Badge color="info">Peninjauan tahap 1</Badge>
        case 2: return <Badge color="danger">Ditolak tahap 1</Badge>
        case 3: return <Badge color="danger">Ditolak tahap 2</Badge>
        case 4: return <Badge color="info">Disetujui tahap 1</Badge>
        case 5: return <Badge color="info">Peninjauan tahap 2</Badge>
        case 6: return <Badge color="info">Peninjauan tahap 2</Badge>
        case 7: return <Badge color="info">Peninjauan tahap 2</Badge>
        case 8: return <Badge color="primary">Disetujui</Badge>
        case 9: return <Badge color="primary">Terbit Kampanye</Badge>
      }
    } else {
      switch (status) {
        case 1: return <Badge color="info">Peninjauan</Badge>
        case 2: return <Badge color="danger">Ditolak</Badge>
        // case 3: return <Badge color="danger">Ditolak</Badge>
        // case 4: return <Badge color="primary">Disetujui Tahap 1</Badge>
        // case 5: return <Badge color="warning">Menunggu persetujuan tahap 2</Badge>
        case 6: return <Badge color="info">Peninjauan</Badge>
        case 7: return <Badge color="info">Peninjauan</Badge>
        case 8: return <Badge color="primary">Disetujui</Badge>
        case 9: return <Badge color="primary">Terbit Kampanye</Badge>
      }
    }
  }

  const handleChoose = item => {
    if(item.project_type.title == "UKM") {
      history.push(`/pengajuan-ukm/${item.uuid}`)
    } else {
      history.push(`/pengajuan-properti/${item.uuid}`)
    }
  }

  // const handleButton = item => {
  //   if(item.project_type.title === "UKM") {
  //     if(item.status === 2) return "Ajukan Kembali"
  //   }
  // }
  const handleDeskripsiStatus = (item) => {
    if(item.approval_project.length === 0) return "Menunggu peninjauan"
    if(item.status === 2 || item.status === 3 || item.status === 4 || item.status === 8 || item.status === 9) {
      if(item.approval_project[item.approval_project.length - 1].description) return item.approval_project[item.approval_project.length - 1].description
      return item.approval_project[item.approval_project.length - 1].reason.title
    }
    return "Menunggu peninjauan"
  }

  // console.log(campaign)

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        // toggle={() => {
        //   toggle()
        // }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{selectProject && selectProject.title}</h5>
          <button
            type="button"
            onClick={() => {
              toggle(null)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          body
        </div>
      </Modal>
      <Container fluid>

        <Row>
          <Col lg="12">
            <div className="">
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap table-centered table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        #
                      </th>
                      <th scope="col">Nama Proyek</th>
                      <th scope="col">Jenis Proyek</th>
                      {/* <th scope="col">Nama Perusahaan</th> */}
                      {/* <th scope="col">Tanggal Submit</th> */}
                      {/* <th scope="col">Nama PIC</th> */}
                      <th scope="col">Status</th>
                      <th scope="col">Deskripsi Status</th>
                      <th scope="col">Data Pengajuan</th>
                      <th scope="col">Kampanye</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(projects, (project, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={logo}
                            alt=""
                            className="avatar-sm"
                          />
                        </td>
                        <td>
                          <h5 className="text-truncate font-size-14">
                            {project.title}
                          </h5>
                          {/* <p className="text-muted mb-0">
                            {project.project_detail.description}
                          </p> */}
                        </td>
                        <td>{project.project_type.title}</td>
                        {/* <td>
                          {project.project_company.company_name}
                        </td> */}
                        {/* <td>12 januari 202</td> */}
                        {/* <td>{project.project_pic[0].full_name}</td> */}
                        <td>
                        {handleStatusProject(project.project_type.title, project.status)}
                        </td>
                        <td>{handleDeskripsiStatus(project)}</td>
                        <td>
                          {
                            project.status === 4 ?
                            <Button
                              type="button"
                              color="primary"
                              className="btn-sm btn-rounded w-100"
                              onClick={() => handleChoose(project)}
                            >
                              Ajukan Tahap 2
                            </Button> :
                            <Button
                              type="button"
                              color="primary"
                              className="btn-sm btn-rounded w-100"
                              onClick={() => handleChoose(project)}
                            >
                              {project.status === 2 || project.status === 3 ? "Ajukan Kembali" : "Lihat"}
                            </Button>
                          }
                        </td>
                        <td>
                          {project.status >= 8 ?
                            <Button
                              color="info"
                              className="btn-sm btn-rounded w-100"
                              // onClick={() => toggle(project)}
                              onClick={() => history.push(`/proyek-detail/${project.uuid}`)}
                            >
                              Lihat
                            </Button> :
                            <Button
                              color="secondary"
                              className="btn-sm btn-rounded w-100"
                              disabled
                            >
                              Belum ada
                            </Button>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

CardProject.propTypes = {
  projects: PropTypes.array
}

export default CardProject
