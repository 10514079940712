// import React, { useState } from "react"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const Toaster = (toastType, title = "Ups", message = "Terjadi kesalahan silahkan hubungi admin") => {
  // const {message, toastType} = props

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: false,
    newestOnTop: false,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  }

  let filterMessage = []
  let messages = message

  if(typeof message === "object") {
    Object.keys(message).forEach(item => {
      filterMessage.push(message[item].toString())
    })
  }

  if(typeof message === "object") {
    messages = filterMessage.toString()
  }

  //Toaster Types
  if (toastType === "info") toastr.info(message, title)
  else if (toastType === "warning") toastr.warning(filterMessage, title)
  else if (toastType === "error") toastr.error(messages, title)
  else toastr.success(message, title)
}

export default Toaster