import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import { Badge, Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import { connect } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import AsyncSelect from 'react-select/async'
import { getCampaignSelector, getClaimVoucher, getVoucher, postVoucher, updateVoucher } from "helpers/backend_helpers"
// import { NULL } from "node-sass"

const Voucher = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [vouchers, setVouchers] = useState([])
  const [allCampagin, setAllCampaign] = useState(null)
  const [campaign, setCampaign] = useState([])

  const [modalCreateVoucher, setModalCreateVoucher] = useState(false)
  const [modalUpdateVoucher, setModalUpdateVoucher] = useState(false)
  const [selectUpdate, setSelectUpdate] = useState(null)

  const [voucherClaim, setVoucherClaim] = useState([])

  const [fullName, setFullName] = useState("")
  const [itemSelect, setItemSelect] = useState(null)

  const [modalTrx, setModalTrx] = useState(false)
  const [selectTrx, setSelectTrx] = useState([])

  // console.log(voucherClaim)

  useEffect(() => {
    getData()
  }, [activeTab, itemSelect])

  useEffect(async () => {
    setLoad(true)
    const campaign = await getCampaignSelector(token, "")
    setCampaign(campaign.data)
    setLoad(false)
  }, [])

  const getData = async () => {
    setLoad(true)
    if(activeTab === "1") {
      const data = await getVoucher(token)
      setVouchers(data.data)
    } else if( activeTab === "2" && itemSelect) {
      const claim = await getClaimVoucher(itemSelect.value, token)
      setVoucherClaim(claim.data)
    }
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleCreateVoucher = async (e, value) => {
    value.expired_at = `${value.expired_at} 00:00:00`
    // console.log(value)
    setLoadButt(true)
    try {
      await postVoucher(value, token)
      toggleModalCreateVoucher()
      setAllCampaign(null)
      getData()
      setLoadButt(false)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleUpdateVoucher = async (e, value) => {
    value.expired_at = `${value.expired_at} 00:00:00`
    // console.log(value)
    setLoadButt(true)
    try {
      await updateVoucher(selectUpdate.uuid, value, token)
      toggleModalUpdateVoucher(null)
      getData()
      setLoadButt(false)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const toggleModalCreateVoucher = () => setModalCreateVoucher(!modalCreateVoucher)
  const toggleModalUpdateVoucher = (item) => {
    if(item && item.isAllCampaign == 0) {
      setAllCampaign("false")
    } else {
      setAllCampaign(null)
    }
    setSelectUpdate(item)
    setModalUpdateVoucher(!modalUpdateVoucher)
  }
  const toggleModalTrx = (item = []) => {
    setSelectTrx(item)
    setModalTrx(!modalTrx)
  }

  const renderCreateVoucer = () => {
    return (
      <Modal
        isOpen={modalCreateVoucher}
        role="dialog"
        autoFocus={true}
        scrollable
      >
        <ModalHeader toggle={toggleModalCreateVoucher}>Buat Voucher</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateVoucher(e, v)
            }}
          >
            <PInput
              name="code"
              type="text"
              label="Kode Voucher"
              validate={{
                required: {value: true, errorMessage: 'Code harus diisi'},
              }}
            />
            <PInput
              name="max"
              type="number"
              label="Maksimum Penggunaan"
              validate={{
                required: {value: true, errorMessage: 'Jumlah harus diisi'},
              }}
            />
            <PInput
              name="totalToGetRewards"
              type="number"
              label="Total Pengumpulan"
              validate={{
                required: {value: true, errorMessage: 'Total harus diisi'},
              }}
            />
            <PInput
              name="expired_at"
              type="date"
              label="Tanggal Kadaluarsa"
              validate={{
                required: {value: true, errorMessage: 'Tanggal harus diisi'},
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              validate={{
                required: {value: true, errorMessage: 'Deskripsi harus diisi'},
              }}
            />
            <PInput
              name="isAllCampaign"
              type="select"
              label="Semua Kampanye ?"
              onChange={e => setAllCampaign(e.target.value)}
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Ya"},
                {uuid: "false", title: "Tidak"},
              ]}
            />
            {allCampagin && allCampagin === "false" ?
              <PInput
                name="campaign_id"
                type="select"
                label="Pilih Kampanye"
                validate={{
                  required: {value: true, errorMessage: 'Field harus diisi'},
                }}
                options={campaign}
              />
              : null
            }
            <PInput
              name="isActive"
              type="select"
              label="Status"
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Aktif"},
                {uuid: "false", title: "Tidak Aktif"},
              ]}
            />
            <PInput
              name="newFundingUser"
              type="select"
              label="Spesifikasi Pengguna Voucher"
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Semua Pengguna yang belum memiliki Transaksi"},
                {uuid: "false", title: "Semua Pengguna"},
              ]}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const renderUpdateVoucer = () => {
    return (
      <Modal
        isOpen={modalUpdateVoucher}
        role="dialog"
        autoFocus={true}
        scrollable
      >
        <ModalHeader toggle={() => toggleModalUpdateVoucher(null)}>Ubah Voucher</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleUpdateVoucher(e, v)
            }}
          >
            <PInput
              name="code"
              type="text"
              label="Kode Voucher"
              value={selectUpdate ? selectUpdate.code : ""}
              validate={{
                required: {value: true, errorMessage: 'Code harus diisi'},
              }}
            />
            <PInput
              name="max"
              type="number"
              label="Jumlah Pemakaian"
              value={selectUpdate ? selectUpdate.max : ""}
              validate={{
                required: {value: true, errorMessage: 'Jumlah harus diisi'},
              }}
            />
            <PInput
              name="totalToGetRewards"
              type="number"
              label="Total Pengumpulan"
              value={selectUpdate ? selectUpdate.totalToGetRewards : ""}
              validate={{
                required: {value: true, errorMessage: 'Total harus diisi'},
              }}
            />
            <PInput
              name="expired_at"
              type="date"
              label="Tanggal Kadaluarsa"
              value={selectUpdate ? selectUpdate.expired_at.slice(0, 10) : ""}
              validate={{
                required: {value: true, errorMessage: 'Tanggal harus diisi'},
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              value={selectUpdate ? selectUpdate.description : ""}
              validate={{
                required: {value: true, errorMessage: 'Deskripsi harus diisi'},
              }}
            />
            <PInput
              name="isAllCampaign"
              type="select"
              label="Semua Kampanye ?"
              value={selectUpdate ? selectUpdate.isAllCampaign == 0 ? "false" : "true" : ""}
              onChange={e => setAllCampaign(e.target.value)}
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Ya"},
                {uuid: "false", title: "Tidak"},
              ]}
            />
            {allCampagin && allCampagin === "false" ?
              <PInput
                name="campaign_id"
                type="select"
                label="Pilih Kampanye"
                value={selectUpdate && selectUpdate.campaign ? selectUpdate.campaign.uuid : ""}
                validate={{
                  required: {value: true, errorMessage: 'Field harus diisi'},
                }}
                options={campaign}
              />
              : null
            }
            <PInput
              name="isActive"
              type="select"
              label="Status"
              value={selectUpdate ? selectUpdate.isActive == 1 ? "true" : "false" : ""}
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Aktif"},
                {uuid: "false", title: "Tidak Aktif"},
              ]}
            />
            <PInput
              name="newFundingUser"
              type="select"
              label="Spesifikasi Pengguna Voucher"
              value={selectUpdate ? selectUpdate.newFundingUser == 1 ? "true" : "false" : ""}
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Semua Pengguna yang belum memiliki Transaksi"},
                {uuid: "false", title: "Semua Pengguna"},
              ]}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const tableDetailTrx = {
    columns: [
      {
        field: "trx_id",
        label: "Id Transaksi",
        sort: "asc",
      },
      {
        field: "trx_amount",
        label: "Pembiayaan",
      },
      {
        field: "trx_va_number",
        label: "Virtual Account",
      },
      // {
      //   field: "trx_status",
      //   label: "Status Transaksi",
      // },
      {
        field: "voc_code",
        label: "Kode Voucher",
      },
      {
        field: "voc_expired_at",
        label: "Voucher Kadaluarsa",
      },
      // {
      //   field: "voc_max",
      //   label: "Maksimal Pemakaian",
      // },
      {
        field: "isClaim",
        label: "Status Voucher",
      },
    ],
    rows: selectTrx.map(item => {
      return {
        ...item,
        trx_amount: `Rp ${parseInt(item.trx_amount).toLocaleString()}`,
        voc_expired_at: moment(item.voc_expired_at).format("DD MMM YYYY HH:mm:ss"),
        isClaim: item.isClaim ? <Badge color="primary">Claimed</Badge> : <Badge color="danger">Not Claimed</Badge>
      }
    })
  }

  const renderModalTrx = () => {
    return (
      <Modal
        isOpen={modalTrx}
        role="dialog"
        autoFocus={true}
        scrollable
        size="xl"
      >
        <ModalHeader toggle={() => toggleModalTrx([])}>Detail Transaksi {fullName && fullName}</ModalHeader>
        <ModalBody>
          <MDBDataTable
            responsive
            bordered
            data={tableDetailTrx}
            striped
            className="mt-2"
            disableRetreatAfterSorting
          />
        </ModalBody>
      </Modal>
    )
  }

  const tableVoucher = {
    columns: [
      {
        field: "code",
        label: "Kode Voucher",
        sort: "asc",
      },
      {
        field: "max",
        label: "Maksimum Penggunaan",
      },
      {
        field: "remaining",
        label: "Sisa Pemakaian",
      },
      {
        field: "totalToGetRewards",
        label: "Total Pengumpulan",
      },
      {
        field: "expired_at",
        label: "Tanggal Kadaluarsa",
      },
      {
        field: "campaign",
        label: "Kampanye",
      },
      {
        field: "newFundingUser",
        label: "Spesifikasi Pengguna Voucher",
      },
      {
        field: "description",
        label: "Deskripsi",
      },
      {
        field: "isActive",
        label: "Status",
        sort: "asc",
      },
      {
        field: "creator",
        label: "Dibuat Oleh",
        sort: "asc",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: vouchers.map(item => {
      return {
        ...item,
        newFundingUser: item.newFundingUser === 0 ? "Semua Pengguna" : "Semua Pengguna yang belum memiliki Transaksi",
        expired_at: moment(item.expired_at).format("DD MMM YYYY HH:mm:ss"),
        creator: `${item.creator.first_name} ${item.creator.last_name}`,
        isActive: item.isActive === 1 ? <Badge color="primary">Aktif</Badge> : <Badge color="secondary">Tidak Aktif</Badge>,
        campaign: item.isAllCampaign === 0 ? item.campaign.title : "Semua Kampanye",
        action: <Button
                  className="btn-sm"
                  color="secondary"
                  onClick={() => toggleModalUpdateVoucher(item)}
                >
                  <i class='bx bxs-edit'></i>
                </Button>,
      }
    })
  }

  const tableVoucherClaim = {
    columns: [
      {
        field: "fullName",
        label: "Nama",
        sort: "asc",
      },
      {
        field: "email",
        label: "Email",
        sort: "asc",
      },
      {
        field: "phone_number",
        label: "Telepon",
      },
      {
        field: "totalClaim",
        label: "Total Claim",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: voucherClaim.map(item => {
      return {
        ...item,
        action: <Button
                  className="btn-sm"
                  color="secondary"
                  onClick={() => {
                    setFullName(item.fullName)
                    toggleModalTrx(item.dataTrx)
                  }}
                >
                  <i className="fas fa-eye"></i>
                </Button>,
      }
    })
  }

  const loadOptions = (inputValue, callback) => {
    let query = `?search=${inputValue}`
    setTimeout(async() => {
      const data = await getCampaignSelector(token, query)
      callback(data.data.map(item => {
        return {label: item.title, value: item.id}
      }))
    })
  };

  if(load) return <Loading title="Voucher" breadcrumbItem="Voucher" />

  return (
    <React.Fragment>
      {renderCreateVoucer()}
      {renderUpdateVoucer()}
      {renderModalTrx()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Voucher" breadcrumbItem="Voucher" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Daftar Voucher
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Voucher Claimed
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModalCreateVoucher}>+ Buat Voucher</Button>
                      </div>
                      
                      <MDBDataTable
                        responsive
                        bordered
                        data={tableVoucher}
                        striped
                        className="mt-3"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <FormGroup row className="mt-4">
                        <Label sm={2}>Pilih Kampanye</Label>
                        <Col sm={6}>
                          <AsyncSelect 
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            onChange={(e) => {
                              // setItem(e.value)
                              setItemSelect(e)
                            }}
                            value={itemSelect}

                          />
                        </Col>
                      </FormGroup>
                      <MDBDataTable
                        responsive
                        bordered
                        data={tableVoucherClaim}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Voucher)
