import React, { useState } from "react"
import AvField from "availity-reactstrap-validation/lib/AvField"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"
import { Button } from "reactstrap"

const PInput = ({
  type = "text",
  name,
  label,
  placeholder = "",
  value = "",
  disabled = false,
  validate = {},
  helpMessage,
  className,
  style,
  options = [],
  onChange,
  requiredLabel,
  delFile,
  handleDelFile,
  multiple,
  ...props
}) => {
  const [file, setFile] = useState(null)

  if (type === "text") {
    return (
      <AvField
        type={type}
        name={name}
        label={
          label && (
            <div>
              {label}
              {validate.required && validate.required.value && (
                <span className="text-danger">*</span>
              )}
            </div>
          )
        }
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        validate={validate}
        helpMessage={helpMessage}
        className={`${className} ${disabled ? "disabled-input-custom" : ""}`}
        style={style}
        onChange={onChange}
        {...props}
      />
    )
  }

  if (type === "number") {
    return (
      <AvField
        type={type}
        name={name}
        label={
          label && (
            <div>
              {label}
              {validate.required && validate.required.value && (
                <span className="text-danger">*</span>
              )}
            </div>
          )
        }
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        validate={validate}
        helpMessage={helpMessage}
        className={`${className} ${disabled ? "disabled-input-custom" : ""}`}
        style={style}
        onChange={onChange}
        onWheel={e => e.target.blur()}
        {...props}
      />
    )
  }

  if (type === "file") {
    return (
      <AvGroup className="position-relative">
        {delFile && value && value.name ? (
          <i
            className="bx bx-x font-22 position-absolute cursor-pointer"
            style={{ right: "0" }}
            onClick={handleDelFile}
          ></i>
        ) : null}
        {label && (
          <label>
            {label}
            {(validate && validate.required && validate.required.value) ||
            requiredLabel ? (
              <span className="text-danger">*</span>
            ) : (
              ""
            )}
          </label>
        )}
        <div className="custom-file">
          <AvInput
            type={type}
            name={name}
            label={
              label && (
                <div>
                  {label}
                  {validate.required && <span className="text-danger">*</span>}
                </div>
              )
            }
            id="customFile"
            onChange={onChange}
            required={
              validate && validate.required ? validate.required.value : false
            }
            helpMessage={helpMessage}
            className={`custom-file-input cp-custom ${className} ${
              disabled ? "disabled-input-custom" : ""
            }`}
            style={style}
            disabled={disabled}
            multiple={multiple}
            {...props}
          />
          <small class="form-text text-muted">{helpMessage}</small>
          <AvFeedback>Berkas harus diisi</AvFeedback>
          <label
            style={{ color: "#636c72" }}
            className={`custom-file-label ${
              disabled && "disabled-input-custom"
            }`}
            htmlFor="customFile"
          >
            {value ? value.name : "Pilih Berkas"}
          </label>
        </div>
      </AvGroup>
    )
  }

  return (
    <AvField
      type={type}
      name={name}
      label={
        label && (
          <div>
            {label}
            {validate.required && validate.required.value && (
              <span className="text-danger">*</span>
            )}
          </div>
        )
      }
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      validate={validate}
      helpMessage={helpMessage}
      className={`${className} ${disabled ? "disabled-input-custom" : ""}`}
      style={style}
      onChange={onChange}
      {...props}
    >
      <option value="">Pilih</option>
      {options.map(item => {
        return (
          <option value={item.uuid || item.id}>
            {item.title || item.name}
          </option>
        )
      })}
    </AvField>
  )
}

export default PInput
