import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { connect } from "react-redux"
import { API_URL } from "helpers/api_url"
import { getCities, getCitiesAll, getDistrict, getDistrictAll, getOrganization, getProjectEdit, getSubDistrict, getSubDistrictAll, postEditProperty, postEditUkm, postUKMtwo } from "helpers/backend_helpers"
import { getBussinesEntity, getCountry, getPropertyOwner, getProvince, getProyekType, getProyekTypeDetail, getSectorIndustry, getSubmitActor } from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Row } from "reactstrap"
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

// import SignaturePad from 'react-signature-pad-wrapper'

let signaturePad;

const INIT_MASTER_DATA = {
  business_entity: [],
  project_type: [],
  sector_industry: [],
  property_ownership: [],
  country: [],
  province: [],
  city: [],
  district: [],
  sub_district: [],
  submit_actor: [],
  project_type_detail: [],
  organitation: [],
}

const INIT_FILES = {
  file_company_profile: null,
  // bank_account_papper_last_three_month: null,
  bank_account_papper_last_six_month: null,
  file_contract: null,
}

const INIT_FILES_TWO = {
  file_ktp_direktur: null,
  file_npwp_company: null,
  file_nib_company: null,
  file_siup_company: null,
  file_akta_company: null,
  file_akta_last_change_number: null,
  file_sk_kemenkumham: null,
  file_organitation: null,
}

const FormUkm = props => {
  const params = props.match.params.uuid
  const token = localStorage.getItem("token")
  const [project, setProject] = useState(null)
  const [masterData, setMasterData] = useState(INIT_MASTER_DATA)
  const [load, setLoad] = useState(false)
  const [file, setFile] = useState(INIT_FILES)
  const [fileTwo, setFileTwo] = useState(INIT_FILES_TWO)
  const [loadButt, setLoadButt] = useState(false)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoad(true)
    try {
      const data = await getProjectEdit(params, token)

      const businessEntity = await getBussinesEntity(token)
      const proyekType = await getProyekType(token)
      const sectorIndustry = await getSectorIndustry(token)
      const propertyOwner = await getPropertyOwner(token)
      const country = await getCountry(token)
      const province = await getProvince(token)
      const submitActor = await getSubmitActor(token)
      const proyekTypeDetail = await getProyekTypeDetail(token)
      const organ = await getOrganization(token)

      const kota = await getCities(data.data.project_company.company_address.province.uuid, token)
      const kecamatan = await getDistrict(data.data.project_company.company_address.city.uuid, token)
      const kelurahan = await getSubDistrict(data.data.project_company.company_address.district.uuid, token)

      setMasterData({
        ...masterData,
        business_entity: businessEntity.data,
        project_type: proyekType.data,
        sector_industry: sectorIndustry.data,
        property_ownership: propertyOwner.data,
        country: country.data,
        province: province.data,
        submit_actor: submitActor.data,
        proyekTypeDetail: proyekTypeDetail.data,
        organitation: organ.data,
        city: kota.data,
        district: kecamatan.data,
        sub_district: kelurahan.data
      })
      setProject(data.data)
      setLoad(false)
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
      setLoad(false)
    }
  }

  const handleNegara = (data) => {
    for(let i = 0; i < data.length; i++) {
      if(data[i].name === "Indonesia") {
        return data[i].uuid
      }
    }
  }

  const getKota = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kota = await getCities(param, token)
      setMasterData({
        ...masterData,
        city: kota.data,
        district: [],
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        city: [],
        district: [],
        sub_district: []
      })
    }
  }

  const GetKecamatan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kecamatan = await getDistrict(param, token)
      setMasterData({
        ...masterData,
        district: kecamatan.data,
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        district: [],
        sub_district: []
      })
    }
  }

  const getKelurahan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const subdistrict = await getSubDistrict(param, token)
      setMasterData({
        ...masterData,
        sub_district: subdistrict.data,
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        sub_district: []
      })
    }
  }

  const handleRegion = (e) => {
    if(e.target.name === 'province_id') {
      getKota(e.target.value)
    } else if(e.target.name === 'city_id') {
      GetKecamatan(e.target.value)
    } else if(e.target.name === 'district_id') {
      getKelurahan(e.target.value)
    }
  }

  const handleChangeFile = e => {
    setFile({
      ...file,
      [e.target.name]: e.target.files[0]
    })
  }

  const handleChangeFileTwo = e => {
    setFileTwo({
      ...fileTwo,
      [e.target.name]: e.target.files[0]
    })
  }
  
  const handleSubmitStepOne = async (e, value) => {
    
    let newValue = {
      ...value,
      ...file,
    }
    // console.log(newValue)
    const formData = new FormData()
    Object.keys(newValue).forEach(item => {
      if(newValue[item]) {
        formData.append(item, newValue[item])
      }
    })

    setLoadButt(true)
    try {
      await postEditUkm(params, formData, token)
      Toaster("success", "Sukses", "Berhasil ajukan proyek")
      setLoadButt(false)
      setRedirect(true)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }

  }

  

  // console.log(props.user.uuid === project.user.uuid)
  if(redirect) return <Redirect to="/proyeks" />

  // if(load || !project) return <Loading />
  if(load || !project) {
    return (
      <SkeletonLoad
        title=""
        breadcrumbItem=""
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton width={320} />
          {[1,2,3,4,5,6,7,8,9].map(item => {
            return (
              <Row>
                <Col sm={3}><Skeleton /></Col>
                <Col sm={9}><Skeleton /></Col>
              </Row>
            )
          })}
        </div>
      </SkeletonLoad>
    )
  }

  if(props.user.uuid !== project.user.uuid) return <Redirect to="/proyeks" />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">
                {project.status === 1 && "Formulir Pengajuan Proyek UKM Tahap 1"}
                {project.status === 2 && "Formulir Pengajuan Proyek UKM Tahap 1"}
                {project.status === 3 && "Formulir Pengajuan Proyek UKM Tahap 2"}
                {project.status === 4 && "Formulir Pengajuan Proyek UKM Tahap 2"}
                {project.status === 5 && "Formulir Pengajuan Proyek UKM Tahap 1 dan 2"}
                {project.status === 7 && "Formulir Pengajuan Proyek UKM Tahap 1 dan 2"}
                {project.status === 8 && "Formulir Pengajuan Proyek UKM Tahap 1 dan 2"}
                {project.status === 9 && "Formulir Pengajuan Proyek UKM Tahap 1 dan 2"}
                {project.status === 6 && "Formulir Pengajuan Proyek UKM Tahap 1 dan 2"}
              </CardTitle>
              <div className="mt-4">
                {project.status === 1 || project.status === 2 ?
                  <StepOne 
                    handleSubmitStepOne={handleSubmitStepOne}
                    handleChangeFile={handleChangeFile}
                    loadButt={loadButt}
                    masterData={masterData}
                    handleNegara={handleNegara}
                    handleRegion={handleRegion}
                    project={project}
                    file={file}
                  /> : null
                }
                {project.status === 3 || project.status === 4 ?
                  <StepTwo 
                    // handleSubmitStepTwo={handleSubmitStepTwo}
                    handleChangeFile={handleChangeFileTwo}
                    loadButt={loadButt}
                    project={project}
                    fileTwo={fileTwo}
                    setLoadButt={setLoadButt}
                    postUKMtwo={postUKMtwo}
                    setRedirect={setRedirect}
                    postEditUkm={postEditUkm}
                    params={params}
                    token={token}
                    masterData={masterData}
                  /> : null
                }
                {project.status === 5 || project.status === 6 || project.status === 7 || project.status === 8 || project.status === 9 ?
                <>
                  <StepOne 
                    handleSubmitStepOne={handleSubmitStepOne}
                    handleChangeFile={handleChangeFile}
                    loadButt={loadButt}
                    masterData={masterData}
                    handleNegara={handleNegara}
                    handleRegion={handleRegion}
                    project={project}
                    file={file}
                  />
                  <StepTwo 
                    // handleSubmitStepTwo={handleSubmitStepTwo}
                    handleChangeFile={handleChangeFileTwo}
                    loadButt={loadButt}
                    project={project}
                    fileTwo={fileTwo}
                    setLoadButt={setLoadButt}
                    postUKMtwo={postUKMtwo}
                    setRedirect={setRedirect}
                    postEditUkm={postEditUkm}
                    params={params}
                    token={token}
                    masterData={masterData}
                  />
                </> : null
                }
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(FormUkm)

