import React, { useEffect, useState } from "react"
import Webcam from "react-webcam"

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"

import img_guide from "../../assets/images/users/guide.png"

import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import {
  checkBankAccount,
  getBankWithSearch,
  getCampaignSelector,
  getCities,
  getCitiesAll,
  getDistrict,
  getDistrictAll,
  getSubDistrict,
  getSubDistrictAll,
  postStepOne,
  postStepThree,
  postStepTwo,
} from "helpers/backend_helpers"
import Toaster from "components/Common/Toast"
import { API_URL } from "helpers/api_url"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import moment from "moment"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"

import AsyncSelect from "react-select/async"

const INIT_KYC_STEP_ONE = {
  email: "",
  first_name: "",
  kyc_status: "",
  last_name: "",
  phone_number: "",
  // housing_name: "",
  province_id: "",
  city_id: "",
  district_id: "",
  sub_district_id: "",
  rt: "",
  rw: "",
  user_title_id: "",
  parent_name: "",
  place_of_birth: "",
  date_of_birth: "",
  religion_id: "",
  gender_id: "",
  married_status_id: "",
  education_id: "",
  job_id: "",
  job_sector_id: "",
  source_funding_id: "",
  month_in_come_id: "",
  address: "",
  home_address: "",
}

const INIT_KYC_STEP_TWO = {
  ktp_number: "",
  ktp_file: null,
  npwp_number: "",
  npwp_file: null,
  selfie_file: null,
  company_name: "",
  akta_pendirian: null,
  sk_akta_pendirian: null,
  akta_perubahan_terakhir: null,
  sk_perubahan_terakhir: null,
  no_nib: "",
  nib_file: null,
  no_siup: "",
  siup_file: null,
  file_support_sim: null,
  file_support_kk: null,
  file_support_passport: null,
}

const INIT_KYC_STEP_THREE = {
  name: "",
  account_number: "",
  bank_id: "",
  branch: "",
}

const FormKYC = props => {
  const { user, masterData, setMasterData, getProfile, keepLogin, setLoad } =
    props
  const [kycOne, setKycOne] = useState(INIT_KYC_STEP_ONE)
  const [kycTwo, setKycTwo] = useState(INIT_KYC_STEP_TWO)
  const [kycThree, setKycThree] = useState(INIT_KYC_STEP_THREE)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(25 * 1)
  const [modal, setModal] = useState(false)
  const [checklistForm, setChecklistForm] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [modalSelfie, setModalSelfie] = useState(false)
  // const [home, setHome] = useState(false)

  const [bankAccount, setBankAccount] = useState(null)

  const handleRoles = () => {
    let result = []
    user.user_role.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  const toggleModal = () => setModal(!modal)
  const toggleModalSelfie = () => {
    setModalSelfie(!modalSelfie)
    setModeSelfie(true)
  }

  const renderModal = () => {
    return (
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // toggle={toggleModal}
        scrollable={true}
        // size="lg"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>Persetujuan</ModalHeader>
          <ModalBody>
            <div className="text-justify">
              Anda akan menggunakan tanda tangan elektronik untuk
              menandatanganani dokumen elektronik dengan PT Ethis Fintek
              Indonesia (ethis.co.id) PT Ethis Fintek Indonesia (ethis.co.id)
              bekerjasama dengan PT Privy Identitas Digital selaku Penyelenggara
              Tanda Tangan Elektronik dan Penyelenggara Sertifikasi Elektronik
              Indonesia yang mendapatkan pengakuan tersertifikasi pada
              Kementerian Komunikasi dan Informatika Republik Indonesia dengan
              merk PrivyID. Anda menyatakan setuju untuk mendaftar sebagai
              pengguna PrivyID untuk dibuatkan data pembuatan tanda tangan
              elektronik dan diterbitkan sertifikat elektronik oleh PT Privy
              Identitas Digital dalam rangka penggunaan layanan Tanda Tangan
              Elektronik untuk menandatangani dokumen elektronik. Anda memberi
              kuasa kepada PT Ethis Fintek Indonesia (ethis.co.id) untuk
              meneruskan data KTP, swafoto, nomor ponsel dan alamat surel Anda
              sebagai data pendaftaran kepada PT Privy Identitas Digital guna
              memenuhi ketentuan Peraturan Perundang-undangan, yaitu Peraturan
              Pemerintah Nomor 71 Tahun 2019 tentang Penyelenggara Sistem dan
              Transaksi Elektronik, dan Peraturan Kementerian Informasi dan
              Komunikasi Nomor 11 Tahun 2018. Dengan ini anda juga menyatakan
              setuju untuk terikat pada syarat dan ketentuan layanan PrivyID
              yang terdapat pada tautan berikut: Syarat dan Ketentuan PrivyID.
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <FormGroup check className="">
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={e => setChecklistForm(e.target.checked)}
                    checked={checklistForm}
                  />{" "}
                  <b>Setuju</b>
                </Label>
              </FormGroup>
              {checklistForm && (
                <Button
                  size=""
                  color="primary"
                  onClick={handleSubmitAgree}
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Lanjutkan"
                  )}
                </Button>
              )}
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderStepActive = step => {
    if (step > 4) {
      return 4
    }
    return step
  }

  useEffect(() => {
    setactiveTabProgress(renderStepActive(user.kyc_status))
    setprogressValue(25 * renderStepActive(user.kyc_status))
    setKYCAll()
  }, [user])

  const setKYCAll = () => {
    if (user.kyc_status > 1) {
      setKycOne({
        ...kycOne,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        // housing_name: user.address[0].housing_name,
        province_id: user.address[0].province.uuid,
        city_id: user.address[0].city.uuid,
        district_id: user.address[0].district.uuid,
        sub_district_id: user.address[0].sub_district.uuid,
        rt: user.address[0].rt,
        rw: user.address[0].rw,
        user_title_id: user.profile.title.uuid,
        parent_name: user.profile.parent_name,
        place_of_birth: user.profile.place_of_birth,
        date_of_birth: user.profile.date_of_birth,
        religion_id: user.profile.religion.uuid,
        gender_id: user.profile.gender.uuid,
        married_status_id: user.profile.married_status.uuid,
        education_id: user.profile.education.uuid,
        job_id: user.profile.job.uuid,
        job_sector_id: user.profile.job_sector.uuid,
        source_funding_id: user.profile.source_funding.uuid,
        month_in_come_id: user.profile.month_income.uuid,
        address: user.address[0].address,
        home_address: user.address[0].home_address,
      })
    }

    if (user.kyc_status > 2) {
      setKycTwo({
        ...kycTwo,
        ktp_number: user.profile.ktp_number,
        ktp_file: user.profile.ktp_file,
        npwp_number: user.profile.npwp_number,
        npwp_file: user.profile.npwp_file,
        selfie_file: user.profile.selfie_file,
        company_name: user.profile.company_name,
        akta_pendirian: user.profile.akta_pendirian,
        sk_akta_pendirian: user.profile.sk_akta_pendirian,
        akta_perubahan_terakhir: user.profile.akta_perubahan_terakhir,
        sk_perubahan_terakhir: user.profile.sk_perubahan_terakhir,
        no_nib: user.profile.no_nib,
        nib_file: user.profile.nib_file,
        no_siup: user.profile.no_siup,
        siup_file: user.profile.siup_file,
        file_support_sim: user.profile.file_support_sim,
        file_support_kk: user.profile.file_support_kk,
        file_support_passport: user.profile.file_support_passport,
      })
    }

    if (user.kyc_status > 3) {
      setKycThree({
        ...kycThree,
        name: user.account[0].name,
        account_number: user.account[0].account_number,
        bank_id: user.account[0].bank_account.uuid,
        branch: user.account[0].branch,
      })
      setBankAccount({
        label: user.account[0].bank_account.name,
        value: user.account[0].bank_account.uuid,
        illuma_code: user.account[0].bank_account.illuma_code,
      })
    }
  }

  const handleChange = e => {
    setKycOne({
      ...kycOne,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeTwo = e => {
    setKycTwo({
      ...kycTwo,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeThree = e => {
    setKycThree({
      ...kycThree,
      [e.target.name]: e.target.value,
    })
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4 && tab <= user.kyc_status) {
        setactiveTabProgress(tab)

        if (tab === 1) {
          setprogressValue(tab * 25)
        }
        if (tab === 2) {
          setprogressValue(tab * 25)
        }
        if (tab === 3) {
          setprogressValue(tab * 25)
        }
        if (tab === 4) {
          setprogressValue(tab * 25)
        }
      }
    }
  }

  const getKota = async uuid => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kota = await getCities(param, token)
      setMasterData({
        ...masterData,
        city: kota.data,
        district: [],
        subDistrict: [],
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        city: [],
        district: [],
        subDistrict: [],
      })
    }
  }

  const GetKecamatan = async uuid => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kecamatan = await getDistrict(param, token)
      setMasterData({
        ...masterData,
        district: kecamatan.data,
        subDistrict: [],
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        district: [],
        subDistrict: [],
      })
    }
  }

  const getKelurahan = async uuid => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const subdistrict = await getSubDistrict(param, token)
      setMasterData({
        ...masterData,
        subDistrict: subdistrict.data,
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        subDistrict: [],
      })
    }
  }

  const handleRegion = e => {
    if (e.target.name === "province_id") {
      getKota(e.target.value)
      setKycOne({
        ...kycOne,
        [e.target.name]: e.target.value,
        city_id: "",
        district_id: "",
        sub_district_id: "",
      })
    } else if (e.target.name === "city_id") {
      GetKecamatan(e.target.value)
      setKycOne({
        ...kycOne,
        [e.target.name]: e.target.value,
        district_id: "",
        sub_district_id: "",
      })
    } else if (e.target.name === "district_id") {
      getKelurahan(e.target.value)
      setKycOne({
        ...kycOne,
        [e.target.name]: e.target.value,
        sub_district_id: "",
      })
    } else {
      setKycOne({
        ...kycOne,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleSelectSourceFunding = () => {
    let arrDataSourceFunding = masterData.sourceFunding
    for (let i = 0; i < arrDataSourceFunding.length; i++) {
      if (arrDataSourceFunding[i].title == "Hasil Bisnis") {
        return arrDataSourceFunding[i].uuid
      }
    }
  }

  // console.log(handleSelectSourceFunding())

  const handleValidSubmitStepOne = async (e, value) => {
    const token = localStorage.getItem("token")

    if (value.phone_number[0] == "0") {
      value.phone_number = `62${value.phone_number.slice(1)}`
    }

    if (!handleRoles().includes("Lender")) {
      value.source_funding_id = handleSelectSourceFunding()
    }

    value.date_of_birth = kycOne.date_of_birth

    setLoadButt(true)
    try {
      await postStepOne({ ...value, isHomeAddress: true }, token)
      keepLogin(token, null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Data tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleValidSubmitStepTwo = async (e, value) => {
    const token = localStorage.getItem("token")
    if (!kycTwo.selfie_file)
      return Toaster("error", "Maaf", "Anda belum melakukan foto selfie")
    let newValue = {
      ...value,
      ...kycTwo,
      // ktp_file: kycTwo.ktp_file,
      // npwp_file: kycTwo.npwp_file,
      // selfie_file: kycTwo.selfie_file,
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postStepTwo(formData, token)
      keepLogin(token, null)
      Toaster("success", "Sukses", "Data tersimpan")
      setLoadButt(false)
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
      setLoadButt(false)
    }
  }

  const handleValidSubmitStepThree = async (e, value) => {
    const token = localStorage.getItem("token")
    if (!bankAccount) return Toaster("error", "Maaf", "Bank harus diisi")

    // toggleModal()

    const newValue = {
      bank_account_number: value.account_number,
      bank_code: bankAccount.illuma_code,
      fullName: value.name,
    }

    setLoadButt(true)
    try {
      await checkBankAccount(newValue, token)

      let intervalId = setInterval(async () => {
        let result = await checkBankAccount(newValue, token)
        if (
          (result && result.data.status == "SUCCESS") ||
          result.data.status == "FAILED"
        ) {
          clearInterval(intervalId)
          if (
            result.data.status == "SUCCESS" &&
            result.data.name_matching_result == "MATCH"
          ) {
            toggleModal()
          } else {
            Toaster(
              "error",
              "Maaf",
              "Nama atau Nomor Rekening Anda tidak ditemukan."
            )
          }
          setLoadButt(false)
        }
      }, 2000)
    } catch (error) {
      setLoadButt(false)
      console.log(error)
    }
  }

  const handleSubmitAgree = async () => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postStepThree(kycThree, token)
      keepLogin(token, null)
      Toaster("success", "Sukses", "Data tersimpan")
      setLoadButt(false)
    } catch (error) {
      setLoadButt(false)
      if (
        error.response &&
        error.response.data &&
        error.response.data.code == 422
      ) {
        let errArr = error.response.data.message.errors.map(
          item => item.messages[0]
        )
        Toaster("error", "Maaf", `${errArr.join(", ")}.`)
      } else {
        Toaster("error", "Maaf", error.response.data.message)
      }
    }
  }

  const renderImage = file => {
    if (!file) return null
    if (typeof file === "object") {
      return (
        <img
          src={file ? URL.createObjectURL(file) : null}
          alt="Unggah"
          className="img-thumbnail mb-2"
        />
      )
    }
    return (
      <img
        src={`${API_URL}${file}`}
        alt="Unggah"
        className="img-thumbnail mb-2"
      />
    )
  }

  const handleAlamat = e => {
    // console.log(e.target.checked)
    if (e.target.checked) {
      setKycOne({
        ...kycOne,
        home_address: kycOne.address,
      })
    } else {
      setKycOne({
        ...kycOne,
        home_address: "",
      })
    }
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const webcamRef = React.useRef(null)

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    // console.log(imageSrc)
    if (!imageSrc)
      return Toaster(
        "error",
        "Maaf",
        "Silahkan aktifkan kamera atau pilih unggah file."
      )
    // const blob = await fetch(imageSrc).then((res) => res.blob())
    // console.log(kycTwo)
    setKycTwo({
      ...kycTwo,
      selfie_file: dataURLtoFile(imageSrc, "selfie.jpeg"),
    })
    toggleModalSelfie()
  }, [webcamRef, setKycTwo, kycTwo, toggleModalSelfie])

  const videoConstraints = {
    width: 464,
    height: 320,
    facingMode: "user",
  }

  const [modeSelfie, setModeSelfie] = useState(true)

  const handleUnggahSelfie = (e, value) => {
    console.log(value)
  }

  const renderModalSelfie = () => {
    return (
      <Modal
        isOpen={modalSelfie}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // toggle={toggleModal}
        scrollable={true}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModalSelfie}>Foto Selfie</ModalHeader>
          <ModalBody>
            {modeSelfie ? (
              <div className="position-relative">
                <img
                  src={img_guide}
                  alt="guide"
                  className="position-absolute"
                  style={{ width: 464, height: 320 }}
                />
                <Webcam
                  audio={false}
                  height={320}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={464}
                  videoConstraints={videoConstraints}
                />
                <div className="d-flex justify-content-end align-items-center mt-2">
                  <Button color="primary" className="btn-sm" onClick={capture}>
                    Ambil Foto
                  </Button>
                  <span className="mx-2">atau</span>
                  <Button
                    color="primary"
                    className="btn-sm"
                    onClick={() => setModeSelfie(false)}
                  >
                    Unggah Foto
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleUnggahSelfie(e, v)
                  }}
                >
                  <Label className="mt-2">Unggah Foto Selfie</Label>
                  <span className="text-danger">*</span>
                  <div className="custom-file mb-3">
                    <AvGroup>
                      <AvInput
                        name="selfie_file"
                        type="file"
                        id="customFile"
                        className="custom-file-input"
                        onChange={e =>
                          setKycTwo({
                            ...kycTwo,
                            [e.target.name]: e.target.files[0],
                          })
                        }
                        required
                      />
                      <AvFeedback>Unggah foto selfie harus diisi</AvFeedback>
                      <label className="custom-file-label" htmlFor="customFile">
                        {kycTwo.selfie_file && kycTwo.selfie_file.name
                          ? kycTwo.selfie_file.name
                          : "jpg, jpeg atau png."}
                      </label>
                    </AvGroup>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mt-2">
                    <Button
                      color="secondary"
                      className="btn-sm"
                      onClick={() => setModeSelfie(true)}
                    >
                      Batal
                    </Button>
                    <span className="mx-1"></span>
                    <Button
                      color="primary"
                      className="btn-sm"
                      onClick={toggleModalSelfie}
                    >
                      Lanjutkan
                    </Button>
                  </div>
                </AvForm>
              </>
            )}
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderButtonFile = item => {
    if (typeof item === "string") {
      return (
        <Button
          className="btn-sm"
          style={{ marginTop: "-12px", marginBottom: "8px" }}
          disabled={!item}
          onClick={() => window.open(`${API_URL}${item}`, "_blank")}
        >
          <i className="fas fa-eye"></i>
        </Button>
      )
    }
    return null
  }

  const loadOptions = (inputValue, callback) => {
    const token = localStorage.getItem("token")
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getBankWithSearch(token, query)
      callback(
        data.data.map(item => {
          return {
            label: item.name,
            value: item.uuid,
            illuma_code: item.illuma_code,
          }
        })
      )
    })
  }

  console.log(user)

  return (
    <React.Fragment>
      {renderModal()}
      {renderModalSelfie()}
      <div id="progrss-wizard" className="twitter-bs-wizard">
        <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills rounded">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabProgress === 1,
              })}
              onClick={() => {
                toggleTabProgress(1)
              }}
            >
              <span className="step-number mr-2">01</span>
              Profil {user.lenderAsCompany ? "Direktur" : ""}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabProgress === 2,
              })}
              onClick={() => {
                toggleTabProgress(2)
              }}
            >
              <span className="step-number mr-2">02</span>
              Dokumen
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabProgress === 3,
              })}
              onClick={() => {
                toggleTabProgress(3)
              }}
            >
              <span className="step-number mr-2">03</span>
              Rekening {user.lenderAsCompany ? "Perusahaan" : ""}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabProgress === 4,
              })}
              onClick={() => {
                toggleTabProgress(4)
              }}
            >
              <span className="step-number mr-2">04</span>
              Konfirmasi
            </NavLink>
          </NavItem>
        </ul>

        <div id="bar" className="mt-4">
          <Progress color="success" striped animated value={progressValue} />
          <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
        </div>

        <TabContent
          activeTab={activeTabProgress}
          className="twitter-bs-wizard-tab-content"
        >
          <TabPane tabId={1}>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmitStepOne(e, v)
              }}
              model={kycOne}
            >
              <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                <Col xs={12} md={6}>
                  <PInput
                    name="first_name"
                    label="Nama Depan"
                    type="text"
                    value={user.first_name}
                    onChange={handleChange}
                    disabled={false}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Nama depan harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="last_name"
                    label="Nama Belakang"
                    type="text"
                    value={user.last_name}
                    onChange={handleChange}
                    disabled={false}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Nama Belakng harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="email"
                    label="Email"
                    type="text"
                    value={user.email}
                    onChange={handleChange}
                    disabled={true}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Email harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="phone_number"
                    label="No Handphone"
                    type="text"
                    value={user.phone_number}
                    // helpMessage="contoh: 081122334455"
                    onChange={handleChange}
                    disabled={false}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Nomor handphone harus diisi",
                      },
                      pattern: {
                        value: "^(^62|^08).{9,12}$",
                        errorMessage:
                          "Format No Handphone salah. Contoh: 62812xxx atau 0812xxx",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="user_title_id"
                    label="Panggilan"
                    type="select"
                    onChange={handleChange}
                    value={kycOne.user_title_id}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Panggilan harus diisi",
                      },
                    }}
                    options={masterData.title}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="address"
                    label="Alamat Sesuai KTP"
                    type="text"
                    value={kycOne.address}
                    onChange={handleChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Alamat harus diisi",
                      },
                    }}
                  />
                </Col>
                {/* <Col xs={12} md={6}>
                  <PInput
                    name="housing_name"
                    label="Nama Perumahan"
                    type="textarea"
                    value={kycOne.housing_name}
                    onChange={handleChange}
                    validate={{
                      required: {value: true, errorMessage: 'Nama perumahan harus diisi'},
                    }}
                  />
                </Col> */}
                <Col xs={12} md={3}>
                  <PInput
                    name="rt"
                    label="RT Sesuai KTP"
                    type="text"
                    value={kycOne.rt}
                    onChange={handleChange}
                    validate={{
                      required: { value: true, errorMessage: "RT harus diisi" },
                    }}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="rw"
                    label="RW Sesuai KTP"
                    type="text"
                    value={kycOne.rw}
                    onChange={handleChange}
                    validate={{
                      required: { value: true, errorMessage: "RW harus diisi" },
                    }}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="province_id"
                    label="Provinsi Sesuai KTP"
                    type="select"
                    onChange={handleRegion}
                    value={kycOne.province_id}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Provinsi harus diisi",
                      },
                    }}
                    options={masterData.province}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="city_id"
                    label="Kota Sesuai KTP"
                    type="select"
                    onChange={handleRegion}
                    value={kycOne.city_id}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Kota harus diisi",
                      },
                    }}
                    options={masterData.city}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="district_id"
                    label="Kecamatan Sesuai KTP"
                    type="select"
                    onChange={handleRegion}
                    value={kycOne.district_id}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Kecamatan harus diisi",
                      },
                    }}
                    options={masterData.district}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="sub_district_id"
                    label="Kelurahan Sesuai KTP"
                    type="select"
                    onChange={handleRegion}
                    value={kycOne.sub_district_id}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Kecamatan harus diisi",
                      },
                    }}
                    options={masterData.subDistrict}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="home_address"
                    label="Alamat Tempat Tinggal"
                    type="text"
                    value={kycOne.home_address}
                    onChange={handleChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Alamat tempat tinggal harus diisi",
                      },
                    }}
                  />
                  {user.kyc_status === 1 && (
                    <div style={{ marginTop: "-14px", fontSize: "11px" }}>
                      <PInput
                        type="checkbox"
                        name="isHomeAddress"
                        label="Ceklis jika sesuai dengan alamat KTP"
                        onChange={e => handleAlamat(e)}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="parent_name"
                    label="Nama Ibu Kandung"
                    type="text"
                    value={kycOne.parent_name}
                    onChange={handleChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Nama ibu kandung harus diisi",
                      },
                      pattern: {
                        value: "^[A-Za-z]+$",
                        errorMessage: "Format Nama Ibu harus huruf",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <PInput
                    name="place_of_birth"
                    label="Tempat Lahir"
                    type="text"
                    value={kycOne.place_of_birth}
                    onChange={handleChange}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Tempat lahir harus diisi",
                      },
                      pattern: {
                        value: "/^[A-Za-z ]+$/",
                        errorMessage: "Format Nama Ibu harus huruf",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  {/* <PInput
                    name="date_of_birth"
                    label="Tanggal Lahir"
                    type="date"
                    helpMessage="Bulan/Hari/Tahun"
                    value={kycOne.date_of_birth}
                    onChange={handleChange}
                    validate={{
                      required: {value: true, errorMessage: 'Tanggal lahir harus diisi'},
                    }}
                  /> */}
                  <div className="form-group mb-4">
                    <label>Tanggal Lahir</label>
                    <div className="input-group">
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Cth: 1986-01-30"
                        value={kycOne.date_of_birth}
                        options={{
                          altInput: true,
                          altFormat: "Y-m-d",
                          dateFormat: "Y-m-d",
                          allowInput: true,
                        }}
                        onChange={e => {
                          setKycOne({
                            ...kycOne,
                            date_of_birth: moment(e[0]).format("YYYY-MM-DD"),
                          })
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={9}></Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="religion_id"
                    label="Agama"
                    type="select"
                    value={kycOne.religion_id}
                    onChange={handleChange}
                    options={masterData.religion}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Agama harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="gender_id"
                    label="Jenis Kelamin"
                    type="select"
                    value={kycOne.gender_id}
                    onChange={handleChange}
                    options={masterData.gender}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Jenis kelamin harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="married_status_id"
                    label="Status Perkawinan"
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Status kawin harus diisi",
                      },
                    }}
                    value={kycOne.married_status_id}
                    onChange={handleChange}
                    options={masterData.married}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="education_id"
                    label="Pendidikan Terakhir"
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Pendidikan harus diisi",
                      },
                    }}
                    value={kycOne.education_id}
                    onChange={handleChange}
                    options={masterData.education}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="job_id"
                    label="Pekerjaan"
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Pekerjaan harus diisi",
                      },
                    }}
                    value={kycOne.job_id}
                    onChange={handleChange}
                    options={masterData.job}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="job_sector_id"
                    label="Bidang Pekerjaan"
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Bidang pekerjaan harus diisi",
                      },
                    }}
                    value={kycOne.job_sector_id}
                    onChange={handleChange}
                    options={masterData.jobSector}
                  />
                </Col>
                {handleRoles().includes("Lender") && (
                  <Col xs={12} md={6}>
                    <PInput
                      name="source_funding_id"
                      label="Sumber Dana"
                      type="select"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Sumber dana harus diisi",
                        },
                      }}
                      value={kycOne.source_funding_id}
                      onChange={handleChange}
                      options={masterData.sourceFunding}
                    />
                  </Col>
                )}
                <Col xs={12} md={6}>
                  <PInput
                    name="month_in_come_id"
                    label="Penghasilan Perbulan"
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Penghasilan harus diisi",
                      },
                    }}
                    value={kycOne.month_in_come_id}
                    onChange={handleChange}
                    options={masterData.getIncome}
                  />
                </Col>
              </Row>
              <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                <Col>
                  <div className="mt-5">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      disabled={
                        (user.kyc_status > 1 && user.kyc_status < 6) || loadButt
                      }
                    >
                      {loadButt ? (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                          Loading
                        </>
                      ) : (
                        "Simpan >"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </TabPane>
          <TabPane tabId={2}>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmitStepTwo(e, v)
              }}
            >
              {user.lenderAsCompany ? (
                <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                  <Col>
                    <Card className="border">
                      <CardHeader>Dokumen Identitas Direktur</CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs={12} md={6}>
                            <PInput
                              name="ktp_number"
                              label="NIK Direktur"
                              type="text"
                              helpMessage="ex. 1234xxx"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "NIK harus diisi",
                                },
                                pattern: {
                                  value: "^[0-9]+$",
                                  errorMessage: "Format NIK harus angka",
                                },
                                minLength: {
                                  value: 16,
                                  errorMessage: "Panjang harus 16 digit",
                                },
                                maxLength: {
                                  value: 16,
                                  errorMessage: "Panjang harus 16 digit",
                                },
                              }}
                              value={kycTwo.ktp_number}
                              onChange={handleChangeTwo}
                            />
                          </Col>
                          <Col xs={12} md={6}></Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="ktp_file"
                              label="Unggah KTP Direktur"
                              type="file"
                              helpMessage="jpg, jpeg atau png."
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "KTP Direktur harus diisi",
                                },
                              }}
                              value={kycTwo.ktp_file}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  ktp_file: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({ ...kycTwo, ktp_file: null })
                              }
                            />
                            {renderImage(kycTwo.ktp_file)}
                          </Col>
                          <Col xs={12} md={6} className="position-relative">
                            {kycTwo.selfie_file && kycTwo.selfie_file.name ? (
                              <i
                                className="bx bx-x font-22 position-absolute cursor-pointer"
                                style={{ right: "12px" }}
                                onClick={() =>
                                  setKycTwo({ ...kycTwo, selfie_file: null })
                                }
                              ></i>
                            ) : null}
                            <Label className="">Foto Selfie Direktur</Label>
                            <span className="text-danger">*</span>
                            <InputGroup
                              className="mb-4"
                              onClick={toggleModalSelfie}
                              style={{ cursor: "pointer" }}
                            >
                              <Input
                                placeholder="Selfie"
                                disabled
                                className="disabled-input-custom"
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-camera"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {renderImage(kycTwo.selfie_file)}
                            <FormText color="muted">
                              *Jika anda menggunakan Kamera Mohon untuk{" "}
                              <b>Allow/Izinkan</b> penggunaan kamera pada
                              perangkat Anda dan Pastikan Anda menggunakan
                              browser <b>Google Chrome</b> atau{" "}
                              <b>Mozilla Firefox</b> versi terbaru.
                            </FormText>
                          </Col>

                          {kycTwo.file_support_sim && (
                            <Col xs={12} md={6}>
                              <PInput
                                name="file_support_sim"
                                label="Unggah SIM"
                                type="file"
                                helpMessage="jpg, jpeg atau png"
                                onChange={e =>
                                  setKycTwo({
                                    ...kycTwo,
                                    file_support_sim: e.target.files[0],
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Unggah SIM harus diisi",
                                  },
                                }}
                                value={kycTwo.file_support_sim}
                                disabled={true}
                                requiredLabel={true}
                                // delFile={true}
                                handleDelFile={() =>
                                  setKycTwo({
                                    ...kycTwo,
                                    file_support_sim: null,
                                  })
                                }
                              />
                              {renderImage(kycTwo.file_support_sim)}
                            </Col>
                          )}

                          {kycTwo.file_support_kk && (
                            <Col xs={12} md={6}>
                              <PInput
                                name="file_support_kk"
                                label="Unggah KK"
                                type="file"
                                helpMessage="jpg, jpeg atau png"
                                onChange={e =>
                                  setKycTwo({
                                    ...kycTwo,
                                    file_support_kk: e.target.files[0],
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Unggah KK harus diisi",
                                  },
                                }}
                                value={kycTwo.file_support_kk}
                                disabled={true}
                                requiredLabel={true}
                                // delFile={true}
                                handleDelFile={() =>
                                  setKycTwo({
                                    ...kycTwo,
                                    file_support_kk: null,
                                  })
                                }
                              />
                              {renderImage(kycTwo.file_support_kk)}
                            </Col>
                          )}

                          {kycTwo.file_support_passport && (
                            <Col xs={12} md={6}>
                              <PInput
                                name="file_support_passport"
                                label="Unggah PASSPORT"
                                type="file"
                                helpMessage="jpg, jpeg atau png"
                                onChange={e =>
                                  setKycTwo({
                                    ...kycTwo,
                                    file_support_passport: e.target.files[0],
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Unggah PASSPORT harus diisi",
                                  },
                                }}
                                value={kycTwo.file_support_passport}
                                disabled={true}
                                requiredLabel={true}
                                // delFile={true}
                                handleDelFile={() =>
                                  setKycTwo({
                                    ...kycTwo,
                                    file_support_passport: null,
                                  })
                                }
                              />
                              {renderImage(kycTwo.file_support_passport)}
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="border">
                      <CardHeader>Dokumen Legalitas Perusahaan</CardHeader>
                      <CardBody>
                        <Row>
                          <Col xs={12} md={6}>
                            <PInput
                              name="company_name"
                              label="Nama Perusahaan"
                              type="text"
                              helpMessage="cth. PT Ethis Fintek Indonesia"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Nama Perusahaan harus diisi",
                                },
                              }}
                              value={kycTwo.company_name}
                              onChange={handleChangeTwo}
                            />
                          </Col>
                          <Col xs={12} md={6}></Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="npwp_number"
                              label="NPWP Perusahaan"
                              type="text"
                              helpMessage="ex. 1234xxx"
                              value={kycTwo.npwp_number}
                              onChange={handleChangeTwo}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "NPWP harus diisi",
                                },
                                pattern: {
                                  value: "^[0-9]+$",
                                  errorMessage: "Format NPWP harus angka",
                                },
                                minLength: {
                                  value: 15,
                                  errorMessage: "Panjang harus 15 digit",
                                },
                                maxLength: {
                                  value: 15,
                                  errorMessage: "Panjang harus 15 digit",
                                },
                              }}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="npwp_file"
                              label="Unggah NPWP Perusahaan"
                              type="file"
                              helpMessage="jpg, jpeg atau png."
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "NPWP Perusahaan harus diisi",
                                },
                              }}
                              value={kycTwo.npwp_file}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  npwp_file: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({ ...kycTwo, npwp_file: null })
                              }
                            />
                            {renderButtonFile(kycTwo.npwp_file)}
                            {/* {renderImage(kycTwo.npwp_file)} */}
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="akta_pendirian"
                              label="Akta Pendirian"
                              type="file"
                              helpMessage="Pdf File"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Akta Pendirian harus diisi",
                                },
                              }}
                              value={kycTwo.akta_pendirian}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  akta_pendirian: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({ ...kycTwo, akta_pendirian: null })
                              }
                            />
                            {renderButtonFile(kycTwo.akta_pendirian)}
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="sk_akta_pendirian"
                              label="SK Akta Pendirian"
                              type="file"
                              helpMessage="Pdf File"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "SK Akta Pendirian harus diisi",
                                },
                              }}
                              value={kycTwo.sk_akta_pendirian}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  sk_akta_pendirian: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({
                                  ...kycTwo,
                                  sk_akta_pendirian: null,
                                })
                              }
                            />
                            {renderButtonFile(kycTwo.sk_akta_pendirian)}
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="akta_perubahan_terakhir"
                              label="Akta Perubahan Terakhir"
                              type="file"
                              helpMessage="Pdf File"
                              // validate={{
                              //   required: {value: true, errorMessage: 'Akta Perubahan Terakhir harus diisi'},
                              // }}
                              value={kycTwo.akta_perubahan_terakhir}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  akta_perubahan_terakhir: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({
                                  ...kycTwo,
                                  akta_perubahan_terakhir: null,
                                })
                              }
                            />
                            {renderButtonFile(kycTwo.akta_perubahan_terakhir)}
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="sk_perubahan_terakhir"
                              label="SK Perubahan Terakhir"
                              type="file"
                              helpMessage="Pdf File"
                              // validate={{
                              //   required: {value: true, errorMessage: 'Akta Perubahan Terakhir harus diisi'},
                              // }}
                              value={kycTwo.sk_perubahan_terakhir}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  sk_perubahan_terakhir: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({
                                  ...kycTwo,
                                  sk_perubahan_terakhir: null,
                                })
                              }
                            />
                            {renderButtonFile(kycTwo.sk_perubahan_terakhir)}
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="no_nib"
                              label="NIB Perusahaan"
                              type="text"
                              // helpMessage="Pdf File"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "NIB Perusahaan harus diisi",
                                },
                              }}
                              value={kycTwo.no_nib}
                              onChange={e =>
                                setKycTwo({ ...kycTwo, no_nib: e.target.value })
                              }
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="nib_file"
                              label="Unggah NIB Perusahaan"
                              type="file"
                              helpMessage="Pdf File"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Dokumen NIB harus diisi",
                                },
                              }}
                              value={kycTwo.nib_file}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  nib_file: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({ ...kycTwo, nib_file: null })
                              }
                            />
                            {renderButtonFile(kycTwo.nib_file)}
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="no_siup"
                              label="Nomor SIUP / Izin Usaha"
                              type="text"
                              // helpMessage="Pdf File"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    "Nomor SIUP Perusahaan harus diisi",
                                },
                              }}
                              value={kycTwo.no_siup}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  no_siup: e.target.value,
                                })
                              }
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <PInput
                              name="siup_file"
                              label="Unggah SIUP / Izin Usaha"
                              type="file"
                              helpMessage="Pdf File"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Dokumen SIUP harus diisi",
                                },
                              }}
                              value={kycTwo.siup_file}
                              onChange={e =>
                                setKycTwo({
                                  ...kycTwo,
                                  siup_file: e.target.files[0],
                                })
                              }
                              delFile={true}
                              handleDelFile={() =>
                                setKycTwo({ ...kycTwo, siup_file: null })
                              }
                            />
                            {renderButtonFile(kycTwo.siup_file)}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                  <Col xs={12} md={6}>
                    <PInput
                      name="ktp_number"
                      label="NIK"
                      type="text"
                      helpMessage="ex. 1234xxx"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "NIK harus diisi",
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Format KTP harus angka",
                        },
                        minLength: {
                          value: 16,
                          errorMessage: "Panjang harus 16 digit",
                        },
                        maxLength: {
                          value: 16,
                          errorMessage: "Panjang harus 16 digit",
                        },
                      }}
                      value={kycTwo.ktp_number}
                      onChange={handleChangeTwo}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="npwp_number"
                      label="NPWP"
                      type="text"
                      helpMessage="ex. 1234xxx"
                      value={kycTwo.npwp_number}
                      onChange={handleChangeTwo}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "NPWP harus diisi",
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Format NPWP harus angka",
                        },
                        minLength: {
                          value: 15,
                          errorMessage: "Panjang harus 15 digit",
                        },
                        maxLength: {
                          value: 15,
                          errorMessage: "Panjang harus 15 digit",
                        },
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="ktp_file"
                      label="Unggah KTP"
                      type="file"
                      helpMessage="jpg, jpeg atau png"
                      onChange={e =>
                        setKycTwo({ ...kycTwo, ktp_file: e.target.files[0] })
                      }
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Unggah KTP harus diisi",
                        },
                      }}
                      value={kycTwo.ktp_file}
                      delFile={true}
                      handleDelFile={() =>
                        setKycTwo({ ...kycTwo, ktp_file: null })
                      }
                    />
                    {renderImage(kycTwo.ktp_file)}
                  </Col>
                  <Col xs={12} md={6}>
                    <PInput
                      name="npwp_file"
                      label="Unggah NPWP"
                      type="file"
                      helpMessage="jpg, jpeg atau png"
                      onChange={e =>
                        setKycTwo({ ...kycTwo, npwp_file: e.target.files[0] })
                      }
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Unggah NPWP harus diisi",
                        },
                      }}
                      value={kycTwo.npwp_file}
                      delFile={true}
                      handleDelFile={() =>
                        setKycTwo({ ...kycTwo, npwp_file: null })
                      }
                    />
                    {renderImage(kycTwo.npwp_file)}
                  </Col>
                  <Col xs={12} md={6} className="position-relative mt-3">
                    {kycTwo.selfie_file && kycTwo.selfie_file.name ? (
                      <i
                        className="bx bx-x font-22 position-absolute cursor-pointer"
                        style={{ right: "12px" }}
                        onClick={() =>
                          setKycTwo({ ...kycTwo, selfie_file: null })
                        }
                      ></i>
                    ) : null}
                    <Label className="">Unggah Foto Selfie</Label>
                    <span className="text-danger">*</span>
                    <InputGroup
                      className="mb-3"
                      onClick={toggleModalSelfie}
                      style={{ cursor: "pointer" }}
                    >
                      <Input
                        placeholder="Selfie"
                        disabled
                        className="disabled-input-custom"
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="fas fa-camera"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {renderImage(kycTwo.selfie_file)}
                    <FormText color="muted">
                      *Jika anda menggunakan Kamera Mohon untuk{" "}
                      <b>Allow/Izinkan</b> penggunaan kamera pada perangkat Anda
                      dan Pastikan Anda menggunakan browser <b>Google Chrome</b>{" "}
                      atau <b>Mozilla Firefox</b> versi terbaru.
                    </FormText>
                  </Col>

                  {kycTwo.file_support_sim && (
                    <Col xs={12} md={6}>
                      <PInput
                        name="file_support_sim"
                        label="Unggah SIM"
                        type="file"
                        helpMessage="jpg, jpeg atau png"
                        onChange={e =>
                          setKycTwo({
                            ...kycTwo,
                            file_support_sim: e.target.files[0],
                          })
                        }
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Unggah SIM harus diisi",
                          },
                        }}
                        value={kycTwo.file_support_sim}
                        disabled={true}
                        requiredLabel={true}
                        // delFile={true}
                        handleDelFile={() =>
                          setKycTwo({
                            ...kycTwo,
                            file_support_sim: null,
                          })
                        }
                      />
                      {renderImage(kycTwo.file_support_sim)}
                    </Col>
                  )}

                  {kycTwo.file_support_kk && (
                    <Col xs={12} md={6}>
                      <PInput
                        name="file_support_kk"
                        label="Unggah KK"
                        type="file"
                        helpMessage="jpg, jpeg atau png"
                        onChange={e =>
                          setKycTwo({
                            ...kycTwo,
                            file_support_kk: e.target.files[0],
                          })
                        }
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Unggah KK harus diisi",
                          },
                        }}
                        value={kycTwo.file_support_kk}
                        disabled={true}
                        requiredLabel={true}
                        // delFile={true}
                        handleDelFile={() =>
                          setKycTwo({
                            ...kycTwo,
                            file_support_kk: null,
                          })
                        }
                      />
                      {renderImage(kycTwo.file_support_kk)}
                    </Col>
                  )}

                  {kycTwo.file_support_passport && (
                    <Col xs={12} md={6}>
                      <PInput
                        name="file_support_passport"
                        label="Unggah PASSPORT"
                        type="file"
                        helpMessage="jpg, jpeg atau png"
                        onChange={e =>
                          setKycTwo({
                            ...kycTwo,
                            file_support_passport: e.target.files[0],
                          })
                        }
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Unggah PASSPORT harus diisi",
                          },
                        }}
                        value={kycTwo.file_support_passport}
                        disabled={true}
                        requiredLabel={true}
                        // delFile={true}
                        handleDelFile={() =>
                          setKycTwo({
                            ...kycTwo,
                            file_support_passport: null,
                          })
                        }
                      />
                      {renderImage(kycTwo.file_support_passport)}
                    </Col>
                  )}
                </Row>
              )}
              <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                <Col>
                  <div className="mt-5">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      disabled={
                        (user.kyc_status > 2 && user.kyc_status < 6) || loadButt
                      }
                    >
                      {loadButt ? (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                          Loading
                        </>
                      ) : (
                        "Simpan >"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </TabPane>
          <TabPane tabId={3}>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmitStepThree(e, v)
              }}
            >
              <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                <Col xs={12} md={6}>
                  <PInput
                    name="name"
                    label="Nama Pemilik Rekening"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Pemilik rekening harus diisi",
                      },
                    }}
                    value={kycThree.name}
                    onChange={handleChangeThree}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="account_number"
                    label="Nomor Rekening"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Nomor rekening harus diisi",
                      },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Format Rekening harus angka",
                      },
                    }}
                    value={kycThree.account_number}
                    onChange={handleChangeThree}
                  />
                </Col>
                <Col xs={12} md={6}>
                  {/* <PInput
                    name="bank_id"
                    label="Bank"
                    type="select"
                    validate={{
                      required: {value: true, errorMessage: 'Bank harus diisi'},
                    }}
                    value={kycThree.bank_id}
                    onChange={handleChangeThree}
                    options={masterData.bank}
                  /> */}
                  <Label>
                    Bank<span className="text-danger">*</span>
                  </Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={e => {
                      setBankAccount(e)
                      setKycThree({ ...kycThree, bank_id: e.value })
                    }}
                    value={bankAccount}
                    // onInputChange={handleInputSearch}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <PInput
                    name="branch"
                    label="Cabang"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Cabang bank harus diisi",
                      },
                    }}
                    value={kycThree.branch}
                    onChange={handleChangeThree}
                  />
                </Col>
                <Col xs={12} md={12}>
                  <PInput
                    name="aggrement"
                    label="Dengan ini saya menyatakan bahwa data yang saya isikan adalah benar"
                    type="checkbox"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Pernyataan ini harus diisi",
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
                <Col>
                  <div className="mt-5">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      disabled={
                        (user.kyc_status > 3 && user.kyc_status < 6) || loadButt
                      }
                    >
                      {loadButt ? (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                          Loading
                        </>
                      ) : (
                        "Simpan >"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </TabPane>
          <TabPane tabId={4}>
            <div className="row justify-content-center">
              <Col lg="6">
                <div className="text-center">
                  <div className="mb-4">
                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                  </div>
                  <div>
                    <h5>Sedang verifikasi</h5>
                    <p className="text-muted">
                      Konfirmasi data menunggu 3 x 24 jam pada hari kerja.
                    </p>
                    {/* <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Scrollable modal
                    </button> */}
                  </div>
                </div>
              </Col>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  )
}

export default FormKYC
