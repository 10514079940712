import React from "react"
import { Link } from "react-router-dom"
import { Container } from "reactstrap"
import { Row, Col } from "reactstrap"

import Logo from "../Image/Logoethis.png"
import sectigo from "../../../assets/images/ethis/sectigo_trust_seal_lg_140x54.png"
import logo_footer from "../../../assets/images/ethis/favicon_ethis_baru.png"
import haki from "../../../assets/images/ethis/haki.jpeg"

const Footer = () => {
  return (
    <React.Fragment>

      <div className="mt-3 footerback">
        <Container className="py-4">
          <h3 className="text-white bold py-4">Perhatian:</h3>
          <Row>
            <Col xs="12" lg="6" className="f-11 text-justify">
              <p>
                1. Layanan Pembiayaan Syariah Berbasis Teknologi Informasi (P2P
                Financing) merupakan kesepakatan perdata antara pemberi pembiayaan
                dengan penerima pembiayaan, sehingga segala resiko akan ditanggung
                oleh masing-masing pihak.
              </p>
              <p>
                2. Risiko gagal bayar akan ditanggung oleh pemberi pembiayaan,
                diluar fraud atau mismanagement. Penerima pembiayaan akan
                bertanggung jawab apabila terjadi fraud atau mismanagement
                sebagaimana ketentuan bagi resiko (Risk Sharing) secara syariah.
                Tidak ada lembaga atau otoritas negara yang bertanggung jawab atas
                risiko pembiayaan atau gagal bayar ini atau mengkompensasi pihak
                manapun atas kerugian, kerusakan, biaya atau konsekuensi yang
                timbul dari sehubungan dengan hal tersebut.
              </p>
              <p>
                3. Penyelenggara dengan persetujuan dari masing-masing pengguna
                (pemberi pembiayaan dan/atau penerima pembiayaan) mengakses,
                memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi
                pengguna (“Pemanfaatan Data”) pada atau di dalam benda, perangkat
                elektronik (termasuk smartphone atau telepon seluler), perangkat
                keras (hardware) maupun lunak (software), dokumen elektronik,
                aplikasi atau sistem elektronik milik Pengguna atau yang dikuasai
                Pengguna, dengan memberitahukan tujuan, batasan dan mekanisme
                Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan
                sebelum memperoleh persetujuan yang dimaksud.
              </p>
              <p>
                4. Pemberi pembiayaan yang belum memiliki pengetahuan dan
                pengalaman terhadap layanan pembiayaan ini, disarankan agar tidak
                menggunakan layanan pembiayaan ini.
              </p>
            </Col>
            <Col xs="12" lg="6" className="f-11 text-justify">
              <p>
                5. Penerima pembiayaan wajib mempertimbangkan tingkat bagi hasil /
                margin / ujroh serta biaya – biaya lainnya sesuai dengan kemampuan
                dalam melunasi pembiayaan.
              </p>
              <p>
                6. Setiap kecurangan yang terjadi akan tercatat secara elektronik
                di dunia maya dan dapat diketahui oleh masyarakat luas melalui
                media sosial.
              </p>
              <p>
                7. Pengguna harus membaca dan memahami informasi ini sebelum
                membuat keputusan menjadi pemberi pembiayaan atau penerima
                pembiayaan.
              </p>
              <p>
                8. Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan (OJK),
                tidak bertanggung jawab atas setiap pelanggaran atau
                ketidakpatuhan oleh pengguna, baik pemberi modal maupun penerima
                modal (baik karena kesengajaan atau kelalaian Pengguna) terhadap
                ketentuan peraturan perundang-undangan maupun kesepakatan atau
                perikatan antara penyelenggara dengan pemberi modal dan/ atau
                penerima modal.
              </p>
              <p>
                9. Setiap transaksi dan kegiatan pemberian modal, pembiayaan,
                pinjam meminjam atau pelaksanaan kesepakatan mengenai pembiayaan
                antara atau yang melibatkan Penyelenggara, Pemberi Modal, Mitra
                Lapangan dan/atau Penerima Modal wajib dilakukan melalui escrow
                account dan virtual account sebagaimana yang diwajibkan
                berdasarkan Peraturan Otoritas Jasa Keuangan Nomor 77/
                POJK.01/2016 tentang Layanan Pinjam Meminjam Uang Berbasis
                Teknologi.
              </p>
            </Col>
          </Row>
          <Row className="pt-5 pb-2 text-justify">
            <Col xs="12" lg="6">
              <h3 className="bold text-white">Tentang Kami</h3>
              <p className="bold text-white">
                ETHIS.CO.ID adalah platform layanan peer-to-peer crowdfunding
                milik PT.ETHIS FINTEK INDONESIA, yang didirikan berdasarkan
                Peraturan dan Hukum Republik Indonesia sebagai perusahaan penyedia
                pelayanan finansial teknologi (fintech) dan terdaftar di
                Kementerian Hukum dan Ham Republik Indonesia.
              </p>
            </Col>
            <Col xs="12" lg="6" className="d-flex align-items-center">
              <a href="/">
                <img src={Logo} className="img-fluid" />
              </a>
            </Col>
          </Row>

          <Row className="text-justify pb-4">
            <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }} className="text-center text-md-left mt-3 mt-md-0">
              <div className="bold text-white mb-2">Sosial Media</div>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link
                    onClick={() => window.open("https://www.facebook.com/Ethis.co.id/")}
                    className="social-list-item bg-secondary text-white border-secondary"
                  >
                    <i className="mdi mdi-facebook"/>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    onClick={() => window.open("https://www.youtube.com/channel/UCl8b21oNBcaXp3VE-T38trQ")}
                    className="social-list-item bg-secondary text-white border-secondary"
                  >
                    <i className="mdi mdi-youtube"/>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    onClick={() => window.open("https://www.instagram.com/ethis.co.id/")}
                    className="social-list-item bg-secondary text-white border-secondary"
                  >
                    <i className="mdi mdi-instagram"/>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    // onClick={() => window.open("support@ethis.co.id")}
                    className="social-list-item bg-secondary text-white border-secondary"
                  >
                    <i className="mdi mdi-email"/>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }} className="text-center text-md-left">
              <div className="bold text-white mb-2">Dilindungi Oleh</div>
              <img src={haki} className="img-fluid cursor-pointer rounded" style={{height: "120px"}} onClick={() => window.open("https://pdki-indonesia.dgip.go.id/detail/J002019037807?type=trademark&keyword=ETHIS+INDONESIA")} />
            </Col>
          </Row>

        </Container>
      </div>
      <div className="copyright-custom">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center py-3">
            <img src={logo_footer} alt="logo-footer" className="img-fluid mr-2" />
            <div><span><i className="bx bx-copyright cursor-pointer" onClick={() => window.open("https://pdki-indonesia.dgip.go.id/detail/J002019037807?type=trademark&keyword=ETHIS+INDONESIA")}></i></span> 2021 PT. Ethis Fintek Indonesia. <a href="/ethis-kebijakan-privasi/" className="text-white hover-underline">Kebijakan Privasi</a>, <a href="/syarat-dan-ketentuan/" className="text-white hover-underline">Syarat dan Ketentuan</a>.</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
