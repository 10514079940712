import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"

import { MDBDataTable } from "mdbreact"
import Toaster from "components/Common/Toast"
import {
  getCategoryTwo,
  getHistoryRisk,
  getPopulateRisk,
  getPopulateRiskProperty,
  getQuestionMeet,
  getSubCategoryOne,
  getSubCategoryOneOnTabRisk,
  getSubCategoryProperty,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import ModalDetail from "./ModalDetail"
import ModalApprove from "./ModalApprove"
import ModalPefindo from "./ModalPefindo"
import ModalRiskInternal from "./ModalRiskInternal"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_PEFINDO } from "helpers/api_url"
import { timeDiffCalc } from "helpers/method_helpers"
import axios from "axios"
import PefindoDetail from "./PefindoDetail"
import ModalTinjauLap from "./ModalTinjauLap"
import ModalRiskHistory from "./ModalRiskHistory"
import ModalTinjauHistory from "./ModalTinjauHistory"
import ModalMeet from "./ModalMeet"
import ModalMeetHis from "./ModalMeetHis"
import ModalCancelProject from "./ModalCancelProject"

const Risk = props => {
  const token = localStorage.getItem("token")
  const [activeTab, setActiveTab] = useState("3")
  const [projects, setProjects] = useState([])
  const [projectProperties, setProjectProperties] = useState([])
  const [history, setHistory] = useState([])

  const [select, setSelect] = useState(null)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalApproved, setmodalApproved] = useState(false)
  const [modalPef, setModalPef] = useState(false)
  const [modalScoring, setModalScoring] = useState(false)
  const [modalPefDetail, setModalPefDetail] = useState(false)
  const [modalTinjauLap, setModalTinjauLap] = useState(false)
  const [modalScoreHistory, setModalScoreHistory] = useState(false)
  const [modalTinjauLapHis, setModalTinjauLapHis] = useState(false)
  const [modalMeet, setModalMeet] = useState(false)
  const [modalMeetHis, setModalMeetHis] = useState(false)

  const [selectScore, setSelectScore] = useState(null)

  const [subCategoryOne, setSubCategoryOne] = useState([])
  const [subCategoryTwo, setSubCategoryTwo] = useState([])
  const [subCategoryProperty, setSubCategoryProperty] = useState([])

  const [load, setLoad] = useState(false)
  const [load2, setLoad2] = useState(false)
  const [loadButt, setLoadButt] = useState(false)

  const [dataPefindo, setDataPefindo] = useState([])
  const [selectPef, setSelectPef] = useState(null)

  const [reasonText, setReasonText] = useState(true)
  const [reason, setReason] = useState("")
  const [reason_text, setReason_text] = useState("")

  const [questionMeet, setQuestionMeet] = useState([])

  const [fdc, setFdc] = useState(null)

  const [selectRisk, setSelectRisk] = useState(null)
  const [selectRiskHis, setSelectRiskHis] = useState(null)
  const [selectLap, setSelectLap] = useState(null)
  const [selectApprove, setSelectApprove] = useState(null)
  const [selectPefindo, setSelectPefindo] = useState(null)
  const [selectMeet, setSelectMeet] = useState(null)
  const [selectMeetHis, setSelectMeetHis] = useState(null)

  const [modalCancelProject, setModalCancelProject] = useState(false)

  useEffect(() => {
    getData()
  }, [activeTab])

  useEffect(() => {
    getDataScore()
  }, [])

  useEffect(() => {
    if (selectRisk) {
      getDataRiskQuestion(selectRisk)
    }
  }, [selectRisk])

  useEffect(() => {
    if (selectRiskHis) {
      getDataRiskQuestionHistory(selectRiskHis)
    }
  }, [selectRiskHis])

  useEffect(() => {
    if (selectScore) {
      getDataRiskQuestionHis()
    }
  }, [selectScore])

  const getDataRiskQuestionHis = async () => {
    // console.log(selectScore)
    let query = ""
    if (selectScore.project_type.title === "UKM") {
      query = "?property=false"
    } else {
      query = "?property=true"
    }
    setLoad2(true)
    const data = await getSubCategoryOne(token, query)
    setSubCategoryOne(data.data)
    setLoad2(false)
  }

  const getDataRiskQuestion = async datas => {
    let query = ""
    if (datas?.project_type.title === "UKM") {
      query = "?property=false"
    } else {
      query = "?property=true"
    }
    setLoad2(true)
    const data = await getSubCategoryOneOnTabRisk(token, query)
    setSubCategoryOne(data.data)
    setLoad2(false)
  }
  const getDataRiskQuestionHistory = async datas => {
    let query = ""
    if (datas?.project_type.title === "UKM") {
      query = "?property=false"
    } else {
      query = "?property=true"
    }
    setLoad2(true)
    const data = await getSubCategoryOneOnTabRisk(token, query)
    setSubCategoryOne(data.data)
    setLoad2(false)
  }

  const getDataScore = async () => {
    setLoad(true)
    try {
      // const catOne = await getSubCategoryOne(token)
      const catTwo = await getCategoryTwo(token)
      const catProp = await getSubCategoryProperty(token)
      const dataMeet = await getQuestionMeet(token)
      setQuestionMeet(dataMeet.data)
      // setSubCategoryOne(catOne.data)
      setSubCategoryTwo(catTwo.data)
      setSubCategoryProperty(catProp.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const getData = async () => {
    setLoad(true)
    try {
      if (activeTab === "1") {
        const projectData = await getPopulateRisk(token)
        // const catOne = await getSubCategoryOne(token)
        // const catTwo = await getCategoryTwo(token)
        setProjects(projectData.data)
        // setSubCategoryOne(catOne.data)
        // setSubCategoryTwo(catTwo.data)
      } else if (activeTab === "2") {
        const his = await getHistoryRisk(token)
        setHistory(his.data)
      } else if (activeTab === "4") {
        const proProperty = await getPopulateRiskProperty(token)
        setProjectProperties(proProperty.data)
      }

      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleStatusProject = (type, status) => {
    if (type === "UKM") {
      switch (status) {
        case 1:
          return <Badge color="info">Peninjauan data tahap 1</Badge>
        case 2:
          return <Badge color="danger">Ditolak tahap 1</Badge>
        case 3:
          return <Badge color="danger">Ditolak tahap 2</Badge>
        case 4:
          return <Badge color="primary">Disetujui tahap 1</Badge>
        case 5:
          return <Badge color="info">Peninjauan data tahap 2</Badge>
        case 6:
          return <Badge color="info">Penilaian resiko</Badge>
        case 7:
          return <Badge color="warning">Persetujuan Kampanye</Badge>
        case 8:
          return <Badge color="primary">Disetujui</Badge>
        case 9:
          return <Badge color="primary">Terbit Kampanye</Badge>
      }
    } else {
      switch (status) {
        case 1:
          return <Badge color="info">Peninjauan data</Badge>
        case 2:
          return <Badge color="danger">Ditolak</Badge>
        // case 3: return <Badge color="danger">Ditolak</Badge>
        // case 4: return <Badge color="primary">Disetujui Tahap 1</Badge>
        // case 5: return <Badge color="warning">Menunggu persetujuan tahap 2</Badge>
        case 6:
          return <Badge color="info">Penilaian resiko</Badge>
        case 7:
          return <Badge color="warning">Persetujuan kampanye</Badge>
        case 8:
          return <Badge color="primary">Disetujui</Badge>
        case 9:
          return <Badge color="primary">Terbit Kampanye</Badge>
      }
    }
  }

  const toggleModalDetail = obj => {
    setSelect(obj)
    setModalDetail(!modalDetail)
  }

  const toggleModalApproved = obj => {
    setSelectApprove(obj)
    setmodalApproved(!modalApproved)
  }

  const toggleModalPef = obj => {
    setSelectPefindo(obj)
    setModalPef(!modalPef)
  }

  const toggleModalMeet = obj => {
    setSelectMeet(obj)
    setModalMeet(!modalMeet)
  }

  const toggleModalScoring = obj => {
    setSelectRisk(obj)
    setModalScoring(!modalScoring)
  }

  const toggleModalTinjauLap = obj => {
    setSelectLap(obj)
    setModalTinjauLap(!modalTinjauLap)
  }

  const toggleModalTinjauLapHis = obj => {
    setSelectScore(obj)
    setModalTinjauLapHis(!modalTinjauLapHis)
  }

  const toggleModalMeetHis = obj => {
    setSelectMeetHis(obj)
    setModalMeetHis(!modalMeetHis)
  }

  const togglePefDetail = obj => {
    setSelectPef(obj)
    setModalPefDetail(!modalPefDetail)
  }

  const toggleModalScoreHistory = obj => {
    setSelectRiskHis(obj)
    setModalScoreHistory(!modalScoreHistory)
  }

  const toggleModalCancelProject = obj => {
    setSelect(obj)
    setModalCancelProject(!modalCancelProject)
  }

  const dataProjectRiskInternal = {
    columns: [
      {
        label: "Nama Proyek",
        field: "title",
        sort: "asc",
      },
      {
        label: "Tipe",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Nominal",
        field: "nominal",
        sort: "asc",
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "details",
      },
      {
        label: "Kartu Penilaian",
        field: "action",
      },
    ],
    rows: projects.map(item => {
      return {
        ...item,
        project_type: item.project_type.title,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm btn-rounded mr-1"
            onClick={() => toggleModalDetail(item)}
          >
            Detail
          </Button>
        ),
        company_name: item.project_company.company_name,
        nominal: `Rp ${Number(
          item.project_detail.submission_total
        ).toLocaleString()}`,
        status: handleStatusProject(item.project_type.title, item.status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm btn-rounded mr-1"
            onClick={() => toggleModalScoring(item)}
          >
            Lihat
          </Button>
        ),
      }
    }),
  }

  const dataProject = {
    columns: [
      {
        label: "Nama Proyek",
        field: "title",
        sort: "asc",
      },
      {
        label: "Tipe",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Nominal",
        field: "nominal",
        sort: "asc",
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      // },
      {
        label: "Detail",
        field: "details",
      },
      {
        label: "Rapat Internal",
        field: "meet",
      },
      {
        label: "Penilaian Internal",
        field: "scoreCard",
      },
      {
        label: "Tanggapan",
        field: "cancel",
      },
      {
        label: "Skor",
        field: "action",
      },
    ],
    rows: projects.map(item => {
      return {
        ...item,
        bussines_entity: item.project_company?.business_entity?.title,
        project_type: item.project_type.title,
        // pefindo:  <Button
        //             type="button"
        //             color="secondary"
        //             className="btn-sm mr-1"
        //             onClick={() => toggleModalPef(item)}
        //           >
        //             <i className="fas fa-eye"></i>
        //           </Button>,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalDetail(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        meet: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalMeet(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        company_name: item.project_company.company_name,
        nominal: `Rp ${Number(
          item.project_detail.submission_total
        ).toLocaleString()}`,
        // status: handleStatusProject(item.project_type.title, item.status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalApproved(item)}
          >
            <i className="bx bxs-edit"></i>
          </Button>
        ),
        scoreCard: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalScoring(item)}
          >
            <i className="bx bxs-edit"></i>
          </Button>
        ),
        cancel: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalCancelProject(item)}
          >
            <i className="bx bxs-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const dataProjectProperty = {
    columns: [
      {
        label: "Nama Proyek",
        field: "title",
        sort: "asc",
      },
      {
        label: "Tipe",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Nominal",
        field: "nominal",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "details",
      },
      {
        label: "Peninjauan",
        field: "peninjauan",
      },
    ],
    rows: projectProperties.map(item => {
      return {
        ...item,
        bussines_entity: item.project_company?.business_entity?.title,
        project_type: item.project_type.title,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalDetail(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        company_name: item.project_company.company_name,
        nominal: `Rp ${Number(
          item.project_detail.submission_total
        ).toLocaleString()}`,
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalApproved(item)}
          >
            <i className="bx bxs-edit"></i>
          </Button>
        ),
        peninjauan: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalTinjauLap(item)}
          >
            <i className="bx bxs-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const dataHistories = {
    columns: [
      {
        label: "Waktu Persetujuan",
        field: "time_approved",
      },
      {
        label: "Nama Proyek",
        field: "project",
        sort: "asc",
      },
      // {
      //   label: "Badan Hukum",
      //   field: "bussines_entity",
      //   sort: "asc",
      // },
      {
        label: "Tipe",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Pembuat",
        field: "emailCreator",
        sort: "asc",
      },
      {
        label: "Rapat Internal",
        field: "meet",
      },
      {
        label: "Penilaian Internal",
        field: "scoreView",
      },
      {
        label: "Tinjau Lapangan",
        field: "scoreTinLap",
      },
      {
        label: "Pefindo",
        field: "score_pefindo",
      },
      // {
      //   label: "Tinjau Lapangan",
      //   field: "score_lapangan",
      // },
      // {
      //   label: "Penilaian Internal",
      //   field: "score_internal",
      // },
      {
        label: "Skor Akhir",
        field: "final_score",
      },
      {
        label: "Hasil Akhir",
        field: "score",
      },
      {
        label: "Deskripsi",
        field: "description",
        sort: "asc",
      },
    ],
    rows: history.map(item => {
      return {
        ...item,
        bussines_entity: item.project?.project_company?.business_entity?.title,
        time_approved:
          !item.dateTimeAfterAction || !item.dateTimeBeforeAction
            ? "-"
            : timeDiffCalc(item.dateTimeAfterAction, item.dateTimeBeforeAction),
        final_score: item.final_score || "-",
        score_pefindo: item.score_pefindo || "-",
        // status: handleStatusProject(item.project.onboardingId ? "UKM" : "Properti", item.project.status),
        project_type: item.project.onboardingId ? "UKM" : "Properti",
        scoreView: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalScoreHistory(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        scoreTinLap: !item.project.onboardingId ? (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalTinjauLapHis(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ) : (
          "-"
        ),
        meet: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalMeetHis(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        project: item.project.title,
        emailCreator: item.creator.email,
      }
    }),
  }

  const handleSearchPefindo = async (e, value) => {
    setLoadButt(true)
    const query = `?name=${value.company_name}&npwp=${value.npwp}&reason=${
      value.reason
    }&reasontext=${value.reason_text || ""}`
    try {
      const data = await axios.post(`${API_PEFINDO}/api/company${query}`, null)
      if (data.data.SmartSearchCompanyResult) {
        if (data.data.SmartSearchCompanyResult.Status == "SubjectFound") {
          let result =
            data.data.SmartSearchCompanyResult.CompanyRecords
              .SearchCompanyRecord
          let arr = Array.isArray(result) ? result : [result]
          setDataPefindo(arr)
        } else {
          const datas2 = { score: 900, npwp: value.npwp }
          localStorage.setItem("pefindo", JSON.stringify(datas2))
          Toaster("info", "Maaf", "Data tidak tersedia")
        }
      } else {
        if (data.data.Status == "SubjectNotFound") {
          const datas = { score: data.data.Skor, npwp: value.npwp }
          localStorage.setItem("pefindo", JSON.stringify(datas))
          Toaster("info", "Maaf", "Data tidak tersedia")
        }
      }
      setLoadButt(false)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", "Gagal ambil data pefindo")
    }
  }

  const pefindoId = {
    columns: [
      {
        label: "Nama Perusahaan",
        field: "CompanyName",
      },
      {
        label: "NPWP Perusahaan",
        field: "NPWP",
      },
      {
        label: "Alamat Perusahaan",
        field: "Address",
      },
      {
        label: "Aksi",
        field: "action",
      },
    ],
    rows: dataPefindo.map(item => {
      return {
        ...item,
        action: (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => togglePefDetail(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const dataFdc = {
    columns: [
      {
        label: "ID Penyelenggara",
        field: "id_penyelenggara",
      },
      {
        label: "Jenis Pengguna",
        field: "jenis_pengguna",
      },
      {
        label: "Nama Peminjam",
        field: "nama_borrower",
      },
      {
        label: "No Identitas",
        field: "no_identitas",
      },
      {
        label: "No NPWP",
        field: "no_npwp",
      },
      {
        label: "Tanggal Perjanjian Peminjam",
        field: "tgl_perjanjian_borrower",
      },
      {
        label: "Tanggal Penyaluran Dana",
        field: "tgl_penyaluran_dana",
      },
      {
        label: "Nilai Pendanaan",
        field: "nilai_pendanaan",
      },
      {
        label: "Tanggal Pelaporan Data",
        field: "tgl_pelaporan_data",
      },
      {
        label: "Sisa Pinjaman Berjalan",
        field: "sisa_pinjaman_berjalan",
      },
      {
        label: "Tanggal Jatuh Tempo",
        field: "tgl_jatuh_tempo_pinjaman",
      },
      {
        label: "Kualitas Pinjaman",
        field: "kualitas_pinjaman",
      },
      {
        label: "DPD Terakhir",
        field: "dpd_terakhir",
      },
      {
        label: "DPD Max",
        field: "dpd_max",
      },
      {
        label: "Status Pinjaman",
        field: "status_pinjaman",
      },
      {
        label: "Keterangan Jenis Pengguna",
        field: "jenis_pengguna_ket",
      },
      {
        label: "Keterangan Kulaitas Pinjaman",
        field: "kualitas_pinjaman_ket",
      },
      {
        label: "Keterangan Status Pinjaman",
        field: "status_pinjaman_ket",
      },
    ],
    rows: !fdc
      ? []
      : fdc.pinjaman.map(item => {
          return {
            ...item,
          }
        }),
  }

  const handleSearchFDC = async (e, value) => {
    setLoadButt(true)
    try {
      const data = await axios.post(
        `https://inquiry.devopsethis.com/api/fdc/inquiry?idIdentitas=${value.npwp}&alasan=1&referensi=${value.referensi}`
      )
      // console.log(data)
      setFdc(data.data)
      setLoadButt(false)
      const datas = { score: data.data.kuantitas.skor, npwp: value.npwp }
      localStorage.setItem("fdc", JSON.stringify(datas))
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", "Tidak dapat tersambung ke FDC")
    }
  }

  if (load) return <Loading title="Analisis" breadcrumbItem="Resiko" />

  return (
    <React.Fragment>
      <ModalRiskInternal
        isOpen={modalScoring}
        subCategoryOne={subCategoryOne}
        subCategoryTwo={subCategoryTwo}
        toggleModal={toggleModalScoring}
        item={selectRisk}
        getData={getData}
        load={load2}
      />
      <ModalRiskHistory
        isOpen={modalScoreHistory}
        subCategoryOne={subCategoryOne}
        subCategoryTwo={subCategoryTwo}
        toggleModal={toggleModalScoreHistory}
        item={selectRiskHis}
        getData={getData}
        load={load2}
      />
      <ModalTinjauLap
        isOpen={modalTinjauLap}
        subCategoryProperty={subCategoryProperty}
        toggleModal={toggleModalTinjauLap}
        item={selectLap}
        getData={getData}
      />
      <ModalTinjauHistory
        isOpen={modalTinjauLapHis}
        subCategoryProperty={subCategoryProperty}
        toggleModal={toggleModalTinjauLapHis}
        item={selectScore}
        getData={getData}
      />
      <ModalDetail
        isOpen={modalDetail}
        toggle={toggleModalDetail}
        item={select}
      />
      <ModalApprove
        isOpen={modalApproved}
        toggle={toggleModalApproved}
        item={selectApprove}
        getData={getData}
      />
      <ModalPefindo
        isOpen={modalPef}
        toggle={toggleModalPef}
        item={selectPefindo}
      />
      <PefindoDetail
        isOpen={modalPefDetail}
        toggle={togglePefDetail}
        item={selectPef}
        reason={reason}
        reason_text={reason_text}
      />
      <ModalMeet
        isOpen={modalMeet}
        toggle={toggleModalMeet}
        item={selectMeet}
        question={questionMeet}
      />
      <ModalMeetHis
        isOpen={modalMeetHis}
        toggle={toggleModalMeetHis}
        item={selectMeetHis}
        question={questionMeet}
      />
      <ModalCancelProject
        isOpen={modalCancelProject}
        toggle={toggleModalCancelProject}
        item={select}
        getData={getData}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Analisis" breadcrumbItem="Resiko" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <h4 className="card-title mb-3">Projects</h4> */}

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Pefindo
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5")
                        }}
                      >
                        FDC
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        Tinjau Lapangan Properti
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Penilaian Resiko
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3 border">
                    <TabPane tabId="3">
                      <div className="mt-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleSearchPefindo(e, v)
                          }}
                        >
                          <Row>
                            <Col lg="6">
                              <PInput
                                name="npwp"
                                label="NPWP Perusahaan"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="company_name"
                                label="Nama Perusahaan"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="reason"
                                label="Tujuan Penggunaan"
                                type="select"
                                onChange={e => {
                                  if (e.target.value == "AnotherReason") {
                                    setReasonText(false)
                                    setReason(e.target.value)
                                  } else {
                                    setReasonText(true)
                                    setReason(e.target.value)
                                    setReason_text("")
                                  }
                                }}
                                options={[
                                  {
                                    uuid: "ProvidingFacilities",
                                    name: "Menyediakan Fasilitas",
                                  },
                                  {
                                    uuid: "MonitoringDebtorOrCustomer",
                                    name: "Monitor Pengguna",
                                  },
                                  {
                                    uuid: "OperationalRiskManagement",
                                    name: "Operasional Manajemen Resiko",
                                  },
                                  {
                                    uuid: "FulfilRegulationRequirements",
                                    name: "Keperluan Regulasi",
                                  },
                                  {
                                    uuid: "AnotherReason",
                                    name: "Alasan Lainnya",
                                  },
                                ]}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            {!reasonText && (
                              <Col lg="6">
                                <PInput
                                  name="reason_text"
                                  label="Tujuan Penggunaan Lainnya"
                                  type="text"
                                  className="form-control"
                                  onChange={e => setReason_text(e.target.value)}
                                  validate={{
                                    required: {
                                      value: !reasonText,
                                      errorMessage: "Field harus diisi",
                                    },
                                  }}
                                  disabled={reasonText}
                                />
                              </Col>
                            )}
                          </Row>
                          <div className="mt-2">
                            <button
                              className="btn btn-primary waves-effect waves-light d-flex align-items-center"
                              type="submit"
                              disabled={loadButt}
                            >
                              {loadButt ? (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                  Loading
                                </>
                              ) : (
                                <>
                                  <i className="bx bx-search-alt mr-1"></i> Cari
                                </>
                              )}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                      <MDBDataTable
                        responsive
                        bordered
                        data={pefindoId}
                        striped
                        className="mt-5"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataProjectProperty}
                        striped
                        // className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="1" id="all-order">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataProject}
                        striped
                        // className="mt-5"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataHistories}
                        striped
                        // className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="5">
                      <div className="mt-2">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleSearchFDC(e, v)
                          }}
                        >
                          <Row>
                            <Col lg="6">
                              <PInput
                                name="npwp"
                                label="NPWP Perusahaan"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="reason"
                                label="Tujuan Penggunaan"
                                type="select"
                                options={[
                                  {
                                    uuid: "1",
                                    name: "Applying loan via Platform",
                                  },
                                  {
                                    uuid: "2",
                                    name: "Inquiring your existing customer",
                                  },
                                ]}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <PInput
                                name="referensi"
                                label="Referensi"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <button
                              className="btn btn-primary waves-effect waves-light d-flex align-items-center"
                              type="submit"
                              disabled={loadButt}
                            >
                              {loadButt ? (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                                  Loading
                                </>
                              ) : (
                                <>
                                  <i className="bx bx-search-alt mr-1"></i> Cari
                                </>
                              )}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                      {fdc ? (
                        <div>
                          <table class="table table-bordered mt-3">
                            <thead style={{ backgroundColor: "#eee" }}>
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">No Identitas</th>
                                <th scope="col">Tanggal Inquiry</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{fdc.status}</td>
                                <td>{fdc.noIdentitas}</td>
                                <td>{fdc.inquiryDate}</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>
                            <b>
                              Jumlah Pinjaman: {fdc.kuantitas.jumlahPinjaman}
                            </b>
                          </p>
                          <table class="table table-bordered mt-3">
                            {/* <thead style={{backgroundColor: "#eee"}}>
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">No Identitas</th>
                                <th scope="col">Tanggal Inquiry</th>
                              </tr>
                            </thead> */}
                            <tbody>
                              <tr
                                className="font-weight-bold"
                                style={{ backgroundColor: "#eee" }}
                              >
                                <td
                                  rowSpan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "10rem",
                                  }}
                                >
                                  Lunas
                                </td>
                                <td>Lancar</td>
                                <td>Tidak Lancar</td>
                                <td>Macet</td>
                              </tr>
                              <tr>
                                <td>{fdc.kuantitas.lunas.lancar}</td>
                                <td>{fdc.kuantitas.lunas.tidakLancar}</td>
                                <td>{fdc.kuantitas.lunas.macet}</td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr
                                className="font-weight-bold"
                                style={{ backgroundColor: "#eee" }}
                              >
                                <td
                                  rowSpan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "10rem",
                                  }}
                                >
                                  Write Off
                                </td>
                                <td>Lancar</td>
                                <td>Tidak Lancar</td>
                                <td>Macet</td>
                              </tr>
                              <tr>
                                <td>{fdc.kuantitas.writeOff.lancar}</td>
                                <td>{fdc.kuantitas.writeOff.tidakLancar}</td>
                                <td>{fdc.kuantitas.writeOff.macet}</td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr
                                className="font-weight-bold"
                                style={{ backgroundColor: "#eee" }}
                              >
                                <td
                                  rowSpan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "10rem",
                                  }}
                                >
                                  Outstanding
                                </td>
                                <td>Lancar</td>
                                <td>Tidak Lancar</td>
                                <td>Macet</td>
                              </tr>
                              <tr>
                                <td>{fdc.kuantitas.outstanding.lancar}</td>
                                <td>{fdc.kuantitas.outstanding.tidakLancar}</td>
                                <td>{fdc.kuantitas.outstanding.macet}</td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr
                                className="font-weight-bold"
                                style={{ backgroundColor: "#eee" }}
                              >
                                <td
                                  rowSpan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "10rem",
                                  }}
                                >
                                  Total
                                </td>
                                <td>Lancar</td>
                                <td>Tidak Lancar</td>
                                <td>Macet</td>
                              </tr>
                              <tr>
                                <td>
                                  {fdc.kuantitas.total.lancar.value} (
                                  {fdc.kuantitas.total.lancar.persen}%)
                                </td>
                                <td>
                                  {fdc.kuantitas.total.tidakLancar.value} (
                                  {fdc.kuantitas.total.tidakLancar.persen}%)
                                </td>
                                <td>
                                  {fdc.kuantitas.total.macet.value} (
                                  {fdc.kuantitas.total.macet.persen}%)
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td
                                  rowSpan="2"
                                  className="font-weight-bold"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "10rem",
                                    backgroundColor: "#eee",
                                  }}
                                >
                                  Skor
                                </td>
                                <td>{fdc.kuantitas.skor}</td>
                              </tr>
                            </tbody>
                          </table>
                          <MDBDataTable
                            responsive
                            bordered
                            data={dataFdc}
                            striped
                            className="mt-5"
                            disableRetreatAfterSorting
                          />
                        </div>
                      ) : null}
                      {/* <MDBDataTable
                        responsive
                        bordered
                        data={pefindoId}
                        striped
                        className="mt-5"
                      /> */}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Risk
