import Toaster from "components/Common/Toast"
import { getAkadAll, getAkadByCampaign, getCampaign, getCheckBooked, getTRX, postCreateVA, postDocMus, postDocWak, postVaNew } from "helpers/backend_helpers"
import React, { useCallback, useEffect, useState } from "react"
import { Redirect, withRouter } from "react-router-dom"
import { Badge, Button, Card, CardBody, CardText, CardTitle, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { CardHeader, InputGroup, InputGroupAddon, Collapse } from "reactstrap"
import { connect } from "react-redux"
import Loading from "pages/Utility/Loading"
import moment from "moment"
import Countdown from 'react-countdown'

import {Prompt} from "react-router-dom"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

import img_bsi from "../../assets/images/ethis/Logo BSI (Bank Syariah Indonesia).png"
import img_permata from "../../assets/images/ethis/permata.png"
import img_other_bank from "../../assets/images/ethis/tf-other-bank-2.png"

const DetailTransaction = props => {
  const uuid = props.match.params.uuid
  const token = localStorage.getItem("token")

  const [trx, setTrx] = useState(null)
  const [load, setLoad] = useState(false)

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)

  useEffect(() => {
    getDataTrx()
  }, [])

  const getDataTrx = async () => {
    setLoad(true)
    const data = await getTRX(uuid, token)
    setTrx(data.data)
    setLoad(false)
  }

  if(load) {
    return (
      <SkeletonLoad>
        <div style={{ fontSize: 24, lineHeight: 3, marginTop: -56 }}>
          <Row>
            <Col lg="12">
              <Skeleton height={100}/>
            </Col>
            <Col lg="12">
              <Skeleton height={70}/>
            </Col>
            <Col lg="8">
              <Skeleton height={250}/>
            </Col>
            <Col lg="8">
              <Skeleton height={500}/>
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  if(!trx) {
    return (
      <SkeletonLoad>
        <div style={{ fontSize: 24, lineHeight: 3, marginTop: -56 }}>
          <Row>
            <Col lg="12">
              <Skeleton height={100}/>
            </Col>
            <Col lg="12">
              <Skeleton height={70}/>
            </Col>
            <Col lg="8">
              <Skeleton height={250}/>
            </Col>
            <Col lg="8">
              <Skeleton height={500}/>
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <h4>Dashboard</h4> */}
          <Row className="">

            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Informasi Pembiayaan</CardTitle>
                  {/* <CardText className="text-primary">Menunggu tandatangan akad musyarakah penerima pembiayaan</CardText> */}
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Card>
                {/* new */}
                <CardBody>
                  {/* <CardTitle className="mb-0">Mohon menunggu penandatangan akad musyarakah oleh penerima pembiayaan</CardTitle> */}
                  <CardTitle className="">Detail Transaksi</CardTitle>
                  <hr></hr>
                  <h3 className="base-color bold text-primary">Rp {(Number(trx.amount) + 3000).toLocaleString()}</h3>
                  <div className="mt-2"><b><em>Total pendanaan anda diatas sudah termasuk biaya layanan pembayaran institusi sebesar Rp 3,000. Mohon melakukan transfer sesuai dengan nominal di atas.</em></b></div>
                  <div className="mt-2">Pembiayaan Proyek <b>{trx.campaign.title}</b></div>
                  <div className="mt-2">ID Transaksi <b>{trx.trx_id}</b></div>

                  {trx.bank_type == 2 || trx.bank_type == 3 ?
                    <>
                      <Badge color="primary" className="mt-2">{`Transfer Menggunakan BSI`}</Badge>
                      <Row className="d-flex align-items-center">
                        <Col lg="1" className="mr-3">
                          <img src={img_bsi} height="48" className="mt-3" />
                        </Col>
                        {/* <Col lg="4">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Institusi</label>
                            <Input disabled className="font-weight-bold" value={'5243'}/>
                          </div>
                        </Col> */}
                        <Col lg="10">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Transaksi BSI</label>
                            <Input disabled className="font-weight-bold" value={trx.va_number}/>
                          </div>
                        </Col>
                      </Row>
                      <Badge color="primary" className="mt-2 mb-1">Transfer Menggunakan Bank Lain</Badge>
                      <Row className="d-flex align-items-center">
                        <Col lg="1" className="mr-3">
                          <img src={img_other_bank} height="34" className="mt-3" />
                        </Col>
                        {/* <Col lg="2">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Bank</label>
                            <Input disabled className="font-weight-bold" value={'900'}/>
                          </div>
                        </Col>
                        <Col lg="2">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Institusi</label>
                            <Input disabled className="font-weight-bold" value={'5243'}/>
                          </div>
                        </Col> */}
                        <Col lg="10">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Transaksi BSI</label>
                            <Input disabled className="font-weight-bold" value={`9005243${trx.va_number}`}/>
                          </div>
                        </Col>
                      </Row>
                    </> : 
                    <>
                      <Badge color="primary" className="mt-2">{`Transfer Menggunakan Permata Syariah`}</Badge>
                      <Row className="d-flex align-items-center">
                        <Col lg="2" className="mr-3">
                          <img src={img_permata} height="32" className="mt-3" />
                        </Col>
                        {/* <Col lg="4">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Institusi</label>
                            <Input disabled className="font-weight-bold" value={'5243'}/>
                          </div>
                        </Col> */}
                        <Col lg="9">
                          <div className="w-100">
                            <label className="mb-0 font-12">Kode Transaksi Permata Syariah</label>
                            <Input disabled className="font-weight-bold" value={trx.va_number}/>
                          </div>
                        </Col>
                      </Row>
                    </>
                  }

                  {/* <InputGroup className="mt-3 w-100 mb-2">
                    <InputGroupAddon addonType="prepend">
                      <div className="d-flex align-items-center">
                        <img src={img_bsi} height="48" className="pr-1" />
                      </div>
                    </InputGroupAddon>
                    <Input disabled className="font-weight-bold" value={'123456789'}/>
                    <Button color="primary" onClick={() => handleCopy(checkout.va_number)}>Salin</Button>
                  </InputGroup> */}
                  
                  <div className="mt-3">
                    *Catatan: <b>Kode Transaksi akan terbit pada menu portofolio jika penerima pembiayaan telah menandatangani akad musyarakah.</b>
                  </div>
                  {trx.expired_at &&
                    <div className="mt-3">
                      Transfer Sebelum <b>{moment(trx.expired_at).format('DD MMMM YYYY | HH:mm:ss ')} WIB </b> 
                      atau Pembiayaan Anda akan dibatalkan secara otomatis.
                    </div>
                  }
                  <Button color="primary" className="mt-2" onClick={() => props.history.push("/portfolio?page=1&perPage=5")}>Kembali ke Portfolio</Button>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle>Panduan Transfer</CardTitle>
                  <CardText className="text-primary">Pilih menu dibawah untuk melihat panduan</CardText>
                  
                    {trx.bank_type == 2 || trx.bank_type == 3 ?
                      <div id="accordion">
                        <Card className="mb-1">
                          <CardHeader
                            className="p-3" 
                            id="headingOne"
                            onClick={() => {
                              setcol1(!col1)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                ATM BSI
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>

                          <Collapse isOpen={col1}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol>
                                  <li className="mb-0">Pilih menu Pembayaran/Payment/Pembelian.</li>
                                  <li className="mb-0">Pilih Institusi.</li>
                                  <li className="mb-0">Masukkan kode Institusi “PT. Ethis Fintek Indonesia” yakni 5243 + Kode Transaksi Pendanaan, Contoh: 5243 + Kode Transaksi Pendanaan (10 Digit) = 52430123456789.</li>
                                  <li className="mb-0">Pilih: Benar / Selanjutnya.</li>
                                  <li className="mb-0">Kemudian layar akan menampilkan Informasi Data Transfer, pastikan data sudah benar.</li>
                                  <li className="mb-0">Pilih Benar / Ya.</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Card>
                        <Card className="mb-1">
                          <CardHeader 
                            onClick={() => {
                                  setcol2(!col2)
                                }}
                            style={{ cursor: "pointer" }} 
                            className="p-3" 
                            id="headingTwo"
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                {" "}
                                BSI Mobile & BSI NET Banking{" "}
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col2}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Pilih menu Pembayaran/Payment.</li>
                                  <li className="mb-0">Pilih Institusi.</li>
                                  <li className="mb-0">Pada kolom Institusi, Masukkan kode 5243 atau pilih (PT. Ethis Fintek Indonesia).</li>
                                  <li className="mb-0">Pilih kolom ID Pelanggan/Kode Transaksi Input Kode Transaksi Pendanaan (10 Digit)  dan pilih “Lanjut”. </li>
                                  <li className="mb-0">Akan Muncul Pada Screen : Nama, Kode Transaksi dan Jumlah Transaksi yang akan anda transfer.</li>
                                  <li className="mb-0">Layar validasi transfer akan muncul pada layar, Masukkan PIN anda dan pilih “Selanjutnya” untuk submit.</li>
                                  <li className="mb-0">Selesai.</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol3(!col3)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                Teller Seluruh Cabang BSI
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col3}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Tulis pada Slip Bayar Tagihan, Nama Pelanggan, Kode 5243 + Kode Transaksi Pendanaan, Contoh: 5243 + Kode Transaksi Pendanaan (10 Digit) = 52430123456789 dan Dibayarkan secara Tunai.</li>
                                  <li className="mb-0">Pendana Tandatangan pada Slip Bayar tagihan tersebut.</li>
                                  <li className="mb-0">Pendana menyampaikan ke Petugas Teller BSI.</li>
                                  <li className="mb-0">Teller akan mengkonfirmasi nominal transaksi yang akan ditransfer.</li>
                                  <li className="mb-0">Selesai</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol4(!col4)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                ATM / M-Banking Bank lain
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col4}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Pilih Transfer.</li>
                                  <li className="mb-0">Pilih  Antar Bank Online / Transfer Online antar Bank.</li>
                                  <li className="mb-0">Masukkan kode BSI 451 atau pilih Bank BSI.
                                  </li>
                                  <li className="mb-0">Masukkan kode 900 + Kode Institusi 5243 + Masukkan Kode Transaksi Pendanaan (10 Digit), Contoh=  90052430123456789.</li>
                                  <li className="mb-0">Masukkan Nominal Transfer</li>
                                  <li className="mb-0">Layar akan menampilkan data transaksi anda, jika data sudah benar pilih YA (OK).</li>
                                  <li className="mb-0">Proses Pembayaran.</li>
                                  <li className="mb-0">Pembayaran Anda telah berhasil.</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                      </div> :

                      <div id="accordion">
                        <Card className="mb-1">
                          <CardHeader
                            className="p-3" 
                            id="headingOne"
                            onClick={() => {
                              setcol1(!col1)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                ATM Permata
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>

                          <Collapse isOpen={col1}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol>
                                  <li className="mb-0">Masukkan PIN</li>
                                  <li className="mb-0">Pilih menu TRANSAKSI LAINNYA</li>
                                  <li className="mb-0">Pilih menu PEMBAYARAN</li>
                                  <li className="mb-0">Pilih menu PEMBAYARAN LAINNYA</li>
                                  <li className="mb-0">Pilih menu VIRTUAL ACCOUNT</li>
                                  <li className="mb-0">Masukkan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, Contoh: 8319031121212018.</li>
                                  <li className="mb-0">Tekan BENAR</li>
                                  <li className="mb-0">Pilih rekening yang menjadi sumber dana yang akan didebet, lalu tekan YA untuk konfirmasi transaksi</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Card>
                        <Card className="mb-1">
                          <CardHeader 
                            onClick={() => {
                                  setcol2(!col2)
                                }}
                            style={{ cursor: "pointer" }} 
                            className="p-3" 
                            id="headingTwo"
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                {" "}
                                ATM Bank lain{" "}
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col2}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Masukkan PIN</li>
                                  <li className="mb-0">Pilih menu TRANSAKSI</li>
                                  <li className="mb-0">Pilih menu KE REK BANK LAIN</li>
                                  <li className="mb-0">Masukkan kode sandi Bank Permata (013) diikuti dengan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, dan tekan BENAR</li>
                                  <li className="mb-0">Masukkan jumlah pembayaran sesuai dengan yang ditagihkan dalam halaman konfirmas</li>
                                  <li className="mb-0">Pilih BENAR untuk menyetujui transaksi tersebut</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol3(!col3)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                Permata Mobile
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col3}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Buka aplikasi PermataMobile Internet (Android/iPhone)</li>
                                  <li className="mb-0">Masukkan User ID & Password</li>
                                  <li className="mb-0">Pilih Pembayaran Tagihan</li>
                                  <li className="mb-0">Pilih Virtual Account</li>
                                  <li className="mb-0">Masukkan 16 digit nomor Virtual Account yang tertera pada halaman konfirmasi</li>
                                  <li className="mb-0">Masukkan nominal pembayaran sesuai dengan yang ditagihkan</li>
                                  <li className="mb-0">Muncul Konfirmasi pembayaran</li>
                                  <li className="mb-0">Masukkan otentikasi transaksi/token</li>
                                  <li className="mb-0">Transaksi selesai</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol4(!col4)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                Permata Net
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col4}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Buka website PermataNet: https://new.permatanet.com</li>
                                  <li className="mb-0">Masukkan user ID & Password</li>
                                  <li className="mb-0">Pilih Pembayaran Tagihan</li>
                                  <li className="mb-0">Pilih Virtual Account</li>
                                  <li className="mb-0">Masukkan 16 digit nomor Virtual Account yang tertera pada halaman konfirmasi</li>
                                  <li className="mb-0">Masukkan nominal pembayaran sesuai dengan yang ditagihkan</li>
                                  <li className="mb-0">Muncul Konfirmasi pembayaran</li>
                                  <li className="mb-0">Masukkan otentifikasi transaksi/token</li>
                                  <li className="mb-0">Transaksi selesai</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                      </div>
                    }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(
  connect(mapStateToProps, null)(DetailTransaction)
)

