import Toaster from "components/Common/Toast"
import { createVaFaspay, getAkadAll, getAkadByCampaign, getCampaign, getCheckBooked, getTRX, postCreateVA, postDocMus, postDocWak, postVaNew } from "helpers/backend_helpers"
import React, { useCallback, useEffect, useState } from "react"
import { Redirect, withRouter } from "react-router-dom"
import { Badge, Button, Card, CardBody, CardText, CardTitle, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Tooltip } from "reactstrap"
import { CardHeader, InputGroup, InputGroupAddon, Collapse } from "reactstrap"
import Musyarakah from "./Musyarakah"
import "./akad.css"
import Wakalah from "./Wakalah"
import { connect } from "react-redux"
import Loading from "pages/Utility/Loading"
import moment from "moment"
import Countdown from 'react-countdown'

import {Prompt} from "react-router-dom"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

import img_bsi from "../../assets/images/ethis/Logo BSI (Bank Syariah Indonesia).png"
import img_permata from "../../assets/images/ethis/permata.png"
import img_other_bank from "../../assets/images/ethis/tf-other-bank-2.png"

const HighlightAkad = props => {
  const pledge = JSON.parse(localStorage.getItem("pledge"))
  const trxUuid = localStorage.getItem("trx")

  const [checkout, setCheckout] = useState(null)

  const [detail, setDetail] = useState(null)
  const [modal, setModal] = useState(false)
  // const [akad, setAkad] = useState(null)
  // const [checklistAkad, setChecklistAkad] = useState(false)

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)

  const [ttop, setttop] = useState(false)

  const [loadButt, setLoadButt] = useState(false)
  const [loading, setLoading] = useState(false)

  const [modalAkad, setModalAkad] = useState(false)
  const [urlDocument, setUrlDocument] = useState("")
  const [akadName, setAkadName] = useState("")

  const [timeLeft, setTimeLeft] = useState(1201)

  const [trx, setTrx] = useState(null)
  const [loadStat, setLoadStat] = useState(false)

  const [bank, setBank] = useState(null)

  const toggleAkad = () => setModalAkad(!modalAkad)

  // console.log(bank)

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = e => {
    window.onbeforeunload = null
    e.preventDefault()
    e.returnValue = ''
  }


  useEffect(() => {
    const intervalId = setInterval(() => updateCountdown(), 1000)
    return () => clearInterval(intervalId)
  }, [timeLeft])
  

  useEffect(() => {
    getDetails()

    return () => {
      localStorage.removeItem("pledge")
      localStorage.removeItem("trx")
    }
  }, [])

  useEffect(() => {
    getDataTrx()
  }, [])

  const updateCountdown = () => {
    if(timeLeft === 0) {
      props.history.push("/daftar-kampanye")
    } else {
      setTimeLeft(timeLeft - 1)
    }
  }

  const handleChange = (e) => {
    setChecklistAkad(e.target.checked)
    toggle()
  }

  const getDetails = async () => {
    const token = localStorage.getItem("token")
    try {
      const data = await getCampaign(pledge.campaign_uuid, token)
      const akad = await getAkadByCampaign(pledge.campaign_uuid, token)
      setDetail(data.data)
      setAkad(akad.data)
    } catch (error) {
      // Toaster("error", "Kesalahan", "Periksa Koneksi anda dan refresh browser")
    }
  }

  const getDataTrx = async () => {
    const token = localStorage.getItem("token")
    try {
      await getTRX(trxUuid, token)
    } catch (error) {
      props.history.push("/daftar-kampanye")
    }
  }

  const handleNisbah = () => {
    let modal = Number(pledge.nominal)
    let nisbah = Number(detail.nisbah) / 100
    return parseInt(modal * nisbah).toLocaleString()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const checkBooked = async () => {
    const token = localStorage.getItem("token")
    const check = await getCheckBooked(pledge.campaign_uuid, token)
    // console.log(check.data)
  }

  const handleTransaction = async () => {
    if(!bank) return Toaster("error", "Maaf", "Silahkan Pilih Metode Pembayaran")
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      const check = await getCheckBooked(pledge.campaign_uuid, token)
      const dataBooked = check.data
      let remaining = parseInt(dataBooked.target) - parseInt(dataBooked.booked)
      // if(remaining < parseInt(pledge.nominal)) {
      //   setLoadButt(false)
      //   return Toaster("error", "Ups", `Nominal kampanye terkumpul saat ini Rp ${remaining.toLocaleString()}`)
      // }

      const trans = await getTRX(trxUuid, token)
      const trxAkad = trans.data
      let temp = 0;
      trxAkad.privy_document_wakalah.forEach(element => {
        if(element.status == 2) {
          temp++
        }
      })

      trxAkad.privy_document_musyarakah.forEach(element => {
        if(element.status == 2) {
          temp++
        }
      })

      if(temp !== 2) {
        setLoadButt(false)
        return Toaster("info", "Informasi", `Mohon menandatangani kedua dokumen akad di atas. Jika sudah, mohon menunggu 1 menit untuk proses verifikasi`)
      }

      if(bank == "bsi") {
        const data = await postVaNew(trxUuid, token)
        setCheckout(data.data)
        Toaster("success", "Terima Kasih", "Pembayaran dapat dilakukan setelah penerima pembiayaan telah melakukan tandatangan akad musyarakah")
        setLoadButt(false)
      } else {
        const data2 = await createVaFaspay(trxUuid, token)
        setCheckout(data2.data)
        Toaster("success", "Terima Kasih", "Pembayaran dapat dilakukan setelah penerima pembiayaan telah melakukan tandatangan akad musyarakah")
        setLoadButt(false)
      }

    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
      setLoadButt(false)
    }
  }

  const handleCopy = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const handleCheckFull = async () => {
    const token = localStorage.getItem("token")
    await getCheckBooked(pledge.campaign_uuid, token)
  }

  const handleWakalah = async () => {
    const token = localStorage.getItem("token")
    let post = {
      documentTitle: `Akad Wakalah_${detail.title}_${props.user.first_name} ${props.user.last_name}`,
      campaign_transaction_id: trxUuid
    }
    setLoading(true)
    try {
      const trans = await getTRX(trxUuid, token)
      if(trans.data.privy_document_wakalah.length > 0) {
        setUrlDocument(trans.data.privy_document_wakalah[0].url_document)
      } else {
        const wak = await postDocWak(detail.uuid, post, token)
        setUrlDocument(wak.data.url_document)
      }
      setAkadName("Wakalah")
      toggleAkad()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleMusyarakah = async () => {
    const token = localStorage.getItem("token")
    let post = {
      documentTitle: `Akad Musyarakah_${detail.title}_${props.user.first_name} ${props.user.last_name}`,
      campaign_transaction_id: trxUuid
    }
    setLoading(true)
    try {
      const trans = await getTRX(trxUuid, token)
      if(trans.data.privy_document_musyarakah.length > 0) {
        setUrlDocument(trans.data.privy_document_musyarakah[0].url_document)
      } else {
        const wak = await postDocMus(detail.uuid, post, token)
        setUrlDocument(wak.data.url_document)
      }
      setAkadName("Musyarakah")
      toggleAkad()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Redirect to="/daftar-kampanye" />
    } else {
      // Render a countdown
      return <span> {minutes}:{seconds}</span>;
    }
  }

  const renderModalAkad = () => {
    return (
      <Modal
        isOpen={modalAkad}
        // toggle={toggleAkad}
        scrollable={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Dokumen Akad {akadName}</h5>
          <button
            type="button"
            onClick={toggleAkad}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe
              title={akadName}
              className="embed-responsive-item"
              src={urlDocument}
            />
          </div>
        </div>
      </Modal>
    )
  }

  const renderTimeLeft = () => {

    let min = Math.floor(timeLeft / 60)
    let sec = timeLeft - (min * 60)
    
    return ` ${String(min).length === 1 ? `0${min}` : min} : ${String(sec).length === 1 ? `0${sec}` : sec}`
  }

  const renderStatusAkad = (item, akad) => {
    if(!item) return false
    if(akad == "wakalah" && item.privy_document_wakalah) {
      if(item.privy_document_wakalah.length > 0) {
        return item.privy_document_wakalah[0].status === 2
      } else {
        return false
      }
    } else if(akad == "musyarakah" && item.privy_document_musyarakah) {
      if(item.privy_document_musyarakah.length > 0) {
        return item.privy_document_musyarakah[0].status === 2
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const checkStatusAkad = async () => {
    const token = localStorage.getItem("token")
    setLoadStat(true)
    try {
      const trans = await getTRX(trxUuid, token)
      // console.log(trans.data)
      if(trans.data.privy_document_musyarakah.length === 0 || trans.data.privy_document_wakalah.length === 0 ) {
        Toaster("info", "Info", "Mohon menandatangani kedua akad dibawah")
      } else {
        if(trans.data.privy_document_musyarakah[0].status === 2 && trans.data.privy_document_wakalah[0].status === 2) {
          Toaster("success", "Info", "Tanda tangan anda telah terverifikasi, Silahkan lanjutkan ke pembayaran")
        } else {
          Toaster("info", "Info", "Setelah anda menandatangani kedua akad dibawah, mohon menunggu 1 menit sebagai proses verifikasi.")
        }
      }
      setTrx(trans.data)
      setLoadStat(false)
    } catch (error) {
      setLoadStat(false)
      Toaster("error", "Ups", error.response.data.message)
      props.history.push("/daftar-kampanye")
    }
  }


  
  if(!pledge || !trxUuid) {
    return <Redirect to="/daftar-kampanye" />
  }

  if(!detail) {
    return (
      <SkeletonLoad>
        <div style={{ fontSize: 24, lineHeight: 3, marginTop: -56 }}>
          <Row>
            <Col lg="12">
              <Skeleton height={100}/>
            </Col>
            <Col lg="12">
              <Skeleton height={70}/>
            </Col>
            <Col lg="8">
              <Skeleton height={250}/>
            </Col>
            <Col lg="8">
              <Skeleton height={500}/>
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  if(loading) {
    return (
      <SkeletonLoad>
        <div style={{ fontSize: 24, lineHeight: 3, marginTop: -56 }}>
          <Row>
            <Col lg="12">
              <Skeleton height={100}/>
            </Col>
            <Col lg="12">
              <Skeleton height={70}/>
            </Col>
            <Col lg="8">
              <Skeleton height={250}/>
            </Col>
            <Col lg="8">
              <Skeleton height={500}/>
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  if(checkout) {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <h4>Dashboard</h4> */}
            <Row className="">

              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle>Informasi Pembiayaan</CardTitle>
                    <CardText className="text-primary">Menunggu tandatangan akad musyarakah penerima pembiayaan</CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="8">
                <Card>
                  {/* new */}
                  <CardBody>
                    <CardTitle className="mb-0">Mohon menunggu penandatangan akad musyarakah oleh penerima pembiayaan</CardTitle>
                    {/* <CardText className="text-primary">Transfer sesuai nominal berikut</CardText> */}
                    <hr></hr>
                    <h3 className="base-color bold text-primary">Rp {(Number(checkout.amount) + 3000).toLocaleString()}</h3>
                    <div className="mt-2"><b><em>Total pendanaan anda diatas sudah termasuk biaya layanan pembayaran institusi sebesar Rp 3,000. Mohon melakukan transfer sesuai dengan nominal di atas.</em></b></div>
                    <div className="mt-2">Pembiayaan Proyek <b>{detail.title}</b></div>
                    <div className="mt-2">ID Transaksi <b>{checkout.trx_id}</b></div>

                    {/* <InputGroup className="mt-3 w-100 mb-2">
                      <InputGroupAddon addonType="prepend">
                        <div className="d-flex align-items-center">
                          <img src={img_bsi} height="48" className="pr-1" />
                        </div>
                      </InputGroupAddon>
                      <Input disabled className="font-weight-bold" value={'123456789'}/>
                      <Button color="primary" onClick={() => handleCopy(checkout.va_number)}>Salin</Button>
                    </InputGroup> */}

                    {bank == "bsi" ?
                      <>
                        <Badge color="primary" className="mt-2">{`Transfer Menggunakan BSI`}</Badge>
                        <Row className="d-flex align-items-center">
                          <Col lg="1" className="mr-3">
                            <img src={img_bsi} height="48" className="mt-3" />
                          </Col>
                          {/* <Col lg="4">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Institusi</label>
                              <Input disabled className="font-weight-bold" value={'5243'}/>
                            </div>
                          </Col> */}
                          <Col lg="10">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Transaksi BSI</label>
                              <Input disabled className="font-weight-bold" value={checkout.va_number}/>
                            </div>
                          </Col>
                        </Row>
                        <Badge color="primary" className="mt-2 mb-1">Transfer Menggunakan Bank Lain</Badge>
                        <Row className="d-flex align-items-center">
                          <Col lg="1" className="mr-3">
                            <img src={img_other_bank} height="34" className="mt-3" />
                          </Col>
                          {/* <Col lg="2">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Bank</label>
                              <Input disabled className="font-weight-bold" value={'900'}/>
                            </div>
                          </Col>
                          <Col lg="2">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Institusi</label>
                              <Input disabled className="font-weight-bold" value={'5243'}/>
                            </div>
                          </Col> */}
                          <Col lg="10">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Transaksi BSI</label>
                              <Input disabled className="font-weight-bold" value={`9005243${checkout.va_number}`}/>
                            </div>
                          </Col>
                        </Row>
                      </> : null
                    }

                    {bank == "permata" ?
                      <>
                        <Badge color="primary" className="mt-2">{`Transfer Menggunakan Permata Syariah`}</Badge>
                        <Row className="d-flex align-items-center">
                          <Col lg="2" className="mr-3">
                            <img src={img_permata} height="32" className="mt-3" />
                          </Col>
                          {/* <Col lg="4">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Institusi</label>
                              <Input disabled className="font-weight-bold" value={'5243'}/>
                            </div>
                          </Col> */}
                          <Col lg="9">
                            <div className="w-100">
                              <label className="mb-0 font-12">Kode Transaksi Permata Syariah</label>
                              <Input disabled className="font-weight-bold" value={checkout.va_number}/>
                            </div>
                          </Col>
                        </Row>
                      </> : null
                    }
                    
                    <div className="mt-3">
                      *Catatan: <b>Kode Transaksi akan terbit pada menu portofolio jika penerima pembiayaan telah menandatangani akad musyarakah.</b>
                    </div>
                    <div className="mt-3">
                      Transfer Sebelum <b>{moment(checkout.expired_at).format('DD MMMM YYYY | HH:mm:ss ')} WIB </b> 
                      atau Pembiayaan Anda akan dibatalkan secara otomatis.
                    </div>
                    <Button color="primary" className="mt-2" onClick={() => props.history.push("/portfolio?page=1&perPage=5")}>Lihat Portfolio</Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle>Panduan Transfer</CardTitle>
                    <CardText className="text-primary">Pilih menu dibawah untuk melihat panduan</CardText>
                    
                    {bank == "bsi" ?
                      <div id="accordion">
                        <Card className="mb-1">
                          <CardHeader
                            className="p-3" 
                            id="headingOne"
                            onClick={() => {
                              setcol1(!col1)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                ATM BSI
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>

                          <Collapse isOpen={col1}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol>
                                  <li className="mb-0">Pilih menu Pembayaran/Payment/Pembelian.</li>
                                  <li className="mb-0">Pilih Institusi.</li>
                                  <li className="mb-0">Masukkan kode Institusi “PT. Ethis Fintek Indonesia” yakni 5243 + Kode Transaksi Pendanaan, Contoh: 5243 + Kode Transaksi Pendanaan (10 Digit) = 52430123456789.</li>
                                  <li className="mb-0">Pilih: Benar / Selanjutnya.</li>
                                  <li className="mb-0">Kemudian layar akan menampilkan Informasi Data Transfer, pastikan data sudah benar.</li>
                                  <li className="mb-0">Pilih Benar / Ya.</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Card>
                        <Card className="mb-1">
                          <CardHeader 
                            onClick={() => {
                                  setcol2(!col2)
                                }}
                            style={{ cursor: "pointer" }} 
                            className="p-3" 
                            id="headingTwo"
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                {" "}
                                BSI Mobile & BSI NET Banking{" "}
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col2}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Pilih menu Pembayaran/Payment.</li>
                                  <li className="mb-0">Pilih Institusi.</li>
                                  <li className="mb-0">Pada kolom Institusi, Masukkan kode 5243 atau pilih (PT. Ethis Fintek Indonesia).</li>
                                  <li className="mb-0">Pilih kolom ID Pelanggan/Kode Transaksi Input Kode Transaksi Pendanaan (10 Digit)  dan pilih “Lanjut”. </li>
                                  <li className="mb-0">Akan Muncul Pada Screen : Nama, Kode Transaksi dan Jumlah Transaksi yang akan anda transfer.</li>
                                  <li className="mb-0">Layar validasi transfer akan muncul pada layar, Masukkan PIN anda dan pilih “Selanjutnya” untuk submit.</li>
                                  <li className="mb-0">Selesai.</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol3(!col3)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                Teller Seluruh Cabang BSI
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col3}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Tulis pada Slip Bayar Tagihan, Nama Pelanggan, Kode 5243 + Kode Transaksi Pendanaan, Contoh: 5243 + Kode Transaksi Pendanaan (10 Digit) = 52430123456789 dan Dibayarkan secara Tunai.</li>
                                  <li className="mb-0">Pendana Tandatangan pada Slip Bayar tagihan tersebut.</li>
                                  <li className="mb-0">Pendana menyampaikan ke Petugas Teller BSI.</li>
                                  <li className="mb-0">Teller akan mengkonfirmasi nominal transaksi yang akan ditransfer.</li>
                                  <li className="mb-0">Selesai</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol4(!col4)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                ATM / M-Banking Bank lain
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col4}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Pilih Transfer.</li>
                                  <li className="mb-0">Pilih  Antar Bank Online / Transfer Online antar Bank.</li>
                                  <li className="mb-0">Masukkan kode BSI 451 atau pilih Bank BSI.
                                  </li>
                                  <li className="mb-0">Masukkan kode 900 + Kode Institusi 5243 + Masukkan Kode Transaksi Pendanaan (10 Digit), Contoh=  90052430123456789.</li>
                                  <li className="mb-0">Masukkan Nominal Transfer</li>
                                  <li className="mb-0">Layar akan menampilkan data transaksi anda, jika data sudah benar pilih YA (OK).</li>
                                  <li className="mb-0">Proses Pembayaran.</li>
                                  <li className="mb-0">Pembayaran Anda telah berhasil.</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                      </div> :

                      <div id="accordion">
                        <Card className="mb-1">
                          <CardHeader
                            className="p-3" 
                            id="headingOne"
                            onClick={() => {
                              setcol1(!col1)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                ATM Permata
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>

                          <Collapse isOpen={col1}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol>
                                  <li className="mb-0">Masukkan PIN</li>
                                  <li className="mb-0">Pilih menu TRANSAKSI LAINNYA</li>
                                  <li className="mb-0">Pilih menu PEMBAYARAN</li>
                                  <li className="mb-0">Pilih menu PEMBAYARAN LAINNYA</li>
                                  <li className="mb-0">Pilih menu VIRTUAL ACCOUNT</li>
                                  <li className="mb-0">Masukkan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, Contoh: 8319031121212018.</li>
                                  <li className="mb-0">Tekan BENAR</li>
                                  <li className="mb-0">Pilih rekening yang menjadi sumber dana yang akan didebet, lalu tekan YA untuk konfirmasi transaksi</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Card>
                        <Card className="mb-1">
                          <CardHeader 
                            onClick={() => {
                                  setcol2(!col2)
                                }}
                            style={{ cursor: "pointer" }} 
                            className="p-3" 
                            id="headingTwo"
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                className="text-dark"
                              >
                                {" "}
                                ATM Bank lain{" "}
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col2}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Masukkan PIN</li>
                                  <li className="mb-0">Pilih menu TRANSAKSI</li>
                                  <li className="mb-0">Pilih menu KE REK BANK LAIN</li>
                                  <li className="mb-0">Masukkan kode sandi Bank Permata (013) diikuti dengan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, dan tekan BENAR</li>
                                  <li className="mb-0">Masukkan jumlah pembayaran sesuai dengan yang ditagihkan dalam halaman konfirmas</li>
                                  <li className="mb-0">Pilih BENAR untuk menyetujui transaksi tersebut</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol3(!col3)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                Permata Mobile
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col3}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Buka aplikasi PermataMobile Internet (Android/iPhone)</li>
                                  <li className="mb-0">Masukkan User ID & Password</li>
                                  <li className="mb-0">Pilih Pembayaran Tagihan</li>
                                  <li className="mb-0">Pilih Virtual Account</li>
                                  <li className="mb-0">Masukkan 16 digit nomor Virtual Account yang tertera pada halaman konfirmasi</li>
                                  <li className="mb-0">Masukkan nominal pembayaran sesuai dengan yang ditagihkan</li>
                                  <li className="mb-0">Muncul Konfirmasi pembayaran</li>
                                  <li className="mb-0">Masukkan otentikasi transaksi/token</li>
                                  <li className="mb-0">Transaksi selesai</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                        <Card className="mb-0">
                          <CardHeader 
                            className="p-3" 
                            id="headingThree"
                            onClick={() => {
                              setcol4(!col4)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h6 className="m-0 font-14 d-flex justify-content-between">
                              <span
                                
                                className="text-dark"
                              >
                                Permata Net
                              </span>
                              <h5 className="m-0">
                                <i className="bx bx-chevron-down"></i>
                              </h5>
                            </h6>
                          </CardHeader>
                          <Collapse isOpen={col4}>
                            <Card style={{fontSize: 11}}>
                              <CardBody>
                                <ol class="text-left">
                                  <li className="mb-0">Buka website PermataNet: https://new.permatanet.com</li>
                                  <li className="mb-0">Masukkan user ID & Password</li>
                                  <li className="mb-0">Pilih Pembayaran Tagihan</li>
                                  <li className="mb-0">Pilih Virtual Account</li>
                                  <li className="mb-0">Masukkan 16 digit nomor Virtual Account yang tertera pada halaman konfirmasi</li>
                                  <li className="mb-0">Masukkan nominal pembayaran sesuai dengan yang ditagihkan</li>
                                  <li className="mb-0">Muncul Konfirmasi pembayaran</li>
                                  <li className="mb-0">Masukkan otentifikasi transaksi/token</li>
                                  <li className="mb-0">Transaksi selesai</li>
                                </ol>
                              </CardBody>
                            </Card>
                          </Collapse>{" "}
                        </Card>
                      </div>
                    }

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment >
      <Prompt
        when={true}
        message={'Jika anda keluar dari halaman ini transaksi anda akan dibatalkan.'}
      />
      {renderModalAkad()}
      <div className="page-content" onClick={handleCheckFull}>
        <Container fluid>
          <Row>
          <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle><span className="text-danger">**</span>Mohon Diperhatikan!</CardTitle>
                  <CardText className="text-dark">
                    <span>Mohon untuk tidak me-refresh browser sampai anda melanjutkan ke tahap selanjutnya.<br></br> Anda dapat melakukan pembayaran setelah penerima pembiayaan telah menandatangani akad musyarakah.</span>
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Pembiayaan dan Akad</CardTitle>
                  <CardText className="text-primary">
                    Periksa Kembali Pembiayaan Anda
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg="8">
              <Card>
                <CardBody>
                  <CardTitle>Tanda Tangan Akad Pembiayaan</CardTitle>
                  <CardText className="text-primary text-justify">Sesuai peraturan OJK sebelum melanjutkan pendanaan. Anda perlu menandatangani kedua dokumen berikut.</CardText>
                  <div className="mb-2 font-weight-bold text-justify">
                    Silahkan lakukan penandatangan Akad Wakalah dan Akad Musyarakah dengan klik tombol dibawah ini untuk melanjutkan sebelum
                    {renderTimeLeft()}
                  </div>
                  <Card className="border mb-0">
                    <CardHeader className="d-flex justify-content-between align-items-center pt-1 pr-2 pl-2 pb-1">
                      <div className="">Klik refresh dipojok kanan untuk memperbaharui status akad anda<span className="text-danger">*</span></div>
                      <div 
                        className="bg-white border-custom d-inline px-2 py-1 rounded"
                        style={{cursor: "pointer"}}
                        onClick={checkStatusAkad}
                      >
                        <i className={`fas fa-sync-alt ${loadStat && "rotate"}`}></i>
                      </div>
                    </CardHeader>
                    <CardBody className="p-2">
                      <div className="row">
                        <div className="col">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            onClick={handleWakalah}
                          >
                            Wakalah - {renderStatusAkad(trx, "wakalah") ? "Sudah TTD" : <span className="text-dark font-weight-bold">Belum TTD</span>}
                          </button>
                          {/* <div className="font-weight-bold font-10">{renderStatusAkad(trx, "wakalah") ? "Sudah TTD" : "Belum TTD"}</div> */}
                        </div>
                        <div className="col">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            onClick={handleMusyarakah}
                          >
                            Musyarakah -  {renderStatusAkad(trx, "musyarakah") ? "Sudah TTD" :  <span className="text-dark font-weight-bold">Belum TTD</span>}
                          </button>
                          {/* <div className="font-weight-bold font-10">{renderStatusAkad(trx, "musyarakah") ? "Sudah TTD" : "Belum TTD"}</div> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  
                </CardBody>
              </Card>
            </Col>

            <Col lg="8">
              <Card>
                <CardBody>
                  <CardTitle>Informasi Pembiayaan</CardTitle>
                  <h4><CardText className="text-primary font-weight-bold">{detail.title}</CardText></h4>
                  <hr></hr>
                  <Row>
                    <Col>Pembiayaan Anda</Col>
                    <Col>Rp {Number(pledge.nominal).toLocaleString()}</Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>Biaya Layanan</Col>
                    <Col>Rp 3,000</Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>Estimasi Nisbah</Col>
                    <Col>Rp {handleNisbah()}</Col>
                  </Row>
                  <hr></hr>
                  <Row className="d-flex align-items-center">
                    <Col>Pilih Metode Pembayaran</Col>
                    <Col>
                      {/* {Number(pledge.nominal) > 90000000 ?
                        <Tooltip
                          placement="top"
                          isOpen={ttop}
                          target="TooltipTop-bank"
                          toggle={() => {
                            setttop(!ttop)
                          }}
                        >
                          Hanya untuk Pembiayaan sampai dengan Rp 90.000.000
                        </Tooltip> : null
                      } */}
                      <Tooltip
                        placement="top"
                        isOpen={ttop}
                        target="TooltipTop-bank"
                        toggle={() => {
                          setttop(!ttop)
                        }}
                      >
                        Maaf, saat ini metode pembayaran dengan BSI tidak tersedia.
                      </Tooltip>
                      {/* <div id="TooltipTop-bank" style={{opacity: `${Number(pledge.nominal) > 90000000 ? "0.5" : "1"}`}} className="custom-control custom-radio custom-radio-primary mb-2 d-flex align-items-center"> */}
                      <div id="TooltipTop-bank" style={{opacity: `0.5`}} className="custom-control custom-radio custom-radio-primary mb-2 d-flex align-items-center">
                        <input
                          type="radio"
                          id="customRadiocolor1"
                          name="bank"
                          className="custom-control-input"
                          onChange={() => setBank("bsi") }
                          checked={bank === "bsi"}
                          // disabled={Number(pledge.nominal) > 90000000}
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadiocolor1"
                        >
                          {/* <span>BSI</span> */}
                          <img src={img_bsi} height="40" style={{marginTop: "-12px"}} />
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-radio-primary d-flex align-items-center">
                        <input
                          type="radio"
                          id="customRadiocolor2"
                          name="bank"
                          className="custom-control-input"
                          onChange={() => setBank("permata") }
                          checked={bank == "permata"}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadiocolor2"
                        >
                          {/* <span>Permata Syariah</span> */}
                          <img src={img_permata} height="40" style={{marginTop: "-12px"}} />
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-5">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      onClick={handleTransaction}
                      disabled={loadButt}
                    >
                      {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Lanjutkan'}
                    </button>
                  </div>
                  <div className="font-weight-bold text-justify mt-2">Catatan: Setelah melakukan tanda tangan pada kedua dokumen akad diatas, mohon menunggu 1 menit sebagai proses verifikasi untuk dapat melanjutkan. Pembayaran dapat dilakukan setelah dilakukannya tandatangan kedua belah pihak antara pemberi pembiayaan dan penerima pembiayaan.</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(
  connect(mapStateToProps, null)(HighlightAkad)
)

