import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import Profiles from "./Profile/reducer"

//crypto
import crypto from "./crypto/reducer"

//projects
import projects from "./projects/reducer"

//loading
import Loading from "./loading/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  crypto,
  projects,
  Profiles,
  Loading,
})

export default rootReducer
