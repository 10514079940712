import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import {
  delCampaignDesc,
  delCampaignGal,
  postDescription,
  postGallery,
  putCampaignGall,
} from "helpers/backend_helpers"
import React, { useState } from "react"
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Card,
  CardText,
} from "reactstrap"

const CampaignGallery = props => {
  const { campaign, getCampaignUuid, handleRoles, loadButt, setLoadButt } =
    props
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [file, setFile] = useState(null)

  const [itemEdit, setItemEdit] = useState(null)

  const toggle = () => {
    setModal(!modal)
  }

  const toggleEdit = item => {
    setItemEdit(item)
    setModalEdit(!modalEdit)
  }

  const handleSubmit = async (e, value) => {
    const newValue = {
      ...value,
      image: file,
    }
    const token = localStorage.getItem("token")

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postGallery(campaign.uuid, formData, token)
      getCampaignUuid()
      toggle()
      setFile(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil tambah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleDelete = async item => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await delCampaignGal(campaign.uuid, item.uuid, token)
      getCampaignUuid()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil hapus data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleSubmitEdit = async (e, value) => {
    const token = localStorage.getItem("token")

    const newValue = {
      ...value,
      image: file,
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await putCampaignGall(campaign.uuid, itemEdit.uuid, formData, token)
      getCampaignUuid()
      setFile(null)
      toggleEdit(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  return (
    <div>
      <Modal isOpen={modal} autoFocus={true} size="lg">
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Tambah Gallery</ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmit(e, v)
              }}
            >
              <Row>
                <Col lg="6">
                  <PInput
                    name="title"
                    type="text"
                    label="Judul"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                  <PInput
                    name="description"
                    type="textarea"
                    label="Deskripsi"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Deskripsi harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="6">
                  <PInput
                    name="title_en"
                    type="text"
                    label="Judul (EN)"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                  <PInput
                    name="description_en"
                    type="textarea"
                    label="Deskripsi (EN)"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Deskripsi harus diisi",
                      },
                    }}
                  />
                </Col>
              </Row>

              <PInput
                name="image"
                type="file"
                label="Image"
                helpMessage="jpg, jpeg atau png"
                onChange={e => setFile(e.target.files[0])}
                validate={{
                  required: { value: true, errorMessage: "Judul harus diisi" },
                }}
                value={file}
              />
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <Modal isOpen={modalEdit} autoFocus={true} size="lg">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleEdit(null)}>
            Ubah Gallery
          </ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmitEdit(e, v)
              }}
            >
              <Row>
                <Col lg="6">
                  <PInput
                    name="title"
                    type="text"
                    label="Judul"
                    value={itemEdit ? itemEdit.title : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                  <PInput
                    name="description"
                    type="textarea"
                    label="Deskripsi"
                    value={itemEdit ? itemEdit.description : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Deskripsi harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="6">
                  <PInput
                    name="title_en"
                    type="text"
                    label="Judul (EN)"
                    value={itemEdit ? itemEdit.title_en : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                  <PInput
                    name="description_en"
                    type="textarea"
                    label="Deskripsi (EN)"
                    value={itemEdit ? itemEdit.description_en : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Deskripsi harus diisi",
                      },
                    }}
                  />
                </Col>
              </Row>

              <PInput
                name="image"
                type="file"
                label="Image"
                helpMessage="jpg, jpeg atau png"
                onChange={e => setFile(e.target.files[0])}
                value={file}
              />
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button
          color="info"
          onClick={toggle}
          disabled={
            handleRoles().includes("Moderator")
              ? false
              : handleRoles().includes("Bisnis")
              ? true
              : false
          }
        >
          + Tambah Galleri
        </Button>
      </div>

      <Row className="mt-4">
        {campaign &&
          campaign.campaign_galleries.map(item => {
            return (
              <Col lg={4}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={API_URL + item.image}
                    alt="Skote"
                  />
                  <CardBody className="p-1">
                    <CardTitle className="mt-0 mb-0">{item.title}</CardTitle>
                    <CardText>
                      <small className="text-muted">{item.subtitle}</small>
                    </CardText>
                    <CardText className="justify-center">
                      {item.description}
                    </CardText>
                  </CardBody>
                  <Row className="row mt-3">
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="danger"
                        onClick={() => handleDelete(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Hapus
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="info"
                        onClick={() => toggleEdit(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Ubah
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )
          })}
      </Row>
    </div>
  )
}

export default CampaignGallery
