import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"

const BarChart = ({ data, categories, name, height = "350" }) => {
  const series = [
    {
      name: name,
      data: data,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: categories,
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={height}
    />
  )
}

export default BarChart
