import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ModalImage from "react-modal-image"
import PuffLoader from "react-spinners/PuffLoader"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap"
import { API_PEFINDO, API_URL } from "helpers/api_url"
import PInput from "components/Input/PInput"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import axios from "axios"
import Toaster from "components/Common/Toast"
import { MDBDataTable } from "mdbreact"
import moment from 'moment'
import classnames from "classnames"
import Loading from "pages/Utility/Loading"

const PefindoDetail = props => {
  const {isOpen, toggle, item, reason_text, reason } = props

  const [activeTab, setActiveTab] = useState("3")
  const [load, setLoad] = useState(false)
  const [dataScore, setDataScore] = useState([])
  const [dataContract, setDataContract] = useState([])
  const [data, setData] = useState(null)

  useEffect(() => {
    if(item) {
      getDetailPefindo()
    }
  }, [item])

  const getDetailPefindo = async () => {
    setLoad(true)
    const query = `?pefindoid=${item.PefindoId}&reason=${reason}&reasontext=${reason_text}&subject=Company`
    try {
      const data = await axios.post(`${API_PEFINDO}/api/company/score${query}`, null)
      setData(data.data)

      if(data.data.GetCustomReportResult.CIP.RecordList.Record) {
        if(Array.isArray(data.data.GetCustomReportResult.CIP.RecordList.Record)) {
          setDataScore(data.data.GetCustomReportResult.CIP.RecordList.Record)
        } else {
          setDataScore([data.data.GetCustomReportResult.CIP.RecordList.Record])
        }
      }

      if(data.data.GetCustomReportResult.ContractOverview.ContractList.Contract) {
        if(Array.isArray(data.data.GetCustomReportResult.ContractOverview.ContractList.Contract)) {
          setDataContract(data.data.GetCustomReportResult.ContractOverview.ContractList.Contract)
        } else {
          setDataContract([data.data.GetCustomReportResult.ContractOverview.ContractList.Contract])
        }
      }

      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", "Gagal ambil data pefindo")
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleValue = (nama, value) => {
    if(!value) return "-"
    if(nama.includes("Date")) return moment(value).format("DD MMMM YYYY")
    return value
  }

  const reasonCheck = (item) => {
    if(!item) return ""
    if(Array.isArray(item)) return item[item.length - 1].Description
    return item.Description
  }

  // console.log(data.GetCustomReportResult.Company.Identifications.NPWP)

  const handleSaveScore = () => {
    const datas = {score: dataScore[0].Score === "999" ? "900" : dataScore[0].Score, npwp: data.GetCustomReportResult.Company.Identifications.NPWP}
    localStorage.setItem("pefindo", JSON.stringify(datas))

    Toaster("success", "Sukses", "Berhasil simpan skor Pefindo")
  }

  const renderContent = () => {
    return (
      <div className="mt-2">
        <div>
          <ul className="nav nav-tabs nav-tabs-custom mb-4" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Company
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                CIP
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Contract Overview
              </NavLink>
            </NavItem>
          </ul>

          <TabContent activeTab={activeTab} className="">
            <TabPane tabId="3">
              <Row>
                { data &&
                  Object.keys(data.GetCustomReportResult.Company).map((item, i) => {
                    return (
                      <Col lg="12">
                        <Card className="border">
                          <CardHeader className=""><b>{item}</b> </CardHeader>
                          <CardBody>
                            <AvForm>
                              <Row>
                                {Object.keys(data.GetCustomReportResult.Company[item]).map((itemIn, idIn) => {
                                  return (
                                    <Col lg="6">
                                      {/* {itemIn} */}
                                      <PInput 
                                        name={`${itemIn}_${i}_${idIn}`}
                                        label={itemIn}
                                        type="text"
                                        // value={data.GetCustomReportResult.Company[item][itemIn] || "-"}
                                        value={handleValue(itemIn, data.GetCustomReportResult.Company[item][itemIn])}
                                        disabled
                                      />
                                    </Col>
                                  )
                                })}
                              </Row>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  })
                }
              </Row>
            </TabPane>
            <TabPane tabId="1" id="all-order">
              <div className="d-flex justify-content-end">
                {dataScore.length > 0 &&
                  <Button
                    color="primary"
                    className="mb-2"
                    onClick={handleSaveScore}
                  >
                    Simpan
                  </Button>
                }
              </div>
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Probability Of Default</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Score</th>
                    <th scope="col">Trend</th>
                  </tr>
                </thead>
                <tbody>
                  {dataScore.map(item => {
                    return (
                      <tr>
                        <td>{moment(item.Date).format("DD MMMM YYYY")}</td>
                        <td>{item.Grade}</td>
                        <td>{item.ProbabilityOfDefault}</td>
                        <td>{reasonCheck(item.ReasonsList.Reason)}</td>
                        <td>{item.Score}</td>
                        <td>{item.Trend}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </TabPane>
            <TabPane tabId="2" id="contract">
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col" className="full-width-tbl">Role Of Client</th>
                    <th scope="col" className="full-width-tbl">Phase Of Contract</th>
                    <th scope="col" className="full-width-tbl">Sector</th>
                    <th scope="col" className="full-width-tbl">Type Of Contract</th>
                    <th scope="col" className="full-width-tbl">Start Date</th>
                    <th scope="col" className="full-width-tbl">Contract Status</th>
                    <th scope="col" className="full-width-tbl">Total Amount</th>
                    <th scope="col" className="full-width-tbl">Outstanding Amount</th>
                    <th scope="col" className="full-width-tbl">Past Due Amount</th>
                    <th scope="col" className="full-width-tbl">PastDueDays</th>
                  </tr>
                </thead>
                <tbody>
                  {dataContract.map(item => {
                    return (
                      <tr>
                        <td className="full-width-tbl">{item.RoleOfClient}</td>
                        <td className="full-width-tbl">{item.PhaseOfContract}</td>
                        <td className="full-width-tbl">{item.Sector}</td>
                        <td className="full-width-tbl">{item.TypeOfContract}</td>
                        <td className="full-width-tbl">{moment(item.StartDate).format("DD MMM YYYY")}</td>
                        <td className="full-width-tbl">{item.ContractStatus}</td>
                        <td className="full-width-tbl">{item.TotalAmount.Currency} {parseInt(item.TotalAmount.Value).toLocaleString()}</td>
                        <td className="full-width-tbl">{item.OutstandingAmount.Currency} {parseInt(item.OutstandingAmount.Value).toLocaleString()}</td>
                        <td className="full-width-tbl">{!item.PastDueAmount ? "-" : `${item.PastDueAmount.Currency} ${parseInt(item.PastDueAmount.Value).toLocaleString()}`}</td>
                        <td className="full-width-tbl">{item.PastDueDays} Day</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </TabPane>
          </TabContent>
        </div>
      </div>
    )
  }

  const toggleAllClose = () => {
    toggle(null)
    setActiveTab("3")
    setData(null)
    setDataContract([])
    setDataScore([])
  }

  if(!item) return null

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      className="exampleModal h-100"
      scrollable={true}
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={toggleAllClose}>{item && item.CompanyName}</ModalHeader>
        <ModalBody>
          {load ? 
            <div style={{height: "50vh"}} className="d-flex justify-content-center align-items-center">
              <PuffLoader color={"#0ec6a2"} loading={true} size={120} />
            </div>
            : renderContent()
          }

        </ModalBody>
      </div>
    </Modal>
  )
}

export default PefindoDetail