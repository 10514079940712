import React, { useState } from "react"
import { useHistory, Link } from "react-router-dom"
import { Button, Collapse } from "reactstrap"

import Logo from "./image/logo.svg"
import ic_close from "./image/ic_close.svg"
import ic_menuburger from "./image/ic_menuburger.svg"
import ic_arrow from "./image/ic_arrowsub.svg"
import ic_profile from "./image/ic_profile.svg"
import ic_dashboard from "./image/ic_dashboard.svg"
import ic_cart from "./image/ic_cart.svg"
import logo_ethis from "../../assets/images/ethis/Logo-Website-Ethis-2021-new-02.png"

const FullTime = [
  {
    item: "Tentang Kami",
    slug: "/tentang-kami",
  },
  {
    item: "Cara Kerja",
    slug: "/how-it-works",
  },
  {
    item: "GCG",
    slug: "/good-corporate-governance",
  },
  {
    item: "Laporan Audit Keuangan",
    slug: "/laporan-keuangan",
  },
]

const AfterHour = [
  {
    item: "Webinar",
    slug: "/webinar",
  },
  {
    item: "Ebook",
    slug: "/ebook",
  },
  {
    item: "Blog",
    slug: "/blogs",
  },
  {
    item: "Hak Cipta",
    slug: "/haki",
  },
]

const PartTime = [
  {
    item: "Pernyataan Resiko",
    slug: "/mitigasi-resiko",
  },
  {
    item: "FAQ Ethis Indonesia",
    slug: "/faq",
  },
  {
    item: "Syarat & Ketentuan",
    slug: "/syarat-dan-ketentuan",
  },
  {
    item: "Kebijakan Privasi",
    slug: "/ethis-kebijakan-privasi",
  },
  {
    item: "Layanan Pengaduan",
    slug: "/layanan-pengaduan",
  },
]

const SubMenuItem = ({ items, toggle }) => {
  if (!items) return null
  return items.map((item, index) => {
    return (
      <Link
        to={item.slug}
        className="linkSubMenu"
        onClick={toggle}
        key={String(index)}
      >
        <div className="SubMenuItem">{item.item}</div>
      </Link>
    )
  })
}

const SubMenu = ({ submenu, setsubMenu, subname, listMenu, toggle }) => {
  return (
    <div className="border-bottom ml-2 mr-2">
      <div
        className=""
        onClick={() => {
          if (submenu === subname) {
            setsubMenu("")
          } else {
            setsubMenu(subname)
          }
        }}
        onKeyDown
      >
        <div className="subMenuNav">
          {subname}
          {listMenu && (
            <img
              src={ic_arrow}
              alt="ic-arrow-sub"
              className={`arrow-subitem ${
                submenu === subname ? "rotateArrowSubItem" : ""
              }`}
            />
          )}
        </div>
      </div>
      <Collapse isOpen={submenu === subname}>
        <SubMenuItem items={listMenu} toggle={toggle} />
      </Collapse>
    </div>
  )
}

const ProgramMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={FullTime}
          subname="PROFIL"
          toggle={toggle}
        />
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={PartTime}
          subname="FAQ ETHIS INDONESIA"
          toggle={toggle}
        />
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          listMenu={AfterHour}
          subname="EDUKASI"
          toggle={toggle}
        />
        {/* <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          subname="EVENT, WORKSHOP, & BOOTCAMP"
          toggle={toggle}
        /> */}
        {/* <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          subname="KIDS & TEENS"
          toggle={toggle}
        /> */}
      </Collapse>
    </>
  )
}

const menu_pembiayaan = [
  // {name: "Beri Pembiayaan", url: "/kampanye"},
  { name: "Cara Memberi Pembiayaan", url: "/cara-mendanai" },
  { name: "Cara Mengajukan Pembiayaan", url: "/cara-mengajukan-pendanaan" },
  { name: "Ajukan Pembiayaan", url: "/apply-project" },
  { name: "Biaya - Biaya", url: "/interest-rates-and-fees" },
  { name: "Akad - Akad", url: "/akad-akad" },
]

const StudentMenu = ({ menu, name, submenu, setsubMenu, toggle, user }) => {
  const renderAjukanPembiayaan = () => {
    if (user) {
      let roles = user.user_role.map(item => item.role.title)
      if (roles.includes("Moderator") || roles.includes("Borrower")) {
        return (
          <Link to="/apply-project" className="linkSubMenu" onClick={toggle}>
            <SubMenu
              submenu={submenu}
              setsubMenu={setsubMenu}
              subname="Ajukan Pembiayaan"
            />
          </Link>
        )
      } else {
        return null
      }
    }

    return (
      <Link to="/register" className="linkSubMenu" onClick={toggle}>
        <SubMenu
          submenu={submenu}
          setsubMenu={setsubMenu}
          subname="Ajukan Pembiayaan"
        />
      </Link>
    )
  }
  return (
    <>
      <Collapse isOpen={menu === name}>
        {menu_pembiayaan.map((item, i) => {
          if (item.url == "/apply-project") return renderAjukanPembiayaan()
          return (
            <Link to={item.url} className="linkSubMenu" onClick={toggle}>
              <SubMenu
                submenu={submenu}
                setsubMenu={setsubMenu}
                subname={item.name}
              />
            </Link>
          )
        })}
      </Collapse>
    </>
  )
}

const AboutMenu = ({ menu, name, submenu, setsubMenu, toggle }) => {
  return (
    <>
      <Collapse isOpen={menu === name}>
        <Link to="/alumni-stories" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="OUR STORIES"
          />
        </Link>
        <Link to="/news" className="linkSubMenu" onClick={toggle}>
          <SubMenu
            submenu={submenu}
            setsubMenu={setsubMenu}
            subname="MEDIA & PRESS"
          />
        </Link>
        <Link to="/career" className="linkSubMenu" onClick={toggle}>
          <SubMenu submenu={submenu} setsubMenu={setsubMenu} subname="CAREER" />
        </Link>
      </Collapse>
    </>
  )
}

const Menu = ({ menu, setMenu, name, Content, toggle, user }) => {
  const [submenu, setsubMenu] = useState("")
  return (
    <div>
      <div
        className={`menuNavMoobile border-bottom ${
          menu === name ? "borderLeft" : ""
        }`}
        onClick={() => {
          if (menu === name) {
            setMenu("")
          } else {
            setMenu(name)
          }
        }}
        onKeyDown
      >
        {name}
        <img
          src={ic_arrow}
          alt="ic-arrow"
          className={`arrow-item ${menu === name ? "rotateArrowItem" : ""}`}
        />
      </div>
      <Content
        menu={menu}
        name={name}
        submenu={submenu}
        setsubMenu={setsubMenu}
        toggle={toggle}
        user={user}
      />
    </div>
  )
}

const Profile = ({ user, menu, name, setMenu, toggle }) => {
  const toggleSignOut = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("tokenImpersonater")
    // setMenu("")
    // toggle()
    window.location.reload()
  }
  return (
    <div className="profileNavMobile border-bottom">
      <img
        src={ic_arrow}
        alt="ic-arrow"
        className={`arrow-item-profile ${
          menu === name ? "rotateArrowItem" : ""
        }`}
      />
      <div
        className="pl-3 pr-3 pt-3 pb-3"
        onClick={() => {
          if (menu === name) {
            setMenu("")
          } else {
            setMenu(name)
          }
        }}
        onKeyDown
      >
        <div className="d-flex align-items-center">
          <img
            src={ic_profile}
            alt="ic_profileMobile"
            className="ic_profileNavMobile"
          />
          <div className="ml-2">
            <div className={`font-16 bold ${menu === name ? "base" : ""}`}>
              {user.first_name} {user.last_name}
            </div>
            <div className="font-11 text-secondary">{user.email}</div>
          </div>
        </div>
      </div>
      <Collapse isOpen={menu === name}>
        <div className="pl-3 pr-3 pt-2 pb-2">
          <Link
            to="/beranda"
            style={{ textDecoration: "none" }}
            onClick={toggle}
          >
            <Button className="w-100" pill color="primary">
              <img
                src={ic_dashboard}
                alt="ic_dashboard-M"
                className="ic_dashboardMobile"
              />
              <span>Dasbor</span>
            </Button>
          </Link>
          <Link
            // to="/"
            style={{ textDecoration: "none" }}
            onClick={toggleSignOut}
          >
            <div className="btnLogoutMobile text-secondary">Keluar</div>
          </Link>
          {/* {!auth.accountVerified && (
            <div className="border-top pwdk-pt-3 text-center">
              <div className="f-sm-h6">Hi, Mohon verifikasi email kamu</div>
              <div className="f-body-alt f--dark-gray pwdk-mt-2">
                Verifikasi email kamu sekarang untuk dapat mengikuti program,
                dan penawaran menarik kami lainnnya.
              </div>
              <PButton className="btnVerifyMobile">
                Kirim Link Verifikasi
              </PButton>
            </div>
          )} */}
        </div>
      </Collapse>
    </div>
  )
}

export default ({ toggle, dropdownActive, user }) => {
  const [menu, setMenu] = useState("")
  // const [submenu, setsubMenu] = useState('');
  // const [openMenu, setOpenMenu] = useState(false);
  return (
    <header className="d-md-none headerNavMobile">
      <div className="globalNav">
        <div className="container">
          <nav className="d-flex align-items-center justify-content-between">
            <img
              src={logo_ethis}
              alt="navlogo-mobile"
              className="navLogoMobile"
            />
            <div onClick={toggle} onKeyDown>
              <img
                src={dropdownActive ? ic_close : ic_menuburger}
                alt="btn-mobile"
                className=""
              />
              {/* {!dropdownActive &&
                Object.values(cart.cart).length > 0 &&
                auth.user.email && <div className="notifMobileNav" />} */}
            </div>
          </nav>
        </div>
      </div>
      <div className={`${dropdownActive ? "border-bottom" : ""}`}>
        {user && (
          <Collapse isOpen={dropdownActive}>
            <Profile
              menu={menu}
              setMenu={setMenu}
              // Content={ProgramMenu}
              name="Profil"
              user={user}
              toggle={toggle}
              // toggleSignOut={toggleSignOut}
            />
          </Collapse>
        )}
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={ProgramMenu}
            name="Tentang Kami"
            toggle={toggle}
          />
        </Collapse>
        <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={StudentMenu}
            name="Pembiayaan"
            toggle={toggle}
            user={user}
          />
        </Collapse>
        {/* <Collapse isOpen={dropdownActive}>
          <Menu
            menu={menu}
            setMenu={setMenu}
            Content={AboutMenu}
            name="Edukasi"
            toggle={toggle}
          />
        </Collapse> */}
        {!user && (
          <Collapse isOpen={dropdownActive}>
            <Link to="/login">
              <div
                className="loginNav border-bottom text-dark"
                // onClick={toggleSignIn}
                // onKeyDown={null}
              >
                <div className="font-11 text-secondary">Sudah punya akun?</div>
                <div className="font-16 bold mt-1">Login</div>
                <img
                  src={ic_arrow}
                  alt="ic-arrow"
                  className="arrow-item position"
                />
              </div>
            </Link>
            <Link
              to={{
                pathname: "/register",
                // state: { from: history.location.pathname },
              }}
              style={{ textDecoration: "none" }}
              onClick={toggle}
            >
              <div className="loginNav text-dark">
                <div className="font-11 text-secondary">Belum punya akun ?</div>
                <div className="font-16 bold mt-1">Daftar Sekarang</div>
                <img
                  src={ic_arrow}
                  alt="ic-arrow"
                  className="arrow-item position"
                />
              </div>
            </Link>
          </Collapse>
        )}

        {/* {false && (
          <Collapse isOpen={dropdownActive}>
            <Link
              to="/cart"
              style={{ textDecoration: 'none' }}
              onClick={toggle}
            >
              <div className="menuNavMoobile border-bottom">
                Keranjang
                <img
                  src={ic_cart}
                  alt="ic_cart_mobile"
                  className="ic_cart_mobile"
                />
                <div className="countCartMobile">
                  {Object.values(cart.cart).length}
                  2
                </div>
              </div>
            </Link>
          </Collapse>
        )} */}
      </div>
    </header>
  )
}
