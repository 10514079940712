import React, { Component, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  CardHeader,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import ModalImage from "react-modal-image"

//Dropzone
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import images
import verificationImg from "../../assets/images/verification-img.png"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import AvField from "availity-reactstrap-validation/lib/AvField"
import Toaster from "components/Common/Toast"
import {
  getScoringResult,
  postScoringOne,
  postScoringTwo,
  getScoringResultProperty,
} from "helpers/backend_helpers"
import { API_URL, API_URL_1 } from "helpers/api_url"

const ModalTinjauHistory = props => {
  const token = localStorage.getItem("token")
  const { toggleModal, subCategoryProperty, item, getData, isOpen } = props
  const [loadButt, setLoadButt] = useState(false)
  const [score, setScore] = useState([])
  const [dataFile, setDataFile] = useState(null)

  // console.log(score)

  useEffect(() => {
    getScore()
  }, [item])

  const getScore = async () => {
    if (item) {
      const data = await getScoringResultProperty(item.uuid, token)
      setScore(data.data)
    }
  }

  const handleSubmit = async (e, value) => {
    // console.log(value)
    // console.log(dataFile)
    let answersOne = []
    Object.keys(value).forEach(item => {
      let arr = item.split(",")
      if (arr[arr.length - 1] === "one") {
        // const formData = new FormData()
        // formData.append("sub_category_id", arr[0])
        // formData.append("analyst_response_master_id", value[item])
        // formData.append("file", dataFile ? dataFile[arr[0]] : null)
        // answersOne.push(formData)
        answersOne.push({
          sub_category_id: arr[0],
          analyst_response_master_id: value[item],
          file: dataFile ? dataFile[arr[0]] : null,
        })
      }
    })

    // console.log(answersOne)

    const formData = new FormData()
    answersOne.forEach((items, idx) => {
      Object.keys(items).forEach(item => {
        if (items[item]) {
          // console.log(`answer[${idx}][${item}]: ${items[item]}`)
          formData.append(`answers[${idx}][${item}]`, items[item])
        }
        // formData.append(`answer[${idx}][${item}]`, items[item])
      })
    })

    setLoadButt(true)
    try {
      await postScoringOne(item.uuid, formData, token)
      setLoadButt(false)
      toggleModal(null)
      setDataFile(null)
      getData()
      Toaster("success", "Sukses", "Data tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleValue = (scores, subCatId) => {
    if (scores.length === 0) return ""

    for (let i = 0; i < scores.length; i++) {
      let scoreSubId = scores[i].analyst_response_master.analyst_sub_category.id
      if (scoreSubId == subCatId) {
        return scores[i].analyst_response_master.id
      }
    }

    return ""
  }

  const handleValueFile = (scores, subCatId) => {
    // if(scores.length === 0) return ""

    for (let i = 0; i < scores.length; i++) {
      let scoreSubId = scores[i].analyst_response_master.analyst_sub_category.id
      if (scoreSubId == subCatId) {
        if (dataFile && dataFile[subCatId]) {
          return URL.createObjectURL(dataFile[subCatId])
        }
        if (!scores[i].file) return null
        return API_URL + scores[i].file
      }
    }

    return ""
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={true}
      // centered={true}
      scrollable={true}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggleModal(null)
            setDataFile(null)
          }}
        >
          {item && item.title}
          {/* Peninjauan Lapangan */}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmit(e, v)
            }}
          >
            {subCategoryProperty.map(item => {
              return (
                <Card className="border">
                  <CardHeader>
                    <b>{item.title}</b>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {item.analyst_sub_category.map((inItem, index) => {
                        if (inItem.analyst_response_master.length === 0)
                          return null
                        return (
                          <Col md={6} key={index} className="border pt-3 px-3">
                            <AvField
                              type="select"
                              name={`${inItem.id},one`}
                              label={inItem.title}
                              // helpMessage={}
                              // onChange={handleChange}
                              value={handleValue(score, inItem.id)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Field harus diisi",
                                },
                              }}
                              disabled
                              className="disabled-input-custom"
                            >
                              <option value="">Pilih</option>
                              {inItem.analyst_response_master.map(
                                itemSelected => {
                                  return (
                                    <option value={itemSelected.id}>{`${
                                      itemSelected.title
                                    } ${
                                      item.title == "Lainnya"
                                        ? ""
                                        : `(${itemSelected.score})`
                                    }`}</option>
                                  )
                                }
                              )}
                            </AvField>
                            <PInput
                              type="file"
                              name={`${inItem.id}`}
                              label={`Unggah`}
                              helpMessage="jpeg, jpg atau png"
                              onChange={e =>
                                setDataFile({
                                  ...dataFile,
                                  [inItem.id]: e.target.files[0],
                                })
                              }
                              value={dataFile ? dataFile[inItem.id] : ""}
                              disabled
                              className="disabled-input-custom"
                            />
                            {handleValueFile(score, inItem.id) ? (
                              // <img src={API_URL + handleValueFile(score, inItem.id)} alt="files" className="w-100 mb-3" />
                              <ModalImage
                                small={handleValueFile(score, inItem.id)}
                                large={handleValueFile(score, inItem.id)}
                                alt="files"
                                className="mb-3"
                              />
                            ) : null}
                          </Col>
                        )
                      })}
                    </Row>
                  </CardBody>
                </Card>
              )
            })}

            {score.length > 0 && (
              <Row>
                <Col>
                  <Card className="border">
                    <CardHeader className="">
                      <b>Total</b>
                    </CardHeader>
                    <CardBody>
                      <h2>
                        <b>{score[0].total.tinjauan_lokasi}</b>
                      </h2>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            {/* <div className="mt-3">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
              </button>
            </div> */}
          </AvForm>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalTinjauHistory
