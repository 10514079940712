import Toaster from "components/Common/Toast"
import {
  getFriends,
  getSession,
  postGetChat,
  postMessages,
  postSession,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import moment from "moment"
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import images from "assets/images"

import Echo from "laravel-echo"
import { API_URL } from "helpers/api_url"

const Chat = props => {
  const token = localStorage.getItem("token")
  const { user } = props

  const [friends, setFriends] = useState([])
  const [load, setLoad] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  const [currentRoomId, setCurrentRoomId] = useState(null)
  const [chatList, setChatList] = useState([])
  const [curMessage, setcurMessage] = useState("")
  const [selectUser, setSelectUser] = useState(null)

  const options = {
    broadcaster: "pusher",
    key: "ab0f7406c459cb8e5f69",
    cluster: "ap1",
    encrypted: true,
    authEndpoint: `${API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        apiKey: process.env.REACT_APP_API_KEY,
      },
    },
  }

  const echo = new Echo(options)

  useEffect(() => {
    getData()
    if (friends.length > 0) {
      initChat()
      handleJoin()
    }
  }, [])

  useEffect(() => {
    getMessages()
  }, [selectUser])

  useEffect(() => {
    if (selectUser) {
      echo
        .private(`Chat.${selectUser.session.id}`)
        .listen("PrivateChatEvent", e => {
          let newMessages = [
            ...chatList,
            { message: e.content, type: 1, send_at: "Just Now" },
          ]
          setChatList(newMessages)
        })
    }
  }, [chatList])

  // const listenForEverySession = (friend) => {
  //   echo.private(`Chat.${friend.session.id}`).listen(
  //       "PrivateChatEvent",
  //       e => (friend.session.open ? "" : friend.session.unreadCount++)
  //   );
  // }

  const initChat = () => {
    echo.channel(`Chat`).listen("SessionEvent", ev => {
      let newFriends = [...friends]
      for (let i = 0; i < newFriends.length; i++) {
        if (newFriends[i].id == ev.session_by) {
          newFriends[i].session = ev.session
        }
      }
      setFriends(newFriends)
    })
  }

  const handleJoin = () => {
    let newFriends = [...friends]
    echo
      .join(`Chat`)
      .joining(user => {
        // console.log(user)
        newFriends.forEach(item => {
          if (user.id == item.id) {
            item.online = true
          }
        })
        setFriends(newFriends)
      })
      .leaving(user => {
        // console.log(user)
        newFriends.forEach(item => {
          if (user.id == item.id) {
            item.online = false
          }
        })
        setFriends(newFriends)
      })
  }

  const getData = async () => {
    setLoad(true)
    try {
      const data = await getFriends(token)
      let newFriends = [...data.data]

      // echo.join(`Chat`)
      //   .here(users => {
      //     newFriends.forEach((item) => {
      //       users.forEach((itemIn) => {
      //         if (itemIn.id == item.id) {
      //           item.online = true;
      //         }
      //       })

      //       if(item.session) {
      //         echo.private(`Chat.${item.session.id}`).listen(
      //             "PrivateChatEvent",
      //             e => item.session.unreadCount++
      //         );
      //       }
      //     })

      //     setFriends(newFriends)
      //     setLoad(false)
      //   })
      setFriends(newFriends)
      setLoad(false)
    } catch (error) {
      setLoad(false)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const checkSession = (user1_id, user2_id, sessions) => {
    for (let i = 0; i < sessions.length; i++) {
      if (
        (sessions[i].user1_id === user1_id &&
          sessions[i].user2_id === user2_id) ||
        (sessions[i].user1_id === user2_id && sessions[i].user2_id === user1_id)
      ) {
        return sessions[i]
      }
    }
    return null
  }

  //Use For Chat Box
  // const userChatOpen = async (userSelect, index) => {
  //   const sessions = await getSession(token)
  //   const sessionData = checkSession(parseInt(props.user.id), userSelect.id, sessions.data)
  //   // console.log(sessionData)
  //   if(sessionData) {
  //     let newValue = [...friends]
  //     newValue[index].session = sessionData
  //     newValue[index].session.unreadCount = 0
  //     setFriends(newValue)
  //     setSelectUser(userSelect)
  //   } else {
  //     const data = await postSession({to_user: userSelect.uuid}, token)
  //     let newValue = [...friends]
  //     newValue[index].session = data.data
  //     setFriends(newValue)
  //     setSelectUser(userSelect)
  //   }
  // }

  const userChatOpen = async (userSelect, index) => {
    let newFriends = [...friends]
    if (userSelect.session) {
      newFriends[index].session.open = true
      newFriends[index].session.unreadCount = 0
      setFriends(newFriends)
      setSelectUser(userSelect)
    } else {
      const data = await postSession({ to_user: userSelect.uuid }, token)
      newFriends[index].session = data.data
      setFriends(newFriends)
      setSelectUser(userSelect)
    }
  }

  const addMessagePrivate = async () => {
    if (selectUser) {
      const chat = await postMessages(
        selectUser.session.id,
        { to_user: selectUser.uuid, message: curMessage },
        token
      )
      // setChatList([...chatList, ])
      setChatList([
        ...chatList,
        {
          id: chat.data,
          message: curMessage,
          type: 0,
          read_at: null,
          send_at: "Just now",
        },
      ])
      setcurMessage("")
    }
  }

  const getMessages = async () => {
    if (selectUser && selectUser.session) {
      const chats = await postGetChat(selectUser.session.id, token)
      setChatList(chats.data)
    }
  }

  // console.log(friends)

  if (load) return <Loading />
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pesan" breadcrumbItem="Pesan" />
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar mr-lg-4">
                  <div className="">
                    <div className="py-4 border-bottom">
                      <Media>
                        <div className="align-self-center mr-3">
                          <img
                            src={`https://banner2.cleanpng.com/20180327/ssq/kisspng-computer-icons-user-profile-avatar-profile-5ab9e3b05772c0.6947928615221318883582.jpg`}
                            className="avatar-xs rounded-circle"
                            alt=""
                          />
                        </div>
                        <Media body>
                          <h5 className="font-size-15 mt-0 mb-1">
                            {user.first_name} {user.last_name}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle mr-1" />
                            Active
                          </p>
                        </Media>

                        {/* <div>
                          <Dropdown
                            isOpen={notification_Menu}
                            toggle={toggleNotification}
                            className="chat-noti-dropdown active"
                          >
                            <DropdownToggle className="btn" tag="i">
                              <i className="bx bx-bell bx-tada" />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem href="#">Action</DropdownItem>
                              <DropdownItem href="#">
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#">
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div> */}
                      </Media>
                    </div>

                    {/* <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div> */}

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Pesan</span>
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Group</span>
                          </NavLink>
                        </NavItem> */}
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Contacts</span>
                          </NavLink>
                        </NavItem> */}
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <h5 className="font-size-14 mb-3">Recent</h5>
                            <ul className="list-unstyled chat-list">
                              <PerfectScrollbar style={{ height: "410px" }}>
                                {friends.map((item, idx) => {
                                  return (
                                    <li
                                      key={idx}
                                      // className={
                                      //   currentRoomId === chat.roomId
                                      //     ? "active"
                                      //     : ""
                                      // }
                                      className="active"
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          // userChatOpen(
                                          //   chat.id,
                                          //   chat.name,
                                          //   chat.status,
                                          //   chat.roomId
                                          // )
                                          userChatOpen(item, idx)
                                        }}
                                      >
                                        <Media>
                                          {/* <div className="align-self-center mr-3">
                                            <i
                                              className={
                                                chat.status === "online"
                                                  ? "mdi mdi-circle text-success font-size-10"
                                                  : chat.status === "intermediate"
                                                  ? "mdi mdi-circle text-warning font-size-10"
                                                  : "mdi mdi-circle font-size-10"
                                              }
                                            />
                                          </div> */}
                                          <div className="align-self-center mr-3">
                                            <img
                                              src={`https://banner2.cleanpng.com/20180327/ssq/kisspng-computer-icons-user-profile-avatar-profile-5ab9e3b05772c0.6947928615221318883582.jpg`}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>

                                          <Media
                                            className="overflow-hidden"
                                            body
                                          >
                                            <h5 className="text-truncate font-size-14 mb-1">
                                              {item.name}
                                            </h5>
                                            <p className="text-truncate mb-0">
                                              {item.email}
                                            </p>
                                          </Media>
                                          {/* <div className="font-size-11">
                                            {chat.time}
                                          </div> */}
                                        </Media>
                                      </Link>
                                    </li>
                                  )
                                })}
                                {/* {map(chats, chat => (
                                  <li
                                    key={chat.id + chat.status}
                                    className={
                                      currentRoomId === chat.roomId
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatOpen(
                                          chat.id,
                                          chat.name,
                                          chat.status,
                                          chat.roomId
                                        )
                                      }}
                                    >
                                      <Media>
                                        <div className="align-self-center mr-3">
                                          <i
                                            className={
                                              chat.status === "online"
                                                ? "mdi mdi-circle text-success font-size-10"
                                                : chat.status === "intermediate"
                                                ? "mdi mdi-circle text-warning font-size-10"
                                                : "mdi mdi-circle font-size-10"
                                            }
                                          />
                                        </div>
                                        <div className="align-self-center mr-3">
                                          <img
                                            src={images[chat.image]}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                          />
                                        </div>

                                        <Media className="overflow-hidden" body>
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {chat.name}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {chat.description}
                                          </p>
                                        </Media>
                                        <div className="font-size-11">
                                          {chat.time}
                                        </div>
                                      </Media>
                                    </Link>
                                  </li>
                                ))} */}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>

                        {/* <TabPane tabId="2">
                          <h5 className="font-size-14 mb-3">Group</h5>
                          <ul className="list-unstyled chat-list">
                            <PerfectScrollbar style={{ height: "410px" }}>
                              {groups &&
                                groups.map(group => (
                                  <li key={"test" + group.image}>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatOpen(
                                          group.id,
                                          group.name,
                                          group.status,
                                          Math.floor(Math.random() * 100)
                                        )
                                      }}
                                    >
                                      <Media className="align-items-center">
                                        <div className="avatar-xs mr-3">
                                          <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                            {group.image}
                                          </span>
                                        </div>

                                        <Media body>
                                          <h5 className="font-size-14 mb-0">
                                            {group.name}
                                          </h5>
                                        </Media>
                                      </Media>
                                    </Link>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </TabPane> */}

                        {/* <TabPane tabId="3">
                          <h5 className="font-size-14 mb-3">Contact</h5>

                          <div>
                            <PerfectScrollbar style={{ height: "410px" }}>
                              {contacts &&
                                contacts.map(contact => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul className="list-unstyled chat-list">
                                      {contact.child.map(array => (
                                        <li key={"test" + array.id}>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              userChatOpen(
                                                array.id,
                                                array.name,
                                                array.status,
                                                Math.floor(Math.random() * 100)
                                              )
                                            }}
                                          >
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </PerfectScrollbar>
                          </div>
                        </TabPane> */}
                      </TabContent>
                    </div>
                  </div>
                </div>

                <div className="w-100">
                  {selectUser && (
                    <div className="w-100 user-chat">
                      <Card>
                        <div className="p-4 border-bottom ">
                          <Row>
                            <Col md="4" xs="9">
                              <h5 className="font-size-15 mb-1">
                                {selectUser.name}
                              </h5>

                              {/* <p className="text-muted mb-0">
                                <i
                                  className={
                                    Chat_Box_User_Status === "online"
                                      ? "mdi mdi-circle text-success align-middle mr-1"
                                      : Chat_Box_User_Status === "intermediate"
                                      ? "mdi mdi-circle text-warning align-middle mr-1"
                                      : "mdi mdi-circle align-middle mr-1"
                                  }
                                />
                                {Chat_Box_User_Status}
                              </p> */}
                            </Col>
                            {/* <Col md="8" xs="3">
                              <ul className="list-inline user-chat-nav text-right mb-0">
                                <li className="list-inline-item d-none d-sm-inline-block">
                                  <Dropdown
                                    isOpen={search_Menu}
                                    toggle={toggleSearch}
                                  >
                                    <DropdownToggle className="btn nav-btn" tag="i">
                                      <i className="bx bx-search-alt-2" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="dropdown-menu-md"
                                      right
                                    >
                                      <Form className="p-3">
                                        <FormGroup className="m-0">
                                          <InputGroup>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search ..."
                                              aria-label="Recipient's username"
                                            />
                                            <InputGroupAddon addonType="append">
                                              <Button color="primary" type="submit">
                                                <i className="mdi mdi-magnify" />
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </FormGroup>
                                      </Form>
                                    </DropdownMenu>
                                  </Dropdown>
                                </li>
                                <li className="list-inline-item  d-none d-sm-inline-block">
                                  <Dropdown
                                    isOpen={settings_Menu}
                                    toggle={toggleSettings}
                                  >
                                    <DropdownToggle className="btn nav-btn" tag="i">
                                      <i className="bx bx-cog" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem href="#">
                                        View Profile
                                      </DropdownItem>
                                      <DropdownItem href="#">
                                        Clear chat
                                      </DropdownItem>
                                      <DropdownItem href="#">Muted</DropdownItem>
                                      <DropdownItem href="#">Delete</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </li>
                                <li className="list-inline-item">
                                  <Dropdown
                                    isOpen={other_Menu}
                                    toggle={toggleOther}
                                  >
                                    <DropdownToggle className="btn nav-btn" tag="i">
                                      <i className="bx bx-dots-horizontal-rounded" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem href="#">Action</DropdownItem>
                                      <DropdownItem href="#">
                                        Another Action
                                      </DropdownItem>
                                      <DropdownItem href="#">
                                        Something else
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </li>
                              </ul>
                            </Col> */}
                          </Row>
                        </div>

                        <div>
                          <div className="chat-conversation p-3">
                            <ul className="list-unstyled">
                              <PerfectScrollbar
                                style={{ height: "470px" }}
                                // containerRef={ref => setMessageBox(ref)}
                              >
                                {/* <li>
                                  <div className="chat-day-title">
                                    <span className="title">Today</span>
                                  </div>
                                </li> */}
                                {chatList.map(message => {
                                  return (
                                    <li
                                      // key={"test_k" + message.id}
                                      className={
                                        message.type === 0 ? "right" : ""
                                      }
                                    >
                                      <div className="conversation-list">
                                        {/* <UncontrolledDropdown>
                                          <DropdownToggle
                                            href="#"
                                            className="btn nav-btn"
                                            tag="i"
                                          >
                                            <i className="bx bx-dots-vertical-rounded" />
                                          </DropdownToggle>
                                          <DropdownMenu direction="right">
                                            <DropdownItem href="#">
                                              Copy
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                              Save
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                              Forward
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                              Delete
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown> */}
                                        <div className="ctext-wrap">
                                          <div className="conversation-name">
                                            {message.type === 0
                                              ? `${user.first_name} ${user.last_name}`
                                              : `${selectUser.name}`}
                                          </div>
                                          <p>{message.message}</p>
                                          <p className="chat-time mb-0">
                                            <i className="bx bx-time-five align-middle mr-1" />
                                            {/* {moment(message.createdAt).format(
                                              "DD-MM-YY hh:mm"
                                            )} */}
                                            {message.send_at}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                })}
                                {/* {messages &&
                                  map(messages, message => (
                                    <li
                                      key={"test_k" + message.id}
                                      className={
                                        message.sender === currentUser.name
                                          ? "right"
                                          : ""
                                      }
                                    >
                                      <div className="conversation-list">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            href="#"
                                            className="btn nav-btn"
                                            tag="i"
                                          >
                                            <i className="bx bx-dots-vertical-rounded" />
                                          </DropdownToggle>
                                          <DropdownMenu direction="right">
                                            <DropdownItem href="#">
                                              Copy
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                              Save
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                              Forward
                                            </DropdownItem>
                                            <DropdownItem href="#">
                                              Delete
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <div className="ctext-wrap">
                                          <div className="conversation-name">
                                            {message.sender}
                                          </div>
                                          <p>{message.message}</p>
                                          <p className="chat-time mb-0">
                                            <i className="bx bx-time-five align-middle mr-1" />
                                            {moment(message.createdAt).format(
                                              "DD-MM-YY hh:mm"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ))} */}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                          <div className="p-3 chat-input-section">
                            <Row>
                              <Col>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    value={curMessage}
                                    // onKeyPress={onKeyPress}
                                    onChange={e =>
                                      setcurMessage(e.target.value)
                                    }
                                    className="form-control chat-input"
                                    placeholder="Pesan..."
                                  />
                                  {/* <div className="chat-input-links">
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">
                                        <Link to="#">
                                          <i
                                            className="mdi mdi-emoticon-happy-outline"
                                            id="Emojitooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="Emojitooltip"
                                          >
                                            Emojis
                                          </UncontrolledTooltip>
                                        </Link>
                                      </li>
                                      <li className="list-inline-item">
                                        <Link to="#">
                                          <i
                                            className="mdi mdi-file-image-outline"
                                            id="Imagetooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="Imagetooltip"
                                          >
                                            Images
                                          </UncontrolledTooltip>
                                        </Link>
                                      </li>
                                      <li className="list-inline-item">
                                        <Link to="#">
                                          <i
                                            className="mdi mdi-file-document-outline"
                                            id="Filetooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="Filetooltip"
                                          >
                                            Add Files
                                          </UncontrolledTooltip>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div> */}
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={() =>
                                    // addMessage(currentRoomId, currentUser.name)
                                    addMessagePrivate()
                                  }
                                  className="btn-rounded chat-send w-md waves-effect waves-light"
                                >
                                  <span className="d-none d-sm-inline-block mr-2">
                                    Kirim
                                  </span>{" "}
                                  <i className="mdi mdi-send" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Chat)
