import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import ModalImage from "react-modal-image"
import React, { useEffect, useState } from "react"
import { Badge, Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import { connect } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import AsyncSelect from 'react-select/async'
import { getCampaignSelector, getCarousel, getCarouselAdmin, getClaimVoucher, getVoucher, postCarousel, postVoucher, updateCarousel, updateVoucher } from "helpers/backend_helpers"
// import { NULL } from "node-sass"

const ManageHomePage = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [allCampagin, setAllCampaign] = useState(null)
  const [campaign, setCampaign] = useState([])
  const [linkTo, setLinkTo] = useState("")

  // new
  const [carousel, setCarousel] = useState([])
  const [banner, setBanner] = useState(null)

  const [modalCreateCarousel, setModalCreateCarousel] = useState(false)
  const [modalUpdateCarousel, setModalUpdateCarousel] = useState(false)
  const [selectUpdateCarousel, setSelectUpdateCarousel] = useState(null)

  // console.log(carousel)

  useEffect(() => {
    getData()
  }, [activeTab])

  useEffect(async () => {
    setLoad(true)
    const campaign = await getCampaignSelector(token, "")
    setCampaign(campaign.data)
    setLoad(false)
  }, [])

  const getData = async () => {
    setLoad(true)
    if(activeTab === "1") {
      const data = await getCarouselAdmin(token)
      setCarousel(data.data)
    }
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleModalCreateCarousel = () => {
    setBanner(null)
    setLinkTo("")
    setModalCreateCarousel(!modalCreateCarousel)
  }
  const toggleModalUpdateCarousel = (item = null) => {
    setSelectUpdateCarousel(item)
    setBanner(null)
    // setLinkTo("")
    if(item) {
      if(item.url) {
        setLinkTo("other")
      } else if(item.isCampaign == 1) {
        setLinkTo("campaign")
      } else {
        setLinkTo("")
      }
    } else {
      setLinkTo("")
    }
    setModalUpdateCarousel(!modalUpdateCarousel)
  }

  const handleCreateCarousel = async (e, value) => {
    if(!value.campaign_id) {
      value.isCampaign = "false"
    } else {
      value.isCampaign = "true"
    }
    value.file = banner
    
    const formData = new FormData()
    Object.keys(value).map(item => {
      formData.append(item, value[item])
    })

    setLoadButt(true)
    try {
      await postCarousel(formData, token)
      setBanner(null)
      setLoadButt(false)
      getData()
      toggleModalCreateCarousel()
      Toaster("success", "Sukses", "Berhasi tambah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderCreateCarousel = () => {
    return (
      <Modal
        isOpen={modalCreateCarousel}
        role="dialog"
        autoFocus={true}
        scrollable
      >
        <ModalHeader toggle={toggleModalCreateCarousel}>Buat Carousel Banner</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateCarousel(e, v)
            }}
          >
            <PInput
              name="file"
              type="file"
              label="Banner"
              onChange={(e) => setBanner(e.target.files[0])}
              helpMessage="jpg, jpeg atau png."
              value={banner}
              validate={{
                required: {value: true, errorMessage: 'Code harus diisi'},
              }}
            />
            <PInput
              name="linkTo"
              type="select"
              label="Link"
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              onChange={e => setLinkTo(e.target.value)}
              options={[
                {uuid: "", title: "Tidak Ada"},
                {uuid: "campaign", title: "Kampanye"},
                {uuid: "other", title: "Lainnya"},
              ]}
            />
            {linkTo && linkTo == "campaign" ?
              <PInput
                name="campaign_id"
                type="select"
                label="Link Banner"
                // helpMessage="Kosongkan bila tidak ada."
                validate={{
                  required: {value: true, errorMessage: 'Link harus diisi'},
                }}
                options={campaign}
              /> : null
            }
            {linkTo && linkTo == "other" ?
              <PInput
                name="url"
                type="text"
                label="Link Banner"
                helpMessage="cth. /webinar"
                validate={{
                  required: {value: true, errorMessage: 'Link harus diisi'},
                }}
              /> : null
            }
            <PInput
              name="isActive"
              type="select"
              label="Status"
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Aktif"},
                {uuid: "false", title: "Tidak Aktif"},
              ]}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleUpdateCarousel = async (e, value) => {
    
    if(!value.campaign_id) {
      value.isCampaign = "false"
    } else {
      value.isCampaign = "true"
    }
    value.file = banner

    if(!banner) {
      delete value.file
    }

    // console.log(value)
    
    const formData = new FormData()
    Object.keys(value).map(item => {
      formData.append(item, value[item])
    })

    setLoadButt(true)
    try {
      await updateCarousel(selectUpdateCarousel.id, formData, token)
      setBanner(null)
      setLoadButt(false)
      getData()
      toggleModalUpdateCarousel(null)
      Toaster("success", "Sukses", "Berhasi tambah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderUpdateCarousel = () => {
    return (
      <Modal
        isOpen={modalUpdateCarousel}
        role="dialog"
        autoFocus={true}
        scrollable
      >
        <ModalHeader toggle={toggleModalUpdateCarousel}>Ubah Carousel Banner</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleUpdateCarousel(e, v)
            }}
          >
            <PInput
              name="file"
              type="file"
              label="Banner"
              onChange={(e) => setBanner(e.target.files[0])}
              helpMessage="jpg, jpeg atau png."
              value={banner}
              // validate={{
              //   required: {value: true, errorMessage: 'Code harus diisi'},
              // }}
            />
            {/* <PInput
              name="campaign_id"
              type="select"
              label="Link Banner Kampanye"
              helpMessage="Kosongkan bila tidak ada."
              // validate={{
              //   required: {value: true, errorMessage: 'Jumlah harus diisi'},
              // }}
              value={selectUpdateCarousel && selectUpdateCarousel.campaign ? selectUpdateCarousel.campaign.uuid : ""}
              options={campaign}
            /> */}
            <PInput
              name="linkTo"
              type="select"
              label="Link"
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              onChange={e => setLinkTo(e.target.value)}
              value={linkTo}
              options={[
                {uuid: "", title: "Tidak Ada"},
                {uuid: "campaign", title: "Kampanye"},
                {uuid: "other", title: "Lainnya"},
              ]}
            />
            {linkTo && linkTo == "campaign" ?
              <PInput
                name="campaign_id"
                type="select"
                label="Link Banner"
                // helpMessage="Kosongkan bila tidak ada."
                value={selectUpdateCarousel && selectUpdateCarousel.campaign ? selectUpdateCarousel.campaign.uuid : ""}
                validate={{
                  required: {value: true, errorMessage: 'Link harus diisi'},
                }}
                options={campaign}
              /> : null
            }
            {linkTo && linkTo == "other" ?
              <PInput
                name="url"
                type="text"
                label="Link Banner"
                helpMessage="cth. /webinar"
                value={selectUpdateCarousel && selectUpdateCarousel.url ? selectUpdateCarousel.url : ""}
                validate={{
                  required: {value: true, errorMessage: 'Link harus diisi'},
                }}
              /> : null
            }
            
            <PInput
              name="isActive"
              type="select"
              label="Status"
              validate={{
                required: {value: true, errorMessage: 'Field harus diisi'},
              }}
              options={[
                {uuid: "true", title: "Aktif"},
                {uuid: "false", title: "Tidak Aktif"},
              ]}
              value={selectUpdateCarousel ? selectUpdateCarousel.isActive === 1 ? "true" : "false" : ""}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  // const loadOptions = (inputValue, callback) => {
  //   let query = `?search=${inputValue}`
  //   setTimeout(async() => {
  //     const data = await getCampaignSelector(token, query)
  //     callback(data.data.map(item => {
  //       return {label: item.title, value: item.id}
  //     }))
  //   })
  // };

  const tableCarousel = {
    columns: [
      {
        field: "file",
        label: "Banner",
      },
      {
        field: "campaign_link",
        label: "Link",
      },
      {
        field: "isActive",
        label: "Status",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: carousel.map(item => {
      return {
        ...item,
        file: <ModalImage
                small={API_URL + item.file}
                large={API_URL + item.file}
                alt="Banner Carousel"
                className="banner-admin"
              />,
        campaign_link: item.url ? item.url : item.isCampaign === 1 ? item.campaign.title : "-",
        isActive: item.isActive === 1 ? <Badge color="primary">Aktif</Badge> : <Badge color="secondary">Tidak Aktif</Badge>,
        action: <Button
                  className="btn-sm"
                  color="secondary"
                  onClick={() => toggleModalUpdateCarousel(item)}
                >
                  <i class='bx bxs-edit'></i>
                </Button>,
      }
    })
  }

  if(load) return <Loading title="Kelola" breadcrumbItem="HomePage" />

  return (
    <React.Fragment>
      {renderCreateCarousel()}
      {renderUpdateCarousel()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kelola" breadcrumbItem="Hompage" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Carousel
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Voucher Claimed
                      </NavLink>
                    </NavItem> */}
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModalCreateCarousel}>+ Tambah Carousel</Button>
                      </div>
                      
                      <MDBDataTable
                        responsive
                        bordered
                        data={tableCarousel}
                        striped
                        className="mt-3"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(ManageHomePage)
