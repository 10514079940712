import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import React, { useEffect, useState } from "react"
import { Button, Card, Col, FormGroup, Label } from "reactstrap"
import SignaturePad from 'react-signature-pad-wrapper'
import Toaster from "components/Common/Toast"

let signaturePad;

const StepTwo = ({
  // handleSubmitStepTwo,
  handleChangeFile,
  loadButt,
  project,
  fileTwo,
  setLoadButt,
  postUKMtwo,
  setRedirect,
  postEditUkm,
  params,
  token,
  masterData
}) => {

  const [organization, setOrganization] = useState(null)

  useEffect(() => {
    if(project.project_company.organitation) {
      setOrganization(project.project_company.organitation.id)
    }
  }, [])

  const handleOrganization = () => {
    if(masterData) {
      for(let i = 0; i < masterData.organitation.length; i++) {
        if(masterData.organitation[i].title == "Tidak ada") {
          return masterData.organitation[i].id
        }
      }
    }
    return null
  }

  const handleSubmitStepTwo = async (e, value) => {
    if(signaturePad.isEmpty()) return Toaster("error", "Ups", "Tanda tangan harus diisi")
    // console.log(signaturePad)
    if(project.status === 4) {
      let newValue = {
        ...value,
        ...fileTwo,
        ttd_borrower: signaturePad.toDataURL(),
      }
      const formData = new FormData()
      Object.keys(newValue).forEach(item => {
        if(newValue[item]) {
          formData.append(item, newValue[item])
        }
      })

      setLoadButt(true)
      try {
        await postUKMtwo(params, formData, token)
        Toaster("success", "Sukses", "Berhasil ajukan proyek")
        setLoadButt(false)
        setRedirect(true)
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    } else {

      let newValues = {
        ...value,
        ...fileTwo,
        ttd_borrower: signaturePad.toDataURL(),
        sector_industry_id: project.sector_industry.uuid,
        title: project.title,
        business_entity_id: project.project_company.business_entity.uuid,
        company_name: project.project_company.company_name,
        company_since: project.project_company.company_since,
        company_full_address: project.project_company.company_address.full_address,
        province_id: project.project_company.company_address.province.uuid,
        city_id: project.project_company.company_address.city.uuid,
        district_id: project.project_company.company_address.district.uuid,
        sub_district_id: project.project_company.company_address.sub_district.uuid,
        country_id: project.project_company.company_address.country.uuid,
        company_telp: project.project_company.company_telp,
        property_ownership_id: project.project_company.property_ownership.uuid,
        company_website: project.project_company.company_website,
        client_name: project.project_detail.client_name,
        submission_total: project.project_detail.submission_total,
        tenor: project.project_detail.tenor,
        description: project.project_detail.description,
        total_project_last_year: project.project_company.total_project_last_year,
        pic_full_name: project.project_pic[0].full_name,
        pic_position: project.project_pic[0].position,
        pic_phone_number: project.project_pic[0].phone_number,
        pic_email: project.project_pic[0].email,
        capital_contribution: project.capital_contribution,
        total_employee: project.project_company.total_employee,
      }

      const formData = new FormData()
      Object.keys(newValues).forEach(item => {
        if(newValues[item]) {
          formData.append(item, newValues[item])
        }
      })

      setLoadButt(true)
      try {
        await postEditUkm(params, formData, token)
        Toaster("success", "Sukses", "Berhasil ajukan proyek")
        setLoadButt(false)
        setRedirect(true)
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  return (
    <AvForm
      className="form-horizontal"
      onValidSubmit={(e, v) => {
        handleSubmitStepTwo(e, v)
      }}
    >
      <FormGroup row>
        <Label sm={3}>ID Onboarding Tahap 1</Label>
        <Col sm={9}>
          <PInput 
            name="onboardingId"
            type="text"
            value={project.onboardingId}
            disabled
            placeholder="ID Onboarding"
            validate={{
              required: {value: true, errorMessage: ' harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Nomor KTP Direktur/Komisaris<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="ktp_director_number"
            type="text"
            placeholder="Nomor KTP"
            value={project.project_pic[0].ktp_director_number}
            validate={{
              required: {value: true, errorMessage: 'Nomor KTP Direktur/Komisaris harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah KTP Direktur/Komisaris<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_ktp_direktur"
            type="file"
            helpMessage="jpeg, jpg atau png maksimal 5 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah KTP Direktur/Komisaris harus diisi'},
            }}
            value={fileTwo.file_ktp_direktur}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_pic[0].file_ktp_direktur}
              onClick={() => window.open(`${API_URL}${project.project_pic[0].file_ktp_direktur}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Nomor NPWP Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="npwp_company_number"
            type="text"
            placeholder="Nomor NPWP"
            value={project.project_company.npwp_company_number}
            validate={{
              required: {value: true, errorMessage: 'Nomor NPWP Perusahaan harus diisi'},
              pattern: {value: '^[0-9]+$', errorMessage: 'Format NPWP harus angka'},
              minLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
              maxLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah NPWP Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_npwp_company"
            type="file"
            helpMessage="jpeg, jpg atau png maksimal 5 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah NPWP Perusahaan harus diisi'},
            }}
            value={fileTwo.file_npwp_company}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_npwp_company}
              onClick={() => window.open(`${API_URL}${project.project_company.file_npwp_company}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah NIB<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_nib_company"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah NIB harus diisi'},
            }}
            value={fileTwo.file_nib_company}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_nib_company}
              onClick={() => window.open(`${API_URL}${project.project_company.file_nib_company}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Nomor NIB Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="company_siup_number"
            type="text"
            placeholder="Nomor NIB Perusahaan"
            value={project.project_company.company_siup_number}
            validate={{
              required: {value: true, errorMessage: 'Nomor NIB Perusahaan harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah SIUP Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_siup_company"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah SIUP Perusahaan harus diisi'},
            }}
            value={fileTwo.file_siup_company}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_siup_company}
              onClick={() => window.open(`${API_URL}${project.project_company.file_siup_company}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={3}>Nomor Akta Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="akta_company_number"
            type="text"
            placeholder="Nomor Akta Perusahaan"
            value={project.project_company.akta_company_number}
            validate={{
              required: {value: true, errorMessage: 'Nomor Akta Perusahaan harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah Akta Perusahaan<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_akta_company"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah Akta Perusahaan harus diisi'},
            }}
            value={fileTwo.file_akta_company}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_akta_company}
              onClick={() => window.open(`${API_URL}${project.project_company.file_akta_company}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Akta Perubahan Terakhir<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="akta_last_change_number"
            type="text"
            placeholder="Akta Perubahan Terakhir"
            value={project.project_company.akta_last_change_number}
            validate={{
              required: {value: true, errorMessage: 'Akta Perubahan terakhir harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah Akta Perubahan Terakhir<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_akta_last_change_number"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah Akta Perubahan Terakhir harus diisi'},
            }}
            value={fileTwo.file_akta_last_change_number}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_akta_last_change_number}
              onClick={() => window.open(`${API_URL}${project.project_company.file_akta_last_change_number}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>SK Kemenkumham<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="sk_kemenkumham"
            type="text"
            placeholder="SK Kemenkumham"
            value={project.project_company.sk_kemenkumham}
            validate={{
              required: {value: true, errorMessage: 'SK Kemenkumham harus diisi'},
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Unggah SK Kemenkumham<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="file_sk_kemenkumham"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4, errorMessage: 'Unggah SK Kemenkumhan harus diisi'},
            }}
            value={fileTwo.file_sk_kemenkumham}
          />
          {project.status !== 4 &&
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_sk_kemenkumham}
              onClick={() => window.open(`${API_URL}${project.project_company.file_sk_kemenkumham}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button>
          }
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} className="pt-0">Apakah perusahaan anda tergabung dalam sebuah organisasi?<span className="text-danger">*</span></Label>
        <Col sm={9}>
          <PInput 
            name="organitation_id"
            type="select"
            // placeholder="Organisasi"
            value={project.project_company.organitation ? project.project_company.organitation.id : ""}
            validate={{
              required: {value: true, errorMessage: 'Organisasi harus diisi'},
            }}
            options={masterData.organitation}
            onChange={e => setOrganization(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} className="pt-0">Unggah Bukti Keanggotaan / Kepengurusan Organisasi{!organization || organization == handleOrganization() ? null : <span className="text-danger">*</span>}</Label>
        <Col sm={9}>
          <PInput 
            name="file_organitation"
            type="file"
            helpMessage="Pdf file maksimal 50 MB"
            onChange={handleChangeFile}
            validate={{
              required: {value: project.status === 4 && !organization || organization == handleOrganization() ? false : !organization || organization == handleOrganization() ? false : true, errorMessage: 'Unggah Organisasi harus diisi'},
            }}
            disabled={project.status === 4 && !organization || organization == handleOrganization() ? true : !organization || organization == handleOrganization() ? true : false}
            value={fileTwo.file_organitation}
          />
          {project.status !== 4 && project.project_company.file_organitation ?
            <Button 
              className="btn-sm" 
              disabled={!project.project_company.file_organitation}
              onClick={() => window.open(`${API_URL}${project.project_company.file_organitation}`, "_blank") }
            >
              <i className="fas fa-eye"></i>
            </Button> : null
          }
        </Col>
      </FormGroup>

      {project.status === 3 || project.status === 4 ?
        <>
        <FormGroup row>
          <Label sm={3}>Tanda Tangan PIC<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <Card className="w-50">
              <div style={{border: "1.5px solid"}}>
                <SignaturePad redrawOnResize={true} ref={ref => signaturePad = ref} />
              </div>
            </Card>
            <Button className="btn-sm" onClick={() => signaturePad.clear()}>Hapus</Button>
            {/* <Button className="btn-sm" onClick={() => console.log(signaturePad.isEmpty())}>Hapus</Button> */}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Penggunaan Tanda Tangan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="agreementTTD"
              label="Dengan ini saya bersedia dan menyetujui bahwa penggunaan tanda tangan ini akan digunakan pada akad Musyarakah pada PrivyId sebagai platform tanda tangan elektronik"
              type="checkbox"
              validate={{
                required: {value: true, errorMessage: 'Pernyataan harus diisi'},
              }}
            />
          </Col>
        </FormGroup></> :
        <FormGroup row>
          <Label sm={3}>Tanda Tangan PIC<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <Card className="w-50">
            <div style={{border: "1px solid"}}>
              <img src={API_URL + project.ttd_borrower} alt="ttd" className="w-100 p-2" />
            </div>
            </Card>
          </Col>
        </FormGroup>
      }
      {project.status === 2 || project.status === 3 ? 
        <>
          <FormGroup row>
            <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
            <Col sm={9}>
              <PInput 
                name="agreement"
                type="checkbox"
                label="Dengan ini saya menyatakan bahwa semua data yang diberikan
                adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
                data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
                pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
                // placeholder="Email"
                // value={inputOne.agreement}
                validate={{
                  required: {value: true, errorMessage: 'Persetujuan Pemohon harus diisi'},
                }}
              />
            </Col>
          </FormGroup>
          <div className="row justify-content-end mb-3">
            <div className="col-9">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan Kembali'}
              </button>

            </div>
          </div>
        </> : null
      }
      {project.status === 4 ? 
        <>
          <FormGroup row>
            <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
            <Col sm={9}>
              <PInput 
                name="agreement"
                type="checkbox"
                label="Dengan ini saya menyatakan bahwa semua data yang diberikan
                adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
                data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
                pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
                // placeholder="Email"
                // value={inputOne.agreement}
                validate={{
                  required: {value: true, errorMessage: 'Persetujuan Pemohon harus diisi'},
                }}
              />
            </Col>
          </FormGroup>
          <div className="row justify-content-end mb-3">
            <div className="col-9">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan'}
              </button>

            </div>
          </div>
        </> : null
      }
    </AvForm>
  )
}

export default StepTwo