import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { logoutUser, logoutUserSuccess } from "../../../store/actions"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"

import img_user from "../../Navbar/image/ic_profile.svg"
import { API_URL } from "helpers/api_url"

const ProfileMenu = props => {
  const tokenImpersonater = localStorage.getItem("tokenImpersonater")
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const handleChangeAkun = () => {
    localStorage.setItem("token", tokenImpersonater)
    localStorage.removeItem("tokenImpersonater")
    window.location.href = "/roles"
  }

  const renderRoles = roles => {
    let arrRoles = roles.map(item => {
      if (item.role.title === "Lender") return "Pemberi Pembiayaan"
      if (item.role.title === "Borrower") return "Penerima Pembiayaan"
      return null
    })

    return arrRoles
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={props.user.avatar ? API_URL + props.user.avatar : img_user}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">
            {props.user && props.user.first_name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle mr-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle mr-1"/>
            {props.t("My Wallet")}
          </DropdownItem> */}
          {tokenImpersonater && (
            <DropdownItem tag="a" onClick={handleChangeAkun}>
              {" "}
              <i className="bx bx-user font-size-16 align-middle mr-1" />
              {props.t("Admin")}{" "}
            </DropdownItem>
          )}
          <DropdownItem tag="a" onClick={() => props.logoutUser(props.history)}>
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            <span>{props.t("Keluar")}</span>
          </DropdownItem>
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle mr-1"/>
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider"/>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, user } = state.Login
  return { error, user }
}

export default withRouter(
  connect(mapStatetoProps, { logoutUser })(withTranslation()(ProfileMenu))
)
