import { getPortofolio, getPortofolioCampaign, getPortofolioDetail, getTransaction } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Input, InputGroup, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row, TabContent } from "reactstrap"
// import ListPortfolio from "./ListPortfolio"
import moment from "moment"
import classnames from "classnames"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { API_URL } from "helpers/api_url"
import Loading from "pages/Utility/Loading"
import { withRouter } from "react-router"
import ListTransaction from "./ListTransaction"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"
import axios from "axios"
import img_cara_bayar from "assets/images/ethis/Cara Transaksi Pendanaan Proyek Ethis.jpg"

const PortfolioTransaction = props => {

  const urlParams = new URLSearchParams(window.location.search)
  const pageTwo = Number(urlParams.get('page'))
  const perPageTwo = Number(urlParams.get('perPage')) || 5
  const uuid = urlParams.get('id')

  const [load, setLoad] = useState(false)
  const [transaction, setTransaction] = useState([])

  // const [perPageTwo, setPerPageTwo] = useState(5)
  // const [pageTwo, setPageTwo] = useState(1)
  const [totalPageTwo, setTotalPageTwo] = useState(0)
  const [currentPageTwo, setCurrentPageTwo] = useState(null)

  const setPageTwo = (page) => {
    props.history.push(`?id=${uuid}&&page=${page}&&perPage=${perPageTwo}`)
  }
  const setPerPageTwo = (perPage) => {
    props.history.push(`?id=${uuid}&&page=${1}&&perPage=${perPage}`)
  }

  useEffect(() => {
    getData()
  }, [pageTwo, perPageTwo])

  const getData = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPageTwo}&&page=${pageTwo}`
    setLoad(true)
    try {
      const trx = await getPortofolioDetail(uuid, token, query)
      setTotalPageTwo(trx.meta.pagination.last_page)
      setCurrentPageTwo(trx.meta.pagination.current_page)
      setTransaction(trx.data)
      setLoad(false)
    } catch (error) {
      console.log(error)
    }
  }

  const renderPaginateTwo = (numb) => {

    let maxPages = 5
    let current_page = currentPageTwo

    // ensure current page isn't out of range
    if (current_page < 1) {
      current_page = 1;
    } else if (current_page > totalPageTwo) {
      current_page = totalPageTwo;
    }

    let startPage
    let endPage

    if (totalPageTwo <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPageTwo;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
  
      if (current_page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
      } else if (current_page + maxPagesAfterCurrentPage >= totalPageTwo) {
          // current page near the end
          startPage = totalPageTwo - maxPages + 1;
          endPage = totalPageTwo;
      } else {
          // current page somewhere in the middle
          startPage = current_page - maxPagesBeforeCurrentPage;
          endPage = current_page + maxPagesAfterCurrentPage;
      }
    }

    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i)

    let result = []
    for(let i = 0; i <= pages.length - 1; i++) {
      result.push(
        <PaginationItem active={pages[i] === current_page}>
          <PaginationLink onClick={() => setPageTwo(pages[i])}>
            {pages[i]}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const downloadCaraBayar = () => {
    window.open(img_cara_bayar)
  }

  if(load) {
    return (
      <SkeletonLoad
        title="Transaksi"
        breadcrumbItem=""
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <div className="d-flex justify-content-end">
            <Skeleton width={100} />
          </div>
          <Skeleton height={32} />
          <Skeleton height={48} />
          <Skeleton height={48} />
          <Skeleton height={48} />
          <Skeleton height={48} />
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs className="pb-0 mb-3" title={`Transaksi`} breadcrumbItem={transaction.length > 0 ? transaction[0].campaign.title : ""} />
          <div className="d-flex justify-content-between align-items-center mt-2" >
            <InputGroup size="sm" style={{width: "6rem"}}>
              {/* <InputGroupAddon addonType="prepend">PerPage</InputGroupAddon> */}
                <Input type="select" value={perPageTwo} onChange={(e) => setPerPageTwo(e.target.value)}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Input>
            </InputGroup>
            {/* <Button color="primary" className="btn-sm" onClick={downloadCaraBayar}>Lihat Cara Pembayaran</Button> */}
            <Button className="btn-sm" onClick={() => {
              props.history.goBack()
            }}>
              <i className="bx bx-arrow-back"></i> Kembali
            </Button>
          </div>

          <ListTransaction 
            transactions={transaction}
          />
          {transaction.length === 0 &&
            <Row className="pt-3">
              <Col lg="12">
                <Card>
                  <CardBody className="text-center">
                    <h5><b>Belum Ada Pembiayaan</b></h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

          {transaction.length > 0 && 
            <Row className="pb-4 pt-4">
              <Col lg={12} className="d-flex justify-content-center">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={pageTwo === 1}>
                    <PaginationLink first onClick={() => setPageTwo(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={pageTwo === 1}>
                    <PaginationLink previous onClick={() => setPageTwo(pageTwo - 1)} />
                  </PaginationItem>
                  {renderPaginateTwo(totalPageTwo)}
                  <PaginationItem disabled={pageTwo === totalPageTwo}>
                    <PaginationLink next onClick={() => setPageTwo(pageTwo + 1)} />
                  </PaginationItem>
                  <PaginationItem disabled={pageTwo === totalPageTwo}>
                    <PaginationLink last onClick={() => setPageTwo(totalPageTwo)} />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PortfolioTransaction)
