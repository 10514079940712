import Toaster from "components/Common/Toast"
import {
  getAkadActive,
  getAkadAll,
  getAkadNonActive,
  getAkadPublish,
  getAkadReady,
  getAkadTitle,
  getAkadType,
  getAllRestructure,
  getBank,
  getBussinesEntity,
  getContract,
  getListTransactionAddendum,
  getListTransactionAddendumV2,
  postAkadMurabahah,
  postAkadMusyarakah,
  postAkadWakalah,
  postDocAddendum,
  postDocBilUjrah,
  updateRequestRestructure,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Button,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"

import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import NumberFormat from "react-number-format"
import classnames from "classnames"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import Loading from "pages/Utility/Loading"
import moment from "moment"
import { handleStatusCampaign } from "helpers/method_helpers"

const INIT_FORM = {
  pokok: "",
  totalProfitEndingProject: "",
  restructure_tenor: "",
  restructure_nisbah: "",
  restructure_outstanding: "",
  restructure_psr_pihak_pertama: "",
  restructure_psr_pihak_kedua: "",
  borrowerPaidOut: "",
  total_pelunasan: "",
  nisbahProjectToInvestor: "",
}

const Akad = props => {
  const [selectCampaign, setSelectCampaign] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalMurabahah, setModalMurabahah] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [activeTabs, setActiveTabs] = useState("1")
  const [master, setMaster] = useState({
    bank: [],
    akad_type: [],
    business_entity: [],
    akad_title: [],
    implementation_of_the_contract: [],
  })

  const [akad, setAkad] = useState([])
  const [akadReady, setAkadReady] = useState([])
  const [akadPublish, setAkadPublish] = useState([])
  const [akadActive, setAkadActive] = useState([])
  const [akadNonActive, setAkadNonActive] = useState([])

  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)

  const [buttId, setButtId] = useState("")

  const [modalAkad, setModalAkad] = useState(false)
  const [urlDocument, setUrlDocument] = useState("")
  const [akadName, setAkadName] = useState("")

  const [addendum, setAddendum] = useState([])

  const [modalRestructure, setModalRestructure] = useState(false)
  const [selectRestructure, setSelectRestructure] = useState(null)

  const [modalAddendum, setModalAddendum] = useState(false)
  const [listAkadAddendum, setListAkadAddendum] = useState([])

  const [form, setForm] = useState(INIT_FORM)

  const toggleModalAddendum = (item = null) => {
    setSelectRestructure(item)
    setModalAddendum(!modalAddendum)
  }

  useEffect(() => {
    if (selectRestructure) {
      setForm({
        totalProfitEndingProject: parseFloat(
          selectRestructure.totalProfitEndingProject
        ),
        pokok: parseFloat(selectRestructure.pokok),
        borrowerPaidOut: parseFloat(selectRestructure.borrowerPaidOut),
        restructure_nisbah: parseFloat(
          selectRestructure.nisbahAfterRestructure
        ),
        restructure_tenor: selectRestructure.tenor,
      })
    } else {
      setForm(INIT_FORM)
    }
  }, [selectRestructure])

  useEffect(() => {
    getDataAddendum()
  }, [modalAddendum, selectRestructure])

  const getDataAddendum = async () => {
    let token = localStorage.getItem("token")
    if (modalAddendum && selectRestructure) {
      setLoad(true)
      const data = await getListTransactionAddendumV2(
        selectRestructure.campaign?.uuid,
        selectRestructure.id,
        token,
        ""
      )
      setListAkadAddendum(data.data?.getListTransaction)
      setLoad(false)
    }
  }

  const handleStatusAkadAddendumLender = statusAkad => {
    if (statusAkad === 4) {
      return <Badge color="danger">Belum Dikirim</Badge>
    } else {
      if (statusAkad === 2) {
        return <Badge color="primary">Sudah TTD</Badge>
      } else {
        return <Badge color="warning">Belum TTD</Badge>
      }
    }
  }

  const handleStatusAkadAddendumBorrower = statusAkad => {
    if (statusAkad === 4) {
      return <Badge color="danger">Belum Dikirim</Badge>
    } else {
      if (statusAkad === 2) {
        return <Badge color="primary">Sudah TTD</Badge>
      } else {
        return <Badge color="warning">Belum TTD</Badge>
      }
    }
  }

  const handleSubmitAkadAddendum = async item => {
    if (item.url_document) {
      // window.open(item.akad_bil_ujrah[0].url_document, "_blank")
      setUrlDocument(item.url_document)
      setAkadName("Akad Addendum")
      toggleAkad()
    } else {
      const token = localStorage.getItem("token")
      setLoadButt(true)
      try {
        await postDocAddendum(
          selectRestructure.campaign?.uuid,
          item.trx_uuid,
          selectRestructure?.id,
          {
            documentTitle: `Akad_Musyarakah_Addendum_${selectRestructure.campaign?.title}_${item.full_name}`,
          },
          token
        )
        getDataAddendum()
        setLoadButt(false)
        Toaster(
          "success",
          "Sukses",
          "Akad Addendum berhasil dikirim ke lender dan borrower."
        )
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const dataTableAddendum = {
    columns: [
      {
        field: "trx_id",
        label: "Id Transaksi",
      },
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "email",
        label: "Email",
      },
      {
        field: "amount",
        label: "Jumlah Pembiayaan",
      },
      {
        field: "status",
        label: "Status Pembiayaan",
      },
      {
        field: "akadAddendum",
        label: "Akad Addendum",
      },
      {
        field: "status_akad_lender",
        label: "Status Akad Lender",
      },
      {
        field: "status_akad_borrower",
        label: "Status Akad Borrower",
      },
    ],
    rows: map(listAkadAddendum, item => ({
      ...item,
      name: `${item.full_name}`,
      email: item.email,
      amount: `Rp ${parseInt(item.amount).toLocaleString()}`,
      status: (
        <div>
          {item.status_pembiayaan === 1 && (
            <Badge color="danger">Belum Dibayar</Badge>
          )}
          {item.status_pembiayaan === 2 && (
            <Badge color="danger">Belum Dibayar</Badge>
          )}
          {item.status_pembiayaan === 3 && (
            <Badge color="warning">Pembayaran Bertahap</Badge>
          )}
          {item.status_pembiayaan === 4 && (
            <Badge color="success">Sudah Dibayar</Badge>
          )}
          {item.status_pembiayaan === 5 && (
            <Badge color="warning">Pengembalian Bertahap</Badge>
          )}
          {item.status_pembiayaan === 6 && (
            <Badge color="primary">Selesai Pengembalian Pokok</Badge>
          )}
          {item.status_pembiayaan === 7 && (
            <Badge color="primary">Selesai Pengembalian dan Nisbah</Badge>
          )}
          {/* {item.status === 7 && <Badge color="primary">Selesai Pengembalian</Badge>}
      {item.status === 8 && <Badge color="primary">Selesai Pengembalian</Badge>} */}
          {item.status_pembiayaan === 9 && (
            <Badge color="info">Kadaluarsa</Badge>
          )}
          {item.status_pembiayaan === 10 && (
            <Badge color="danger">Dikembalikan</Badge>
          )}
          {item.status_pembiayaan === 11 && (
            <Badge color="danger">Dibatalkan</Badge>
          )}
        </div>
      ),
      status_akad_lender: handleStatusAkadAddendumLender(item.lender_ttd),
      status_akad_borrower: handleStatusAkadAddendumBorrower(item.borrower_ttd),
      akadAddendum: (
        <Button
          type="button"
          color="primary"
          className="btn-sm"
          onClick={() => {
            setButtId(item.trx_id)
            handleSubmitAkadAddendum(item)
          }}
          disabled={loadButt}
        >
          {item.url_document ? (
            <>
              Lihat<i className="fas fa-eye ml-1"></i>
            </>
          ) : loadButt && buttId === item.trx_id ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              Loading
            </>
          ) : (
            <div className="d-flex align-items-center">
              Kirim <i className="bx bx-send ml-1"></i>
            </div>
          )}
        </Button>
      ),
    })),
  }

  const renderModalAddendum = () => {
    return (
      <Modal
        isOpen={modalAddendum}
        role="dialog"
        size="xl"
        autoFocus={true}
        scrollable
      >
        <ModalHeader toggle={() => toggleModalAddendum(null)}>
          Akad Addendum {selectRestructure?.campaign?.title || ""}
        </ModalHeader>
        <ModalBody>
          <MDBDataTable
            responsive
            bordered
            data={dataTableAddendum}
            striped
            className="mt-4"
          />
        </ModalBody>
      </Modal>
    )
  }

  const dataTableRestructure = {
    columns: [
      {
        field: "campaign_name",
        label: "Nama Kampanye",
      },
      {
        field: "akad_addendum_number",
        label: "Nomor Akad Addendum",
      },
      {
        field: "borrowerPaidOut",
        label: "Total Pembayaran yang telah dilakukan Borrower",
      },
      {
        field: "dueDateBeforeRestructure",
        label: "Due date sebelum Restruktur",
      },
      {
        field: "dueDateAfterRestructure",
        label: "Due date setelah Restruktur",
      },
      {
        field: "legitimationDate",
        label: "Tanggal Addendum",
      },
      {
        field: "nisbahBeforeRestructure",
        label: "ROI sebelum Restruktur",
      },
      {
        field: "nisbahAfterRestructure",
        label: "ROI setelah Restruktur",
      },
      {
        field: "tenor",
        label: "Penambahan Tenor",
      },
      {
        field: "restructure_tenor",
        label: "Total Tenor",
      },
      {
        field: "nisbahProjectToInvestor",
        label: "Proyeksi Total Nisbah Lender",
      },
      {
        field: "outstandingBeforeRestructure",
        label: "Outstanding sebelum Restruktur",
      },
      {
        field: "outstandingAfterRestructure",
        label: "Outstanding setelah Restruktur",
      },
      // {
      //   field: "persentaseNetProfit",
      //   label: "Persentase profit",
      // },
      {
        field: "pokok",
        label: "Pokok Pembiayaan",
      },
      {
        field: "psrPihakPertamaBeforeRestructure",
        label: "PSR pihak pertama sebelum Restruktur",
      },
      {
        field: "psrPihakPertamaAfterRestructure",
        label: "PSR pihak pertama setelah Restruktur",
      },
      {
        field: "psrPihakKeduaBeforeRestructure",
        label: "PSR pihak kedua sebelum Restruktur",
      },
      {
        field: "psrPihakKeduaAfterRestructure",
        label: "PSR pihak kedua setelah Restruktur",
      },
      {
        field: "status",
        label: "Status",
      },
      // {
      //   field: "totalNetProfit",
      //   label: "Total profit",
      // },
      {
        field: "totalProfitEndingProject",
        label: "Proyeksi Total Keuntungan Proyek",
      },
      {
        field: "total_pelunasan",
        label: "Total pelunasan",
      },
      {
        field: "files",
        label: "Berkas Permohonan Addendum",
      },
      {
        field: "creator",
        label: "Dibuat oleh",
      },
      {
        field: "updater",
        label: "Diupdate oleh",
      },
      {
        field: "action",
        label: "Ubah",
      },
      {
        field: "send_akad",
        label: "Kirim Akad Addendum",
      },
    ],
    rows: addendum.map(item => {
      return {
        ...item,
        tenor: item.tenor + " Bulan",
        restructure_tenor: item.campaign?.restructure_tenor + " Bulan",
        campaign_name: item.campaign?.title,
        legitimationDate: item.legitimationDate || "-",
        akad_addendum_number: item.akad_addendum_number || "-",
        borrowerPaidOut: `Rp ${Number(item.borrowerPaidOut).toLocaleString()}`,
        nisbahAfterRestructure: `${item.nisbahAfterRestructure} %`,
        nisbahBeforeRestructure: `${item.nisbahBeforeRestructure} %`,
        nisbahProjectToInvestor: `Rp ${Number(
          item.nisbahProjectToInvestor
        ).toLocaleString()}`,
        outstandingAfterRestructure: `Rp ${Number(
          item.outstandingAfterRestructure
        ).toLocaleString()}`,
        outstandingBeforeRestructure: `Rp ${Number(
          item.outstandingBeforeRestructure
        ).toLocaleString()}`,
        persentaseNetProfit: item.persentaseNetProfit
          ? `${item.persentaseNetProfit} %`
          : "-",
        pokok: `Rp ${Number(item.pokok).toLocaleString()}`,
        psrPihakKeduaAfterRestructure: `${item.psrPihakKeduaAfterRestructure} %`,
        psrPihakKeduaBeforeRestructure: `${item.psrPihakKeduaBeforeRestructure} %`,
        psrPihakPertamaAfterRestructure: `${item.psrPihakPertamaAfterRestructure} %`,
        psrPihakPertamaBeforeRestructure: `${item.psrPihakPertamaBeforeRestructure} %`,
        status: item.status ? "Sudah direview" : "Belum direview",
        totalNetProfit: `Rp ${Number(item.totalNetProfit).toLocaleString()}`,
        totalProfitEndingProject: `Rp ${Number(
          item.totalProfitEndingProject
        ).toLocaleString()}`,
        total_pelunasan: `Rp ${Number(item.total_pelunasan).toLocaleString()}`,
        creator: item.creator
          ? `${item.creator.first_name} ${item.creator.last_name}`
          : "-",
        files: item.files?.length
          ? item.files.map(el => {
              return (
                <Button
                  className="btn-sm mb-1 mr-1"
                  onClick={() => window.open(`${API_URL}${el.file}`)}
                >
                  <i className="fas fa-eye"></i>
                </Button>
              )
            })
          : "-",
        updater: item.updater
          ? `${item.updater.first_name} ${item.updater.last_name}`
          : "-",
        action: (
          <Button
            className="btn-sm"
            onClick={() => {
              toggleModalRestructure(item)
            }}
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
        send_akad: (
          <Button
            className="btn-sm"
            onClick={() => {
              toggleModalAddendum(item)
            }}
            disabled={item.status !== 1}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const toggleModalRestructure = (item = null) => {
    // if (!item) {
    //   setForm(INIT_FORM)
    // }
    setSelectRestructure(item)
    setModalRestructure(!modalRestructure)
  }

  const toggleAkad = () => setModalAkad(!modalAkad)

  useEffect(() => {
    getMaster()
  }, [])

  useEffect(() => {
    getAkads()
  }, [activeTabs])

  const getMaster = async () => {
    let token = localStorage.getItem("token")
    setLoad(true)
    try {
      const bank = await getBank(token)
      const akad_type = await getAkadType(token)
      const business_entity = await getBussinesEntity(token)
      const akad_title = await getAkadTitle(token)
      const implementation_of_the_contract = await getContract(token)
      setMaster({
        bank: bank.data,
        akad_type: akad_type.data,
        business_entity: business_entity.data,
        akad_title: akad_title.data,
        implementation_of_the_contract: implementation_of_the_contract.data,
      })
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const getAkads = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      if (activeTabs === "1") {
        const ready = await getAkadReady(token)
        setAkadReady(ready.data)
      } else if (activeTabs === "2") {
        const publish = await getAkadPublish(token)
        setAkadPublish(publish.data)
      } else if (activeTabs === "3") {
        const active = await getAkadActive(token)
        setAkadActive(active.data)
      } else if (activeTabs === "4") {
        const nonActive = await getAkadNonActive(token)
        setAkadNonActive(nonActive.data)
      } else if (activeTabs === "5") {
        const data = await getAkadAll(token)
        setAkad(data.data)
      } else {
        const addendumData = await getAllRestructure("", token)
        setAddendum(addendumData.data)
      }
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const toggleTabs = tab => {
    if (activeTabs !== tab) {
      setActiveTabs(tab)
    }
  }

  const renderImage = (file, link) => {
    if (!file && !link) return null
    if (file) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="upload"
          className="img-thumbnail"
        />
      )
    }
    return (
      <img src={`${API_URL}${link}`} alt="upload" className="img-thumbnail" />
    )
  }

  const toggle = campaign => {
    if (campaign) {
      setSelectCampaign(campaign)
      if (campaign?.akad_type?.title == "Murabahah") {
        setModalMurabahah(true)
      } else {
        setModal(true)
      }
    } else {
      setModal(false)
      setModalMurabahah(false)
    }
  }

  // const handleStatus = status => {
  //   if (status === 1) return <Badge color="danger">Kelola Kampanye</Badge>
  //   if (status === 2) return <Badge color="warning">Kelola Akad</Badge>
  //   if (status === 3) return <Badge color="info">Siap Terbit</Badge>
  //   if (status === 4) return <Badge color="success">Aktif</Badge>
  //   if (status === 5) return <Badge color="danger">Tidak Aktif</Badge>
  //   if (status === 6) return <Badge color="info">Target Terkumpul</Badge>
  //   if (status === 7) return <Badge color="info">Tidak Perform</Badge>
  //   if (status === 8) return <Badge color="info">Tidak Perform Max</Badge>
  //   if (status === 9) return <Badge color="info">Perform Max</Badge>
  //   if (status === 10)
  //     return <Badge color="info">Target Terkumpul & Tidak Aktif</Badge>
  //   if (status === 11) return <Badge color="secondary">Dibatalkan</Badge>
  // }

  const handleStatus = (isPaidOff, isHaveRepayments, status) => {
    return (
      <Badge
        color={handleStatusCampaign(
          "color",
          Boolean(isPaidOff),
          Boolean(isHaveRepayments),
          status
        )}
      >
        {handleStatusCampaign(
          "label",
          Boolean(isPaidOff),
          Boolean(isHaveRepayments),
          status
        )}
      </Badge>
    )
  }

  const handleSubmitBilUjrah = async item => {
    if (item.akad_bil_ujrah.length > 0) {
      // window.open(item.akad_bil_ujrah[0].url_document, "_blank")
      setUrlDocument(item.akad_bil_ujrah[0].url_document)
      setAkadName("Wakalah Bil Ujrah")
      toggleAkad()
    } else {
      const token = localStorage.getItem("token")
      setLoadButt(true)
      try {
        await postDocBilUjrah(
          item.uuid,
          { documentTitle: `Akad Wakalah Bil Ujrah_${item.title}` },
          token
        )
        getAkads()
        setLoadButt(false)
        Toaster("success", "Sukses", "Berhasil dikirim ke penerima pembiayaan")
      } catch (error) {
        setLoadButt(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const handleStatusAkadBilUjrah = akad => {
    if (akad.length === 0) {
      return <Badge color="danger">Belum Dikirim</Badge>
    } else {
      if (akad[0].status === 2) {
        return <Badge color="primary">Sudah TTD</Badge>
      } else {
        return <Badge color="warning">Belum TTD</Badge>
      }
    }
  }

  const dataNonActive = {
    columns: [
      {
        field: "title",
        label: "Nama Proyek",
        sort: "asc",
      },
      {
        field: "target",
        label: "Target",
        sort: "asc",
      },
      {
        field: "nisbah",
        label: "Nisbah",
        sort: "asc",
      },
      {
        field: "tenor",
        label: "Tenor",
        sort: "asc",
      },
      {
        field: "end_date_campaign",
        label: "Kampenye Berakhir",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "bilUjrah",
        label: "Wakalah Bil Ujrah",
      },
      {
        field: "status_akad",
        label: "Status Akad",
      },
      {
        field: "akadEdit",
        label: "Aksi",
      },
    ],
    rows: map(akadNonActive, item => ({
      ...item,
      target: `Rp ${parseInt(item.target).toLocaleString()}`,
      nisbah: `${item.nisbah} %`,
      tenor: `${item.tenor} Bulan`,
      end_date_campaign: moment(item.end_date_campaign).format("DD MMM YYYY"),
      status: handleStatus(item.isPaidOff, item.isHaveRepayments, item.status),
      akadEdit: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggle(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
      status_akad: handleStatusAkadBilUjrah(item.akad_bil_ujrah),
      bilUjrah: (
        <Button
          type="button"
          color="primary"
          className="btn-sm"
          onClick={() => {
            setButtId(item.uuid)
            handleSubmitBilUjrah(item)
          }}
          disabled={loadButt}
        >
          {item.akad_bil_ujrah.length > 0 ? (
            <>
              Lihat<i className="fas fa-eye ml-1"></i>
            </>
          ) : loadButt && buttId === item.uuid ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              Loading
            </>
          ) : (
            <div className="d-flex align-items-center">
              Kirim <i className="bx bx-send ml-1"></i>
            </div>
          )}
        </Button>
      ),
    })),
  }

  const dataActive = {
    columns: [
      {
        field: "title",
        label: "Nama Proyek",
        sort: "asc",
      },
      {
        field: "target",
        label: "Target",
        sort: "asc",
      },
      {
        field: "nisbah",
        label: "Nisbah",
        sort: "asc",
      },
      {
        field: "tenor",
        label: "Tenor",
        sort: "asc",
      },
      {
        field: "end_date_campaign",
        label: "Kampenye Berakhir",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "bilUjrah",
        label: "Wakalah Bil Ujrah",
      },
      {
        field: "status_akad",
        label: "Status Akad",
      },
      {
        field: "akadEdit",
        label: "Aksi",
      },
    ],
    rows: map(akadActive, item => ({
      ...item,
      target: `Rp ${parseInt(item.target).toLocaleString()}`,
      nisbah: `${item.nisbah} %`,
      tenor: `${item.tenor} Bulan`,
      end_date_campaign: moment(item.end_date_campaign).format("DD MMM YYYY"),
      status: handleStatus(item.isPaidOff, item.isHaveRepayments, item.status),
      akadEdit: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggle(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
      status_akad: handleStatusAkadBilUjrah(item.akad_bil_ujrah),
      bilUjrah: (
        <Button
          type="button"
          color="primary"
          className="btn-sm"
          onClick={() => {
            setButtId(item.uuid)
            handleSubmitBilUjrah(item)
          }}
          disabled={loadButt}
        >
          {item.akad_bil_ujrah.length > 0 ? (
            <>
              Lihat<i className="fas fa-eye ml-1"></i>
            </>
          ) : loadButt && buttId === item.uuid ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              Loading
            </>
          ) : (
            <div className="d-flex align-items-center">
              Kirim <i className="bx bx-send ml-1"></i>
            </div>
          )}
        </Button>
      ),
    })),
  }

  const renderModalAkad = () => {
    return (
      <Modal
        isOpen={modalAkad}
        // toggle={toggleAkad}
        scrollable={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Dokumen Akad {akadName}</h5>
          <button
            type="button"
            onClick={toggleAkad}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe
              title={akadName}
              className="embed-responsive-item"
              src={urlDocument}
            />
          </div>
        </div>
      </Modal>
    )
  }

  const dataPublish = {
    columns: [
      {
        field: "title",
        label: "Nama Proyek",
        sort: "asc",
      },
      {
        field: "akad_type",
        label: "Jenis Akad",
        sort: "asc",
      },
      {
        field: "target",
        label: "Target",
        sort: "asc",
      },
      {
        field: "nisbah",
        label: "Nisbah",
        sort: "asc",
      },
      {
        field: "tenor",
        label: "Tenor",
        sort: "asc",
      },
      {
        field: "end_date_campaign",
        label: "Kampenye Berakhir",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "bilUjrah",
        label: "Wakalah Bil Ujrah",
      },
      {
        field: "status_akad",
        label: "Status Akad",
      },
      {
        field: "akadEdit",
        label: "Aksi",
      },
    ],
    rows: map(akadPublish, item => ({
      ...item,
      akad_type: item.akad_type?.title,
      target: `Rp ${parseInt(item.target).toLocaleString()}`,
      nisbah: `${item.nisbah} %`,
      tenor: `${item.tenor} Bulan`,
      end_date_campaign: moment(item.end_date_campaign).format("DD MMM YYYY"),
      status: handleStatus(item.isPaidOff, item.isHaveRepayments, item.status),
      akadEdit: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggle(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
      status_akad: handleStatusAkadBilUjrah(item.akad_bil_ujrah),
      bilUjrah: (
        <Button
          type="button"
          color="primary"
          className="btn-sm"
          onClick={() => {
            setButtId(item.uuid)
            handleSubmitBilUjrah(item)
          }}
          disabled={loadButt}
        >
          {item.akad_bil_ujrah.length > 0 ? (
            <>
              Lihat<i className="fas fa-eye ml-1"></i>
            </>
          ) : loadButt && buttId === item.uuid ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              Loading
            </>
          ) : (
            <div className="d-flex align-items-center">
              Kirim <i className="bx bx-send ml-1"></i>
            </div>
          )}
        </Button>
      ),
    })),
  }

  const dataReady = {
    columns: [
      {
        field: "title",
        label: "Nama Proyek",
        sort: "asc",
      },
      {
        field: "akad_type",
        label: "Jenis Akad",
        sort: "asc",
      },
      {
        field: "target",
        label: "Target",
        sort: "asc",
      },
      {
        field: "nisbah",
        label: "Nisbah",
        sort: "asc",
      },
      {
        field: "tenor",
        label: "Tenor",
        sort: "asc",
      },
      {
        field: "end_date_campaign",
        label: "Kampenye Berakhir",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "akadEdit",
        label: "Aksi",
      },
    ],
    rows: map(akadReady, item => ({
      ...item,
      akad_type: item.akad_type?.title,
      target: `Rp ${parseInt(item.target).toLocaleString()}`,
      nisbah: `${item.nisbah} %`,
      tenor: `${item.tenor} Bulan`,
      end_date_campaign: moment(item.end_date_campaign).format("DD MMM YYYY"),
      status: handleStatus(item.isPaidOff, item.isHaveRepayments, item.status),
      akadEdit: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggle(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
    })),
  }

  const data = {
    columns: [
      {
        field: "title",
        label: "Nama Proyek",
        sort: "asc",
      },
      {
        field: "target",
        label: "Target",
        sort: "asc",
      },
      {
        field: "nisbah",
        label: "Nisbah",
        sort: "asc",
      },
      {
        field: "tenor",
        label: "Tenor",
        sort: "asc",
      },
      {
        field: "end_date_campaign",
        label: "Kampenye Berakhir",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "bilUjrah",
        label: "Wakalah Bil Ujrah",
      },
      {
        field: "status_akad",
        label: "Status Akad",
      },
      {
        field: "akadEdit",
        label: "Aksi",
      },
    ],
    rows: map(akad, item => ({
      ...item,
      target: `Rp ${parseInt(item.target).toLocaleString()}`,
      nisbah: `${item.nisbah} %`,
      tenor: `${item.tenor} Bulan`,
      end_date_campaign: moment(item.end_date_campaign).format("DD MMM YYYY"),
      status: handleStatus(item.isPaidOff, item.isHaveRepayments, item.status),
      akadEdit: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggle(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
      status_akad: handleStatusAkadBilUjrah(item.akad_bil_ujrah),
      bilUjrah: (
        <Button
          type="button"
          color="primary"
          className="btn-sm"
          onClick={() => {
            setButtId(item.uuid)
            handleSubmitBilUjrah(item)
          }}
          disabled={loadButt}
        >
          {item.akad_bil_ujrah.length > 0 ? (
            <>
              Lihat<i className="fas fa-eye ml-1"></i>
            </>
          ) : loadButt && buttId === item.uuid ? (
            <>
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
              Loading
            </>
          ) : (
            <div className="d-flex align-items-center">
              Kirim <i className="bx bx-send ml-1"></i>
            </div>
          )}
        </Button>
      ),
    })),
  }

  const dataAddendum = {
    columns: [
      {
        field: "title",
        label: "Nama Proyek",
        sort: "asc",
      },
      {
        field: "target",
        label: "Target",
        sort: "asc",
      },
      {
        field: "nisbah",
        label: "Nisbah",
        sort: "asc",
      },
      {
        field: "tenor",
        label: "Tenor",
        sort: "asc",
      },
      {
        field: "end_date_campaign",
        label: "Kampenye Berakhir",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "akadEdit",
        label: "Aksi",
      },
    ],
    rows: map(addendum, item => ({
      ...item,
      title: item.campaign?.title,
      target: `Rp ${parseInt(item.campaign?.target).toLocaleString()}`,
      nisbah: `${item.campaign?.nisbah} %`,
      tenor: `${item.campaign?.tenor} Bulan`,
      end_date_campaign: moment(item.campaign?.end_date_campaign).format(
        "DD MMM YYYY"
      ),
      // status: handleStatus(item.campaign?.status),
      status: handleStatus(
        item.isPaidOff,
        item.isHaveRepayments,
        item.campaign?.status
      ),
      akadEdit: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggleModalRestructure(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
    })),
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleSubmitMusyarakah = async (e, value) => {
    let newValue = {
      ...value,
      pengesahan_number: "00",
      pengesahan_date: "2021-01-01",
      net_profit_borrower: "0",
      net_profit_lender: "0",
      ttd_borrower: null,
    }
    // if(ttd) {
    //   newValue.ttd_borrower = ttd
    // }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postAkadMusyarakah(
        selectCampaign.akad_musyarakah[0].uuid,
        formData,
        token
      )
      getAkads()
      toggleTab("2")
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasi tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleSubmitWakalah = async (e, value) => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postAkadWakalah(selectCampaign.akad_wakalah[0].uuid, value, token)
      getAkads()
      toggle(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasi tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleSubmitRestructure = async (e, value) => {
    // delete value.files
    const token = localStorage.getItem("token")
    const newValue = {
      ...form,
      akad_addendum_number: value.akad_addendum_number,
      legitimationDate: value.legitimationDate,
      nisbahProjectToInvestor:
        parseFloat(form.pokok) * (parseFloat(form.restructure_nisbah) / 100),
      restructure_outstanding:
        parseFloat(form.pokok) +
        parseFloat(form.pokok) * (parseFloat(form.restructure_nisbah) / 100),
      restructure_psr_pihak_kedua:
        ((parseFloat(form.pokok) *
          (parseFloat(form.restructure_nisbah) / 100)) /
          parseFloat(form.totalProfitEndingProject)) *
        100,
      restructure_psr_pihak_pertama:
        100 -
        ((parseFloat(form.pokok) *
          (parseFloat(form.restructure_nisbah) / 100)) /
          parseFloat(form.totalProfitEndingProject)) *
          100,
      total_pelunasan:
        parseFloat(form.pokok) +
        parseFloat(form.pokok) * (parseFloat(form.restructure_nisbah) / 100) -
        parseFloat(form.borrowerPaidOut),
    }

    if (!selectRestructure?.psrPihakKeduaBeforeRestructure) {
      newValue.old_psr_pihak_kedua = value.old_psr_pihak_kedua
    }
    if (!selectRestructure?.psrPihakPertamaBeforeRestructure) {
      newValue.old_psr_pihak_pertama = value.old_psr_pihak_pertama
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await updateRequestRestructure(selectRestructure.id, formData, token)
      toggleModalRestructure(null)
      setLoadButt(false)
      getAkads()
      Toaster("success", "Sukses", "Berhasil ubah akad addendum")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalRestructure = () => {
    return (
      <Modal
        isOpen={modalRestructure}
        role="dialog"
        autoFocus={true}
        size="lg"
        scrollable
      >
        <ModalHeader toggle={() => toggleModalRestructure(null)}>
          Restrukturisasi
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitRestructure(e, v)
            }}
          >
            <Row>
              <Col lg="6">
                <PInput
                  name="akad_addendum_number"
                  type="text"
                  label="Nomor Akad Addendum"
                  value={
                    selectRestructure
                      ? selectRestructure.akad_addendum_number
                      : ""
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Nomor Akad Addendum harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <PInput
                  name="legitimationDate"
                  type="date"
                  label="Tanggal Addendum"
                  value={
                    selectRestructure ? selectRestructure.legitimationDate : ""
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Tanggal Addendum harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="pokok"
                  label="Pokok Pembiayaan"
                  helpMessage="Rupiah"
                  value={form.pokok}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      pokok: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Pokok harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="totalProfitEndingProject"
                  label="Proyeksi Total Keuntungan Proyek"
                  helpMessage="Rupiah"
                  value={form.totalProfitEndingProject}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      totalProfitEndingProject: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Total Profit harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" Bulan"}
                  name="restructure_tenor"
                  label="Penambahan Tenor"
                  helpMessage="Bulan"
                  value={form.restructure_tenor}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_tenor: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Tenor harus diisi",
                    },
                  }}
                />
              </Col>
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" %"}
                  name="restructure_nisbah"
                  label="ROI"
                  value={form.restructure_nisbah}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_nisbah: e.value,
                    })
                  }
                  helpMessage="Persen"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Nisbah harus diisi",
                    },
                  }}
                />
              </Col>
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="restructure_outstanding"
                  label="Outstanding"
                  value={form.restructure_outstanding}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_outstanding: e.value,
                    })
                  }
                  helpMessage="Rupiah"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Outstanding harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" %"}
                  name="restructure_psr_pihak_pertama"
                  label="PSR Pihak Pertama"
                  helpMessage="Persen"
                  value={
                    form.restructure_psr_pihak_kedua &&
                    parseFloat(form.restructure_psr_pihak_kedua) <= 100
                      ? 100 - parseFloat(form.restructure_psr_pihak_kedua)
                      : ""
                  }
                  disabled
                  className="disabled-input-custom"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "PSR Pihak Pertama harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  suffix={" %"}
                  name="restructure_psr_pihak_kedua"
                  label="PSR Pihak Kedua"
                  helpMessage="Persen"
                  value={form.restructure_psr_pihak_kedua}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      restructure_psr_pihak_kedua: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "PSR Pihak Kedua harus diisi",
                    },
                  }}
                />
              </Col> */}
              <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="borrowerPaidOut"
                  label="Total Yang sudah dibayarkan Borrower"
                  helpMessage="Rupiah"
                  value={form.borrowerPaidOut}
                  onValueChange={e =>
                    setForm({
                      ...form,
                      borrowerPaidOut: e.value,
                    })
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage:
                        "Total Yang sudah dibayarkan Borrower harus diisi",
                    },
                  }}
                />
              </Col>
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="total_pelunasan"
                  label="Total Pelunasan"
                  helpMessage="Rupiah"
                  disabled
                  className="disabled-input-custom"
                  value={form.total_pelunasan}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Total Pelunasan harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <NumberFormat
                  customInput={PInput}
                  thousandSeparator={true}
                  prefix={"Rp "}
                  name="nisbahProjectToInvestor"
                  label="Proyeksi Imbal Hasil Ke Lender Keseluruhan"
                  helpMessage="Rupiah"
                  disabled
                  className="disabled-input-custom"
                  value={form.nisbahProjectToInvestor}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Nisbah Lender harus diisi",
                    },
                  }}
                />
              </Col> */}
              {/* <Col lg="6">
                <PInput
                  name="files"
                  type="file"
                  label="Unggah Berkas"
                  helpMessage="Berkas bisa lebih dari 1 (pdf.)"
                  onChange={e => setFilesRestructure(e.target.files)}
                  validate={{
                    required: {
                      value: selectRestructure ? false : true,
                      errorMessage: "Berkas harus diisi",
                    },
                  }}
                  value={
                    filesRestructure?.length
                      ? { name: filesRestructure.length + " Berkas" }
                      : null
                  }
                  multiple
                />
              </Col> */}
              {!selectRestructure?.psrPihakPertamaBeforeRestructure && (
                <Col lg="6">
                  <PInput
                    name="old_psr_pihak_pertama"
                    type="number"
                    label="PSR pihak pertama sebelumnya"
                    helpMessage="Persen"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "field harus diisi",
                      },
                    }}
                  />
                </Col>
              )}

              {!selectRestructure?.psrPihakKeduaBeforeRestructure && (
                <Col lg="6">
                  <PInput
                    name="old_psr_pihak_kedua"
                    type="number"
                    label="PSR pihak Kedua sebelumnya"
                    helpMessage="Persen"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "field harus diisi",
                      },
                    }}
                  />
                </Col>
              )}
            </Row>

            <div>
              <table class="table table-striped border">
                <thead>
                  <tr>
                    <th scope="col">Deskripsi</th>
                    <th scope="col">Restruktur</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pokok Pembiayaan</td>
                    <td>
                      Rp{" "}
                      {form.pokok
                        ? `${parseFloat(form.pokok).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Proyeksi Total Keuntungan Proyek</td>
                    <td>
                      Rp{" "}
                      {form.totalProfitEndingProject
                        ? `${parseFloat(
                            form.totalProfitEndingProject
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Penambahan Tenor</td>
                    <td>
                      {form.restructure_tenor
                        ? `${parseFloat(
                            form.restructure_tenor
                          ).toLocaleString()} Bulan`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>ROI</td>
                    <td>
                      {form.restructure_nisbah
                        ? `${parseFloat(form.restructure_nisbah)} %`
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <td>PSR Pihak Pertama (Borrower)</td>
                    <td>
                      {form.pokok &&
                      form.restructure_nisbah &&
                      form.totalProfitEndingProject
                        ? `${
                            100 -
                            ((parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100)) /
                              parseFloat(form.totalProfitEndingProject)) *
                              100
                          } %`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>PSR Pihak Kedua (Lender)</td>
                    <td>
                      {form.pokok &&
                      form.restructure_nisbah &&
                      form.totalProfitEndingProject
                        ? `${
                            ((parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100)) /
                              parseFloat(form.totalProfitEndingProject)) *
                            100
                          } %`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Proyeksi Total Nisbah ke Lender</td>
                    <td>
                      Rp{" "}
                      {form.pokok && form.restructure_nisbah
                        ? `${(
                            parseFloat(form.pokok) *
                            (parseFloat(form.restructure_nisbah) / 100)
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Outstanding Baru</td>
                    <td>
                      Rp{" "}
                      {form.pokok && form.restructure_nisbah
                        ? `${(
                            parseFloat(form.pokok) +
                            parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100)
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Pembayaran yang telah dilakukan</td>
                    <td>
                      Rp{" "}
                      {form.borrowerPaidOut
                        ? `${parseFloat(form.borrowerPaidOut).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Pelunasan</td>
                    <td>
                      Rp{" "}
                      {form.pokok &&
                      form.restructure_nisbah &&
                      form.borrowerPaidOut
                        ? `${(
                            parseFloat(form.pokok) +
                            parseFloat(form.pokok) *
                              (parseFloat(form.restructure_nisbah) / 100) -
                            parseFloat(form.borrowerPaidOut)
                          ).toLocaleString()}`
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const renderModalFormMusyarakah = () => {
    return (
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        size="lg"
        scrollable={true}
      >
        <ModalHeader toggle={() => toggle(null)}>
          Akad {selectCampaign && selectCampaign.title}
        </ModalHeader>
        <ModalBody>
          <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                Musyarakah & Wakalah Bil Ujrah
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Wakalah
              </NavLink>
            </NavItem>
          </ul>
          {selectCampaign &&
          selectCampaign.akad_musyarakah.length > 0 &&
          selectCampaign.akad_wakalah.length > 0 ? (
            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1" id="all-order">
                <div>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmitMusyarakah(e, v)
                    }}
                  >
                    <Row>
                      <Col lg="6">
                        <PInput
                          name="akad_date"
                          label="Tanggal Akad"
                          type="date"
                          value={selectCampaign.akad_musyarakah[0].akad_date}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Tanggal Akad harus diisi",
                            },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_number"
                          label="Nomor Akad"
                          type="text"
                          value={selectCampaign.akad_musyarakah[0].akad_number}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="place_of_akad"
                          label="Tempat Akad"
                          type="text"
                          value={
                            selectCampaign.akad_musyarakah[0].place_of_akad
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="director_name"
                          label="Nama Direktur Penerima Pinjaman"
                          type="text"
                          value={
                            selectCampaign.akad_musyarakah[0].director_name
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="deed_of_incorporation"
                          label="Akta pendirian"
                          type="text"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .deed_of_incorporation
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="business_entity_id"
                          label="Perusahaan"
                          type="select"
                          value={
                            selectCampaign.akad_musyarakah[0].business_entity
                              ? selectCampaign.akad_musyarakah[0]
                                  .business_entity.uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.business_entity}
                          // disabled
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="notaris_name"
                          label="Nama Notaris Penerima Pinjaman"
                          type="text"
                          value={selectCampaign.akad_musyarakah[0].notaris_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="create_notaris_date"
                          label="Tanggal Pembuatan Notaris"
                          type="date"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .create_notaris_date
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="company_address"
                          label="Alamat Perusahaan"
                          type="textarea"
                          value={
                            selectCampaign.akad_musyarakah[0].company_address
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6"></Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_indonesia"
                          label="Tujuan Pendanaan (ID)"
                          type="textarea"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .funding_objectives_indonesia
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_en"
                          label="Tujuan Pendanaan (EN)"
                          type="textarea"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .funding_objectives_en
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="bank_id"
                          label="Bank Pengembalian"
                          type="select"
                          value={
                            selectCampaign.akad_musyarakah[0].bank
                              ? selectCampaign.akad_musyarakah[0].bank.uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.bank}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="bank_account_refund"
                          label="Rekening Bank Pengembalian"
                          type="text"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .bank_account_refund
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      {/* <Col lg="6">
                      <PInput
                        name="pengesahan_number"
                        label="Nomor Pengesahaan"
                        type="text"
                        value={selectCampaign.akad_musyarakah[0].pengesahan_number}
                        validate={{
                          required: {value: true},
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <PInput
                        name="pengesahan_date"
                        label="Tanggal Pengesahan"
                        type="date"
                        value={selectCampaign.akad_musyarakah[0].pengesahan_date}
                        validate={{
                          required: {value: true},
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <PInput
                        name="net_profit_borrower"
                        label="Keuntungan Bersih Borrower"
                        type="number"
                        value={selectCampaign.akad_musyarakah[0].net_profit_borrower}
                        validate={{
                          required: {value: true},
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <PInput
                        name="net_profit_lender"
                        label="Keuntungan Bersih Lender"
                        type="number"
                        value={selectCampaign.akad_musyarakah[0].net_profit_lender}
                        validate={{
                          required: {value: true},
                        }}
                      />
                    </Col> */}
                      <Col lg="6">
                        <PInput
                          name="cancellation_fee"
                          label="Biaya Pembatalan"
                          type="number"
                          defaultValue="7.00"
                          value={
                            selectCampaign.akad_musyarakah[0].cancellation_fee
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Persen"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_title_id"
                          label="Panggilan Direktur"
                          type="select"
                          value={
                            selectCampaign.akad_musyarakah[0].akad_title
                              ? selectCampaign.akad_musyarakah[0].akad_title
                                  .uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.akad_title}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="ethis_contribute"
                          label="Kontribusi Ethis"
                          type="text"
                          value={
                            parseInt(
                              selectCampaign?.akad_musyarakah?.[0]
                                ?.ethis_contribute
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="project_contribute"
                          label="Kontribusi Proyek"
                          type="number"
                          value={
                            parseInt(
                              selectCampaign?.akad_musyarakah?.[0]
                                ?.project_contribute
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="sk_date_kemenkunham"
                          label="Tanggal SK Kemenkumham"
                          type="date"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .sk_date_kemenkunham
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="sk_number_kemenkunham"
                          label="Nomor SK Kemenkumham"
                          type="text"
                          value={
                            selectCampaign.akad_musyarakah[0]
                              .sk_number_kemenkunham
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="psr_pihak_pertama"
                          label="PSR Pihak Pertama"
                          type="number"
                          value={
                            selectCampaign.akad_musyarakah[0].psr_pihak_pertama
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Persen"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="psr_pihak_kedua"
                          label="PSR Pihak Kedua"
                          type="number"
                          value={
                            selectCampaign.akad_musyarakah[0].psr_pihak_kedua
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Persen"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="dana_pembiayaan_total"
                          label="Dana Pembiayaan Total"
                          type="number"
                          value={
                            parseInt(
                              selectCampaign?.akad_musyarakah?.[0]
                                ?.dana_pembiayaan_total
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="platform_fee_ethis"
                          label="Platform Fee Ethis"
                          type="number"
                          value={
                            selectCampaign.akad_musyarakah[0].platform_fee_ethis
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Persen"
                        />
                      </Col>
                      {/* <Col lg="6">
                      <PInput
                        name="ttd_borrower"
                        label="TTD Borrower"
                        type="file"
                        onChange={(e) => setTtd(e.target.files[0])}
                        validate={{
                          required: {value: false},
                        }}
                      />
                      {renderImage(ttd, selectCampaign.akad_musyarakah[0].ttd_borrower)}
                    </Col> */}
                    </Row>
                    <div className="row justify-content-end mb-1 mt-3">
                      <div className="col-12">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loadButt}
                        >
                          {loadButt ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              Loading
                            </>
                          ) : (
                            "Simpan"
                          )}
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </TabPane>
              <TabPane tabId="2" id="processing">
                <div>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmitWakalah(e, v)
                    }}
                  >
                    <Row>
                      <Col lg="6">
                        <PInput
                          name="akad_number"
                          label="Nomor Akad"
                          type="text"
                          value={selectCampaign.akad_wakalah[0].akad_number}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_date"
                          label="Tanggal Akad"
                          type="date"
                          value={selectCampaign.akad_wakalah[0].akad_date}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="place_of_akad"
                          label="Tempat Akad"
                          type="text"
                          defaultValue="DKI Jakarta"
                          value={selectCampaign.akad_wakalah[0].place_of_akad}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="director_name"
                          label="Nama Direktur"
                          type="text"
                          defaultValue="Ronald Wijaya"
                          value={selectCampaign.akad_wakalah[0].director_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="business_entity_id"
                          label="Perusahaan"
                          type="select"
                          defaultValue={
                            master?.business_entity?.find(
                              item => item?.title == "PT"
                            )?.uuid
                          }
                          value={
                            selectCampaign.akad_wakalah[0].business_entity
                              ? selectCampaign.akad_wakalah[0].business_entity
                                  .uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.business_entity}
                          // disabled
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="deed_of_incorporation"
                          label="Akta Pendirian"
                          type="text"
                          defaultValue="02"
                          value={
                            selectCampaign.akad_wakalah[0].deed_of_incorporation
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="notaris_name"
                          label="Nama Notaris"
                          type="text"
                          defaultValue="Risbert, S.H., M.H"
                          value={selectCampaign.akad_wakalah[0].notaris_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="create_notaris_date"
                          label="Tanggal Pembuatan Notaris"
                          type="date"
                          defaultValue="2018-07-02"
                          value={
                            selectCampaign.akad_wakalah[0].create_notaris_date
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="company_address"
                          label="Alamat Perusahaan"
                          type="textarea"
                          defaultValue="Rukan Puri Mansion Blok B No. 7, Jl. Lingkar Luar Barat Raya, Kel. Kembangan Selatan, Kec. Kembangan, Kota Adm. Jakarta Barat, DKI Jakarta, 11610"
                          value={selectCampaign.akad_wakalah[0].company_address}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6"></Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_indonesia"
                          label="Tujuan Pendanaan (ID)"
                          type="textarea"
                          value={
                            selectCampaign.akad_wakalah[0]
                              .funding_objectives_indonesia
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_en"
                          label="Tujuan Pendanaan (EN)"
                          type="textarea"
                          value={
                            selectCampaign.akad_wakalah[0].funding_objectives_en
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="implementation_of_the_contract_id"
                          label="Kontrak"
                          type="select"
                          value={
                            selectCampaign.akad_wakalah[0]
                              .implementation_contract
                              ? selectCampaign.akad_wakalah[0]
                                  .implementation_contract.uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.implementation_of_the_contract}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="pengesahan_number"
                          label="Nomor Pengesahan"
                          type="text"
                          defaultValue="AHU-0088780.AH.01.11.Tahun 2018"
                          value={
                            selectCampaign.akad_wakalah[0].pengesahan_number
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="pengesahan_date"
                          label="Tanggal Pengesahan"
                          type="date"
                          defaultValue="2018-07-11"
                          value={selectCampaign.akad_wakalah[0].pengesahan_date}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="time_period_akad"
                          label="Waktu Periode Akad"
                          helpMessage="Dalam bulan"
                          type="number"
                          value={
                            selectCampaign.akad_wakalah[0].time_period_akad
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="due_date_akad"
                          label="Tanggal Berakhir Akad"
                          type="date"
                          value={selectCampaign.akad_wakalah[0].due_date_akad}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_code"
                          label="Kode Akad"
                          type="text"
                          value={selectCampaign.akad_wakalah[0].akad_code}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="row justify-content-end mb-1 mt-3">
                      <div className="col-12">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loadButt}
                        >
                          {loadButt ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              Loading
                            </>
                          ) : (
                            "Simpan"
                          )}
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </TabPane>
            </TabContent>
          ) : null}
        </ModalBody>
      </Modal>
    )
  }

  const handleSubmitMurabahah = async (e, value) => {
    // return console.log(value)
    const formData = new FormData()
    Object.keys(value).map(item => {
      formData.append(item, value[item])
    })

    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postAkadMurabahah(
        selectCampaign.akad_murabahah[0].uuid,
        formData,
        token
      )
      getAkads()
      toggleTab("2")
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasi tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message)
    }
  }

  const renderModalFormMurabahah = () => {
    return (
      <Modal
        isOpen={modalMurabahah}
        role="dialog"
        autoFocus={true}
        size="lg"
        scrollable={true}
      >
        <ModalHeader toggle={() => toggle(null)}>
          Akad {selectCampaign && selectCampaign.title}
        </ModalHeader>
        <ModalBody>
          <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                Murabahah & Wakalah Bil Ujrah
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Wakalah
              </NavLink>
            </NavItem>
          </ul>
          {selectCampaign &&
          selectCampaign.akad_murabahah?.length > 0 &&
          selectCampaign.akad_wakalah?.length > 0 ? (
            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1" id="all-order">
                <div>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmitMurabahah(e, v)
                    }}
                  >
                    <Row>
                      <Col lg="6">
                        <PInput
                          name="akad_date"
                          label="Tanggal Akad"
                          type="date"
                          value={selectCampaign.akad_murabahah[0].akad_date}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_number"
                          label="Nomor Akad"
                          type="text"
                          value={selectCampaign.akad_murabahah[0].akad_number}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="place_of_akad"
                          label="Tempat Akad"
                          type="text"
                          defaultValue="Jakarta"
                          value={selectCampaign.akad_murabahah[0].place_of_akad}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="director_name"
                          label="Nama Direktur Penerima Pinjaman"
                          type="text"
                          value={selectCampaign.akad_murabahah[0].director_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="deed_of_incorporation"
                          label="Akta pendirian"
                          type="text"
                          value={
                            selectCampaign.akad_murabahah[0]
                              .deed_of_incorporation
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="deed_of_incorporation_date"
                          label="Tanggal Akta pendirian"
                          type="date"
                          value={
                            selectCampaign.akad_murabahah[0]
                              .deed_of_incorporation_date
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="business_entity_id"
                          label="Badan Hukum"
                          type="select"
                          value={
                            selectCampaign.akad_murabahah[0].business_entity
                              ? selectCampaign.akad_murabahah[0].business_entity
                                  .uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.business_entity}
                          // disabled
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="notaris_name"
                          label="Nama Notaris Penerima Pinjaman"
                          type="text"
                          value={selectCampaign.akad_murabahah[0].notaris_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="create_notaris_date"
                          label="Tanggal Pembuatan Notaris"
                          type="date"
                          value={
                            selectCampaign.akad_murabahah[0].create_notaris_date
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="company_address"
                          label="Alamat Perusahaan"
                          type="text"
                          value={
                            selectCampaign.akad_murabahah[0].company_address
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_indonesia"
                          label="Tujuan Pendanaan (ID)"
                          type="textarea"
                          value={
                            selectCampaign.akad_murabahah[0]
                              .funding_objectives_indonesia
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_en"
                          label="Tujuan Pendanaan (EN)"
                          type="textarea"
                          value={
                            selectCampaign.akad_murabahah[0]
                              .funding_objectives_en
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="bank_id"
                          label="Bank Pengembalian"
                          type="select"
                          value={
                            selectCampaign.akad_murabahah[0].bank
                              ? selectCampaign.akad_murabahah[0].bank.uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.bank}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="bank_account_refund"
                          label="Rekening Bank Pengembalian"
                          type="text"
                          value={
                            selectCampaign.akad_murabahah[0].bank_account_refund
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="cancellation_fee"
                          label="Biaya Pembatalan"
                          type="number"
                          defaultValue="7.00"
                          value={
                            selectCampaign.akad_murabahah[0].cancellation_fee
                          }
                          validate={{
                            required: { value: true },
                          }}
                          disabled
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_title_id"
                          label="Panggilan Direktur"
                          type="select"
                          value={
                            selectCampaign.akad_murabahah[0].akad_title
                              ? selectCampaign.akad_murabahah[0].akad_title.uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.akad_title}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="ethis_contribute"
                          label="Kontribusi Ethis"
                          type="number"
                          value={
                            parseInt(
                              selectCampaign.akad_murabahah[0].ethis_contribute
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Rupiah"
                        />
                      </Col>
                      {/* <Col lg="6">
                        <PInput
                          name="project_contribute"
                          label="Kontribusi Proyek"
                          type="number"
                          value={
                            parseInt(
                              selectCampaign.akad_murabahah[0]
                                .project_contribute
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Rupiah"
                        />
                      </Col> */}
                      <Col lg="6">
                        <PInput
                          name="sk_date_kemenkumham"
                          label="Tanggal SK Kemenkumham"
                          type="date"
                          value={
                            selectCampaign.akad_murabahah[0].sk_date_kemenkumham
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="sk_number_kemenkumham"
                          label="Nomor SK Kemenkumham"
                          type="text"
                          value={
                            selectCampaign.akad_murabahah[0]
                              .sk_number_kemenkumham
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col lg="6">
                        <PInput
                          name="dana_pembiayaan_total"
                          label="Dana Pembiayaan Total"
                          type="number"
                          value={
                            parseInt(
                              selectCampaign.akad_murabahah[0]
                                .dana_pembiayaan_total
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Rupiah"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="platform_fee_ethis"
                          label="Platform Fee Ethis"
                          type="number"
                          value={
                            selectCampaign.akad_murabahah[0].platform_fee_ethis
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Persen"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="margin"
                          label="Margin"
                          type="number"
                          value={
                            parseInt(selectCampaign.akad_murabahah[0].margin) ||
                            ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Rupiah"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="purchase_price"
                          label="Harga Beli"
                          type="number"
                          value={
                            parseInt(
                              selectCampaign.akad_murabahah[0].purchase_price
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Rupiah"
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="selling_price"
                          label="Harga Jual"
                          type="text"
                          value={
                            parseInt(
                              selectCampaign.akad_murabahah[0].selling_price
                            ) || ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          helpMessage="Dalam Rupiah"
                        />
                      </Col>
                    </Row>
                    <div className="row justify-content-end mb-1 mt-3">
                      <div className="col-12">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loadButt}
                        >
                          {loadButt ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              Loading
                            </>
                          ) : (
                            "Simpan"
                          )}
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </TabPane>
              <TabPane tabId="2" id="processing">
                <div>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleSubmitWakalah(e, v)
                    }}
                  >
                    <Row>
                      <Col lg="6">
                        <PInput
                          name="akad_number"
                          label="Nomor Akad"
                          type="text"
                          value={selectCampaign.akad_wakalah[0].akad_number}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_date"
                          label="Tanggal Akad"
                          type="date"
                          value={selectCampaign.akad_wakalah[0].akad_date}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="place_of_akad"
                          label="Tempat Akad"
                          type="text"
                          defaultValue="DKI Jakarta"
                          value={selectCampaign.akad_wakalah[0].place_of_akad}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="director_name"
                          label="Nama Direktur"
                          type="text"
                          defaultValue="Ronald Wijaya"
                          value={selectCampaign.akad_wakalah[0].director_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="business_entity_id"
                          label="Perusahaan"
                          type="select"
                          defaultValue={
                            master?.business_entity?.find(
                              item => item?.title == "PT"
                            )?.uuid
                          }
                          value={
                            selectCampaign.akad_wakalah[0].business_entity
                              ? selectCampaign.akad_wakalah[0].business_entity
                                  .uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.business_entity}
                          // disabled
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="deed_of_incorporation"
                          label="Akta Pendirian"
                          type="text"
                          defaultValue="02"
                          value={
                            selectCampaign.akad_wakalah[0].deed_of_incorporation
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="notaris_name"
                          label="Nama Notaris"
                          type="text"
                          defaultValue="Risbert, S.H., M.H"
                          value={selectCampaign.akad_wakalah[0].notaris_name}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="create_notaris_date"
                          label="Tanggal Pembuatan Notaris"
                          type="date"
                          defaultValue="2018-07-02"
                          value={
                            selectCampaign.akad_wakalah[0].create_notaris_date
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="company_address"
                          label="Alamat Perusahaan"
                          type="textarea"
                          defaultValue="Rukan Puri Mansion Blok B No. 7, Jl. Lingkar Luar Barat Raya, Kel. Kembangan Selatan, Kec. Kembangan, Kota Adm. Jakarta Barat, DKI Jakarta, 11610"
                          value={selectCampaign.akad_wakalah[0].company_address}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6"></Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_indonesia"
                          label="Tujuan Pendanaan (ID)"
                          type="textarea"
                          value={
                            selectCampaign.akad_wakalah[0]
                              .funding_objectives_indonesia
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="funding_objectives_en"
                          label="Tujuan Pendanaan (EN)"
                          type="textarea"
                          value={
                            selectCampaign.akad_wakalah[0].funding_objectives_en
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="implementation_of_the_contract_id"
                          label="Kontrak"
                          type="select"
                          value={
                            selectCampaign.akad_wakalah[0]
                              .implementation_contract
                              ? selectCampaign.akad_wakalah[0]
                                  .implementation_contract.uuid
                              : ""
                          }
                          validate={{
                            required: { value: true },
                          }}
                          options={master.implementation_of_the_contract}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="pengesahan_number"
                          label="Nomor Pengesahan"
                          type="text"
                          defaultValue="AHU-0088780.AH.01.11.Tahun 2018"
                          value={
                            selectCampaign.akad_wakalah[0].pengesahan_number
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="pengesahan_date"
                          label="Tanggal Pengesahan"
                          type="date"
                          defaultValue="2018-07-11"
                          value={selectCampaign.akad_wakalah[0].pengesahan_date}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="time_period_akad"
                          label="Waktu Periode Akad"
                          helpMessage="Dalam bulan"
                          type="number"
                          value={
                            selectCampaign.akad_wakalah[0].time_period_akad
                          }
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="due_date_akad"
                          label="Tanggal Berakhir Akad"
                          type="date"
                          value={selectCampaign.akad_wakalah[0].due_date_akad}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <PInput
                          name="akad_code"
                          label="Kode Akad"
                          type="text"
                          value={selectCampaign.akad_wakalah[0].akad_code}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="row justify-content-end mb-1 mt-3">
                      <div className="col-12">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loadButt}
                        >
                          {loadButt ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              Loading
                            </>
                          ) : (
                            "Simpan"
                          )}
                        </button>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </TabPane>
            </TabContent>
          ) : null}
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading title="Akad" breadcrumbItem="Akad" />

  return (
    <React.Fragment>
      {renderModalRestructure()}
      {renderModalAkad()}
      {renderModalAddendum()}
      {renderModalFormMusyarakah()}
      {renderModalFormMurabahah()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Akad" breadcrumbItem="Akad" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabs === "1",
                        })}
                        onClick={() => {
                          toggleTabs("1")
                        }}
                      >
                        Siap Akad
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabs === "2",
                        })}
                        onClick={() => {
                          toggleTabs("2")
                        }}
                      >
                        Siap Terbit
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabs === "3",
                        })}
                        onClick={() => {
                          toggleTabs("3")
                        }}
                      >
                        Aktif
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabs === "6",
                        })}
                        onClick={() => {
                          toggleTabs("6")
                        }}
                      >
                        Addendum
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabs === "4",
                        })}
                        onClick={() => {
                          toggleTabs("4")
                        }}
                      >
                        Tidak Aktif
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTabs === "5",
                        })}
                        onClick={() => {
                          toggleTabs("5")
                        }}
                      >
                        Lainnya
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTabs} className="">
                    <TabPane tabId="1">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataReady}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataPublish}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataActive}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataNonActive}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="5">
                      <MDBDataTable
                        responsive
                        bordered
                        data={data}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                    <TabPane tabId="6">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataTableRestructure}
                        striped
                        className="mt-4"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Akad
