import { getPortofolio, getPortofolioCampaign, getPortofolioDetail, getTransaction } from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Input, InputGroup, InputGroupAddon, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row, TabContent } from "reactstrap"
import ListPortfolio from "./ListPortfolio"
import moment from "moment"
import classnames from "classnames"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { API_URL } from "helpers/api_url"
import Loading from "pages/Utility/Loading"
import ListTransaction from "./ListTransaction"
import { withRouter } from "react-router"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const Portfolio = props => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = Number(urlParams.get('page'))
  const perPage = Number(urlParams.get('perPage'))
  const [portfolio, setPortfolio] = useState([])
  const [load, setLoad] = useState(false)

  const [selectFolio, setSelectFolio] = useState(null)
  const [transaction, setTransaction] = useState([])

  // const [perPage, setPerPage] = useState(5)
  // const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)

  const [perPageTwo, setPerPageTwo] = useState(5)
  const [pageTwo, setPageTwo] = useState(1)
  const [totalPageTwo, setTotalPageTwo] = useState(0)
  const [currentPageTwo, setCurrentPageTwo] = useState(null)

  const setPage = (page) => {
    props.history.push(`?page=${page}&perPage=${perPage}`)
  }

  const setPerPage = (perPage) => {
    props.history.push(`?page=${1}&perPage=${perPage}`)
  }

  useEffect(() => {
    handleGetPortofolio()
  }, [page, perPage])

  useEffect(() => {
    getDataTransaction()
  }, [selectFolio, pageTwo])

  const handleGetPortofolio = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPage}&&page=${page}`
    setLoad(true)
    const portfolio = await getPortofolioCampaign(token, query)
    setTotalPage(portfolio.meta.pagination.last_page)
    setCurrentPage(portfolio.meta.pagination.current_page)
    setPortfolio(portfolio.data)
    setLoad(false)
  }

  const getDataTransaction = async () => {
    const token = localStorage.getItem("token")
    let query = `?per_page=${perPageTwo}&&page=${pageTwo}`
    if(selectFolio) {
      setLoad(true)
      const trx = await getPortofolioDetail(selectFolio.uuid, token, query)
      setTotalPageTwo(trx.meta.pagination.last_page)
      setCurrentPageTwo(trx.meta.pagination.current_page)
      setTransaction(trx.data)
      setLoad(false)
    }
  }

  const renderPaginate = (numb) => {
    let result = []
    for(let i = 1; i <= numb; i++) {
      result.push(
        <PaginationItem active={i === currentPage}>
          <PaginationLink onClick={() => setPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  const renderPaginateTwo = (numb) => {
    let result = []
    for(let i = 1; i <= numb; i++) {
      result.push(
        <PaginationItem active={i === currentPageTwo}>
          <PaginationLink onClick={() => setPageTwo(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return result
  }

  if(load) {
    return (
      <SkeletonLoad
        title="Portfolio"
        breadcrumbItem="Portfolio"
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <div className="d-flex justify-content-end">
            <Skeleton width={100} />
          </div>
          <Skeleton height={32} />
          <Skeleton height={48} />
          <Skeleton height={48} />
          <Skeleton height={48} />
          <Skeleton height={48} />
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Portfolio" breadcrumbItem="Portfolio" />
          <div className="d-flex justify-content-end">
            <InputGroup size="sm" style={{width: "6rem"}}>
              {/* <InputGroupAddon addonType="prepend">PerPage</InputGroupAddon> */}
                <Input type="select" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Input>
            </InputGroup>
          </div>
          <ListPortfolio 
            projects={portfolio}
            setSelectFolio={setSelectFolio}
          />
          {portfolio.length === 0 &&
            <Row className="">
              <Col lg="12">
                <Card>
                  <CardBody className="text-center">
                    <h5><b>Belum Ada Pembiayaan</b></h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

          {portfolio.length > 0 && 
            <Row className="pb-4 pt-4">
              <Col lg={12} className="d-flex justify-content-center">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink first onClick={() => setPage(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink previous onClick={() => setPage(page - 1)} />
                  </PaginationItem>
                  {renderPaginate(totalPage)}
                  <PaginationItem disabled={page === totalPage}>
                    <PaginationLink next onClick={() => setPage(page + 1)} />
                  </PaginationItem>
                  <PaginationItem disabled={page === totalPage}>
                    <PaginationLink last onClick={() => setPage(totalPage)} />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Portfolio)
