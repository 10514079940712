import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import {
  getScoreAll,
  getScoringResult,
  postApproveRisk,
  postRolebackProject,
} from "helpers/backend_helpers"

const ModalCancelProject = props => {
  const { isOpen, toggle, item, getData } = props

  const [isCheck, setIsCheck] = useState(false)
  const [actionCheck, setActionCheck] = useState("")

  const [loadButt, setLoadButt] = useState(false)
  const [notes, setNotes] = useState("")
  const [files, setFiles] = useState([{ file: null }])

  const handleValidSubmit = async (e, value) => {
    setIsCheck(true)
  }

  const handleChangeFiles = (e, index) => {
    let temp = [...files]
    temp[index].file = e.target.files[0]
    setFiles(temp)
  }

  const handleConfirm = async () => {
    const token = localStorage.getItem("token")

    if (!actionCheck || actionCheck.toLowerCase() !== "revisi")
      return Toaster("error", "Maaf", "Kode Konfirmasi tidak sesuai")

    const newValue = {
      notes,
    }

    files.forEach((el, index) => {
      if (el.file) {
        newValue[`files[${index}]`] = el.file
      }
    })

    delete newValue.files

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postRolebackProject(item.uuid, formData, token)
      toggle(null)
      setFiles([{ file: null }])
      setIsCheck(false)
      setNotes("")
      setActionCheck("")
      setLoadButt(false)
      getData()
      Toaster("success", "Sukses", "Proyek berhasil dikembalikan ke BD.")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      // centered={true}
      className="exampleModal"
      tabindex="-1"
      // toggle={toggle}
      scrollable={true}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggle(null)
            setFiles([{ file: null }])
            setIsCheck(false)
            setNotes("")
            setActionCheck("")
          }}
        >
          {item && item.title}
        </ModalHeader>
        {isCheck ? (
          <ModalBody>
            <FormGroup>
              <Label>
                Masukkan <b>REVISI</b> untuk Konfirmasi Bahwa Proyek akan
                dikembalikan ke Dashboard BD
              </Label>
              <Input
                type="text"
                name="isCheck"
                value={actionCheck}
                onChange={e => setActionCheck(e.target.value)}
              />
            </FormGroup>
            <div className="mt-3 d-flex justify-content-end">
              <Button
                className="mr-2"
                onClick={() => {
                  setIsCheck(false)
                  setActionCheck("")
                }}
                disabled={loadButt}
              >
                Batal
              </Button>
              <Button
                color="primary"
                onClick={handleConfirm}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Konfirmasi"
                )}
              </Button>
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <PInput
                name="notes"
                type="textarea"
                placeholder="Catatan Revisi"
                onChange={e => setNotes(e.target.value)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Catatan Revisi harus diisi",
                  },
                }}
                value={notes}
              />

              {files.map((item, idx) => {
                return (
                  <Row>
                    <Col lg="10">
                      <PInput
                        name={`files[${idx}]`}
                        type="file"
                        // helpMessage="Pdf File"
                        accept=".pdf, .xls, .xlsx"
                        onChange={e => handleChangeFiles(e, idx)}
                        validate={{
                          required: {
                            value: files.length > 1 ? true : false,
                            errorMessage: "File harus diisi",
                          },
                        }}
                        value={item.file}
                      />
                    </Col>
                    <Col lg="2">
                      <Button
                        color="secondary"
                        className="w-100"
                        disabled={files.length === 1}
                        onClick={() =>
                          setFiles(files.filter((el, i) => idx !== i))
                        }
                      >
                        <i className="bx bx-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                )
              })}

              <div className="d-flex justify-content-end mb-3">
                <Button
                  className="btn-sm"
                  onClick={() => setFiles([...files, { file: null }])}
                >
                  Tambah File
                </Button>
              </div>

              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Lanjutkan"
                )}
              </button>
            </AvForm>
          </ModalBody>
        )}
      </div>
    </Modal>
  )
}

export default ModalCancelProject
