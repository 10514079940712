import React from "react"
import { Link } from "react-router-dom"

export default ({ handleNonActiveDropdown, user }) => {
  const renderAjukanPembiayaan = () => {
    if (user) {
      let roles = user.user_role.map(item => item.role.title)
      if (roles.includes("Moderator") || roles.includes("Borrower")) {
        return (
          <Link
            to="/apply-project"
            style={{ textDecoration: "none" }}
            onClick={handleNonActiveDropdown}
          >
            <div className="subtitleNavi">Ajukan Pembiayaan</div>
          </Link>
        )
      } else {
        return null
      }
    }

    return (
      <Link
        to="/register"
        style={{ textDecoration: "none" }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Ajukan Pembiayaan</div>
      </Link>
    )
  }

  return (
    <div className="p-4">
      {/* <Link
        to="/kampanye"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi mt-0">Beri Pembiayaan</div>
      </Link> */}
      <Link
        to="/cara-mendanai"
        style={{ textDecoration: "none" }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Cara Memberi Pembiayaan</div>
      </Link>
      <Link
        to="/cara-mengajukan-pendanaan"
        style={{ textDecoration: "none" }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Cara Mengajukan Pembiayaan</div>
      </Link>
      {renderAjukanPembiayaan()}
      {/* <Link
        to="/apply-project"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Ajukan Pembiayaan</div>
      </Link> */}
      {/* <Link
        to="/login"
        style={{ textDecoration: 'none' }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Ajukan Pembiayaan UKM</div>
      </Link> */}
      <Link
        to="/interest-rates-and-fees"
        style={{ textDecoration: "none" }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Biaya - Biaya</div>
      </Link>
      <Link
        to="/akad-akad"
        style={{ textDecoration: "none" }}
        onClick={handleNonActiveDropdown}
      >
        <div className="subtitleNavi">Akad - Akad</div>
      </Link>
    </div>
  )
}
