import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import ModalImage from "react-modal-image"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import { connect } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import AsyncSelect from "react-select/async"
import {
  createBlog,
  createCategory,
  deleteBlog,
  deleteCategory,
  getBlogAll,
  getCampaignSelector,
  getCarousel,
  getCarouselAdmin,
  getCategory,
  getCategoryAdmin,
  getClaimVoucher,
  getVoucher,
  postCarousel,
  postVoucher,
  updateBlog,
  updateCarousel,
  updateCategory,
  updateVoucher,
} from "helpers/backend_helpers"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"

import PInputHTML from "components/Input/PInputHTML"

const Blog = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  // //
  const [blogs, setBlogs] = useState([])
  const [select, setSelect] = useState(null)
  const [selectCat, setSelectCat] = useState(null)
  const [modalCreateBlog, setModalCreateBlog] = useState(false)
  const [modalUpdateBlog, setModalUpdateBlog] = useState(false)
  const [modalDeleteBlog, setModalDeleteBlog] = useState(false)
  const [modalViewBlog, setModalViewBlog] = useState(false)
  const [modalCreateCategory, setModalCreateCategory] = useState(false)
  const [modalUpdateCategory, setModalUpdateCategory] = useState(false)
  const [modalDeleteCategory, setModalDeleteCategory] = useState(false)

  const [fileUpload, setFileUpload] = useState([])
  const [description, setDescription] = useState(null)
  const [description_en, setDescription_en] = useState(null)

  const [categories, setCategories] = useState([])

  // console.log(convertFromRaw(description))
  const toggleModalCreateCategory = () => {
    setModalCreateCategory(!modalCreateCategory)
  }

  const toggleModalUpdateCategory = item => {
    setSelectCat(item)
    setModalUpdateCategory(!modalUpdateCategory)
  }

  const toggleModalDeleteCategory = item => {
    setSelectCat(item)
    setModalDeleteCategory(!modalDeleteCategory)
  }

  const toggleModalCreateBlog = () => {
    setFileUpload([])
    setDescription(null)
    setDescription_en(null)
    setModalCreateBlog(!modalCreateBlog)
  }

  const toggleModalUpdateBlog = (item = null) => {
    setFileUpload([])
    if (item) {
      setSelect(item)
      setDescription(item.description)
      setDescription_en(item.description_en)
    } else {
      setSelect(null)
      setDescription(null)
      setDescription_en(null)
    }
    setModalUpdateBlog(!modalUpdateBlog)
  }

  const toggleModalDeleteBlog = item => {
    setSelect(item)
    setModalDeleteBlog(!modalDeleteBlog)
  }

  const toggleModalViewBlog = item => {
    setSelect(item)
    setModalViewBlog(!modalViewBlog)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    getDataBlog()
    getDataCategory()
  }, [])

  const getDataBlog = async () => {
    setLoad(true)
    const data = await getBlogAll(token)
    setBlogs(data.data)
    setLoad(false)
  }

  const getDataCategory = async () => {
    setLoad(true)
    const data = await getCategoryAdmin(token)
    setCategories(data.data)
    setLoad(false)
  }

  const tableBlog = {
    columns: [
      {
        field: "title",
        label: "Judul",
      },
      {
        field: "category",
        label: "Kategori",
      },
      // {
      //   field: "images",
      //   label: "Gambar",
      // },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: blogs.map(item => {
      return {
        ...item,
        category: item.post_category ? item.post_category.title : "-",
        images: (
          <ModalImage
            small={API_URL + item.files[0].file}
            large={API_URL + item.files[0].file}
            alt="blog-image"
            className="banner-admin"
          />
        ),
        description: (
          <div dangerouslySetInnerHTML={{ __html: item.description }} />
        ),
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm"
              color="secondary"
              onClick={() => toggleModalUpdateBlog(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              className="btn-sm mx-2"
              color="primary"
              // onClick={() => toggleModalViewBlog(item)}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_API_URL_HOME}/blog/${item.slug}`
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => toggleModalDeleteBlog(item)}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const handleCreateBlog = async (e, value) => {
    if (!description) return Toaster("error", "Maaf", "Field harus diisi")
    if (!description_en) return Toaster("error", "Maaf", "Field harus diisi")
    let newValue = {
      ...value,
      description: description,
      description_en: description_en,
    }

    delete newValue.files

    Object.values(fileUpload).forEach((el, index) => {
      newValue[`files[${index}]`] = el
    })

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await createBlog(formData, token)
      toggleModalCreateBlog()
      setLoadButt(false)
      getDataBlog()
      Toaster("success", "Sukses", "Berhasi tambah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderCreateBlog = () => {
    return (
      <Modal
        isOpen={modalCreateBlog}
        role="dialog"
        autoFocus={true}
        scrollable
        size="xl"
      >
        <ModalHeader toggle={toggleModalCreateBlog}>Buat Blog</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateBlog(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Judul"
              validate={{
                required: { value: true, errorMessage: "Judul harus diisi" },
              }}
            />
            <PInput
              name="title_en"
              type="text"
              label="Judul (EN)"
              validate={{
                required: { value: true, errorMessage: "Judul harus diisi" },
              }}
            />
            <PInput
              name="category_id"
              type="select"
              label="Kategori"
              validate={{
                required: { value: true, errorMessage: "Kategori harus diisi" },
              }}
              options={categories}
            />

            <PInputHTML
              label="Deskripsi"
              value={description}
              onChange={val => {
                setDescription(val)
              }}
            />

            <PInputHTML
              label="Deskripsi (EN)"
              value={description_en}
              onChange={val => {
                setDescription_en(val)
              }}
            />

            <AvGroup>
              <label>
                Gambar<span className="text-danger">*</span>
              </label>
              <div className="custom-file">
                <AvInput
                  type="file"
                  name="files"
                  id="customFile"
                  onChange={e => setFileUpload(e.target.files)}
                  className={`custom-file-input cp-custom`}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Gambar harus diisi",
                    },
                  }}
                  multiple
                />
                <small class="form-text text-muted">
                  jpg, jpeg atau png. (Bisa lebih dari 1 gambar)
                </small>
                <AvFeedback>Berkas harus diisi</AvFeedback>
                <label
                  style={{ color: "#636c72" }}
                  className={`custom-file-label`}
                  htmlFor="customFile"
                >
                  {fileUpload.length > 0
                    ? fileUpload.length + " Berkas"
                    : "Pilih Berkas"}
                </label>
              </div>
            </AvGroup>

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleUpdateBlog = async (e, value) => {
    if (!description) return Toaster("error", "Maaf", "Field harus diisi")
    if (!description_en) return Toaster("error", "Maaf", "Field harus diisi")
    let newValue = {
      ...value,
      description: description,
      description_en: description_en,
    }

    delete newValue.files

    Object.values(fileUpload).forEach((el, index) => {
      newValue[`files[${index}]`] = el
    })

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await updateBlog(select.id, formData, token)
      toggleModalUpdateBlog(null)
      setLoadButt(false)
      getDataBlog()
      Toaster("success", "Sukses", "Berhasi ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderUpdateBlog = () => {
    return (
      <Modal
        isOpen={modalUpdateBlog}
        role="dialog"
        autoFocus={true}
        scrollable
        size="xl"
      >
        <ModalHeader toggle={() => toggleModalUpdateBlog(null)}>
          Ubah Blog
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleUpdateBlog(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Judul"
              value={select ? select.title : ""}
              validate={{
                required: { value: true, errorMessage: "Judul harus diisi" },
              }}
            />
            <PInput
              name="title_en"
              type="text"
              label="Judul (EN)"
              value={select ? select.title_en : ""}
              validate={{
                required: { value: true, errorMessage: "Judul harus diisi" },
              }}
            />
            <PInput
              name="category_id"
              type="select"
              label="Kategori"
              validate={{
                required: { value: true, errorMessage: "Kategori harus diisi" },
              }}
              value={
                select && select.post_category ? select.post_category.id : ""
              }
              options={categories}
            />

            <PInputHTML
              label="Deskripsi"
              value={description}
              onChange={val => {
                setDescription(val)
              }}
            />

            <PInputHTML
              label="Deskripsi (EN)"
              value={description_en}
              onChange={val => {
                setDescription_en(val)
              }}
            />

            <AvGroup>
              <label>
                Gambar<span className="text-danger">*</span>
              </label>
              <div className="custom-file">
                <AvInput
                  type="file"
                  name="files"
                  id="customFile"
                  onChange={e => setFileUpload(e.target.files)}
                  className={`custom-file-input cp-custom`}
                  multiple
                />
                <small class="form-text text-muted">
                  jpg, jpeg atau png. (Bisa lebih dari 1 gambar)
                </small>
                <AvFeedback>Berkas harus diisi</AvFeedback>
                <label
                  style={{ color: "#636c72" }}
                  className={`custom-file-label`}
                  htmlFor="customFile"
                >
                  {fileUpload.length > 0
                    ? fileUpload.length + " Berkas"
                    : "Pilih Berkas"}
                </label>
              </div>
            </AvGroup>

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const renderViewBlog = () => {
    return (
      <Modal
        isOpen={modalViewBlog}
        role="dialog"
        autoFocus={true}
        scrollable
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalViewBlog(null)}>
          Tampilan Blog
        </ModalHeader>
        <ModalBody>
          {select && (
            <div className="pt-5">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div>
                    <div className="text-center">
                      <h4>{select && select.title}</h4>
                      <p className="text-muted mb-4">
                        <i className="mdi mdi-calendar mr-1"></i>{" "}
                        {moment(select.created_at).format("DD MMMM YYYY")}
                      </p>
                    </div>

                    <hr />
                    <div className="text-center">
                      <Row>
                        <Col sm={4}>
                          <div>
                            <p className="text-muted mb-2">Kategori</p>
                            <h5 className="font-size-15">
                              {select.post_category
                                ? select.post_category.title
                                : "-"}
                            </h5>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mt-4 mt-sm-0">
                            <p className="text-muted mb-2">Tanggal</p>
                            <h5 className="font-size-15">
                              {moment(select.created_at).format("DD MMMM YYYY")}
                            </h5>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mt-4 mt-sm-0">
                            <p className="text-muted mb-2">Di Posting Oleh</p>
                            <h5 className="font-size-15">Admin Relations</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />

                    <div className="my-5">
                      {select && (
                        <img
                          src={API_URL + select.files[0].file}
                          alt=""
                          className="img-thumbnail mx-auto d-block"
                        />
                      )}
                    </div>

                    <hr />

                    <div className="mt-4 mb-4">
                      <div className="text-muted font-size-14">
                        {select && (
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: select.description,
                            }}
                          />
                        )}
                      </div>

                      <Row className="mb-4 mt-4">
                        {select &&
                          select.files.map(item => {
                            return (
                              <Col lg="3">
                                <ModalImage
                                  small={API_URL + item.file}
                                  large={API_URL + item.file}
                                  alt={item.title}
                                  className="gall-blog"
                                />
                              </Col>
                            )
                          })}
                      </Row>

                      <hr />
                    </div>

                    {/* <div className="row mb-3">
                      {select &&
                        select.files.map(item => {
                          return (
                            <Col lg="3">
                              <ModalImage
                                small={API_URL + item.file}
                                large={API_URL + item.file}
                                alt={item.title}
                                className="gall-blog"
                              />
                            </Col>
                          )
                        })                  
                      }
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    )
  }

  const handleDeleteBlog = async () => {
    setLoadButt(true)
    try {
      await deleteBlog(select.id, token)
      toggleModalDeleteBlog(null)
      setLoadButt(false)
      getDataBlog()
      Toaster("success", "Sukses", "Berhasi ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderDeleteBlog = () => {
    return (
      <Modal isOpen={modalDeleteBlog} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDeleteBlog(null)}>
          Hapus Blog
        </ModalHeader>
        <ModalBody>
          <div className="">
            <div className="font-16">
              Yakin ingin Menghapus <b>{select && select.title}</b> ?
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="mr-2"
                color="secondary"
                disabled={loadButt}
                onClick={() => toggleModalDeleteBlog(null)}
              >
                Batal
              </Button>
              <Button
                // className="btn-sm"
                color="danger"
                onClick={handleDeleteBlog}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Hapus"
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const handleCreateCategory = async (e, value) => {
    setLoadButt(true)
    try {
      await createCategory({ ...value }, token)
      toggleModalCreateCategory()
      setLoadButt(false)
      getDataCategory()
      Toaster("success", "Sukses", "Berhasi tambah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderCreateCategory = () => {
    return (
      <Modal
        isOpen={modalCreateCategory}
        role="dialog"
        autoFocus={true}
        scrollable
        // size="xl"
      >
        <ModalHeader toggle={toggleModalCreateCategory}>
          Buat Kategori
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateCategory(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Nama Kategori"
              validate={{
                required: { value: true, errorMessage: "Kategori harus diisi" },
              }}
            />
            <PInput
              name="title_en"
              type="text"
              label="Nama Kategori (EN)"
              validate={{
                required: { value: true, errorMessage: "Kategori harus diisi" },
              }}
            />

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleUpdateCategory = async (e, value) => {
    setLoadButt(true)
    try {
      await updateCategory(selectCat.id, { ...value }, token)
      toggleModalUpdateCategory()
      setLoadButt(false)
      getDataCategory()
      Toaster("success", "Sukses", "Berhasi ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderUpdateCategory = () => {
    return (
      <Modal
        isOpen={modalUpdateCategory}
        role="dialog"
        autoFocus={true}
        scrollable
        // size="xl"
      >
        <ModalHeader toggle={() => toggleModalUpdateCategory(null)}>
          Ubah Kategori
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleUpdateCategory(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Nama Kategori"
              value={selectCat && selectCat.title}
              validate={{
                required: { value: true, errorMessage: "Kategori harus diisi" },
              }}
            />

            <PInput
              name="title_en"
              type="text"
              label="Nama Kategori (EN)"
              value={selectCat && selectCat.title_en}
              validate={{
                required: { value: true, errorMessage: "Kategori harus diisi" },
              }}
            />

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleDeleteCategory = async () => {
    setLoadButt(true)
    try {
      await deleteCategory(selectCat.id, token)
      toggleModalDeleteCategory()
      setLoadButt(false)
      getDataCategory()
      Toaster("success", "Sukses", "Berhasi hapus data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderDeleteCategory = () => {
    return (
      <Modal isOpen={modalDeleteCategory} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDeleteCategory(null)}>
          Hapus Kategori
        </ModalHeader>
        <ModalBody>
          <div className="">
            <div className="font-16">
              Yakin ingin Menghapus <b>{selectCat && selectCat.title}</b> ?
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="mr-2"
                color="secondary"
                disabled={loadButt}
                onClick={() => toggleModalDeleteCategory(null)}
              >
                Batal
              </Button>
              <Button
                // className="btn-sm"
                color="danger"
                onClick={handleDeleteCategory}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Hapus"
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const tableCategories = {
    columns: [
      {
        field: "title",
        label: "Judul",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: categories.map(item => {
      return {
        ...item,
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm mr-2"
              color="secondary"
              onClick={() => toggleModalUpdateCategory(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => toggleModalDeleteCategory(item)}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  // console.log(categories)
  // //

  if (load) return <Loading title="Kelola" breadcrumbItem="Blog" />

  return (
    <React.Fragment>
      {renderCreateBlog()}
      {renderUpdateBlog()}
      {renderDeleteBlog()}
      {renderViewBlog()}
      {renderCreateCategory()}
      {renderUpdateCategory()}
      {renderDeleteCategory()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kelola" breadcrumbItem="Blog" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Blog
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Kategori
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModalCreateBlog}>
                          + Tambah Blog
                        </Button>
                      </div>

                      <MDBDataTable
                        responsive
                        bordered
                        data={tableBlog}
                        striped
                        // className="mt-3"
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModalCreateCategory}>
                          + Tambah Kategori
                        </Button>
                      </div>

                      <MDBDataTable
                        responsive
                        bordered
                        data={tableCategories}
                        striped
                        // className="mt-3"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Blog)
