import React from "react"
import PropTypes from "prop-types"
import ModalImage from "react-modal-image"
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { API_URL } from "helpers/api_url"
import PInput from "components/Input/PInput"
import AvForm from "availity-reactstrap-validation/lib/AvForm"

const ModalDetail = props => {
  const { isOpen, toggle, user } = props
  // console.log(user)

  const handleRoles = () => {
    let result = []
    user.user_role.forEach(element => {
      result.push(element.role.title)
    })
    return result
  }

  const renderButtonFile = item => {
    if (typeof item === "string") {
      return (
        <Button
          className="btn-sm"
          style={{ marginTop: "-12px", marginBottom: "8px" }}
          disabled={!item}
          onClick={() => window.open(`${API_URL}${item}`, "_blank")}
        >
          <i className="fas fa-eye"></i>
        </Button>
      )
    }
    return null
  }

  const renderData = () => {
    if (!user) return
    return (
      <AvForm>
        <Row className="">
          <Col xs={12} md={6}>
            <PInput
              name="first_name"
              label="Nama Depan"
              type="text"
              value={user.first_name}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="last_name"
              label="Nama Belakang"
              type="text"
              value={user.last_name}
              disabled
            />
          </Col>

          <Col xs={12} md={6}>
            <PInput
              name="email"
              label="Email"
              type="text"
              value={user.email}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="phone_number"
              label="No Handphone"
              type="text"
              value={user.phone_number}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="user_title_id"
              label="Panggilan"
              // type="select"
              value={user.profile.title.title}
              disabled
              // options={[{title: user ? user.profile.title.title : ""}]}
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="address"
              label="Alamat Sesuai KTP"
              type="text"
              value={user.address[0].address}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="rt"
              label="RT Sesuai KTP"
              type="text"
              value={user.address[0].rt}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="rw"
              label="RW Sesuai KTP"
              type="text"
              value={user.address[0].rw}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="province_id"
              label="Provinsi Sesuai KTP"
              // type="select"
              value={user.address[0].province.name}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="city_id"
              label="Kota Sesuai KTP"
              // type="select"
              value={user.address[0].city.name}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="district_id"
              label="Kecamatan Sesuai KTP"
              // type="select"
              value={user.address[0].district.name}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="sub_district_id"
              label="Kelurahan Sesuai KTP"
              // type="select"
              value={user.address[0].sub_district.name}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="home_address"
              label="Alamat Tempat Tinggal"
              type="text"
              value={user.address[0].home_address}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="parent_name"
              label="Nama Ibu"
              type="text"
              value={user.profile.parent_name}
              disabled
            />
          </Col>
          <Col xs={12} md={3}>
            <PInput
              name="place_of_birth"
              label="Tempat Lahir"
              type="text"
              value={user.profile.place_of_birth}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="date_of_birth"
              label="Tanggal Lahir"
              type="date"
              helpMessage="Bulan/Hari/Tahun"
              value={user.profile.date_of_birth}
              disabled
            />
          </Col>
          <Col className="col-md-9"></Col>
          <Col xs={12} md={6}>
            <PInput
              name="religion_id"
              label="Agama"
              // type="select"
              value={user.profile.religion.title}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="gender_id"
              label="Jenis Kelamin"
              // type="select"
              value={user.profile.gender.title}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="married_status_id"
              label="Status Perkawinan"
              // type="select"
              value={user.profile.married_status.title}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="education_id"
              label="Pendidikan Terakhir"
              // type="select"
              value={user.profile.education.title}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="job_id"
              label="Pekerjaan"
              // type="select"
              value={user.profile.job.title}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="job_sector_id"
              label="Bidang Pekerjaan"
              // type="select"
              value={user.profile.job_sector.title}
              disabled
            />
          </Col>
          {handleRoles().includes("Lender") && (
            <Col xs={12} md={6}>
              <PInput
                name="source_funding_id"
                label="Sumber Dana"
                // type="select"
                value={user.profile.source_funding.title}
                disabled
              />
            </Col>
          )}
          <Col xs={12} md={6}>
            <PInput
              name="month_in_come_id"
              label="Penghasilan Perbulan"
              // type="select"
              value={user.profile.month_income.title}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="ktp_number"
              label="NIK"
              type="text"
              value={user.profile.ktp_number}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="npwp_number"
              label="NPWP"
              type="text"
              value={user.profile.npwp_number}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="mb-3">
              <Label>KTP</Label>
              <ModalImage
                small={API_URL + user.profile.ktp_file}
                large={API_URL + user.profile.ktp_file}
                alt="KTP file"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="mb-3">
              <Label>NPWP</Label>
              <ModalImage
                small={API_URL + user.profile.npwp_file}
                large={API_URL + user.profile.npwp_file}
                alt="NPWP file"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="mb-3">
              <Label>Foto selfie</Label>
              <ModalImage
                small={API_URL + user.profile.selfie_file}
                large={API_URL + user.profile.selfie_file}
                alt="Selfie file"
              />
            </div>
          </Col>
          {user.lenderAsCompany == 1 ? (
            <>
              <Col xs={12} md={6}>
                <PInput
                  name="company_name"
                  label="Nama Perusahaan"
                  type="text"
                  value={user.profile.company_name}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="addressNib"
                  label="Alamat Perusahaan"
                  type="text"
                  value={user.profile.addressNib}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="establishmentDeedNumber"
                  label="Nomor Akta Pendirian"
                  type="text"
                  value={user.profile.establishmentDeedNumber}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="dateOfDeedOfEstablishment"
                  label="Tanggal Akta Pendirian"
                  type="date"
                  value={user.profile.dateOfDeedOfEstablishment}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  <Label>File Akta Pendirian</Label>
                </p>
                {renderButtonFile(user.profile.akta_pendirian)}
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="establishmentSkNumber"
                  label="Nomor SK Akta Pendirian"
                  type="text"
                  value={user.profile.establishmentSkNumber}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="dateOfEstablishmentDecree"
                  label="Tanggal SK Akta Pendirian"
                  type="date"
                  value={user.profile.dateOfEstablishmentDecree}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  <Label>File SK Akta Pendirian</Label>
                </p>
                {renderButtonFile(user.profile.sk_akta_pendirian)}
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="lastAmendmentDeedNumber"
                  label="Nomor Akta Perubahan Terakhir"
                  type="text"
                  value={user.profile.lastAmendmentDeedNumber}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="dateOfLastAmendmentDeed"
                  label="Tanggal Akta Perubahan Terakhir"
                  type="date"
                  value={user.profile.dateOfLastAmendmentDeed}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  <Label>File Akta Perubahan Terakhir</Label>
                </p>
                {renderButtonFile(user.profile.akta_perubahan_terakhir)}
              </Col>

              <Col xs={12} md={6}>
                <PInput
                  name="skNumberLastChange"
                  label="Nomor SK Akta Perubahan Terakhir"
                  type="text"
                  value={user.profile.skNumberLastChange}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="dateSkLastChange"
                  label="Tanggal SK Akta Perubahan Terakhir"
                  type="date"
                  value={user.profile.dateSkLastChange}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  <Label>File SK Akta Perubahan Terakhir</Label>
                </p>
                {renderButtonFile(user.profile.sk_perubahan_terakhir)}
              </Col>

              <Col xs={12} md={6}>
                <PInput
                  name="no_nib"
                  label="NIB Perusahaan"
                  type="text"
                  value={user.profile.no_nib}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  <Label>File NIB Perusahaan</Label>
                </p>
                {renderButtonFile(user.profile.nib_file)}
              </Col>
              <Col xs={12} md={6}>
                <PInput
                  name="no_siup"
                  label="Nomor SIUP"
                  type="text"
                  value={user.profile.no_siup}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <p>
                  <Label>File SIUP</Label>
                </p>
                {renderButtonFile(user.profile.siup_file)}
              </Col>
            </>
          ) : null}
          <Col xs={12} md={6}>
            <PInput
              name="name"
              label="Nama Pemilik Rekening"
              type="text"
              value={user.account[0].name}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="account_number"
              label="Nomor Rekening"
              type="text"
              value={user.account[0].account_number}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="bank_id"
              label="Bank"
              // type="select"
              value={user.account[0].bank_account.name}
              disabled
            />
          </Col>
          <Col xs={12} md={6}>
            <PInput
              name="branch"
              label="Cabang"
              type="text"
              value={user.account[0].branch}
              disabled
            />
          </Col>
        </Row>
      </AvForm>
    )
  }

  // if(!user) return null

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      className="exampleModal"
      tabindex="-1"
      // toggle={toggle}
      scrollable={true}
      size="lg"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => toggle(null)}>Profil Detail</ModalHeader>
        <ModalBody>{renderData()}</ModalBody>
      </div>
    </Modal>
  )
}

export default ModalDetail
