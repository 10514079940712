import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

const Welcome = props => {
  const {user} = props

  // console.log(user)

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="12">
              <div className="text-primary p-3">
                <h2 className="text-primary">Selamat Datang {user.first_name} {user.last_name}</h2>
                {user.kyc_status < 9 ?
                  <div className="mt-3 mb-3">
                    <Link
                      to="/profile"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Lengkapi Profil Anda <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </div> :
                  <div className="mt-3 mb-3">
                    <Link
                      to="/profile"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Lihat Profil <i className="mdi mdi-arrow-right ml-1"></i>
                    </Link>
                  </div>
                }
              </div>
            </Col>
            {/* <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col> */}
          </Row>
        </div>
        {/* <CardBody className="pt-0 pb-3">
          <Row>
            <Col sm="4">
              
              <div className="avatar-md profile-user-wid mb-2">
                <img
                  src={`https://banner2.cleanpng.com/20180327/ssq/kisspng-computer-icons-user-profile-avatar-profile-5ab9e3b05772c0.6947928615221318883582.jpg`}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{`${user.first_name} ${user.last_name}`}</h5>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="4">
                    <h5 className="font-size-15">{data ? `Rp ${parseInt(data.totalInvestment).toLocaleString()}` : "Rp -"}</h5>
                    <p className="text-muted mb-0">Total Pembiayaan</p>
                  </Col>
                  <Col xs="4">
                    <h5 className="font-size-15">{data ? data.totalInvestCampaign : "-"}</h5>
                    <p className="text-muted mb-0">Total Kampanye Dibiayai</p>
                  </Col>
                  <Col xs="4">
                    <h5 className="font-size-15">{data ? `Rp ${parseInt(data.totalPayout).toLocaleString()}` : "Rp -"}</h5>
                    <p className="text-muted mb-0">Total Pengembalian</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody> */}
      </Card>
    </React.Fragment>
  )
}
export default Welcome
