import {
  delDisburse,
  delPayout,
  delRefund,
  downloadPayout,
  getAllDisburse,
  getCampaign,
  getCampaignActive,
  getCampaignAll,
  getCampaignSelector,
  getCollectionId,
  getRefundByCampaign,
  getRepaymentType,
  getTrxCampaigns,
  getTrxCampaignsInvestment,
  getTrxHistories,
  postDisburse,
  postEditPayout,
  postImportPayout,
  postImportUpdatePayout,
  postPayout,
  postRefund,
  postUpdateDisburse,
  getCampaignAdmin,
  uploadTax,
} from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { map } from "lodash"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { post } from "helpers/api_helpers"
import Toaster from "components/Common/Toast"
import { API_URL, API_URL_1 } from "helpers/api_url"
import axios from "axios"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import SweetAlert from "react-bootstrap-sweetalert"
import { GET_UPDATE_TEMPLATE } from "helpers/url_helpers"

const Payout = props => {
  const [campaign, setCampaign] = useState([])
  const [load, setLoad] = useState(false)
  const [trx, setTrx] = useState([])
  const [select, setSelect] = useState("")
  const [activeTab, setActiveTab] = useState("1")
  const [modal, setModal] = useState(false)
  const [uuid, setUuid] = useState(null)
  const [paymentType, setPaymentType] = useState([])
  const [file, setFile] = useState(null)
  const [fileUpdate, setFileUpdate] = useState(null)
  const [loadButt, setLoadButt] = useState(false)
  const [modalDetail, setModalDetail] = useState(false)
  const [historyTrx, setHistoryTrx] = useState([])
  const [cmpgn, setCmpgn] = useState(null)
  const [editedIdx, setEditedIdx] = useState({
    item: null,
    index: null,
  })
  const [selected, setSelected] = useState(null)

  const [modalCreateDisburse, setModalCreateDisburse] = useState(false)
  const [modalEditDisburse, setModalEditDisburse] = useState(false)
  const [selectDisburse, setSelectDisburse] = useState(null)
  const [fileDisburse, setFileDisburse] = useState(null)
  const [disbursement, setDisbursement] = useState([])

  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)

  const [transactions, setTransactions] = useState([])

  const [modalRefund, setModalRefund] = useState(false)
  const [selectRefund, setSelectRefund] = useState(null)
  const [fileRefund, setFileRefund] = useState(null)

  const [refunds, setRefunds] = useState([])

  const [modalDownload, setModalDownload] = useState(false)
  const [modalDownloadAll, setModalDownloadAll] = useState(false)

  const [loadDownload, setLoadDownload] = useState(false)
  const [collect, setCollect] = useState([])

  const [ttop, setttop] = useState(false)
  const [ttopIdx, setttopIdx] = useState(null)

  const [loadButtDownload, setLoadButtDownload] = useState(false)

  const toggleModalDownload = () => setModalDownload(!modalDownload)
  const toggleModalDownloadAll = () => setModalDownloadAll(!modalDownloadAll)

  const toggleCreateDisburse = () =>
    setModalCreateDisburse(!modalCreateDisburse)
  const toggleEditDisburse = item => {
    setSelectDisburse(item)
    setModalEditDisburse(!modalEditDisburse)
  }

  const toggleModalRefund = item => {
    setSelectRefund(item)
    setModalRefund(!modalRefund)
  }

  const toggleModal = id => {
    if (id) {
      setUuid(id)
    } else {
      setUuid(null)
    }
    setModal(!modal)
  }

  const toggleModalDetail = id => {
    if (id) {
      setUuid(id)
    } else {
      setUuid(null)
    }
    setModalDetail(!modalDetail)
  }

  useEffect(() => {
    getCampaignActiveAll()
  }, [])

  useEffect(() => {
    if (select) {
      getCollectionPayout()
    }
  }, [select])

  useEffect(() => {
    if (select) {
      getSelectCampaign()
    }
  }, [select, activeTab])

  const getCollectionPayout = async () => {
    const token = localStorage.getItem("token")
    // setLoad(true)
    const data = await getCollectionId(select, token)
    setCollect(data.data)
    // setLoad(false)
  }

  const getCampaignActiveAll = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    // const data = await getCampaignAll(token)
    const type = await getRepaymentType(token)
    // setCampaign(data.data)
    setPaymentType(type.data)
    setLoad(false)
  }

  const getSelectCampaign = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      if (activeTab === "1") {
        const data = await getTrxCampaigns(select, token)
        setTrx(data.data)
      } else if (activeTab === "2") {
        const his = await getTrxHistories(select, token)
        setHistoryTrx(his.data)
      } else if (activeTab === "3") {
        const disburseData = await getAllDisburse(select, token)
        setDisbursement(disburseData.data)
      } else if (activeTab === "4") {
        const trxs = await getTrxCampaignsInvestment(select, token)
        setTransactions(trxs.data)
      } else {
        const refundAll = await getRefundByCampaign(select, token)
        setRefunds(refundAll.data)
      }

      const cmpgn = await getCampaignAdmin(select, token)
      setCmpgn(cmpgn.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const [amountDownload, setAmountDownload] = useState("")
  const [vaDownload, setVaDownload] = useState(null)
  const resetDownload = () => {
    setAmountDownload("")
    setVaDownload(null)
  }

  const handleDownload = async (e, value) => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    axios
      .get(
        `${API_URL_1}/campaigns/${select}/export?amount=${value.amount}&collection_collect_id=${value.collection_collect_id}&tax=${value.tax}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": "attachment; filename=transaksi_payout.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `transaksi_payout.xlsx`)
        document.body.appendChild(link)
        link.click()
        toggleModalDownload()
        resetDownload()
        setLoadButt(false)
      })
      .catch(err => {
        setLoadButt(false)
        Toaster("error", "Ups", err.response.data.message)
      })
  }

  const renderModalDownload = () => {
    return (
      <Modal isOpen={modalDownload} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDownload()}>
          Unduh Pengembalian
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleDownload(e, v)
            }}
          >
            <PInput
              name="amount"
              type="number"
              label="Jumlah"
              helpMessage="Rupiah"
              value={amountDownload}
              disabled={vaDownload ? true : false}
              onChange={e => setAmountDownload(e.target.value)}
            />
            <PInput
              name="tax"
              type="number"
              label="Pajak"
              helpMessage="Dalam Persen"
              // onChange={e => setAmountDownload(e.target.value)}
            />
            <PInput
              name="collection_collect_id"
              type="select"
              label="Pilih Virtual Account Pengembalian Borrower"
              value={vaDownload}
              onChange={e => setVaDownload(e.target.value)}
              disabled={amountDownload ? true : false}
              options={collect.map(item => {
                return { name: item.va_number, uuid: item.uuid }
              })}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Unduh"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const [amountDownloadAll, setAmountDownloadAll] = useState("")
  const [vaDownloadAll, setVaDownloadAll] = useState(null)
  const resetDownloadAll = () => {
    setAmountDownloadAll("")
    setVaDownloadAll(null)
  }

  const handleDownloadAll = async (e, value) => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    axios
      .get(
        `${API_URL_1}/campaigns/${select}/export/all?amount=${value.amount}&collection_collect_id=${value.collection_collect_id}&tax=${value.tax}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Disposition": `attachment; filename=transaksi_semua.xlsx`,
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `transaksi_semua.xlsx`)
        document.body.appendChild(link)
        link.click()
        toggleModalDownloadAll()
        resetDownloadAll()
        setLoadButt(false)
      })
      .catch(err => {
        setLoadButt(false)
        Toaster("error", "Ups", err.response.data.message)
      })
  }

  const renderModalDownloadAll = () => {
    return (
      <Modal isOpen={modalDownloadAll} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDownloadAll()}>
          Unduh Semua Pengembalian
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleDownloadAll(e, v)
            }}
          >
            <PInput
              name="amount"
              type="number"
              label="Jumlah"
              helpMessage="Rp"
              value={amountDownloadAll}
              disabled={vaDownloadAll ? true : false}
              onChange={e => setAmountDownloadAll(e.target.value)}
            />
            <PInput
              name="tax"
              type="number"
              label="Pajak"
              helpMessage="Dalam Persen"
              // onChange={e => setAmountDownload(e.target.value)}
            />
            <PInput
              name="collection_collect_id"
              type="select"
              label="Pilih Virtual Account Pengembalian Borrower"
              value={vaDownloadAll}
              onChange={e => setVaDownloadAll(e.target.value)}
              disabled={amountDownloadAll ? true : false}
              options={collect.map(item => {
                return { name: item.va_number, uuid: item.uuid }
              })}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Unduh"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const toggleModalDownloadInvoiceAll = async () => {
    const token = localStorage.getItem("token")
    setLoadButtDownload(true)
    axios
      .get(`${API_URL_1}/campaigns/${select}/download/zip/invoice`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `invoice-${cmpgn?.title}.zip`)
        document.body.appendChild(link)
        link.click()
        setLoadButtDownload(false)
      })
      .catch(err => {
        setLoadButtDownload(false)
        Toaster("error", "Ups", err.response.data.message)
      })
  }

  const checkPrivy = arr => {
    if (!arr) return
    if (arr.length === 0) return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].status === 1) return <Badge color="danger">Belum TTD</Badge>
    if (arr[0].status === 2) return <Badge color="primary">Sudah TTD</Badge>
    if (arr[0].status === 3)
      return <Badge color="danger">Kesalahan Token</Badge>
  }

  const Nisbah = (amount, nisbah) => {
    let modal = Number(amount)
    let roi = Number(nisbah) / 100
    return parseInt(modal * roi).toLocaleString()
  }

  const handleStatus = trx => {
    if (trx.status === 1) return <Badge color="info">Menunggu Pembayaran</Badge>
    if (trx.status === 2) return <Badge color="info">Menunggu Pembayaran</Badge>
    if (trx.status === 3)
      return <Badge color="info">Menunggu Pembayaran Bertahap</Badge>
    if (trx.status === 4)
      return <Badge color="info">Menunggu Pengembalian</Badge>
    if (trx.status === 5)
      return <Badge color="info">Menunggu Pengembalian Bertahap</Badge>
    if (trx.status === 6)
      return <Badge color="info">Selesai Pengembalian Pokok</Badge>
    if (trx.status === 7)
      return <Badge color="info">Selesai Pengembalian Pokok dan Nisbah</Badge>
    // if(trx.status === 7) return <Badge color="info">Selesai Pengembalian Pokok dan Nisbah</Badge>
    // if(trx.status === 8) return <Badge color="info">Selesai Pengembalian Pokok dan Nisbah</Badge>
    if (trx.status === 9) return <Badge color="info">Kadaluarsa</Badge>
  }

  const handleBankType = value => {
    switch (value) {
      case 2:
      case 3:
        return "BSI [EX-BNIS]"
      case 4:
        return "Permata Syariah"
      case 6:
        return "RDF"
      default:
        return "-"
    }
  }

  const handleTotalPayout = data => {
    let result = 0
    for (let i = 0; i < data.length; i++) {
      result += parseFloat(data[i].amount_total)
    }
    return result
  }

  const handleDataTrx = data => {
    const colums = []
    let newData = data.map((item, index) => {
      let newItem = item
      // if (item.repayment_transaction.length === 1) return newItem
      item.repayment_transaction.forEach((el, idx) => {
        newItem[`payout_${idx + 1}`] = (
          <div style={{ cursor: "pointer" }}>
            <Tooltip
              placement="top"
              isOpen={ttop && ttopIdx === el.uuid}
              target={"TooltipTop-" + el.uuid}
              toggle={() => {
                setttop(!ttop)
                setttopIdx(el.uuid)
              }}
            >
              <div className="text-left">
                {parseInt(el.tax) > 0 ? (
                  <div>{`Pajak: Rp ${Number(el.total_tax).toLocaleString()} (${
                    el.tax
                  }%)`}</div>
                ) : (
                  <div>Pajak: -</div>
                )}

                <div>{`Total: Rp ${Number(
                  parseInt(el.tax) > 0
                    ? el.received_by_investor
                    : el.amount_total
                ).toLocaleString()}`}</div>
              </div>
            </Tooltip>
            <div id={"TooltipTop-" + el.uuid}>{`Rp ${Number(
              el.amount_total
            ).toLocaleString()}`}</div>
          </div>
        )
        // newItem[`payout_${idx + 1}`] = `Rp ${Number(
        //   el.amount_total
        // ).toLocaleString()}`

        // if (parseFloat(el.tax) > 0) {
        //   newItem[`tax_${idx + 1}`] = (
        //     <div className="text-nowrap">{`Rp ${Number(
        //       el.total_tax
        //     ).toLocaleString()} (${el.tax}%)`}</div>
        //   )
        //   newItem[`received_by_investor_${idx + 1}`] = `Rp ${Number(
        //     el.received_by_investor
        //   ).toLocaleString()}`
        // }
        if (index === 0) {
          colums.push({
            field: `payout_${idx + 1}`,
            label: `Pengembalian Tahap ${idx + 1}`,
          })
          // if (parseFloat(el.tax) > 0) {
          //   colums.push({
          //     field: `tax_${idx + 1}`,
          //     label: `Pajak Pengembalian Tahap ${idx + 1}`,
          //   })
          //   colums.push({
          //     field: `received_by_investor_${idx + 1}`,
          //     label: `Total Pengembalian Tahap ${idx + 1}`,
          //   })
          // }
        }
      })

      return newItem
    })

    return { newData, colums }
  }

  const isMurabahah = value => value == "Murabahah"
  const isMusyarakah = value => value == "Musyarakah"

  const data = {
    columns: [
      {
        field: "trx_id",
        label: <div className="text-nowrap">Id Transaksi</div>,
      },
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "amount",
        label: "Pembiayaan",
      },
      {
        field: "bank_type",
        label: "Metode Pembayaran",
      },
      {
        field: "datetime_payment",
        label: <div className="text-nowrap">Tanggal Bayar</div>,
      },
      {
        field: "estimasi_nisbah",
        label: <div className="text-nowrap">Estimasi Nisbah</div>,
      },
      ...handleDataTrx(trx).colums,
      {
        field: "total_payout",
        label: "Total Pengembalian",
      },
      {
        field: "outstanding",
        label: "Sisa Pengembalian",
      },
      {
        field: "status_doc_musyarakah",
        label: cmpgn?.akad_type?.title || "Musyarakah",
      },
      {
        field: "status_doc_wakalah",
        label: "Wakalah",
      },
      {
        field: "detailPayout",
        label: "Detail Pengembalian",
      },
      {
        field: "payout",
        label: "Input Pengembalian",
      },
      {
        field: "refund",
        label: "Pembatalan Transaksi",
      },
    ],
    rows: map(handleDataTrx(trx).newData, item => ({
      ...item,
      outstanding: `Rp ${item.outstanding.toLocaleString()}`,
      bank_type:
        item.bank_type === 5
          ? item.bank_name || "-"
          : handleBankType(item.bank_type),
      total_payout: `Rp ${handleTotalPayout(
        item.repayment_transaction
      ).toLocaleString()}`,
      datetime_payment: item.datetime_payment ? (
        <div className="text-nowrap">
          {moment(item.datetime_payment).format("DD MMM YYYY")}
        </div>
      ) : (
        "-"
      ),
      name: `${item.user.first_name} ${item.user.last_name}`,
      estimasi_nisbah: (
        <div className="text-nowrap">
          Rp {Nisbah(item.amount, item.campaign.nisbah)}
        </div>
      ),
      amount: `Rp ${Number(item.payment_amount).toLocaleString()}`,
      // status: <div>{handleStatus(item)}</div>,
      status_doc_musyarakah: checkPrivy(
        isMurabahah(cmpgn?.akad_type?.title)
          ? item.privy_document_murabahah
          : item.privy_document_musyarakah
      ),
      status_doc_wakalah: checkPrivy(item.privy_document_wakalah),
      payout: (
        <Button className="btn-sm" onClick={() => toggleModal(item)}>
          <i className="fas fa-edit"></i>
        </Button>
      ),
      detailPayout: (
        <Button className="btn-sm" onClick={() => toggleModalDetail(item)}>
          <i className="fas fa-eye"></i>
        </Button>
      ),
      refund:
        item.status !== 10 ? (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggleModalRefund(item)}
          >
            <i class="bx bxs-edit"></i>
          </Button>
        ) : (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => {
              setSelectRefund(item)
              setconfirm_alert2(true)
            }}
          >
            Batalkan
          </Button>
        ),
    })),
  }

  const dataHistory = {
    columns: [
      {
        field: "trx_id",
        label: <div className="text-nowrap">Id Transaksi</div>,
      },
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "amount",
        label: "Pembiayaan",
      },
      {
        field: "bank_type",
        label: "Metode Pembayaran",
      },
      {
        field: "datetime_payment",
        label: <div className="text-nowrap">Tanggal Bayar</div>,
      },
      {
        field: "status",
        label: "Status",
      },
      {
        field: "estimasi_nisbah",
        label: <div className="text-nowrap">Estimasi Nisbah</div>,
      },
      ...handleDataTrx(historyTrx).colums,
      {
        field: "total_payout",
        label: "Total Pengembalian",
      },
      {
        field: "status_doc_musyarakah",
        label: cmpgn?.akad_type?.title || "Musyarakah",
      },
      {
        field: "status_doc_wakalah",
        label: "Wakalah",
      },
      {
        field: "detailPayout",
        label: "Detail Pengembalian",
      },
      {
        field: "invoice",
        label: "Download Invoice",
      },
    ],
    rows: map(handleDataTrx(historyTrx).newData, item => ({
      ...item,
      bank_type:
        item.bank_type === 5
          ? item.bank_name || "-"
          : handleBankType(item.bank_type),
      total_payout: `Rp ${handleTotalPayout(
        item.repayment_transaction
      ).toLocaleString()}`,
      datetime_payment: item.datetime_payment ? (
        <div className="text-nowrap">
          {moment(item.datetime_payment).format("DD MMM YYYY")}
        </div>
      ) : (
        "-"
      ),
      name: `${item.creator.first_name} ${item.creator.last_name}`,
      estimasi_nisbah: (
        <div className="text-nowrap">
          Rp {Nisbah(item.amount, item.campaign.nisbah)}
        </div>
      ),
      amount: `Rp ${Number(item.payment_amount).toLocaleString()}`,
      status: <div>{handleStatus(item)}</div>,
      status_doc_musyarakah: checkPrivy(
        isMurabahah(cmpgn?.akad_type?.title)
          ? item.privy_document_murabahah
          : item.privy_document_musyarakah
      ),
      status_doc_wakalah: checkPrivy(item.privy_document_wakalah),
      detailPayout: (
        <Button className="btn-sm" onClick={() => toggleModalDetail(item)}>
          <i className="fas fa-eye"></i>
        </Button>
      ),
      invoice: (
        <Button
          className="btn-sm"
          onClick={() => handleDownloadInvoiceAll(item)}
          disabled={loadButt}
        >
          <i className="bx bx-download"></i>
        </Button>
      ),
    })),
  }

  const disburseDatas = {
    columns: [
      {
        field: "amount",
        label: "Jumlah",
        sort: "asc",
      },
      {
        field: "date",
        label: "Tanggal",
        sort: "asc",
      },
      {
        field: "description",
        label: "Deskripsi",
        sort: "asc",
      },
      {
        field: "file",
        label: "Bukti Pencairan",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: disbursement.map(item => {
      return {
        ...item,
        amount: `Rp ${parseInt(item.amount).toLocaleString()}`,
        date: moment(item.date).format("DD MMM YYYY"),
        file: !item.file ? (
          "-"
        ) : (
          <Button
            className="btn-sm"
            onClick={() => window.open(`${API_URL}${item.file}`, "_blank")}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm mr-2"
              color="secondary"
              onClick={() => toggleEditDisburse(item)}
            >
              <i class="bx bxs-edit"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => {
                setSelectDisburse(item)
                setconfirm_alert(true)
              }}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const dataRefund = {
    columns: [
      {
        field: "created_at",
        label: "Tanggal Transaksi",
        sort: "asc",
      },
      {
        field: "campaign",
        label: "Nama Kampanye",
        sort: "asc",
      },
      {
        field: "trx_id",
        label: "ID Transaksi",
        sort: "asc",
      },
      {
        field: "amount",
        label: "Pembiayaan",
        sort: "asc",
      },
      {
        field: "name",
        label: "Nama",
        sort: "asc",
      },
      {
        field: "email",
        label: "Email",
        sort: "asc",
      },
      {
        field: "phone",
        label: "Telepon",
        sort: "asc",
      },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      // {
      //   field: "status_doc_musyarakah",
      //   label: "Musyarakah",
      //   sort: "asc",
      // },
      // {
      //   field: "status_doc_wakalah",
      //   label: "Wakalah",
      //   sort: "asc",
      // },
      {
        field: "refund",
        label: "Form Pembatalan",
      },
    ],
    rows: map(transactions, item => ({
      ...item,
      created_at: moment(item.created_at).format("DD MMM YYYY HH:mm"),
      campaign: item.campaign.title,
      amount: `Rp ${Number(item.amount).toLocaleString()}`,
      status: (
        <div>
          {item.status === 1 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 2 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 3 && (
            <Badge color="warning">Pembayaran Bertahap</Badge>
          )}
          {item.status === 4 && <Badge color="success">Sudah Dibayar</Badge>}
          {item.status === 5 && (
            <Badge color="warning">Pengembalian Bertahap</Badge>
          )}
          {item.status === 6 && (
            <Badge color="primary">Selesai Pengembalian Pokok</Badge>
          )}
          {item.status === 7 && (
            <Badge color="primary">Selesai Pengembalian dan Nisbah</Badge>
          )}
          {/* {item.status === 7 && <Badge color="primary">Selesai Pengembalian</Badge>}
                {item.status === 8 && <Badge color="primary">Selesai Pengembalian</Badge>} */}
          {item.status === 9 && <Badge color="info">Kadaluarsa</Badge>}
          {item.status === 10 && <Badge color="secondary">Dikembalikan</Badge>}
          {item.status === 11 && <Badge color="secondary">Dibatalkan</Badge>}
        </div>
      ),
      name: `${item.user.first_name} ${item.user.last_name}`,
      email: item.user.email,
      phone: item.user.phone_number,
      status_doc_musyarakah: checkPrivy(item.privy_document_musyarakah),
      status_doc_wakalah: checkPrivy(item.privy_document_wakalah),
      refund:
        item.status !== 10 ? (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggleModalRefund(item)}
          >
            <i class="bx bxs-edit"></i>
          </Button>
        ) : (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => {
              setSelectRefund(item)
              setconfirm_alert2(true)
            }}
          >
            Batalkan
          </Button>
        ),
    })),
  }

  const dataRefundHistory = {
    columns: [
      {
        field: "campaign",
        label: "Nama Kampanye",
        sort: "asc",
      },
      {
        field: "trx_id",
        label: "ID Transaksi",
        sort: "asc",
      },
      {
        field: "amount",
        label: "Pembiayaan",
        sort: "asc",
      },
      // {
      //   field: "name",
      //   label: "Nama",
      //   sort: "asc",
      // },
      // {
      //   field: "email",
      //   label: "Email",
      //   sort: "asc",
      // },
      // {
      //   field: "phone",
      //   label: "Telepon",
      //   sort: "asc",
      // },
      {
        field: "status",
        label: "Status",
        sort: "asc",
      },
      {
        field: "date",
        label: "Tanggal Pembatalan",
      },
      {
        field: "desc",
        label: "Deskripsi",
      },
      {
        field: "file",
        label: "Bukti Pembatalan",
      },
      {
        field: "refundCancel",
        label: "Aksi",
      },
    ],
    rows: map(refunds, item => ({
      ...item,
      date: moment(
        item.transaction_refund[item.transaction_refund.length - 1].date
      ).format("DD MMM YYYY"),
      desc: item.transaction_refund[item.transaction_refund.length - 1]
        .description,
      file: (
        <Button
          className="btn-sm"
          color="secondary"
          onClick={() =>
            window.open(
              `${API_URL}${
                item.transaction_refund[item.transaction_refund.length - 1].file
              }`,
              "_blank"
            )
          }
        >
          <i class="fas fa-eye"></i>
        </Button>
      ),
      campaign: item.campaign.title,
      amount: `Rp ${Number(item.amount).toLocaleString()}`,
      status: (
        <div>
          {item.status === 1 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 2 && <Badge color="danger">Belum Dibayar</Badge>}
          {item.status === 3 && (
            <Badge color="warning">Pembayaran Bertahap</Badge>
          )}
          {item.status === 4 && <Badge color="success">Sudah Dibayar</Badge>}
          {item.status === 5 && (
            <Badge color="warning">Pengembalian Bertahap</Badge>
          )}
          {item.status === 6 && (
            <Badge color="primary">Selesai Pengembalian Pokok</Badge>
          )}
          {item.status === 7 && (
            <Badge color="primary">Selesai Pengembalian dan Nisbah</Badge>
          )}
          {/* {item.status === 7 && <Badge color="primary">Selesai Pengembalian</Badge>}
                {item.status === 8 && <Badge color="primary">Selesai Pengembalian</Badge>} */}
          {item.status === 9 && <Badge color="info">Kadaluarsa</Badge>}
          {item.status === 10 && <Badge color="secondary">Dikembalikan</Badge>}
          {item.status === 11 && <Badge color="secondary">Dibatalkan</Badge>}
        </div>
      ),
      refundCancel: (
        <Button
          className="btn-sm"
          color="secondary"
          onClick={() => {
            setSelectRefund(item)
            setconfirm_alert2(true)
          }}
        >
          Batalkan
        </Button>
      ),
      // name: `${item.user.first_name} ${item.user.last_name}`,
      // email: item.user.email,
      // phone: item.user.phone_number,
      // status_doc_musyarakah: checkPrivy(item.privy_document_musyarakah),
      // status_doc_wakalah: checkPrivy(item.privy_document_wakalah),
    })),
  }

  const handleSubmit = async (e, value) => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postPayout(uuid.uuid, value, token)
      getSelectCampaign()
      toggleModal(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    }
  }

  const handleUpload = async () => {
    if (!file) return Toaster("error", "Ups", "Masukkan file excel")
    const formData = new FormData()
    formData.append("file", file)
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postImportPayout(formData, token)
      setFile(null)
      setLoadButt(false)
      if (select) {
        getSelectCampaign()
      }
      Toaster("success", "Sukses", "Berhasil unggah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error?.response?.data?.message || "Server Error")
    }
  }

  const handleEditPayout = e => {
    setEditedIdx({
      ...editedIdx,
      item: {
        ...editedIdx.item,
        [e.target.name]: e.target.value,
      },
    })
  }

  const saveEditPayout = async () => {
    const token = localStorage.getItem("token")
    const post = {
      repayment_type_id: null,
      amount_total: editedIdx.item.amount_total,
      date: editedIdx.item.date,
    }

    setLoadButt(true)
    try {
      const data = await postEditPayout(editedIdx.item.uuid, post, token)
      getSelectCampaign()
      let getResultNew = { ...uuid }
      getResultNew.repayment_transaction[editedIdx.index].amount_total =
        data.data.amount_total
      getResultNew.repayment_transaction[editedIdx.index].date = data.data.date
      setUuid(getResultNew)
      setEditedIdx({ item: null, index: null })
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const loadOptions = (inputValue, callback) => {
    const token = localStorage.getItem("token")
    let query = `?search=${inputValue}`
    setTimeout(async () => {
      const data = await getCampaignSelector(token, query)
      callback(
        data.data.map(item => {
          return { label: item.title, value: item.id }
        })
      )
    })
  }

  const handleDownloadInvoice = item => {
    // return console.log(uuid)
    const token = localStorage.getItem("token")
    setLoadButt(true)
    axios
      .get(
        `${API_URL_1}/payout/download/campaign-transaction/${uuid.uuid}/generate/${item.uuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url

        link.setAttribute(
          "download",
          `Invoice_${uuid?.trx_id}_${
            uuid?.creator?.first_name || uuid?.user?.first_name
          } ${uuid?.creator?.last_name || uuid?.user?.last_name}.pdf`
        )
        document.body.appendChild(link)
        link.click()
        setLoadButt(false)
      })
      .catch(err => {
        console.log(err)
        setLoadButt(false)
        Toaster("error", "Maaf", err?.response?.data?.message || "Server Error")
      })
  }

  const handleDownloadInvoiceAll = item => {
    // return console.log(item)
    const token = localStorage.getItem("token")
    setLoadButt(true)
    axios
      .get(
        `${API_URL_1}/payout/download/transaction/${select}/generate/${item.uuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `Invoice_All_${item?.trx_id}_${item?.creator?.first_name} ${item?.creator?.last_name}.pdf`
        )
        document.body.appendChild(link)
        link.click()
        setLoadButt(false)
      })
      .catch(err => {
        setLoadButt(false)
        Toaster("error", "Maaf", err?.response?.data?.message || "Server Error")
      })
    // console.log(uuid)
  }

  const handleCreateDisburse = async (e, value) => {
    const token = localStorage.getItem("token")
    let newValue = {
      ...value,
      file: fileDisburse,
    }

    if (!newValue.file) {
      delete newValue.file
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postDisburse(select, formData, token)
      toggleCreateDisburse()
      getSelectCampaign()
      setLoadButt(false)
      setFileDisburse(null)
      Toaster("success", "Sukses", "Berhasil dibuat")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleEditDisburse = async (e, value) => {
    const token = localStorage.getItem("token")
    let newValue = {
      ...value,
      file: fileDisburse,
    }

    if (!newValue.file) {
      delete newValue.file
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postUpdateDisburse(select, selectDisburse.id, formData, token)
      toggleEditDisburse(null)
      getSelectCampaign()
      setLoadButt(false)
      setFileDisburse(null)
      Toaster("success", "Sukses", "Berhasil dibuat")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleDelDisburse = async () => {
    const token = localStorage.getItem("token")
    // setLoadButt(true)
    setconfirm_alert(false)
    try {
      await delDisburse(select, selectDisburse.id, token)
      getSelectCampaign()
      // setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil dihapus")
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleModalRefund = async (e, value) => {
    const token = localStorage.getItem("token")
    const newValue = {
      ...value,
      file: fileRefund,
    }

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postRefund(selectRefund.uuid, formData, token)
      toggleModalRefund(null)
      getSelectCampaign()
      setLoadButt(false)
      setFileRefund(null)
      Toaster("success", "Sukses", "Transaksi berhasil dibatalkan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleDelRefund = async () => {
    // console.log(selectRefund)
    const token = localStorage.getItem("token")
    setconfirm_alert2(false)
    try {
      await delRefund(
        selectRefund.uuid,
        selectRefund.transaction_refund[
          selectRefund.transaction_refund.length - 1
        ].id,
        token
      )
      getSelectCampaign()
      Toaster("success", "Sukses", "Berhasil dihapus")
    } catch (error) {
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleDeletePayout = async id => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await delPayout(id, token)
      toggleModalDetail(null)
      getSelectCampaign()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil dihapus")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleDownloadTemplateUpdate = async () => {
    const token = localStorage.getItem("token")
    setLoadDownload(true)
    axios
      .get(`${API_URL_1}${GET_UPDATE_TEMPLATE}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Disposition":
            "attachment; filename=template_payout_update.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `template_payout_update.xlsx`)
        document.body.appendChild(link)
        link.click()
        setLoadDownload(false)
      })
      .catch(err => {
        setLoadDownload(false)
        Toaster("error", "Ups", err.response.data.message)
      })
  }

  const handleUploadUpdatePayout = async () => {
    if (!fileUpdate) return Toaster("error", "Ups", "Masukkan file excel")
    const formData = new FormData()
    formData.append("file", fileUpdate)
    const token = localStorage.getItem("token")
    setLoadDownload(true)
    try {
      await postImportUpdatePayout(formData, token)
      setFileUpdate(null)
      setLoadDownload(false)
      if (select) {
        getSelectCampaign()
      }
      Toaster("success", "Sukses", "Berhasil unggah data")
    } catch (error) {
      setLoadDownload(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  if (load) return <Loading title="Pengembalian" breadcrumbItem="Transaksi" />

  return (
    <React.Fragment>
      {renderModalDownload()}
      {renderModalDownloadAll()}
      {confirm_alert ? (
        <SweetAlert
          title="Apakah anda yakin?"
          warning
          showCancel
          confirmBtnText="Hapus"
          cancelBtnText="Batal"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={handleDelDisburse}
          onCancel={() => setconfirm_alert(false)}
        >
          Anda tidak akan dapat mengembalikan data ini!
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Apakah anda yakin?"
          warning
          showCancel
          confirmBtnText="Ya"
          cancelBtnText="Tidak"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          onConfirm={handleDelRefund}
          onCancel={() => setconfirm_alert2(false)}
        >
          {/* Anda tidak akan dapat mengembalikan data ini! */}
        </SweetAlert>
      ) : null}
      <Modal isOpen={modalDetail} role="dialog" autoFocus={true} size="lg">
        <ModalHeader toggle={() => toggleModalDetail(null)}>
          Detail Pengembalian (ID Transaksi {uuid && uuid.trx_id})
        </ModalHeader>
        <ModalBody>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Tanggal</th>
                <th scope="col">Jumlah</th>
                {!editedIdx.item && (
                  <>
                    <th scope="col">Pajak</th>
                    <th scope="col">Bukti Potong Pajak</th>
                    <th scope="col">Invoice</th>
                  </>
                )}
                <th scope="col" className="text-center">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {uuid &&
                uuid.repayment_transaction.map((item, i) => {
                  if (editedIdx.item && editedIdx.index === i) {
                    return (
                      <tr>
                        <td>
                          <InputGroup>
                            <Input
                              type="date"
                              name="date"
                              value={editedIdx.item.date}
                              onChange={handleEditPayout}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>Rp</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              name="amount_total"
                              placeholder="Jumlah"
                              value={parseInt(editedIdx.item.amount_total)}
                              onChange={handleEditPayout}
                            />
                          </InputGroup>
                        </td>

                        <td className="d-flex justify-content-center">
                          <Button
                            color="primary"
                            className="btn-sm btn-rounded mr-2"
                            onClick={saveEditPayout}
                            disabled={loadButt}
                          >
                            {loadButt ? (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                                Loading
                              </>
                            ) : (
                              "Simpan"
                            )}
                          </Button>
                          <Button
                            color="danger"
                            className="btn-sm btn-rounded"
                            onClick={() =>
                              setEditedIdx({ item: null, index: null })
                            }
                            disabled={loadButt}
                          >
                            Batal
                          </Button>
                        </td>
                      </tr>
                    )
                  }
                  return (
                    <tr>
                      <td>{moment(item.date).format("DD MMM YYYY")}</td>
                      <td>Rp {Number(item.amount_total).toLocaleString()}</td>
                      {!editedIdx.item && (
                        <>
                          <td>
                            {parseInt(item.tax) > 0 ? (
                              <p>
                                Rp {parseInt(item.total_tax).toLocaleString()}
                              </p>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              {item.payment_receipt_file ? (
                                <Button
                                  className="btn-sm btn-rounded"
                                  onClick={() =>
                                    window.open(
                                      `${API_URL}${item.payment_receipt_file}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <i className="fas fa-eye"></i>
                                </Button>
                              ) : (
                                <div>-</div>
                              )}

                              {parseInt(item.tax) > 0 ? (
                                <div className="d-flex align-items-center ml-2">
                                  <label
                                    for={`uploadTax-${i}`}
                                    className="cursor-pointer btn-sm btn-rounded py-1 px-2 m-0 bg-primary text-white font-weight-normal"
                                  >
                                    Unggah
                                  </label>
                                  <input
                                    type="file"
                                    name={`uploadTax-${i}`}
                                    id={`uploadTax-${i}`}
                                    className="d-none"
                                    onChange={async e => {
                                      const token =
                                        localStorage.getItem("token")
                                      const formData = new FormData()
                                      formData.append("file", e.target.files[0])

                                      if (e.target.files[0]) {
                                        try {
                                          const repaymentTax = await uploadTax(
                                            item.uuid,
                                            formData,
                                            token
                                          )
                                          let newRepayment = { ...uuid }
                                          newRepayment.repayment_transaction[
                                            i
                                          ] = repaymentTax.data
                                          setUuid(newRepayment)
                                          Toaster(
                                            "success",
                                            "Sukses",
                                            "Berhasil unggah bukti potong pajak"
                                          )
                                        } catch (error) {
                                          Toaster(
                                            "error",
                                            "Maaf",
                                            error?.response?.data?.message ||
                                              "Server Error"
                                          )
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </td>
                          <td>
                            <Button
                              color="primary"
                              className="btn-sm btn-rounded"
                              onClick={() => handleDownloadInvoice(item)}
                              disabled={loadButt}
                            >
                              Download
                            </Button>
                          </td>
                        </>
                      )}
                      <td className="d-flex justify-content-center">
                        <Button
                          color="secondary"
                          className="btn-sm btn-rounded mr-2"
                          onClick={() => setEditedIdx({ item: item, index: i })}
                          disabled={loadButt}
                          // disabled
                        >
                          Ubah
                        </Button>
                        <Button
                          color="danger"
                          className="btn-sm btn-rounded mr-2"
                          onClick={() => handleDeletePayout(item.uuid)}
                          disabled={loadButt}
                          // disabled
                        >
                          Hapus
                        </Button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModal(null)}>
          ID Transaksi {uuid && uuid.trx_id}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmit(e, v)
            }}
          >
            <PInput
              name="repayment_type_id"
              type="select"
              label="Tipe Pengembalian"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Tipe Pengembalian harus diisi",
                },
              }}
              options={paymentType}
            />
            <PInput
              name="amount_total"
              type="number"
              label="Total Pengembalian"
              helpMessage="Rp"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Total Pengembalian harus diisi",
                },
              }}
            />
            <PInput
              name="date"
              type="date"
              label="Tanggal pengembalian"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Tanggal pengembalian harus diisi",
                },
              }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalCreateDisburse} role="dialog" autoFocus={true}>
        <ModalHeader toggle={toggleCreateDisburse}>Buat Pencairan</ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateDisburse(e, v)
            }}
          >
            <PInput
              name="amount"
              type="number"
              label="Jumlah"
              helpMessage="Rp"
              validate={{
                required: { value: true, errorMessage: "Jumlah harus diisi" },
              }}
            />
            <PInput
              name="date"
              type="date"
              label="Tanggal"
              validate={{
                required: { value: true, errorMessage: "Tanggal harus diisi" },
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi pengembalian harus diisi",
                },
              }}
            />
            <PInput
              name="file"
              type="file"
              label="Bukti Pencairan"
              onChange={e => setFileDisburse(e.target.files[0])}
              value={fileDisburse}
              helpMessage="jpg, jpeg, png atau pdf"
              // validate={{
              //   required: { value: true, errorMessage: "Berkas harus diisi" },
              // }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalEditDisburse} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleEditDisburse(null)}>
          Ubah Pencairan
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleEditDisburse(e, v)
            }}
          >
            <PInput
              name="amount"
              type="number"
              label="Jumlah"
              helpMessage="Rp"
              value={selectDisburse ? selectDisburse.amount : ""}
              validate={{
                required: { value: true, errorMessage: "Jumlah harus diisi" },
              }}
            />
            <PInput
              name="date"
              type="date"
              label="Tanggal"
              value={selectDisburse ? selectDisburse.date : ""}
              validate={{
                required: { value: true, errorMessage: "Tanggal harus diisi" },
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              value={selectDisburse ? selectDisburse.description : ""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi pengembalian harus diisi",
                },
              }}
            />
            <PInput
              name="file"
              type="file"
              label="Bukti Pencairan"
              onChange={e => setFileDisburse(e.target.files[0])}
              value={fileDisburse}
              helpMessage="jpg, jpeg, png atau pdf"
              // validate={{
              //   required: {value: true, errorMessage: 'Berkas harus diisi'},
              // }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRefund} role="dialog" autoFocus={true}>
        <ModalHeader
          toggle={() => {
            setFileRefund(null)
            toggleModalRefund(null)
          }}
        >
          Pembatalan Transaksi
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleModalRefund(e, v)
            }}
          >
            <PInput
              name="date"
              type="date"
              label="Tanggal Pembatalan"
              validate={{
                required: { value: true, errorMessage: "Tanggal harus diisi" },
              }}
            />
            <PInput
              name="description"
              type="textarea"
              label="Deskripsi"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi pengembalian harus diisi",
                },
              }}
            />
            <PInput
              name="file"
              type="file"
              label="Bukti Pembatalan"
              onChange={e => setFileRefund(e.target.files[0])}
              value={fileRefund}
              helpMessage="jpg, jpeg, png atau pdf"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Bukti Pembatalan harus diisi",
                },
              }}
            />
            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pengembalian" breadcrumbItem="Transaksi" />
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup className="select2-container" row>
                <Label sm="3">Pilih Kampanye</Label>
                <Col sm="8">
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={e => {
                      setSelect(e.value)
                      setSelected(e)
                    }}
                    value={selected}
                    // onInputChange={handleInputSearch}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col lg={4}>
              <div className="">
                <div className="mr-3">
                  {/* <label>Unggah Pengembalian</label> */}
                  {/* <Input 
                    type="file" 
                    onChange={(e) => setFile(e.target.files[0])}
                  /> */}
                  <div className="custom-file mb-2">
                    <input
                      type="file"
                      className="custom-file-input cp-custom"
                      id="customFile"
                      onChange={e => setFile(e.target.files[0])}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFile"
                      style={{ color: "#636c72" }}
                    >
                      {file ? file.name : "Pilih Berkas"}
                    </label>
                  </div>
                </div>
                <Button
                  color="primary"
                  onClick={handleUpload}
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Unggah Pengembalian"
                  )}
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {cmpgn && (
                    <div className="row">
                      <div className="col-md-12">
                        <table class="table table-striped border">
                          <thead>
                            <tr className="full-width-tbl">
                              <th scope="col">Total Pembiayaan</th>
                              <th scope="col">Total Estimasi Nisbah</th>
                              <th scope="col">Total Estimasi Pengembalian</th>
                              <th scope="col">Total Pengembalian</th>
                              <th scope="col">Persentase Pengembalian Ethis</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="full-width-tbl">
                              <td>
                                Rp{" "}
                                {cmpgn
                                  ? cmpgn.collected
                                    ? parseInt(cmpgn.collected).toLocaleString()
                                    : "0"
                                  : "0"}
                              </td>
                              <td>
                                {!cmpgn
                                  ? "-"
                                  : `Rp ${cmpgn.getEstimateTotalNisbah?.toLocaleString()}`}
                              </td>
                              <td>
                                {!cmpgn
                                  ? "-"
                                  : `Rp ${cmpgn.estimate_repayment?.toLocaleString()}`}
                              </td>

                              <td>
                                {!cmpgn
                                  ? "-"
                                  : `Rp ${Number(
                                      cmpgn?.getTotalRepayment
                                    ).toLocaleString()}`}
                              </td>
                              <td>
                                {!cmpgn
                                  ? "-"
                                  : `${cmpgn.getPersentaseRepaymentEthis}`}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-12 d-flex align-items-start justify-content-end">
                        <Button onClick={toggleModalDownload} className="mr-3">
                          Unduh Pengembalian
                        </Button>
                        <Button
                          onClick={toggleModalDownloadInvoiceAll}
                          disabled={loadButtDownload}
                        >
                          {loadButtDownload ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                              Loading
                            </>
                          ) : (
                            "Unduh Semua Instruksi Bayar"
                          )}
                        </Button>
                      </div>
                    </div>
                  )}

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Pengembalian
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat Pengembalian
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Pencairan
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        Pembatalan Transaksi
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5")
                        }}
                      >
                        Riwayat Pembatalan Transaksi
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1" id="all-order">
                      <MDBDataTable
                        responsive
                        bordered
                        data={data}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="2" id="processing">
                      <div className="w-25 mt-3">
                        <div className="">
                          <div
                            className="custom-file mb-2"
                            style={{ zIndex: 0 }}
                          >
                            <input
                              type="file"
                              className="custom-file-input cp-custom"
                              id="customFileUpdate"
                              onChange={e => setFileUpdate(e.target.files[0])}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFileUpdate"
                              style={{ color: "#636c72" }}
                            >
                              {fileUpdate ? fileUpdate.name : "Pilih Berkas"}
                            </label>
                          </div>
                        </div>
                        <Button
                          color="primary"
                          className="w-100 btn-sm mb-2"
                          onClick={handleUploadUpdatePayout}
                          disabled={loadDownload}
                        >
                          Update Pengembalian
                        </Button>
                        <Button
                          color="primary"
                          className="w-100 btn-sm"
                          onClick={handleDownloadTemplateUpdate}
                          disabled={loadDownload}
                        >
                          Unduh Template
                        </Button>
                      </div>
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataHistory}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      {cmpgn && (
                        <div className="mt-3">
                          <table class="table table-striped border">
                            <thead>
                              <tr className="full-width-tbl">
                                <th scope="col">Nama</th>
                                <th scope="col">Email</th>
                                <th scope="col">Telepon</th>
                                <th scope="col">Bank</th>
                                <th scope="col">No Rekening</th>
                                {/* <th scope="col">Total Pembiayaan</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {cmpgn.project.user.first_name}{" "}
                                  {cmpgn.project.user.last_name}
                                </td>
                                <td>{cmpgn.project.user.email}</td>
                                <td>{cmpgn.project.user.phone_number}</td>
                                <td>
                                  {cmpgn.project.user.account[0]
                                    ? cmpgn.project.user.account[0].bank_account
                                        .name
                                    : "-"}
                                </td>
                                <td>
                                  {cmpgn.project.user.account[0]
                                    ? cmpgn.project.user.account[0]
                                        .account_number
                                    : "-"}
                                </td>
                                {/* <td>Rp {cmpgn ? cmpgn.collected ? parseInt(cmpgn.collected).toLocaleString() : "0" : "0"}</td> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {select && (
                        <div className="d-flex justify-content-end mt-3">
                          <Button onClick={toggleCreateDisburse}>
                            + Buat Pencairan
                          </Button>
                        </div>
                      )}
                      <MDBDataTable
                        responsive
                        bordered
                        data={disburseDatas}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataRefund}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                    <TabPane tabId="5">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataRefundHistory}
                        striped
                        className="mt-4"
                        disableRetreatAfterSorting
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Payout
