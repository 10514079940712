import {
    GET_PROFILE
  } from "./actionTypes"
  
  const initialState = {
    profile: null
  }
  
  const profileUser = (state = initialState, action) => {
    switch (action.type) {
      case GET_PROFILE:
        state = {
          ...state,
          profile: action.payload,
        }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default profileUser
  