export const handleChange = (event, value, setValue) => {
  setValue({
    ...value,
    [event.target.name]: event.target.value,
  })
}

export const timeDiffCalc = (dateFuture, dateNow) => {
  dateFuture = new Date(dateFuture)
  dateNow = new Date(dateNow)
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400)
  diffInMilliSeconds -= days * 86400
  // console.log('calculated days', days);

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24
  diffInMilliSeconds -= hours * 3600
  // console.log('calculated hours', hours);

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60
  diffInMilliSeconds -= minutes * 60
  // console.log('minutes', minutes);

  let difference = ""
  if (days > 0) {
    difference += days === 1 ? `${days} Hari, ` : `${days} Hari, `
  }

  difference += hours === 0 || hours === 1 ? `${hours} Jam, ` : `${hours} Jam, `

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} Menit` : `${minutes} Menit`

  return difference
}

export const handleStatusCampaign = (
  type,
  isPaidOff,
  isHaveRepayments,
  status
) => {
  if (type === "color") {
    if (isPaidOff) return "success"

    if (isHaveRepayments) return "info"

    switch (status) {
      case 9:
        return "success"
      case 3:
        return "info"
      case 4:
        return "primary"
      case 7:
      case 8:
      case 6:
      case 2:
        return "warning"
      case 11:
      case 10:
      case 5:
      case 1:
        return "danger"
      default:
        return "info"
    }
  }

  if (type === "label") {
    if (isPaidOff) return "Selesai"

    if (isHaveRepayments) return "Proses Pengembalian"

    switch (status) {
      case 1:
        return "Kelola Kampanye"
      case 2:
        return "Kelola Akad"
      case 3:
        return "Siap Terbit"
      case 4:
        return "Proses Pendanaan"
      case 5:
      case 10:
        return "Tidak Aktif"
      case 6:
      case 7:
      case 8:
        return "Sedang Berjalan"
      case 9:
        return "Selesai"
      case 11:
        return "Dibatalkan"
      default:
        return "-"
    }
  }

  return null
}
