import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo_ from "assets/images/brands-ethis/logo.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"
import axios from "axios"
import { API_URL_1 } from "helpers/api_url"
import OtpInput from "react-otp-input"
import {
  CLAIM_CODE_REGISTER_EMAIL,
  POST_CODE_REGISTER_EMAIL,
} from "helpers/url_helpers"
import Toaster from "components/Common/Toast"
import moment from "moment"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  const [otp, setOtp] = useState("")
  const [timeLeft, setTimeLeft] = useState(60)
  const [modalOtpEmail, setModalOtpEmail] = useState(false)
  const [timer, setTimer] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [resendCodeLoad, setResendCodeLoad] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  // useEffect(() => {
  //   if(props.error && props.error.code === 433) {
  //     setModalOtpEmail(true)
  //     setTimer(true)
  //   }
  // }, [props.error])

  useEffect(() => {
    const intervalId = setInterval(() => updateCountdown(), 1000)
    return () => clearInterval(intervalId)
  }, [timeLeft, timer])

  const updateCountdown = () => {
    if (timer && timeLeft > 0) {
      setTimeLeft(timeLeft - 1)
    } else {
      setTimer(false)
    }
  }

  const handleSubmitCode = async () => {
    setLoadButt(true)
    try {
      await axios.post(API_URL_1 + CLAIM_CODE_REGISTER_EMAIL, {
        email: email,
        code: otp,
      })
      setTimeLeft(0)
      setTimer(false)
      setModalOtpEmail(false)
      setOtp("")
      setEmail("")
      setPassword("")
      setLoadButt(false)
      Toaster("success", "Terima Kasih", "Akun anda telah terverifikasi.")
      // props.history.push("/login")
      props.loginUser({ email, password }, props.history)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleResendCodeEmail = async () => {
    if (timer && timeLeft > 0) return
    setResendCodeLoad(true)
    try {
      const resCode = await axios.post(API_URL_1 + POST_CODE_REGISTER_EMAIL, {
        email: email,
      })
      setResendCodeLoad(false)
      setTimeLeft(
        moment(resCode.data.expired).diff(moment(resCode.data.datetime)) / 1000
      )
      setTimer(true)
      Toaster(
        "success",
        "Verifikasi Kode",
        "Silahkan cek kode verifikasi anda melalui email."
      )
    } catch (error) {
      setResendCodeLoad(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleSendCodeEmail = async () => {
    // if(timer && timeLeft > 0) return;
    setResendCodeLoad(true)
    try {
      const res = await axios.post(API_URL_1 + POST_CODE_REGISTER_EMAIL, {
        email: email,
      })
      setResendCodeLoad(false)
      setModalOtpEmail(true)
      setTimeLeft(
        moment(res.data.expired).diff(moment(res.data.datetime)) / 1000
      )
      setTimer(true)
      Toaster(
        "success",
        "Verifikasi Kode",
        "Silahkan cek kode verifikasi anda melalui email."
      )
    } catch (error) {
      setResendCodeLoad(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderTimeLeft = () => {
    let min = Math.floor(timeLeft / 60)
    let sec = timeLeft - min * 60

    return ` ${String(min).length === 1 ? `0${min}` : min} : ${
      String(sec).length === 1 ? `0${sec}` : sec
    }`
  }

  // console.log(props)

  const renderModalOtpEmail = () => {
    return (
      <Modal
        isOpen={modalOtpEmail}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // scrollable={true}
        // size="lg"
      >
        <div className="modal-content">
          <ModalHeader className="">Masukkan Kode Verifikasi Email</ModalHeader>
          <ModalBody>
            <div className="pb-2">
              <div className="pb-4">
                <div>
                  Silahkan masukkan kode verifikasi telah dikirimkan melalui
                  email Anda
                </div>
                {/* <div>Salah nomor? klik disini untuk mengganti nomor</div> */}
              </div>
              <OtpInput
                value={otp}
                onChange={e => setOtp(e)}
                numInputs={6}
                separator={"  "}
                containerStyle="justify-content-center"
                inputStyle="inputStyleOtp"
                hasErrored
                isInputNum
                shouldAutoFocus
              />
              <div className="text-center mt-4">
                <Button
                  color="primary"
                  // className="btn-sm"
                  onClick={handleSubmitCode}
                  disabled={loadButt || otp.length !== 6}
                  color={`${otp.length !== 6 ? "secondary" : "primary"}`}
                  style={{
                    cursor: `${otp.length !== 6 ? "not-allowed" : "pointer"}`,
                  }}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Konfirmasi"
                  )}
                </Button>
                <div className="mt-3" onClick={handleResendCodeEmail}>
                  {resendCodeLoad ? (
                    <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                  ) : (
                    <div
                      className={`${
                        !timer &&
                        "send-code-email text-primary font-weight-bold"
                      }`}
                    >
                      Kirim Ulang Kode
                    </div>
                  )}
                </div>
                <div className="mt-1">
                  <div>{renderTimeLeft()}</div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      {renderModalOtpEmail()}
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages pt-3 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Selamat Datang</h5>
                        <p>Silahkan Masuk</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-1">
                      <span className="avatar-title rounded-circle bg-white">
                        <img
                          src={logo_}
                          alt="Logo"
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "object" ? (
                        <>
                          {props.error.message === "Unauthenticated." ? null : (
                            <Alert color="danger">
                              {props.error.code === 433 ? (
                                <span>
                                  Email anda belum terverifikasi, lakukan
                                  verifikasi ulang dengan klik{" "}
                                  {!resendCodeLoad ? (
                                    <b
                                      className="cursor-pointer verification-hover"
                                      onClick={handleSendCodeEmail}
                                    >
                                      disini
                                    </b>
                                  ) : (
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                                  )}
                                  .
                                </span>
                              ) : (
                                props.error.message
                              )}
                            </Alert>
                          )}
                        </>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          // value=""
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          onChange={e => setEmail(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Email harus diisi",
                            },
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Kata Sandi"
                          // value=""
                          type="password"
                          placeholder="Kata Sandi"
                          onChange={e => setPassword(e.target.value)}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Kata Sandi harus diisi",
                            },
                          }}
                        />
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={props.loading}
                        >
                          {props.loading ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                              Loading
                            </>
                          ) : (
                            "Masuk"
                          )}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forget-password" className="text-muted">
                          <i className="mdi mdi-lock mr-1" />
                          Lupa kata sandi?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  Belum punya akun ?{" "}
                  <Link
                    to="register"
                    className="font-weight-medium text-primary"
                  >
                    {" "}
                    Daftar{" "}
                  </Link>{" "}
                </p>
                <div className="text-muted">
                  {new Date().getFullYear()} © PT Ethis Fintek Indonesia{" "}
                  {/* <i className="mdi mdi-heart text-danger"/> */}
                </div>
                <p className="text-muted">Version 1.4.5</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
