import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import { post } from "helpers/api_helpers"
import { POST_APPROVAL } from "helpers/url_helpers"
import { postData } from "helpers/api_helper_local"
import Toaster from "components/Common/Toast"

const ModalApprove = props => {
  const {isOpen, toggle, user, getUsers, reason} = props

  const [etc, setEtc] = useState('')
  const [description, setDescription] = useState('')
  const [err, setErr] = useState([])
  const [loadButt, setLoadButt] = useState(false)

  useEffect(() => {
    if(isOpen) {
      setErr([])
    }
  }, [isOpen])

  const handleValidSubmit = async (e, value) => {
    if(etc === handleSelectReason() && !description) {
      return Toaster("error", "Ups", "Deskripsi harus di isi")
    }
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postData(`${POST_APPROVAL}/${user.uuid}?approve=true`, {...value, }, token)
      toggle()
      setEtc("")
      setDescription("")
      getUsers()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Disetujui")
    } catch (error) {
      Toaster("error", "Ups", "Gagal Disetujui")
      setErr(error.response.data.message.errors)
      setLoadButt(false)
    }
  }

  const handleReject = async () => {
    if(!etc) return Toaster("error", "Ups", "Masukkan alasannya")
    if(etc === handleSelectReason() && !description) {
      return Toaster("error", "Ups", "Deskripsi harus di isi")
    }

    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postData(`${POST_APPROVAL}/${user.uuid}?approve=false`, {
        reason_id: etc,
        description: description ? description : null
      }, token)
      toggle()
      setEtc("")
      setDescription("")
      getUsers()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Ditolak")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
      // Toaster("error", "Ups", "Masukkan Alasannya")
    }
  }

  const handleSelect = (e) => {
    setEtc(e.target.value)
  }

  const handleSelectReason = () => {
    for(let i = 0; i < reason.length; i++) {
      if(reason[i].title === "Lain-nya") {
        return reason[i].uuid
      }
    }
  }

  const handleDisableAppoval = () => {
    let result = {
      rejectedId: [],
      appovedId: [],
      etcId: [],
    }
    for(let i = 0; i < reason.length; i++) {
      if(reason[i].title == "Lain-nya") {
        result.etcId.push(reason[i].uuid)
      } else if (reason[i].title == "Data cukup jelas.") {
        result.appovedId.push(reason[i].uuid)
      } else {
        result.rejectedId.push(reason[i].uuid)
      }
    }
    return result
  }
  
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      // centered={true}
      className="exampleModal"
      tabindex="-1"
      // toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Persetujuan KYC</ModalHeader>
        <ModalBody>
          <AvForm 
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            <div className="form-group">
              <AvField
                name="reason_id"
                label="Alasan"
                type="select"
                onChange={handleSelect}
                validate={{
                  required: {value: true, errorMessage: 'Alasan harus diisi'},
                }}
              >
                <option value="">Pilih</option>
                {reason.map(item => {
                  return <option value={item.uuid}>{item.title}</option>
                })}
              </AvField>
            </div>
            {
              err.length > 0 && 
              err.map((item, i) => {
                return <div style={{color: 'red'}}>{`${i + 1}. ${item.messages[0]}`}</div>
              })
            }
            {etc === handleSelectReason() && 
              <div className="form-group">
                <AvField
                  name="description"
                  label="Deskripsi"
                  type="textarea"
                  placeholder=""
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  validate={{
                    required: {value: true, errorMessage: 'Deskripsi harus diisi'},
                  }}
                />
              </div>
            }
            <div className="mt-4">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt || handleDisableAppoval().rejectedId.includes(etc) || handleDisableAppoval().etcId.includes(etc) ? true : false}
                style={{cursor: `${handleDisableAppoval().rejectedId.includes(etc) || handleDisableAppoval().etcId.includes(etc) ? "not-allowed" : "pointer"}`}}
              >
                {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i></> : 'Disetujui'}
              </button>
            </div>
            
          </AvForm>
          <div className="mt-3">
            <button
              className="btn btn-danger btn-block waves-effect waves-light"
              onClick={handleReject}
              disabled={loadButt || handleDisableAppoval().appovedId.includes(etc)}
              style={{cursor: `${handleDisableAppoval().appovedId.includes(etc) ? "not-allowed" : "pointer"}`}}
            >
              {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i></> : 'Ditolak'}
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalApprove