import React, { useEffect, useState } from "react"

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { connect } from "react-redux"

import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PInput from "components/Input/PInput"
import DataTable from "components/Common/DataTable"

import {
  createRefferer,
  getRefferer,
  getUserRefferer,
  updateRefferer,
} from "helpers/backend_helpers"

const Refferer = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const [refferer, setRefferer] = useState([])
  const [modalRefferer, setModalRefferer] = useState(false)
  const [selectRefferer, setSelectRefferer] = useState(null)

  const [userRefferer, setUserRefferer] = useState([])

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)
  const [pagination, setPagination] = useState(null)

  const toggleModalRefferer = item => {
    setSelectRefferer(item)
    setModalRefferer(!modalRefferer)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    getData()
  }, [activeTab, page, perPage])

  const getData = async () => {
    setLoad(true)
    let query = `?per_page=${perPage}&page=${page}`
    if (activeTab === "1") {
      const ref = await getRefferer(token, query)
      setTotalPage(ref.meta.pagination.last_page)
      setCurrentPage(ref.meta.pagination.current_page)
      setPagination(ref.meta.pagination)
      setRefferer(ref.data)
    } else {
      const userRef = await getUserRefferer(token, query)
      setTotalPage(userRef.meta.pagination.last_page)
      setCurrentPage(userRef.meta.pagination.current_page)
      setPagination(userRef.meta.pagination)
      setUserRefferer(userRef.data)
    }
    setLoad(false)
  }

  const tableRefferer = {
    columns: [
      {
        field: "title",
        label: "Judul",
      },
      {
        field: "status",
        label: "Status",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: refferer.map(item => {
      return {
        ...item,
        status: Boolean(item.isActive) ? (
          <Badge color="primary">Aktif</Badge>
        ) : (
          <Badge color="danger">Tidak Aktif</Badge>
        ),
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm mr-2"
              color="secondary"
              onClick={() => toggleModalRefferer(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
            {/* <Button
              className="btn-sm"
              color="danger"
              onClick={() => toggleModalDeleteBlog(item)}
            >
              <i className="bx bx-trash"></i>
            </Button> */}
          </div>
        ),
      }
    }),
  }

  const tableUserRefferer = {
    columns: [
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "refferers",
        label: "Referensi",
      },
      {
        field: "descriptions",
        label: "Deskripsi",
      },
    ],
    rows: userRefferer.map(item => {
      return {
        name: item?.user?.fullName,
        refferers: item?.refferer?.title || "",
        descriptions: item.description ? item.description : "-",
        ...item,
      }
    }),
  }

  const handleSubmitRefferer = async (e, value) => {
    setLoadButt(true)
    try {
      if (selectRefferer) {
        await updateRefferer(selectRefferer.id, value, token)
      } else {
        await createRefferer(value, token)
      }
      toggleModalRefferer()
      setLoadButt(false)
      getData()
      Toaster("success", "Sukses", "Berhasi tambah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderCreateRefferer = () => {
    return (
      <Modal isOpen={modalRefferer} role="dialog" autoFocus={true} scrollable>
        <ModalHeader toggle={() => toggleModalRefferer(null)}>
          {selectRefferer ? "Ubah" : "Tanbah"} Refferer
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitRefferer(e, v)
            }}
          >
            <PInput
              name="title"
              type="text"
              label="Judul (ID)"
              value={selectRefferer?.title}
              validate={{
                required: { value: true, errorMessage: "Nama harus diisi" },
              }}
            />

            <PInput
              name="title_en"
              type="text"
              label="Judul (EN)"
              value={selectRefferer?.title_en}
              validate={{
                required: { value: true, errorMessage: "Nama harus diisi" },
              }}
            />
            <PInput
              name="isActive"
              type="select"
              label="Status"
              value={selectRefferer?.isActive ? "1" : "0"}
              validate={{
                required: { value: true, errorMessage: "Status harus diisi" },
              }}
              options={[
                { id: "1", title: "Aktif" },
                { id: "0", title: "Tidak Aktif" },
              ]}
            />

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading title="Rujukan" breadcrumbItem="Rujukan" />

  return (
    <React.Fragment>
      {renderCreateRefferer()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Rujukan" breadcrumbItem="Rujukan" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                          setPage(1)
                          setPerPage(10)
                        }}
                      >
                        Daftar Rujukan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                          setPage(1)
                          setPerPage(10)
                        }}
                      >
                        Rujukan Pengguna
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={() => toggleModalRefferer(null)}>
                          + Tambah Refferer
                        </Button>
                      </div>

                      <DataTable
                        table={tableRefferer}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <DataTable
                        table={tableUserRefferer}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Refferer)
