import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import {
  getAdditional,
  getResultMeet,
  postAdditional,
  postAnswerMeet,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"

import PuffLoader from "react-spinners/PuffLoader"

const init_form_bd = {
  title: "",
  description: "",
  file: null,
}

const ModalMeetHis = props => {
  const { isOpen, toggle, item, question, getProjectAdmin } = props

  const [activeTab, setActiveTab] = useState("1")
  const [loadButtMeet, setLoadButtMeet] = useState(false)
  const [resultMeet, setResultMeet] = useState(null)
  const [form, setForm] = useState(false)
  const [formBisdev, setFormBisdev] = useState(init_form_bd)
  const [additionalData, setAdditionalData] = useState([])
  const [load, setLoad] = useState(false)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    if (item) {
      setAdditionalData(item.additional_data)
    }
  }, [item])

  useEffect(() => {
    getData()
    // getAdditionalData()
  }, [item])

  const getData = async () => {
    if (item) {
      const token = localStorage.getItem("token")
      setLoad(true)
      const data = await getResultMeet(item.uuid, token)
      setResultMeet(data.data)
      setLoad(false)
    }
  }

  // console.log(additionalData)

  const getAdditionalData = async () => {
    const token = localStorage.getItem("token")
    const data = await getAdditional(token)
    setAdditionalData(data.data)
  }

  const handleSubmit = async (e, value) => {
    const token = localStorage.getItem("token")
    const postArray = []
    Object.keys(value).forEach(el => {
      postArray.push({
        bd_question_id: el,
        bd_question_option_id:
          value[el].split("-").length === 5 ? value[el] : null,
        value: value[el].split("-").length === 5 ? null : value[el],
      })
    })
    // console.log(postArray)

    setLoadButtMeet(true)
    try {
      await postAnswerMeet(item.uuid, { answers: postArray }, token)
      // toggleTab("2")
      // toggle(null)
      setLoadButtMeet(false)
      getProjectAdmin()
      Toaster("success", "Sukses", "Data tersimpan")
    } catch (error) {
      setLoadButtMeet(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleHelpMessage = title => {
    if (!title) return null
    if (title === "kontribusi-modal") return "Dalam Persen (Max. 95%)"
    if (title === "direktur") return "1 - 20"
    if (title === "bisDev") return "1 - 20"
  }

  const handleValue = value => {
    if (!resultMeet) return ""
    for (let i = 0; i < resultMeet.length; i++) {
      let resultMeetId = resultMeet[i].question.id
      if (resultMeetId == value.id) {
        return resultMeet[i].answer
          ? resultMeet[i].answer.id
          : resultMeet[i].value
      }
    }
    return ""
  }

  const handleMaxMin = title => {
    if (title == "bisDev" || title == "direktur") {
      return {
        max: { value: 20, errorMessage: "Maksimal 20" },
        min: { value: 1, errorMessage: "Minimal 1" },
      }
    }
    if (title == "kontribusi-modal") {
      return {
        max: { value: 95, errorMessage: "Maksimal 95%" },
        min: { value: 1, errorMessage: "Minimal 1%" },
      }
    }
    return {}
  }
  const handleSubmitAdd = async (e, value) => {
    // console.log(formBisdev)
    const token = localStorage.getItem("token")

    const formData = new FormData()
    Object.keys(formBisdev).map(item => {
      formData.append(item, formBisdev[item])
    })

    setLoadButtMeet(true)
    try {
      const data = await postAdditional(item.uuid, formData, token)
      console.log(data.data)
      setLoadButtMeet(false)
      setFormBisdev(init_form_bd)
      setForm(false)
      getProjectAdmin()
      // setAdditionalData([...item.additional_data, data.data])
      toggle(null)
      Toaster("success", "Sukses", "Berhasil tambah data")
    } catch (error) {
      setLoadButtMeet(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  // console.log(additionalData)

  const renderFormAdditional = () => {
    return (
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleSubmitAdd(e, v)
        }}
      >
        <Row>
          <Col lg="6">
            <PInput
              type="text"
              name="title"
              placeholder="Judul"
              label="Judul"
              onChange={e =>
                setFormBisdev({ ...formBisdev, title: e.target.value })
              }
              value={formBisdev.title}
              validate={{
                required: { value: true, errorMessage: "Field harus diisi" },
              }}
            />
          </Col>
          <Col lg="6">
            <PInput
              type="text"
              name="description"
              placeholder="Deskripsi"
              label="Deskripsi"
              onChange={e =>
                setFormBisdev({ ...formBisdev, description: e.target.value })
              }
              value={formBisdev.description}
              validate={{
                required: { value: true, errorMessage: "Field harus diisi" },
              }}
            />
          </Col>
          <Col lg="6">
            <PInput
              type="file"
              name="file"
              label="Unggah Data"
              onChange={e =>
                setFormBisdev({
                  ...formBisdev,
                  file: e.target.files[0],
                })
              }
              value={formBisdev.file}
              validate={{
                required: { value: false, errorMessage: "Field harus diisi" },
              }}
            />
          </Col>
        </Row>
        <div className="mt-3">
          <button
            className="btn btn-secondary btn-block waves-effect waves-light"
            type="submit"
            disabled={loadButtMeet}
            // onClick={handleSubmitAdd}
          >
            {loadButtMeet ? (
              <>
                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                Loading
              </>
            ) : (
              "Simpan"
            )}
            {/* Simpan */}
          </button>
        </div>
      </AvForm>
    )
  }

  const renderFormBisdev = () => {
    return (
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleSubmit(e, v)
        }}
      >
        <Row>
          {question.map((item, idx) => {
            if (
              item.title == "direktur" ||
              item.title == "bisDev" ||
              item.title == "jaminan"
            )
              return null
            return (
              <Col
                md={item.title === "kesimpulan-meeting" ? "12" : "6"}
                key={idx}
              >
                <PInput
                  type={
                    item.type === "option"
                      ? "select"
                      : item.title === "kesimpulan-meeting"
                      ? "textarea"
                      : "number"
                  }
                  name={item.id}
                  // placeholder={item.title.split("-").map(el => el[0].toUpperCase() + el.slice(1)).join(" ")}
                  label={item.title
                    .split("-")
                    .map(el => el[0].toUpperCase() + el.slice(1))
                    .join(" ")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Field harus diisi",
                    },
                    ...handleMaxMin(item.title),
                  }}
                  options={item.bd_question_option}
                  helpMessage={handleHelpMessage(item.title)}
                  value={handleValue(item)}
                />
              </Col>
            )
          })}
        </Row>

        {/* <div className="mt-3">
          <button
            className="btn btn-primary btn-block waves-effect waves-light"
            type="submit"
            disabled={loadButtMeet}
          >
            {loadButtMeet ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Simpan'}
          </button>
        </div> */}
      </AvForm>
    )
  }

  const renderAdditionalBisdev = () => {
    return (
      <>
        {/* <div className="d-flex justify-content-end mb-2">
          {!form ? 
            <Button 
              color="secondary"
              className="btn-sm"
              onClick={() => setForm(true)}
            >
              + Tambah
            </Button> :
            <Button 
              color="danger"
              className="btn-sm"
              onClick={() => setForm(false)}
            >
              Batal
            </Button>
          }
        </div> */}
        {form ? (
          renderFormAdditional()
        ) : (
          <table class="table table-striped border mt-3">
            <thead>
              <tr>
                <th scope="col">Judul</th>
                <th scope="col">Deskripsi</th>
                <th scope="col">Data</th>
              </tr>
            </thead>
            <tbody>
              {additionalData && additionalData.length > 0 ? (
                additionalData.map(item => {
                  return (
                    <tr>
                      <td>{item.title}</td>
                      <td>{item.description}</td>
                      <td>
                        {item.file ? (
                          <Button
                            type="button"
                            // color="primary"
                            className="btn-sm"
                            onClick={() =>
                              window.open(API_URL + item.file, "_blank")
                            }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className="text-center">
                  <td colSpan="3">Belum Ada Data Tambahan</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    )
  }

  // console.log(item)

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      scrollable={true}
      size="lg"
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggle(null)
            setResultMeet(null)
            // toggleTab("1")
          }}
        >
          {item && item.title}
        </ModalHeader>
        <ModalBody>
          <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                Rapat
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Data Tambahan
              </NavLink>
            </NavItem>
          </ul>
          <TabContent activeTab={activeTab} className="p-3 border">
            <TabPane tabId="1">
              {/* {renderFormBisdev()} */}
              {load ? (
                <div
                  style={{ height: "50vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PuffLoader color={"#0ec6a2"} loading={true} size={120} />
                </div>
              ) : (
                renderFormBisdev()
              )}
            </TabPane>
            <TabPane tabId="2">{renderAdditionalBisdev()}</TabPane>
          </TabContent>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalMeetHis
