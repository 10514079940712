import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  CardTitle,
  CardText,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { withRouter } from "react-router-dom"

import makeAnimated from "react-select/animated"
import Select from "react-select"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/brands-ethis/logo.png"
import axios from "axios"
import { API_URL_1 } from "helpers/api_url"
import handleErrorMessage from "helpers/handle_error_messages"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import OtpInput from "react-otp-input"

import flag_ina from "../../assets/images/flags/flag_ina.png"
import Toaster from "components/Common/Toast"
import {
  CLAIM_CODE_REGISTER_EMAIL,
  POST_CODE_REGISTER_EMAIL,
  POST_REGISTER,
} from "helpers/url_helpers"
import { postCodeRegisterEmail } from "helpers/backend_helpers"
import moment from "moment"

const animatedComponents = makeAnimated()

const Register = props => {
  // const [selectRoles, setSelectRoles] = useState(null)
  const [roles, setRoles] = useState([])
  const [key, setKey] = useState(1)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modalOtp, setModalOtp] = useState(false)
  const [checklistForm1, setChecklistForm1] = useState(false)
  const [checklistForm2, setChecklistForm2] = useState(false)
  const [daftar, setDaftar] = useState(null)
  const [roleRegis, setRoleRegis] = useState(null)

  const [otp, setOtp] = useState("")
  const [timeLeft, setTimeLeft] = useState(60)
  const [modalOtpEmail, setModalOtpEmail] = useState(false)
  const [timer, setTimer] = useState(false)

  const [loadButt, setLoadButt] = useState(false)
  const [resendCodeLoad, setResendCodeLoad] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => updateCountdown(), 1000)
    return () => clearInterval(intervalId)
  }, [timeLeft, timer])

  // useEffect(() => {

  // }, [props.user])

  // const getCodeRegisEmail = async () => {
  //   if(props.user && props.user.success) {
  //     try {

  //     } catch (error) {

  //       Toaster("error", "Maaf", error.response.data.message)
  //     }
  //   }
  // }

  const updateCountdown = () => {
    if (timer && timeLeft > 0) {
      setTimeLeft(timeLeft - 1)
    } else {
      setTimer(false)
    }
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    if (!roleRegis)
      return Toaster("error", "Maaf", "Mohon pilih mendaftar sebagai")

    const userRegister = {
      ...values,
      confirmation_password: values.password,
      lenderAsCompany: roleRegis.company,
      role_id: [roleRegis.uuid],
    }

    if (values.phone_number[0] == "0") {
      userRegister.phone_number = `62${values.phone_number.slice(1)}`
    }

    setDaftar(userRegister)
    toggleModal1()

    // console.log(userRegister)
    // props.registerUser(userRegister)
  }

  // console.log(daftar)

  const [errorMessage, setErrorMessage] = useState("")

  const handleRegister = async () => {
    setErrorMessage("")
    setLoadButt(true)
    try {
      const userRegis = await axios.post(API_URL_1 + POST_REGISTER, daftar)
      const resCode = await axios.post(API_URL_1 + POST_CODE_REGISTER_EMAIL, {
        email: userRegis.data.data.email,
      })
      setTimeLeft(
        moment(resCode.data.expired).diff(moment(resCode.data.datetime)) / 1000
      )
      toggleModal2()
      setModalOtpEmail(true)
      setTimer(true)
      setChecklistForm1(false)
      setChecklistForm2(false)
      setKey(key + 1)
      setLoadButt(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      toggleModal2()
      setLoadButt(false)
    }
  }

  const handleSubmitCode = async () => {
    setLoadButt(true)
    try {
      await axios.post(API_URL_1 + CLAIM_CODE_REGISTER_EMAIL, {
        email: daftar.email,
        code: otp,
      })
      setTimeLeft(60)
      setTimer(false)
      setModalOtpEmail(false)
      setOtp("")
      setLoadButt(false)
      Toaster(
        "success",
        "Terima Kasih",
        "Akun anda telah terverifikasi, silahkan melakukan login."
      )
      props.history.push("/login")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleResendCodeEmail = async () => {
    if (timer && timeLeft > 0) return
    setResendCodeLoad(true)
    try {
      const result = await axios.post(API_URL_1 + POST_CODE_REGISTER_EMAIL, {
        email: daftar.email,
      })
      setResendCodeLoad(false)
      setTimeLeft(
        moment(result.data.expired).diff(moment(result.data.datetime)) / 1000
      )
      setTimer(true)
      Toaster(
        "success",
        "Kirim Ulang Kode",
        "Silahkan cek kode verifikasi anda melalui email."
      )
    } catch (error) {
      setResendCodeLoad(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const getRoles = () => {
    axios
      .get(`${API_URL_1}/roles/populate`)
      .then(res => {
        let tempRoles = []
        res.data.data.forEach(item => {
          if (item.title === "Lender") {
            tempRoles.push({
              label: "Pemberi Dana (Perorangan)",
              value: "Pemberi Dana (Perorangan)",
              uuid: item.uuid,
              company: false,
            })
            tempRoles.push({
              label: "Pemberi Dana (Institusi)",
              value: "Pemberi Dana (Institusi)",
              uuid: item.uuid,
              company: true,
            })
          }

          if (item.title === "Borrower") {
            tempRoles.push({
              label: "Penerima Dana",
              value: "Penerima Dana",
              uuid: item.uuid,
              company: false,
            })
          }
        })

        setRoles(tempRoles)
      })
      .catch(err => console.log(err.response))
  }

  useEffect(() => {
    getRoles()
    // props.registerUserFailed("")
    if (props.user) {
      setKey(key + 1)
    }
  }, [props.user])

  const toggleModal1 = () => setModal1(!modal1)
  const toggleModal2 = () => setModal2(!modal2)
  const toggleOtp = () => setModalOtp(!modalOtp)
  const toggleOtpEmail = () => setModalOtpEmail(!modalOtpEmail)

  const renderModalOtp = () => {
    return (
      <Modal
        isOpen={true}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // scrollable={true}
        // size="lg"
      >
        <div className="modal-content">
          <ModalHeader>Masukkan Kode Verifikasi</ModalHeader>
          <ModalBody>
            <div className="pb-3">
              <div className="pb-4">
                <div>
                  Kode verifikasi telah dikirimkan melalui SMS ke{" "}
                  {daftar && daftar.phone_number}
                </div>
                <div>Salah nomor? klik disini untuk mengganti nomor</div>
              </div>
              <OtpInput
                value={otp}
                onChange={e => setOtp(e)}
                numInputs={6}
                separator={"  "}
                containerStyle="justify-content-center"
                inputStyle="inputStyleOtp"
                hasErrored
                isInputNum
              />
              <div className="text-center mt-4">
                <Button color="primary" className="btn-rounded">
                  Verifikasi
                </Button>
                {/* <div>
                  Mohon Menunggu
                </div> */}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderModal2 = () => {
    return (
      <Modal
        isOpen={modal2}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // toggle={toggleModal}
        scrollable={true}
        size="lg"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal2}>Kebijakan Privasi</ModalHeader>
          <ModalBody>
            <div className="text-justify">
              <CardTitle>Deskripsi</CardTitle>
              <CardText>
                <p>
                  Halaman ini berisikan ketentuan privasi dalam hal akses
                  website dan penggunaan layanan kami. PT. Ethis Fintek
                  Indonesia (EFI) memperlakukan privasi anda dengan sangat
                  serius. Anda dianjurkan untuk membaca kebijakan privasi ini
                  dengan hati-hati karena berisi informasi penting tentang apa
                  yang diharapkan ketika kami mengumpulkan informasi pribadi
                  tentang anda dan bagaimana kami menggunakan informasi pribadi
                  anda. Apabila anda tidak menyetujui Kebijakan Privasi ini,
                  harap untuk tidak menggunakan Web dan Layanan kami. Jika anda
                  memiliki pertanyaan sehubungan dengan kebijakan privasi Situs
                  Web kami, jangan ragu untuk menghubungi kami dengan pertanyaan
                  atau komentar apa pun dengan mengirimkan email kepada kami
                  legal@ethisindonesia.com.
                </p>
                <p>
                  Pengumpulan Data Pengunjung Situs Web Kami akan mendapatkan
                  data pribadi tentang anda ketika anda mengunjungi kami. Ketika
                  anda mengunjungi kami, kami dapat memantau penggunaan Situs
                  ini melalui penggunaan cookie dan perangkat pelacakan serupa
                  jenis informasi yang kami dapatkan akan berbeda, berdasarkan
                  dari penggunaan dan sumber anda mendapatkan layanan kami. Kami
                  hanya akan menggunakan informasi pribadi untuk memberikan
                  layanan kepada pengguna dan tidak akan berbagi data tersebut
                  kepada pihak ketiga tanpa persetujuan dari pengguna kami. Kami
                  mengumpulkan informasi finansial dan pribadi dari anda sewaktu
                  anda menggunakan aplikasi kami dan sebagian besar informasi
                  didapatkan sewaktu anda mendaftar dan mengunggah data anda.
                </p>
              </CardText>
              <CardTitle>Cookie</CardTitle>
              <CardText>
                Website ini menggunakan cookie yang memungkinkan kami untuk
                mengumpulkan informasi log internet standar dan informasi
                perilaku pengunjung. Data-data ini mencakup:
                <ul className="pl-3 mb-0 mt-2">
                  <li>ponsel pintar;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>komputer anda;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>informasi geografis;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>alamat IP;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>sumber rujukan;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>lama waktu anda di laman;;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>periode kunjungan;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>pageviews;</li>
                </ul>
                <ul className="pl-3 mb-0 mt-2">
                  <li>dan klik.</li>
                </ul>
                <p>
                  Kumpulan informasi ini sebagian besar digunakan untuk
                  meningkatkan kinerja dan keamanan penyelenggara. Anda dapat
                  mengatur browser anda untuk tidak menerima cookies. Namun,
                  dalam beberapa fitur Situs kami mungkin tidak berfungsi jika
                  anda menghapus cookie dari browser anda.
                </p>
              </CardText>
              <CardTitle>Informasi Pribadi</CardTitle>
              <CardText>
                Anda mungkin diminta untuk memberikan kami informasi tentang
                diri anda dengan menggunakan formulir online yang disediakan di
                Situs Website. Ini termasuk, tanpa batasan, informasi yang anda
                berikan saat anda mendaftar untuk menggunakan Situs Website,
                masuk ke dalam transaksi melalui Situs Website, berpartisipasi
                dalam papan diskusi atau fungsi media sosial lainnya di Situs
                Website dan / atau ketika anda melaporkan masalah dengan Situs
                Website atau pelayanan kami. Hal ini ditujukan untuk memudahkan
                proses verifikasi identitas anda yang telah diatur oleh hukum
                negara, dan memastikan bahwa anda memenuhi syarat secara legal
                dan mengurangi potensi untuk penipuan. Informasi yang anda
                berikan kepada kami dapat meliputi: (a) Nama anda; (b) Tanggal
                lahir anda; (c) Alamat pos anda saat ini dan (jika perlu) alamat
                pos sebelumnya; (d) Nomor telepon dan alamat email anda; (e)
                Informasi yang berkaitan dengan pekerjaan anda, pendapatan,
                keadaan keuangan dan pribadi; (f) Informasi yang berkaitan
                dengan bisnis atau perusahaan yang mungkin anda minati; dan (g)
                Jika berlaku, dokumen khusus yang melakukan verifikasi identitas
                anda dan informasi pribadi lainnya.
              </CardText>
              <CardTitle>Penggunaan Informasi Pribadi</CardTitle>
              <CardText>
                Kami dapat menggunakan informasi anda yang kami peroleh
                sebagaimana tercantum dalam Kebijakan Privasi ini untuk tujuan:
                (a) Mengirimkan produk dan layanan kami kepada anda (b)
                Meningkatkan produk dan layanan kami kepada anda (c) Memasarkan
                dan mengiklankan produk dan layanan platform Ethis kepada anda.
                (d) Melakukan verifikasi identitas anda untuk mencegah dan
                mendeteksi pencucian uang dan penipuan (e) Melakukan pemeriksaan
                referensi kredit (f) Melakukan riset pasar (g) Memberi tahu anda
                tentang setiap perubahan pada layanan kami (h) Memberi anda
                informasi tentang biaya dan tagihan
              </CardText>
              <CardTitle>Pembaruan dan Perbaikan Informasi</CardTitle>
              <CardText>
                Anda memiliki hak untuk meminta akses ke data pribadi anda dan
                untuk memperbaiki data pribadi anda jika dalam hal ini tidak
                akurat, tidak lengkap, keliru, tidak sesuai, dan tidak benar.
                Anda dapat meminta secara tertulis untuk mengakses data pribadi
                anda untuk mengubah dan memperbarui informasi anda. Namun, kami
                memiliki hak untuk menggunakan kebijaksanaan kami dalam
                perbaikan yang diminta dan / atau membutuhkan bukti dokumen
                lebih lanjut dari data baru untuk menghindari penipuan atau
                ketidakakuratan data.
              </CardText>
              <CardTitle>Perlindungan Informasi</CardTitle>
              <CardText>
                Kami berkomitmen untuk menjaga keamanan informasi pribadi anda.
                Akan ada prosedur keamanan yang sesuai dan tindakan teknis dan
                organisasi untuk melindungi informasi pribadi anda.
              </CardText>
              <CardTitle>Jaringan Pihak Ketiga</CardTitle>
              <CardText>
                Mungkin ada tautan situs web Pihak Ketiga yang disediakan di
                Situs Web. Ini demi kenyamanan anda dalam mengakses informasi
                lain dan kami tidak bertanggung jawab atas segala kerusakan atau
                kerugian yang terjadi dengan mengandalkan Situs Web Pihak
                Ketiga. Selain itu, kebijakan privasi ini hanya berlaku untuk
                Situs Web kami dan tidak akan meluas ke tautan pihak ketiga mana
                pun. Pastikan anda membaca kebijakan privasi situs web Pihak
                Ketiga sebelum menggunakannya.
              </CardText>
              <CardTitle>Perubahan Kebijakan Privasi</CardTitle>
              <CardText>
                Kami akan terus-menerus meninjau Kebijakan Privasi Situs Web
                kami untuk memastikan untuk selalu diperbarui. Oleh karena itu,
                kami berhak untuk mengubah dan / atau merevisi dan / atau
                memodifikasi Kebijakan Privasi Situs web ini dari waktu ke waktu
                tanpa menjelaskan atau memberikan alasan atau memberitahu kepada
                anda tentang perubahan yang dibuat. Perubahan akan diunggah ke
                Situs Website dengan segera dan merupakan kewajiban anda untuk
                secara berkala meninjau informasi yang dipublikasikan di halaman
                Situs Website. Penggunaan berkelanjutan anda menunjukkan
                penerimaan Kebijakan Privasi Situs Website yang diubah.
              </CardText>
              <CardTitle>Berbagi Informasi</CardTitle>
              <CardText>
                Kami tidak menjual, memperdagangkan, atau mentransfer data
                informasi pribadi anda ke pihak ketiga. Namun, kami mungkin akan
                melepaskan informasi anda untuk kebutuhan hukum, menegakkan
                kebijakan situs kami, atau melindungi hak, properti, atau
                keselamatan kami dan orang lain. Kecuali diizinkan atau
                diharuskan untuk dilakukan oleh hukum, kami tidak akan pernah
                mengungkapkan informasi apapun yang diklasifikasikan sebagai
                “data pribadi sensitif” tanpa persetujuan eksplisit dari anda.
              </CardText>
            </div>
            <div className="d-flex justify-content-between mt-3 align-items-center mb-2">
              <FormGroup check className="">
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={e => setChecklistForm2(e.target.checked)}
                    checked={checklistForm2}
                  />{" "}
                  <b>Setuju</b>
                </Label>
              </FormGroup>
              {checklistForm2 && (
                <Button
                  size=""
                  color="primary"
                  // onClick={() => {
                  //   props.registerUser(daftar)
                  //   toggleModal2()
                  // }}
                  disabled={loadButt}
                  onClick={handleRegister}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Lanjutkan"
                  )}
                </Button>
              )}
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderModal1 = () => {
    return (
      <Modal
        isOpen={modal1}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // toggle={toggleModal}
        scrollable={true}
        size="lg"
      >
        <div className="modal-content">
          <ModalHeader toggle={toggleModal1}>Syarat dan Ketentuan</ModalHeader>
          <ModalBody>
            <div className="text-justify">
              Syarat dan ketentuan pada halaman ini menetapkan ketentuan umum
              dalam penggunaan anda pada situs web dan konten di bawah nama
              domain www.ethis.co.id (“Situs Web”). Baca syarat dan ketentuan
              ini dengan saksama sebelum menggunakan situs web atau layanan apa
              pun yang disediakan di situs web. Pastikan bahwa anda memahami
              syarat dan ketentuan dengan sepenuhnya. Dengan mengakses situs
              web, anda menerima dan setuju untuk terikat pada syarat dan
              ketentuan ini. Jika anda tidak setuju dengan syarat dan ketentuan
              yang tercantum di sini, harap jangan gunakan situs web ini. Jika
              anda terus menggunakan situs web ini, maka anda dianggap telah
              menerima dan menyetujui syarat dan ketentuan.
              <CardTitle className="mt-3">Definisi</CardTitle>
              <CardText>
                <ul className="pl-3 mb-0">
                  <li>
                    Ethis / Kami adalah PT. Ethis Fintek Indonesia, perusahaan
                    pembiayaan syariah digital yang menyediakan layanan jasa
                    Peer-to-Peer Financing.
                  </li>
                </ul>
                <ul className="pl-3 mb-0">
                  <li>
                    Layanan adalah bentuk jasa maupun produk yang kami sediakan
                    dalam bentuk digital maupun fisik pada platform, untuk
                    mempertemukan pemberi biaya dan rekan developer dalam rangka
                    melaksanakan pembiayaan secara peer-to-peer syariah.
                  </li>
                </ul>
                <ul className="pl-3 mb-0">
                  <li>
                    Platform adalah teknologi; sistem elektronik; situs web
                    dan/atau mobile application yang disediakan ethis kepada
                    pengguna untuk dapat mengunjungi dan mengakses layanan.
                  </li>
                </ul>
                <ul className="pl-3 mb-0">
                  <li>
                    Pengguna / anda adalah setiap orang yang mengunjungi;
                    mengakses dan/atau menggunakan platform.
                  </li>
                </ul>
                <ul className="pl-3 mb-0">
                  <li>
                    Pemberi biaya adalah pengguna yang menggunakan layanan yang
                    bermaksud untuk memberikan pembiayaan melalui platform.
                  </li>
                </ul>
                <ul className="pl-3 mb-0">
                  <li>
                    Rekan developer adalah pengguna layanan yang bermaksud untuk
                    menawarkan, mengunggah, mempublikasikan objek usaha untuk
                    dilakukan pembiayaan bersama-sama oleh pemberi biaya melalui
                    Platform.
                  </li>
                </ul>
                <ul className="pl-3 mb-0">
                  <li>
                    Campaign / proyek adalah halaman web berisikan informasi
                    terkait proyek pembiayaan yang diajukan oleh rekan
                    developer.
                  </li>
                </ul>
              </CardText>
              <CardTitle className="mt-3">Informasi</CardTitle>
              <CardText>
                Kami menyatakan bahwa informasi yang kami terima akan digunakan
                untuk memastikan validitas data. Ethis dan rekanan, direktur,
                karyawan, dan mitranya tidak menjamin konten dan tidak menerima
                tanggung jawab atas kerugian yang timbul untuk penggunana
                dokumen yang diperlukan. Namun, kami menerapkan pemeriksaan uji
                tuntas internal untuk memastikan bahwa informasi proyek atau
                kampanye sebelum menerbitkannya di situs kami. Jika informasi
                yang disediakan untuk proyek atau pemilik kampanye dinyatakan
                tidak benar, tidak akurat, tidak lengkap atau tidak diperbarui,
                kami berhak untuk menangguhkan atau menghentikan proyek atau
                akun pemilik kampanye dan melarang semua akses yang ada di situs
                web.
              </CardText>
              <CardTitle>Detil Akun</CardTitle>
              <CardText>
                Untuk menggunakan layanan situs web, anda harus terlebih dahulu
                terdaftar sebagai anggota di situs web ethis.co.id sehingga
                dapat mengakses halaman registrasi, dengan mengklik “bergabung”.
                Anda akan diarahkan ke halaman pendaftaran yang mengharuskan
                anda untuk mengisi rincian sebagai user seperti nama pengguna
                dan kata sandi anda. Merupakan tanggung jawab anda untuk menjaga
                kerahasiaan kata sandi anda. Anda perlu melakukan verifikasi
                email anda melalui email yang dikirimkan oleh admin Ethis
                Indonesia. Anda bertanggung jawab atas semua aktivitas yang
                terjadi pada akun anda. Jika ada transaksi atau aktivitas yang
                mencurigakan dari akun anda, anda disarankan untuk segera
                memberi tahu kami. Kami akan mengambil tindakan yang dianggap
                tepat sesuai pemberitahuan anda tentang penyalahgunaan atas akun
                Anda atau tindakan apa pun yang mengganggu situs web.
              </CardText>
              <CardTitle>Kriteria Pengguna</CardTitle>
              <CardText>
                Setiap pengguna yang mendaftar sebagai pemberi biaya harus
                berusia di atas 18 tahun;
                <ul className="pl-3 mb-0">
                  <li>Memiliki kartu identitas yang valid;</li>
                  <li>Memiliki nomor kontak yang valid;</li>
                  <li>Memiliki rekening bank;</li>
                  <li>
                    Merupakan badan usaha yang dilegalisasikan di pemerintah
                    setempat;
                  </li>
                  <li>
                    Melengkapi formulir pernyataan untuk pendaftaran sebagai
                    pemberi biaya.
                  </li>
                </ul>
              </CardText>
              <CardTitle>Hak Cipta dan Merek Dagang</CardTitle>
              <CardText>
                Kami menghormati hak setiap individu dalam hal kekayaan
                intelektual. Oleh karena itu kami akan menanggapi pemberitahuan
                terkait pelanggaran hak cipta yang sesuai dengan hukum yang
                berlaku. Seperti yang disebutkan di atas, kami tidak
                memperkenankan setiap orang mengambil keuntungan dari hak
                kekayaan intelektual seseorang. Dalam hal ini, anda tidak
                diperkenankan membuat, memodifikasi, menyalin, mendistribusi,
                dan mengunggah informasi yang tersedia dalam situs web.
                Informasi di sini berdasarkan pada kebijakan (mitigasi risiko,
                pernyataan pengungkapan, syarat dan ketentuan, kebijakan
                privasi, dll.) Yang dipublikasikan di situs web kami dalam
                setiap hal yang dipublikasi. Setiap persetujuan yang kami
                berikan dan berhubungan dengan konten yang tertera dalam situs
                web dapat dihapus setiap saat tanpa pemberitahuan sebelumnya.
              </CardText>
              <CardTitle>Pembatasan Dalam Penggunaan</CardTitle>
              <CardText>
                Dengan membaca syarat dan ketentuan ini, anda menyetujui bahwa
                tidak diperkenankan dengan cara apa pun menggunakan situs web
                dengan maksud dan tujuan sebagai berikut:
                <ol className="pl-3 mb-0 mt-2">
                  <li className="mb-0">
                    Melanggar syarat dan ketentuan, kebijakan atau perjanjian
                    lain yang terdapat di situs web kami;
                  </li>
                  <li className="mb-0">
                    Melakukan tindakan curang, kriminal atau melanggar hukum di
                    situs web;
                  </li>
                  <li className="mb-0">
                    Penyalahgunaan situs web dengan berisikan konten pornografi,
                    vandalisme, vulgar, sarkasme, rasisme, diskriminatif, hoax,
                    teror, fitnah, umbaran kebencian, dan politik;
                  </li>
                  <li className="mb-0">
                    Melanggar hukum dan peraturan yang berlaku dalam yurisdiksi
                    termasuk yang tertulis pada peraturan Negara Republik
                    Indonesia;
                  </li>
                  <li className="mb-0">
                    Meniru atau merusak gambar orang atau badan apa pun;
                  </li>
                  <li className="mb-0">Melanggar hak cipta atau hak milik;</li>
                  <li className="mb-0">
                    Menyebarkan, atau mengungkapkan informasi pribadi apa pun
                    dari pihak ketiga; atau
                  </li>
                  <li className="mb-0">
                    Membatasi orang lain untuk menggunakan forum publik di situs
                    web.
                  </li>
                </ol>
                Kami berhak untuk menangguhkan dan / atau mengakhiri akun anda
                jika kami memiliki anggapan bahwa anda melakukan pelanggaran
                atau dicurigai melakukan pelanggaran seperti yang disebutkan di
                atas.
              </CardText>
              <CardTitle>Penggunaan Layanan Situs Web</CardTitle>
              <CardText>
                Akses dan penggunaan layanan kami adalah tanggung jawab anda.
                Ethis tidak membuat pernyataan ataupun jaminan apapun secara
                tertulis ataupun tidak. Kami tidak memiliki tanggung jawab
                apapun yang terkait dengan penggunaan atau ketidakmampuan anda
                dalam menggunakan atau menunda penggunaan Situs Web, baik
                informasi atau materi apapun yang terdapat di dalam situs web
                dan dapat diakses yang merupakan tindakan yang diambil secara
                personal.
              </CardText>
              <CardTitle>Ganti Rugi</CardTitle>
              <CardText>
                Anda setuju untuk mengganti rugi dan melindungi kami dari segala
                tuntutan, kerusakan, klaim, tindakan, biaya dan pengeluaran
                sehubungan dengan klaim apa pun dari pihak ketiga yang timbul
                dari atau penggunaan anda atas situs web atau materi lainnya
                melalui situs web oleh anda atau pengguna akun anda yang
                melanggar syarat dan ketentuan hukum yang berlaku.
              </CardText>
              <CardTitle>Kewajiban Kerahasiaan</CardTitle>
              <CardText>
                Pengguna akun diperbolehkan untuk mengakses informasi di situs
                web untuk penggunaan pribadi mereka atau dalam keperluan bisnis
                mereka untuk tujuan menilai apakah anda berhak atau tidak untuk
                berpartisipasi dalam pembiayaan kampanye atau mencari biaya.
                Setiap anggota menerima bahwa ia memiliki kewajiban kerahasiaan
                kepada perusahaan sehubungan dengan:
                <br />
                <br />
                - Sistem dan prosedur operasi internal yang digunakan oleh
                perusahaan;
                <br />
                - Perjanjian yang disediakan dan dokumen; dan
                <br />- Informasi apa pun yang diberikan terkait dengan calon
                pemberi biaya yang sebenarnya.
              </CardText>
              <CardTitle>Konflik Kepentingan</CardTitle>
              <CardText>
                Petugas Ethis dilarang memberikan bantuan keuangan kepada
                pemberi biaya untuk melakukan pembiayaan dalam proyek. Kami
                tidak akan memberikan pembiayaan apapun atau memberi pembiayaan
                dalam proyek apa pun yang diluncurkan di platform. Kami akan
                mengadopsi kerangka konflik kepentingan yang akan memiliki
                proses dan prosedur yang tepat yang memungkinkan Ethis diizinkan
                untuk membiayai dalam kampanye proyek.
              </CardText>
              <CardTitle>Pembatalan Keanggotaan</CardTitle>
              <CardText>
                Setia pengguna dapat mengakhiri keanggotaannya dengan
                mengirimkan pemberitahuan tertulis tentang keputusannya kepada
                perusahaan. Perusahaan akan mengakhiri keanggotaannya kapan pun
                dengan alasan apapun dengan mengirimkan pemberitahuan tertulis
                tentang pengakhiran kepada pengguna situs web.
              </CardText>
            </div>
            <div className="d-flex justify-content-between mt-3 align-items-center mb-2">
              <FormGroup check className="">
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={e => setChecklistForm1(e.target.checked)}
                    checked={checklistForm1}
                  />{" "}
                  <b>Setuju</b>
                </Label>
              </FormGroup>
              {checklistForm1 && (
                <Button
                  size=""
                  color="primary"
                  onClick={() => {
                    toggleModal1()
                    toggleModal2()
                  }}
                >
                  Lanjutkan
                </Button>
              )}
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  const renderTimeLeft = () => {
    let min = Math.floor(timeLeft / 60)
    let sec = timeLeft - min * 60

    return ` ${String(min).length === 1 ? `0${min}` : min} : ${
      String(sec).length === 1 ? `0${sec}` : sec
    }`
  }

  const renderModalOtpEmail = () => {
    return (
      <Modal
        isOpen={modalOtpEmail}
        role="dialog"
        autoFocus={true}
        // centered={true}
        className="exampleModal"
        tabindex="-1"
        // scrollable={true}
        // size="lg"
      >
        <div className="modal-content">
          <ModalHeader className="">Masukkan Kode Verifikasi Email</ModalHeader>
          <ModalBody>
            <div className="pb-2">
              <div className="pb-4">
                <div>
                  Kode verifikasi telah dikirimkan melalui email ke{" "}
                  {daftar && <b>{daftar.email}</b>}
                </div>
                {/* <div>Salah nomor? klik disini untuk mengganti nomor</div> */}
              </div>
              <OtpInput
                value={otp}
                onChange={e => setOtp(e)}
                numInputs={6}
                separator={"  "}
                containerStyle="justify-content-center"
                inputStyle="inputStyleOtp"
                hasErrored
                isInputNum
                shouldAutoFocus
              />
              <div className="text-center mt-4">
                <Button
                  color="primary"
                  // className="btn-sm"
                  onClick={handleSubmitCode}
                  disabled={loadButt || otp.length !== 6}
                  color={`${otp.length !== 6 ? "secondary" : "primary"}`}
                  style={{
                    cursor: `${otp.length !== 6 ? "not-allowed" : "pointer"}`,
                  }}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Konfirmasi"
                  )}
                </Button>
                <div className="mt-3" onClick={handleResendCodeEmail}>
                  {resendCodeLoad ? (
                    <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>
                  ) : (
                    <div
                      className={`${
                        !timer &&
                        "send-code-email text-primary font-weight-bold"
                      }`}
                    >
                      Kirim Ulang Kode
                    </div>
                  )}
                </div>
                <div className="mt-1">
                  <div>{renderTimeLeft()}</div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      {renderModal1()}
      {renderModal2()}
      {renderModalOtpEmail()}
      {/* {renderModalOtp()} */}
      <div className="account-pages pt-3 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={7} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-9">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Daftar</h5>
                        <p>
                          Silahkan daftar untuk memulai pembiayaan atau
                          pengajuan proyek anda
                        </p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-1">
                      <span className="avatar-title rounded-circle bg-white">
                        <img
                          src={logoImg}
                          alt="Logo"
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                      key={String(key)}
                    >
                      {/* {props.user && props.user ? (
                        <Alert color="success">
                          Registrasi berhasil. <br></br>
                          Silahkan cek email untuk verifikasi akun anda.
                        </Alert>
                      ) : null} */}
                      {/* {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{handleErrorMessage(props.registrationError)}</Alert>
                      ) : null} */}
                      {errorMessage ? (
                        <Alert color="danger">{errorMessage}</Alert>
                      ) : null}

                      <Row>
                        <Col lg="6">
                          <div className="form-group">
                            <AvField
                              name="first_name"
                              label="Nama Depan"
                              type="text"
                              placeholder="Nama Depan"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Nama depan harus diisi",
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="form-group">
                            <AvField
                              name="last_name"
                              label="Nama Belakang"
                              type="text"
                              placeholder="Nama Belakang"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Nama belakang harus diisi",
                                },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Email harus diisi",
                            },
                            email: {
                              value: true,
                              errorMessage: "Format email salah",
                            },
                          }}
                        />
                      </div>

                      <AvGroup>
                        <Label>No Handphone</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={flag_ina}
                                height="16"
                                className="mr-1"
                              />
                              +62
                            </InputGroupText>
                          </InputGroupAddon>
                          <AvInput
                            name="phone_number"
                            type="text"
                            placeholder="Contoh. 62812xxx atau 0812xxx"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Nomor Handphone harus diisi",
                              },
                              pattern: {
                                value: "^(^62|^08).{9,12}$",
                                errorMessage:
                                  "Format No Handphone salah. Contoh: 62812xxx atau 0812xxx",
                              },
                            }}
                            required
                          />
                          <AvFeedback>
                            Format No Handphone salah. Contoh: 62812xxx atau
                            0812xxx
                          </AvFeedback>
                        </InputGroup>
                      </AvGroup>

                      <FormGroup className="select2-container">
                        <Label>Mendaftar Sebagai</Label>
                        <Select
                          value={roleRegis}
                          onChange={e => {
                            setRoleRegis(e)
                          }}
                          placeholder="Pilih"
                          options={roles}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Kata Sandi"
                          type="password"
                          placeholder="Kata Sandi"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Password harus diisi",
                            },
                            minLength: {
                              value: 8,
                              errorMessage: "Panjang karakter minimal 8",
                            },
                            maxLength: {
                              value: 16,
                              errorMessage: "Panjang karakter maksimal 16",
                            },
                            pattern: {
                              value:
                                "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$",
                              errorMessage:
                                "Password harus terdiri dari huruf besar, huruf kecil, dan angka",
                            },
                          }}
                        />
                      </div>

                      {/* <div className="form-group">
                        <AvField
                          name="confirmation_password"
                          label="Konfirmasi Password"
                          type="password"
                          placeholder="Masukkan konfirmasi password anda"
                          validate={{
                            required: {value: true, errorMessage: 'Konfirmasi password harus diisi'},
                            match: {value: 'password', errorMessage: 'Konfirmasi password tidak sama'},
                          }}
                        />
                      </div> */}

                      {/* <FormGroup className="mb-0 templating-select select2-container">
                        <label className="control-label">Daftar Sebagai</label>
                        <Select
                          value={selectRoles}
                          isMulti={true}
                          onChange={handleRoles}
                          options={roles}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          required
                        />
                      </FormGroup> */}

                      <div className="mt-5">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loadButt}
                        >
                          {loadButt ? (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                              Loading
                            </>
                          ) : (
                            "Daftar"
                          )}
                          {/* Daftar */}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Skote{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div> */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  Sudah punya akun ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Masuk
                  </Link>{" "}
                </p>
                <div className="text-muted">
                  {new Date().getFullYear()} © PT Ethis Fintek Indonesia{" "}
                  {/* <i className="mdi mdi-heart text-danger"/> */}
                </div>
                <p className="text-muted">Version 1.4.5</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default withRouter(
  connect(mapStatetoProps, {
    registerUser,
    apiError,
    registerUserFailed,
  })(Register)
)
