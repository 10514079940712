import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import { getCities, getCitiesAll, getDistrict, getDistrictAll, getOrganization, getProjectEdit, getSubDistrict, getSubDistrictAll, postEditProperty } from "helpers/backend_helpers"
import { getBussinesEntity, getCountry, getPropertyOwner, getProvince, getProyekType, getProyekTypeDetail, getSectorIndustry, getSubmitActor } from "helpers/backend_helpers"
import Loading from "pages/Utility/Loading"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Label, Row } from "reactstrap"
import SignaturePad from 'react-signature-pad-wrapper'
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const INIT_MASTER_DATA = {
  business_entity: [],
  project_type: [],
  sector_industry: [],
  property_ownership: [],
  country: [],
  province: [],
  city: [],
  district: [],
  sub_district: [],
  submit_actor: [],
  project_type_detail: [],
  organitation: [],
}

const INIT_FILES = {
  file_npwp_company: null,
  file_siup_company: null,
  file_nib_company: null,
  file_akta_company: null,
  file_akta_last_change_number: null,
  file_sk_kemenkumham: null,
  file_organitation: null,
}

let signaturePad;

const FormProperties = props => {
  const params = props.match.params.uuid
  const token = localStorage.getItem("token")
  const [project, setProject] = useState(null)
  const [masterData, setMasterData] = useState(INIT_MASTER_DATA)
  const [load, setLoad] = useState(false)
  const [file, setFile] = useState(INIT_FILES)
  const [loadButt, setLoadButt] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const [organization, setOrganization] = useState(null)

  const handleOrganization = () => {
    for(let i = 0; i < masterData.organitation.length; i++) {
      if(masterData.organitation[i].title == "Tidak ada") {
        return masterData.organitation[i].id
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoad(true)
    try {
      const data = await getProjectEdit(params, token)

      const businessEntity = await getBussinesEntity(token)
      const proyekType = await getProyekType(token)
      const sectorIndustry = await getSectorIndustry(token)
      const propertyOwner = await getPropertyOwner(token)
      const country = await getCountry(token)
      const province = await getProvince(token)
      const submitActor = await getSubmitActor(token)
      const proyekTypeDetail = await getProyekTypeDetail(token)
      const organ = await getOrganization(token)

      const kota = await getCities(data.data.project_company.company_address.province.uuid, token)
      const kecamatan = await getDistrict(data.data.project_company.company_address.city.uuid, token)
      const kelurahan = await getSubDistrict(data.data.project_company.company_address.district.uuid, token)

      setMasterData({
        ...masterData,
        business_entity: businessEntity.data,
        project_type: proyekType.data,
        sector_industry: sectorIndustry.data,
        property_ownership: propertyOwner.data,
        country: country.data,
        province: province.data,
        submit_actor: submitActor.data,
        proyekTypeDetail: proyekTypeDetail.data,
        organitation: organ.data,
        city: kota.data,
        district: kecamatan.data,
        sub_district: kelurahan.data
      })
      setProject(data.data)
      if(data.data.project_company.organitation) {
        setOrganization(data.data.project_company.organitation.id)
      }
      setLoad(false)
    } catch (error) {
      // Toaster("error", "Ups", error.response.data.message)
      setLoad(false)
    }
  }

  const handleNegara = (data) => {
    for(let i = 0; i < data.length; i++) {
      if(data[i].name === "Indonesia") {
        return data[i].uuid
      }
    }
  }

  const getKota = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kota = await getCities(param, token)
      setMasterData({
        ...masterData,
        city: kota.data,
        district: [],
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        city: [],
        district: [],
        sub_district: []
      })
    }
  }

  const GetKecamatan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kecamatan = await getDistrict(param, token)
      setMasterData({
        ...masterData,
        district: kecamatan.data,
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        district: [],
        sub_district: []
      })
    }
  }

  const getKelurahan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const subdistrict = await getSubDistrict(param, token)
      setMasterData({
        ...masterData,
        sub_district: subdistrict.data,
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        sub_district: []
      })
    }
  }

  const handleRegion = (e) => {
    if(e.target.name === 'province_id') {
      getKota(e.target.value)
    } else if(e.target.name === 'city_id') {
      GetKecamatan(e.target.value)
    } else if(e.target.name === 'district_id') {
      getKelurahan(e.target.value)
    }
  }

  const handleChangeFile = e => {
    setFile({
      ...file,
      [e.target.name]: e.target.files[0]
    })
  }
  
  const handleSubmitProject = async (e, value) => {
    if(signaturePad.isEmpty()) return Toaster("error", "Ups", "Tanda tangan harus diisi")
    let newValue = {
      ...value,
      ...file,
      ttd_borrower: signaturePad.toDataURL(),
    }
    const formData = new FormData()
    Object.keys(newValue).forEach(item => {
      if(newValue[item]) {
        formData.append(item, newValue[item])
      }
    })

    setLoadButt(true)
    try {
      await postEditProperty(params, formData, token)
      Toaster("success", "Sukses", "Berhasil ajukan proyek")
      setLoadButt(false)
      setRedirect(true)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }

  }

  if(redirect) return <Redirect to="/proyeks" />

  // if(load || !project) return <Loading />
  if(load || !project) {
    return (
      <SkeletonLoad
        title=""
        breadcrumbItem=""
      >
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <Skeleton width={320} />
          {[1,2,3,4,5,6,7,8,9].map(item => {
            return (
              <Row>
                <Col sm={3}><Skeleton /></Col>
                <Col sm={9}><Skeleton /></Col>
              </Row>
            )
          })}
        </div>
      </SkeletonLoad>
    )
  }

  if(props.user.uuid !== project.user.uuid) return <Redirect to="/proyeks" />

  // if(props.user.uuid !== project.user.uuid) return <Redirect to="/proyeks" />

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Formulir Pengajuan Proyek Properti</CardTitle>

              <div className="mt-4 ">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleSubmitProject(e, v)
                  }}
                >

                  <Card className="border">
                    <CardHeader><b>Infomasi Perusahaan</b></CardHeader>
                    <CardBody>
                      <FormGroup row>
                        <Label sm={3}>Pengajuan Sebagai<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="submission_actor_id"
                            type="select"
                            validate={{
                              required: {value: true, errorMessage: 'Pengajuan Sebagai harus diisi'},
                            }}
                            options={masterData.submit_actor}
                            value={project.submission_actor.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Badan Usaha<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="business_entity_id"
                            type="select"
                            validate={{
                              required: {value: true, errorMessage: 'Badan Usaha harus diisi'},
                            }}
                            options={masterData.business_entity}
                            value={project.project_company.business_entity.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Nama Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="company_name"
                            type="text"
                            placeholder="cth. PT Multi Guna Jaya"
                            helpMessage="Mohon sertakan PT atau CV pada nama perusahaan anda"
                            validate={{
                              required: {value: true, errorMessage: 'Nama Perusahaan harus diisi'},
                            }}
                            value={project.project_company.company_name}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Alamat Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="company_full_address"
                            type="text"
                            placeholder="Alamat"
                            validate={{
                              required: {value: true, errorMessage: 'Alamat Perusahaan harus diisi'},
                            }}
                            value={project.project_company.company_address.full_address}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Provinsi<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="province_id"
                            type="select"
                            // value={input.province_id}
                            onChange={handleRegion}
                            validate={{
                              required: {value: true, errorMessage: 'Provinsi harus diisi'},
                            }}
                            options={masterData.province}
                            value={project.project_company.company_address.province.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Kota<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="city_id"
                            type="select"
                            onChange={(e) => handleRegion(e)}
                            validate={{
                              required: {value: true, errorMessage: 'Kota harus diisi'},
                            }}
                            options={masterData.city}
                            value={project.project_company.company_address.city.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Kecamatan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="district_id"
                            type="select"
                            onChange={(e) => handleRegion(e)}
                            validate={{
                              required: {value: true, errorMessage: 'Kecamatan harus diisi'},
                            }}
                            options={masterData.district}
                            value={project.project_company.company_address.district.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Kelurahan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="sub_district_id"
                            type="select"
                            // onChange={(e) => handleRegion(e)}
                            validate={{
                              required: {value: true, errorMessage: 'Kelurahan harus diisi'},
                            }}
                            options={masterData.sub_district}
                            value={project.project_company.company_address.sub_district.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Negara<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="country_id"
                            type="select"
                            value={handleNegara(masterData.country)}
                            disabled
                            validate={{
                              required: {value: true, errorMessage: 'Negara harus diisi'},
                            }}
                            options={masterData.country}
                            // value={}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Status Kepemilikan Kantor<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="property_ownership_id"
                            type="select"
                            validate={{
                              required: {value: true, errorMessage: 'Status Kepemilikan Kantor harus diisi'},
                            }}
                            options={masterData.property_ownership}
                            value={project.project_company.property_ownership.uuid}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Total Asset<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="total_asset"
                            type="number"
                            placeholder="Total Asset"
                            helpMessage="Dalam Rupiah"
                            value={parseInt(project.project_company.total_asset)}
                            validate={{
                              required: {value: true, errorMessage: 'Total Asset harus diisi'},
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Total Karyawan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="total_employee"
                            type="number"
                            placeholder="Total Karyawan"
                            // helpMessage="Dalam Persen"
                            validate={{
                              required: {value: true, errorMessage: 'Total Karyawan harus diisi'},
                            }}
                            value={project.project_company.total_employee}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Tahun Berdiri<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="company_since"
                            type="text"
                            placeholder="ex. 2004"
                            validate={{
                              required: {value: true, errorMessage: 'Tahun Berdiri harus diisi'},
                            }}
                            value={project.project_company.company_since}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Jumlah Proyek yang sudah di Kerjakan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="total_project_last_year"
                            type="number"
                            placeholder="Jumlah Proyek yang sudah di Kerjakan"
                            validate={{
                              required: {value: true, errorMessage: 'Jumlah proyek harus diisi'},
                            }}
                            value={project.project_company.total_project_last_year}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Telepon Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="company_telp"
                            type="text"
                            placeholder="Telepon"
                            helpMessage="ex. 081233445566"
                            validate={{
                              required: {value: true, errorMessage: 'Telepon Perusahaan harus diisi'},
                            }}
                            value={project.project_company.company_telp}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card className="border">
                    <CardHeader><b>Legalitas Perusahaan</b></CardHeader>
                    <CardBody>
                      <FormGroup row>
                        <Label sm={3}>Nomor NIB Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="company_siup_number"
                            type="text"
                            placeholder="Nomor NIB"
                            validate={{
                              required: {value: true, errorMessage: 'Nomor NIB Perusahaan harus diisi'},
                            }}
                            value={project.project_company.company_siup_number}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Unggah NIB Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="file_nib_company"
                            type="file"
                            onChange={handleChangeFile}
                            helpMessage="Pdf file maksimal 50 MB"
                            // validate={{
                            //   required: {value: true, errorMessage: 'Unggah NIB harus diisi'},
                            // }}
                            value={file.file_nib_company}
                            // onChange={handleChange}
                          />
                          <Button 
                            className="btn-sm" 
                            disabled={!project.project_company.file_nib_company}
                            onClick={() => window.open(`${API_URL}${project.project_company.file_nib_company}`, "_blank") }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Nomor NPWP Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="npwp_company_number"
                            type="text"
                            placeholder="Nomor NPWP"
                            validate={{
                              required: {value: true, errorMessage: 'Nomor NPWP Perusahaan harus diisi'},
                              pattern: {value: '^[0-9]+$', errorMessage: 'Format NPWP harus angka'},
                              minLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
                              maxLength: {value: 15, errorMessage: 'Panjang harus 15 digit'},
                            }}
                            value={project.project_company.npwp_company_number}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Unggah NPWP Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9} >
                          <PInput 
                            name="file_npwp_company"
                            onChange={handleChangeFile}
                            type="file"
                            helpMessage="jpg, jpeg, atau png maksimal 5 MB"
                            // validate={{
                            //   required: {value: true, errorMessage: 'Unggah NPWP Perusahaan harus diisi'},
                            // }}
                            value={file.file_npwp_company}
                            // onChange={handleChange}
                          />
                          <Button 
                            className="btn-sm" 
                            disabled={!project.project_company.file_npwp_company}
                            onClick={() => window.open(`${API_URL}${project.project_company.file_npwp_company}`, "_blank") }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Unggah SIUP Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="file_siup_company"
                            type="file"
                            onChange={handleChangeFile}
                            helpMessage="Pdf file maksimal 50 MB"
                            // validate={{
                            //   required: {value: true, errorMessage: 'Unggah SIUP Perusahaan harus diisi'},
                            // }}
                            value={file.file_siup_company}
                            // onChange={handleChange}
                          />
                          <Button 
                            className="btn-sm" 
                            disabled={!project.project_company.file_siup_company}
                            onClick={() => window.open(`${API_URL}${project.project_company.file_siup_company}`, "_blank") }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Nomor Akta Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="akta_company_number"
                            type="text"
                            placeholder="Nomor Akta Perusahaan"
                            validate={{
                              required: {value: true, errorMessage: 'Nomor Akta Perusahaan harus diisi'},
                            }}
                            value={project.project_company.akta_company_number}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Unggah Akta Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="file_akta_company"
                            type="file"
                            onChange={handleChangeFile}
                            helpMessage="Pdf file maksimal 50 MB"
                            // validate={{
                            //   required: {value: true, errorMessage: 'Akta Perusahaan harus diisi'},
                            // }}
                            value={file.file_akta_company}
                            // onChange={handleChange}
                          />
                          <Button 
                            className="btn-sm"
                            disabled={!project.project_company.file_akta_company} 
                            onClick={() => window.open(`${API_URL}${project.project_company.file_akta_company}`, "_blank") }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Nomor Akta Perubahan Terakhir<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="akta_last_change_number"
                            type="text"
                            placeholder="Akta Perubahan Terakhir"
                            // helpMessage="Dalam Persen"
                            validate={{
                              required: {value: true, errorMessage: 'Akta Perubahan Terakhir harus diisi'},
                            }}
                            value={project.project_company.akta_last_change_number}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Unggah Akta Perubahan Terakhir<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="file_akta_last_change_number"
                            type="file"
                            onChange={handleChangeFile}
                            helpMessage="Pdf file maksimal 50 MB"
                            // validate={{
                            //   required: {value: true, errorMessage: 'Akta Perubahan terakhir harus diisi'},
                            // }}
                            value={file.file_akta_last_change_number}
                            // onChange={handleChange}
                          />
                          <Button 
                            className="btn-sm" 
                            disabled={!project.project_company.file_akta_last_change_number}
                            onClick={() => window.open(`${API_URL}${project.project_company.file_akta_last_change_number}`, "_blank") }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>SK Kemenkumham<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="sk_kemenkumham"
                            type="text"
                            placeholder="SK Kemenkumham"
                            // helpMessage="Dalam Persen"
                            validate={{
                              required: {value: true, errorMessage: 'SK Kemenkumham harus diisi'},
                            }}
                            value={project.project_company.sk_kemenkumham}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Unggah SK Kemenkumham<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="file_sk_kemenkumham"
                            type="file"
                            onChange={handleChangeFile}
                            helpMessage="Pdf file maksimal 50 MB"
                            // validate={{
                            //   required: {value: true, errorMessage: 'Unggah SK Kemenkumham harus diisi'},
                            // }}
                            value={file.file_sk_kemenkumham}
                            // onChange={handleChange}
                          />
                          <Button 
                            className="btn-sm" 
                            disabled={!project.project_company.file_sk_kemenkumham}
                            onClick={() => window.open(`${API_URL}${project.project_company.file_sk_kemenkumham}`, "_blank") }
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3} className="pt-0">Apakah perusahaan anda tergabung dalam sebuah organisasi?<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="organitation_id"
                            type="select"
                            placeholder="Organisasi"
                            // helpMessage="Dalam Persen"
                            validate={{
                              required: {value: true, errorMessage: 'Field harus diisi'},
                            }}
                            options={masterData.organitation}
                            value={project.project_company.organitation ? project.project_company.organitation.id : ""}
                            onChange={e => setOrganization(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      {/* {!organization || organization == handleOrganization() ? null :
                      } */}
                      <FormGroup row>
                        <Label sm={3} className="pt-0">Unggah Bukti Keanggotaan / Kepengurusan Organisasi{!organization || organization == handleOrganization() ? null : <span className="text-danger">*</span>}</Label>
                        <Col sm={9}>
                          <PInput 
                            name="file_organitation"
                            type="file"
                            onChange={handleChangeFile}
                            helpMessage="Pdf file maksimal 50 MB"
                            validate={{
                              required: {value: !organization || organization == handleOrganization() ? false : true, errorMessage: 'Unggah Organisasi harus diisi'},
                            }}
                            value={file.file_organitation}
                            disabled={!organization || organization == handleOrganization() ? true : false}
                            // onChange={handleChange}
                          />
                          {/* {project.project_company.file_organitation &&
                          } */}
                          <Button 
                            className="btn-sm" 
                            onClick={() => window.open(`${API_URL}${project.project_company.file_organitation}`, "_blank") }
                            disabled={project.project_company.file_organitation === null}
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card className="border">
                    <CardHeader><b>Informasi Proyek Diajukan</b></CardHeader>
                    <CardBody>
                      <FormGroup row>
                        <Label sm={3}>Nama Proyek<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="title"
                            type="text"
                            placeholder="Nama Proyek"
                            validate={{
                              required: {value: true, errorMessage: 'Nama Proyek harus diisi'},
                            }}
                            value={project.title}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Jenis Proyek<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="project_detail_type"
                            type="text"
                            placeholder="Jenis Proyek"
                            validate={{
                              required: {value: true, errorMessage: 'Jenis Proyek harus diisi'},
                            }}
                            // options={masterData.proyekTypeDetail}
                            value={project.project_detail.project_detail_type}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Alamat Proyek<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="project_full_address"
                            type="textarea"
                            placeholder="Alamat Proyek"
                            validate={{
                              required: {value: true, errorMessage: 'Alamat Proyek harus diisi'},
                            }}
                            value={project.project_address.full_address}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Deskripsi Proyek<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="description"
                            type="textarea"
                            placeholder="Deskripsi Proyek"
                            validate={{
                              required: {value: true, errorMessage: 'Deskripsi Proyek harus diisi'},
                            }}
                            value={project.project_detail.description}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Jumlah Dana yang Diajukan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="submission_total"
                            type="number"
                            placeholder="Rp"
                            helpMessage="Rupiah"
                            validate={{
                              required: {value: true, errorMessage: 'Jumlah Dana harus diisi'},
                              max: {value: 2000000000, errorMessage: 'Maksimal 2 Milyar'}
                            }}
                            value={parseInt(project.project_detail.submission_total)}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Ekspektasi Kontribusi Modal Pendana<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="capital_contribution"
                            type="number"
                            placeholder="Kontribusi Modal Pendana"
                            helpMessage="Dalam Persen (Max 95%)"
                            validate={{
                              required: {value: true, errorMessage: 'Kontribusi Modal Pendana harus diisi'},
                              max: {value: 95, errorMessage: 'Maksimal 95 %'},
                              min: {value: 1, errorMessage: 'Minimal 1 %'}
                            }}
                            value={project.capital_contribution}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                  </Card>
                  <Card className="border">
                    <CardHeader><b>Informasi Pengaju</b></CardHeader>
                    <CardBody>
                      <FormGroup row>
                        <Label sm={3}>Nama Lengkap Pengaju<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="pic_full_name"
                            type="text"
                            placeholder="Nama Pengaju"
                            validate={{
                              required: {value: true, errorMessage: 'Nama Lengkap harus diisi'},
                            }}
                            value={project.project_pic[0].full_name}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Jabatan Pengaju di Perusahaan<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="pic_position"
                            type="text"
                            placeholder="Jabatan Pengaju"
                            validate={{
                              required: {value: true, errorMessage: 'Jabatan di Perusahaan harus diisi'},
                            }}
                            value={project.project_pic[0].position}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>No HP Pengaju<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="pic_phone_number"
                            type="text"
                            placeholder="ex. 081233445566"
                            validate={{
                              required: {value: true, errorMessage: 'No HP harus diisi'},
                            }}
                            value={project.project_pic[0].phone_number}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={3}>Email Pengaju<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                          <PInput 
                            name="pic_email"
                            type="text"
                            placeholder="Email PIC"
                            validate={{
                              required: {value: true, errorMessage: 'Email PIC harus diisi'},
                            }}
                            value={project.project_pic[0].email}
                            // onChange={handleChange}
                          />
                        </Col>
                      </FormGroup>
                      {project.status === 2 || project.status === 3 ?
                        <>
                        <FormGroup row>
                          <Label sm={3}>Tanda Tangan PIC<span className="text-danger">*</span></Label>
                          <Col sm={9}>
                            <Card className="w-50">
                              <div style={{border: "1.5px solid"}}>
                                <SignaturePad redrawOnResize={true} ref={ref => signaturePad = ref} />
                              </div>
                            </Card>
                            <Button className="btn-sm" onClick={() => signaturePad.clear()}>Hapus</Button>
                            {/* <Button className="btn-sm" onClick={() => console.log(signaturePad.isEmpty())}>Hapus</Button> */}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={3}>Penggunaan Tanda Tangan<span className="text-danger">*</span></Label>
                          <Col sm={9}>
                            <PInput 
                              name="agreementTTD"
                              label="Dengan ini saya bersedia dan menyetujui bahwa penggunaan tanda tangan ini akan digunakan pada akad Musyarakah pada PrivyId sebagai platform tanda tangan elektronik"
                              type="checkbox"
                              validate={{
                                required: {value: true, errorMessage: 'Pernyataan harus diisi'},
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
                          <Col sm={9}>
                            <PInput 
                              name="agreement"
                              label="Dengan ini saya menyatakan bahwa semua data yang diberikan
                              adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
                              data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
                              pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
                              type="checkbox"
                              validate={{
                                required: {value: true, errorMessage: 'Persetujuan harus diisi'},
                              }}
                              // value={project.agreement}
                              // onChange={handleChange}
                            />
                          </Col>
                        </FormGroup></> : 
                        <FormGroup row>
                          <Label sm={3}>Tanda Tangan PIC<span className="text-danger">*</span></Label>
                          <Col sm={9}>
                            <Card className="w-50">
                            <div style={{border: "1.5px solid"}}>
                              <img src={API_URL + project.ttd_borrower} alt="ttd" className="w-100 p-2" />
                            </div>
                            </Card>
                          </Col>
                        </FormGroup>
                      }
                    </CardBody>
                  </Card>
              
                  {project.status === 2 || project.status === 3 ?
                    <div className="row justify-content-end mb-3">
                      <div className="col-9">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={loadButt}
                        >
                          {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan Kembali'}
                        </button>

                      </div>
                    </div> : null
                  }
                  
                </AvForm>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(FormProperties)
