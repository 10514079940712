import React from "react"
import PropTypes from "prop-types"
import ModalImage from "react-modal-image"
import {
  Button,
  Card,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import { API_URL } from "helpers/api_url"
import PInput from "components/Input/PInput"
import AvForm from "availity-reactstrap-validation/lib/AvForm"

const ModalDetail = props => {
  const { isOpen, toggle } = props
  const project = props.item

  const isCooperative =
    project?.project_company?.business_entity?.title == "Koperasi"

  const renderFormProperty = () => {
    return (
      <div className="mt-2 ">
        <AvForm className="form-horizontal">
          <FormGroup row>
            <Label sm={3}>
              Pengajuan Sebagai<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="submission_actor_id"
                // type="select"
                value={project.submission_actor.title}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Badan Usaha<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="business_entity_id"
                // type="select"
                value={project.project_company.business_entity.title}
                disabled
              />
            </Col>
          </FormGroup>
          {isCooperative && (
            <FormGroup row>
              <Label sm={3}>
                Tipe Koperasi<span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <PInput
                  name="business_entity_id"
                  // type="select"
                  value={project.koperasi_type?.title}
                  disabled
                />
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Label sm={3}>
              Nama Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="company_name"
                type="text"
                value={project.project_company.company_name}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Alamat Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="company_full_address"
                type="text"
                value={project.project_company.company_address.full_address}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Provinsi<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="province_id"
                // type="select"
                value={project.project_company.company_address.province.name}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Kota<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="city_id"
                // type="select"
                value={project.project_company.company_address.city.name}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Kecamatan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="district_id"
                // type="select"
                value={project.project_company.company_address.district.name}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Kelurahan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="sub_district_id"
                // type="select"
                value={
                  project.project_company.company_address.sub_district.name
                }
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Negara<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="country_id"
                // type="select"
                value={project.project_company.company_address.country.name}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Status Kepemilikan Kantor<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="property_ownership_id"
                // type="select"
                value={project.project_company.property_ownership.title}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Total Asset<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="total_asset"
                type="text"
                // placeholder="Total Asset"
                helpMessage="Rupiah"
                value={parseInt(
                  project.project_company.total_asset
                ).toLocaleString()}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Tahun Berdiri<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="company_since"
                type="text"
                value={project.project_company.company_since}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Jumlah Proyek yang sudah di Kerjakan
              <span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="total_project_last_year"
                type="number"
                value={project.project_company.total_project_last_year}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Nomor NPWP Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="npwp_company_number"
                type="text"
                value={project.project_company.npwp_company_number}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Unggah NPWP Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_npwp_company}`,
                    "_blank"
                  )
                }
                disabled={!project.project_company.file_npwp_company}
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Nomor Akta Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="akta_company_number"
                type="text"
                value={project.project_company.akta_company_number}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          {isCooperative && (
            <>
              <FormGroup row>
                <Label sm={3}>
                  Nomor Induk Koperasi<span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <PInput
                    name="no_induk_koperasi"
                    type="text"
                    placeholder="Nomor Induk Koperasi"
                    value={project.project_company.no_induk_koperasi}
                    disabled
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>
                  Unggah Setifikat Nomor Induk Koperasi
                  <span className="text-danger">*</span>
                </Label>
                <Col sm={9}>
                  <Button
                    className="btn-sm"
                    onClick={() =>
                      window.open(
                        `${API_URL}${project.project_company.file_certificate_koperasi}`,
                        "_blank"
                      )
                    }
                  >
                    <i className="fas fa-eye"></i>
                  </Button>
                </Col>
              </FormGroup>
            </>
          )}
          <FormGroup row>
            <Label sm={3}>
              Nomor NIB Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="company_siup_number"
                type="text"
                value={project.project_company.company_siup_number}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>
              Unggah NIB<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                disabled={!project.project_company.file_nib_company}
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_nib_company}`,
                    "_blank"
                  )
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Unggah SIUP Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                disabled={!project.project_company.file_siup_company}
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_siup_company}`,
                    "_blank"
                  )
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Telepon Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="company_telp"
                type="text"
                value={project.project_company.company_telp}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Deskripsi Proyek<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="description"
                type="textarea"
                value={project.project_detail.description}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Alamat Proyek<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="project_full_address"
                type="textarea"
                value={project.project_address.full_address}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Nama Proyek<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="title"
                type="text"
                value={project.title}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Jenis Proyek<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="project_detail_type"
                type="text"
                value={project.project_detail.project_detail_type}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Jumlah Dana yang Diajukan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="submission_total"
                type="text"
                placeholder="Rp"
                helpMessage="Rupiah"
                value={parseInt(
                  project.project_detail.submission_total
                ).toLocaleString()}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Kontribusi Modal<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="capital_contribution"
                type="number"
                placeholder="Kontribusi Modal"
                helpMessage="Dalam Persen"
                // validate={{
                //   required: {value: true, errorMessage: 'Kontribusi Modal harus diisi'},
                //   max: {value: 100, errorMessage: 'Maksimal 100 %'}
                // }}
                value={parseInt(project.capital_contribution)}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Unggah Akta Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                disabled={!project.project_company.file_akta_company}
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_akta_company}`,
                    "_blank"
                  )
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Akta Perubahan Terakhir<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="akta_last_change_number"
                type="text"
                placeholder="Akta Perubahan Terakhir"
                // helpMessage="Dalam Persen"
                // validate={{
                //   required: {value: true, errorMessage: 'Akta Perubahan Terakhir harus diisi'},
                // }}
                value={project.project_company.akta_last_change_number}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Unggah Akta Perubahan Terakhir
              <span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                disabled={!project.project_company.file_akta_last_change_number}
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_akta_last_change_number}`,
                    "_blank"
                  )
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              SK Kemenkumhan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="sk_kemenkumham"
                type="text"
                placeholder="SK Kemenkumhan"
                // helpMessage="Dalam Persen"
                // validate={{
                //   required: {value: true, errorMessage: 'SK Kemenkumhan harus diisi'},
                // }}
                value={project.project_company.sk_kemenkumham}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Unggah SK Kemenkumhan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                disabled={!project.project_company.file_sk_kemenkumham}
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_sk_kemenkumham}`,
                    "_blank"
                  )
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Organisasi<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="organitation_id"
                type="text"
                value={
                  project.project_company.organitation
                    ? project.project_company.organitation.title
                    : ""
                }
                disabled
              />
            </Col>
          </FormGroup>
          {project.project_company.file_organitation && (
            <FormGroup row>
              <Label sm={3}>
                Unggah Organisasi<span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Button
                  className="btn-sm"
                  disabled={!project.project_company.file_organitation}
                  onClick={() =>
                    window.open(
                      `${API_URL}${project.project_company.file_organitation}`,
                      "_blank"
                    )
                  }
                >
                  <i className="fas fa-eye"></i>
                </Button>
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Label sm={3}>
              Total Karyawan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="total_employee"
                type="number"
                placeholder="Total Karyawan"
                // helpMessage="Dalam Persen"
                // validate={{
                //   required: {value: true, errorMessage: 'Total Karyawan harus diisi'},
                // }}
                value={project.project_company.total_employee}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Nama Lengkap PIC<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="pic_full_name"
                type="text"
                value={project.project_pic[0].full_name}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Jabatan PIC di Perusahaan<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="pic_position"
                type="text"
                value={project.project_pic[0].position}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              No Hp PIC<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="pic_phone_number"
                type="text"
                value={project.project_pic[0].phone_number}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>
              Email PIC<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="pic_email"
                type="text"
                value={project.project_pic[0].email}
                disabled
                // onChange={handleChange}
              />
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label sm={3}>
              Tanda Tangan PIC<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Card className="w-50 border">
                <img src={API_URL + project.ttd_borrower} alt="ttd" />
              </Card>
            </Col>
          </FormGroup> */}
        </AvForm>
      </div>
    )
  }

  const renderFormUkmOne = () => {
    return (
      <AvForm className="form-horizontal">
        <FormGroup row>
          <Label sm={3}>
            Nama Proyek<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="title"
              type="text"
              placeholder="Nama Proyek"
              value={project.title}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Badan Usaha<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="business_entity_id"
              // type="select"
              value={project.project_company.business_entity.title}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Nama Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="company_name"
              type="text"
              placeholder="ex. Mitra Multi Guna"
              value={project.project_company.company_name}
              disabled
            />
          </Col>
        </FormGroup>
        {project.sector_industry ? (
          <FormGroup row>
            <Label sm={3}>
              Sektor Industri<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="sector_industry_id"
                // type="select"
                value={project.sector_industry.title}
                disabled
              />
            </Col>
          </FormGroup>
        ) : (
          <FormGroup row>
            <Label sm={3}>
              Tipe Koperasi<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <PInput
                name="koperasi_type_id"
                // type="select"
                value={project.koperasi_type?.title}
                disabled
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label sm={3}>
            Tahun Berdiri<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="company_since"
              type="text"
              placeholder="ex. 2004"
              value={project.project_company.company_since}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Alamat Kantor<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="company_full_address"
              type="textarea"
              placeholder="Nama jalan / Gedung / Tempat"
              value={project.project_company.company_address.full_address}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Provinsi<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="province_id"
              // type="select"
              value={project.project_company.company_address.province.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Kota<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="city_id"
              // type="select"
              value={project.project_company.company_address.city.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Kecamatan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="district_id"
              // type="select"
              value={project.project_company.company_address.district.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Kelurahan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="sub_district_id"
              // type="select"
              value={project.project_company.company_address.sub_district.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Negara<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="country_id"
              // type="select"
              value={project.project_company.company_address.country.name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Telepon Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="company_telp"
              type="text"
              placeholder="ex. 081233445566"
              value={project.project_company.company_telp}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Status Kepemilikan Kantor<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="property_ownership_id"
              // type="select"
              value={project.project_company.property_ownership.title}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Total Asset<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="total_asset"
              type="text"
              // placeholder="Total Asset"
              helpMessage="Rupiah"
              value={parseInt(
                project.project_company.total_asset
              ).toLocaleString()}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah Profile Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_company_profile}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Website Perusahaan</Label>
          <Col sm={9}>
            <PInput
              name="company_website"
              type="text"
              value={project.project_company.company_website}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Klien (Pemberi Pekerjaan)</Label>
          <Col sm={9}>
            <PInput
              name="client_name"
              type="text"
              value={project.project_detail.client_name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Jumlah Dana Diajukan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="submission_total"
              type="text"
              placeholder="Rp"
              helpMessage="Rupiah"
              value={parseInt(
                project.project_detail.submission_total
              ).toLocaleString()}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Tenor Proyek <span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="tenor"
              type="number"
              helpMessage="Bulan"
              value={project.project_detail.tenor}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Kontrak / Purchase Order <span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_detail.file_contract}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Deskripsi Proyek<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="description"
              type="textarea"
              placeholder="Deskirpsikan manfaat apa saja dari proyek anda"
              value={project.project_detail.description}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Jumlah Proyek dikerjakan 12 bulan terakhir
            <span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="total_project_last_year"
              type="number"
              value={project.project_company.total_project_last_year}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Rekening Koran 3 - 6 Bulan Terakhir
            <span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.bank_account_papper_last_six_month}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Kontribusi Modal<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="capital_contribution"
              type="number"
              placeholder="Kontribusi Modal"
              helpMessage="Dalam Persen"
              value={parseInt(project.capital_contribution)}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Total Karyawan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="total_employee"
              type="number"
              placeholder="Total Karyawan"
              value={project.project_company.total_employee}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Nama Lengkap PIC<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="pic_full_name"
              type="text"
              placeholder="Nama Lengkap PIC"
              value={project.project_pic[0].full_name}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Jabatan PIC di Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="pic_position"
              type="text"
              placeholder="Jabatan di Perusahaan"
              value={project.project_pic[0].position}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            No HP PIC<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="pic_phone_number"
              type="text"
              placeholder="No HP. PIC"
              value={project.project_pic[0].phone_number}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Email PIC<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="pic_email"
              type="text"
              placeholder="Email"
              // helpMessage="Email hanya digunakan untuk keperluan data"
              value={project.project_pic[0].email}
              disabled
            />
          </Col>
        </FormGroup>
      </AvForm>
    )
  }

  const renderFormUkmTwo = () => {
    return (
      <AvForm className="form-horizontal">
        <FormGroup row>
          <Label sm={3}>ID Onboarding Tahap 1</Label>
          <Col sm={9}>
            <PInput
              name="onboardingId"
              type="text"
              value={project.onboardingId}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Nomor KTP Direktur/Komisaris<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="ktp_director_number"
              type="text"
              placeholder="Nomor KTP"
              value={project.project_pic[0].ktp_director_number}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah KTP Direktur/Komisaris<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_pic[0].file_ktp_direktur}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Nomor NPWP Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="npwp_company_number"
              type="text"
              placeholder="Nomor NPWP"
              value={project.project_company.npwp_company_number}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah NPWP Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_npwp_company}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        {isCooperative && (
          <>
            <FormGroup row>
              <Label sm={3}>
                Nomor Induk Koperasi<span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <PInput
                  name="no_induk_koperasi"
                  type="text"
                  placeholder="Nomor Induk Koperasi"
                  value={project.project_company.no_induk_koperasi}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3}>
                Unggah Setifikat Nomor Induk Koperasi
                <span className="text-danger">*</span>
              </Label>
              <Col sm={9}>
                <Button
                  className="btn-sm"
                  onClick={() =>
                    window.open(
                      `${API_URL}${project.project_company.file_certificate_koperasi}`,
                      "_blank"
                    )
                  }
                >
                  <i className="fas fa-eye"></i>
                </Button>
              </Col>
            </FormGroup>
          </>
        )}

        <FormGroup row>
          <Label sm={3}>Nomor NIB Perusahaan</Label>
          <Col sm={9}>
            <PInput
              name="company_siup_number"
              type="text"
              value={project.project_company.company_siup_number}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah NIB<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_nib_company}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah SIUP Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_siup_company}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>
            Nomor Akta Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="akta_company_number"
              type="text"
              placeholder="Nomor Akta Perusahaan"
              value={project.project_company.akta_company_number}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah Akta Perusahaan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              disabled={!project.project_company.file_akta_company}
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_akta_company}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Akta Perubahan Terakhir<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="akta_last_change_number"
              type="text"
              placeholder="Akta Perubahan Terakhir"
              value={project.project_company.akta_last_change_number}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah Akta Perubahan Terakhir<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              disabled={!project.project_company.file_akta_last_change_number}
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_akta_last_change_number}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            SK Kemenkumhan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="sk_kemenkumham"
              type="text"
              placeholder="SK Kemenkumhan"
              value={project.project_company.sk_kemenkumham}
              disabled
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Unggah SK Kemenkumhan<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <Button
              className="btn-sm"
              disabled={!project.project_company.file_sk_kemenkumham}
              onClick={() =>
                window.open(
                  `${API_URL}${project.project_company.file_sk_kemenkumham}`,
                  "_blank"
                )
              }
            >
              <i className="fas fa-eye"></i>
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>
            Organisasi<span className="text-danger">*</span>
          </Label>
          <Col sm={9}>
            <PInput
              name="organitation_id"
              // type="select"
              // placeholder="Organisasi"
              value={
                project.project_company.organitation
                  ? project.project_company.organitation.title
                  : ""
              }
              disabled
            />
          </Col>
        </FormGroup>
        {project.project_company.file_organitation && (
          <FormGroup row>
            <Label sm={3}>
              Unggah Organisasi<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Button
                className="btn-sm"
                onClick={() =>
                  window.open(
                    `${API_URL}${project.project_company.file_organitation}`,
                    "_blank"
                  )
                }
              >
                <i className="fas fa-eye"></i>
              </Button>
            </Col>
          </FormGroup>
        )}

        {/* {project.ttd_borrower && (
          <FormGroup row>
            <Label sm={3}>
              Tanda Tangan PIC<span className="text-danger">*</span>
            </Label>
            <Col sm={9}>
              <Card className="w-50 border">
                <img src={API_URL + project.ttd_borrower} alt="ttd" />
              </Card>
            </Col>
          </FormGroup>
        )} */}
      </AvForm>
    )
  }

  if (!project) return null

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      // centered={true}
      className="exampleModal"
      tabindex="-1"
      // toggle={toggle}
      scrollable={true}
      size="lg"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => toggle(null)}>{project?.title}</ModalHeader>
        <ModalBody>
          {project.project_type.title === "Properti" && renderFormProperty()}
          {/* {project.project_type.title === "UKM" && project.status === 1 && renderFormUkmOne()}
          {project.project_type.title === "UKM" && project.status > 1 && renderFormUkmTwo()} */}
          {project.project_type.title === "UKM" && (
            <>
              {project.status === 1 ||
              project.status === 2 ||
              project.status === 4 ? (
                renderFormUkmOne()
              ) : (
                <>
                  <div>{renderFormUkmOne()}</div>
                  <div>{renderFormUkmTwo()}</div>
                </>
              )}
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalDetail
