import { API_URL } from "helpers/api_url";
import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";

const Message = props => {
  const { item, id, name, message, file } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      {/* <Button className="mr-1" color="secondary" id={"message-" + id}>
        {item.text}
      </Button> */}
      <div class="chatbox__body__message chatbox__body__message--right" id={"message-" + id}>
        <div class="ul_section_full">
          <ul class="ul_msg">
            <li className="bold font-14">{name}</li>
            <li className="font-12">{message}</li>
            {file && 
              <Button
                className="btn-sm mt-1 rounded-circle"
                onClick={() => window.open(`${API_URL}${file}`, "_blank") }
              >
                <i className="bx bxs-download"></i>
              </Button>
            }
          </ul>
          <div class="clearfix"></div>
        </div>
        {/* <span class="time_date_right"> 11:01 AM | June 9</span> */}
      </div>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen && item.length > 0}
        target={"message-" + id}
        toggle={toggle}
        // trigger="click"
      >
        <div className="text-left">Dibaca oleh:</div>
        {item.map(el => <div className="text-left">{el.user.first_name} {el.user.last_name}</div>)}
      </Tooltip>
    </span>
  );
};

export default Message