import React from "react"
import Skeleton from "react-loading-skeleton"
import PuffLoader from "react-spinners/PuffLoader"
import { Container } from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

export default (props) => {
  return (
    // <div style={{height: "100vh"}} className="d-flex align-items-center justify-content-center">
    //   <PuffLoader color={"#0ec6a2"} loading={true} size={120} />
    // </div>
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.title} breadcrumbItem={props.breadcrumbItem} />
          <div style={{ fontSize: 24, lineHeight: 1.5}}>
            <Skeleton height={30} />
            <div className="d-flex justify-content-between mt-4">
              <Skeleton />
              {/* <Skeleton width={300} /> */}
            </div>
            <Skeleton height={350} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}