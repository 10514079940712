import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Headroom from "headroom.js"
import "./index.css"
import { Link, withRouter } from "react-router-dom"
import NavItem from "./NavItem"
import About from "./About"
import Financing from "./Financing"
import NavbarMobile from "./NavbarMobile"
import logo_ethis from "../../assets/images/ethis/Logo-Website-Ethis-2021-new-02.png"

const Navbar = props => {
  const [activeMenu, setActiveMenu] = useState("")
  const [dropdownActive, setDropdownActive] = useState(false)
  // const [loading, setLoading] = useState(true);
  const handleActiveMenu = menu => {
    if (activeMenu === menu) {
      setActiveMenu("")
      setDropdownActive(false)
    } else {
      setActiveMenu(menu)
    }
  }
  const handleNonActiveDropdown = () => {
    setDropdownActive(false)
    setActiveMenu("")
  }
  const RenderOverlay = () => {
    return (
      <div
        className={`overlay ${dropdownActive ? "showNavbar" : ""}`}
        onClick={() => {
          setDropdownActive(false)
          setActiveMenu("")
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setDropdownActive(false)
            setActiveMenu("")
          }
        }}
      />
    )
  }

  const setHeadroom = () => {
    const headroom = new Headroom(document.querySelector("#headerNav"), {
      offset: 0,
      tolerance: 5,
      classes: {
        initial: "animated",
        pinned: "slideDown",
        unpinned: "slideUp",
      },
    })
    headroom.init()
  }

  useEffect(() => {
    setHeadroom()
  }, [])

  // console.log(props.user)

  return (
    <React.Fragment>
      {RenderOverlay()}
      <header id="headerNav" className="d-none d-md-block">
        <div className="d-none d-md-block top-bar-bg">
          <div className="d-flex justify-content-end align-items-center px-5 py-2 text-white font-12">
            <div className="d-flex align-items-center mr-4">
              <i class="bx bx-envelope mr-1"></i>
              <div>support@ethis.co.id</div>
            </div>
            <div className="d-flex align-items-center mr-4">
              <i class="bx bx-phone mr-1"></i>
              <div>Hubungi kami : +6221 5401817</div>
            </div>
            <div className="d-flex align-items-center">
              <div>TKB90=99,73%</div>
            </div>
          </div>
        </div>
        <div className="globalNav">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="logo-box">
                <Link to="/">
                  <img src={logo_ethis} alt="logo" className="logo-ethis" />
                </Link>
              </div>
              <div className="menu-home d-flex align-items-center">
                <ul className="nav-primary">
                  <li>
                    <NavItem
                      name="Tentang Kami"
                      setDropdownActive={setDropdownActive}
                      handleActiveMenu={handleActiveMenu}
                      activeMenu={activeMenu}
                      dropdownActive={dropdownActive}
                      content={
                        <About
                          handleNonActiveDropdown={handleNonActiveDropdown}
                        />
                      }
                      className="programsNav"
                    />
                  </li>
                  <li>
                    <NavItem
                      name="Pembiayaan"
                      setDropdownActive={setDropdownActive}
                      handleActiveMenu={handleActiveMenu}
                      activeMenu={activeMenu}
                      dropdownActive={dropdownActive}
                      content={
                        <Financing
                          handleNonActiveDropdown={handleNonActiveDropdown}
                          user={props.user}
                        />
                      }
                      className="aboutNav"
                    />
                  </li>
                </ul>
                {props.user ? (
                  <Link
                    to="/beranda"
                    className="btn btn-outline-primary w-xs font-weight-bold ml-4 d-flex align-items-center"
                  >
                    <span>Dasbor</span>{" "}
                    <i className="bx bx-log-in font-14 ml-1"></i>
                  </Link>
                ) : (
                  <>
                    <div className="mr-2">
                      <Link
                        to="/login"
                        className="btn btn-outline-primary w-xs"
                      >
                        Masuk
                      </Link>
                    </div>
                    <div className="">
                      <Link
                        to="/register"
                        className="btn btn-outline-primary w-xs"
                      >
                        Daftar
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <NavbarMobile
        toggle={() => setDropdownActive(!dropdownActive)}
        dropdownActive={dropdownActive}
        user={props.user}
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default withRouter(connect(mapStateToProps, null)(Navbar))
