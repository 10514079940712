import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  GET_PROFILE,
  KEEP_LOGIN,
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getUserLogin,
  postLogin,
  postUserLogout,
} from "../../../helpers/backend_helpers"

const checkRole = arr => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].role.title === "Lender" || arr[i].role.title === "Borrower") {
      return true
    }
  }
  return false
}

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })

    if (
      response.data?.roles.length === 1 &&
      response.data?.roles[0] === "Lender"
    ) {
      // return (window.location = `${process.env.REACT_APP_LENDER_URL}/token?token=${response.data?.token}`)
      return yield put(
        apiError({
          // code: 403,
          data: null,
          message: "Role tidak ditemukan.",
          success: false,
          trace: null,
        })
      )
    } else if (
      response.data?.roles.length === 1 &&
      response.data?.roles[0] === "Borrower"
    ) {
      // return (window.location = `${process.env.REACT_APP_LENDER_URL}/token?token=${response.data?.token}`)
      return yield put(
        apiError({
          // code: 403,
          data: null,
          message: "Role tidak ditemukan.",
          success: false,
          trace: null,
        })
      )
    } else {
      localStorage.setItem("token", response.data.token)

      const responseUser = yield call(getUserLogin, response.data.token)
      yield put(loginSuccess(responseUser.data))
      yield put(apiError(""))
      history.push("/beranda")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(postUserLogout, localStorage.getItem("token"))
    yield put(logoutUserSuccess(response))
    localStorage.removeItem("token")
    localStorage.removeItem("tokenImpersonater")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
    localStorage.removeItem("token")
    localStorage.removeItem("tokenImpersonater")
    history.push("/login")
  }
}

function* keepLoginUser({ payload: { token, history } }) {
  try {
    const responseUser = yield call(getUserLogin, token)
    yield put(loginSuccess(responseUser.data))
  } catch (error) {
    localStorage.removeItem("token")
    yield put(apiError(error))
    history.push("/login")
  }
}

function* getUserProfile({ payload: { token } }) {
  try {
    const responseUser = yield call(getUserLogin, token)
    yield put(loginSuccess(responseUser.data))
  } catch (error) {
    // localStorage.removeItem("token")
    yield put(apiError(error))
    // history.push("/login")
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(KEEP_LOGIN, keepLoginUser)
  yield takeEvery(GET_PROFILE, getUserProfile)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
