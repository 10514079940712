import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row className="text-center">
            {/* <Col md={6}>{new Date().getFullYear()} © Ethis Fintek Indonesia.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Develop by Ethis Version 1.2.3
              </div>
            </Col> */}
            <Col>
              <div>{new Date().getFullYear()} © PT Ethis Fintek Indonesia.</div>
              <p>Version 1.4.5</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
