import React, { useEffect, useState } from "react"
import { get } from "helpers/api_helpers"
import { GET_PROVINCE, GET_USER_LOGIN } from "helpers/url_helpers"
import { connect } from "react-redux"
import { Card, CardBody, Col, Container, Row, Badge } from "reactstrap"
import Skeleton from "react-loading-skeleton"

// actions
import { getProfile, keepLogin, setLoading } from "store/actions"

import {
  getProvince,
  getBank,
  getTitle,
  getEducation,
  getJob,
  getJobSector,
  getMarried,
  getGender,
  getMonthInCome,
  getReligion,
  getSourceFunding,
  getCities,
  getDistrict,
  getSubDistrict,
  postStepOne,
  postStepTwo,
  postStepThree,
  getCitiesAll,
} from "helpers/backend_helpers"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import FormKYC from "./FormKYC"
import FormEditKYC from "./FormEditKYC"
import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import SkeletonLoad from "components/Loading/SkeletonLoad"

const masterKyc = {
  province: [],
  city: [],
  district: [],
  subDistrict: [],
  bank: [],
  title: [],
  education: [],
  job: [],
  jobSector: [],
  married: [],
  gender: [],
  getIncome: [],
  religion: [],
  sourceFunding: [],
  cityBank: [],
}

const Profile = props => {
  const { getProfile, keepLogin } = props
  const [masterData, setMasterData] = useState(masterKyc)
  const [user, setUser] = useState(props.user)
  const [load, setLoad] = useState(false)

  useEffect(() => {
    setUser(props.user)
    getMasterDataKYC()
  }, [props.user])

  const getMasterDataKYC = async () => {
    const token = localStorage.getItem("token")
    if (user.kyc_status > 1) {
      setLoad(true)
      try {
        const province = await getProvince(token)
        const kota = await getCities(user.address[0].province.uuid, token)
        const kecamatan = await getDistrict(user.address[0].city.uuid, token)
        const kelurahan = await getSubDistrict(
          user.address[0].district.uuid,
          token
        )
        const bank = await getBank(token)
        const title = await getTitle(token)
        const education = await getEducation(token)
        const job = await getJob(token)
        const jobSector = await getJobSector(token)
        const married = await getMarried(token)
        const gender = await getGender(token)
        const getIncome = await getMonthInCome(token)
        const religion = await getReligion(token)
        const sourceFunding = await getSourceFunding(token)
        const cityBank = await getCitiesAll(token)

        setMasterData({
          ...masterData,
          province: province.data,
          bank: bank.data,
          title: title.data,
          education: education.data,
          job: job.data,
          jobSector: jobSector.data,
          married: married.data,
          gender: gender.data,
          getIncome: getIncome.data,
          religion: religion.data,
          sourceFunding: sourceFunding.data,
          cityBank: cityBank.data,
          city: kota.data,
          district: kecamatan.data,
          subDistrict: kelurahan.data,
        })
        setLoad(false)
      } catch (error) {
        setLoad(false)
        Toaster("error", "Ups", "Refresh browser anda")
      }
    } else {
      setLoad(true)
      try {
        const province = await getProvince(token)
        const bank = await getBank(token)
        const title = await getTitle(token)
        const education = await getEducation(token)
        const job = await getJob(token)
        const jobSector = await getJobSector(token)
        const married = await getMarried(token)
        const gender = await getGender(token)
        const getIncome = await getMonthInCome(token)
        const religion = await getReligion(token)
        const sourceFunding = await getSourceFunding(token)
        const cityBank = await getCitiesAll(token)

        setMasterData({
          ...masterData,
          province: province.data,
          bank: bank.data,
          title: title.data,
          education: education.data,
          job: job.data,
          jobSector: jobSector.data,
          married: married.data,
          gender: gender.data,
          getIncome: getIncome.data,
          religion: religion.data,
          sourceFunding: sourceFunding.data,
          cityBank: cityBank.data,
        })
        setLoad(false)
      } catch (error) {
        setLoad(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  // console.log(props.user)

  const renderStatusKYC = status => {
    let initial = ""
    switch (status) {
      case 1:
        initial = (
          <h4>
            <Badge color="info">Lengkapi profil anda</Badge>
          </h4>
        )
        break
      case 2:
        initial = (
          <h4>
            <Badge color="info">Lengkapi profil anda</Badge>
          </h4>
        )
        break
      case 3:
        initial = (
          <h4>
            <Badge color="info">Lengkapi profil anda</Badge>
          </h4>
        )
        break
      case 4:
        initial = (
          <h4>
            <Badge color="info">Menunggu Verifikasi admin</Badge>
          </h4>
        )
        break
      case 5:
        initial = (
          <h4>
            <Badge color="info">Menunggu Verifikasi PrivyID</Badge>
          </h4>
        )
        break
      case 6:
        initial = (
          <h4>
            <Badge color="danger">
              {props.user.approval_history[
                props.user.approval_history.length - 1
              ].reason.title === "Lain-nya"
                ? props.user.approval_history[
                    props.user.approval_history.length - 1
                  ].description
                : props.user.approval_history[
                    props.user.approval_history.length - 1
                  ].reason.title}
            </Badge>
          </h4>
        )
        break
      case 7:
        initial = (
          <h4>
            <Badge color="danger">
              {/* Ditolak PrivyID:{" "} */}
              {
                JSON.parse(
                  props.user.status_privy[props.user.status_privy.length - 1]
                    .reject_reason
                ).reason
              }{" "}
              (Mohon perbarui Dokumen Anda)
            </Badge>
          </h4>
        )
        break
      case 8:
        initial = (
          <h4>
            <Badge color="danger">
              {/* Ditolak PrivyID:{" "} */}
              {
                JSON.parse(
                  props.user.status_privy[props.user.status_privy.length - 1]
                    .reject_reason
                ).reason
              }{" "}
              (Mohon perbarui Dokumen Anda)
            </Badge>
          </h4>
        )
        break
      case 9:
        initial = (
          <h4>
            <Badge color="primary">Profil anda telah terverifikasi</Badge>
          </h4>
        )
        break
      case 10:
        initial = (
          <h4>
            <Badge color="primary">Profil anda telah terverifikasi</Badge>
          </h4>
        )
        break
      default:
        initial = "Status not found"
        break
    }
    return initial
  }

  // if(load) return <Loading />
  if (load) {
    return (
      <SkeletonLoad title="Profil" breadcrumbItem="Profil">
        <Card>
          <CardBody>
            <div style={{ fontSize: 20, lineHeight: 2.5 }}>
              <Skeleton width={280} height={24} />
              <Skeleton height={48} />
              <Skeleton height={12} />
            </div>
            <Row className="px-xs-0 mx-xs-0 px-md-5 mx-md-5">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
                return (
                  <Col xs={12} md={6}>
                    <div style={{ lineHeight: 2.5 }}>
                      <Skeleton width={100} height={16} />
                      <Skeleton height={24} />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profil" breadcrumbItem="profil" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between mb-2">
                    <h4 className="card-title">
                      {renderStatusKYC(user.kyc_status)}
                    </h4>
                  </div>

                  {user.kyc_status <= 5 && (
                    <FormKYC
                      user={user}
                      masterData={masterData}
                      setMasterData={setMasterData}
                      getProfile={getProfile}
                      keepLogin={keepLogin}
                      load={load}
                      setLoad={setLoad}
                    />
                  )}

                  {user.kyc_status >= 6 && (
                    <FormEditKYC
                      user={user}
                      masterData={masterData}
                      setMasterData={setMasterData}
                      getProfile={getProfile}
                      keepLogin={keepLogin}
                      load={load}
                      setLoad={setLoad}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, { getProfile, keepLogin, setLoading })(
  Profile
)
