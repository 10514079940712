import React, { useEffect, useState } from "react"
import UnderContruction from "./UnderContruction"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  Button,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import classnames from "classnames"

import img_webinar from "../../assets/images/ethis/Web-Page-Webinar_-e-talk-10.jpg"
import img_free from "./img/Webinar-Icon-free-300x144.png"
import img_sertifikat from "./img/Webinar-Icon-Certificate-300x144.png"
import img_book from "./img/Webinar-Icon-Ebook-300x144.png"
import { getRecentWebinar, getResponseWebinar, getWebinarBySlug, getWebinarEthis, getWebinarPublic, registerWebinar } from "helpers/backend_helpers"
import { API_URL, API_URL_1 } from "helpers/api_url"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import Toaster from "components/Common/Toast"
import Skeleton from "react-loading-skeleton"
import { withRouter } from "react-router-dom"
import { Helmet } from "react-helmet"

const list_webinar = [
  {
    title: "E-Talk 7: Digitalisasi Keuangan Syariah Menuju Efisiensi Masyarakat",
    url_video: "https://www.youtube.com/embed/maKbeVhAxWs"
  },
  {
    title: "E-Talk 5 Fintech Syari'ah Meningkatkan Inklusi Keuangan",
    url_video: "https://www.youtube.com/embed/Ca7XrY1cfO8"
  },
  {
    title: "E Talk Tazki Bijak Memilih Sebelum Memanfaatkan",
    url_video: "https://www.youtube.com/embed/vkUtSCk3NPY"
  },
  {
    title: "E Talk_Ekosistem Pembiayaan Syariah Melalui Fintech",
    url_video: "https://www.youtube.com/embed/VZp2YJw-mjo"
  },
  {
    title: "E-Talk_ Optimalisasi Penguatan Ekonomi Bersama Teknologi Keuangan Syariah (Unida)",
    url_video: "https://www.youtube.com/embed/CnRlGWroZvk"
  },
  {
    title: "Webinar#3 Potensi Berbisnis Property Di Indonesia",
    url_video: "https://www.youtube.com/embed/MtTiOPQrcm8"
  },
  {
    title: "E-Talk_ Peluang Implementasi Keuangan Syari’ah pada Industri 4.0",
    url_video: "https://www.youtube.com/embed/bR8S0hmzpe0"
  },
]

const listWebinar = (item, token, history) => (
  <Col lg="12" className="mb-5">
    <Row>
      <Col lg="4"><img src={API_URL + `${item.files.length > 0 ? item.files[0].file : ""}`} alt="webinar-list" className="w-100" /></Col>
      <Col lg="8">
        <div className="bold font-28 mb-3">{item.title}</div>
        <p>{item.description.split(/\n/).map(line => <>{line}<br/></>)}</p>
        <p className="bold">
          {`${moment(item.date).format("dddd, DD MMMM YYYY | HH:MM")} WIB.`}
        </p>
        <p>
          <Button color="primary" className="" onClick={() => history.push(`/daftar-webinar/${item.slug}`)} disabled={item.isUserEthis == 1 ? token ? false : true : false}>Daftar</Button>
          {item.isUserEthis == 1 && !token ? 
            <div className="mt-2 bold">*Catatan: Hanya untuk Pengguna Ethis, Silahkan Login terlebih dahulu.</div>
            : null
          }
        </p>
        <p className=" bold">Bila ada pertanyaan, jangan ragu untuk menghubungi kami di:<br /> 0877-4110-4555 (wa)/ fariz@ethisindonesia.com</p>
      </Col>
    </Row>
  </Col>
)

const DetailWebinar = props => {
  const token = localStorage.getItem("token")
  const paramId = props.match.params.slug
  const [activeTab, setactiveTab] = useState(0)
  const [loadButt, setLoadButt] = useState(false)
  const [webinar, setWebinar] = useState(null)
  const [load, setLoad] = useState(false)
  const [modalRegister, setModalRegister] = useState(false)
  const [select, setSelect] = useState(null)

  const toggleModalRegister = (item = null) => {
    setSelect(item)
    setModalRegister(!modalRegister)
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoad(true)
    const data = await getWebinarBySlug(paramId)
    setWebinar(data.data)
    setLoad(false)
  }

  const handleRegisterWebinar = async (e, value) => {
    let newValue = {
      ...value
    }
    let arrQuestion = [...select.webinar_question, ...select.webinar_question_choice]
    arrQuestion.forEach((el, idx) => {
      newValue[`answers[${idx}][uuid_question]`] = el.id
      newValue[`answers[${idx}][type]`] = el.title
      newValue[`answers[${idx}][dataOrUuid]`] = newValue[el.id]

      delete newValue[el.id]
    })

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await registerWebinar(select.id, formData)
      toggleModalRegister(null)
      setLoadButt(false)
      Toaster("success", "Terima Kasih", "Anda telah terdaftar sebagai peserta Webinar")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderRegister = () => {
    if(true) {
      return (
        <Modal
          isOpen={modalRegister}
          role="dialog"
          autoFocus={true}
          scrollable
          size="lg"
        >
          <ModalHeader toggle={() => toggleModalRegister(null)}>{select && select.title}</ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleRegisterWebinar(e, v)
              }}
            >
              <PInput
                name="name"
                type="text"
                label="Nama"
                placeholder="Nama"
                validate={{
                  required: {value: true, errorMessage: 'Nama harus diisi'},
                }}
              />
              <PInput
                name="email"
                type="email"
                label="Email"
                placeholder="Email"
                validate={{
                  required: {value: true, errorMessage: 'Email harus diisi'},
                  email: {value: true, errorMessage: 'Format email salah'},
                }}
              />
              <PInput
                name="phone_number"
                type="text"
                label="Telepon"
                placeholder="Contoh. 62812xxx atau 0812xxx"
                validate={{
                  required: {value: true, errorMessage: 'Nama harus diisi'},
                  pattern: {value: '^(^62|^08).{9,12}$', errorMessage: 'Format No Handphone salah. Contoh: 62812xxx atau 0812xxx'}
                }}
              />

              {select && select.webinar_question.map(item => {
                return (
                  <PInput
                    name={item.id}
                    type="text"
                    label={item.objectQuestion}
                    validate={{
                      required: {value: true, errorMessage: 'Field harus diisi'},
                    }}
                  />
                )
              })}

              {select && select.webinar_question_choice.map(item => {
                return (
                  <PInput
                    name={item.id}
                    type="select"
                    label={item.objectQuestion}
                    validate={{
                      required: {value: true, errorMessage: 'Field harus diisi'},
                    }}
                    options={item.webinar_question_option.map(inItem => {
                      return {uuid: inItem.id, title: inItem.title}
                    })}
                  />
                )
              })}
              
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Daftar'}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>
      )
    }
  }

  if(!paramId) return <Redirect to="/webinar" />

  if(load || !webinar) {
    return (
      <div className="wrapper-body">
        <Skeleton height={280} />
        <Row className="mt-5">
          {[1, 2].map(item => {
            return (
              <Col lg="6">
                <Skeleton height={600} />
              </Col>
            )
          })}
        </Row>
      </div>
    )
  }

  // console.log(webinar)
  return (
    <React.Fragment>
      <Helmet>
        <title>{paramId}</title>
        <meta name="description" content={paramId} />
        <meta name="keywords" content="peer to peer syariah, p2p syariah, investasi, pembiayaan, p2p lending syariah" />
        <link rel="canonical" href={`https://ethis.co.id/daftar-webinar/${paramId}`} />
      </Helmet>
      {renderRegister()}
      <div className="wrapper-body">
        {/* <UnderContruction /> */}
        <div className="fancy-header-bg-webinar">
          <div className="text-center">
            <div className="font-40 bold base">Webinar Ethis</div>
            <div className="font-14 base">WEBINAR ETHIS</div>
          </div>
        </div>

        <div className="text-justify font-14 py-5">

          <div className="container">
            <div className="text-center bold mb-5">
              <div className="font-32 base bold">Daftar Webinar</div>
            </div>
            
            <Row>
              {webinar && webinar.isActive == 1 ? 
                <Col lg="12" className="mb-5">
                  <Row>
                    <Col lg="6"><img src={API_URL + `${webinar.files.length > 0 ? webinar.files[0].file : ""}`} alt="webinar-list" className="w-100" /></Col>
                    <Col lg="6">
                      <Card className="bg-light">
                        <CardBody>
                          <div className="bold text-dark font-24 mb-4">{webinar.title}</div>
                          <p>{webinar.description.split(/\n/).map(line => <>{line}<br/></>)}</p>
                          <p>
                            <div className="bold">Simak lebih dalam pada Webinar:</div>
                            <div className="bold">{webinar.title}</div>
                            <div className="bold">
                            {`${moment(webinar.date).format("dddd, DD MMMM YYYY | HH:MM")} WIB.`}
                            </div>
                            {/* <div className="bold">Pembicara : Sarah Silvia (Senior Social Media Executive RevoU dan Founder Halal Foodventures)</div> */}
                            {/* <div className="bold">
                              Bahasan: 
                              <ul>
                                <li>Bagaimana membangun Personal Branding untuk para pencari kerja dengan  menggunakan LinkedIn</li>
                                <li>Live Demo Personal Branding</li>
                              </ul>
                            </div> */}
                          </p>
                          <p className="bold">Daftar sekarang juga, Gratis !!</p>
                          <p>
                            <Button color="primary" className="" onClick={() => toggleModalRegister(webinar)} disabled={webinar.isUserEthis == 1 ? token ? false : true : false}>Daftar Sekarang</Button>
                            {webinar.isUserEthis == 1 && !token ? 
                              <div className="mt-2 bold">*Catatan: Hanya untuk Pengguna Ethis, Silahkan Login terlebih dahulu.</div>
                              : null
                            }
                          </p>
                          <p className=" bold">Bila ada pertanyaan, jangan ragu untuk menghubungi kami di:<br /> 0877-4110-4555 (wa)/ fariz@ethisindonesia.com</p>
                        </CardBody>
                      </Card>
                      <div className="d-flex flex-row justify-content-center text-center bold text-dark mt-2">
                        <div>
                          <img src={img_free} alt="free" className="w-100" />
                          <div className="mt-3">Tanpa Biaya</div>
                        </div>
                        <div>
                          <img src={img_sertifikat} alt="free" className="w-100" />
                          <div className="mt-3">E-Sertifikat</div>
                        </div>
                        <div>
                          <img src={img_book} alt="free" className="w-100" />
                          <div className="mt-3">E-Book</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              :
                <Col className=" text-center bold">
                  {/* <div className="font-32 base bold mb-4">Formulir Pendaftaran</div> */}
                  <div className="text-dark font-20 mb-4">Maaf webinar telah berakhir. Nantikan webinar selanjutnya.</div>
                  <div className="text-dark font-20">Bila ada pertanyaan, jangan ragu untuk menghubungi kami di:</div>
                  <div className="text-dark font-20">0877-4110-4555 (wa)/ fariz@ethisindonesia.com</div>
                </Col>
              }
            </Row>

            {/* <div className="pt-5 text-center bold mb-5">
              <div className="font-32 base bold">Webinar Sebelumnya</div>
            </div> */}

            {/* <Row className="">
              <Col lg="12">
                <div className="vertical-nav">
                  <Row>
                    <Col lg="4" sm="4">
                      <Nav pills className="flex-column">
                        {[...filterWebinarNotActive, ...list_webinar].map((item, i) => {
                          return (
                            <NavLink
                              className={`${classnames({ active: activeTab === i })} p-2`}
                              onClick={() => {
                                setactiveTab(i)
                              }}
                              key={i}
                            >
                              <p className="font-weight-bold mb-0 text-justify">
                                {item.title}
                              </p>
                            </NavLink>
                          )
                        })}
                      </Nav>
                    </Col>
                    <Col lg="8" sm="8">
                      <Card>
                        <CardBody>
                          <TabContent activeTab={activeTab}>
                            {[...filterWebinarNotActive, ...list_webinar].map((item, i) => {
                              return (
                                <TabPane tabId={i} className="text-center">
                                  {item.url_video ?
                                    <iframe width="100%" height="400" src={item.url_video} title={item.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    :
                                    <img src={API_URL + item.files[0].file} alt="webinar" height="460px" />
                                  }
                                </TabPane>
                              )
                            })}
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DetailWebinar)