import axios from "axios"
import { Redirect } from "react-router-dom"
// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = `${process.env.REACT_APP_API_URL}/api`
const API_URL_BLOG = `${process.env.REACT_APP_API_URL}/blog-webinar/api`
const API_URL_HELPDESK = `${process.env.REACT_APP_API_URL}/helpdesk/api`

const axiosApi = axios.create({
  baseURL: API_URL,
})

const axiosApiBlog = axios.create({
  baseURL: API_URL_BLOG,
})

const axiosApiHelpdesk = axios.create({
  baseURL: API_URL_HELPDESK,
})

axiosApi.defaults.headers.common["apiKey"] = process.env.REACT_APP_API_KEY

axiosApiBlog.defaults.headers.common["apiKey"] = process.env.REACT_APP_API_KEY

axiosApiHelpdesk.defaults.headers.common["apiKey"] =
  process.env.REACT_APP_API_KEY

axiosApi.interceptors.response.use(
  response => response,
  // error => Promise.reject(error)
  error => {
    // console.log('back', error.response.status)
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("tokenImpersonater")
      window.location.reload()
      // window.location.href =
      // return <Redirect to="/login" />
    }
    return Promise.reject(error)
  }
)

axiosApiBlog.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("tokenImpersonater")
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

axiosApiHelpdesk.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token")
      localStorage.removeItem("tokenImpersonater")
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

export async function getData(url, token = "", locale = "id") {
  return await axiosApi
    .get(url, {
      headers: { Authorization: `Bearer ${token}`, "x-localization": locale },
    })
    .then(response => response.data)
}

export async function postData(url, data, token = "") {
  return axiosApi
    .post(url, data, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function putData(url, data, token = "") {
  return axiosApi
    .put(url, { ...data }, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function delData(url, token = "") {
  return await axiosApi
    .delete(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

// blog
export async function getDataBlog(url, token = "") {
  return await axiosApiBlog
    .get(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function postDataBlog(url, data, token = "") {
  return axiosApiBlog
    .post(url, data, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function putDataBlog(url, data, token = "") {
  return axiosApiBlog
    .put(url, { ...data }, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function delDataBlog(url, token = "") {
  return await axiosApiBlog
    .delete(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

// helpdesk
export async function getDataHelpdesk(url, token = "") {
  return await axiosApiHelpdesk
    .get(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function postDataHelpdesk(url, data, token = "") {
  return axiosApiHelpdesk
    .post(url, data, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function putDataHelpdesk(url, data, token = "") {
  return axiosApiHelpdesk
    .put(url, { ...data }, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}

export async function delDataHelpdesk(url, token = "") {
  return await axiosApiHelpdesk
    .delete(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => response.data)
}
