import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import {
  getScoreAll,
  getScoringResult,
  postApproveRisk,
} from "helpers/backend_helpers"

const ModalApprove = props => {
  const pefScore = JSON.parse(localStorage.getItem("pefindo"))
  const { isOpen, toggle, item, getData } = props
  const [loadButt, setLoadButt] = useState(false)
  const [scores, setScores] = useState(null)
  const [pefindoScore, setPefindoScore] = useState("")
  const [fdc, setFdc] = useState([])

  // console.log(scores)

  useEffect(() => {
    if (isOpen) {
      getDataScores()
      // getScore()
    }
  }, [item])

  useEffect(() => {
    if (isOpen && pefScore && item) {
      getPefindoScore()
    }
  }, [item])

  const getDataScores = async () => {
    const token = localStorage.getItem("token")
    if (item) {
      const data = await getScoreAll(item.uuid, token)
      setScores(data.data)
    }
  }

  const getScore = async () => {
    if (item) {
      const data = await getScoringResult(item.uuid, token)
      setFdc(data.data)
    }
  }

  const handleScoring = num => {
    let result
    if (num < 66) {
      result = "C (Tolak)"
    } else if (num >= 66 && num < 71) {
      result = "C+"
    } else if (num >= 71 && num < 76) {
      result = "B-"
    } else if (num >= 76 && num < 81) {
      result = "B"
    } else if (num >= 81 && num < 86) {
      result = "B+"
    } else if (num >= 86 && num < 91) {
      result = "A-"
    } else if (num >= 91 && num < 96) {
      result = "A"
    } else if (num >= 96 && num <= 100) {
      result = "A+"
    } else {
      result = null
    }
    return result
  }

  const handleValidSubmit = async (e, value) => {
    const token = localStorage.getItem("token")

    let countResult = 0
    if (item.project_type.title == "UKM") {
      let riskScore = parseFloat(value.penilaian_internal) * 0.5
      let pefindoScore = parseFloat(value.penilaian_pefindo) * 0.5

      countResult = riskScore + pefindoScore
      // console.log(countResult)
    } else {
      let riskScore = parseFloat(value.penilaian_internal) * 0.25
      let pefindoScore = parseFloat(value.penilaian_pefindo) * 0.5
      let tinLap = parseFloat(value.penilaian_lapangan) * 0.25

      countResult = riskScore + pefindoScore + tinLap
      // console.log(countResult)
    }

    // console.log(handleScoring(countResult))
    let scoreResult = handleScoring(Math.round(countResult))

    const newValue = {
      score: scoreResult,
      penilaian_lapangan: null,
      // score_akhir: `${Math.round(countResult)}`,
      score_akhir: `${countResult.toFixed(2)}`,
      ...value,
    }

    // console.log(newValue)

    setLoadButt(true)
    try {
      await postApproveRisk(item.uuid, newValue, token)
      setScores(null)
      setPefindoScore("")
      toggle(null)
      getData()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const getPefindoScore = async () => {
    // console.log(item)
    // console.log(pefScore)
    if (item.project_company.npwp_company_number == pefScore.npwp) {
      if (pefScore.score == "999") {
        setPefindoScore("0")
      } else {
        setPefindoScore(pefScore.score)
      }
    }
  }

  const renderResultScore = () => {
    let pefindoResultScore = (parseFloat(pefindoScore) / 9).toFixed(2)

    if (
      item.project_type.title === "UKM" &&
      parseInt(scores.analisa_resiko) > 0
    ) {
      return (
        <Card className="border">
          <CardHeader className="p-2">
            <b>Hasil</b>
          </CardHeader>
          <CardBody className="p-2">
            <table class="table table-striped border">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Skor</th>
                  <th scope="col">Kontribusi Max</th>
                  <th scope="col">Kontribusi Skor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pefindo Skor</td>
                  <td>{pefindoResultScore}</td>
                  <td>50%</td>
                  <td>{(pefindoResultScore * 0.5).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Internal + FDC Skor</td>
                  <td>{scores.analisa_resiko}</td>
                  <td>50%</td>
                  <td>{parseFloat(scores.analisa_resiko) * 0.5}</td>
                </tr>
                <tr>
                  <th colSpan="3">Skor Akhir</th>
                  <th>
                    {(
                      pefindoResultScore * 0.5 +
                      parseFloat(scores.analisa_resiko) * 0.5
                    ).toFixed(2)}
                  </th>
                </tr>
                <tr>
                  <th colSpan="3">Nilai</th>
                  <th>
                    {handleScoring(
                      pefindoResultScore * 0.5 +
                        parseFloat(scores.analisa_resiko) * 0.5
                    )}
                  </th>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      )
    } else if (
      item.project_type.title === "Properti" &&
      parseInt(scores.analisa_resiko) > 0 &&
      parseInt(scores.tinjauan_lokasi) > 0
    ) {
      return (
        <Card className="border">
          <CardHeader className="p-2">
            <b>Hasil</b>
          </CardHeader>
          <CardBody className="p-2">
            <table class="table table-striped border">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Skor</th>
                  <th scope="col">Kontribusi Max</th>
                  <th scope="col">Kontribusi Skor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pefindo Skor</td>
                  <td>{pefindoResultScore}</td>
                  <td>50%</td>
                  <td>{(pefindoResultScore * 0.5).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Tinjauan Lokasi</td>
                  <td>{scores.tinjauan_lokasi}</td>
                  <td>25%</td>
                  <td>{parseFloat(scores.tinjauan_lokasi) * 0.25}</td>
                </tr>
                <tr>
                  <td>Internal + FDC Skor</td>
                  <td>{scores.analisa_resiko}</td>
                  <td>25%</td>
                  <td>{parseFloat(scores.analisa_resiko) * 0.25}</td>
                </tr>
                <tr>
                  <th colSpan="3">Skor Akhir</th>
                  <th>
                    {(
                      pefindoResultScore * 0.5 +
                      parseFloat(scores.analisa_resiko) * 0.25 +
                      parseFloat(scores.tinjauan_lokasi) * 0.25
                    ).toFixed(2)}
                  </th>
                </tr>
                <tr>
                  <th colSpan="3">Nilai</th>
                  <th>
                    {handleScoring(
                      pefindoResultScore * 0.5 +
                        parseFloat(scores.analisa_resiko) * 0.25 +
                        parseFloat(scores.tinjauan_lokasi) * 0.25
                    )}
                  </th>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      )
    }
    return null
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      // centered={true}
      className="exampleModal"
      tabindex="-1"
      // toggle={toggle}
      scrollable={true}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            setScores(null)
            setPefindoScore("")
            toggle(null)
          }}
        >
          {item && item.title}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            {/* <PInput 
              name="score"
              label="Skor Proyek"
              type="text"
              placeholder="Skor Proyek"
              helpMessage="ex. A"
              validate={{
                required: {value: true, errorMessage: 'Skor Proyek harus diisi'},
              }}
            /> */}
            <PInput
              name="penilaian_internal"
              label="Penilaian Internal(Max. 60) + Skor FDC(Max. 40)"
              type="number"
              // placeholder="Penilaian Internal"
              helpMessage="Kontribusi Skor 50% (Max 100)."
              validate={{
                required: {
                  value: true,
                  errorMessage: "Isi form penilain internal terlebih dahulu",
                },
                max: { value: 100, errorMessage: "Maksimal 100" },
                min: {
                  value: 1,
                  errorMessage: "Isi form penilain internal terlebih dahulu",
                },
              }}
              value={scores ? `${scores.analisa_resiko}` : ""}
              disabled
            />
            <PInput
              name="penilaian_pefindo_hide"
              label="Penilaian Pefindo"
              type="number"
              // placeholder="Penilaian Pefindo"
              helpMessage={`${
                item && item.project_type.title == "UKM"
                  ? "Konversi Skor pefindo dibagi 9 (Max 900)."
                  : "Konversi Skor pefindo dibagi 9 (Max 900)."
              }`}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Penilaian Pefindo harus diisi",
                },
                max: { value: 900, errorMessage: "Maksimal 900" },
                min: { value: 0, errorMessage: "Minimal 0" },
              }}
              onChange={e => setPefindoScore(e.target.value)}
              // disabled
              value={pefindoScore}
            />
            <PInput
              name="penilaian_pefindo"
              label="Konversi Pefindo"
              type="number"
              // placeholder="Penilaian Pefindo"
              helpMessage={`${
                item && item.project_type.title == "UKM"
                  ? "Kontribusi Skor 50% (Max 100)."
                  : "Kontribusi Skor 25% (Max 100)."
              }`}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Penilaian Pefindo harus diisi",
                },
                max: { value: 100, errorMessage: "Maksimal 900" },
                min: { value: 0, errorMessage: "Minimal 0" },
              }}
              disabled
              value={
                pefindoScore &&
                parseFloat(pefindoScore) >= 0 &&
                parseFloat(pefindoScore) <= 900
                  ? (parseFloat(pefindoScore) / 9).toFixed(2)
                  : ""
              }
            />
            {item && item.project_type.title == "Properti" ? (
              <PInput
                name="penilaian_lapangan"
                label="Penilaian Tinjau Lapangan"
                type="number"
                // placeholder="Penilaian Pefindo"
                helpMessage="Kontribusi Skor 50% (Max 100)."
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Isi form tinjau lapangan terlebih dahulu",
                  },
                  max: { value: 100, errorMessage: "Maksimal 100" },
                  min: {
                    value: 1,
                    errorMessage: "Isi form tinjau lapangan terlebih dahulu",
                  },
                }}
                value={scores ? `${scores.tinjauan_lokasi}` : ""}
                disabled
              />
            ) : null}
            {scores &&
            pefindoScore &&
            parseInt(pefindoScore) <= 900 &&
            parseInt(pefindoScore) >= 0 &&
            item
              ? renderResultScore()
              : null}
            <PInput
              name="description"
              label="Deskripsi"
              type="textarea"
              placeholder="Deskripsi"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Deskripsi harus diisi",
                },
              }}
            />
            <button
              className="btn btn-primary btn-block waves-effect waves-light mt-3"
              type="submit"
              disabled={loadButt}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                </>
              ) : (
                "Simpan"
              )}
            </button>
          </AvForm>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalApprove
