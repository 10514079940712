import { API_URL } from "helpers/api_url"
import {
  getPayoutUser,
  getPortofolio,
  getTransaction,
  getTRX,
  postDocMus,
  postDocWak,
} from "helpers/backend_helpers"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
  Table,
  UncontrolledCarousel,
} from "reactstrap"
import classnames from "classnames"
import moment from "moment"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  Nav,
  CardSubtitle,
  CardHeader,
  Progress,
  CardImg,
} from "reactstrap"
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loading from "pages/Utility/Loading"
import Toaster from "components/Common/Toast"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SkeletonLoad from "components/Loading/SkeletonLoad"
import Skeleton from "react-loading-skeleton"

const PortfolioDetail = props => {
  const [trx, setTrx] = useState(null)
  const [activeTab, setactiveTab] = useState("1")
  const [loading, setLoading] = useState(false)
  const [payout, setPayout] = useState([])

  const [lightBox, setLightBox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const [modalAkad, setModalAkad] = useState(false)
  const [urlDocument, setUrlDocument] = useState("")
  const [akadName, setAkadName] = useState("")

  const toggleAkad = () => setModalAkad(!modalAkad)

  useEffect(() => {
    handleGetTrx()
  }, [])

  const handleGetTrx = async () => {
    setLoading(true)
    const token = localStorage.getItem("token")
    const data = await getTRX(props.match.params.uuid, token)
    const payment = await getPayoutUser(props.match.params.uuid, token)
    setTrx(data.data)
    setPayout(payment.data)
    setLoading(false)
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const handleWakalah = async () => {
    if (trx.privy_document_wakalah.length > 0) {
      // window.open(trx.privy_document_wakalah[0].url_document, "_blank")
      setUrlDocument(trx.privy_document_wakalah[0].url_document)
      setAkadName("Wakalah")
      toggleAkad()
    } else {
      setLoading(true)
      const token = localStorage.getItem("token")
      let post = {
        documentTitle: "Akad Wakalah",
        campaign_transaction_id: trx.uuid,
      }
      try {
        const data = await postDocWak(trx.campaign.uuid, post, token)
        handleGetTrx()
        setLoading(false)
        // window.open(data.data.url_document, "_blank")
        setUrlDocument(data.data.url_document)
        setAkadName("Wakalah")
        toggleAkad()
      } catch (error) {
        setLoading(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const handleMusyarakah = async () => {
    if (trx.privy_document_musyarakah.length > 0) {
      // window.open(trx.privy_document_musyarakah[0].url_document, "_blank")
      setUrlDocument(trx.privy_document_musyarakah[0].url_document)
      setAkadName("Musyarakah")
      toggleAkad()
    } else {
      setLoading(true)
      const token = localStorage.getItem("token")
      let post = {
        documentTitle: "Akad Musyarakah",
        campaign_transaction_id: trx.uuid,
      }
      try {
        const data = await postDocMus(trx.campaign.uuid, post, token)
        handleGetTrx()
        setLoading(false)
        // window.open(data.data.url_document, "_blank")
        setUrlDocument(data.data.url_document)
        setAkadName("Musyarakah")
        toggleAkad()
      } catch (error) {
        setLoading(false)
        Toaster("error", "Ups", error.response.data.message)
      }
    }
  }

  const handleAddendum = async () => {
    setUrlDocument(trx.privy_document_addendum[0].url_document)
    setAkadName("Addendum")
    toggleAkad()
  }

  const handleNisbah = () => {
    let modal = Number(trx.payment_amount)
    let nisbah = Number(trx.campaign.nisbah) / 100
    return parseInt(modal * nisbah).toLocaleString()
  }

  const Nisbah = (amount, nisbah, target) => {
    let modal = Number(amount)
    let roi = Number(nisbah) / 100
    return parseInt(modal * roi).toLocaleString()
  }

  const handleTTDMusyarakah = () => {
    if (trx.privy_document_musyarakah.length === 0) return "Belum TTD"
    if (trx.privy_document_musyarakah[0].status === 1) {
      return "Belum TTD"
    }
    return "Sudah TTD"
  }
  const handleTTDWakalah = () => {
    if (trx.privy_document_wakalah.length === 0) return "Belum TTD"
    if (trx.privy_document_wakalah[0].status === 1) {
      return "Belum TTD"
    }
    return "Sudah TTD"
  }
  const handleTTDAddendum = () => {
    if (trx.privy_document_addendum.length === 0) return "Belum TTD"
    if (trx.privy_document_addendum[0].lender_status === 1) {
      return "Belum TTD"
    }
    return "Sudah TTD"
  }

  const handleStatus = trx => {
    if (trx.status === 4) return "Proses"
    if (trx.status === 5) return "Proses Pengembalian"
    if (trx.status === 6) return "Selesai Pengembalian Pokok"
    if (trx.status === 7) return "Selesai Pengembalian Pokok dan Nisbah"
    // if(trx.status === 3) return <Badge color="info">Proses</Badge>
    // if(trx.status === 4) return <Badge color="info">Selesai pengembalian pokok dan nisbah</Badge>
    // if(trx.status === 5) return <Badge color="info">Selesai pengembalian pokok dan nisbah</Badge>
    // if(trx.status === 6) return <Badge color="danger">Tidak perform</Badge>
    // if(trx.status === 7) return <Badge color="info">Tidak perform maksimal</Badge>
    // if(trx.status === 8) return <Badge color="primary">perform maksimal</Badge>
  }

  const CardDetail = item => {
    return (
      <div className="mb-20">
        <div className="card">
          <img
            draggable="false"
            src={API_URL + item.image}
            className="img-fluid"
            height="180px"
            width="100%"
          />
          <div className="f-11 project-card-body">
            <div className="project-head">
              <Row>
                <Col xs="12">
                  <p>
                    <b>{item.title}</b>
                  </p>
                </Col>
                <Col xs="12">
                  <Row>
                    <Col>
                      <p>
                        <i className="fa fa-map-marker-alt"></i>{" "}
                        {item.province.name}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <i className="fa fa-handshake"></i>{" "}
                        {item.akad_type.title}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <hr></hr>
            <div className="project-body">
              <div className="project-content">
                <Row>
                  <Col>
                    <p>Pembiayaan Anda</p>
                    <p className="bold">
                      Rp {Number(trx.payment_amount).toLocaleString()}
                    </p>
                  </Col>
                  <Col>
                    <p>Estimasi Nisbah</p>
                    <p className="bold">Rp {handleNisbah()}</p>
                  </Col>
                </Row>
                <div class="separator"></div>
                <div class="separator"></div>
                <Row>
                  <Col>
                    <p>Tenor</p>
                    <p className="bold">{item.tenor} Bulan</p>
                  </Col>
                  <Col>
                    <p>Nisbah</p>
                    <p className="bold">{item.nisbah} %</p>
                  </Col>
                </Row>
                <hr></hr>

                <Row className="mb-1">
                  <Col lg="6">
                    <div className="mb-1 ml-1">
                      <b>TTD Akad Digital Privy</b>
                    </div>
                    <Button
                      className="w-100"
                      color="primary"
                      onClick={handleWakalah}
                      disabled={loading}
                    >
                      Wakalah
                      {/* {loading ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Menunggu</> : 'Wakalah'} */}
                    </Button>
                    <div className="mb-1 ml-1">
                      Status: <b>{handleTTDWakalah()}</b>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-1 ml-1">
                      <b>TTD Akad Digital Privy</b>
                    </div>
                    <Button
                      className="w-100"
                      color="primary"
                      onClick={handleMusyarakah}
                      disabled={loading}
                    >
                      Musyarakah
                      {/* {loading ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Menunggu</> : 'Musyarakah'} */}
                    </Button>
                    <div className="mb-1 ml-1">
                      Status: <b>{handleTTDMusyarakah()}</b>
                    </div>
                  </Col>
                  {trx?.privy_document_addendum?.length > 0 ? (
                    <Col lg="12">
                      {/* <div className="mb-1 ml-1">
                        <b>TTD Akad Digital Privy</b>
                      </div> */}
                      <Button
                        className="w-100"
                        color="primary"
                        onClick={handleAddendum}
                        disabled={loading}
                      >
                        Addendum
                        {/* {loading ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Menunggu</> : 'Musyarakah'} */}
                      </Button>
                      <div className="mb-1 ml-1">
                        Status: <b>{handleTTDAddendum()}</b>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderModalAkad = () => {
    return (
      <Modal
        isOpen={modalAkad}
        // toggle={toggleAkad}
        scrollable={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Dokumen Akad {akadName}</h5>
          <button
            type="button"
            onClick={toggleAkad}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <div className="embed-responsive embed-responsive-4by3">
            <iframe
              title={akadName}
              className="embed-responsive-item"
              src={urlDocument}
            />
          </div>
        </div>
      </Modal>
    )
  }

  if (!trx) {
    return (
      <SkeletonLoad title="Detail" breadcrumbItem="Portfolio">
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <div className="d-flex justify-content-end">
            <Skeleton width={100} />
          </div>
          <Skeleton height={70} />
          <Row>
            <Col lg="4">
              <Skeleton height={400} />
            </Col>
            <Col lg="8">
              <Skeleton height={400} />
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  if (loading) {
    return (
      <SkeletonLoad title="Detail" breadcrumbItem="Portfolio">
        <div style={{ fontSize: 24, lineHeight: 2 }}>
          <div className="d-flex justify-content-end">
            <Skeleton width={100} />
          </div>
          <Skeleton height={70} />
          <Row>
            <Col lg="4">
              <Skeleton height={400} />
            </Col>
            <Col lg="8">
              <Skeleton height={400} />
            </Col>
          </Row>
        </div>
      </SkeletonLoad>
    )
  }

  return (
    <React.Fragment>
      {renderModalAkad()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            className="pb-0 mb-2"
            title="Detail"
            breadcrumbItem="Portfolio"
          />
          <div className="d-flex justify-content-end mb-2">
            <Button
              className="btn-sm"
              onClick={() => {
                props.history.goBack()
              }}
            >
              <i className="bx bx-arrow-back"></i> Kembali
            </Button>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Portofolio Pembiayaan</CardTitle>
                  <CardText className="text-primary">
                    Tanda tangani Akad dan Pantau Perkembangan Pembiayaan Anda
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">{CardDetail(trx.campaign)}</Col>
            <Col lg="8">
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1")
                        }}
                      >
                        Perkembangan Proyek
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2")
                        }}
                      >
                        Informasi Pengembalian
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
              </Card>
              <Card>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" className="p-3">
                    <Row className="">
                      {trx.campaign.campaign_histories.length > 0 ? (
                        trx.campaign.campaign_histories.map((item, idx) => {
                          return (
                            <Col lg={6}>
                              <Card
                              // style={{cursor: "pointer"}}
                              // onClick={() => {
                              //   setPhotoIndex(idx)
                              //   setLightBox(true)
                              // }}
                              >
                                {/* <CardImg top className="img-fluid" src={API_URL+item.file} alt="Skote" /> */}
                                <UncontrolledCarousel
                                  items={[
                                    { file: item.file },
                                    ...item.files,
                                  ].map(item => ({ src: API_URL + item.file }))}
                                  className="custom-image-carousel"
                                />
                                <CardBody className="p-2">
                                  <CardTitle className="mt-0">
                                    {item.title}
                                  </CardTitle>
                                  <CardText className="text-justify">
                                    {item.description}
                                  </CardText>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                          // return (
                          //   <Row>
                          //     <Col lg="5">
                          //       <UncontrolledCarousel items={[{file: item.file}, ...item.files].map(item => ({src: API_URL + item.file}))} />
                          //     </Col>
                          //     <Col lg="7">
                          //       <div className="bold base font-20">{item.title}</div>
                          //       <div className="">{item.description}</div>
                          //     </Col>
                          //   </Row>
                          // )
                        })
                      ) : (
                        <div className="w-100 text-center mt-2">
                          <h5>
                            <b>Belum Ada Data</b>
                          </h5>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Row className="mt-3">
                      <Col lg="7">
                        <div className="table-responsive">
                          <Table className="table mb-0 table-bordered">
                            <tbody>
                              <tr>
                                <th
                                  scope="row"
                                  style={{ width: "140px" }}
                                  className={"text-capitalize"}
                                >
                                  Kampanye Berakhir
                                </th>
                                <td>
                                  {moment(
                                    trx.campaign.end_date_campaign
                                  ).format("DD MMMM YYYY")}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  style={{ width: "140px" }}
                                  className={"text-capitalize"}
                                >
                                  Pembiayaan
                                </th>
                                <td>{`Rp ${parseInt(
                                  trx.payment_amount
                                ).toLocaleString()}`}</td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  style={{ width: "140px" }}
                                  className={"text-capitalize"}
                                >
                                  Proyeksi Imbal Hasil
                                </th>
                                <td>{`Rp ${handleNisbah()}`}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col lg="5">
                        <div className="border text-center h-100">
                          <div className="p-2 border-bottom font-weight-bold ">
                            Deskripsi Pengembalian
                          </div>
                          <div className="mx-3" style={{ paddingTop: 30 }}>
                            <h6>{handleStatus(trx)}</h6>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <table class="table table-striped mt-4 border">
                      <thead>
                        <tr>
                          <th scope="col">Tanggal Pengembalian</th>
                          <th scope="col">Nominal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payout.length > 0 ? (
                          payout.map((item, i) => {
                            return (
                              <tr>
                                {/* <td>Rp {Number(item.campaign_transaction.amount).toLocaleString()}</td>
                                <td>Rp {Nisbah(item.campaign_transaction.amount, trx.campaign.nisbah, trx.campaign.target).toLocaleString()}</td> */}
                                <td>
                                  {moment(item.date).format("DD MMMM YYYY")}
                                </td>
                                <td>
                                  Rp{" "}
                                  {Number(item.amount_total).toLocaleString()}
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan="2">Belum Ada Pengembalian</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {lightBox && (
        <Lightbox
          mainSrc={API_URL + trx.campaign.campaign_histories[photoIndex].file}
          nextSrc={
            trx.campaign.campaign_histories[
              (photoIndex + 1) % trx.campaign.campaign_histories.length
            ]
          }
          prevSrc={
            trx.campaign.campaign_histories[
              (photoIndex + trx.campaign.campaign_histories.length - 1) %
                trx.campaign.campaign_histories.length
            ]
          }
          enableZoom={true}
          onCloseRequest={() => {
            setLightBox(false)
          }}
          onMovePrevRequest={() => {
            setPhotoIndex(
              (photoIndex + trx.campaign.campaign_histories.length - 1) %
                trx.campaign.campaign_histories.length
            )
          }}
          onMoveNextRequest={() => {
            setPhotoIndex(
              (photoIndex + 1) % trx.campaign.campaign_histories.length
            )
          }}
          imageCaption={trx.campaign.campaign_histories[photoIndex].description}
        />
      )}
    </React.Fragment>
  )
}

export default PortfolioDetail
