import React, { useEffect, useState, useRef } from "react"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { map } from "lodash"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

import {
  getApprovalHistoryProyek,
  getCategoryTwo,
  getHistoryApprovalBisnisDecision,
  getHistoryBisnisDecision,
  getProjectModerator,
  getQuestionMeet,
  getReason,
  getResultRisk,
  getSubCategoryOne,
  getSubCategoryProperty,
} from "helpers/backend_helpers"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { MDBDataTable } from "mdbreact"
import "../../assets/scss/datatables.scss"
import Toaster from "components/Common/Toast"
import ColumnsProject from "./columns"
import ModalApprove from "./ModalApprove"
import ModalDetail from "./ModalDetail"
import Loading from "pages/Utility/Loading"
import ModalApproveRisk from "./ModalApproveRisk"

import moment from "moment"
import ModalMeet from "./ModalMeet"
import ModalMeetHis from "./ModalMeetHis"
import ModalTinjauHistory from "./ModalTinjauHistory"
import ModalRiskHistory from "./ModalRiskHistory"
import { timeDiffCalc } from "helpers/method_helpers"
import DataTable from "components/Common/DataTable"
import { API_URL } from "helpers/api_url"

const ProjectApproval = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [approvedTab, setApprovedTab] = useState("2")
  const [historyTabApproval, setHistoryTabApproval] = useState("2")
  const [historyTabRisk, setHistoryTabRisk] = useState("1")
  const [stepTab, setStepTab] = useState("1")
  const [projects, setProjects] = useState([])
  const [reason, setReason] = useState([])

  const [itemDetail, setItemDetail] = useState(null)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalApproved, setmodalApproved] = useState(false)
  const [modalApprovedRisk, setmodalApprovedRisk] = useState(false)
  const [modalMeet, setModalMeet] = useState(false)
  const [modalMeetHis, setModalMeetHis] = useState(false)
  const [modalRevisiRisk, setModalRevisiRisk] = useState(false)

  const [itemRisk, setItemRisk] = useState(null)
  const [itemMeet, setItemMeet] = useState(null)
  const [itemMeetHis, setItemMeetHis] = useState(null)
  const [itemRevisiRisk, setItemRevisiRisk] = useState(null)
  const [itemLap, setItemLap] = useState(false)

  const [subCategoryOne, setSubCategoryOne] = useState([])
  const [subCategoryTwo, setSubCategoryTwo] = useState([])
  const [subCategoryProperty, setSubCategoryProperty] = useState([])
  const [modalScoreHistory, setModalScoreHistory] = useState(false)
  const [modalTinjauLapHis, setModalTinjauLapHis] = useState(false)

  const [appHistory, setAppHistory] = useState([])
  const [appHistoryTwo, setAppHistoryTwo] = useState([])
  const [load, setLoad] = useState(false)
  const [load2, setLoad2] = useState(false)

  const [risk, setRisk] = useState([])
  const [historyRisk, setHistoryRisk] = useState([])

  const [questionMeet, setQuestionMeet] = useState([])

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pagination, setPagination] = useState(null)
  const [keySearch, setKeySearch] = useState("title")
  const [search, setSearch] = useState("")

  useEffect(() => {
    getProjectAdmin()
  }, [
    activeTab,
    approvedTab,
    stepTab,
    perPage,
    page,
    historyTabApproval,
    historyTabRisk,
  ])

  useEffect(() => {
    getReasonData()
  }, [])

  useEffect(() => {
    getDataScore()
  }, [])

  useEffect(() => {
    if (itemRisk) {
      getDataRiskQuestion()
    }
  }, [itemRisk])

  const getDataRiskQuestion = async () => {
    const token = localStorage.getItem("token")
    let query = ""
    if (itemRisk.project_type.title === "UKM") {
      query = "?property=false"
    } else {
      query = "?property=true"
    }
    setLoad2(true)
    const data = await getSubCategoryOne(token, query)
    setSubCategoryOne(data.data)
    setLoad2(false)
  }

  const getDataScore = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      // const catOne = await getSubCategoryOne(token)
      const catTwo = await getCategoryTwo(token)
      const catProp = await getSubCategoryProperty(token)
      // setSubCategoryOne(catOne.data)
      setSubCategoryTwo(catTwo.data)
      setSubCategoryProperty(catProp.data)
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const getReasonData = async () => {
    const token = localStorage.getItem("token")
    try {
      const data = await getReason(token, "?type=project-approval")
      const dataMeet = await getQuestionMeet(token)
      setReason(data.data)
      setQuestionMeet(dataMeet.data)
    } catch (error) {
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const getProjectAdmin = async () => {
    const token = localStorage.getItem("token")
    setLoad(true)
    try {
      if (activeTab === "2") {
        let query = `?per_page=${perPage}&page=${page}&isUkm=${
          historyTabApproval === "1" ? false : true
        }`
        if (search) {
          query += `&type=${
            keySearch === "title" ? "project" : "user"
          }&keySearch=${keySearch}&search=${search}`
        }
        const his = await getApprovalHistoryProyek(token, query)
        setAppHistory(his.data)
        setTotalPage(his.meta.pagination.last_page)
        setCurrentPage(his.meta.pagination.current_page)
        setPagination(his.meta.pagination)
      } else if (activeTab === "3") {
        const dataRisk = await getResultRisk(token)
        setRisk(dataRisk.data)
      } else if (activeTab === "5") {
        let query = `?per_page=${perPage}&page=${page}&isUkm=${
          historyTabRisk === "1" ? false : true
        }`
        if (search) {
          query += `&type=${
            keySearch === "title" ? "project" : "user"
          }&keySearch=${keySearch}&search=${search}`
        }
        const hisTwo = await getHistoryApprovalBisnisDecision(token, query)
        setAppHistoryTwo(hisTwo.data)
        setTotalPage(hisTwo.meta.pagination.last_page)
        setCurrentPage(hisTwo.meta.pagination.current_page)
        setPagination(hisTwo.meta.pagination)
      } else if (activeTab === "4") {
        const hisRisk = await getHistoryBisnisDecision(token)
        setHistoryRisk(hisRisk.data)
      } else {
        let query =
          approvedTab === "1"
            ? "?type=property"
            : `?type=ukm${
                stepTab === "1"
                  ? "&project_status=step-one"
                  : "&project_status=step-two"
              }`
        let paginateProject = `&per_page=${perPage}&page=${page}&keySearch=${keySearch}&search=${search}&query_type=${
          keySearch === "email" ? "user" : "project"
        }`
        const data = await getProjectModerator(token, query + paginateProject)
        setProjects(data.data)
        setTotalPage(data.meta.pagination.last_page)
        setCurrentPage(data.meta.pagination.current_page)
        setPagination(data.meta.pagination)
      }

      setLoad(false)
    } catch (error) {
      Toaster("error", "Maaf", error.response?.data?.message || "Not Found")
      setLoad(false)
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleApproved = tab => {
    if (approvedTab !== tab) {
      setApprovedTab(tab)
    }
  }

  const toggleHistoryTabApproval = tab => {
    if (historyTabApproval !== tab) {
      setHistoryTabApproval(tab)
    }
  }

  const toggleHistoryTabRisk = tab => {
    if (historyTabRisk !== tab) {
      setHistoryTabRisk(tab)
    }
  }

  const toggleTabStep = tab => {
    if (stepTab !== tab) {
      setStepTab(tab)
    }
  }

  const toggleModalRevisiRisk = obj => {
    setItemRevisiRisk(obj)
    setModalRevisiRisk(!modalRevisiRisk)
  }

  const handleStatusProject = (type, status) => {
    if (type === "UKM") {
      switch (status) {
        case 1:
          return <Badge color="info">Peninjauan data tahap 1</Badge>
        case 2:
          return <Badge color="danger">Ditolak tahap 1</Badge>
        case 3:
          return <Badge color="danger">Ditolak tahap 2</Badge>
        case 4:
          return <Badge color="primary">Disetujui tahap 1</Badge>
        case 5:
          return <Badge color="info">Peninjauan data tahap 2</Badge>
        case 6:
          return <Badge color="info">Penilaian resiko</Badge>
        case 7:
          return <Badge color="warning">Persetujuan Kampanye</Badge>
        case 8:
          return <Badge color="primary">Disetujui</Badge>
        case 9:
          return <Badge color="primary">Terbit Kampanye</Badge>
      }
    } else {
      switch (status) {
        case 1:
          return <Badge color="info">Peninjauan data</Badge>
        case 2:
          return <Badge color="danger">Ditolak</Badge>
        // case 3: return <Badge color="danger">Ditolak</Badge>
        // case 4: return <Badge color="primary">Disetujui Tahap 1</Badge>
        // case 5: return <Badge color="warning">Menunggu persetujuan tahap 2</Badge>
        case 6:
          return <Badge color="info">Penilaian resiko</Badge>
        case 7:
          return <Badge color="warning">Persetujuan kampanye</Badge>
        case 8:
          return <Badge color="primary">Disetujui</Badge>
        case 9:
          return <Badge color="primary">Terbit Kampanye</Badge>
      }
    }
  }

  const toggleModalDetail = obj => {
    setItemDetail(obj)
    setModalDetail(!modalDetail)
  }

  const toggleModalMeet = obj => {
    setItemMeet(obj)
    setModalMeet(!modalMeet)
  }

  const toggleModalMeetHis = obj => {
    setItemMeetHis(obj)
    setModalMeetHis(!modalMeetHis)
  }

  const toggleModalApproved = obj => {
    setItemDetail(obj)
    setmodalApproved(!modalApproved)
  }

  const toggleModalApprovedRisk = obj => {
    setItemDetail(obj)
    setmodalApprovedRisk(!modalApprovedRisk)
  }

  const toggleModalTinjauLapHis = obj => {
    setItemLap(obj)
    setModalTinjauLapHis(!modalTinjauLapHis)
  }

  const toggleModalScoreHistory = obj => {
    setItemRisk(obj)
    setModalScoreHistory(!modalScoreHistory)
  }

  const dataHistories = {
    columns: [
      {
        label: "Tanggal Pengajuan",
        field: "dateTimeBeforeAction",
      },
      {
        label: "Tanggal Persetujuan",
        field: "dateTimeAfterAction",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Nama Proyek",
        field: "project",
        sort: "asc",
      },
      {
        label: "Nominal Pengajuan",
        field: "nominal",
      },
      {
        label: "Jangka Waktu",
        field: "tenor",
      },
      {
        label: "Tipe Proyek",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Email Pengaju",
        field: "email_pengaju",
        sort: "asc",
      },
      {
        label: "Telepon Pengaju",
        field: "tel_pengaju",
      },
      {
        label: "Persetujuan",
        field: "isApprove",
        sort: "asc",
      },
      {
        label: "Persetujuan Oleh",
        field: "emailCreator",
        sort: "asc",
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      // },
      {
        label: "Detail Proyek",
        field: "details",
      },
      {
        label: "Rapat Internal",
        field: "meet",
      },
      {
        label: "Alasan",
        field: "reason",
        sort: "asc",
      },
      {
        label: "Deskripsi",
        field: "description",
        sort: "asc",
      },
    ],
    rows: appHistory.map(item => {
      return {
        ...item,
        bussines_entity: item.project?.project_company?.business_entity?.title,
        nominal: `Rp ${parseFloat(
          item.project?.project_detail?.submission_total
        ).toLocaleString()}`,
        tenor: item.project?.project_detail?.tenor
          ? `${item.project?.project_detail?.tenor} Bulan`
          : "-",
        tel_pengaju: item.project?.user?.phone_number,
        company_name: item.project?.project_company?.company_name || "-",
        email_pengaju: item.project?.user?.email || "-",
        time_approved:
          !item.dateTimeAfterAction || !item.dateTimeBeforeAction
            ? "-"
            : timeDiffCalc(item.dateTimeAfterAction, item.dateTimeBeforeAction),
        // status: handleStatusProject(item.project.project_type.title, item.project.status),
        project_type: item.project.project_type.title,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalDetail(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        meet: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalMeetHis(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        project: item.project.title,
        emailCreator: item.creator.email,
        isApprove: Boolean(item.isApprove) ? (
          <Badge color="primary">Disetujui</Badge>
        ) : Boolean(item.isPermanent) ? (
          <Badge color="danger">Ditolak</Badge>
        ) : (
          <Badge color="warning">Revisi</Badge>
        ),
        reason: item.reason.title,
        description: item.description ? item.description : "Tidak ada",
      }
    }),
  }

  const data = {
    columns: [
      {
        label: "Tanggal Input Pengajuan",
        field: "created_at",
      },
      {
        label: "Tanggal Ubah Pengajuan",
        field: "updated_at",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Nama Proyek",
        field: "title",
        sort: "asc",
      },
      {
        label: "Tipe Proyek",
        field: "project_type",
        sort: "asc",
      },
      // {
      //   label: "Jenis Proyek",
      //   field: "project_type_detail",
      //   sort: "asc",
      // },
      {
        label: "Nominal Pengajuan",
        field: "nominal",
      },
      {
        label: "Jangka Waktu",
        field: "tenor",
      },
      {
        label: "Email Pengaju",
        field: "email",
        sort: "asc",
      },
      {
        label: "Telepon Pengaju",
        field: "phone_number",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Detail Pengajuan",
        field: "details",
      },
      {
        label: "Rapat Internal",
        field: "meet",
      },
      {
        label: "Revisi Risk",
        field: "revisionRisk",
      },
      {
        label: "Persetujuan Internal",
        field: "action",
      },
    ],
    rows: projects.map(item => {
      return {
        ...item,
        bussines_entity: item.project_company?.business_entity?.title || "-",
        project_type_detail: item.project_detail?.project_type_detail || "-",
        tenor: item.project_detail?.tenor
          ? `${item.project_detail?.tenor} Bulan`
          : "-",
        email: item.user?.email,
        phone_number: item.user?.phone_number,
        updated_at: moment(item.updated_at).format("DD MMM YYYY"),
        created_at: moment(item.created_at).format("DD MMM YYYY"),
        project_type: item.project_type.title,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalDetail(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        company_name: item.project_company.company_name,
        nominal: `Rp ${Number(
          item.project_detail.submission_total
        ).toLocaleString()}`,
        status: handleStatusProject(item.project_type.title, item.status),
        action: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalApproved(item)}
            disabled={
              item.meeting_result.length > 0 ||
              (item.project_type.title == "UKM" && item.status === 1)
                ? false
                : true
            }
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
        meet:
          item.project_type.title === "Properti" ? (
            <Button
              type="button"
              color="secondary"
              className="btn-sm"
              onClick={() => toggleModalMeet(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
          ) : (
            <Button
              type="button"
              color="secondary"
              className="btn-sm"
              onClick={() => toggleModalMeet(item)}
              // disabled={item.status === 1}
            >
              <i className="fas fa-edit"></i>
            </Button>
          ),
        revisionRisk: !item?.risk_analyst_notes?.length ? (
          "-"
        ) : (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalRevisiRisk(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
      }
    }),
  }

  const dataRisk = {
    columns: [
      {
        label: "Nama Proyek",
        field: "title",
        sort: "asc",
      },
      {
        label: "Tipe Proyek",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Nominal",
        field: "nominal",
        sort: "asc",
      },
      {
        label: "Pefindo",
        field: "score_pefindo",
      },
      {
        label: "Tinjau Lapangan",
        field: "score_lapangan",
      },
      {
        label: "Detail Tinjau Lapangan",
        field: "detail_lap",
      },
      {
        label: "Penilaian Internal",
        field: "score_internal",
      },
      {
        label: "Detail Penilaian Internal",
        field: "detail_internal",
      },
      {
        label: "Skor Akhir",
        field: "final_score",
      },
      {
        label: "Hasil Akhir",
        field: "score",
      },
      {
        label: "Deskripsi Penilaian",
        field: "scoreDesc",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "details",
      },
      {
        label: "Rapat Internal",
        field: "meet",
      },
      {
        label: "Persetujuan",
        field: "action",
      },
    ],
    rows: map(risk, item => ({
      ...item,
      bussines_entity: item.project_company?.business_entity?.title,
      final_score:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].final_score || "-"
          : "-",
      score_internal:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score_internal ||
            "-"
          : "-",
      score_lapangan:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score_lapangan ||
            "-"
          : "-",
      score_pefindo:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score_pefindo || "-"
          : "-",
      score:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score
          : "-",
      scoreDesc:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].description
          : "-",
      project_type: item.project_type.title,
      details: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm mr-1"
          onClick={() => toggleModalDetail(item)}
        >
          <i className="fas fa-eye"></i>
        </Button>
      ),
      company_name: item.project_company.company_name,
      nominal: `Rp ${Number(
        item.project_detail.submission_total
      ).toLocaleString()}`,
      // status: handleStatusProject(item.project_type.title, item.status),
      action: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggleModalApprovedRisk(item)}
        >
          <i className="fas fa-edit"></i>
        </Button>
      ),
      meet: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm"
          onClick={() => toggleModalMeetHis(item)}
        >
          <i className="fas fa-eye"></i>
        </Button>
      ),
      detail_lap:
        item.project_type.title == "Properti" &&
        item.risk_analyst.length > 0 &&
        item.risk_analyst[item.risk_analyst.length - 1].score_lapangan ? (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalTinjauLapHis(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ) : (
          "-"
        ),
      detail_internal:
        item.risk_analyst.length > 0 &&
        item.risk_analyst[item.risk_analyst.length - 1].score_internal ? (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalScoreHistory(item)}
            // disabled={item.meeting_result.length === 0}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ) : (
          "-"
        ),
    })),
  }

  const dataHistoriesRisk = {
    columns: [
      {
        label: "Nama Proyek",
        field: "title",
        sort: "asc",
      },
      {
        label: "Tipe",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Nominal",
        field: "nominal",
        sort: "asc",
      },
      // {
      //   label: "Persetujuan",
      //   field: "isApprove",
      //   sort: "asc",
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      {
        label: "Pefindo",
        field: "score_pefindo",
      },
      {
        label: "Tinjau Lapangan",
        field: "score_lapangan",
      },
      {
        label: "Detail Tinjau Lapangan",
        field: "detail_lap",
      },
      {
        label: "Penilaian Internal",
        field: "score_internal",
      },
      {
        label: "Detail Penilaian Internal",
        field: "detail_internal",
      },
      {
        label: "Skor Akhir",
        field: "final_score",
      },
      {
        label: "Hasil Akhir",
        field: "score",
      },
      {
        label: "Deskripsi Penilaian",
        field: "scoreDesc",
        sort: "asc",
      },
      {
        label: "Detail",
        field: "details",
      },
    ],
    rows: map(historyRisk, item => ({
      ...item,
      bussines_entity: item.project_company?.business_entity?.title,
      // isApprove: item.approval_project[item.approval_project.length - 1].isApprove === 0 ? <Badge color="danger">Ditolak</Badge> : <Badge color="primary">Disetujui</Badge>,
      final_score:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].final_score || "-"
          : "-",
      score_internal:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score_internal ||
            "-"
          : "-",
      score_lapangan:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score_lapangan ||
            "-"
          : "-",
      score_pefindo:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score_pefindo || "-"
          : "-",
      score:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].score
          : "-",
      scoreDesc:
        item.risk_analyst.length > 0
          ? item.risk_analyst[item.risk_analyst.length - 1].description
          : "-",
      project_type: item.project_type.title,
      details: (
        <Button
          type="button"
          color="secondary"
          className="btn-sm mr-1"
          onClick={() => toggleModalDetail(item)}
        >
          <i className="fas fa-eye"></i>
        </Button>
      ),
      company_name: item.project_company.company_name,
      nominal: `Rp ${Number(
        item.project_detail.submission_total
      ).toLocaleString()}`,
      status: handleStatusProject(item.project_type.title, item.status),
      detail_lap:
        item.project_type.title == "Properti" &&
        item.risk_analyst.length > 0 &&
        item.risk_analyst[item.risk_analyst.length - 1].score_lapangan ? (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalTinjauLapHis(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ) : (
          "-"
        ),
      detail_internal:
        item.risk_analyst.length > 0 &&
        item.risk_analyst[item.risk_analyst.length - 1].score_internal ? (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalScoreHistory(item)}
            // disabled={item.meeting_result.length === 0}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ) : (
          "-"
        ),
    })),
  }

  const dataHistoriesTwo = {
    columns: [
      {
        label: "Tanggal Submit Penilaian",
        field: "dateTimeBeforeAction",
      },
      {
        label: "Tanggal Persetujuan",
        field: "dateTimeAfterAction",
      },
      {
        label: "Badan Hukum",
        field: "bussines_entity",
        sort: "asc",
      },
      {
        label: "Nama Perusahaan",
        field: "company_name",
        sort: "asc",
      },
      {
        label: "Nama Proyek",
        field: "project",
        sort: "asc",
      },
      {
        label: "Email Pengaju",
        field: "email_pengaju",
        sort: "asc",
      },
      {
        label: "Tipe",
        field: "project_type",
        sort: "asc",
      },
      {
        label: "Persetujuan Oleh",
        field: "emailCreator",
        sort: "asc",
      },
      {
        label: "Persetujuan",
        field: "isApprove",
        sort: "asc",
      },
      {
        label: "Rapat Internal",
        field: "meet",
      },
      {
        label: "Detail Proyek",
        field: "details",
      },
      {
        label: "Pefindo",
        field: "score_pefindo",
      },
      {
        label: "Tinjau Lapangan",
        field: "score_lapangan",
      },
      {
        label: "Detail Tinjau Lapangan",
        field: "detail_lap",
      },
      {
        label: "Penilaian Internal",
        field: "score_internal",
      },
      {
        label: "Detail Penilaian Internal",
        field: "detail_internal",
      },
      {
        label: "Skor Akhir",
        field: "final_score",
      },
      {
        label: "Hasil Akhir",
        field: "score",
      },
      {
        label: "Deskripsi Penilaian",
        field: "scoreDesc",
        sort: "asc",
      },
      {
        label: "Alasan",
        field: "reason",
        sort: "asc",
      },
      {
        label: "Deskripsi",
        field: "description",
        sort: "asc",
      },
    ],
    rows: appHistoryTwo.map(item => {
      return {
        ...item,
        bussines_entity: item.project?.project_company?.business_entity?.title,
        company_name: item.project?.project_company?.company_name,
        email_pengaju: item.project?.user?.email || "-",
        time_approved:
          !item.dateTimeAfterAction || !item.dateTimeBeforeAction
            ? "-"
            : timeDiffCalc(item.dateTimeAfterAction, item.dateTimeBeforeAction),
        final_score:
          item.project.risk_analyst.length > 0
            ? item.project.risk_analyst[item.project.risk_analyst.length - 1]
                .final_score || "-"
            : "-",
        score_internal:
          item.project.risk_analyst.length > 0
            ? item.project.risk_analyst[item.project.risk_analyst.length - 1]
                .score_internal || "-"
            : "-",
        score_lapangan:
          item.project.risk_analyst.length > 0
            ? item.project.risk_analyst[item.project.risk_analyst.length - 1]
                .score_lapangan || "-"
            : "-",
        score_pefindo:
          item.project.risk_analyst.length > 0
            ? item.project.risk_analyst[item.project.risk_analyst.length - 1]
                .score_pefindo || "-"
            : "-",
        score:
          item.project.risk_analyst.length > 0
            ? item.project.risk_analyst[item.project.risk_analyst.length - 1]
                .score
            : "",
        scoreDesc:
          item.project.risk_analyst.length > 0
            ? item.project.risk_analyst[item.project.risk_analyst.length - 1]
                .description
            : "",
        // status: handleStatusProject(item.project.project_type.title, item.project.status),
        project_type: item.project.project_type.title,
        details: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm mr-1"
            onClick={() => toggleModalDetail(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        meet: (
          <Button
            type="button"
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalMeetHis(item.project)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        // details: "d",
        project: item.project.title,
        emailCreator: item.creator.email,
        isApprove:
          item.isApprove === 0 ? (
            <Badge color="danger">Ditolak</Badge>
          ) : (
            <Badge color="primary">Disetujui</Badge>
          ),
        reason: item.reason.title,
        description: item.description ? item.description : "Tidak ada",
        detail_lap:
          item.project.project_type.title == "Properti" &&
          item.project.risk_analyst.length > 0 &&
          item.project.risk_analyst[item.project.risk_analyst.length - 1]
            .score_lapangan ? (
            <Button
              type="button"
              color="secondary"
              className="btn-sm"
              onClick={() => toggleModalTinjauLapHis(item.project)}
            >
              <i className="fas fa-eye"></i>
            </Button>
          ) : (
            "-"
          ),
        detail_internal:
          item.project.risk_analyst.length > 0 &&
          item.project.risk_analyst[item.project.risk_analyst.length - 1]
            .score_internal ? (
            <Button
              type="button"
              color="secondary"
              className="btn-sm"
              onClick={() => toggleModalScoreHistory(item.project)}
              // disabled={item.meeting_result.length === 0}
            >
              <i className="fas fa-eye"></i>
            </Button>
          ) : (
            "-"
          ),
      }
    }),
  }

  const handleReminder = (dataProjects, type) => {
    let result = ""
    if (type === "ukm_step_one") {
      dataProjects.forEach((element, idx) => {
        let reminder =
          element.project_reminder?.length > 0
            ? element.project_reminder[element.project_reminder.length - 1]
                .isOverDueStepOne
            : null
        if (reminder === 1) {
          result += ` approval-project-${idx + 1}`
        }
      })
    } else if (type === "ukm_step_two") {
      dataProjects.forEach((element, idx) => {
        let reminder =
          element.project_reminder?.length > 0
            ? element.project_reminder[element.project_reminder.length - 1]
                .isOverDueStepTwo
            : null
        if (reminder === 1) {
          result += ` approval-project-${idx + 1}`
        }
      })
    } else if (type === "property") {
      dataProjects.forEach((element, idx) => {
        let reminder =
          element.project_reminder?.length > 0
            ? element.project_reminder[element.project_reminder.length - 1]
                .isOverDueProperty
            : null
        if (reminder === 1) {
          result += ` approval-project-${idx + 1}`
        }
      })
    }
    return result
  }

  const renderModalRevisiRisk = () => {
    return (
      <Modal isOpen={modalRevisiRisk} role="dialog" autoFocus={true} size="md">
        <ModalHeader toggle={() => toggleModalRevisiRisk(null)}>
          {itemRevisiRisk?.title}
        </ModalHeader>
        <ModalBody>
          <div className="chat-conversation">
            <ul className="list-unstyled">
              {itemRevisiRisk?.risk_analyst_notes?.map((item, idx) => (
                <li key={"test_k" + idx} className={""}>
                  <div className="conversation-list w-100">
                    <div className="ctext-wrap">
                      {/* {Boolean(message.files?.length) && (
                        <ModalImage
                          small={API_URL + message.files[0].file}
                          large={API_URL + message.files[0].file}
                          className="mb-1 w-100"
                        />
                      )} */}
                      <div className="conversation-name font-16">
                        {item?.creator?.fullName}
                      </div>
                      <p className="font-14 text-justify mb-2">{item.notes}</p>
                      {Boolean(item.files?.length) && (
                        <div className="flex mb-3">
                          {item.files.map(el => {
                            return (
                              <Button
                                className="mr-2 btn-sm"
                                onClick={() => window.open(API_URL + el.file)}
                              >
                                <i className="fas fa-download"></i>
                              </Button>
                            )
                          })}
                        </div>
                      )}
                      <p className="chat-time mb-0">
                        <i className="bx bx-time-five align-middle mr-1" />
                        {moment(item.created_at).format("DD-MMM-YYYY hh:mm")}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading title="Persetujuan" breadcrumbItem="Proyek" />

  return (
    <React.Fragment>
      {renderModalRevisiRisk()}
      <ModalApprove
        isOpen={modalApproved}
        toggle={toggleModalApproved}
        item={itemDetail}
        getProjectAdmin={getProjectAdmin}
        reason={reason}
      />
      <ModalDetail
        isOpen={modalDetail}
        toggle={toggleModalDetail}
        item={itemDetail}
      />
      <ModalApproveRisk
        isOpen={modalApprovedRisk}
        toggle={toggleModalApprovedRisk}
        item={itemDetail}
        getProjectAdmin={getProjectAdmin}
        reason={reason}
      />
      <ModalMeet
        isOpen={modalMeet}
        toggle={toggleModalMeet}
        item={itemMeet}
        getProjectAdmin={getProjectAdmin}
        question={questionMeet}
      />
      <ModalMeetHis
        isOpen={modalMeetHis}
        toggle={toggleModalMeetHis}
        item={itemMeetHis}
        question={questionMeet}
        getProjectAdmin={getProjectAdmin}
      />
      <ModalTinjauHistory
        isOpen={modalTinjauLapHis}
        subCategoryProperty={subCategoryProperty}
        toggleModal={toggleModalTinjauLapHis}
        item={itemLap}
      />
      <ModalRiskHistory
        isOpen={modalScoreHistory}
        subCategoryOne={subCategoryOne}
        subCategoryTwo={subCategoryTwo}
        toggleModal={toggleModalScoreHistory}
        item={itemRisk}
        load={load2}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Persetujuan" breadcrumbItem="Proyek" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <h4 className="card-title mb-3">Projects</h4> */}

                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                          setPerPage(10)
                          setPage(1)
                          setKeySearch("title")
                          setSearch("")
                        }}
                      >
                        Kelengkapan data
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                          setPerPage(10)
                          setPage(1)
                          setKeySearch("title")
                          setSearch("")
                        }}
                      >
                        Riwayat kelengkapan data
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Analisa resiko
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5")
                          setPerPage(10)
                          setPage(1)
                          setKeySearch("title")
                          setSearch("")
                        }}
                      >
                        Riwayat analisa resiko
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        Proyek Disetujui
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1" id="all-order">
                      <ul
                        className="nav nav-tabs nav-tabs-custom"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: approvedTab === "2",
                            })}
                            onClick={() => {
                              toggleApproved("2")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("title")
                              setSearch("")
                            }}
                          >
                            UKM
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: approvedTab === "1",
                            })}
                            onClick={() => {
                              toggleApproved("1")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("title")
                              setSearch("")
                            }}
                          >
                            Properti
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent activeTab={approvedTab} className="">
                        <TabPane tabId="1">
                          {/* <MDBDataTable
                            responsive
                            bordered
                            data={data}
                            striped
                            className={`mt-3`}
                            disableRetreatAfterSorting
                          /> */}
                          <DataTable
                            table={data}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["title", "email"]}
                            dataKeyCustom={[
                              { value: "title", label: "Nama Proyek" },
                              { value: "email", label: "Email Pengaju" },
                            ]}
                            handleGetSearch={getProjectAdmin}
                            searching={true}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <ul
                            className="nav nav-tabs nav-tabs-custom"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: stepTab === "1",
                                })}
                                onClick={() => {
                                  toggleTabStep("1")
                                  setPerPage(10)
                                  setPage(1)
                                  setKeySearch("title")
                                  setSearch("")
                                }}
                              >
                                Tahap 1
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: stepTab === "2",
                                })}
                                onClick={() => {
                                  toggleTabStep("2")
                                  setPerPage(10)
                                  setPage(1)
                                  setKeySearch("title")
                                  setSearch("")
                                }}
                              >
                                Tahap 2
                              </NavLink>
                            </NavItem>
                          </ul>
                          <TabContent activeTab={stepTab} className="">
                            <TabPane tabId="1">
                              {/* <MDBDataTable
                                responsive
                                bordered
                                data={data}
                                striped
                                className={`mt-3`}
                                disableRetreatAfterSorting
                              /> */}
                              <DataTable
                                table={data}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                page={page}
                                setPage={setPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPage={totalPage}
                                setTotalPage={setTotalPage}
                                pagination={pagination}
                                search={search}
                                handleSearch={setSearch}
                                keySearch={keySearch}
                                setKeySearch={setKeySearch}
                                dataKey={["title", "email"]}
                                dataKeyCustom={[
                                  { value: "title", label: "Nama Proyek" },
                                  { value: "email", label: "Email Pengaju" },
                                ]}
                                handleGetSearch={getProjectAdmin}
                                searching={true}
                              />
                            </TabPane>
                            <TabPane tabId="2">
                              {/* <MDBDataTable
                                responsive
                                bordered
                                data={data}
                                striped
                                className={`mt-3`}
                                disableRetreatAfterSorting
                              /> */}
                              <DataTable
                                table={data}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                page={page}
                                setPage={setPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPage={totalPage}
                                setTotalPage={setTotalPage}
                                pagination={pagination}
                                search={search}
                                handleSearch={setSearch}
                                keySearch={keySearch}
                                setKeySearch={setKeySearch}
                                dataKey={["title", "email"]}
                                dataKeyCustom={[
                                  { value: "title", label: "Nama Proyek" },
                                  { value: "email", label: "Email Pengaju" },
                                ]}
                                handleGetSearch={getProjectAdmin}
                                searching={true}
                              />
                            </TabPane>
                          </TabContent>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="2">
                      <ul
                        className="nav nav-tabs nav-tabs-custom"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: historyTabApproval === "1",
                            })}
                            onClick={() => {
                              toggleHistoryTabApproval("1")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("title")
                              setSearch("")
                            }}
                          >
                            Properti
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: historyTabApproval === "2",
                            })}
                            onClick={() => {
                              toggleHistoryTabApproval("2")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("title")
                              setSearch("")
                            }}
                          >
                            UKM
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent activeTab={historyTabApproval} className="">
                        <TabPane tabId="1">
                          <DataTable
                            table={dataHistories}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["title", "email", "phone_number"]}
                            dataKeyCustom={[
                              { value: "title", label: "Nama Proyek" },
                              { value: "email", label: "Email Pengaju" },
                              {
                                value: "phone_number",
                                label: "Telepon Pengaju",
                              },
                            ]}
                            handleGetSearch={getProjectAdmin}
                            searching={true}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <DataTable
                            table={dataHistories}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["title", "email", "phone_number"]}
                            dataKeyCustom={[
                              { value: "title", label: "Nama Proyek" },
                              { value: "email", label: "Email Pengaju" },
                              {
                                value: "phone_number",
                                label: "Telepon Pengaju",
                              },
                            ]}
                            handleGetSearch={getProjectAdmin}
                            searching={true}
                          />
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="data-table-custom">
                        <MDBDataTable
                          responsive
                          bordered
                          data={dataRisk}
                          striped
                          className="mt-3"
                          disableRetreatAfterSorting
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="data-table-custom">
                        <MDBDataTable
                          responsive
                          bordered
                          data={dataHistoriesRisk}
                          striped
                          className="mt-3"
                          disableRetreatAfterSorting
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <ul
                        className="nav nav-tabs nav-tabs-custom"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: historyTabRisk === "1",
                            })}
                            onClick={() => {
                              toggleHistoryTabRisk("1")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("title")
                              setSearch("")
                            }}
                          >
                            Properti
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: historyTabRisk === "2",
                            })}
                            onClick={() => {
                              toggleHistoryTabRisk("2")
                              setPerPage(10)
                              setPage(1)
                              setKeySearch("title")
                              setSearch("")
                            }}
                          >
                            UKM
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent activeTab={historyTabRisk} className="">
                        <TabPane tabId="1">
                          <DataTable
                            table={dataHistoriesTwo}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["title", "email", "phone_number"]}
                            dataKeyCustom={[
                              { value: "title", label: "Nama Proyek" },
                              { value: "email", label: "Email Pengaju" },
                              {
                                value: "phone_number",
                                label: "Telepon Pengaju",
                              },
                            ]}
                            handleGetSearch={getProjectAdmin}
                            searching={true}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <DataTable
                            table={dataHistoriesTwo}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            page={page}
                            setPage={setPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            pagination={pagination}
                            search={search}
                            handleSearch={setSearch}
                            keySearch={keySearch}
                            setKeySearch={setKeySearch}
                            dataKey={["title", "email", "phone_number"]}
                            dataKeyCustom={[
                              { value: "title", label: "Nama Proyek" },
                              { value: "email", label: "Email Pengaju" },
                              {
                                value: "phone_number",
                                label: "Telepon Pengaju",
                              },
                            ]}
                            handleGetSearch={getProjectAdmin}
                            searching={true}
                          />
                        </TabPane>
                      </TabContent>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectApproval
