import React, { Component, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  CardHeader,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"

//Dropzone
import Dropzone from "react-dropzone"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import images
import verificationImg from "../../assets/images/verification-img.png"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import AvField from "availity-reactstrap-validation/lib/AvField"
import Toaster from "components/Common/Toast"
import {
  getScoringResult,
  postScoringOne,
  postScoringTwo,
} from "helpers/backend_helpers"
import { API_URL } from "helpers/api_url"
import PuffLoader from "react-spinners/PuffLoader"

const ModalRiskHistory = props => {
  const token = localStorage.getItem("token")
  const {
    toggleModal,
    subCategoryOne,
    subCategoryTwo,
    item,
    getData,
    isOpen,
    load,
  } = props
  const [loadButt, setLoadButt] = useState(false)
  const [score, setScore] = useState(null)
  const [jaminan, setJaminan] = useState(null)
  const [fileJaminan, setFileJaminan] = useState(null)
  const [load2, setLoad2] = useState(false)

  useEffect(() => {
    getScore()
  }, [item])

  const getScore = async () => {
    if (item) {
      setLoad2(true)
      const data = await getScoringResult(item.uuid, token)
      setScore(data.data || {})
      setLoad2(false)
    }
  }

  const handleSubmit = async (e, value) => {
    // console.log(value)
    let answersOne = []
    let answerTwo = []
    Object.keys(value).forEach(item => {
      let arr = item.split(",")
      if (arr[arr.length - 1] === "one") {
        answersOne.push({
          sub_category_id: arr[0],
          analyst_response_master_id: value[item],
          // file: fileJaminan ? fileJaminan[item] : null
        })
      } else if (arr[arr.length - 1] === "file_jaminan") {
        answersOne.push({
          sub_category_id: arr[0],
          analyst_response_master_id: value[item],
          file: fileJaminan ? fileJaminan : null,
        })
      } else if (arr[arr.length - 1] === "two") {
        answerTwo.push({
          analyst_response_master_id: arr[0],
          score: value[item],
        })
      }
    })

    // console.log(answersOne)
    const formData = new FormData()
    answersOne.forEach((items, idx) => {
      Object.keys(items).forEach(item => {
        if (items[item]) {
          // console.log(`answer[${idx}][${item}]: ${items[item]}`)
          formData.append(`answers[${idx}][${item}]`, items[item])
        }
        // formData.append(`answer[${idx}][${item}]`, items[item])
      })
    })

    setLoadButt(true)
    try {
      await postScoringOne(item.uuid, formData, token)
      await postScoringTwo(item.uuid, { answers: answerTwo }, token)
      setLoadButt(false)
      toggleModal(null)
      setJaminan(null)
      setFileJaminan(null)
      getData()
      Toaster("success", "Sukses", "Data tersimpan")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleValue = (scores, subCatId) => {
    if (scores.length === 0) return ""

    for (let i = 0; i < scores.length; i++) {
      let scoreSubId = scores[i].uuid
      if (scoreSubId == subCatId) {
        return scores[i].analyst_response_master.uuid
      }
    }

    return ""
  }

  const handleValueFdc = scores => {
    const { fdcScore } = scores || {}

    if (fdcScore && fdcScore > 0) {
      return fdcScore
    }

    const fdcData = JSON.parse(localStorage.getItem("fdc"))
    // console.log(fdcData)
    // console.log(item)
    if (!fdcData || !item) return ""
    if (item.project_company.npwp_company_number == fdcData.npwp)
      return fdcData.score
    return ""
  }

  const handleValueFile = (scores, subCatId) => {
    if (scores.length === 0) return ""

    for (let i = 0; i < scores.length; i++) {
      let scoreSubId = scores[i].analyst_response_master.analyst_sub_category.id
      if (scoreSubId == subCatId) {
        return scores[i].file
      }
    }

    return ""
  }

  const handleValueInput = (scores, subCatId) => {
    if (scores.length === 0) return ""

    for (let i = 0; i < scores.length; i++) {
      let scoreSubId = scores[i].analyst_response_master.id
      if (scoreSubId == subCatId) {
        return scores[i].score_value
      }
    }

    return ""
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg"
      autoFocus={true}
      // centered={true}
      scrollable={true}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggleModal(null)
            setJaminan(null)
            setFileJaminan(null)
          }}
        >
          {item && item.title}
          {/* Kartu Penilaian */}
        </ModalHeader>
        <ModalBody>
          {load || load2 ? (
            <div
              style={{ height: "50vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <PuffLoader color={"#0ec6a2"} loading={true} size={120} />
            </div>
          ) : (
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmit(e, v)
              }}
            >
              <Row>
                {score?.analyst_sub_category?.map((subCat, idxInner) => {
                  return (
                    <Col md={6}>
                      <AvField
                        name={`${subCat.uuid},one`}
                        label={subCat.title}
                        type="text"
                        value={`${subCat.analyst_response_master?.title} (${subCat.analyst_response_master?.analyst_response?.score_value})`}
                        disabled
                        className="disabled-input-custom"
                      />
                      {/* <AvField
                        type="select"
                        name={`${subCat.id},one`}
                        label={subCat.title}
                        value={handleValue(
                          score?.analyst_sub_category || [],
                          subCat.id
                        )}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Field harus diisi",
                          },
                        }}
                        disabled
                        className="disabled-input-custom"
                      >
                        <option value="">Pilih</option>
                        {subCat.analyst_response_master.map(itemSelected => {
                          return (
                            <option
                              value={itemSelected.id}
                            >{`${itemSelected.title} (${itemSelected.score})`}</option>
                          )
                        })}
                      </AvField> */}
                    </Col>
                  )
                })}
              </Row>
              {/* {subCategoryOne.map((cat, idx) => {
                return (
                  <Card className="border">
                    <CardHeader className="">
                      <b>{cat.title}</b>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {cat.analyst_sub_category.map((subCat, idxInner) => {
                          if (cat.title == "Jaminan") {
                            return (
                              <>
                                <Col md={6}>
                                  <AvField
                                    type="select"
                                    name={`${subCat.id},file_jaminan`}
                                    label={subCat.title}
                                    onChange={e => setJaminan(e.target.value)}
                                    value={handleValue(score, subCat.id)}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Field harus diisi",
                                      },
                                    }}
                                  >
                                    <option value="">Pilih</option>
                                    {subCat.analyst_response_master.map(
                                      itemSelected => {
                                        return (
                                          <option
                                            value={itemSelected.id}
                                          >{`${itemSelected.title} (${itemSelected.score})`}</option>
                                        )
                                      }
                                    )}
                                  </AvField>
                                  {handleValueFile(score, subCat.id) && (
                                    <Button
                                      color="secondary"
                                      className="btn-sm"
                                      onClick={() =>
                                        window.open(
                                          `${API_URL}${handleValueFile(
                                            score,
                                            subCat.id
                                          )}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <i className="fas fa-eye"></i>
                                    </Button>
                                  )}
                                </Col>
                                <Col md={6}>
                                  <PInput
                                    label="Unggah Dokumen Appraisal (KJPP)"
                                    type="file"
                                    helpMessage="pdf file"
                                    name={`${subCat.id},file,file_jaminans`}
                                    onChange={e =>
                                      setFileJaminan(e.target.files[0])
                                    }
                                    validate={{
                                      required: {
                                        value: false,
                                        errorMessage: "Field harus diisi",
                                      },
                                    }}
                                    value={fileJaminan}
                                  />
                                </Col>
                              </>
                            )
                          }
                          return (
                            <Col md={6}>
                              <AvField
                                type="select"
                                name={`${subCat.id},one`}
                                label={subCat.title}
                                value={handleValue(score, subCat.id)}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              >
                                <option value="">Pilih</option>
                                {subCat.analyst_response_master.map(
                                  itemSelected => {
                                    return (
                                      <option
                                        value={itemSelected.id}
                                      >{`${itemSelected.title} (${itemSelected.score})`}</option>
                                    )
                                  }
                                )}
                              </AvField>
                            </Col>
                          )
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                )
              })} */}

              {subCategoryTwo.map(item => {
                if (item.title == "Komite Pembiayaan") return null
                return (
                  <Card className="border">
                    <CardHeader className="">
                      <b>{item.title}</b>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {item.analyst_sub_category[0].analyst_response_master.map(
                          itemInner => {
                            if (
                              itemInner.title == "PEFINDO" ||
                              itemInner.title == "Legal Compliance"
                            ) {
                              return null
                            }
                            return (
                              <Col md={6}>
                                <AvField
                                  type="number"
                                  name={`${itemInner.id},two`}
                                  label={itemInner.title}
                                  helpMessage="1 - 40"
                                  // onChange={handleChange}
                                  // value={handleValueInput(score, itemInner.id)}
                                  value={handleValueFdc(score)}
                                  // value={score?.sumScore || ""}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Field harus diisi",
                                    },
                                    max: {
                                      value: 40,
                                      errorMessage: "Maksimal 40",
                                    },
                                    min: {
                                      value: 1,
                                      errorMessage: "Minimal 1",
                                    },
                                  }}
                                  disabled
                                  className="disabled-input-custom"
                                />
                              </Col>
                            )
                          }
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                )
              })}

              {score?.analyst_sub_category?.length > 0 && (
                <Row>
                  {/* <Col>
                  <Card className="border">
                    <CardHeader className=""><b>Skor</b></CardHeader>
                    <CardBody>
                      <h2>
                        <b>
                          {score[0].total.analisa_resiko >= 1 && score[0].total.analisa_resiko <= 65 ? "Tolak": null}
                          {score[0].total.analisa_resiko >= 66 && score[0].total.analisa_resiko <=70 ? "C+" : null}
                          {score[0].total.analisa_resiko >= 71 && score[0].total.analisa_resiko <=75 ? "B-" : null}
                          {score[0].total.analisa_resiko >= 76 && score[0].total.analisa_resiko <=80 ? "B" : null}
                          {score[0].total.analisa_resiko >= 81 && score[0].total.analisa_resiko <=85 ? "B+" : null}
                          {score[0].total.analisa_resiko >= 86 && score[0].total.analisa_resiko <=90 ? "A-" : null}
                          {score[0].total.analisa_resiko >= 91 && score[0].total.analisa_resiko <=95 ? "A" : null}
                          {score[0].total.analisa_resiko >= 96 && score[0].total.analisa_resiko <=100 ? "A+" : null}
                        </b>
                      </h2>
                    </CardBody>
                  </Card>
                </Col> */}
                  <Col>
                    <Card className="border">
                      <CardHeader className="">
                        <b>Total</b>
                      </CardHeader>
                      <CardBody>
                        <h2>
                          <b>{score?.sumScore}</b>
                        </h2>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

              {/* <div className="mt-3">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div> */}
            </AvForm>
          )}
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalRiskHistory
