import AvCheckbox from "availity-reactstrap-validation/lib/AvCheckbox"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import { getCities, getDistrict, getSubDistrict, postUKMone, postUKMtwo } from "helpers/backend_helpers"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Col, FormGroup, Label } from "reactstrap"
// import { Form } from "redux-form"

const INIT_STEP_ONE = {
  project_type_id: "",
  title: "",
  sector_industry_id: "",
  business_entity_id: "",
  company_name: "",
  property_ownership_id: "",
  company_since: "",
  company_telp: "",
  file_company_profile: null,
  company_website: "",
  total_project_last_year: "",
  // bank_account_papper_last_three_month: null,
  bank_account_papper_last_six_month: null,
  company_full_address: "",
  country_id: "",
  province_id: "",
  city_id: "",
  district_id: "",
  sub_district_id: "",
  client_name: "",
  tenor: "",
  description: "",
  submission_total: "",
  file_contract: null,
  pic_full_name: "",
  pic_position: "",
  pic_phone_number: "",
  pic_email: "",
  agreement: false,
  capital_contribution: "",
  total_employee: "",
  total_asset: "",
}

const StepOne = (props) => {
  const {
    inputOne, 
    setInputOne, 
    handleChangeOne, 
    handleChangeFileOne,
    masterData,
    setMasterData,
    setActiveForm,
    getProjectUser,
    setLoad
  } = props

  const [loadButt, setLoadButt] = useState(false)

  const getKota = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kota = await getCities(param, token)
      setMasterData({
        ...masterData,
        city: kota.data,
        district: [],
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        city: [],
        district: [],
        sub_district: []
      })
    }
  }

  const GetKecamatan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const kecamatan = await getDistrict(param, token)
      setMasterData({
        ...masterData,
        district: kecamatan.data,
        sub_district: []
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        district: [],
        sub_district: []
      })
    }
  }

  const getKelurahan = async (uuid) => {
    const token = localStorage.getItem("token")
    let param = uuid
    try {
      const subdistrict = await getSubDistrict(param, token)
      setMasterData({
        ...masterData,
        sub_district: subdistrict.data,
      })
    } catch (error) {
      setMasterData({
        ...masterData,
        sub_district: []
      })
    }
  }

  const handleRegion = (e) => {
    if(e.target.name === 'province_id') {
      getKota(e.target.value)
      setInputOne({
        ...inputOne,
        [e.target.name]: e.target.value,
        city_id: '',
        district_id: '',
        sub_district_id: ''
      })
    } else if(e.target.name === 'city_id') {
      GetKecamatan(e.target.value)
      setInputOne({
        ...inputOne,
        [e.target.name]: e.target.value,
        district_id: '',
        sub_district_id: ''
      })
    } else if(e.target.name === 'district_id') {
      getKelurahan(e.target.value)
      setInputOne({
        ...inputOne,
        [e.target.name]: e.target.value,
        sub_district_id: ''
      })
    } else {
      setInputOne({
        ...inputOne,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmitUKM = async (e, value) => {
    let postUkm = {
      ...value,
      project_type_id: masterData.project_type[1].uuid,
      file_company_profile: inputOne.file_company_profile,
      // bank_account_papper_last_three_month: inputOne.bank_account_papper_last_three_month,
      bank_account_papper_last_six_month: inputOne.bank_account_papper_last_six_month,
      file_contract: inputOne.file_contract
    }

    const formData = new FormData()
    Object.keys(postUkm).map(item => {
      formData.append(item, postUkm[item])
    })

    const token = localStorage.getItem('token')
    setLoadButt(true)
    try {
      await postUKMone(formData, token)
      getProjectUser()
      Toaster("success", "Success", "Berhasil Submit Proyek")
      // setActiveForm(0)
      setLoadButt(false)
      props.history.push("/proyeks")
    } catch (error) {
      Toaster("error", `Ups`, error.response.data.message)
      setLoadButt(false)
    }
  }

  const handleNegara = (data) => {
    for(let i = 0; i < data.length; i++) {
      if(data[i].name === "Indonesia") {
        return data[i].uuid
      }
    }
  }

  return (
    <div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleSubmitUKM(e, v)
        }}
      >
        <FormGroup row>
          <Label sm={3}>Nama Proyek<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="title"
              type="text"
              placeholder="Nama Proyek"
              value={inputOne.title}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Nama Proyek harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Badan Usaha<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="business_entity_id"
              type="select"
              value={inputOne.business_entity_id}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Badan Usaha harus diisi'},
              }}
              options={masterData.business_entity}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Nama Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="company_name"
              type="text"
              placeholder="cth. PT Multi Guna Jaya"
              helpMessage="Mohon sertakan PT atau CV pada nama perusahaan anda"
              value={inputOne.company_name}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Nama Perusahaan harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Sektor Industri<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="sector_industry_id"
              type="select"
              value={inputOne.sector_industry_id}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Sektor Industri harus diisi'},
              }}
              options={masterData.sector_industry}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Tahun Berdiri<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="company_since"
              type="text"
              placeholder="ex. 2004"
              value={inputOne.company_since}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Tahun Berdiri harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Alamat Kantor<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="company_full_address"
              type="textarea"
              placeholder="Nama jalan / Gedung / Tempat"
              value={inputOne.company_full_address}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Alamat Kantor harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Provinsi<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="province_id"
              type="select"
              value={inputOne.province_id}
              onChange={handleRegion}
              validate={{
                required: {value: true, errorMessage: 'Provinsi harus diisi'},
              }}
              options={masterData.province}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Kota<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="city_id"
              type="select"
              value={inputOne.city_id}
              onChange={handleRegion}
              validate={{
                required: {value: true, errorMessage: 'Kota harus diisi'},
              }}
              options={masterData.city}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Kecamatan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="district_id"
              type="select"
              value={inputOne.district_id}
              onChange={handleRegion}
              validate={{
                required: {value: true, errorMessage: 'Kecamatan harus diisi'},
              }}
              options={masterData.district}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Kelurahan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="sub_district_id"
              type="select"
              value={inputOne.sub_district_id}
              onChange={handleRegion}
              validate={{
                required: {value: true, errorMessage: 'Kelurahan harus diisi'},
              }}
              options={masterData.sub_district}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Negara<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="country_id"
              type="select"
              value={handleNegara(masterData.country)}
              disabled
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Negara harus diisi'},
              }}
              options={masterData.country}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Telepon Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="company_telp"
              type="text"
              placeholder="ex. 081233445566"
              value={inputOne.company_telp}
              onChange={handleChangeOne}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Status Kepemilikan Kantor<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="property_ownership_id"
              type="select"
              value={inputOne.property_ownership_id}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Status Kepemilikan Kantor harus diisi'},
              }}
              options={masterData.property_ownership}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Total Asset<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="total_asset"
              type="number"
              placeholder="Total Asset"
              helpMessage="Dalam Rupiah"
              value={inputOne.total_asset}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Total Asset harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Unggah Profile Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="file_company_profile"
              type="file"
              helpMessage="Pdf file maksimal 50 MB"
              // value={inputOne.file_company_profile}
              onChange={handleChangeFileOne}
              validate={{
                required: {value: true, errorMessage: 'Unggah Profile Perusahaan harus diisi'},
              }}
              value={inputOne.file_company_profile}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Website Perusahaan</Label>
          <Col sm={9}>
            <PInput 
              name="company_website"
              type="text"
              placeholder="https://"
              value={inputOne.company_website}
              onChange={handleChangeOne}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Klien (Pemberi Pekerjaan)</Label>
          <Col sm={9}>
            <PInput 
              name="client_name"
              type="text"
              placeholder="Nama Perusahaan Klien"
              value={inputOne.client_name}
              onChange={handleChangeOne}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Jumlah Dana Diajukan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="submission_total"
              type="number"
              placeholder="Rp"
              helpMessage="Dalam Rupiah (Maksimal 2 Milyar Rupiah)"
              value={inputOne.submission_total}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Jumlah Pengajuan harus diisi'},
                max: {value: 2000000000, errorMessage: 'Maksimal 2 Milyar'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Tenor Proyek <span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="tenor"
              type="number"
              placeholder="Tenor dalam bulan"
              helpMessage="Bulan"
              value={inputOne.tenor}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Tenor Proyek harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Kontrak / Purchase Order <span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="file_contract"
              type="file"
              helpMessage="Pdf file maksimal 50 MB"
              // value={inputOne.title}
              onChange={handleChangeFileOne}
              validate={{
                required: {value: true, errorMessage: 'Kontrak / Purchase Order harus diisi'},
              }}
              value={inputOne.file_contract}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Deskripsi Proyek<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="description"
              type="textarea"
              placeholder="Deskripsikan manfaat apa saja dari proyek anda"
              value={inputOne.description}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Deskripsi Proyek harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Jumlah Proyek dikerjakan 12 bulan terakhir<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="total_project_last_year"
              type="number"
              placeholder="Total proyek yang pernah dikerjakan"
              value={inputOne.total_project_last_year}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Jumlah Proyek harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Label sm={3}>Rekening Koran 3-6 Bulan Terakhir<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="bank_account_papper_last_three_month"
              type="file"
              helpMessage="Pdf file maksimal 50 MB"
              onChange={handleChangeFileOne}
              validate={{
                required: {value: true, errorMessage: 'Rekening koran harus diisi'},
              }}
            />
          </Col>
        </FormGroup> */}
        <FormGroup row>
          <Label sm={3}>Rekening Koran 3 - 6 Bulan Terakhir<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="bank_account_papper_last_six_month"
              type="file"
              helpMessage="Pdf file maksimal 50 MB"
              onChange={handleChangeFileOne}
              validate={{
                required: {value: true, errorMessage: 'Rekening koran harus diisi'},
              }}
              value={inputOne.bank_account_papper_last_six_month}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Ekspektasi Kontribusi Modal Pendana<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="capital_contribution"
              type="number"
              placeholder="Kontribusi Modal Pendana"
              helpMessage="Dalam Persen (Max 95%)"
              validate={{
                required: {value: true, errorMessage: 'Kontribusi Modal Pendana harus diisi'},
                max: {value: 95, errorMessage: 'Maksimal 95 %'},
                min: {value: 1, errorMessage: 'Minimal 1 %'}
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Total Karyawan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="total_employee"
              type="number"
              placeholder="Total Karyawan"
              // helpMessage="Dalam Persen"
              validate={{
                required: {value: true, errorMessage: 'Total Karyawan harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Nama Lengkap PIC<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="pic_full_name"
              type="text"
              placeholder="Nama Lengkap PIC"
              value={inputOne.pic_full_name}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Nama Lengkap PIC harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Jabatan PIC di Perusahaan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="pic_position"
              type="text"
              placeholder="Jabatan PIC di Perusahaan"
              value={inputOne.pic_position}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Jabatan di Perusahaan harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>No HP PIC<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="pic_phone_number"
              type="text"
              placeholder="No HP. PIC"
              value={inputOne.pic_phone_number}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'No Handphone PIC harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Email PIC<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="pic_email"
              type="text"
              placeholder="Email"
              // helpMessage="Email hanya digunakan untuk keperluan data"
              value={inputOne.pic_email}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Email PIC harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Persetujuan<span className="text-danger">*</span></Label>
          <Col sm={9}>
            <PInput 
              name="agreement"
              type="checkbox"
              label="Dengan ini saya menyatakan bahwa semua data yang diberikan
              adalah benar dan dapat dipertanggung jawabkan secara hukum perdata dan pidana. Apabila
              data yang diberikan tidak benar/palsu/data orang lain, saya bersedia bertanggung jawab kepada
              pihak yang dirugikan sesuai dengan ketentuan KUHP Pasal 263 dan 264"
              // placeholder="Email"
              value={inputOne.agreement}
              onChange={handleChangeOne}
              validate={{
                required: {value: true, errorMessage: 'Persetujuan Pemohon harus diisi'},
              }}
            />
          </Col>
        </FormGroup>
        <div className="row justify-content-end mb-3">
          <div className="col-9">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              type="submit"
              disabled={loadButt}
            >
              {loadButt ? <><i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{' '}Loading</> : 'Ajukan'}
            </button>

          </div>
        </div>
      </AvForm>
      <div className="row justify-content-end mb-5">
        <div className="col-9">
          <button
            className="btn btn-secondary btn-block waves-effect waves-light"
            type="submit"
            disabled={loadButt}
            onClick={() => setActiveForm(0)}
          >
            {`Batal`}
          </button>

        </div>
      </div>
    </div>
  )
}

const FormUMKM = props => {
  const {masterData, setMasterData, projects, setActiveForm, getProjectUser, setLoad} = props
  const [inputOne, setInputOne] = useState(INIT_STEP_ONE)

  const handleChangeOne = e => {
    setInputOne({
      ...inputOne,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeFileOne = e => {
    setInputOne({
      ...inputOne,
      [e.target.name]: e.target.files[0]
    })
  }

  return (
    <div>
      <h4 className="mt-2 font-weight-semibold">
        Formulir Pengajuan Proyek UKM Tahap 1
      </h4>
      <div className="mt-4 ">
        <StepOne 
          inputOne={inputOne}
          setInputOne={setInputOne}
          handleChangeOne={handleChangeOne}
          handleChangeFileOne={handleChangeFileOne}
          masterData={masterData}
          setMasterData={setMasterData}
          setActiveForm={setActiveForm}
          getProjectUser={getProjectUser}
          setLoad={setLoad}
          history={props.history}
        />
      </div>
    </div>
  )
}

export default withRouter(FormUMKM)