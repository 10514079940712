import Toaster from "components/Common/Toast"
import Loading from "pages/Utility/Loading"
import ModalImage from "react-modal-image"
import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PInput from "components/Input/PInput"
import { API_URL } from "helpers/api_url"
import { connect } from "react-redux"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import AsyncSelect from "react-select/async"
import {
  createBlog,
  createFileWebinar,
  createWebinar,
  deleteBlog,
  getBlogAll,
  updateWebinar,
  getCarousel,
  getCarouselAdmin,
  getClaimVoucher,
  getRecentWebinar,
  getResponseWebinar,
  getVoucher,
  getWebinarByUuid,
  postCarousel,
  postVoucher,
  updateBlog,
  updateCarousel,
  updateVoucher,
  uploadFileWebinar,
  deleteWebinar,
  getResponseWebinarAdmin,
} from "helpers/backend_helpers"
import AvInput from "availity-reactstrap-validation/lib/AvInput"
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback"
import AvGroup from "availity-reactstrap-validation/lib/AvGroup"

import PInputHTML from "components/Input/PInputHTML"

import Board, { moveCard } from "@lourenci/react-kanban"
import RenderCardTitle from "./render-card-title"
import { divIcon } from "leaflet"

import img_free from "../NonAuthPages/img/Webinar-Icon-free-300x144.png"
import img_sertifikat from "../NonAuthPages/img/Webinar-Icon-Certificate-300x144.png"
import img_book from "../NonAuthPages/img/Webinar-Icon-Ebook-300x144.png"

const board_init = {
  columns: [
    {
      id: 1,
      title: "Form Webinar",
      cards: [],
    },
  ],
}

const input_init = {
  type: "",
  question: "",
  question_options: [{ name: "" }],
}

const Webinar = props => {
  const token = localStorage.getItem("token")
  const [load, setLoad] = useState(false)
  const [loadButt, setLoadButt] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const [fileUpload, setFileUpload] = useState([])

  // New
  const [modalCreateWebinar, setModalCreateWebinar] = useState(false)
  const [modalUpdateWebinar, setModalUpdateWebinar] = useState(false)
  const [modalDeleteWebinar, setModalDeleteWebinar] = useState(false)
  const [modalViewWebinar, setModalViewWebinar] = useState(false)
  const [modalViewRegistered, setModalViewRegistered] = useState(false)

  const [modalParticipants, setModalParticipants] = useState(false)

  const [select, setSelect] = useState(null)
  const [selectEn, setSelectEn] = useState(null)

  const [selectView, setSelectView] = useState(null)

  const [webinar, setWebinar] = useState([])

  const [field, setField] = useState(null)

  const [board, setBoard] = useState(board_init)
  const [input, setInput] = useState(input_init)

  const toggleModalViewWebinar = item => {
    setSelectView(item)
    setModalViewWebinar(!modalViewWebinar)
  }

  const toggleModalParticipants = item => {
    setSelectView(item)
    setModalParticipants(!modalParticipants)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleModalCreateWebinar = () =>
    setModalCreateWebinar(!modalCreateWebinar)

  const toggleModalUpdateWebinar = async (item = null) => {
    if (item) {
      setLoad(true)
      const objWeb = await getWebinarByUuid(item.id, token)

      // console.log(objWeb)

      let questionsText = objWeb.data.webinar_question.map((item, i) => {
        return {
          id: item.id,
          type: "text",
          question: item.objectQuestion,
          question_options: [{ name: "" }],
        }
      })

      let questionsOptions = objWeb.data.webinar_question_choice.map(
        (item, i) => {
          let arrOption = item.webinar_question_option.map(inItem => {
            return { name: inItem.title }
          })
          return {
            id: item.id,
            type: "options",
            question: item.objectQuestion,
            question_options: arrOption,
          }
        }
      )

      let tempBoard = { ...board }

      tempBoard.columns[0].cards = [...questionsText, ...questionsOptions]

      // console.log(tempBoard)

      setSelect(objWeb.data)
      setBoard(tempBoard)
      setLoad(false)
    } else {
      setSelect(null)
      setBoard(board_init)
    }
    setSelectEn(item)
    setModalUpdateWebinar(!modalUpdateWebinar)
  }

  const toggleModalRegistered = async (item = null) => {
    if (item) {
      setLoad(true)
      const objWebReg = await getWebinarByUuid(item.id, token)

      setSelect(objWebReg.data)
      setLoad(false)
    } else {
      setSelect(null)
    }
    setModalViewRegistered(!modalViewRegistered)
  }

  const toggleModalDeleteWebinar = async (item = null) => {
    if (item) {
      setLoad(true)
      const objWebDel = await getWebinarByUuid(item.id, token)

      setSelect(objWebDel.data)
      setLoad(false)
    } else {
      setSelect(null)
    }
    setModalDeleteWebinar(!modalDeleteWebinar)
  }

  useEffect(() => {
    getDataWebinar()
  }, [])

  const getDataWebinar = async () => {
    setLoad(true)
    const data = await getResponseWebinarAdmin(token)
    setWebinar(data.data)
    setLoad(false)
  }

  // console.log(webinar)

  const tableWebinar = {
    columns: [
      // {
      //   field: "image",
      //   label: "Gambar",
      // },
      {
        field: "title",
        label: "Judul",
      },
      // {
      //   field: "description",
      //   label: "Deskripsi",
      // },
      {
        field: "link",
        label: "Link",
      },
      {
        field: "url_video",
        label: "Video",
      },
      {
        field: "date",
        label: "Tanngal",
      },
      {
        field: "isActive",
        label: "Status",
      },
      {
        field: "isUserEthis",
        label: "Jenis Peserta",
      },
      {
        field: "userRegis",
        label: "Daftar Peserta",
      },
      {
        field: "usersResponse",
        label: "Response",
      },
      {
        field: "action",
        label: "Aksi",
      },
    ],
    rows: webinar.map(item => {
      return {
        ...item,
        url_video: item.url_video ? item.url_video : "-",
        isUserEthis: item.isUserEthis == 1 ? "Pengguna Ethis" : "Umum",
        isActive:
          item.isActive == 1 ? (
            <Badge color="primary">Aktif</Badge>
          ) : (
            <Badge color="danger">Tidak Aktif</Badge>
          ),
        date: moment(item.date).format("dddd, DD MMM YYYY | HH:MM") + " WIB",
        usersResponse: (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggleModalParticipants(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        userRegis: (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => toggleModalRegistered(item)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        action: (
          <div className="d-flex">
            <Button
              className="btn-sm"
              color="secondary"
              onClick={() => toggleModalUpdateWebinar(item)}
            >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              className="btn-sm mx-2"
              color="primary"
              onClick={() => toggleModalViewWebinar(item)}
            >
              <i className="fas fa-eye"></i>
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => toggleModalDeleteWebinar(item)}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        ),
      }
    }),
  }

  const handleCreateWebinar = async (e, value) => {
    if (field) return Toaster("error", "Maaf", "Mohon lengkapi form")
    if (board.columns[0].cards.length === 0)
      return Toaster("error", "Maaf", "Mohon lengkapi form")

    let newValue = {
      ...value,
      date: `${value.date} ${value.time}:00`,
    }

    delete newValue.files
    delete newValue.time

    board.columns[0].cards.forEach((el, index) => {
      // newValue[`files[${index}]`] = el
      if (el.type == "text") {
        newValue[`webinar[${index}][type]`] = "free-text"
        newValue[`webinar[${index}][question]`] = el.question
      } else if (el.type == "options") {
        newValue[`webinar[${index}][type]`] = "option"
        newValue[`webinar[${index}][question]`] = el.question
        el.question_options.forEach((option, idx) => {
          newValue[`webinar[${index}][question-options][${idx}]`] = option.name
        })
      }
    })

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    // upload file
    let filesObj = {}
    Object.values(fileUpload).forEach((el, index) => {
      filesObj[`files[${index}]`] = el
    })

    const formDataFile = new FormData()
    Object.keys(filesObj).map(item => {
      formDataFile.append(item, filesObj[item])
    })

    // console.log(newValue)
    // console.log(filesObj)

    setLoadButt(true)
    try {
      const res = await createWebinar(formData, token)
      await createFileWebinar(res.data.id, formDataFile, token)
      toggleModalCreateWebinar()
      setLoadButt(false)
      setInput(input_init)
      setBoard(board_init)
      getDataWebinar()
      Toaster("success", "Sukses", "Berhasil buat Webinar")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleCreateText = () => {
    if (!input.question)
      return Toaster("error", "Maaf", "Pertanyaan harus diisi")
    let tempBoard = { ...board }
    tempBoard.columns[0].cards.push({
      id: board.columns[0].cards.length + 1,
      type: "text",
      question: input.question,
      question_options: [{ name: "" }],
    })
    // console.log(tempBoard)
    setBoard(tempBoard)
    setInput(input_init)
    setField(null)
  }

  const handleCreateOptions = () => {
    if (!input.question)
      return Toaster("error", "Maaf", "Pertanyaan harus diisi")

    // input.question_options.forEach(el => {
    //   if(!el.name) {
    //     return Toaster("error", "Maaf", "Pilihan harus diisi")
    //   }
    // })

    let tempBoard = { ...board }
    tempBoard.columns[0].cards.push({
      id: board.columns[0].cards.length + 1,
      type: "options",
      question: input.question,
      question_options: input.question_options,
    })
    setBoard(tempBoard)
    setInput(input_init)
    setField(null)
  }

  const clearField = () => {
    setField("")
    setInput(input_init)
  }

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
  }

  const addOption = () => {
    setInput({
      ...input,
      question_options: [...input.question_options, { name: "" }],
    })
  }

  const removeCard = id => {
    let tempBoard = { ...board }
    let newCard = tempBoard.columns[0].cards.filter(item => item.id !== id)
    // setBoard(newBoard)
    tempBoard.columns[0].cards = newCard
    setBoard(tempBoard)
  }

  const renderCreateWebinar = () => {
    return (
      <Modal
        isOpen={modalCreateWebinar}
        role="dialog"
        autoFocus={true}
        scrollable
        size="xl"
      >
        <ModalHeader toggle={toggleModalCreateWebinar}>
          Buat Webinar
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleCreateWebinar(e, v)
            }}
          >
            <Row>
              <Col lg="6">
                <PInput
                  name="title"
                  type="text"
                  label="Judul"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Judul harus diisi",
                    },
                  }}
                />
                <PInput
                  name="title_en"
                  type="text"
                  label="Judul (EN)"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Judul harus diisi",
                    },
                  }}
                />
                <PInput
                  name="description"
                  type="textarea"
                  label="Deskripsi"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Deskripsi harus diisi",
                    },
                  }}
                />
                <PInput
                  name="description_en"
                  type="textarea"
                  label="Deskripsi (EN)"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Deskripsi harus diisi",
                    },
                  }}
                />
                <PInput
                  name="link"
                  type="text"
                  label="Link"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Title harus diisi",
                    },
                  }}
                />
                <PInput
                  name="url_video"
                  type="text"
                  label="URL Video"
                  validate={{
                    required: { value: false, errorMessage: "URL harus diisi" },
                  }}
                />
                <PInput
                  name="date"
                  type="date"
                  label="Tanggal Mulai"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Tanggal harus diisi",
                    },
                  }}
                />
                <PInput
                  name="time"
                  type="time"
                  label="Jam Mulai"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Waktu harus diisi",
                    },
                  }}
                />
                <PInput
                  name="isActive"
                  type="select"
                  label="Status"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Status harus diisi",
                    },
                  }}
                  options={[
                    { uuid: "true", title: "Aktif" },
                    { uuid: "false", title: "Tidak Aktif" },
                  ]}
                />
                <PInput
                  name="isUserEthis"
                  type="select"
                  label="Peserta"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Peserta harus diisi",
                    },
                  }}
                  options={[
                    { uuid: "true", title: "Pengguna" },
                    { uuid: "false", title: "Umum" },
                  ]}
                />
                <AvGroup>
                  <label>
                    Gambar<span className="text-danger">*</span>
                  </label>
                  <div className="custom-file">
                    <AvInput
                      type="file"
                      name="files"
                      id="customFile"
                      onChange={e => setFileUpload(e.target.files)}
                      className={`custom-file-input cp-custom`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Gambar harus diisi",
                        },
                      }}
                      multiple
                    />
                    <small class="form-text text-muted">
                      jpg, jpeg atau png. (Bisa lebih dari 1 gambar)
                    </small>
                    <AvFeedback>Berkas harus diisi</AvFeedback>
                    <label
                      style={{ color: "#636c72" }}
                      className={`custom-file-label`}
                      htmlFor="customFile"
                    >
                      {fileUpload.length > 0
                        ? fileUpload.length + " Berkas"
                        : "Pilih Berkas"}
                    </label>
                  </div>
                </AvGroup>
              </Col>
              <Col lg="6">
                <Card className="border">
                  <CardHeader className="bold d-flex justify-content-between align-items-center">
                    <div>Input</div>
                    <div>
                      <Button
                        color="primary"
                        className="btn-sm mr-2"
                        onClick={() => {
                          setInput(input_init)
                          setField("text")
                        }}
                      >
                        Text
                      </Button>
                      <Button
                        color="primary"
                        className="btn-sm"
                        onClick={() => {
                          setInput(input_init)
                          setField("options")
                        }}
                      >
                        Options
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody className="px-4 py-2">
                    {field ? (
                      <>
                        {field == "text" ? (
                          <div>
                            <FormGroup className="align-items-center">
                              <Label>Pertanyaan</Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                value={input.question}
                                onChange={e =>
                                  setInput({
                                    ...input,
                                    question: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                color="primary"
                                className="btn-sm mr-2"
                                onClick={handleCreateText}
                              >
                                Tambah
                              </Button>
                              <Button
                                color="secondary"
                                className="btn-sm"
                                onClick={clearField}
                              >
                                Batal
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <FormGroup>
                              <Label>Pertanyaan</Label>
                              <Input
                                type="text"
                                value={input.question}
                                bsSize="sm"
                                onChange={e =>
                                  setInput({
                                    ...input,
                                    question: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label className="mb-0">Pilihan</Label>
                              {/* <Input type="text" className="" bsSize="sm"/> */}
                              {input.question_options.map((item, idx) => {
                                return (
                                  <Input
                                    type="text"
                                    value={item.name}
                                    className="mt-2"
                                    bsSize="sm"
                                    onChange={e => {
                                      let tempOptions = [
                                        ...input.question_options,
                                      ]
                                      tempOptions[idx].name = e.target.value
                                      setInput({
                                        ...input,
                                        question_options: tempOptions,
                                      })
                                    }}
                                  />
                                )
                              })}
                              <Button
                                color="secondary"
                                className="btn-sm mt-2"
                                onClick={addOption}
                              >
                                Tambah Pilihan
                              </Button>
                            </FormGroup>
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                color="primary"
                                className="btn-sm mr-2"
                                onClick={handleCreateOptions}
                              >
                                Tambah
                              </Button>
                              <Button
                                color="secondary"
                                className="btn-sm"
                                onClick={clearField}
                              >
                                Batal
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="bold text-center py-2">Pilih Input</div>
                    )}
                  </CardBody>
                </Card>
                <Card className="border">
                  <CardHeader className="bold d-flex justify-content-between align-items-center">
                    <div>Form</div>
                    {/* <div>
                      <Button color="primary" className="btn-sm mr-2" onClick={() => handleCreateText()}>Text</Button>
                      <Button color="primary" className="btn-sm" onClick={() => handleCreateOptions()}>Options</Button>
                    </div> */}
                  </CardHeader>
                  <CardBody className="p-0">
                    {board.columns[0].cards.length > 0 ? (
                      <Board
                        onCardDragEnd={handleCardMove}
                        disableColumnDrag
                        renderCard={(data, { dragging }) => (
                          <Card
                            style={{ minWidth: "480px" }}
                            dragging={dragging}
                          >
                            {/* <CardHeader className="">{data.type}</CardHeader> */}
                            <CardBody className="px-4 pb-1 pt-2">
                              {data.type == "text" ? (
                                <FormGroup>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <Label>{data.question}</Label>
                                    <i
                                      className="bx bx-x font-16 cursor-pointer"
                                      onClick={() => removeCard(data.id)}
                                    ></i>
                                  </div>
                                  <Input type="text" bsSize="sm" />
                                </FormGroup>
                              ) : (
                                <FormGroup>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <Label>{data.question}</Label>
                                    <i
                                      className="bx bx-x font-16 cursor-pointer"
                                      onClick={() => removeCard(data.id)}
                                    ></i>
                                  </div>
                                  <Input type="select" bsSize="sm">
                                    <option>Pilih</option>
                                    {data.question_options.map(item => (
                                      <option>{item.name}</option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              )}
                            </CardBody>
                          </Card>
                        )}
                      >
                        {board}
                      </Board>
                    ) : (
                      <div className="bold text-center my-3">
                        Tambahkan Form Input Diatas
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleUpdateWebinar = async (e, value) => {
    // if(field) return Toaster("error", "Maaf", "Mohon lengkapi form")
    // if(board.columns[0].cards.length === 0) return Toaster("error", "Maaf", "Mohon lengkapi form")

    let newValue = {
      ...value,
      date: `${value.date} ${value.time}`,
    }

    delete newValue.files
    delete newValue.time

    let forms = [...select.webinar_question, ...select.webinar_question_choice]

    forms.forEach((el, idx) => {
      newValue[`webinar[${idx}][type]`] =
        el.type === "webinar_question" ? "free-text" : "option"
      newValue[`webinar[${idx}][uuid_question]`] = el.id
      newValue[`webinar[${idx}][question]`] = newValue[el.id]

      el.webinar_question_option.forEach((elIn, idxIn) => {
        newValue[`webinar[${idx}][question-options][${idxIn}][uuid]`] = elIn.id
        newValue[`webinar[${idx}][question-options][${idxIn}][option]`] =
          newValue[elIn.id]
        delete newValue[elIn.id]
      })

      delete newValue[el.id]
    })

    // console.log(newValue)

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    // upload file
    let filesObj = {}
    if (fileUpload.length > 0) {
      Object.values(fileUpload).forEach((el, index) => {
        filesObj[`files[${index}]`] = el
      })
    }

    const formDataFile = new FormData()
    Object.keys(filesObj).map(item => {
      formDataFile.append(item, filesObj[item])
    })

    // console.log(newValue)

    setLoadButt(true)
    try {
      const resUpdate = await updateWebinar(select.id, formData, token)
      if (fileUpload.length > 0) {
        await uploadFileWebinar(resUpdate.data.id, formDataFile, token)
      }
      toggleModalUpdateWebinar(null)
      setLoadButt(false)
      setInput(input_init)
      setBoard(board_init)
      setFileUpload([])
      getDataWebinar()
      Toaster("success", "Sukses", "Berhasil buat Webinar")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderUpdateWebinar = () => {
    return (
      <Modal
        isOpen={modalUpdateWebinar}
        role="dialog"
        autoFocus={true}
        scrollable
        size="xl"
      >
        <ModalHeader toggle={() => toggleModalUpdateWebinar(null)}>
          Ubah Webinar
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleUpdateWebinar(e, v)
            }}
          >
            <Row>
              <Col lg="6">
                <PInput
                  name="title"
                  type="text"
                  label="Judul"
                  value={select ? select.title : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Title harus diisi",
                    },
                  }}
                />
                <PInput
                  name="title_en"
                  type="text"
                  label="Judul (EN)"
                  value={selectEn ? selectEn.title_en : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Title harus diisi",
                    },
                  }}
                />
                <PInput
                  name="description"
                  type="textarea"
                  label="Deskripsi"
                  value={select ? select.description : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Deskripsi harus diisi",
                    },
                  }}
                />
                <PInput
                  name="description_en"
                  type="textarea"
                  label="Deskripsi (EN)"
                  value={selectEn ? selectEn.description_en : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Deskripsi harus diisi",
                    },
                  }}
                />
                <PInput
                  name="link"
                  type="text"
                  label="Link"
                  value={select ? select.link : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Title harus diisi",
                    },
                  }}
                />
                <PInput
                  name="url_video"
                  type="text"
                  label="URL Video"
                  value={select ? select.url_video : ""}
                  validate={{
                    required: { value: false, errorMessage: "URL harus diisi" },
                  }}
                />
                <PInput
                  name="date"
                  type="date"
                  label="Tanggal Mulai"
                  value={select ? select.date.slice(0, 10) : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Tanggal harus diisi",
                    },
                  }}
                />
                <PInput
                  name="time"
                  type="time"
                  label="Jam Mulai"
                  value={select ? select.date.slice(11) : ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Waktu harus diisi",
                    },
                  }}
                />
                <PInput
                  name="isActive"
                  type="select"
                  label="Status"
                  value={
                    select ? (select.isActive == 0 ? "false" : "true") : ""
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Status harus diisi",
                    },
                  }}
                  options={[
                    { uuid: "true", title: "Aktif" },
                    { uuid: "false", title: "Tidak Aktif" },
                  ]}
                />
                <PInput
                  name="isUserEthis"
                  type="select"
                  label="Peserta"
                  value={
                    select ? (select.isUserEthis == 0 ? "false" : "true") : ""
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Peserta harus diisi",
                    },
                  }}
                  options={[
                    { uuid: "true", title: "Pengguna" },
                    { uuid: "false", title: "Umum" },
                  ]}
                />
                <AvGroup>
                  <label>Gambar</label>
                  <div className="custom-file">
                    <AvInput
                      type="file"
                      name="files"
                      id="customFile"
                      onChange={e => setFileUpload(e.target.files)}
                      className={`custom-file-input cp-custom`}
                      validate={{
                        required: {
                          value: false,
                          errorMessage: "Gambar harus diisi",
                        },
                      }}
                      multiple
                    />
                    <small class="form-text text-muted">
                      jpg, jpeg atau png. (Bisa lebih dari 1 gambar)
                    </small>
                    <AvFeedback>Berkas harus diisi</AvFeedback>
                    <label
                      style={{ color: "#636c72" }}
                      className={`custom-file-label`}
                      htmlFor="customFile"
                    >
                      {fileUpload.length > 0
                        ? fileUpload.length + " Berkas"
                        : "Pilih Berkas"}
                    </label>
                  </div>
                </AvGroup>
              </Col>
              <Col lg="6">
                <Card className="border">
                  <CardHeader className="border-bottom bold d-flex justify-content-between align-items-center">
                    <div>Form</div>
                    {/* <div>
                      <Button color="primary" className="btn-sm mr-2" onClick={() => handleCreateText()}>Text</Button>
                      <Button color="primary" className="btn-sm" onClick={() => handleCreateOptions()}>Options</Button>
                    </div> */}
                  </CardHeader>
                  {select &&
                  select.webinar_question &&
                  select.webinar_question_choice ? (
                    <CardHeader className="border-top">
                      {select.webinar_question.map(item => {
                        return (
                          <Card>
                            <CardHeader className="border">
                              {item.title}
                            </CardHeader>
                            <CardBody>
                              <PInput
                                type="text"
                                name={`${item.id}`}
                                label="Pertanyaan"
                                value={item.objectQuestion}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                            </CardBody>
                          </Card>
                        )
                      })}
                      {select.webinar_question_choice.map(item => {
                        return (
                          <Card>
                            <CardHeader className="border">
                              {item.title}
                            </CardHeader>
                            <CardBody>
                              <PInput
                                type="text"
                                name={`${item.id}`}
                                label="Pertanyaan"
                                value={item.objectQuestion}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "Field harus diisi",
                                  },
                                }}
                              />
                              {item.webinar_question_option.map(itemIn => {
                                return (
                                  <PInput
                                    type="text"
                                    name={`${itemIn.id}`}
                                    label="Pilihan"
                                    value={itemIn.title}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Field harus diisi",
                                      },
                                    }}
                                  />
                                )
                              })}
                            </CardBody>
                          </Card>
                        )
                      })}
                    </CardHeader>
                  ) : null}
                </Card>
              </Col>
            </Row>

            <div className="py-2">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }

  const handleDeleteWebinar = async () => {
    setLoadButt(true)
    try {
      await deleteWebinar(select.id, token)
      toggleModalDeleteWebinar(null)
      setLoadButt(false)
      getDataWebinar()
      Toaster("success", "Sukses", "Berhasi hapus data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderDeleteWebinar = () => {
    return (
      <Modal isOpen={modalDeleteWebinar} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalDeleteWebinar(null)}>
          Hapus Blog
        </ModalHeader>
        <ModalBody>
          <div className="">
            <div className="font-16">
              Yakin ingin Menghapus <b>{select && select.title}</b> ?
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="mr-2"
                color="secondary"
                disabled={loadButt}
                onClick={() => toggleModalDeleteWebinar(null)}
              >
                Batal
              </Button>
              <Button
                // className="btn-sm"
                color="danger"
                onClick={handleDeleteWebinar}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Hapus"
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  // const handleListUsers = arr => {
  //   let result = []
  //   arr.forEach(el => {
  //     el.webinar_question_response.forEach(elInner => {
  //       result.push(elInner.participant)
  //     })
  //   })
  // }

  const renderModalParticipants = () => {
    return (
      <Modal
        isOpen={modalParticipants}
        role="dialog"
        autoFocus={true}
        scrollable
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalParticipants(null)}>
          Jawaban Peserta
        </ModalHeader>
        <ModalBody>
          {selectView &&
            selectView.webinar_question.map(item => {
              return (
                <Card className="border">
                  <CardHeader>{item.objectQuestion}</CardHeader>
                  <CardBody>
                    <table class="table table-striped border">
                      <thead>
                        <tr>
                          <th scope="col">Nama</th>
                          <th scope="col">Email</th>
                          <th scope="col">Telepon</th>
                          <th scope="col">Jawaban</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.webinar_question_response.map(inItem => {
                          return (
                            <tr>
                              <td>{inItem.participant.name}</td>
                              <td>{inItem.participant.email}</td>
                              <td>{inItem.participant.phone_number}</td>
                              <td>{inItem.string}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              )
            })}

          {selectView &&
            selectView.webinar_question_choice.map(item => {
              return (
                <Card className="border">
                  <CardHeader>{item.objectQuestion}</CardHeader>
                  <CardBody>
                    <table class="table table-striped border">
                      <thead>
                        <tr>
                          <th scope="col">Nama</th>
                          <th scope="col">Email</th>
                          <th scope="col">Telepon</th>
                          <th scope="col">Jawaban</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.webinar_question_response.map(inItem => {
                          return (
                            <tr>
                              <td>{inItem.participant.name}</td>
                              <td>{inItem.participant.email}</td>
                              <td>{inItem.participant.phone_number}</td>
                              <td>
                                {inItem.question_option_choice.length > 0
                                  ? inItem.question_option_choice[0].title
                                  : "-"}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              )
            })}
        </ModalBody>
      </Modal>
    )
  }

  const renderViewWebinar = () => {
    return (
      <Modal
        isOpen={modalViewWebinar}
        role="dialog"
        autoFocus={true}
        scrollable
        size="xl"
      >
        <ModalHeader toggle={() => toggleModalViewWebinar(null)}>
          Webinar
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6">
              <img
                src={
                  API_URL +
                  `${
                    selectView && selectView.files.length > 0
                      ? selectView.files[0].file
                      : ""
                  }`
                }
                alt="webinar-list"
                className="w-100"
              />
            </Col>
            <Col lg="6">
              <Card className="bg-light">
                <CardBody>
                  <div className="bold text-dark font-24 mb-4">
                    {selectView && selectView.title}
                  </div>
                  <p>
                    {selectView &&
                      selectView.description.split(/\n/).map(line => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                  </p>
                  <p>
                    <div className="bold">Simak lebih dalam pada Webinar:</div>
                    <div className="bold">{selectView && selectView.title}</div>
                    <div className="bold">
                      {`${moment(selectView && selectView.date).format(
                        "dddd, DD MMMM YYYY | HH:MM"
                      )} WIB.`}
                    </div>
                    {/* <div className="bold">Pembicara : Sarah Silvia (Senior Social Media Executive RevoU dan Founder Halal Foodventures)</div> */}
                    {/* <div className="bold">
                      Bahasan: 
                      <ul>
                        <li>Bagaimana membangun Personal Branding untuk para pencari kerja dengan  menggunakan LinkedIn</li>
                        <li>Live Demo Personal Branding</li>
                      </ul>
                    </div> */}
                  </p>
                  <p className="bold">Daftar sekarang juga, Gratis !!</p>
                  <p>
                    <Button
                      color="primary"
                      className=""
                      disabled={
                        selectView && selectView.isUserEthis == 1 ? true : false
                      }
                    >
                      Daftar Sekarang
                    </Button>
                    {selectView && selectView.isUserEthis == 1 ? (
                      <div className="mt-2 bold">
                        *Catatan: Hanya untuk Pengguna Ethis, Silahkan Login
                        terlebih dahulu.
                      </div>
                    ) : null}
                  </p>
                  <p className=" bold">
                    Bila ada pertanyaan, jangan ragu untuk menghubungi kami di:
                    <br /> 0877-4110-4555 (wa)/ fariz@ethisindonesia.com
                  </p>
                </CardBody>
              </Card>
              <div className="d-flex flex-row justify-content-center text-center bold text-dark mt-2">
                <div>
                  <img src={img_free} alt="free" className="w-100" />
                  <div className="mt-3">Tanpa Biaya</div>
                </div>
                <div>
                  <img src={img_sertifikat} alt="free" className="w-100" />
                  <div className="mt-3">E-Sertifikat</div>
                </div>
                <div>
                  <img src={img_book} alt="free" className="w-100" />
                  <div className="mt-3">E-Book</div>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }

  const renderViewRegistered = () => {
    return (
      <Modal
        isOpen={modalViewRegistered}
        role="dialog"
        autoFocus={true}
        scrollable
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalRegistered(null)}>
          Daftar Peserta
        </ModalHeader>
        <ModalBody>
          <table class="table table-striped border">
            <thead>
              <tr>
                <th scope="col">Nama</th>
                <th scope="col">Email</th>
                <th scope="col">Telepon</th>
              </tr>
            </thead>
            <tbody>
              {select &&
                select.webinar_participant.map(inItem => {
                  return (
                    <tr>
                      <td>{inItem.name}</td>
                      <td>{inItem.email}</td>
                      <td>{inItem.phone_number}</td>
                    </tr>
                  )
                })}
              <tr>
                <th colSpan="1">Total</th>
                <th>{select && select.webinar_participant.length} Peserta</th>
              </tr>
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    )
  }

  // console.log(selectView)
  // New

  if (load) return <Loading title="Kelola" breadcrumbItem="Webinar" />

  return (
    <React.Fragment>
      {renderCreateWebinar()}
      {renderUpdateWebinar()}
      {renderDeleteWebinar()}
      {renderModalParticipants()}
      {renderViewWebinar()}
      {renderViewRegistered()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Kelola" breadcrumbItem="Webinar" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Webinar
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <div className="d-flex justify-content-end mt-3">
                        <Button onClick={toggleModalCreateWebinar}>
                          + Tambah Webinar
                        </Button>
                      </div>

                      <MDBDataTable
                        responsive
                        bordered
                        data={tableWebinar}
                        striped
                        className="mt-3"
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading, user } = state.Login
  return { error, loading, user }
}

export default connect(mapStateToProps, null)(Webinar)
