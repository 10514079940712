import axios from "axios"
import { API_URL_1 } from "helpers/api_url"
import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
// import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// import images
// import logodark from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"

const EmailVerification = (props) => {
  const queryParams = new URLSearchParams(props.location.search)
  const token = queryParams.get('token')
  const [successVerify, setSuccessVerify] = useState(null)
  const [message, setMessage] = useState("")

  useEffect(() => {
    verifyEmail()
  })

  const verifyEmail = () => {
    axios
      .post(`${API_URL_1}/verify/${token}`)
      .then(res => {
        setSuccessVerify(res.data.data.success)
        setMessage(res.data.data.message)
      })
      .catch(err => {
        setSuccessVerify(err.response.data.success)
        setMessage(err.response.data.message)
      })
  }
  
  if (!token) {
    return <Redirect to="/not-found" />
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className={`${message === 'Token not found' ? 'bx bxs-message-error text-danger' : 'bx bxs-message-check text-primary'} h1 mb-0`}></i>
                        </div>
                      </div>
                      {successVerify ? 
                        <div className="p-2 mt-4">
                          <h4>Verifikasi email berhasil</h4>
                          <p>
                            Silahkan melakukan login ke akun anda{" "}
                          </p>
                          <div className="mt-4">
                            <a href="/login" className="btn btn-success w-md">
                              Masuk
                            </a>
                          </div>
                        </div> :

                        <div className="p-2 mt-4">
                          { 
                            message === 'Token already claimed.' ?
                            <>
                              <h4>Verifikasi email berhasil</h4>
                              <p>
                                Silahkan melakukan login ke akun anda{" "}
                              </p>
                              <div className="mt-4">
                                <a href="/login" className="btn btn-success w-md">
                                  Masuk
                                </a>
                              </div>
                            </> : 
                            <>
                              <h4>Verifikasi gagal</h4>
                              <p>
                                Silahkan klik email verifikasi yang telah kami kirimkan melalui email kamu{" "}
                              </p>
                              {/* <div className="mt-4">
                                <a href="#" className="btn btn-success w-md">
                                  Login
                                </a>
                              </div> */}
                            </> 
                          }
                        </div>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Did't receive an email ?{" "}
                  <a href="#" className="font-weight-medium text-primary">
                    {" "}
                    Resend{" "}
                  </a>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default EmailVerification
