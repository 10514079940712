import {
  getHelpdesk,
  getMessagesTicket,
  getTicketAssign,
  getUsersAll,
  postAssignTicket,
  postClosingTicket,
  postMessagesTicket,
  postSolvingTicket,
} from "helpers/backend_helpers"
import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import AsyncSelect from "react-select/async"
import DataTable from "components/Common/DataTable"
import { MDBDataTable } from "mdbreact"
import { API_URL } from "helpers/api_url"
import Toaster from "components/Common/Toast"
import { connect } from "react-redux"
import Loading from "pages/Utility/Loading"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import ModalImage from "react-modal-image"
import moment from "moment"

const Helpdesk = props => {
  const token = localStorage.getItem("token")
  const [helpdesk, setHelpdesk] = useState([])
  const [load, setLoad] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [loadButt, setLoadButt] = useState(false)

  const [select, setSelect] = useState(null)
  const [modalAssign, setModalAssign] = useState(false)
  const [modalSolved, setModalSolved] = useState(false)
  const [user, setUser] = useState(null)

  const [solvingDescription, setSolvingDescription] = useState("")

  const [messages, setMessages] = useState([])
  const [modalMessages, setModalMessages] = useState(false)
  const [selectTicket, setSelectTicket] = useState(null)

  const [message, setMessage] = useState("")
  const [imageMessage, setImageMessage] = useState(null)

  const scrollMessage = useRef(null)

  const toggleMessagesModal = (item = null) => {
    setSelectTicket(item)
    if (!item) {
      setMessages([])
    }
    setMessage("")
    setImageMessage(null)
    setModalMessages(!modalMessages)
  }

  useEffect(() => {
    if (scrollMessage && scrollMessage.current) {
      scrollMessage.current.scrollIntoView()
    }
  }, [messages])

  useEffect(() => {
    if (selectTicket) {
      getMessages()
    }
  }, [selectTicket])

  useEffect(() => {
    getHepldesks()
  }, [])

  const toggleModalAssign = (item = null) => {
    setSelect(item)
    setUser(null)
    setModalAssign(!modalAssign)
  }

  const toggleModalSolved = (item = null) => {
    setSolvingDescription("")
    setSelect(item)
    setModalSolved(!modalSolved)
  }

  const getMessages = async () => {
    setLoad(true)
    const data = await getMessagesTicket(selectTicket?.id, token)
    setMessages(data.data)
    setLoad(false)
  }

  const getHepldesks = async () => {
    // let query = `?per_page=${perPage}&&page=${page}&&keySearch=${keySearch}&&search=${search}`
    setLoad(true)
    const data = await getTicketAssign(token)
    setHelpdesk(data.data)
    setLoad(false)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const priorityData = [
    { value: 1, label: <Badge color="info">Low</Badge> },
    { value: 2, label: <Badge color="warning">Medium</Badge> },
    { value: 3, label: <Badge color="danger">High</Badge> },
  ]

  const status = [
    { value: 1, label: <Badge color="info">Open</Badge> },
    { value: 2, label: <Badge color="warning">Pending</Badge> },
    { value: 3, label: <Badge color="info">InProggess</Badge> },
    { value: 4, label: <Badge color="secondary">Deffered</Badge> },
    { value: 5, label: <Badge color="primary">Solved</Badge> },
    { value: 6, label: <Badge color="secondary">Closed</Badge> },
  ]

  const typeData = [
    { value: 1, label: <Badge color="danger">Bug</Badge> },
    { value: 2, label: <Badge color="primary">Improvement</Badge> },
    { value: 3, label: <Badge color="warning">Question</Badge> },
    { value: 4, label: <Badge color="info">Feedback</Badge> },
  ]

  const dataTableHelpdesk = {
    columns: [
      {
        field: "ticket_id",
        label: "Ticket Id",
      },
      {
        field: "email",
        label: "Email",
      },
      {
        field: "name",
        label: "Nama",
      },
      {
        field: "title",
        label: "Judul",
      },
      {
        field: "description",
        label: "Deskripsi",
      },
      {
        field: "priority",
        label: "Prioritas",
      },
      {
        field: "type",
        label: "Tipe",
      },
      {
        field: "image",
        label: "Gambar",
      },
      {
        field: "status",
        label: "Status",
      },
      {
        field: "assignee",
        label: "Penerima Tugas",
      },
      {
        field: "reported_at",
        label: "Tanggal Pengaduan",
      },
      {
        field: "solved_at",
        label: "Tanggal Penyelesaian",
      },
      {
        field: "solved_by",
        label: "Diselesaikan Oleh",
      },
      {
        field: "solvingDescription",
        label: "Deskripsi Penyelesaian",
      },
      {
        field: "messages_modal",
        label: "Pesan",
      },
      {
        field: "solved_action",
        label: "Aksi",
      },
      // {
      //   field: "assign_by",
      //   label: "Ditugaskan Kepada",
      // },
      // {
      //   field: "closed_ticket",
      //   label: "Tutup Tiket",
      // },
    ],
    rows: helpdesk.map(item => {
      return {
        ...item,
        reported_at: moment(item.reported_at).format("DD MMM YYYY"),
        solvingDescription: item.solvingDescription || "-",
        assignee: (
          <>
            {item.helpdesk_assignee?.length
              ? item.helpdesk_assignee.map(
                  inner => inner.assigned_to?.fullName || "-"
                )
              : "-"}
          </>
        ),
        solved_by: item.solved_by?.fullName || "-",
        closed_by: item.closed_by?.fullName || "-",
        priority: priorityData.find(inner => inner.value === item.priority)
          ?.label,
        type: typeData.find(inner => inner.value === item.type)?.label,
        image: item.files?.length && (
          <Button
            className="btn-sm"
            color="secondary"
            onClick={() => window.open(`${API_URL}${item.files[0].file}`)}
          >
            <i className="fas fa-eye"></i>
          </Button>
        ),
        status: status.find(inner => inner.value === item.status)?.label,
        solved_at: !item.solved_at
          ? "-"
          : moment(item.solved_at).format("DD MMM YYYY"),
        // closed_at: item.closed_at || "-",
        // assign_by: (
        //   <Button
        //     color="secondary"
        //     className="btn-sm"
        //     onClick={() => toggleModalAssign(item)}
        //   >
        //     <i className="fas fa-edit"></i>
        //   </Button>
        // ),
        messages_modal: (
          <Button
            color="secondary"
            className="btn-sm"
            onClick={() => toggleMessagesModal(item)}
          >
            <i className="fas fa-comment-dots"></i>
          </Button>
        ),
        solved_action: (
          <Button
            color="secondary"
            className="btn-sm"
            onClick={() => toggleModalSolved(item)}
            disabled={item.status >= 5}
          >
            <i className="fas fa-edit"></i>
          </Button>
        ),
      }
    }),
  }

  const loadOptions = (inputValue, callback) => {
    let param = `?per_page=5&keySearch=email&search=${inputValue}`
    setTimeout(async () => {
      const data = await getUsersAll(token, param)
      callback(
        data.data.map(item => {
          return { label: item.email, value: item.uuid }
        })
      )
    })
  }

  const handleAssign = async () => {
    if (!user) return Toaster("error", "Maaf", "Kolom wajib diisi.")
    setLoadButt(true)
    try {
      await postAssignTicket(select.id, { user_id: user.value }, token)
      toggleModalAssign(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Ditugaskan")
      getHepldesks()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalAssign = () => {
    return (
      <Modal isOpen={modalAssign} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalAssign(null)}>
          Tambah Penerima Tugas
        </ModalHeader>
        <ModalBody>
          <AsyncSelect
            cacheOptions
            // isMulti={true}
            loadOptions={loadOptions}
            defaultOptions
            classNamePrefix="select2-selection"
            onChange={e => {
              setUser(e)
            }}
            value={user}
          />
          <div className="py-2 mt-3">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              disabled={loadButt}
              onClick={handleAssign}
            >
              {loadButt ? (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                  Loading
                </>
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const handleSolved = async () => {
    if (!solvingDescription)
      return Toaster("error", "Maaf", "Deskripsi harus diisi")
    setLoadButt(true)
    try {
      await postSolvingTicket(
        select.id,
        { type: "SOLVED", solvingDescription: solvingDescription },
        token
      )
      toggleModalSolved(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Tiket berhasil diselesaikan")
      getHepldesks()
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const renderModalSolved = () => {
    return (
      <Modal isOpen={modalSolved} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleModalSolved(null)}>
          Penyelesaian Tugas
        </ModalHeader>
        <ModalBody>
          <div className="">
            <Label>Deskripsi Penyelesaian Tugas</Label>
            <Input
              type="textarea"
              value={solvingDescription}
              onChange={e => setSolvingDescription(e.target.value)}
              placeholder="Deskripsi"
            />
            <div className="font-16 mt-3">Yakin Tiket telah diselesaikan ?</div>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="mr-2"
                color="secondary"
                disabled={loadButt}
                onClick={() => toggleModalSolved(null)}
              >
                Batal
              </Button>
              <Button
                // className="btn-sm"
                color="primary"
                onClick={handleSolved}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Selesai"
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const messageList = [
    {
      position: "right",
      sender: "subhan",
      message:
        "123 dfafd dsfafd asfasdfdfafd sdfafsafasf sdafad adfsdfadfafd sdfadsf asdfasdf sdfadf sdfdsf ",
    },
    { position: "left", sender: "subhan", message: "123" },
    { position: "right", sender: "subhan", message: "123" },
    { position: "left", sender: "subhan", message: "123" },
  ]

  const handleSendMessage = async () => {
    if (!message) return Toaster("error", "Maaf", "Pesan harus diisi")

    const data = {
      text: message,
    }

    if (imageMessage) {
      data["files[0]"] = imageMessage
    }

    const formData = new FormData()
    Object.keys(data).map(item => {
      formData.append(item, data[item])
    })

    setLoadButt(true)
    const dataMessage = await postMessagesTicket(
      selectTicket?.id,
      formData,
      token
    )
    setMessages([...messages, dataMessage.data])
    setLoadButt(false)
    setMessage("")
    setImageMessage(null)
  }

  const handleSendMessageKeyPress = async e => {
    if (e.key == "Enter") {
      return handleSendMessage()
    }
  }

  const renderMessagesTicket = () => {
    return (
      <Modal isOpen={modalMessages} role="dialog" autoFocus={true}>
        <ModalHeader toggle={() => toggleMessagesModal(null)}>
          {selectTicket?.title}
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="chat-conversation">
              <ul className="list-unstyled">
                <PerfectScrollbar
                  style={{ height: "460px" }}
                  // containerRef={ref => setMessageBox(ref)}
                >
                  <li className={``}>
                    <div className="conversation-list">
                      <div className="ctext-wrap">
                        {Boolean(selectTicket?.files?.length) && (
                          <ModalImage
                            small={API_URL + selectTicket.files[0].file}
                            large={API_URL + selectTicket.files[0].file}
                            className="mb-1 w-100"
                          />
                        )}
                        <div className="conversation-name">
                          {selectTicket?.reported_by?.fullName}
                        </div>
                        <p>{selectTicket?.description}</p>
                        <p className="chat-time mb-0">
                          <i className="bx bx-time-five align-middle mr-1" />
                          {moment(message.created_at).format("DD-MM-YY hh:mm")}
                        </p>
                      </div>
                    </div>
                  </li>
                  {Boolean(messages.length) ? (
                    messages.map(message => (
                      <li
                        key={"test_k" + message.id}
                        className={
                          message.created_by?.id == props?.user?.uuid
                            ? "right"
                            : ""
                        }
                      >
                        <div className="conversation-list">
                          <div className="ctext-wrap">
                            {Boolean(message.files?.length) && (
                              <ModalImage
                                small={API_URL + message.files[0].file}
                                large={API_URL + message.files[0].file}
                                className="mb-1 w-100"
                              />
                            )}
                            <div className="conversation-name">
                              {message.created_by?.fullName}
                            </div>
                            <p>{message.text}</p>
                            <p className="chat-time mb-0">
                              <i className="bx bx-time-five align-middle mr-1" />
                              {moment(message.created_at).format(
                                "DD-MM-YY hh:mm"
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="text-center">Tidak Ada Pesan</div>
                  )}
                  <div ref={scrollMessage} />
                </PerfectScrollbar>
              </ul>
            </div>
            <div className="pt-3 chat-input-section">
              <Row>
                <Col>
                  <div className="position-relative">
                    <Input
                      type="textarea"
                      value={message}
                      // onKeyPress={handleSendMessageKeyPress}
                      onChange={e => setMessage(e.target.value)}
                      className="form-control chat-input"
                      style={{ minHeight: 38, height: 38 }}
                      placeholder="Tulis Pesan..."
                    />
                    <label
                      htmlFor="imageMessage"
                      tabIndex="0"
                      className="cursor-pointer"
                      style={{
                        top: "50%",
                        right: 8,
                        transform: "translate(-50%, -50%)",
                        position: "absolute",
                      }}
                    >
                      <input
                        id="imageMessage"
                        accept="image/*"
                        type="file"
                        className="d-none"
                        onChange={e => {
                          setImageMessage(e.target.files[0])
                        }}
                      />
                      <i className="fas fa-file-upload font-20" />
                    </label>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={loadButt}
                    className="btn-rounded chat-send w-md waves-effect waves-light"
                  >
                    <span className="d-none d-sm-inline-block mr-2">Kirim</span>{" "}
                    <i className="mdi mdi-send" />
                  </Button>
                </Col>
              </Row>
              {imageMessage && (
                <div className="mt-1 pl-2 d-flex align-items-center">
                  <div className="mr-1">{imageMessage.name}</div>
                  <i
                    className="fas fa-times cursor-pointer"
                    onClick={() => setImageMessage(null)}
                  />
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  if (load) return <Loading title="Pengaduan" breadcrumbItem="Pengaduan" />

  return (
    <React.Fragment>
      {renderModalAssign()}
      {renderModalSolved()}
      {renderMessagesTicket()}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pengaduan" breadcrumbItem="Pengaduan" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Pengaduan
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Riwayat Hapus Data
                      </NavLink>
                    </NavItem> */}
                  </ul>

                  <TabContent activeTab={activeTab} className="">
                    <TabPane tabId="1">
                      <MDBDataTable
                        responsive
                        bordered
                        data={dataTableHelpdesk}
                        striped
                        className="mt-4"
                      />
                      {/* <DataTable
                        table={dataTableHelpdesk}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        page={page}
                        setPage={setPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        pagination={pagination}
                        search={search}
                        handleSearch={setSearch}
                        keySearch={keySearch}
                        setKeySearch={setKeySearch}
                        dataKey={dataKey}
                        handleGetSearch={getHepldesks}
                        searching={true}
                      /> */}
                    </TabPane>
                    <TabPane tabId="2">fgdfg</TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default connect(mapStateToProps, null)(Helpdesk)
