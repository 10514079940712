import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

import PuffLoader from "react-spinners/GridLoader"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  withRouter,
  useLocation,
  useHistory,
} from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import {
  userRoutes,
  authRoutes,
  nonAuthRoutes,
  homeRoutes,
} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// actions
import { keepLogin } from "store/actions"

// Import scss
import "./assets/scss/theme.scss"
import HomePage from "pages/HomePage"
import Landing from "pages/HomePage/Landing"
import HomeLayout from "components/HomeLayout"
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { API_URL_1 } from "helpers/api_url"
import Select from "react-select"
import Toaster from "components/Common/Toast"
import { postRoleUser } from "helpers/backend_helpers"
import ScrollToTop from "pages/Utility/ScrollToTop"
import ReactGA from "react-ga"
import useGoogleAnalytics from "common/useGoogleAnalytics"
import ReactPixel from "react-facebook-pixel"

axios.defaults.headers.common["apiKey"] = process.env.REACT_APP_API_KEY

const App = props => {
  const [modalRole, setModalRole] = useState(false)
  const [masterRoles, setMasterRoles] = useState([])
  const [roleRegis, setRoleRegis] = useState(null)
  const [loadButt, setLoadButt] = useState(false)

  // google analytics
  useGoogleAnalytics()

  // facebook pixels
  useEffect(() => {
    const advancedMatching = {
      em: props.login.user ? props.login.user.email : "",
    } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    }
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL,
      advancedMatching,
      options
    )
    ReactPixel.pageView() // For tracking page view
    // ReactPixel.track("AddPaymentInfo")
    // ReactPixel.track("AddToCart")
    // ReactPixel.track("AddToWishlist")
    // ReactPixel.track("CompleteRegistration")
    // ReactPixel.track("Contact")
    // ReactPixel.track("CustomizeProduct")
    // ReactPixel.track("Donate")
    // ReactPixel.track("FindLocation")
    // ReactPixel.track("InitiateCheckout")
    // ReactPixel.track("Lead")
    // ReactPixel.track("Schedule")
    // ReactPixel.track("Search")
    // ReactPixel.track("SubmitApplication")
    // ReactPixel.track("ViewContent")
  }, [])

  const toggleModalRole = () => {
    setModalRole(!modalRole)
  }

  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const getRoles = () => {
    axios
      .get(`${API_URL_1}/roles/populate`)
      .then(res =>
        setMasterRoles(
          res.data.data.map(item => {
            return {
              value: item.title === "Lender" ? "Pemberi Dana" : "Penerima Dana",
              label:
                item.title === "Borrower" ? "Penerima Dana" : "Pemberi Dana",
              uuid: item.uuid,
            }
          })
        )
      )
      .catch(err => console.log(err.response))
  }

  useEffect(() => {
    localStorage.removeItem("pledge")
    localStorage.removeItem("trx")
    const token = localStorage.getItem("token")
    if (token) {
      props.keepLogin(token, props.history)
    }
  }, [])

  useEffect(() => {
    getRoles()
  }, [])

  useEffect(() => {
    if (props.login.user) {
      let roles = props.login.user.user_role.map(item => item.role.title)
      const tokenImpersonater = localStorage.getItem("tokenImpersonater")
      if (
        props.login.user.kyc_status >= 9 &&
        roles.includes("Borrower") &&
        roles.includes("Lender") &&
        !roles.includes("Moderator") &&
        !roles.includes("Legal") &&
        !roles.includes("Finance") &&
        !roles.includes("Publisher") &&
        !roles.includes("Marketing") &&
        !roles.includes("Bisnis") &&
        !roles.includes("Risk") &&
        !roles.includes("Collection") &&
        !tokenImpersonater
      ) {
        // console.log(masterRoles)
        toggleModalRole()
      }
    }
  }, [props.login.user])

  const Layout = getLayout()

  const handleRoles = arr => {
    let userRole = props.login.user.user_role
    for (let i = 0; i < userRole.length; i++) {
      if (arr.includes(userRole[i].role.title)) {
        return true
      }
    }
    return false
  }

  if (!props.login.user && localStorage.getItem("token")) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <PuffLoader color={"#0ec6a2"} loading={true} size={12} />
      </div>
    )
  }

  const handleSubmitRole = async () => {
    if (!roleRegis) return Toaster("error", "Maaf", "Mohon pilih peran anda")
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await postRoleUser(
        props.login.user.uuid,
        { roles: [roleRegis.uuid] },
        token
      )
      toggleModalRole()
      setLoadButt(false)
      props.keepLogin(token, props.history)
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const renderModalRole = () => {
    return (
      <Modal isOpen={modalRole} role="dialog" autoFocus={true}>
        <div className="modal-content">
          <ModalHeader>Silahkan Pilih Peran Anda</ModalHeader>
          <ModalBody>
            <FormGroup className="select2-container">
              <Label>Sebagai</Label>
              <Select
                value={roleRegis}
                onChange={e => {
                  setRoleRegis(e)
                }}
                placeholder="Pilih"
                options={masterRoles}
                classNamePrefix="select2-selection"
              />
            </FormGroup>
            <div className="mt-3">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                disabled={loadButt}
                onClick={handleSubmitRole}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Simpan"
                )}
                {/* Daftar */}
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      {renderModalRole()}
      {/* <Router> */}
      <ScrollToTop />
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx + "auth"}
            isAuthProtected={false}
            isPageAuth={true}
            loggingRole={
              props.login.user
                ? props.login.user.user_role.map(item => item.role.title)
                : []
            }
            exact
          />
        ))}

        {homeRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={HomeLayout}
            component={route.component}
            key={idx + "home"}
            isAuthProtected={false}
            // loggingRole={["Moderator"]}
            exact
          />
        ))}

        {props.login.user &&
          userRoutes.map((route, idx) => {
            if (handleRoles(route.roles)) {
              return (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx + "user"}
                  isAuthProtected={true}
                  // loggingRole={["Moderator"]}
                  exact
                />
              )
            }
          })}

        {nonAuthRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx + "nonAuth"}
            isAuthProtected={false}
            // loggingRole={["Moderator"]}
          />
        ))}
      </Switch>
      {/* </Router> */}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    login: state.Login,
    load: state.Loading,
  }
}

export default withRouter(connect(mapStateToProps, { keepLogin })(App))
