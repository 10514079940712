import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import Toaster from "components/Common/Toast"
import { approveProject } from "helpers/backend_helpers"

const ModalApprove = props => {
  const { isOpen, toggle, item, getProjectAdmin, reason } = props

  const [isCheck, setIsCheck] = useState(false)
  const [initCheck, setInitCheck] = useState("")
  const [actionCheck, setActionCheck] = useState("")

  const [reasonObj, setReasonObj] = useState(null)
  const [description, setDescription] = useState("")
  const [loadButt, setLoadButt] = useState(false)

  const handleValidSubmit = async () => {
    const token = localStorage.getItem("token")

    let post = {
      reason_id: reasonObj.uuid,
      description: description || null,
    }

    setLoadButt(true)
    try {
      await approveProject(item.uuid, true, false, post, token)
      toggle()
      setReasonObj(null)
      setDescription("")
      setIsCheck(false)
      setInitCheck("")
      setActionCheck("")
      getProjectAdmin()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Disetujui")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleReject = async () => {
    const token = localStorage.getItem("token")

    let post = {
      reason_id: reasonObj.uuid,
      description: description || null,
    }

    setLoadButt(true)
    try {
      await approveProject(item.uuid, false, true, post, token)
      toggle()
      setReasonObj(null)
      setDescription("")
      setIsCheck(false)
      setInitCheck("")
      setActionCheck("")
      getProjectAdmin()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Ditolak")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleRevisi = async () => {
    const token = localStorage.getItem("token")
    if (!reasonObj) return Toaster("error", "Maaf", "Alasan harus diisi")
    if (reasonObj?.title === "Lain-nya" && !description)
      return Toaster("error", "Maaf", "Deskripsi harus di isi")
    let post = {
      reason_id: reasonObj.uuid,
      description: description || null,
    }

    setLoadButt(true)
    try {
      await approveProject(item.uuid, false, false, post, token)
      toggle()
      setReasonObj(null)
      setDescription("")
      setIsCheck(false)
      setInitCheck("")
      setActionCheck("")
      getProjectAdmin()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Direvisi")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleReason = e => {
    setReasonObj({ ...reason[e.target.value], index: e.target.value })
  }

  const handleConfirm = () => {
    if (!actionCheck || !initCheck)
      return Toaster("error", "Maaf", "Mohon Masukkan Konfirmasi Persetujuan")

    if (actionCheck.toLowerCase() !== initCheck)
      return Toaster("error", "Maaf", "Konfirmasi Persetujuan Tidak Sesuai")

    switch (actionCheck.toLowerCase()) {
      case "setuju":
        return handleValidSubmit()
      case "tolak":
        return handleReject()
      case "revisi":
        return handleRevisi()
      default:
        return Toaster(
          "error",
          "Maaf",
          "Terjadi Kesalahan Silahkan Hubungi Admin"
        )
    }
  }

  return (
    <Modal isOpen={isOpen} role="dialog" autoFocus={true}>
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggle(null)
            setReasonObj(null)
            setDescription("")
            setIsCheck(false)
            setInitCheck("")
            setActionCheck("")
          }}
        >
          {item?.title}
        </ModalHeader>
        {isCheck ? (
          <ModalBody>
            <FormGroup>
              <Label>
                Masukkan <b>{initCheck.toUpperCase()}</b> Untuk Konfirmasi
                Persetujuan
              </Label>
              <Input
                type="text"
                name="isCheck"
                // placeholder="Deskripsi"
                value={actionCheck}
                onChange={e => setActionCheck(e.target.value)}
              />
            </FormGroup>
            <div className="mt-3 d-flex justify-content-end">
              <Button
                className="mr-2"
                onClick={() => {
                  setIsCheck(false)
                  setInitCheck("")
                  setActionCheck("")
                }}
                disabled={loadButt}
              >
                Batal
              </Button>
              <Button
                color="primary"
                onClick={handleConfirm}
                disabled={loadButt}
              >
                {loadButt ? (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                    Loading
                  </>
                ) : (
                  "Konfirmasi"
                )}
              </Button>
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <FormGroup>
              <Label>Alasan</Label>
              <Input
                type="select"
                name="select"
                onChange={handleReason}
                value={reasonObj?.index || ""}
              >
                <option value={null}>Pilih</option>
                {reason.map((item, idx) => {
                  return (
                    <option key={idx} value={idx}>
                      {item.title}
                    </option>
                  )
                })}
              </Input>
            </FormGroup>
            {reasonObj?.title === "Lain-nya" ? (
              <FormGroup>
                <Label>Deskripsi</Label>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Deskripsi"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </FormGroup>
            ) : null}
            <>
              <div className="mt-4">
                <Button
                  color="primary"
                  className="w-100"
                  onClick={() => {
                    if (!reasonObj)
                      return Toaster("error", "Maaf", "Alasan harus diisi")
                    if (reasonObj?.title === "Lain-nya" && !description)
                      return Toaster("error", "Maaf", "Deskripsi harus di isi")
                    setInitCheck("setuju")
                    setIsCheck(true)
                  }}
                  disabled={
                    !reasonObj || reasonObj?.lock_approve === 1 || loadButt
                  }
                  style={{
                    cursor:
                      !reasonObj || reasonObj?.lock_approve === 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                    </>
                  ) : (
                    "Setuju"
                  )}
                </Button>
              </div>
              <div className="mt-2">
                <Button
                  color="danger"
                  className="w-100"
                  onClick={() => {
                    if (!reasonObj)
                      return Toaster("error", "Maaf", "Alasan harus diisi")
                    if (reasonObj?.title === "Lain-nya" && !description)
                      return Toaster("error", "Maaf", "Deskripsi harus di isi")
                    setInitCheck("tolak")
                    setIsCheck(true)
                  }}
                  disabled={
                    !reasonObj || reasonObj?.lock_reject === 1 || loadButt
                  }
                  style={{
                    cursor:
                      !reasonObj || reasonObj?.lock_reject === 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                    </>
                  ) : (
                    "Tolak"
                  )}
                </Button>
              </div>
              <div className="mt-2">
                <Button
                  // color="danger"
                  className="w-100"
                  onClick={() => {
                    if (!reasonObj)
                      return Toaster("error", "Maaf", "Alasan harus diisi")
                    if (reasonObj?.title === "Lain-nya" && !description)
                      return Toaster("error", "Maaf", "Deskripsi harus di isi")
                    setInitCheck("revisi")
                    setIsCheck(true)
                  }}
                  disabled={
                    !reasonObj || reasonObj?.lock_reject === 1 || loadButt
                  }
                  style={{
                    cursor:
                      !reasonObj || reasonObj?.lock_reject === 1
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                    </>
                  ) : (
                    "Revisi"
                  )}
                </Button>
              </div>
            </>
          </ModalBody>
        )}
      </div>
    </Modal>
  )
}

export default ModalApprove
