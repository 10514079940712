import AvForm from "availity-reactstrap-validation/lib/AvForm"
import Toaster from "components/Common/Toast"
import PInput from "components/Input/PInput"
import PInputHTML from "components/Input/PInputHTML"
import { API_URL } from "helpers/api_url"
import {
  delCampaignUpd,
  getProjectUpdate,
  postDescription,
  postProjectUpdate,
  putProjectUpdate,
} from "helpers/backend_helpers"
import React, { useState } from "react"
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  CardImg,
  CardBody,
  CardTitle,
  Row,
  Col,
  Card,
  CardText,
  UncontrolledCarousel,
} from "reactstrap"

const ProjectUpdate = props => {
  const { campaign, getCampaignUuid, handleRoles, loadButt, setLoadButt } =
    props
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [file, setFile] = useState(null)
  const [files, setFiles] = useState([{ file: null }])

  const [itemEdit, setItemEdit] = useState(null)

  const [modalView, setModalView] = useState(false)
  const [selectView, setSelectView] = useState(false)

  const [description, setDescription] = useState("")
  const [description_en, setDescription_en] = useState("")

  const toggle = () => {
    setFile(null)
    setFiles([{ file: null }])
    setDescription("")
    setDescription_en("")
    setModal(!modal)
  }

  const toggleEdit = item => {
    setItemEdit(item)
    setFile(null)
    setFiles([{ file: null }])
    setModalEdit(!modalEdit)
  }

  const toggleModalView = async item => {
    const token = localStorage.getItem("token")
    if (item) {
      setLoadButt(true)
      const data = await getProjectUpdate(campaign.uuid, item.uuid, token)
      setSelectView(data.data)
      setLoadButt(false)
    } else {
      setSelectView(null)
    }
    setModalView(!modalView)
  }

  const handleSubmit = async (e, value) => {
    const newValue = {
      ...value,
      description,
      description_en,
      file: file,
    }

    const token = localStorage.getItem("token")

    files.forEach((el, index) => {
      newValue[`files[${index}]`] = el.file
    })

    delete newValue.files

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await postProjectUpdate(campaign.uuid, formData, token)
      getCampaignUuid()
      toggle()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil Tambah Data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Maaf", error.response.data.message)
    }
  }

  const handleDelete = async item => {
    const token = localStorage.getItem("token")
    setLoadButt(true)
    try {
      await delCampaignUpd(campaign.uuid, item.uuid, token)
      getCampaignUuid()
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil hapus data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleSubmitEdit = async (e, value) => {
    const token = localStorage.getItem("token")

    const newValue = {
      ...value,
      description: itemEdit?.description,
      description_en: itemEdit?.description_en,
      file: file,
    }

    files.forEach((el, index) => {
      if (el.file) {
        newValue[`files[${index}]`] = el.file
      }
    })

    delete newValue.files

    const formData = new FormData()
    Object.keys(newValue).map(item => {
      formData.append(item, newValue[item])
    })

    setLoadButt(true)
    try {
      await putProjectUpdate(campaign.uuid, itemEdit.uuid, formData, token)
      getCampaignUuid()
      toggleEdit(null)
      setLoadButt(false)
      Toaster("success", "Sukses", "Berhasil ubah data")
    } catch (error) {
      setLoadButt(false)
      Toaster("error", "Ups", error.response.data.message)
    }
  }

  const handleChangeFiles = (e, index) => {
    let temp = [...files]
    temp[index].file = e.target.files[0]
    setFiles(temp)
  }

  const renderModalView = () => {
    return (
      <Modal
        isOpen={modalView}
        autoFocus={true}
        scrollable
        // size="lg"
      >
        <div className="modal-content">
          <ModalHeader toggle={() => toggleModalView(null)}>
            Project Update
          </ModalHeader>
          <ModalBody>
            {selectView && (
              <div>
                <Row>
                  {/* <Col lg="5">
                    <UncontrolledCarousel items={[{file: selectView.file}, ...selectView.files].map(item => ({src: API_URL + item.file}))} />
                  </Col>
                  <Col lg="7">
                    <div className="bold base font-20">{selectView.title}</div>
                    <div className="">{selectView.description}</div>
                  </Col> */}
                  <Col lg={12}>
                    <Card>
                      {/* <CardImg top className="img-fluid" src={API_URL+item.file} alt="Skote" /> */}
                      <UncontrolledCarousel
                        items={[
                          { file: selectView.file },
                          ...selectView.files,
                        ].map(item => ({ src: API_URL + item.file }))}
                        className="custom-image-carousel"
                      />
                      <CardBody className="p-2">
                        <CardTitle className="mt-0">
                          {selectView.title}
                        </CardTitle>
                        <CardText className="text-justify">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selectView.description,
                            }}
                          />
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
        </div>
      </Modal>
    )
  }

  return (
    <div>
      {renderModalView()}
      <Modal isOpen={modal} autoFocus={true} size="lg" scrollable>
        <div className="modal-content">
          <ModalHeader toggle={toggle}>Tambah Project Update</ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmit(e, v)
              }}
            >
              <Row>
                <Col lg="6">
                  <PInput
                    name="title"
                    type="text"
                    label="Judul"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="6">
                  <PInput
                    name="title_en"
                    type="text"
                    label="Judul (EN)"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="12">
                  <PInputHTML
                    label="Deskripsi"
                    value={description}
                    onChange={val => {
                      setDescription(val)
                    }}
                  />

                  <PInputHTML
                    label="Deskripsi (EN)"
                    value={description_en}
                    onChange={val => {
                      setDescription_en(val)
                    }}
                  />
                </Col>
              </Row>
              <PInput
                name="file"
                type="file"
                label="Gambar"
                helpMessage="Gambar yang dikirim ke email pemberi pembiayaan"
                onChange={e => setFile(e.target.files[0])}
                validate={{
                  required: { value: true, errorMessage: "Gambar harus diisi" },
                }}
                value={file}
              />
              {files.map((item, idx) => {
                return (
                  <Row className="align-items-center">
                    <Col lg="10">
                      <PInput
                        name={`files[${idx}]`}
                        type="file"
                        label={`Gambar (${idx + 1})`}
                        // helpMessage="Gambar yang dikirim ke email pemberi pembiayaan"
                        onChange={e => handleChangeFiles(e, idx)}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Gambar harus diisi",
                          },
                        }}
                        value={item.file}
                      />
                    </Col>
                    <Col lg="2">
                      <Button
                        color="secondary"
                        className="btn-block inner mt-2"
                        disabled={idx == 0}
                        onClick={() =>
                          setFiles(files.filter((el, i) => idx !== i))
                        }
                      >
                        Hapus
                      </Button>
                    </Col>
                  </Row>
                )
              })}
              <Button
                className="mb-3"
                onClick={() => setFiles([...files, { file: null }])}
              >
                Tambah Galeri
              </Button>
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <Modal isOpen={modalEdit} autoFocus={true} size="lg">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleEdit(null)}>
            Ubah Project Update
          </ModalHeader>
          <ModalBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleSubmitEdit(e, v)
              }}
            >
              <Row>
                <Col lg="6">
                  <PInput
                    name="title"
                    type="text"
                    label="Judul"
                    value={itemEdit ? itemEdit.title : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="6">
                  <PInput
                    name="title_en"
                    type="text"
                    label="Judul (EN)"
                    value={itemEdit ? itemEdit.title_en : ""}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Judul harus diisi",
                      },
                    }}
                  />
                </Col>
                <Col lg="12">
                  <PInputHTML
                    label="Deskripsi"
                    value={itemEdit ? itemEdit.description : ""}
                    onChange={val => {
                      setItemEdit({
                        ...itemEdit,
                        description: val,
                      })
                    }}
                  />

                  <PInputHTML
                    label="Deskripsi (EN)"
                    value={itemEdit ? itemEdit.description_en : ""}
                    onChange={val => {
                      setItemEdit({
                        ...itemEdit,
                        description_en: val,
                      })
                    }}
                  />
                </Col>
              </Row>
              <PInput
                name="file"
                type="file"
                label="Gambar"
                helpMessage="Gambar yang dikirim ke email pemberi pembiayaan"
                onChange={e => setFile(e.target.files[0])}
                value={file}
              />
              {files.map((item, idx) => {
                return (
                  <Row className="align-items-center">
                    <Col lg="10">
                      <PInput
                        name={`files[${idx}]`}
                        type="file"
                        label={`Gambar (${idx + 1})`}
                        // helpMessage="Gambar yang dikirim ke email pemberi pembiayaan"
                        onChange={e => handleChangeFiles(e, idx)}
                        value={item.file}
                        validate={{
                          required: {
                            value: files.length > 1,
                            errorMessage: "Gambar harus diisi",
                          },
                        }}
                      />
                    </Col>
                    <Col lg="2">
                      <Button
                        color="secondary"
                        className="btn-block inner mt-2"
                        disabled={idx == 0}
                        onClick={() =>
                          setFiles(files.filter((el, i) => idx !== i))
                        }
                      >
                        Hapus
                      </Button>
                    </Col>
                  </Row>
                )
              })}
              <Button
                className="mb-3"
                onClick={() => setFiles([...files, { file: null }])}
              >
                Tambah Galeri
              </Button>
              <div className="py-2">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                  disabled={loadButt}
                >
                  {loadButt ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>{" "}
                      Loading
                    </>
                  ) : (
                    "Simpan"
                  )}
                </button>
              </div>
            </AvForm>
          </ModalBody>
        </div>
      </Modal>
      <div className="d-flex justify-content-end">
        <Button
          color="info"
          onClick={toggle}
          disabled={
            handleRoles().includes("Moderator")
              ? false
              : handleRoles().includes("Bisnis")
              ? true
              : false
          }
        >
          + Tambah Perkembangan Proyek
        </Button>
      </div>

      <Row className="mt-4">
        {campaign &&
          campaign.campaign_histories.map(item => {
            return (
              <Col lg={4}>
                <Card className="border">
                  <CardImg
                    top
                    className="img-fluid"
                    src={API_URL + item.file}
                    alt="Skote"
                  />
                  <CardBody className="p-1">
                    <CardTitle className="mt-0 mb-0">{item.title}</CardTitle>
                    <CardText className="justify-center">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </CardText>
                  </CardBody>
                  <Row className="row p-2">
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="primary"
                        onClick={() => toggleModalView(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Lihat
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="info"
                        onClick={() => toggleEdit(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Ubah
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="btn-sm w-100"
                        color="danger"
                        onClick={() => handleDelete(item)}
                        disabled={
                          handleRoles().includes("Moderator")
                            ? false
                            : handleRoles().includes("Bisnis")
                            ? true
                            : false
                        }
                      >
                        Hapus
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )
          })}
      </Row>
    </div>
  )
}

export default ProjectUpdate
